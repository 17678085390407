<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { UserLeanDto } from "@/types/_generated/api";
import AppModuleUsersSelect from "@/components/app/AppModuleUsersSelect.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    recipientIds?: number[];
    moduleUsers: UserLeanDto[] | null;
  }>(),
  {
    recipientIds: () => [],
    moduleUsers: () => [],
  },
);

const emit = defineEmits<{
  "update:value": [number[]];
}>();

const recipients = computed(() =>
  props.recipientIds.map((id) => props.moduleUsers?.find((user) => user.id === id)),
);

const add = (id: string) => {
  const newRecipients = [...props.recipientIds, parseInt(id)];
  emit("update:value", newRecipients);
};

const remove = (id: number) => {
  const newRecipients = props.recipientIds.filter((recipientId) => recipientId !== id);
  emit("update:value", newRecipients);
};
</script>

<template>
  <div>
    <AppModuleUsersSelect
      :label="t('survey.recommendationResponseRecipients')"
      :module-users="moduleUsers"
      :filter="(user) => !props.recipientIds.includes(user.id)"
      :disabled="recipients.length === 5"
      @change="add($event as string)"
    />

    <div v-if="recipients.length > 0" class="survey-edit-drawer__recipients__pills">
      <BasePill
        v-for="recipient in recipients"
        :key="recipient?.id"
        :label="recipient?.fullName"
        clickable
        @click="remove(recipient!.id)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer {
  &__recipients {
    &__pills {
      display: flex;
      padding: $spacing-4 0;
      flex-wrap: wrap;
      gap: $spacing-2;
    }
  }
}
</style>
