import { Ref, computed } from "vue";
import { toCurrency, toInteger, toPercent } from "@/helpers/formatNumber";
import { LossEstimateType, RiskCostBenefitAnalysisDto } from "@/types/_generated/api";

export const useRcba = (rcba: Ref<RiskCostBenefitAnalysisDto>) => {
  // Changes here must be reflected in BlueRatingRecommendation.Rcba.cs in backend
  const isValid = computed(() => {
    if (rcba.value.rcbaCostToComplete === undefined) {
      return false;
    }

    if (
      rcba.value.rcbaPropertyPrior === undefined &&
      rcba.value.rcbaPropertyAfter === undefined &&
      rcba.value.rcbaBiPrior === undefined &&
      rcba.value.rcbaBiAfter === undefined
    ) {
      return false;
    }

    if (
      (rcba.value.rcbaPropertyPrior === undefined && rcba.value.rcbaPropertyAfter !== undefined) ||
      (rcba.value.rcbaPropertyPrior !== undefined && rcba.value.rcbaPropertyAfter === undefined)
    ) {
      return false;
    }

    if (
      (rcba.value.rcbaBiPrior === undefined && rcba.value.rcbaBiAfter !== undefined) ||
      (rcba.value.rcbaBiPrior !== undefined && rcba.value.rcbaBiAfter === undefined)
    ) {
      return false;
    }

    if (
      (rcba.value.rcbaExtraPrior === undefined && rcba.value.rcbaExtraAfter !== undefined) ||
      (rcba.value.rcbaExtraPrior !== undefined && rcba.value.rcbaExtraAfter === undefined)
    ) {
      return false;
    }

    return true;
  });

  const priorSum = computed(() => {
    if (rcba.value.rcbaLossEstimateType === LossEstimateType.Percent) {
      return 0;
    }

    const sum =
      (rcba.value.rcbaPropertyPrior ?? 0) +
      (rcba.value.rcbaBiPrior ?? 0) +
      (rcba.value.rcbaExtraPrior ?? 0);

    return sum;
  });

  const afterSum = computed(() => {
    if (rcba.value.rcbaLossEstimateType === LossEstimateType.Percent) {
      return 0;
    }

    const sum =
      (rcba.value.rcbaPropertyAfter ?? 0) +
      (rcba.value.rcbaBiAfter ?? 0) +
      (rcba.value.rcbaExtraAfter ?? 0);

    return sum;
  });

  const pdSum = computed(
    () => (rcba.value.rcbaPropertyPrior ?? 0) - (rcba.value.rcbaPropertyAfter ?? 0),
  );
  const biSum = computed(() => (rcba.value.rcbaBiPrior ?? 0) - (rcba.value.rcbaBiAfter ?? 0));

  const formatSum = (sum: number | undefined) => {
    if (!sum || isNaN(sum) || typeof sum !== "number") {
      return "";
    }

    return rcba.value.rcbaLossEstimateType === LossEstimateType.Monetary
      ? toCurrency(sum, rcba.value.rcbaCurrency)
      : rcba.value.rcbaLossEstimateType === LossEstimateType.Percent
        ? toPercent(sum)
        : toInteger(sum);
  };

  const costBenefitRatio = computed((): string => {
    if (rcba.value.rcbaLossEstimateType !== LossEstimateType.Monetary || !isValid.value) {
      return "";
    }
    // Changes to this ratio calculation also needs to be reflected in the backend in SurveyRecommendation
    const reduction = priorSum.value - afterSum.value;

    if (reduction === 0 || rcba.value.rcbaCostToComplete === 0) {
      return "";
    }

    return `${toInteger(reduction / rcba.value.rcbaCostToComplete!)}:1`;
  });

  const hasExtra = computed(() => rcba.value.rcbaExtraPrior! > 0 || rcba.value.rcbaExtraAfter! > 0);

  const hasProbability = computed(
    () => !!rcba.value.rcbaProbability || rcba.value.rcbaProbability.trim().length > 0,
  );

  return {
    afterSum,
    biSum,
    costBenefitRatio,
    formatSum,
    hasExtra,
    hasProbability,
    isValid,
    pdSum,
    priorSum,
  };
};
