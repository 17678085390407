import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { getExemptions } from "@/services/siteExemptions";
import { SiteExemptionDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";

export const useSiteExemptions = (siteId: number) => {
  const { t } = useI18n({ useScope: "global" });

  const exemptions = ref<SiteExemptionDto[]>([]);
  const isLoading = ref(false);

  const loadExemptions = async () => {
    isLoading.value = true;
    exemptions.value = (await getExemptions(siteId)) ?? [];
    isLoading.value = false;
  };

  const columns = [
    new ColumnDefinition({
      title: t("sites.exemptions.number"),
      key: "number",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.location"),
      key: "location",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.comment"),
      key: "comment",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.text"),
      key: "text",
      autoWidth: false,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.addedDate"),
      key: "addedDate",
      type: ColumnDataType.Date,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.addedBy"),
      key: "addedByFullName",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.reviewedDate"),
      key: "reviewedDate",
      type: ColumnDataType.Date,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.exemptions.reviewedBy"),
      key: "reviewedByFullName",
      autoWidth: true,
    }),
  ] as ColumnDefinition<SiteExemptionDto>[];

  return {
    exemptions,
    columns,
    isLoading,
    loadExemptions,
  };
};
