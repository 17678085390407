import { Ref, ref, readonly, onUnmounted } from "vue";
import { useRouter, type RouteLocationNormalized } from "vue-router";

interface Breadcrumb {
  label: string;
  name?: string;
}

const breadcrumbs = ref<Breadcrumb[]>([]);

const setBreadcrumbs = (value: Breadcrumb[] | Ref<Breadcrumb[]>) => {
  breadcrumbs.value = value as Breadcrumb[];
};

export const useBreadcrumbs = () => {
  const router = useRouter();

  // Clear breadcrumbs after each navigation
  const clearBreadcrumbs = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // Check if the route path has changed (ignoring hash changes)
    if (to.path !== from.path) {
      breadcrumbs.value = [];
    }
  };

  const unregister = router.afterEach(clearBreadcrumbs);

  // Cleanup the navigation guard when the consuming component is unmounted
  onUnmounted(() => {
    unregister();
  });

  return {
    setBreadcrumbs,
    breadcrumbs: readonly(breadcrumbs),
  };
};
