<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { displayValue } from "@/helpers/displayValue";
import { SiteDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

interface Props {
  isLoadingSite: boolean;
  site: SiteDto | null;
}

defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const siteFields: Array<keyof SiteDto> = [
  "surveyCount",
  "latestSurveyDate",
  "latestSurveyStatus",
  "surveyorName",
];
</script>

<template>
  <BaseDataDisplayList :title="t('sites.surveys')">
    <template v-for="field in siteFields" :key="field">
      <BaseDataDisplayListItem :is-loading="isLoadingSite" :label="t('sites.' + field)">
        <template v-if="field === 'latestSurveyStatus'">
          <BaseStatusBadge v-if="site?.latestSurveyStatus" :status="site?.latestSurveyStatus" />
          <p v-else>{{ displayValue("") }}</p>
        </template>

        <template v-else>
          <p>{{ displayValue(site?.[field]) }}</p>
        </template>
      </BaseDataDisplayListItem>
    </template>
  </BaseDataDisplayList>
</template>
