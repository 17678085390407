<script setup lang="ts">
import "bingmaps";
import { onMounted, ref, watch, computed } from "vue";
import { generateUniqueId } from "@/helpers/generateUniqueId";
import type { MapHeights, MapPin } from "./types";
import BaseAlert from "../BaseAlert.vue";
import BaseSkeleton from "../BaseSkeleton.vue";
import { useMap } from "./composables/useMap";
import { roundCoordinate } from "./helpers/roundCoordinate";

const props = defineProps<{
  isLoadingData?: boolean;
  height?: MapHeights;
  pins?: MapPin[];
  movablePins?: boolean;
  clickablePins?: boolean;
}>();

const emit = defineEmits<{
  "update:pin": [{ index: number; latitude: number; longitude: number }];
  "click:pin": [{ pin: MapPin }];
}>();

const mapId = `base-map-${generateUniqueId()}`;
const { map, loadMap, setPins, isLoadingMap } = useMap();

const errorMessage = ref<string>();

const isLoading = computed(() => isLoadingMap.value || props.isLoadingData);

const validPins = computed(
  () => props.pins?.filter((pin) => pin.latitude != null && pin.longitude != null) ?? [],
);

const onMovePin = (index: number, latitude: number, longitude: number) => {
  emit("update:pin", {
    index,
    latitude: roundCoordinate(latitude),
    longitude: roundCoordinate(longitude),
  });
};

const onClickPin = (pin: MapPin) => {
  emit("click:pin", { pin });
};

const setPinsOnMap = () => {
  if (validPins.value.length > 0) {
    setPins(
      validPins.value,
      props.movablePins ?? false,
      onMovePin,
      onClickPin,
      props.clickablePins,
    );
  }
};

const addClickEventForNewPin = () => {
  // Emit move event on first click if no previous pins and movablePins is true
  if (map.value && props.movablePins && !map.value.entities.getLength()) {
    Microsoft.Maps.Events.addOne(map.value, "click", (e: Microsoft.Maps.IMouseEventArgs) => {
      onMovePin(validPins.value.length, e.location.latitude, e.location.longitude);
    });
  }
};

onMounted(async () => {
  errorMessage.value = await loadMap(mapId, validPins.value);

  if (!errorMessage.value) {
    setPinsOnMap();
    addClickEventForNewPin();
  }
});

watch(() => validPins.value, setPinsOnMap, { deep: true });
</script>

<template>
  <div class="base-map" :class="{ [`base-map--height-${height ?? 'medium'}`]: true }">
    <template v-if="!errorMessage">
      <BaseSkeleton v-if="isLoading" width="100%" height="100%" class="base-map__skeleton" />

      <div
        :id="mapId"
        class="base-map__content"
        :class="{ 'base-map__content--loading': isLoading }"
      ></div>
    </template>

    <BaseAlert v-if="errorMessage" severity="error">
      {{ errorMessage }}
    </BaseAlert>
  </div>
</template>

<style scoped lang="scss">
.base-map {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  min-height: 12.5rem;
  overflow: hidden;

  &--height {
    &-small {
      height: 20rem;
    }
    &-medium {
      height: 30rem;
    }
    &-large {
      height: 40rem;
    }
    &-100p {
      height: 100%;
    }
  }

  &__skeleton {
    height: 100%;
    width: 100%;
  }

  &__content {
    z-index: $z-0; // Bing Maps overlays will interfere with drawers

    &--loading {
      visibility: hidden;
    }
  }
}
</style>
