<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ReportMailLoginType } from "@/types/_generated/api";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { ModuleForm, ErrorMessage, UpdateModuleField } from "./types";

defineProps<{
  moduleForm: ModuleForm;
  errorMessage: ErrorMessage;
}>();

defineEmits<{
  "update:field": UpdateModuleField;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="module-edit-email">
    <div class="module-edit-email__container">
      <BaseDataDisplayListTitle>
        {{ t("survey.preSurveyEmail.preSurveyEmail") }}
      </BaseDataDisplayListTitle>

      <div class="module-edit-email__form">
        <BaseTextField
          :label="t('survey.preSurveyEmail.subject')"
          :error-message="errorMessage.preSurveyMailSubject"
          :value="moduleForm.preSurveyMailSubject"
          @update:value="$emit('update:field', 'preSurveyMailSubject', String($event))"
        />

        <div>
          <BaseLabel has-spacing>{{ t("survey.preSurveyEmail.body") }}</BaseLabel>
          <BaseEditor
            :custom-height="{ min: '200px' }"
            :error-message="errorMessage.preSurveyMailBody"
            :value="moduleForm.preSurveyMailBody"
            @input="$emit('update:field', 'preSurveyMailBody', $event)"
          />
        </div>
      </div>
    </div>

    <div class="module-edit-email__container">
      <BaseDataDisplayListTitle>
        {{ t("survey.surveyReportMail.surveyReportMail") }}
      </BaseDataDisplayListTitle>

      <div class="module-edit-email__form">
        <BaseSelect
          :label="t('modules.recResponseMfa')"
          :value="moduleForm.reportMailLoginType"
          @change="$emit('update:field', 'reportMailLoginType', $event)"
        >
          <BaseOption
            v-for="option in Object.values(ReportMailLoginType)"
            :key="option"
            :value="option"
          >
            {{ t(`common.reportMailLoginType.${option.toLowerCase()}`) }}
          </BaseOption>
        </BaseSelect>

        <BaseTextField
          :label="t('survey.surveyReportMail.subject')"
          :error-message="errorMessage.reportMailSubject"
          :value="moduleForm.reportMailSubject"
          @update:value="$emit('update:field', 'reportMailSubject', String($event))"
        />

        <div>
          <BaseLabel has-spacing>{{ t("survey.surveyReportMail.body") }}</BaseLabel>
          <BaseEditor
            :custom-height="{ min: '200px' }"
            :error-message="errorMessage.reportMailBody"
            :value="moduleForm.reportMailBody"
            @input="$emit('update:field', 'reportMailBody', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-edit-email {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &__container {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
