<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onBeforeMount, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { useQuestionnaireDraftClient } from "@/composables/useQuestionnaireDraftClient";
import { useQuestionnaireDraftValidation } from "@/composables/useQuestionnareDraftValidation";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import { AreaDto, CategoryDto, QuestionnaireDraftActionType } from "@/types/_generated/api";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BaseValidationErrors from "@/components/base/BaseValidationErrors.vue";
import QuestionnaireDescriptionToggle from "@/components/shared/Questionnaire/QuestionnaireDescriptionToggle.vue";
import QuestionnaireDraftAddButtons from "./QuestionnaireDraftAddButtons.vue";
import QuestionnaireDraftCategoryLimit from "./QuestionnaireDraftCategoryLimit.vue";
import QuestionnaireDraftEntityWrapper from "./QuestionnaireDraftEntityWrapper.vue";
import QuestionnaireDraftInputWrapper from "./QuestionnaireDraftInputWrapper.vue";
import QuestionnaireDraftPosition from "./QuestionnaireDraftPosition.vue";

const props = defineProps<{
  realQuestionnaireId: number;
  area: AreaDto;
  category: CategoryDto;
  hideQuestionGradingDescriptions: boolean;
}>();

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();
const store = useQuestionnaireStore();
const draft = useQuestionnaireDraftStore();
const client = useQuestionnaireDraftClient(store.questionnaire!.questionnaireId!);

const categoryRef = toRef(() => props.category);
const { categoryRules } = useQuestionnaireDraftValidation();

const v$ = useVuelidate(categoryRules, categoryRef, {
  $registerAs: `category_${props.category.categoryId}`,
  $autoDirty: true,
});

onBeforeMount(() => v$.value.$touch());

const isLinkedWithHistory = computed(
  () =>
    (props.category.basedOnCategoryId ||
      props.category.questions.some((x) => x.basedOnQuestionId)) &&
    store.questionnaire?.moduleId,
);

const deleteCategory = async () => {
  const entity = t("questionnaire.types.category");

  const isConfirmed = await dialog.openDialog({
    title: t("common.deleteQuestionnairePrompt.title", { entity }),
    description: `${t("common.deleteQuestionnairePrompt.message", { entity })} ${
      isLinkedWithHistory.value
        ? t("common.deleteQuestionnairePrompt.historyWarning", { entity })
        : ""
    }`,
    confirmText: t("common.actions.delete"),
  });

  if (isConfirmed) {
    client.category.remove(props.category.categoryId, draft.draft!);
  }
};
defineEmits<{
  (event: "toggle-gradient-description"): void;
}>();
</script>

<template>
  <QuestionnaireDraftEntityWrapper
    :id="`category_${category.categoryId}`"
    :title="category.name"
    :position="`${area.position}.${category.position}`"
    :type="QuestionnaireDraftActionType.Category"
    @delete="deleteCategory"
  >
    <template #default>
      <div
        class="questionnaire-draft-category"
        :class="{
          'questionnaire-draft-category--show-only-total':
            category.gradeBy === 'LowestGradedQuestion',
        }"
      >
        <div class="questionnaire-draft-category__name">
          <QuestionnaireDraftInputWrapper
            :id="category.categoryId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Category"
            property-name="Name"
            :target-name="category.name"
          >
            <template #default="{ update }">
              <BaseTextField
                :value="category.name"
                :placeholder="t('questionnaire.draft.placeholders.categoryName')"
                @update:value="update"
              />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
        <div class="questionnaire-draft-category__name__validation">
          <BaseValidationErrors :errors="v$.name?.$errors" />
        </div>

        <div class="questionnaire-draft-category__position">
          <QuestionnaireDraftPosition
            :levels="[
              {
                count: draft.draft?.areas.length ?? 0,
                current: area.position,
                type: QuestionnaireDraftActionType.Area,
              },
              {
                count: area.categories.length,
                current: category.position,
                type: QuestionnaireDraftActionType.Category,
              },
            ]"
            @update="
              ({ type, position }) => {
                if (type === 'Area') {
                  const areaId = draft.draft?.areas.find((a) => a.position === position)?.areaId;

                  client.category.moveToArea(category.categoryId, areaId!);
                }

                if (type === 'Category') {
                  client.category.moveTo(category.categoryId, position);
                }
              }
            "
          >
          </QuestionnaireDraftPosition>
        </div>

        <div class="questionnaire-draft-category__grade">
          <QuestionnaireDraftInputWrapper
            :id="category.categoryId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Category"
            property-name="GradeBy"
            :target-name="category.name"
            :debounce-time="0"
          >
            <template #default="{ update }">
              <BaseSelect :value="category.gradeBy" @change="($event) => update($event as string)">
                <BaseOption
                  v-for="option in [
                    {
                      title: t(`questionnaire.category.categoryGradings.definedLimits`),
                      value: 'DefinedLimits',
                    },
                    {
                      title: t(`questionnaire.category.categoryGradings.lowestGradedQuestion`),
                      value: 'LowestGradedQuestion',
                    },
                  ]"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.title }}
                </BaseOption>
              </BaseSelect>
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
        <div class="questionnaire-draft-category__grade__validation">
          <BaseValidationErrors :errors="v$.gradeBy?.$errors" />
        </div>

        <div v-if="category.gradeBy === 'DefinedLimits'" class="questionnaire-draft-category__blue">
          <QuestionnaireDraftInputWrapper
            :id="category.categoryId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Category"
            property-name="BlueLimit"
            :target-name="category.name"
          >
            <template #default="{ update }">
              <BaseTextField :value="category.blueLimit" type="number" @update:value="update" />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
        <div class="questionnaire-draft-category__blue__validation">
          <BaseValidationErrors :errors="v$.blueLimit?.$errors" />
        </div>

        <div class="questionnaire-draft-category__blue-score">
          <QuestionnaireDraftCategoryLimit :category="category" :color="'blue'" />
        </div>

        <div
          v-if="category.gradeBy === 'DefinedLimits'"
          class="questionnaire-draft-category__green"
        >
          <QuestionnaireDraftInputWrapper
            :id="category.categoryId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Category"
            property-name="GreenLimit"
            :target-name="category.name"
          >
            <template #default="{ update }">
              <BaseTextField :value="category.greenLimit" type="number" @update:value="update" />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
        <div class="questionnaire-draft-category__green__validation">
          <BaseValidationErrors :errors="v$.greenLimit?.$errors" />
        </div>

        <div class="questionnaire-draft-category__green-score">
          <QuestionnaireDraftCategoryLimit :category="category" :color="'green'" />
        </div>

        <div
          v-if="category.gradeBy === 'DefinedLimits'"
          class="questionnaire-draft-category__yellow"
        >
          <QuestionnaireDraftInputWrapper
            :id="category.categoryId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Category"
            property-name="YellowLimit"
            :target-name="category.name"
          >
            <template #default="{ update }">
              <BaseTextField :value="category.yellowLimit" type="number" @update:value="update" />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
        <div class="questionnaire-draft-category__yellow__validation">
          <BaseValidationErrors :errors="v$.yellowLimit?.$errors" />
        </div>

        <div class="questionnaire-draft-category__yellow-score">
          <QuestionnaireDraftCategoryLimit :category="category" :color="'yellow'" />
        </div>
      </div>
    </template>

    <template #controls>
      <div>
        <QuestionnaireDescriptionToggle
          :show-description="!hideQuestionGradingDescriptions"
          @toggle="$emit('toggle-gradient-description')"
        />
      </div>
    </template>
  </QuestionnaireDraftEntityWrapper>

  <BaseButtonRow>
    <QuestionnaireDraftAddButtons
      v-if="
        area.position === draft.draft?.areas.length &&
        category.position === area.categories.length &&
        category.questions.length === 0
      "
      :insert-into-id="draft.draft?.questionnaireId"
      :type="'Area'"
    />

    <QuestionnaireDraftAddButtons
      v-if="category.position === area.categories.length && category.questions.length === 0"
      :insert-into-id="area.areaId"
      :type="'Category'"
    />

    <QuestionnaireDraftAddButtons
      v-if="category.questions.length === 0"
      :insert-into-id="category.categoryId"
      :type="'Question'"
    />
  </BaseButtonRow>
</template>

<style lang="scss">
.questionnaire-draft-category {
  display: grid;
  grid-template-areas:
    "name      name       position    position"
    "name-val  name-val   name-val    name-val"
    "grade     blue       green       yellow"
    "grade-val blue-val   green-val   yellow-val"
    "none      blue-score green-score yellow-score";
  grid-template-columns: repeat(4, 1fr);
  gap: $spacing-4;
  align-items: flex-end;

  .base-select__wrapper__label > .base-label {
    font-size: $text-base;
    font-weight: inherit;
  }

  &__name {
    grid-area: name;
  }
  &__name__validation {
    grid-area: name-val;
  }
  &__position {
    grid-area: position;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-4;
  }
  &__grade {
    grid-area: grade;
  }
  &__grade__validation {
    grid-area: grade-val;
  }

  $colors: (
    "blue": $rating-blue,
    "green": $rating-green,
    "yellow": $rating-yellow,
  );

  @each $color, $gradient in $colors {
    &__#{$color} {
      grid-area: #{$color};
      :deep(.questionnaire-draft__input-wrapper__header) {
        background: $gradient;
      }
    }

    &__#{$color}__validation {
      grid-area: #{$color}-val;
    }

    &__#{$color}-score {
      grid-area: #{$color}-score;
    }

    &--show-only-total &__#{$color}-score {
      grid-area: #{$color};
      padding-bottom: $spacing-3;
    }
  }

  &__blue,
  &__green,
  &__yellow {
    :deep(.questionnaire-draft__input-wrapper__header) {
      border-radius: $rounded-base $rounded-base 0 0;
      display: grid;
      grid-template-columns: 1fr auto;
    }
    :deep(.questionnaire-draft__input-wrapper__header__label) {
      color: white;
      text-align: center;
      padding-left: 37px;
    }
    :deep(svg) {
      color: white;
    }
    :deep(.questionnaire-draft-input__history-btn):hover {
      background-color: transparent;
    }
    :deep(.base-textfield__box) {
      border-radius: 0 0 $rounded-base $rounded-base;
    }
  }
}
:deep(.questionnaire-draft__input-wrapper) {
  margin-bottom: 0;
}
</style>
