import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { ModuleTranslations } from "@/types/ModuleTranslations";

export const useModuleTranslations = (module: Ref<ModuleTranslations | null>) => {
  const { t } = useI18n({ useScope: "global" });

  const useCustomOrDefault = (translation: string | undefined, i18nKey: string) => {
    return translation?.length ? translation : i18nKey;
  };

  const moduleTranslations = computed(() => ({
    name: useCustomOrDefault(
      module.value?.nameTranslation,
      t("blueRating.recommendation.properties.header"),
    ),

    location: useCustomOrDefault(
      module.value?.locationTranslation,
      t("blueRating.recommendation.properties.location"),
    ),

    priority: useCustomOrDefault(
      module.value?.priorityTranslation,
      t("blueRating.recommendation.properties.priority"),
    ),

    abeyanceTranslation: useCustomOrDefault(
      module.value?.abeyanceTranslation,
      t("blueRating.surveyStatus.abeyance"),
    ),

    abeyanceDate: module.value?.abeyanceTranslation?.length
      ? t("blueRating.recommendation.properties.translationAbeyanceDate", {
          status: module.value.abeyanceTranslation,
        })
      : t("blueRating.recommendation.properties.abeyanceDate"),

    abeyanceComment: module.value?.abeyanceTranslation?.length
      ? t("blueRating.recommendation.properties.translationAbeyanceComment", {
          status: module.value.abeyanceTranslation,
        })
      : t("blueRating.recommendation.properties.abeyanceComment"),

    textForBlue: useCustomOrDefault(module.value?.textForBlue, t("common.blueColors.blue")),

    textForGreen: useCustomOrDefault(module.value?.textForGreen, t("common.blueColors.green")),

    textForYellow: useCustomOrDefault(module.value?.textForYellow, t("common.blueColors.yellow")),

    textForRed: useCustomOrDefault(module.value?.textForRed, t("common.blueColors.red")),
  }));

  return { moduleTranslations };
};
