<script setup lang="ts">
import { UserDto, UserModulePermissionDto } from "@/types/_generated/api";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import { useUserDetailsTabs, UserDetailsTab } from "../../composables/useUserDetailsTabs";
import UserDetailsGeneral from "../UserDetailsGeneral.vue";
import UserDetailsPermissions from "./UserDetailsPermissions.vue";

const props = defineProps<{
  user: UserDto;
  userModulePermissions: UserModulePermissionDto[];
}>();

const { activeTab, changeTab, tabs } = useUserDetailsTabs(props.user.role);
</script>

<template>
  <BaseDrawerTabs :current-tab="activeTab" :tabs="tabs" @change="changeTab" />

  <div class="user-detail-drawer">
    <UserDetailsGeneral v-if="activeTab === UserDetailsTab.General" :user="user" />

    <UserDetailsPermissions
      v-if="activeTab === UserDetailsTab.Permissions"
      :user="user"
      :user-module-permissions="userModulePermissions"
    />
  </div>
</template>

<style scoped lang="scss">
.user-detail-drawer {
  padding: $spacing-6;
}
</style>
