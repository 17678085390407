import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { StandardRecommendationLeanDto } from "@/types/_generated/api";
import { ColumnDefinition } from "@/types/ColumnDefinition";

export const useStandardRecommendationTableColumns = (
  includeClientColumn: Ref<boolean | undefined>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const columns = computed(() => {
    const columns: ColumnDefinition<StandardRecommendationLeanDto>[] = [
      new ColumnDefinition({
        title: t("standardRecommendations.name"),
        key: "header",
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: t("standardRecommendations.country"),
        key: "country",
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: t("standardRecommendations.type"),
        key: "type",
        autoWidth: true,
      }),

      new ColumnDefinition({
        title: t("standardRecommendations.module"),
        key: "moduleName",
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: t("standardRecommendations.categoryTags"),
        key: "categories",
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: t("standardRecommendations.questionTags"),
        key: "questions",
        autoWidth: true,
      }),
    ];

    if (includeClientColumn.value) {
      columns.splice(
        3,
        0,
        new ColumnDefinition({
          title: t("standardRecommendations.client"),
          key: "clientName",
          autoWidth: true,
        }),
      );
    }
    return columns;
  });

  return { columns };
};
