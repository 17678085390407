<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { QuestionnaireDraftHistoryActionDto } from "@/types/_generated/api";
import QuestionnaireChangeLogDiff from "./QuestionnaireDraftGlobalHistoryDiff.vue";

const props = defineProps<{
  action: QuestionnaireDraftHistoryActionDto;
  selected: boolean;
}>();

const { t } = useI18n({ useScope: "global" });

const type = computed(() => t(`questionnaire.types.${props.action.type.toLowerCase()}`));

const actionType = computed(() =>
  t(`questionnaire.draft.history.actions.${props.action.action.toLowerCase()}`),
);

const targetName = computed(() =>
  props.action.targetName === "Questionnaire" ? "questionnaire" : `'${props.action.targetName}'`,
);

const actionPropertyTargetText = computed(() => {
  if (props.action.action === "Delete") {
    return `${actionType.value} ${props.action.property} ${targetName.value}`;
  }

  return t("questionnaire.draft.history.changeOn", {
    action: actionType.value,
    entity: props.action.property,
    target: targetName.value,
  });
});

const actionEntityTargetText = computed(() => {
  if (props.action.action === "Delete") {
    return `${actionType.value} ${type.value} ${targetName.value}`;
  }

  return t("questionnaire.draft.history.changeOn", {
    action: actionType.value,
    entity: type.value,
    target: targetName.value,
  });
});
</script>

<template>
  <div class="questionnaire-draft__global-history__action">
    <div class="questionnaire-draft__global-history__action__time-stamp">
      <div class="questionnaire-draft__global-history__action__time-stamp__date">
        {{ formatDate.asDateAndTime(action.insertDate) }}
      </div>
      <div class="questionnaire-draft__global-history__action__time-stamp__user">
        <div>{{ action.insertByFullName }}</div>
      </div>
    </div>

    <div
      class="questionnaire-draft__global-history__action__inner"
      :class="{
        'questionnaire-draft__global-history__action__inner--selected': selected,
      }"
    >
      <div>
        <div
          :class="[
            'questionnaire-draft__global-history__action__type',
            `questionnaire-draft__global-history__action__type--${action.action.toLowerCase()}`,
          ]"
          :title="actionPropertyTargetText"
        >
          {{ action.type }}
        </div>

        <div class="questionnaire-draft__global-history__action__property">
          <div
            v-if="action.property"
            :class="{
              'questionnaire-draft__global-history__action__property__inner': true,
            }"
            :title="actionPropertyTargetText"
          >
            {{ action.property }}
          </div>
        </div>
      </div>

      <div class="questionnaire-draft__global-history__action__value">
        <QuestionnaireChangeLogDiff
          v-if="action.value"
          :before="action.targetName"
          :after="action.value"
        />
        <div v-else>
          <div class="questionnaire-draft__global-history__action__value__target-name">
            {{ actionEntityTargetText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.questionnaire-draft__global-history__action {
  display: flex;
  align-items: start;
  gap: $spacing-1;
  flex-direction: row;
  font-size: $text-sm;
  margin-bottom: $spacing-3;

  &__time-stamp {
    display: flex;
    position: relative;
    font-size: $text-xs;
    flex-direction: column;
    max-width: 100px;
    min-width: 100px;
    padding: $spacing-1;
    gap: $spacing-1;

    &::before {
      content: "";
      position: absolute;
      left: -16.5px;
      top: 10px;
      width: 10px;
      height: 10px;
      background-color: $primary-4;
      border-radius: 100%;
    }

    &__date {
      font-weight: $font-medium;
    }

    &__user {
      display: flex;
      font-size: $text-xs;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 100px 1fr;

    cursor: pointer;
    position: relative;
    flex-grow: 1;
    padding: $spacing-2;
    gap: $spacing-3;
    border: 1px solid $primary-3;
    border-radius: $rounded-base;
    background-color: $primary-0;

    &::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 5px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $primary-3;
    }

    &::after {
      content: "";
      position: absolute;
      left: -9px;
      top: 5px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }

    &--selected {
      border-color: $secondary-3;
      background-color: $secondary-1;

      &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: 5px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid $secondary-3;
      }

      &::after {
        content: "";
        position: absolute;
        left: -9px;
        top: 5px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid $secondary-1;
      }
    }
  }

  &__type,
  &__property__inner {
    font-size: $text-xs;
    text-align: center;
    padding: 2px 10px;
    border-radius: 10px;
    border: 1px solid $primary-3;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &__type {
    margin-bottom: $spacing-2;
    color: white;
    background-color: $info-4;

    &--create {
      background-color: $success-4;
      border-color: $success-4;
    }

    &--delete {
      background-color: $error-4;
      border-color: $error-5;
    }
  }

  &__property {
    &__inner {
      border: 1px solid $primary-4;
      background-color: $primary-0;
    }
  }

  &__value {
    &__target-name {
      padding: $spacing-1;
    }
  }
}
</style>
