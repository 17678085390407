<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseInfoState from "@/components/base/BaseInfoState/BaseInfoState.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";

defineProps<{
  isLoadingData: boolean;
  errorFetchingData: boolean;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <BaseSpinner v-if="isLoadingData" />
  <BaseInfoState
    v-else-if="errorFetchingData"
    :title="t('mods.infoState.defaults.error.title')"
    :description="t('mods.infoState.defaults.error.description')"
    variant="error"
  />
</template>
