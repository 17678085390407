<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTooltip from "@/components/base/BaseTooltip/BaseTooltip.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  selectedSurveyRound: string;
}>();

defineEmits<{
  "update:surveyRound": [number];
}>();
</script>

<template>
  <div class="exec-summary-filter-survey-round">
    <div class="exec-summary-filter-survey-round__label">
      <BaseLabel has-spacing>
        {{ t("userWeb.filter.surveyRound.title") }}
      </BaseLabel>
      <BaseTooltip
        class="exec-summary-filter-survey-round__tooltip"
        :title="t('userWeb.filter.surveyRound.description')"
      />
    </div>

    <UWTextField
      type="number"
      :min="1"
      :value="selectedSurveyRound"
      @input="$emit('update:surveyRound', Number($event.trim()))"
    />
  </div>
</template>

<style scoped lang="scss">
.exec-summary-filter-survey-round {
  &__label {
    display: flex;
    align-items: center;
    gap: $spacing-2;
  }

  &__tooltip {
    margin-bottom: $spacing-2;
  }
}
</style>
