<script setup lang="ts">
import { generateUniqueId } from "@/helpers/generateUniqueId";

export type BaseRadioGroupOption = {
  value: string;
  label: string;
};

interface Props {
  horizontal?: boolean;
  options: BaseRadioGroupOption[];
  value: string;
  disabled?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  input: [value: string];
}>();

const groupName = `radio-group-${generateUniqueId()}`;

const handleRadioChange = (value: string) => {
  if (!props.disabled) {
    emit("input", value);
  }
};
</script>

<template>
  <fieldset class="base-radiogroup" :class="{ 'base-radiogroup--horizontal': horizontal }">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="base-radiogroup__option"
      @click="handleRadioChange(option.value)"
    >
      <input
        :id="`${groupName}-radio-${index}`"
        type="radio"
        :name="groupName"
        :disabled="disabled"
        :value="option.value"
        :checked="props.value === option.value"
      />

      <label :for="`${groupName}-radio-${index}`">
        {{ option.label }}
      </label>
    </div>
  </fieldset>
</template>

<style scoped lang="scss">
.base-radiogroup {
  display: flex;
  flex-direction: column;
  gap: $spacing-3;

  &--horizontal {
    flex-direction: row;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: $spacing-2;
    user-select: none;
  }

  input[type="radio"] {
    appearance: none;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    border-radius: $rounded-full;
    background: $primary-0;
    border: 1px solid $primary-7;

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
      border-color: transparent;
      background-color: $secondary-4;
    }

    &:disabled {
      opacity: 0.4;
    }
  }
}
</style>
