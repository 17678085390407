import { ref, readonly } from "vue";
import { updateRecImagePositionService } from "@/services/recommendations";

export const useUpdateRecImagePosition = () => {
  const isUpdatingRecImagePosition = ref(false);
  const hasError = ref(false);

  const updateRecImagePosition = async (
    surveyId: number,
    recommendationId: number,
    fileId: string,
    position: number,
  ) => {
    isUpdatingRecImagePosition.value = true;
    hasError.value = false;

    try {
      await updateRecImagePositionService(surveyId, recommendationId, fileId, position);
    } catch {
      hasError.value = true;
    } finally {
      isUpdatingRecImagePosition.value = false;
    }
  };

  return {
    hasError: readonly(hasError),
    isUpdatingRecImagePosition: readonly(isUpdatingRecImagePosition),
    updateRecImagePosition,
  };
};
