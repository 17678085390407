<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { BlueRatingCategoryDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";

const props = defineProps<{
  isSurveyPublished: boolean;
  isSaving: boolean;
  category: BlueRatingCategoryDto;
  isNotApplicable: boolean;
  hasQuestionWithUnhandledRecommendations: boolean;
}>();

defineEmits<{
  (event: "click"): void;
}>();

const { t } = useI18n({ useScope: "global" });

const disabled = computed(
  () => props.isSurveyPublished || props.isSaving || props.hasQuestionWithUnhandledRecommendations,
);

const title = computed(() => {
  if (props.isNotApplicable) {
    return t("blueRating.category.allQuestionsAreN/A");
  }

  if (props.hasQuestionWithUnhandledRecommendations) {
    return t("blueRating.category.unhandledRecommendations");
  }

  if (disabled.value) {
    return "";
  }

  return t("blueRating.category.N/A");
});
</script>

<template>
  <BaseButton
    class="blue-rating-category__set-all-to-na"
    :class="{
      ['blue-rating-category__set-all-to-na__gray']: isNotApplicable,
    }"
    :disabled="disabled || isSaving"
    variant="outlined"
    :title="title"
    @click="$emit('click')"
  >
    N/A
  </BaseButton>
</template>

<style lang="scss" scoped>
.blue-rating-category__set-all-to-na__gray {
  background: $rating-notset;
}
</style>
