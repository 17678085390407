<script setup lang="ts">
import { computed, type DeepReadonly } from "vue";
import type { SiteDto, SiteDefinitionSuggestionsDto, UpdateSite } from "@/types/_generated/api";
import type { CustomDefinitionKey, ModuleSiteDefinitionProperty } from "@/types/SiteDefinitions";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { UpdateSiteField, ErrorMessages } from "./types";

const props = defineProps<{
  site: SiteDto | null;
  siteDefinitionSuggestions: SiteDefinitionSuggestionsDto[];
  siteForm: DeepReadonly<UpdateSite>;
  errorMessages: ErrorMessages;
}>();

defineEmits<{
  "update:field": UpdateSiteField;
}>();

const siteDefinitions = computed(() => {
  if (!props.site) return [];

  const definitions = [];

  for (let i = 1; i <= 4; i++) {
    const suggestions = props.siteDefinitionSuggestions.find((s) => s.siteDefinitionNumber === i);
    const customKey = `moduleSiteDefinition${i}` as ModuleSiteDefinitionProperty;

    // If the module does not have a site definition for that index, skip it
    if (!props.site[customKey]) {
      continue;
    }

    definitions.push({
      siteDefinition: `custom${i}` as CustomDefinitionKey,
      label: props.site[customKey],
      forceSuggestion: suggestions?.forceSuggestion ?? false,
      suggestions: suggestions?.suggestions || [],
    });
  }

  return definitions;
});
</script>

<template>
  <div class="site-edit-definitions">
    <template v-for="sd in siteDefinitions" :key="sd.siteDefinition">
      <BaseSelect
        v-if="sd.forceSuggestion && sd.suggestions.length"
        required
        :label="sd.forceSuggestion ? sd.label : '&nbsp;'"
        :error-message="errorMessages[sd.siteDefinition]"
        :value="siteForm?.[sd.siteDefinition]"
        @change="$emit('update:field', sd.siteDefinition, $event)"
      >
        <BaseOption v-for="s in sd.suggestions" :key="s" :value="s">
          {{ s }}
        </BaseOption>
      </BaseSelect>

      <BaseTextField
        v-else
        required
        :list="sd.suggestions"
        :label="sd.label"
        :error-message="errorMessages[sd.siteDefinition]"
        :value="siteForm?.[sd.siteDefinition]"
        @update:value="$emit('update:field', sd.siteDefinition, String($event))"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.site-edit-definitions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing-4;
}
</style>
