<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTableFilter } from "@/composables/useTableFilter";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import {
  getEffectiveSiteDefinitions,
  getUserSitePermissons,
  saveUserSitePermissions,
} from "@/services/permissions";
import { getModuleSites } from "@/services/sites";
import { getUserService } from "@/services/users";
import { SiteLeanDto, UserSitePermissionDto } from "@/types/_generated/api";
import { CustomDefinitionNumber, SiteDefinition } from "@/types/SiteDefinitions";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import ToggleAddRemoveButton from "@/components/shared/ToggleAddRemoveButton.vue";
import { useEditSitePermissionsTableColumns } from "../../composables/useEditSitePermissionsTableColumns";
import UserSiteDefinitionFilterEditor from "./UserSiteDefinitionFilterEditor.vue";

export type EditSitePermission = UserSitePermissionDto & {
  isExplicit: boolean;
};

const props = defineProps<{
  userId: number;
  moduleId: number;
}>();

const emit = defineEmits<{
  close: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

const isLoading = ref(false);
const userFullName = ref<string>();
const siteDefinitions = ref<SiteDefinition[]>([]);
const editSitePermissions = ref<EditSitePermission[]>([]);
const hasPermissionsChanges = ref(false);
const hasFilterChanges = ref(false);

const columnCustomDefinition = (num: number) =>
  `column-custom${num}` as `column-custom${CustomDefinitionNumber}`;

const { columns } = useEditSitePermissionsTableColumns(siteDefinitions);

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(editSitePermissions, columns);

const saveExplicitPermissions = async () => {
  isLoading.value = true;
  await saveUserSitePermissions(
    props.userId,
    props.moduleId,
    editSitePermissions.value.filter((p) => p.isExplicit).map((p) => p.siteId),
  );

  setCompareBase({ siteDefinitions, editSitePermissions });

  isLoading.value = false;

  emit("close");
};

const mapPermissions = (sites: SiteLeanDto[], sitePermissions: UserSitePermissionDto[]) =>
  sites.map(({ siteId, custom1, custom2, custom3, custom4 }) => {
    const permission = sitePermissions!.find((sp) => sp.siteId === siteId);
    const isExplicit = !!permission?.sitePermissionId;
    const isImplicit = sitePermissions
      .filter((p) => p.isImplicit)
      .some((esp) => esp.siteId === siteId);

    return {
      siteId,
      custom1,
      custom2,
      custom3,
      custom4,
      moduleId: props.moduleId,
      sitePermissionId: permission?.sitePermissionId,
      isImplicit,
      isExplicit,
    };
  });

const setHasAccess = (sitePermission: EditSitePermission, value: boolean) => {
  sitePermission.isExplicit = value;
  hasPermissionsChanges.value = true;
};

onBeforeMount(async () => {
  isLoading.value = true;

  const [siteDefinitionRes, userRes, sitePermissionsRes, sitesRes] = await Promise.all([
    getEffectiveSiteDefinitions(props.moduleId),
    getUserService(props.userId),
    getUserSitePermissons(props.userId),
    getModuleSites(props.moduleId),
  ]);

  userFullName.value = userRes?.fullName;
  siteDefinitions.value = siteDefinitionRes!;

  editSitePermissions.value = mapPermissions(sitesRes?.sites || [], sitePermissionsRes!);

  setCompareBase({ siteDefinitions, editSitePermissions });

  isLoading.value = false;
});

const close = async () => {
  if (await discardUnsavedChanges({ siteDefinitions, editSitePermissions })) {
    emit("close");
  }
};

const evaluateSitePermissionFilter = (event: number[]) => {
  editSitePermissions.value.forEach((esp) => {
    esp.isImplicit = event.indexOf(esp.siteId) > -1;
  });
  hasFilterChanges.value = true;
};
</script>

<template>
  <BaseDrawer
    :title="
      t('users.userSitePermissionsEditDrawerTitle', {
        fullName: userFullName,
      })
    "
    @close="close"
  >
    <UserSiteDefinitionFilterEditor
      v-if="siteDefinitions.length"
      :module-id="moduleId"
      :user-id="userId"
      :site-definitions="siteDefinitions"
      :has-changes="hasFilterChanges"
      @save="
        () => {
          hasFilterChanges = false;
        }
      "
      @evaluate="evaluateSitePermissionFilter"
    />

    <BaseCard
      has-padding
      class="user-site-permissions-edit-drawer__table-card"
      :title="t('users.permissions.sitePermissions')"
    >
      <BaseGridTable
        enable-row-click
        :columns="columns"
        :rows="result.result"
        :default-sort="[
          { key: 'isExplicit' },
          { key: 'isImplicit' },
          { key: 'custom1' },
          { key: 'custom2' },
          { key: 'custom3' },
          { key: 'custom4' },
        ]"
      >
        <template #controls>
          <BaseFilter
            :search-phrase="searchPhrase"
            :search-key="searchKey"
            :search-keys="searchKeys"
            @update:search-phrase="setSearchPhrase($event)"
            @update:search-key="setSearchKey($event)"
          />
        </template>

        <template #column-isExplicit="{ row }">
          <BaseIcon v-if="row.isExplicit" icon="check" />
        </template>

        <template #column-isImplicit="{ row }">
          <BaseIcon v-if="row.isImplicit" icon="check" />
        </template>

        <template
          v-for="({ customDefinitionNumber }, i) in siteDefinitions"
          :key="i"
          #[columnCustomDefinition(customDefinitionNumber)]="{ cell, row }"
        >
          <span
            :class="{
              'user-site-permissions__table__cell--no-access': !row.isExplicit && !row.isImplicit,
            }"
          >
            {{ cell }}
          </span>
        </template>

        <template #actions="{ row }">
          <ToggleAddRemoveButton
            :item="row"
            :is-remove="(item) => (item as EditSitePermission).isExplicit"
            @add="setHasAccess(row, true)"
            @remove="setHasAccess(row, false)"
          />
        </template>
      </BaseGridTable>
    </BaseCard>

    <template #footer-left>
      <BaseButton :disabled="!hasPermissionsChanges || isLoading" @click="saveExplicitPermissions">
        {{ t("users.permissions.saveExplicitPermissions") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.user-site-permissions__table__cell--no-access {
  opacity: 0.6;
}

.user-site-permissions-edit-drawer__table-card {
  margin-top: $spacing-4;
}
</style>
