export const toCurrency = (value: number, currency: string) => {
  try {
    return value.toLocaleString(undefined, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      currencyDisplay: "narrowSymbol",
    });
  } catch (_error) {
    return `${value} ${currency}`;
  }
};

export const toPercent = (value: number, isFraction = false, decimalDigits = 0) => {
  return isFraction
    ? value.toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits,
      })
    : (value / 100).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits,
      });
};

export const toInteger = (value: number) => {
  return value.toLocaleString(undefined, {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
