import { useI18n } from "vue-i18n";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { binaryOptions } from "@/helpers/options";
import { sortBlueColor, sortSiteResponseStatus, sortSurveyorStatus } from "@/helpers/sort";
import { addZeroPadding } from "@/helpers/zeroPadding";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";

export const useRecommendationTrackingColumns = () => {
  const { t } = useI18n({ useScope: "global" });

  const columns: ColumnDefinition<TrackedRecommendation>[] = [
    new ColumnDefinition({
      title: t("userWeb.recommendation.surveyDate"),
      key: "surveyDate",
      defaultHidden: true,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendationTracking.surveyType"),
      key: "surveyType",
      defaultHidden: true,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.recommendationNumber"),
      key: "fullPosition",
      autoWidth: true,
      sortValueFunc: (item: TrackedRecommendation) => addZeroPadding(item.fullPosition, 4, "."),
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.blueRating"),
      key: "color",
      autoWidth: true,
      sortValueFunc: (item: TrackedRecommendation) => sortBlueColor(item.color),
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.responseStatus"),
      key: "siteResponseStatus",
      autoWidth: true,
      sortValueFunc: (item: TrackedRecommendation) =>
        sortSiteResponseStatus(item.siteResponseStatus),
    }),
    new ColumnDefinition({
      title: t("surveys.surveysStatus"),
      key: "surveyorStatus",
      autoWidth: true,
      sortValueFunc: (item: TrackedRecommendation) => sortSurveyorStatus(item.surveyorStatus),
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.priority"),
      key: "priority",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.header"),
      key: "heading",
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.siteComment"),
      key: "siteResponseComment",
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.riskManagerComment"),
      key: "riskManagerComment",
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.costToComplete"),
      key: "siteResponseCostToComplete",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.isKeyRecommendation"),
      key: "isKeyRecommendation",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
  ];

  return { columns };
};
