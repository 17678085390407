<script setup lang="ts">
import { computed } from "vue";

interface Props {
  drawerState: string;
}

const props = defineProps<Props>();

defineEmits<{
  click: [void];
}>();

const backdropClass = computed(() => `base-drawer-backdrop--${props.drawerState}`);
</script>

<template>
  <div
    class="base-drawer-backdrop"
    :class="backdropClass"
    role="presentation"
    aria-hidden="true"
    @click="$emit('click')"
  />
</template>

<style scoped lang="scss">
.base-drawer-backdrop {
  position: absolute;
  inset: 0;
  background: $backdrop;
  z-index: $z-negative-1;

  &--expanded {
    animation: fadeIn $duration-200 ease-in-out;
  }

  &--collapsed {
    animation: fadeOut $duration-200 ease-in-out;
  }
}
</style>
