import { ref } from "vue";
import {
  deleteSitePermissionFilter as deleteSitePermissionFilterService,
  evaluateSitePermissionFilter as evaluateSitePermissionFilterService,
  getSitePermissionFilter as getSitePermissionFilterService,
  saveSitePermissionFilter as saveSitePermissionFilterService,
} from "@/services/permissions";
import { EvaluateSitePermissionFilter, SitePermissionFilterDto } from "@/types/_generated/api";

export const useSitePermissionFilterService = () => {
  const isLoadingSitePermissionFilter = ref(false);

  const deleteSitePermissionFilter = async (userId: number, moduleId: number) => {
    isLoadingSitePermissionFilter.value = true;

    await deleteSitePermissionFilterService(userId, moduleId);

    isLoadingSitePermissionFilter.value = false;
  };

  const evaluateSitePermissionFilter = async (dto: EvaluateSitePermissionFilter) => {
    isLoadingSitePermissionFilter.value = true;

    const result = await evaluateSitePermissionFilterService(dto);

    isLoadingSitePermissionFilter.value = false;

    return result;
  };

  const getSitePermissionFilter = async (userId: number, moduleId: number) => {
    isLoadingSitePermissionFilter.value = true;

    const result = await getSitePermissionFilterService(userId, moduleId);

    isLoadingSitePermissionFilter.value = false;

    return result;
  };

  const saveSitePermissionFilter = async (
    userId: number,
    moduleId: number,
    filter: SitePermissionFilterDto[],
  ) => {
    isLoadingSitePermissionFilter.value = true;

    await saveSitePermissionFilterService(userId, moduleId, filter);

    isLoadingSitePermissionFilter.value = false;
  };

  return {
    isLoadingSitePermissionFilter,
    deleteSitePermissionFilter,
    evaluateSitePermissionFilter,
    getSitePermissionFilter,
    saveSitePermissionFilter,
  };
};
