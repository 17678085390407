<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 15C1.46957 15 0.960859 14.7975 0.585786 14.4372C0.210714 14.0768 0 13.588 0 13.0784V10.3332C3.38746e-09 10.1148 0.0903059 9.90535 0.251051 9.75091C0.411797 9.59646 0.629814 9.5097 0.857143 9.5097C1.08447 9.5097 1.30249 9.59646 1.46323 9.75091C1.62398 9.90535 1.71429 10.1148 1.71429 10.3332V13.0784C1.71429 13.2299 1.84229 13.3529 2 13.3529H14C14.0758 13.3529 14.1484 13.324 14.202 13.2725C14.2556 13.221 14.2857 13.1512 14.2857 13.0784V10.3332C14.2857 10.1148 14.376 9.90535 14.5368 9.75091C14.6975 9.59646 14.9155 9.5097 15.1429 9.5097C15.3702 9.5097 15.5882 9.59646 15.7489 9.75091C15.9097 9.90535 16 10.1148 16 10.3332V13.0784C16 13.588 15.7893 14.0768 15.4142 14.4372C15.0391 14.7975 14.5304 15 14 15H2Z"
    />
    <path
      d="M12.3201 4.81C12.3996 4.88642 12.4627 4.97716 12.5058 5.07701C12.5488 5.17687 12.571 5.28389 12.571 5.39197C12.571 5.50005 12.5488 5.60708 12.5058 5.70693C12.4627 5.80679 12.3996 5.89752 12.3201 5.97394C12.2405 6.05037 12.1461 6.11099 12.0422 6.15235C11.9382 6.19371 11.8268 6.215 11.7143 6.215C11.6019 6.215 11.4905 6.19371 11.3865 6.15235C11.2826 6.11099 11.1882 6.05037 11.1086 5.97394L8.85721 3.81186V10.0587C8.85721 10.2771 8.7669 10.4866 8.60615 10.6411C8.44541 10.7955 8.22739 10.8823 8.00006 10.8823C7.77274 10.8823 7.55472 10.7955 7.39397 10.6411C7.23323 10.4866 7.14292 10.2771 7.14292 10.0587V3.81186L4.89149 5.97394C4.81195 6.05037 4.71752 6.11099 4.61359 6.15235C4.50966 6.19371 4.39827 6.215 4.28578 6.215C4.17329 6.215 4.0619 6.19371 3.95797 6.15235C3.85404 6.11099 3.75961 6.05037 3.68006 5.97394C3.60052 5.89752 3.53742 5.80679 3.49437 5.70693C3.45133 5.60708 3.42917 5.50005 3.42917 5.39197C3.42917 5.28389 3.45133 5.17687 3.49437 5.07701C3.53742 4.97716 3.60052 4.88642 3.68006 4.81L7.39435 1.2413C7.47385 1.16481 7.56827 1.10412 7.6722 1.06272C7.77614 1.02131 7.88755 1 8.00006 1C8.11258 1 8.22399 1.02131 8.32792 1.06272C8.43186 1.10412 8.52627 1.16481 8.60578 1.2413L12.3201 4.81Z"
    />
  </svg>
</template>
