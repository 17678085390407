import useVuelidateLib, { GlobalConfig, ValidationArgs } from "@vuelidate/core";
import { ComponentPublicInstance, Ref, ToRefs } from "vue";
import { useMultiRef } from "./useMultiRef";

export interface BaseInput {
  input: HTMLInputElement;
}

export const useVuelidate = function <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key in keyof Vargs]: any },
  Vargs extends ValidationArgs = ValidationArgs,
>(rules: Ref<Vargs> | Vargs, form: T | Ref<T> | ToRefs<T>, globalConfig?: GlobalConfig) {
  const v$ = useVuelidateLib(rules, form, globalConfig);
  const { addRef: addMultiRef, refs } = useMultiRef();

  const addRef = (ref: ComponentPublicInstance | null | Element, key: string & keyof T) => {
    const component = ref as ComponentPublicInstance;

    if (!component) {
      return;
    }

    const { input } = component as unknown as BaseInput;

    if (input && input instanceof HTMLInputElement) {
      addMultiRef({ el: input, key });
    }
  };

  const focusError = () => {
    if (!v$.value.$errors.length) {
      return;
    }

    const [{ $propertyPath }] = v$.value.$errors;
    const ref = refs.value.find((r) => r.key === $propertyPath);
    ref?.el.focus();
  };

  return { v$, addRef, focusError };
};
