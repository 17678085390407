import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import type { PopoverContent } from "../components/BaseEditorPopover.vue";
import { Editor } from "../constants";

export const useEditorPopover = () => {
  const { t } = useI18n({ useScope: "global" });

  const popoverVisibility = reactive({
    textSize: false,
  });

  const isPopoverVisible = (command: Editor.Command) => {
    switch (command) {
      case Editor.Command.TextSize:
        return popoverVisibility.textSize;
      default:
        return false;
    }
  };

  const togglePopover = (command: Editor.Command) => {
    switch (command) {
      case Editor.Command.TextSize:
        popoverVisibility.textSize = !popoverVisibility.textSize;
        break;
      default:
        break;
    }
  };

  const getPopoverContent = (command: Editor.Command): PopoverContent => {
    switch (command) {
      case Editor.Command.TextSize:
        return {
          type: Editor.Popover.List,
          list: [
            {
              label: t("library.editor.textSizes.small"),
              value: "12px",
              style: { fontSize: "12px" },
            },
            {
              label: t("library.editor.textSizes.normal"),
              value: "14px",
              style: { fontSize: "14px" },
            },
            {
              label: t("library.editor.textSizes.large"),
              value: "16px",
              style: { fontSize: "16px" },
            },
            {
              label: t("library.editor.textSizes.huge"),
              value: "20px",
              style: { fontSize: "20px" },
            },
          ],
        };
      default:
        return null;
    }
  };

  return { isPopoverVisible, togglePopover, getPopoverContent };
};
