<script setup lang="ts">
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useModuleTranslations } from "@/composables/useModuleTranslations";
import { getCategoryById, getQuestionById } from "@/helpers/survey-helpers";
import {
  BlueColor,
  ModuleDto,
  Status,
  BlueRatingDto,
  SurveyorStatus,
  UpdateBlueRatingRecommendation,
} from "@/types/_generated/api";
import { Option } from "@/types/Option";
import { useRecommendationSelectOptions } from "@/views/Admin/BlueRating/composables/useRecommendationSelectOptions";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDateField from "@/components/base/BaseDateField.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BlueRatingRecQuestionList from "./BlueRatingRecQuestionList.vue";
import { ErrorMessage, RecommendationForm } from "./types";

const props = defineProps<{
  recommendation: RecommendationForm;
  survey: BlueRatingDto;
  module: ModuleDto;
  disabled: boolean;
  spoeCheckComment: string;
  errorMessage: ErrorMessage;
}>();

const emits = defineEmits<{
  <Key extends keyof UpdateBlueRatingRecommendation>(
    event: "update",
    key: Key,
    value: UpdateBlueRatingRecommendation[Key],
  ): void;
}>();

const { t } = useI18n({ useScope: "global" });
const { moduleTranslations } = useModuleTranslations(toRef(props.module));

const { colorOverrideOptions, defaultRecommendationTypeOptions, surveyorStatusOptions } =
  useRecommendationSelectOptions(toRef(props.module));

const colors = computed((): readonly Option[] =>
  props.recommendation?.categoryColorOverride === BlueColor.Blue
    ? [
        {
          value: BlueColor.Blue,
          title: t("common.blueColors.blue"),
          icon: "IconWarning",
        },
        ...colorOverrideOptions,
      ]
    : colorOverrideOptions,
);

const updateValue = <Key extends keyof UpdateBlueRatingRecommendation>(
  key: Key,
  value: UpdateBlueRatingRecommendation[Key],
) => {
  emits("update", key, value);
};

const showBlueWarning = computed(() => {
  if (!props.recommendation || !props.survey) {
    return false;
  }

  if (props.recommendation.categoryColorOverride === BlueColor.Blue) {
    return true;
  }

  const category = props.recommendation.categoryId
    ? getCategoryById(props.survey, props.recommendation.categoryId)
    : null;

  if (category && category.color === BlueColor.Blue) {
    return true;
  }

  const questions = (props.recommendation.questionIds || []).map((id) =>
    getQuestionById(props.survey!, id),
  );

  if (questions.length && questions.some((q) => q?.value === BlueColor.Blue)) {
    return true;
  }

  return false;
});

const recommendationTypeOptions = computed((): readonly Option[] => {
  const options: Option[] = [];
  const defaults = props.module.recommendationTypes?.length
    ? props.module.recommendationTypes
    : defaultRecommendationTypeOptions;

  // Add recommendation types from module or defaults
  options.push(
    ...defaults.map((type) => ({
      value: type,
      title: type,
    })),
  );

  // Add selected recommendation type if not in options
  if (props.recommendation.type && !options.some((o) => o.value === props.recommendation.type)) {
    options.push({
      value: props.recommendation.type,
      title: props.recommendation.type,
    });
  }

  return options;
});
</script>

<template>
  <section v-if="recommendation">
    <BaseAlert v-if="showBlueWarning" severity="warning">
      <p>
        {{ t("blueRating.recommendation.recommendationColorWarning") }}
      </p>
    </BaseAlert>

    <BaseCard has-padding>
      <div class="blue-rating-rec-general">
        <BaseTextField
          :label="moduleTranslations.name"
          :disabled="disabled"
          :value="recommendation.header"
          :required="module.forceName"
          :error-message="errorMessage.header"
          @update:value="updateValue('header', $event as string)"
          @clear="updateValue('header', '')"
        />
        <div class="blue-rating-rec-general__isKeyRecommendation">
          <BaseCheckbox
            :label="t('blueRating.recommendation.isKeyRecommendation')"
            :disabled="disabled"
            :checked="recommendation.isKeyRecommendation"
            @change="updateValue('isKeyRecommendation', $event as boolean)"
          />
        </div>

        <BaseTextField
          :label="moduleTranslations.location"
          :disabled="disabled"
          :value="recommendation.location"
          :required="module.forceLocation"
          :error-message="errorMessage.location"
          @update:value="updateValue('location', $event as string)"
          @clear="updateValue('location', ' ')"
        />
        <div>
          <BaseTextField
            :label="moduleTranslations.priority"
            :disabled="disabled"
            :value="recommendation.priority"
            :required="module.forcePriority"
            :error-message="errorMessage.priority"
            @update:value="updateValue('priority', $event as string)"
            @clear="updateValue('priority', '')"
          />
        </div>

        <div
          :class="[
            'blue-rating-rec-general__full-span',
            'blue-rating-rec-general__row',
            'blue-rating-rec-general__row--quad',
          ]"
        >
          <BaseSelect
            v-if="!recommendation.isGeneralRecommendation"
            :label="t('blueRating.recommendation.properties.color')"
            :value="recommendation.categoryColorOverride"
            :disabled="disabled"
            :required="!recommendation.isGeneralRecommendation && module.forceOverrideCategoryColor"
            :error-message="errorMessage.categoryColorOverride"
            :blue-color="recommendation.categoryColorOverride"
            @change="updateValue('categoryColorOverride', $event as BlueColor)"
          >
            <BaseOption v-if="!module.forceOverrideCategoryColor" :value="BlueColor.NotSet">
              {{ t("common.blueColors.notSet") }}
            </BaseOption>
            <BaseOption v-for="option in colors" :key="option.value" :value="option.value">
              {{ option.title }}
            </BaseOption>
          </BaseSelect>

          <BaseSelect
            :label="t('blueRating.recommendation.properties.surveyorStatus')"
            :value="recommendation.surveyorStatus"
            :options="surveyorStatusOptions"
            :disabled="survey.status === Status.Published"
            @change="updateValue('surveyorStatus', $event as SurveyorStatus)"
          >
            <BaseOption
              v-for="option in surveyorStatusOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.title }}
            </BaseOption>
          </BaseSelect>

          <BaseSelect
            :label="t('blueRating.recommendation.properties.type')"
            :disabled="disabled"
            :value="recommendation.type"
            :required="module.forceType"
            :error-message="errorMessage.type"
            @change="updateValue('type', $event as string)"
          >
            <BaseOption v-if="!module.forceType" value="">
              {{ t("blueRating.recommendation.noSelectedType") }}
            </BaseOption>
            <BaseOption
              v-for="option in recommendationTypeOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.title }}
            </BaseOption>
          </BaseSelect>
        </div>
        <BaseDateField
          v-if="recommendation.surveyorStatus === SurveyorStatus.Abeyance"
          :label="moduleTranslations.abeyanceDate"
          :value="recommendation.abeyanceDate"
          @update:value="updateValue('abeyanceDate', $event as string)"
        />
        <BaseTextArea
          v-if="recommendation.surveyorStatus === SurveyorStatus.Abeyance"
          :label="moduleTranslations.abeyanceComment"
          :value="recommendation.abeyanceComment"
          @update:value="updateValue('abeyanceComment', $event as string)"
        />
        <BaseDateField
          v-if="recommendation.surveyorStatus === SurveyorStatus.Completed"
          :label="t('blueRating.recommendation.properties.completedDate')"
          :value="recommendation.completedDate"
          @update:value="updateValue('completedDate', $event as string)"
        />
        <BaseTextArea
          v-if="recommendation.surveyorStatus === SurveyorStatus.Completed"
          :label="t('blueRating.recommendation.properties.completedComment')"
          :value="recommendation.completedComment"
          @update:value="updateValue('completedComment', $event as string)"
        />

        <div v-if="spoeCheckComment.length">
          <BaseLabel>{{ t("blueRating.recommendation.properties.spoeCheckComment") }}</BaseLabel>
          <p>
            {{ spoeCheckComment }}
          </p>
        </div>

        <div class="blue-rating-rec-general__full-span blue-rating-rec-general__editor">
          <BaseLabel has-spacing>
            {{ t("blueRating.recommendation.properties.text") }}
          </BaseLabel>
          <BaseEditor
            :custom-height="{ min: '200px' }"
            :disabled="disabled"
            :value="recommendation.text"
            @input="updateValue('text', $event)"
          />
        </div>

        <div class="blue-rating-rec-general__full-span blue-rating-rec-general__editor">
          <BaseLabel has-spacing>
            {{ t("blueRating.recommendation.properties.comment") }}
          </BaseLabel>
          <BaseEditor
            :custom-height="{ min: '100px' }"
            :disabled="disabled"
            :value="recommendation.comment"
            @input="updateValue('comment', $event)"
          />
        </div>
      </div>
    </BaseCard>

    <BaseCard
      v-if="!recommendation.isGeneralRecommendation"
      has-padding
      :title="t('blueRating.recommendation.addRecommendationToMoreQuestions')"
    >
      <BlueRatingRecQuestionList
        :placeholder="t('common.select')"
        :category="getCategoryById(survey!, recommendation.categoryId!)!"
        :survey-rec="recommendation"
        :disabled="disabled"
        @add-question="updateValue('questionIds', [...recommendation.questionIds, $event])"
        @remove="
          updateValue(
            'questionIds',
            recommendation!.questionIds.filter((id: number) => id !== $event),
          )
        "
      />
    </BaseCard>
  </section>
</template>

<style scoped lang="scss">
.blue-rating-rec-general {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing-4;
  margin-top: $spacing-2;

  &__isKeyRecommendation {
    display: flex;
    margin-top: $spacing-4;
  }

  &__row {
    display: grid;
    gap: $spacing-4;
    grid-auto-columns: auto;

    &--quad {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__full-span {
    grid-column: 1 / -1;
  }

  &__editor {
    margin-top: $spacing-3;
  }
}
</style>
