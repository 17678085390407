<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type {
  ExecutiveReportQuestionDto,
  ExecutiveReportReplyDataDto,
} from "@/types/_generated/api";
import { vTruncationTitle } from "@/directives/truncationTitle";
import ExecSummaryCatRecTitle from "./ExecSummaryCatRecTitle.vue";

const props = defineProps<{
  questions: ExecutiveReportQuestionDto[];
  questionData?: ExecutiveReportReplyDataDto[];
}>();

const { t } = useI18n({ useScope: "global" });

const getQuestionReply = (index: number) => props.questionData?.[index];

const getColor = (index: number): string =>
  getQuestionReply(index)?.color.toLowerCase() ?? "not-set";

const getScore = (index: number) => {
  const reply = getQuestionReply(index);
  return `${reply?.score ?? 0}p`;
};
</script>

<template>
  <div>
    <ExecSummaryCatRecTitle>
      {{ t("userWeb.executiveSummary.categoryRecommendations.questions") }}
    </ExecSummaryCatRecTitle>

    <div class="cat-rec-questions">
      <div v-for="(question, index) in questions" :key="index" class="cat-rec-questions__row">
        <div
          class="cat-rec-questions__row__dot"
          :class="`cat-rec-questions__row__dot--${getColor(index)}`"
        ></div>

        <p v-truncation-title class="cat-rec-questions__row__question">
          <span class="cat-rec-questions__row__number">{{ index + 1 }}. </span>
          <span>{{ question.text }}</span>
        </p>

        <p class="cat-rec-questions__row__points">
          {{ getScore(index) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cat-rec-questions {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-2;

    &__number,
    &__points {
      font-weight: $font-medium;
      font-variant-numeric: tabular-nums;
    }

    &__question {
      width: 100%;
    }

    &__dot {
      width: 10px;
      height: 10px;
      border-radius: $rounded-full;
      flex-shrink: 0;

      @include blue-rating-styles;
    }
  }
}
</style>
