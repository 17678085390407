import type { GetRecResponseSummaryResponse, RecResponseSummaryDto } from "@/types/_generated/api";
import http from "@/helpers/http";
import { handleError } from "@/services/shared/handleError";

export const getRecResponseSummaryService = async (): Promise<
  RecResponseSummaryDto | undefined
> => {
  try {
    const {
      data: { recResponseSummary },
    } = await http.get<GetRecResponseSummaryResponse>("/recommendation-response/summary");
    return recResponseSummary;
  } catch (error) {
    handleError(error);
  }
  return undefined;
};
