<script setup lang="ts">
import { BlueColor } from "@/types/_generated/api";

const props = defineProps<{
  score: number;
  text: string;
  color: BlueColor;
  showDescription?: boolean;
}>();
</script>

<template>
  <div class="questionnaire-question-color">
    <div
      class="questionnaire-question-color__score"
      :class="`questionnaire-question-color__score--${color.toLowerCase()}`"
    >
      <p>{{ props.score }}p</p>
    </div>

    <div v-show="showDescription" class="questionnaire-question-color__text">
      <p>{{ props.text }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.questionnaire-question-color {
  display: flex;
  flex-direction: column;
  border: 1px solid $primary-4;
  border-radius: $rounded-base;
  font-size: $text-sm;

  &__score {
    display: flex;
    justify-content: center;
    padding: $spacing-2 $spacing-3;
    width: 100%;
    line-height: $leading-none;

    @include blue-rating-styles;
  }

  &__text {
    line-height: $leading-snug;
    padding: $spacing-2 $spacing-3;
    hyphens: auto;
    word-break: break-word;
  }
}
</style>
