<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { MfaOption } from "@/types/_generated/api";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

defineProps<{
  value: MfaOption;
  errorMessage: string | undefined;
}>();

defineEmits<{
  change: [MfaOption];
}>();

const { t } = useI18n({ useScope: "global" });

const translations = {
  [MfaOption.Email]: t("common.mfa.email"),
  [MfaOption.Sms]: t("common.mfa.sms"),
  [MfaOption.EmailorSms]: t("common.mfa.emailorsms"),
};
</script>

<template>
  <BaseSelect
    :label="t('users.mfaMethod')"
    :value="value"
    :error-message="errorMessage"
    @change="$emit('change', $event as MfaOption)"
  >
    <BaseOption :value="MfaOption.Email">
      {{ translations[MfaOption.Email] }}
    </BaseOption>
    <BaseOption :value="MfaOption.Sms">
      {{ translations[MfaOption.Sms] }}
    </BaseOption>
    <BaseOption :value="MfaOption.EmailorSms">
      {{ translations[MfaOption.EmailorSms] }}
    </BaseOption>
  </BaseSelect>
</template>
