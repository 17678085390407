<script setup lang="ts">
import { Ref, ref } from "vue";
import { vClickOutside } from "@/directives/clickOutside";
import BaseButton, { Props as BaseButtonProps } from "@/components/base/BaseButton.vue";
import BaseIcon, { Props as BaseIconProps } from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseDropdownMenuOption from "./BaseDropdownMenuOption.vue";

export interface MenuOption {
  label: string;
  icon?: BaseIconProps["icon"];
  disabled?: boolean;
  checked?: Ref<boolean> | boolean;
  action: () => void;
}

withDefaults(
  defineProps<{
    title?: string;
    label?: string;
    position?: ["bottom" | "top", "left" | "right"];
    variant?: BaseButtonProps["variant"];
    options: MenuOption[];
    scroll?: boolean;
    disabled?: boolean;
  }>(),
  {
    scroll: false,
    label: "",
    title: "",
    position: () => ["bottom", "right"],
    variant: "text",
  },
);

const isOpen = ref(false);
const toggle = () => (isOpen.value = !isOpen.value);
</script>

<template>
  <div
    v-click-outside="() => (isOpen = false)"
    class="base-dropdown-menu"
    :class="{ 'base-dropdown-menu--disabled': disabled }"
    @keydown.escape="() => (isOpen = false)"
  >
    <slot name="activator" :toggle="toggle">
      <BaseButton
        class="base-dropdown-menu__activator"
        :label="label"
        :title="title"
        :disabled="disabled"
        :variant="variant"
        @click="toggle"
      >
        <slot name="icon">
          <BaseIcon icon="kebab-horizontal" size="medium" />
        </slot>
        {{ label }}
      </BaseButton>
    </slot>

    <div
      v-if="isOpen && !disabled"
      class="base-dropdown-menu__list"
      :class="{
        [`base-dropdown-menu__list--${position[0]}`]: true,
        [`base-dropdown-menu__list--${position[1]}`]: true,
        'base-dropdown-menu__list--scroll': scroll,
      }"
    >
      <template v-for="(option, i) in options" :key="i">
        <BaseDropdownMenuOption
          :option="option"
          @click="
            () => {
              if (option.checked === undefined) {
                isOpen = false;
              }

              option.action();
            }
          "
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-dropdown-menu {
  position: relative;
  display: block;

  &__activator {
    margin: auto;
    text-align: center;
    width: 100%;
  }

  &__list {
    position: absolute;
    background: $primary-0;
    border: 1px solid $primary-5;
    border-radius: $rounded-base;
    box-shadow: 0 4px 6px -2px rgba(54, 64, 73, 0.1);
    z-index: 100;
    margin-top: $spacing-2;

    &--top {
      bottom: 100%;
      margin-bottom: $spacing-2;
    }

    &--bottom {
      top: 100%;
      margin-top: $spacing-2;
    }

    &--left {
      right: 0;
    }

    &--right {
      left: 0;
    }

    &--scroll {
      max-height: 20rem;
      overflow-y: auto;
    }
  }
}
</style>
