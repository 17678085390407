<script setup lang="ts">
import { useI18n } from "vue-i18n";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import ModulesTable from "./components/ModulesTable.vue";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <AdminHeader :breadcrumbs="[{ title: t('navigation.modules') }]" />

  <AdminViewLayout>
    <ModulesTable show-client-name />
  </AdminViewLayout>
</template>
