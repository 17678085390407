<script setup lang="ts">
import { useTemplateRef } from "vue";
import { convertFiles } from "@/helpers/convertFiles";
import { useImageUpload } from "../composables/useImageUpload";

const emit = defineEmits<{
  upload: [string];
}>();

const input = useTemplateRef("input");

defineExpose({ input });

const { handleImageUpload } = useImageUpload();

const onUpload = async (event: Event) => {
  const fileList = (event.target as HTMLInputElement)?.files;

  if (!fileList || !fileList.length) {
    return;
  }

  const files = await convertFiles(fileList);
  const base64 = await handleImageUpload(files);
  emit("upload", base64);

  // Reset the file input after successful upload
  (event.target as HTMLInputElement).value = "";
};
</script>

<template>
  <input ref="input" class="base-editor-image-upload" type="file" @input="onUpload" />
</template>

<style lang="scss" scoped>
.base-editor-image-upload {
  display: none;
}
</style>
