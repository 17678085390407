import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  ModulePermissionDto,
  GetModulePermissionsResponse,
  UserModulePermissionDto,
  GetUserModulePermissionsResponse,
  SitePermissionDto,
  GetSitePermissionsResponse,
  UserSitePermissionDto,
  GetUserSitePermissionsResponse,
  SitePermissionFilterDto,
  EvaluateSitePermissionFilter,
  EvaluatedSitePermissionDto,
  SaveModulePermissionDto,
  GetModulePermissions,
} from "@/types/_generated/api";
import { SiteDefinition } from "@/types/SiteDefinitions";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const modulePermissionEvents = new BroadcastService("module-permissions");
export const sitePermissionEvents = new BroadcastService("site-permissions");

export const getModulePermissions = async (
  req: GetModulePermissions,
): Promise<ModulePermissionDto[] | null> => {
  try {
    const {
      data: { modulePermissions },
    } = await http.get<GetModulePermissionsResponse>(
      `/modules/${req.moduleId}/module-permissions`,
      { params: { onlyActive: req.onlyActive } },
    );
    return modulePermissions;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("modules.modulePermissions"),
    });
  }
  return null;
};

export const getUserModulePermissions = async (
  userId: number,
): Promise<UserModulePermissionDto[] | null> => {
  try {
    const {
      data: { modulePermissions },
    } = await http.get<GetUserModulePermissionsResponse>(`/users/${userId}/module-permissions`);
    return modulePermissions;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("modules.modulePermissions"),
    });
  }
  return null;
};

export const getSitePermissions = async (
  siteId: number,
  userId?: number,
): Promise<SitePermissionDto[] | null> => {
  try {
    const {
      data: { sitePermissions },
      // Should be
    } = await http.get<GetSitePermissionsResponse>(`/sites/${siteId}/site-permissions`, {
      params: { userId },
    });
    return sitePermissions;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("users.permissions.sitePermissions"),
    });
  }
  return null;
};

export const getUserSitePermissons = async (
  userId: number,
): Promise<UserSitePermissionDto[] | null> => {
  try {
    const {
      data: { sitePermissions },
    } = await http.get<GetUserSitePermissionsResponse>(`/users/${userId}/site-permissions`);
    return sitePermissions;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("users.permissions.sitePermissions"),
    });
  }
  return null;
};

export const getEffectiveSiteDefinitions = async (
  moduleId: number,
  siteId?: number,
  userId?: number,
): Promise<SiteDefinition[] | null> => {
  try {
    const {
      data: { siteDefinitions },
    } = await http.get<{ siteDefinitions: SiteDefinition[] }>(
      `/modules/${moduleId}/effective-site-definitions`,
      {
        params: { siteId, userId },
      },
    );
    return siteDefinitions;
  } catch (error) {
    handleError(error);
  }
  return null;
};

export const getSitePermissionFilter = async (
  userId: number,
  moduleId: number,
): Promise<SitePermissionFilterDto[] | null> => {
  try {
    const {
      data: { filter },
    } = await http.get<{ filter: SitePermissionFilterDto[] }>(
      `/users/${userId}/site-permission-filter/${moduleId}`,
    );
    return filter;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("users.permissions.sitePermissionFilters"),
    });
  }
  return null;
};

export const evaluateSitePermissionFilter = async (
  data: EvaluateSitePermissionFilter,
): Promise<EvaluatedSitePermissionDto[] | null> => {
  try {
    const {
      data: { sitePermissions },
    } = await http.post<{ sitePermissions: EvaluatedSitePermissionDto[] }>(
      `/modules/${data.moduleId}/evaluate-site-permission-filter`,
      data,
    );
    return sitePermissions || null;
  } catch (error) {
    handleError(error);
  }
  return null;
};

export const saveUserModulePermissions = async (
  userId: number,
  modulePermissions: SaveModulePermissionDto[],
) => {
  try {
    await http.put(`/users/${userId}/module-permissions`, {
      modulePermissions,
    });
    notify.success(t("modules.modulePermissions"), t("common.updated"));
    modulePermissionEvents.post({ action: "update", id: undefined });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("modules.modulePermissions"),
    });
  }
};

export const saveUserSitePermissions = async (
  userId: number,
  moduleId: number,
  explicitPermissions: number[],
) => {
  try {
    await http.post(`/modules/${moduleId}/site-permissions/users/${userId}`, {
      moduleId,
      explicitPermissions,
    });
    notify.success(t("users.permissions.sitePermissions"), t("common.updated"));
    sitePermissionEvents.post({ action: "update", id: undefined });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("users.permissions.sitePermissions"),
    });
  }
};

export const saveSitePermissionFilter = async (
  userId: number,
  moduleId: number,
  filter: SitePermissionFilterDto[],
) => {
  try {
    await http.put(`/users/${userId}/site-permission-filter/${moduleId}`, {
      filter,
    });

    notify.success(t("users.permissions.sitePermissions"), t("common.updated"));
    sitePermissionEvents.post({ action: "update", id: undefined });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("users.permissions.sitePermissionFilters"),
    });
  }
};

export const deleteSitePermissionFilter = async (userId: number, moduleId: number) => {
  try {
    await http.delete(`/users/${userId}/site-permission-filter/${moduleId}`);

    notify.success(t("users.permissions.sitePermissions"), t("common.updated"));
    sitePermissionEvents.post({ action: "update", id: undefined });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("users.permissions.sitePermissionFilters"),
    });
  }
};
