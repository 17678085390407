import { ref } from "vue";
import { getCurrentQuestionnaire, getQuestionnaire } from "@/services/questionnaire";
import { useQuestionnaireStore } from "@/stores/questionnaire";

// Abstracts the logic for fetching a questionnaire OR questionnaire template
export const useFetchQuestionnaire = () => {
  const store = useQuestionnaireStore();
  const isLoadingQuestionnaire = ref<boolean>(false);

  const fetchQuestionnaire = async (moduleId: number | null, questionnaireId: number | null) => {
    isLoadingQuestionnaire.value = true;

    // If it's a module questionnaire
    if (moduleId) {
      store.questionnaire = await getCurrentQuestionnaire({
        moduleId,
      });
      // If it's a questionnaire template
    } else if (questionnaireId) {
      store.questionnaire = await getQuestionnaire(questionnaireId!);
    } else {
      throw new Error("No moduleId or questionnaireId provided");
    }

    isLoadingQuestionnaire.value = false;
  };

  return {
    fetchQuestionnaire,
    isLoadingQuestionnaire,
  };
};
