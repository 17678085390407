import { ref, watch } from "vue";
import type { StorageSchema, TableSettings } from "@/composables/useStorage/StorageSchema";
import { useStorage } from "@/composables/useStorage/useStorage";

const defaultTableSettings: Readonly<TableSettings> = {
  rowsPerPage: 25,
  truncateTableValues: true,
};

export const useTableSettingStorage = (key: keyof StorageSchema | undefined) => {
  // Storage is not available if no key is provided or if the browser does not support storage
  const storage = key ? useStorage() : undefined;

  // Load settings from storage or use a blank object
  const savedSetting = (key ? storage?.get(key) : {}) ?? {};

  // Combine the default settings with the saved settings (if any)
  const settings = ref<TableSettings>(Object.assign({ ...defaultTableSettings }, savedSetting));

  // Watch for changes to the settings and save them to storage if possible
  watch(settings.value, (value) => {
    if (key && storage) {
      storage.set(key, value);
    }
  });

  return { settings };
};
