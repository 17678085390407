<script setup lang="ts" generic="T">
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseButton from "../base/BaseButton.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  item: T;
  isRemove: (item: T) => boolean;
}>();

defineEmits<{
  (e: "add", item: T): void;
  (e: "remove", item: T): void;
}>();
</script>

<template>
  <BaseButton
    v-if="!isRemove(item as T)"
    :title="t('users.permissions.addModulePermission')"
    variant="text"
    @click="$emit('add', item as T)"
  >
    <BaseIcon icon="plus" />
  </BaseButton>

  <BaseButton
    v-else
    :title="t('users.permissions.removeModulePermission')"
    variant="text"
    @click="$emit('remove', item as T)"
  >
    <BaseIcon icon="trash" />
  </BaseButton>
</template>
