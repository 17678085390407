<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58173 3.58173 0 8 0C12.4183 0 16 3.58173 16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6233 5.09948C10.9825 5.30563 11.1067 5.76399 10.9005 6.12326L8.60691 10.1206C7.90206 11.3488 6.10636 11.275 5.50474 9.99303L5.07101 9.06856C4.89507 8.69357 5.05644 8.24695 5.43144 8.07102C5.80643 7.89508 6.25304 8.05645 6.42898 8.43144L6.86265 9.35578C6.86266 9.3558 6.86264 9.35576 6.86265 9.35578C6.94865 9.53891 7.20513 9.54952 7.30587 9.37404C7.30588 9.37403 7.30587 9.37405 7.30587 9.37404L9.59947 5.37674C9.80562 5.01747 10.264 4.89333 10.6233 5.09948Z"
    />
  </svg>
</template>
