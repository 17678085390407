<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon, { type Props as BaseIconProps } from "@/components/base/BaseIcon/BaseIcon.vue";
import type { Section, UpdateFormField } from "../types";
import { MoveDirection } from "../constants";

type MenuOption = {
  title: string;
  icon: BaseIconProps["icon"];
  disabled?: boolean;
  hidden?: boolean;
  action: () => void;
};

const props = defineProps<{
  numberOfSections: number;
  section: Section;
}>();

const emit = defineEmits<{
  "update:form-field": [UpdateFormField];
  "move:section": [MoveDirection];
  "open:section": [void];
}>();

const { t } = useI18n({ useScope: "global" });

const menuOptions = computed((): MenuOption[] => [
  {
    title: t("common.actions.moveUp"),
    icon: "chevron-up",
    disabled: props.section.position === 2,
    hidden: props.section.position === 1,
    action: () => emit("move:section", MoveDirection.Up),
  },
  {
    title: t("common.actions.moveDown"),
    icon: "chevron-down",
    disabled: props.section.position === props.numberOfSections,
    hidden: props.section.position === 1,
    action: () => emit("move:section", MoveDirection.Down),
  },
  {
    title: props.section.isVisible
      ? t("module.recReportSettings.hideSection")
      : t("module.recReportSettings.showSection"),
    icon: props.section.isVisible ? "eye" : "eye-closed",
    action: () =>
      emit("update:form-field", {
        sectionKey: props.section.key,
        key: "isVisible",
        value: !props.section.isVisible,
      }),
  },
  {
    title: t("common.actions.edit"),
    icon: "settings",
    action: () => emit("open:section"),
  },
]);
</script>

<template>
  <div class="rec-report-section" :class="{ 'rec-report-section--hidden': !section.isVisible }">
    <p v-truncation-title class="rec-report-section__title">
      {{ section.sectionLabel }}
    </p>

    <div class="rec-report-section__buttons">
      <BaseButton
        v-for="option in menuOptions.filter((option) => !option.hidden)"
        :key="option.title"
        variant="text"
        size="small"
        :disabled="option.disabled"
        :title="option.title"
        @click="option.action()"
      >
        <BaseIcon :icon="option.icon" />
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rec-report-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4;
  background-color: $primary-2;
  padding: $spacing-2 $spacing-3;
  border: 1px solid $primary-5;
  border-radius: $rounded-base;
  transition: background-color $duration-100;

  &--hidden {
    color: $primary-6;
    background-color: transparent;
    border: 1px solid $primary-4;
  }

  &__title {
    font-size: $text-sm;
  }

  &__buttons {
    display: flex;
    gap: $spacing-2;
  }
}
</style>
