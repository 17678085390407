<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { UpdateFormField, ValidationErrors } from "@/composables/validation/types";
import { languages } from "@/helpers/options";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { type UserForm } from "./types";

defineProps<{
  errorMessage: ValidationErrors<UserForm>;
  userForm: UserForm;
}>();

defineEmits<{
  "update:field": UpdateFormField<UserForm>;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="user-edit-personal">
    <BaseDataDisplayListTitle>{{ t("users.personalDetails") }}</BaseDataDisplayListTitle>

    <div class="user-edit-personal__fields user-edit-personal__fields--two-columns">
      <BaseTextField
        required
        :label="t('common.firstName')"
        :error-message="errorMessage.firstName"
        :value="userForm.firstName"
        @update:value="$emit('update:field', 'firstName', String($event))"
      />

      <BaseTextField
        required
        :label="t('common.lastName')"
        :error-message="errorMessage.lastName"
        :value="userForm.lastName"
        @update:value="$emit('update:field', 'lastName', String($event))"
      />
    </div>

    <div class="user-edit-personal__fields user-edit-personal__fields--three-columns">
      <BaseTextField
        :label="t('common.phone')"
        :error-message="errorMessage.phoneNumber"
        :value="userForm.phoneNumber"
        @update:value="$emit('update:field', 'phoneNumber', String($event))"
      />

      <BaseTextField
        :label="t('common.mobile')"
        :error-message="errorMessage.mobilePhoneNumber"
        :value="userForm.mobilePhoneNumber"
        @update:value="
          $emit('update:field', 'mobilePhoneNumber', String($event).replace(/\s/g, ''))
        "
      />

      <BaseSelect
        :label="t('common.language')"
        :value="userForm.language"
        @change="$emit('update:field', 'language', $event)"
      >
        <BaseOption v-for="option in languages" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-edit-personal {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__fields {
    display: grid;
    gap: $spacing-4;

    &--two-columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &--three-columns {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
