import { computed } from "vue";
import { authorize } from "@/plugins/can";
import { Role } from "@/types/_generated/api";

export const useHasReportMailPermission = (moduleId: number) => {
  const hasReportMailPermission = computed(() => {
    if (authorize.hasRole(Role.EXT) && authorize.hasAllowRule("allowReportMail")) {
      return true;
    }

    if (authorize.hasModulePermission(moduleId, "editSurvey")) {
      return true;
    }

    return false;
  });

  return { hasReportMailPermission };
};
