import { computed, Ref } from "vue";
import { BlueTrendResultDto } from "@/types/_generated/api";
import { FilterChartOptions } from "../constants/FilterChartOptions";
import { DaatClientFilter } from "./useFilterClient";

export const useBlueTrendChartData = (
  data: Ref<BlueTrendResultDto[]>,
  clientFilter: Ref<DaatClientFilter>,
) => {
  const series = computed(() =>
    data.value.map((br) => ({
      data: [...years.value].map((year) => {
        const dataPoint = br.dataPoints.find((dp) => dp.year === year);

        const value = clientFilter.value.selectedChartOptions.has(FilterChartOptions.Percentage)
          ? dataPoint?.average
          : dataPoint?.averageScore;

        return value ?? null;
      }),
      type: "line",
      connectNulls: true,
      name: br.group,
      symbolSize: 15,
    })),
  );

  const years = computed(() => {
    if (data.value.length === 0) {
      return [];
    }

    const yearsInData = data.value
      .map((br) => br.dataPoints.map((dp) => dp.year))
      .flat()
      .sort();

    const [firstYear] = yearsInData;
    const lastYear = yearsInData[yearsInData.length - 1];

    const years = [];

    for (let year = firstYear; year <= lastYear; year++) {
      years.push(year);
    }

    return years;
  });

  return {
    years,
    series,
  };
};
