<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CategoryDto } from "@/types/_generated/api";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  category: CategoryDto;
  color: "blue" | "green" | "yellow";
}>();

const calculateTotalColorScore = (
  category: CategoryDto,
  color: "blue" | "green" | "yellow",
): string => {
  let totalScore = 0;

  category.questions.forEach((question) => {
    totalScore += question[`${color}Score`];
  });

  return `${color} ${t("questionnaire.draft.categoryLimit")} ${totalScore}`;
};
</script>
<template>
  <span class="questionnaire-draft-category-limit">
    {{ calculateTotalColorScore(props.category, props.color) }}
  </span>
</template>

<style lang="scss" scoped>
.questionnaire-draft-category-limit {
  text-transform: capitalize;
  text-align: center;
  color: $primary-6;
  line-height: $leading-normal;
  margin-top: -1px;
}
</style>
