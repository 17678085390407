<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { ref, computed, reactive, watch, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import {
  email as emailValidator,
  mobile as mobileValidator,
  required,
} from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDateField from "@/components/base/BaseDateField.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { ReportMailRecipient } from "../types";

const props = defineProps({
  incomingRecipient: {
    type: Object as PropType<ReportMailRecipient>,
    required: false,
    default: null,
  },
  isEditingForm: {
    type: Boolean,
  },
  isMobileRequired: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits<{
  "edit:recipient": [void];
  "confirm:recipient": [ReportMailRecipient];
}>();

const { t } = useI18n({ useScope: "global" });

const recipientForm = reactive<ReportMailRecipient>({
  email: "",
  mobile: "",
  includeRecipient: false,
  includeRecommendationResponseLink: false,
  reminderDates: [],
  continueRemindingWhenSiteResponseIsGiven: false,
});

const dateFieldRef = ref<InstanceType<typeof BaseDateField> | null>(null);

const v$ = useVuelidate(
  {
    email: { email: emailValidator, required },
    mobile: { mobile: mobileValidator, required: props.isMobileRequired ? required : false },
  },
  recipientForm,
);

const emailDescription = computed(() => {
  const nordicChars = /[åäöÅÄÖ]/;
  if (nordicChars.test(recipientForm.email)) {
    return t("validations.emailContainsNordicChars");
  }
  return "";
});

const confirmRecipient = () => {
  // Validate the form
  v$.value.$validate();
  if (v$.value.$invalid) return;

  // Emit the recipient
  emits("confirm:recipient", { ...recipientForm });

  // Reset the form
  recipientForm.email = "";
  recipientForm.mobile = "";
  recipientForm.includeRecommendationResponseLink = false;
  recipientForm.reminderDates = [];
  recipientForm.continueRemindingWhenSiteResponseIsGiven = false;

  // Reset the validation state
  v$.value.$reset();
};

const addReminderDate = (date: string) => {
  recipientForm.reminderDates = [...recipientForm.reminderDates, date];
  dateFieldRef.value?.clearInput(); // Clear the input using the exposed method
};

const removeReminderDate = (index: number) => {
  recipientForm.reminderDates.splice(index, 1);
};

watch(
  () => props.incomingRecipient,
  (newRecipient) => Object.assign(recipientForm, newRecipient),
  { deep: true, immediate: true },
);

const updateEmail = (value: string) => {
  recipientForm.email = value;
  v$.value.$touch();
};

const updateMobile = (value: string) => {
  recipientForm.mobile = value;
  v$.value.$touch();
};
</script>

<template>
  <form class="report-mail-recipient-form" @submit.prevent="confirmRecipient">
    <div class="report-mail-recipient-form__contact-info">
      <BaseTextField
        :label="t('common.email')"
        :description="emailDescription"
        :errors="v$.email.$errors"
        :value="recipientForm.email"
        @update:value="updateEmail(String($event))"
      />

      <BaseTextField
        :label="t('common.mobile')"
        :errors="v$.mobile.$errors"
        :value="recipientForm.mobile"
        @update:value="updateMobile(String($event))"
      />
    </div>

    <BaseCheckbox
      :label="t('survey.surveyReportMail.includeRedommendationResponseLink')"
      :checked="recipientForm.includeRecommendationResponseLink"
      @change="recipientForm.includeRecommendationResponseLink = $event"
    />

    <BaseDateField
      v-if="recipientForm.includeRecommendationResponseLink"
      ref="dateFieldRef"
      :label="t('survey.surveyReportMail.addReminderDates')"
      :disabled="recipientForm.reminderDates.length == 4"
      @change:value="addReminderDate(String($event))"
    />

    <div
      v-if="recipientForm.reminderDates.length"
      class="report-mail-recipient-form__reminder-dates"
    >
      <BasePill
        v-for="(date, i) in recipientForm.reminderDates"
        :key="i"
        clickable
        :label="date"
        @click="removeReminderDate(i)"
      />
    </div>

    <BaseCheckbox
      v-if="recipientForm.includeRecommendationResponseLink && recipientForm.reminderDates.length"
      :label="t('survey.surveyReportMail.continueRemindingWhenSiteResponseIsGiven')"
      :checked="recipientForm.continueRemindingWhenSiteResponseIsGiven"
      @change="recipientForm.continueRemindingWhenSiteResponseIsGiven = $event"
    />

    <div class="report-mail-recipient-form__buttons">
      <BaseButton type="submit" :variant="isEditingForm ? 'contained' : 'outlined'">
        {{
          isEditingForm
            ? t("survey.surveyReportMail.updateRecipient")
            : t("survey.surveyReportMail.addRecipient")
        }}
      </BaseButton>
      <BaseButton v-if="isEditingForm" variant="outlined" @click="$emit('edit:recipient')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </div>
  </form>
</template>

<style scoped lang="scss">
.report-mail-recipient-form {
  display: grid;
  gap: $spacing-4;
  width: 100%;

  &__contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-3;
  }

  &__reminder-dates {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-2;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
  }
}
</style>
