export const getIconForFileType = (fileName?: string) => {
  // Handle empty fileName
  if (!fileName) return "file";

  const extension = fileName.split(".").pop()?.toLowerCase();

  switch (extension) {
    case "pdf":
      return "pdf";
    case "doc":
    case "docx":
      return "word";
    case "xls":
    case "xlsx":
      return "excel";
    default:
      return "file";
  }
};
