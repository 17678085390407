<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { RecRespRouteName } from "@/router/RouteName";
import { MfaMethod } from "@/types/_generated/api";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import { getMfaOptionsService, hasSessionService } from "../services/auth";

const { t } = useI18n({ useScope: "global" });
const router = useRouter();

const emit = defineEmits<{
  mfaOptionsReceived: [MfaMethod[]];
}>();

const isValidating = ref(false);
const isInvalidParam = ref(false);

const validateLinkId = (): string => {
  const { linkId } = router.currentRoute.value.query;
  if (!linkId || typeof linkId !== "string") {
    throw new Error("Missing or invalid linkId");
  }
  return linkId;
};

const verifyAndFetchMfa = async () => {
  isValidating.value = true;
  isInvalidParam.value = false;

  try {
    const linkId = validateLinkId();
    const mfaOptions = await getMfaOptionsService(linkId);
    if (!mfaOptions) {
      isInvalidParam.value = true;
      return;
    }

    emit("mfaOptionsReceived", mfaOptions.mfaOptions);
  } finally {
    isValidating.value = false;
  }
};

onMounted(async () => {
  if (await hasSessionService()) {
    router.push({ name: RecRespRouteName.Summary });
    return;
  }

  verifyAndFetchMfa();
});
</script>

<template>
  <div>
    <BaseSpinner v-if="isValidating" />

    <BaseAlert v-else-if="isInvalidParam" severity="error">
      <p>{{ t("recResponse.validation.invalidParam") }}</p>
    </BaseAlert>
  </div>
</template>
