import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { SaveRiskManagerComments } from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const saveRiskManagerComments = async (
  settings: SaveRiskManagerComments,
): Promise<boolean> => {
  try {
    await http.put(`/surveys/${settings.surveyId}/riskmanagercomments`, settings);

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("survey.riskManagerComments"),
    });
  }
  return false;
};
