import { computed, type Ref } from "vue";
import { useRouter } from "vue-router";
import type { DashboardTableLink } from "@/components/shared/Dashboard/types";

interface UseDashboardTableOptions<T extends object> {
  rows: Ref<T[]>;
  sortBy?: Ref<keyof T>;
}

export const useDashboardTable = <T extends object>(options: UseDashboardTableOptions<T>) => {
  const router = useRouter();

  const sortedRows = computed(() => {
    if (!options.sortBy?.value) return options.rows.value; // No sorting

    // Sort descending
    return options.rows.value.toSorted((a, b) => {
      const aValue = a[options.sortBy?.value as keyof T];
      const bValue = b[options.sortBy?.value as keyof T];
      return aValue < bValue ? 1 : -1 || 0;
    });
  });

  const getRouterLink = (row: T & Partial<DashboardTableLink>) => {
    return row.routerLink;
  };

  const handleRowClick = (row: T & Partial<DashboardTableLink>) => {
    const routerLink = getRouterLink(row);
    if (routerLink) {
      router.push(routerLink);
    }
  };

  const getDisplayValue = (row: T & Partial<DashboardTableLink>, key: keyof T) => {
    if (key === "routerLink") return "";
    return row[key];
  };

  return {
    sortedRows,
    getRouterLink,
    handleRowClick,
    getDisplayValue,
  };
};
