<script setup lang="ts">
import { computed, ref } from "vue";
import {
  BlueRatingNavigation,
  BlueRating,
  BlueRatingArea,
  BlueRatingCategory,
} from "@/types/Survey";
import AppOverflowContainer from "@/components/app/AppOverflowContainer.vue";
import BlueRatingNavChevron from "./BlueRatingNavChevron.vue";
import BlueRatingNavLeaf from "./BlueRatingNavLeaf.vue";
import BlueRatingNavProgress from "./BlueRatingNavProgress.vue";
import BlueRatingNavFoldingButton from "./Controls/BlueRatingNavFoldingButton.vue";
import BlueRatingNavToggle from "./Controls/BlueRatingNavToggle.vue";
import BlueRatingNavToggleLegendButton from "./Controls/BlueRatingNavToggleLegendButton.vue";

defineProps<{
  survey: BlueRating;
  currentCategoryId?: number;
  currentQuestionId?: number;
  isVisible: boolean;
}>();

defineEmits<{
  (event: "navigate"): void;
  (event: "toggle-visible"): void;
  (event: "collapse-or-expand-all", value: boolean): void;
  (
    event: "toggle-expanded",
    value: {
      pointer: BlueRatingNavigation;
      value: boolean;
    },
  ): void;
}>();

const isExpanded = (item: BlueRatingArea | BlueRatingCategory) =>
  item.expanded || item.expanded === undefined;

const showProgressLegend = ref(false);

const controlsHeight = computed(() => {
  const height = showProgressLegend.value ? 9.6 : 6.05;

  return `${height}rem` as `${number}rem`;
});
</script>

<template>
  <div class="blue-rating-nav" :class="{ 'blue-rating-nav--hidden': !isVisible }">
    <div class="blue-rating-nav__controls">
      <BlueRatingNavToggleLegendButton
        v-if="isVisible"
        :show-legend="showProgressLegend"
        @toggle="showProgressLegend = !showProgressLegend"
      />
      <BlueRatingNavFoldingButton
        v-if="isVisible"
        :folded="!survey.expanded"
        @fold="$emit('collapse-or-expand-all', false)"
        @unfold="$emit('collapse-or-expand-all', true)"
      />
      <BlueRatingNavToggle
        class="blue-rating-nav__controls__nav-toggle"
        :show="isVisible"
        @toggle="$emit('toggle-visible')"
      />
    </div>

    <transition name="fade" appear>
      <div v-if="isVisible" class="blue-rating-nav__progress">
        <BlueRatingNavProgress :survey="survey" :show-legend="showProgressLegend" />
      </div>
    </transition>
    <AppOverflowContainer
      :remove-navigation="true"
      :remove-custom="controlsHeight"
      :overflow="isVisible ? 'auto' : 'hidden'"
    >
      <transition name="menu" appear>
        <div v-if="isVisible" class="blue-rating-nav__grid">
          <template v-for="(area, ai) in survey.areas" :key="ai">
            <!-- AREA -->
            <div class="blue-rating-nav__grid__area" :title="area.name">
              <div class="blue-rating-nav__grid__area__collapse">
                <BlueRatingNavChevron
                  :expanded="area.expanded"
                  :show-expand="!!area.categories.length"
                  @expand="
                    $emit('toggle-expanded', {
                      pointer: {
                        areaId: area.areaId,
                      },
                      value: $event,
                    })
                  "
                />
              </div>
              <span class="blue-rating-nav__grid__area__name__position">
                {{ area.position }}
              </span>
              <span class="blue-rating-nav__grid__area__name__text">
                <a :href="`#area_${area.areaId}`" @click="$emit('navigate')">{{ area.name }}</a>
              </span>
            </div>

            <template v-for="(category, ci) in area.categories" :key="ci">
              <template v-if="isExpanded(area)">
                <!-- CATEGORY -->
                <div><!--area pos-->&nbsp;</div>
                <div class="blue-rating-nav__grid__category__collapse">
                  <BlueRatingNavChevron
                    :expanded="category.expanded"
                    :show-expand="!!category.questions.length"
                    @expand="
                      $emit('toggle-expanded', {
                        pointer: {
                          areaId: area.areaId,
                          categoryId: category.categoryId,
                        },
                        value: $event,
                      })
                    "
                  />
                </div>

                <BlueRatingNavLeaf
                  :hash="`#category_${category.categoryId}`"
                  :position="category.fullPosition"
                  :label="category.name"
                  :value="category.color"
                  :recommendation-count="category.recommendations.length"
                  :selected="category.categoryId === currentCategoryId"
                  @click="$emit('navigate')"
                />

                <template v-for="(question, qi) in category.questions" :key="qi">
                  <template v-if="isExpanded(category)">
                    <div><!--collapse pos-->&nbsp;</div>
                    <div><!--area pos-->&nbsp;</div>
                    <div><!--category pos-->&nbsp;</div>
                    <BlueRatingNavLeaf
                      :hash="`#question_${question.questionId}`"
                      :is-visible="isExpanded(area) && isExpanded(category)"
                      :position="question.fullPosition"
                      :label="question.text"
                      :value="question.value"
                      :recommendation-count="question.recommendations.length"
                      :selected="question.questionId === currentQuestionId"
                      is-question
                      @click="$emit('navigate')"
                    />
                  </template>
                </template>
              </template>
            </template>
          </template>
        </div>
      </transition>
    </AppOverflowContainer>
  </div>
</template>

<style lang="scss" scoped>
.blue-rating-nav {
  width: 360px;
  transition: width 0.2s ease-in-out;
  min-height: 100%;

  $lineHeight: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  &--hidden {
    overflow-y: hidden;
    transition: width 0.2s ease-in-out;
    width: 45px;
  }

  &__controls {
    height: 3.125rem;
    display: grid;
    grid-template-columns: auto auto 1fr;
    background-color: $primary-3;
    align-items: center;
    padding-left: $spacing-1;
    padding-right: $spacing-1;
    border-bottom: 1px solid $primary-3;

    &__nav-toggle {
      justify-self: right;
    }
  }

  &__progress {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    padding: $spacing-4;
    gap: $spacing-4;
  }

  &__grid {
    display: grid;
    padding: $spacing-3;
    overflow-x: hidden;
    align-items: center;
    scrollbar-gutter: stable;
    grid-template-columns: auto auto auto auto 1fr $lineHeight;

    // row name
    &__area {
      display: contents;
      box-sizing: content-box;
      line-height: $lineHeight;
      cursor: pointer;

      & > * {
        margin-bottom: $spacing-6;
      }

      &:not(:first-of-type) > * {
        margin-top: $spacing-6;
      }

      &__name {
        &__position {
          text-align: center;
          min-width: 30px;
        }

        &__text {
          font-size: $text-xl;
          grid-column: span 4;
          line-height: $lineHeight;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          a {
            display: block;
            text-decoration: none;
          }
        }
      }
    }

    &__area__collapse,
    &__category__collapse {
      & > * {
        color: $primary-5;
        &:hover {
          color: $primary-7;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
