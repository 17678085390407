<script setup lang="ts">
import { onBeforeMount, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useSearchStandardRecommendations } from "@/composables/services/useSearchStandardRecommendations";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useTableFilter } from "@/composables/useTableFilter";
import { sdtRecommendationEvents } from "@/services/standardRecommendations";
import { StandardRecommendationFilter } from "@/types/_generated/api";
import { useStandardRecommendationTableColumns } from "@/views/Admin/BlueRating/composables/useStandardRecommendationTableColumns";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  filter: StandardRecommendationFilter;
}>();

defineEmits<{
  "update:selected": [standardRecommendationId: number];
  "update:includeAllClients": [includeAllClients: boolean];
}>();

const { standardRecommendations, searchStandardRecommendations, isLoadingStandardRecommendations } =
  useSearchStandardRecommendations();

useBroadcastService(
  sdtRecommendationEvents,
  async () => await searchStandardRecommendations(props.filter),
);

const { columns } = useStandardRecommendationTableColumns(
  toRef(() => props.filter.includeAllClients),
);

const { searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys, result } =
  useTableFilter(standardRecommendations, columns);

watch(
  () => props.filter,
  async () => await searchStandardRecommendations(props.filter),
);

onBeforeMount(async () => await searchStandardRecommendations(props.filter));
</script>

<template>
  <BaseGridTable
    enable-row-click
    :rows="result.result"
    :is-loading="isLoadingStandardRecommendations"
    :columns="columns"
    @click:row="$emit('update:selected', $event.standardRecommendationId)"
  >
    <template #controls>
      <div class="add-from-standard-rec-table__filter">
        <BaseFilter
          :search-phrase="searchPhrase"
          :search-key="searchKey"
          :search-keys="searchKeys"
          @update:search-phrase="setSearchPhrase($event)"
          @update:search-key="setSearchKey($event)"
        />
      </div>
    </template>

    <template #control-actions>
      <div class="add-from-standard-rec-table__include-all-clients">
        <BaseCheckbox
          :label="t('standardRecommendations.includeAllAlients')"
          :checked="filter.includeAllClients ?? false"
          @change="$emit('update:includeAllClients', $event)"
        />
      </div>
    </template>

    <template #column-categories="{ row }">
      <template v-if="row.categories && row.categories.length > 0">
        <template v-for="c in row.categories" :key="c">
          <BasePill :label="c" />
        </template>
      </template>
      <template v-else> &nbsp; </template>
    </template>
    <template #column-questions="{ row }">
      <template v-if="row.questions && row.questions.length > 0">
        <template v-for="q in row.questions" :key="q">
          <BasePill :label="q" />
        </template>
      </template>
      <template v-else> &nbsp; </template>
    </template>
  </BaseGridTable>
</template>

<style scoped lang="scss">
.add-from-standard-rec-table {
  &__filter {
    padding-left: $spacing-4;
  }

  &__include-all-clients {
    padding-right: $spacing-4;
  }
}
</style>
