<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { LossScenarioDto, SurveyInsuredValueDto } from "@/types/_generated/api";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  insuredValue: SurveyInsuredValueDto;
  lossScenario: LossScenarioDto;
  currencyCode: string;
}>();

type InsuredValueType = keyof Omit<SurveyInsuredValueDto, "lossScenarioId">;
export type InsuredValueUpdate = { type: InsuredValueType; value: number };

const emit = defineEmits<{
  update: [InsuredValueUpdate];
}>();

const update = (type: InsuredValueType, value: number) => {
  emit("update", { type, value });
};
</script>

<template>
  <div class="survey-edit-drawer__insured-value">
    <span>&nbsp;</span>
    <span>{{ t("modules.propertyDamage") }}</span>
    <span>{{ t("modules.businessInterruption") }}</span>
    <span>&nbsp;</span>
    <span class="survey-edit-drawer__insured-value__loss-scenario-name">
      {{ lossScenario.name }}
    </span>
    <BaseTextField
      type="number"
      :value="insuredValue.propertyDamage"
      @update:value="update('propertyDamage', $event as number)"
    />
    <BaseTextField
      type="number"
      :value="insuredValue.businessInterruption"
      @update:value="update('businessInterruption', $event as number)"
    />
    <span>{{ currencyCode }}</span>
    <span>&nbsp;</span>
    <span :class="['survey-edit-drawer__insured-value__descriptions__pd']">
      {{ lossScenario.propertyDamage }}
    </span>
    <span :class="['survey-edit-drawer__insured-value__descriptions__bi']">
      {{ lossScenario.businessInterruption }}
    </span>
    <span>&nbsp;</span>
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer__insured-value {
  display: contents;

  &__loss-scenario-name {
    font-weight: $font-medium;
  }

  &__descriptions {
    &__pd,
    &__bi {
      padding: $spacing-2;
      align-self: start;
      font-size: $text-sm;
    }
  }
}
</style>
