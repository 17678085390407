<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{ showPoints: boolean }>();

const emit = defineEmits<{ (event: "toggle"): void }>();

const toggle = () => emit("toggle");
</script>

<template>
  <BaseButton
    class="questionnaire-nav-points-toggle"
    :title="!showPoints ? t('blueRating.nav.showPoints') : t('blueRating.nav.hidePoints')"
    variant="text"
    @click="toggle"
  >
    <BaseIcon :icon="showPoints ? 'eye' : 'eye-closed'" />
  </BaseButton>
</template>

<style scoped lang="scss">
.questionnaire-nav-points-toggle {
  margin-left: $spacing-10;
}
</style>
