<script setup lang="ts">
import { type DeepReadonly, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { UpdateSite } from "@/types/_generated/api";
import { useModuleUsersOptions } from "@/composables/useModuleUsersOptions";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { UpdateSiteField, ErrorMessages } from "./types";

const props = defineProps<{
  moduleId: number;
  selectedSurveyorId?: number;
  siteForm: DeepReadonly<UpdateSite>;
  errorMessages: ErrorMessages;
}>();

defineEmits<{
  "update:field": UpdateSiteField;
}>();

const { t } = useI18n({ useScope: "global" });
const { moduleUserOptions } = useModuleUsersOptions(props.moduleId);

const surveyorValue = computed(
  () => moduleUserOptions.value.find((u) => u.value === props.selectedSurveyorId)?.value,
);
</script>

<template>
  <div class="site-edit-next-survey">
    <BaseTextField
      :label="t('sites.nextSurveyDate')"
      type="date"
      :error-message="errorMessages.nextSurveyDate"
      :value="siteForm.nextSurveyDate"
      @update:value="$emit('update:field', 'nextSurveyDate', String($event))"
    />

    <BaseSelect
      :label="t('sites.surveyor')"
      :error-message="errorMessages.surveyorId"
      :value="surveyorValue"
      @change="$emit('update:field', 'surveyorId', $event)"
    >
      <BaseOption :value="undefined"></BaseOption>
      <BaseOption v-for="option in moduleUserOptions" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseSelect>
  </div>
</template>

<style scoped lang="scss">
.site-edit-next-survey {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing-4;
}
</style>
