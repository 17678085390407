<script setup lang="ts">
import { onMounted, toRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { ExecSummaryServerFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import { useGetSitesData } from "@/composables/services/useGetSitesData";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import ExecSummaryDrawers from "./components/ExecSummaryDrawers.vue";
import ExecSummaryStates from "./components/ExecSummaryStates.vue";
import ExecSummaryTable from "./components/ExecSummaryTable/ExecSummaryTable.vue";
import ExecSummaryToolbar from "./components/ExecSummaryToolbar/ExecSummaryToolbar.vue";
import { useDrawerToggle } from "./composables/useDrawerToggle";
import { useFilterClient } from "./composables/useFilterClient";
import { useFilterServer } from "./composables/useFilterServer";
import { useGetExecSummary } from "./composables/useGetExecSummary";
import { useGetModuleData } from "./composables/useGetModuleData";
import { useGroupedSurveys } from "./composables/useGroupedSurveys";

const props = defineProps<{ moduleId: number }>();

const { t } = useI18n({ useScope: "global" });
const { setBreadcrumbs } = useBreadcrumbs();
const { tableLegend, abeyanceShort, currencyCode, isLoadingModule, getModuleData } =
  useGetModuleData();

const { currentDrawer, closeDrawer, openDrawer } = useDrawerToggle();
const { sitesData, isLoadingSites, getSitesData } = useGetSitesData();
const { isLoadingTableData, errorFetchingTableData, executiveSummary, getExecSummary } =
  useGetExecSummary();

const applyUpdatedServerFilter = (newFilter: ExecSummaryServerFilter) => {
  getExecSummary(props.moduleId, newFilter);
};

const { filteredData, clientFilter, updateClientFilter } = useFilterClient(executiveSummary);
const { serverFilter, updateServerFilter } = useFilterServer(applyUpdatedServerFilter);

const { groupedSurveys } = useGroupedSurveys(toRef(() => filteredData.value?.surveys ?? []));

// Loss scenarios currently visible in the table
const filteredLossScenarios = computed(() => {
  if (!executiveSummary.value) return [];
  return executiveSummary.value?.lossScenarios.filter((lossScenario) =>
    clientFilter.value.selectedColumns.has(`lossScenario-${lossScenario.id}`),
  );
});

onMounted(() => {
  setBreadcrumbs([{ label: t("userWeb.navigation.executiveSummary") }]);

  getExecSummary(props.moduleId);
  getModuleData(props.moduleId);
  getSitesData(props.moduleId, true);
});
</script>

<template>
  <UserWebViewLayout>
    <div class="exec-summary-view">
      <ExecSummaryToolbar
        :module-id="props.moduleId"
        :is-loading-data="isLoadingTableData || isLoadingModule || isLoadingSites"
        :loss-scenarios="filteredData?.lossScenarios"
        :site-definitions="sitesData"
        :group-by="serverFilter.groupBySiteDefinition"
        :client-filter="clientFilter"
        :server-filter="serverFilter"
        @apply-server-filter="updateServerFilter"
        @open-drawer="openDrawer"
      />

      <ExecSummaryStates
        :is-loading-table-data="isLoadingTableData"
        :error-fetching-table-data="errorFetchingTableData"
        :survey-length="filteredData?.surveys.length"
      />

      <ExecSummaryTable
        v-if="!isLoadingTableData && filteredData?.surveys.length"
        :currency-code="currencyCode"
        :legend="tableLegend"
        :abeyance-short="abeyanceShort"
        :client-filter="clientFilter"
        :server-filter="serverFilter"
        :executive-summary="filteredData"
        :group-by="serverFilter.groupBySiteDefinition"
        :grouped-surveys="groupedSurveys"
        :loss-scenarios="filteredLossScenarios"
        @open-drawer="openDrawer"
      />

      <ExecSummaryDrawers
        v-if="executiveSummary"
        :module-id="moduleId"
        :exec-summary="executiveSummary"
        :abeyance-short="abeyanceShort"
        :table-legend="tableLegend"
        :surveys="executiveSummary.surveys"
        :sites-data="sitesData"
        :client-filter="clientFilter"
        :server-filter="serverFilter"
        :current-drawer="currentDrawer"
        :grouped-surveys="groupedSurveys"
        @apply-client-filter="updateClientFilter"
        @apply-server-filter="updateServerFilter"
        @close-drawer="closeDrawer"
      />
    </div>
  </UserWebViewLayout>
</template>

<style scoped lang="scss">
.exec-summary-view {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: $spacing-4;
  height: 100%;
}
</style>
