import { Ref, computed, ref, unref } from "vue";
import type { CustomFilterFunc, FilterKey } from "@/types/Models";
import { filter } from "@/helpers/filter";

export const useFilter = <
  T extends object,
  TFilter extends FilterKey<T>,
  TCustomFilter extends CustomFilterFunc<T>,
>(
  items: T[] | Ref<T[]>,
  searchKeys?: TFilter[] | Ref<TFilter[]>,
  customFilters?: TCustomFilter[] | Ref<CustomFilterFunc<T>[]>,
) => {
  const searchPhrase = ref("");
  const searchKey = ref<TFilter>();

  const result = computed(() =>
    filter(
      unref(items),
      searchPhrase.value,
      searchKey.value,
      unref(searchKeys),
      unref(customFilters),
    ),
  );

  const setSearchPhrase = (phrase: string) => {
    searchPhrase.value = phrase;
  };

  const setSearchKey = (key: string | FilterKey<T>) => {
    if (!key) {
      searchKey.value = undefined;
    } else if (typeof key === "string") {
      searchKey.value = unref(searchKeys)?.find((sk) => sk.name === key);
    } else {
      searchKey.value = unref(searchKeys)?.find((sk) => sk.name === key.name);
    }
  };

  return {
    result,
    searchKey: computed(() => unref(searchKey)),
    searchPhrase: computed(() => unref(searchPhrase)),
    searchKeys: computed(() => unref(searchKeys)),
    setSearchPhrase,
    setSearchKey,
  };
};
