<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { AppRouteName } from "@/router/RouteName";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { useAuthStore } from "@/stores/auth";
import { useSessionStore } from "@/stores/session";
import BaseButton from "../base/BaseButton.vue";
import BaseModal from "../base/BaseModal.vue";

const { t } = useI18n({ useScope: "global" });
const router = useRouter();
const auth = useAuthStore();
const session = useSessionStore();

const show = ref(false);
const userIsActiveOnClient = ref(true);

const initialCountdownValue = 1800; // set value in seconds

onMounted(() => {
  ["mousemove", "mousedown", "keydown", "touchstart", "wheel"].forEach((event) =>
    document.addEventListener(event, () => {
      if (show.value) {
        return;
      }

      if (!show.value && userIsActiveOnClient.value) {
        // Reset user activity timer
      }

      if (!show.value) {
        userIsActiveOnClient.value = true;
      }
    }),
  );
});

watch(
  () => session.timer,
  () => {
    if (session.timer <= initialCountdownValue / 10) show.value = true;
    if (session.timer <= 0) signUserOut();
  },
);

const convertToFormattedTime = computed(() => {
  let hours = Math.floor(session.timer / 3600);
  let minutes = Math.floor((session.timer - hours * 3600) / 60);
  let seconds = session.timer - hours * 3600 - minutes * 60;

  if (hours < 10) hours = 0 + hours;
  if (minutes < 10) minutes = 0 + minutes;
  if (seconds < 10) seconds = 0 + seconds;

  return `${hours}:${minutes}:${seconds}`;
});

const closeModal = () => {
  session.reset();
  show.value = false;
};

const signUserOut = () => {
  closeModal();
  auth.signOut().then(() =>
    router.push({
      name: AppRouteName.Auth,
      params: { reason: ViewAuthStatus.SessionExpired },
    }),
  );
};
</script>

<template>
  <BaseModal v-if="show" :title="t('sessionExpire.title')" @cancel="closeModal">
    <p>
      {{ t("sessionExpire.message1") }}
      <strong>{{ convertToFormattedTime }} </strong>
      {{ t("sessionExpire.message2") }}
    </p>

    <template #controls>
      <BaseButton @click="closeModal">
        {{ t("sessionExpire.confirm") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="signUserOut">
        {{ t("sessionExpire.cancel") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
