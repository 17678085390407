<script setup lang="ts">
import { computed, onBeforeMount, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@/composables/useAuth";
import { useAppStore } from "@/stores/app";
import { remoteAuthBroadcastService, useAuthStore } from "@/stores/auth";
import { useNotificationsStore } from "@/stores/notifications";
import AppConsole from "@/components/app/AppConsole.vue";
import AppImpersonateWarning from "@/components/app/AppImpersonateWarning.vue";
import AppSession from "@/components/app/AppSession.vue";
import AppDialog from "./components/app/AppDialog.vue";
import AppToast from "./components/app/AppToast.vue";
import AppUnsavedChangesModal from "./components/app/AppUnsavedChangesModal.vue";
import { addCookieBanner } from "./cookie-banner";

const i18n = useI18n();
const app = useAppStore();
const auth = useAuthStore();
const notifications = useNotificationsStore();
const { isFullyAuthenticated } = useAuth();

watch(
  // re-render component when language changes
  () => app.settings.locale,
  () => (i18n.locale.value = app.settings.locale),
);

onBeforeMount(() => addCookieBanner());

const isDevelopment = computed(() => import.meta.env.MODE === "development");

remoteAuthBroadcastService.subscribe(() => window.document.location.reload(), ["login"]);
remoteAuthBroadcastService.subscribe(() => {
  auth.reset();
  window.document.location.reload();
}, ["logout"]);
</script>

<template>
  <AppConsole v-if="isDevelopment" />

  <AppImpersonateWarning v-if="auth.isImpersonated" />
  <router-view />

  <AppSession v-if="isFullyAuthenticated" />
  <AppToast :toasts="notifications.toasts" @remove="notifications.removeToast" />
  <AppDialog />
  <AppUnsavedChangesModal />
</template>
