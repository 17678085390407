import { Ref, ref } from "vue";
import { getQuestionById, getQuestionCategory } from "@/helpers/survey-helpers";
import { saveReplyService } from "@/services/blueRating";
import {
  BlueColor,
  BlueRatingCategoryDto,
  BlueRatingDto,
  SurveyorStatus,
} from "@/types/_generated/api";
import { State } from "@/components/base/BaseAutoSaveStatus.vue";

export const useBlueRatingReply = (survey: Ref<BlueRatingDto | null>) => {
  const replySaveStates = ref<Record<number, State>>({});

  const setQuestionValue = async (questionId: number, value: BlueColor) => {
    replySaveStates.value[questionId] = "saving";

    const question = survey.value && getQuestionById(survey.value, questionId);

    if (!question || !survey.value?.siteId) {
      throw Error("Question not found");
    }

    const oldValue = question.value;

    question.value = value;

    const category = survey.value && getQuestionCategory(survey.value, questionId);

    if (!category) {
      throw Error("Category not found");
    }

    const result = await saveReplyService(
      survey.value.surveyId,
      category.categoryId,
      question.questionId,
      value,
    );

    if (!result) {
      question.value = oldValue;
    }

    replySaveStates.value[questionId] = result ? "success" : "error";

    setTimeout(() => {
      replySaveStates.value[questionId] = "idle";
    }, 2000);
  };

  const toggleAllQuestionsNa = async (category: BlueRatingCategoryDto) => {
    const questionIds = category.questions
      .filter((q) =>
        q.recommendations.every(
          (r) =>
            r.surveyorStatus === SurveyorStatus.Completed ||
            r.surveyorStatus === SurveyorStatus.Withdrawn,
        ),
      )
      .map((q) => q.questionId);

    if (questionIds.length === 0) return;

    // Determine if all questions are currently set to Gray
    const allQuestionsAreNotApplicable = questionIds.every((questionId) => {
      const question = survey.value && getQuestionById(survey.value, questionId);
      return question?.value === BlueColor.Gray;
    });
    const newColor = allQuestionsAreNotApplicable ? BlueColor.NotSet : BlueColor.Gray;

    await Promise.all(questionIds.map((questionId) => setQuestionValue(questionId, newColor)));
  };

  return { toggleAllQuestionsNa, setQuestionValue, replySaveStates };
};
