import { ref } from "vue";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";

export const useSpoeCheckComments = (userId: number) => {
  const spoeCheckComments = ref<{ [key: number]: string }>({});
  const riskManagerComments = ref<{ [key: number]: string }>({});

  const setSpoeCheckComments = (recommendations: BlueRatingRecommendationDto[]) => {
    for (const recommendation of recommendations) {
      if (recommendation.spoeCheckComment) {
        spoeCheckComments.value[recommendation.recommendationId] = recommendation.spoeCheckComment;
      }
    }
  };

  const setRiskManagerComments = (recommendations: BlueRatingRecommendationDto[]) => {
    for (const recommendation of recommendations) {
      const unsavedComment = recommendation.riskManagerCommentHistory.find(
        (c) => c.userId === userId && !c.locked,
      );
      riskManagerComments.value[recommendation.recommendationId] = unsavedComment?.comment ?? "";
    }
  };

  const getSpoeCheckCommentsForUpdate = (recommendations: BlueRatingRecommendationDto[]) => {
    const recommendationComments: { recommendationId: number; spoeCheckComment: string }[] = [];
    for (const recommendation of recommendations) {
      if (
        recommendation.spoeCheckComment !== spoeCheckComments.value[recommendation.recommendationId]
      ) {
        recommendationComments.push({
          recommendationId: recommendation.recommendationId,
          spoeCheckComment: spoeCheckComments.value[recommendation.recommendationId],
        });
      }
    }
    return recommendationComments;
  };

  return {
    spoeCheckComments,
    riskManagerComments,
    setSpoeCheckComments,
    setRiskManagerComments,
    getSpoeCheckCommentsForUpdate,
  };
};
