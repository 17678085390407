/* eslint-disable no-console */
import type { Settings } from "@/stores/app";
import type { CurrentUserDto } from "@/types/_generated/api";

/// Print support information to the console
export const printSupportInfo = (settings: Settings, user?: CurrentUserDto) => {
  const nav = navigator as Navigator & NavigatorUA;

  const browserName = (nav.userAgentData?.brands ?? [])
    .map((b) => `${b.brand} ${b.version}`)
    .join(", ");

  // Try catch since browser restrictions might throw an error
  try {
    console.log(
      `%cWelcome to Blue ${settings.version}`,
      "color: #ffffff; font-family: Inter, sans-serif; font-size: 16px; background-color: #3b0764; padding: 5px 10px; border-radius: 5px;",
    );

    console.groupCollapsed("System Information");

    console.table({
      Time: new Date().toISOString(),
      BlueVersion: settings.version,
      BlueLanguage: settings.locale,
      OS: nav.userAgentData?.platform ?? nav.platform,
      BrowserName: browserName ?? nav.appCodeName,
      BrowserLanguage: navigator.language,
      UserId: user?.id,
      UserEmail: user?.email,
      UserRole: user?.role,
      UserLanguage: user?.languageCode,
      URL: window.location.href,
    });

    console.groupEnd();
  } catch {}
};
