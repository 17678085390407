const getBase64FromDataURL = (dataURL: string) => {
  // Check if input is a valid data URL
  if (!/^data:/.test(dataURL)) {
    throw new Error("Not a valid data URL");
  }

  // Find the first comma, which separates the header from the base64 data
  const commaIndex = dataURL.indexOf(",");

  // If no comma is found, or it's at the end of the string (no data), return an error
  if (commaIndex === -1 || commaIndex === dataURL.length - 1) {
    throw new Error("No data found in data URL");
  }

  // The base64 data is everything after the comma
  return dataURL.substring(commaIndex + 1);
};

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(getBase64FromDataURL(reader.result as string));
    reader.onerror = reject;
  });
