<script setup lang="ts">
import { computed, onBeforeMount, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import { useFetchQuestionnaire } from "./composables/useFetchQuestionnaire";
import { useQuestionnaireBreadcrumbs } from "./composables/useQuestionnaireBreadcrumbs";
import { useQuestionnaireNavigation } from "./composables/useQuestionnaireNavigation";

// EITHER moduleId or questionnaireId should be set
// moduleId will be used when viewing the current questionnaire on a module
// questionnaireId will be used when viewing a template
const props = defineProps<{
  moduleId: number | null;
  questionnaireId: number | null;
}>();

const { t } = useI18n({ useScope: "global" });

const { fetchQuestionnaire } = useFetchQuestionnaire();
const { currentTab, navigateToDraft, navigateToLatestQuestionnaire } = useQuestionnaireNavigation();

const store = useQuestionnaireStore();
const draft = useQuestionnaireDraftStore();
const { questionnaireBreadcrumbs, templateBreadcrumbs } = useQuestionnaireBreadcrumbs(
  props.moduleId!,
  toRef(() => store.questionnaire?.name ?? ""),
  currentTab,
);

onBeforeMount(async () => {
  store.$reset();
  draft.$reset();
  await fetchQuestionnaire(props.moduleId, props.questionnaireId);
});

const tabs = computed(() => {
  const tabs = [
    {
      label: t("questionnaire.current"),
      name: "current",
      action: () => navigateToLatestQuestionnaire(props.moduleId, props.questionnaireId),
    },
  ];

  if (store.questionnaire?.hasDraft) {
    tabs.push({
      label: t("questionnaire.draft.draft"),
      name: "draft",
      action: () => navigateToDraft(props.moduleId),
    });
  }

  return tabs;
});
</script>

<template>
  <AdminHeader :breadcrumbs="moduleId ? questionnaireBreadcrumbs : templateBreadcrumbs" />

  <main class="view-questionnaire">
    <BaseDrawerTabs :current-tab="currentTab" :tabs="tabs" @change="currentTab = $event">
      <router-view v-if="store.questionnaire" />
    </BaseDrawerTabs>
  </main>
</template>
