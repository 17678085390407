import { ref, readonly } from "vue";
import type { UpdateModule, ModuleDto } from "@/types/_generated/api";
import type { ErrorMessage } from "../types";

export const useModuleForm = (resetErrorMessage: (field: keyof ErrorMessage) => void) => {
  const moduleForm = ref<UpdateModule | null>(null);

  const updateModuleForm = (form: ModuleDto) => {
    moduleForm.value = { ...form };
  };

  const updateModuleField = <K extends keyof UpdateModule>(
    updatedField: K,
    value: UpdateModule[K],
  ) => {
    if (moduleForm.value) {
      resetErrorMessage(updatedField);
      moduleForm.value[updatedField] = value;
    }
  };

  return {
    moduleForm: readonly(moduleForm),
    updateModuleForm,
    updateModuleField,
  };
};
