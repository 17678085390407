import { reactive } from "vue";
import { RecResponseSurveyInfoDto } from "@/types/_generated/api";

type HeaderSurveyInfo = Pick<RecResponseSurveyInfoDto, "moduleName" | "siteName" | "surveyDate">;

const surveyInfo = reactive<HeaderSurveyInfo>({
  moduleName: "",
  siteName: "",
  surveyDate: "",
});

export const useRecResponseSurveyInfo = () => {
  const setSurveyInfo = (surveyInfo: HeaderSurveyInfo) => Object.assign(surveyInfo, surveyInfo);

  const clearSurveyInfo = () =>
    setSurveyInfo({
      moduleName: "",
      siteName: "",
      surveyDate: "",
    });

  return {
    clearSurveyInfo,
    surveyInfo,
    setSurveyInfo,
  };
};
