import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  QuestionnaireDto,
  CreateQuestionnaireDraft,
  CreateQuestionnaireDraftResponse,
  QuestionnaireDraftActionDto,
  UpdateQuestionnaireDraftResponse,
  PublishQuestionnaireDraft,
  PublishQuestionnaireDraftResponse,
  GetQuestionnaireDraftHistory,
  QuestionnaireDraftHistoryActionDto,
  GetQuestionnaireDraftPropertyHistory,
  QuestionnaireDraftPropertyHistoryDto,
  InsertQuestionnaireDraftAreas,
  InsertQuestionnaireDraftAreasResponse,
  InsertQuestionnaireDraftCategories,
  InsertQuestionnaireDraftCategoriesResponse,
  InsertQuestionnaireDraftQuestions,
  InsertQuestionnaireDraftQuestionsResponse,
  RevertQuestionnaireDraftResponse,
} from "@/types/_generated/api";
import { BaseCRUDMessage, BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export type QuestionnaireCRUDMessage = BaseCRUDMessage &
  (
    | {
        action: "create";
        id: number;
      }
    | {
        action: "update";
        questionnaire: QuestionnaireDto;
      }
    | {
        action: "delete";
        id: number;
      }
  );

export const questionnaireDraftEvents = new BroadcastService<QuestionnaireCRUDMessage>(
  "QuestionnaireDraft",
);

export const getQuestionnaireDraft = async (
  questionnaireId: number,
): Promise<QuestionnaireDto | null> => {
  try {
    const {
      data: { questionnaire },
    } = await http.get<{ questionnaire: QuestionnaireDto }>(
      `/questionnaires/${questionnaireId}/draft`,
    );

    return questionnaire;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
  }

  return null;
};

export const createQuestionnaireDraft = async (
  settings: CreateQuestionnaireDraft,
): Promise<number | null> => {
  try {
    const response = await http.post<CreateQuestionnaireDraftResponse>(
      `/questionnaires/${settings.questionnaireId}/draft`,
      settings,
    );

    if (response.status === 201) {
      const createdQuestionnaireId = response.data.questionnaire.questionnaireId;

      questionnaireDraftEvents.post({
        action: "create",
        id: createdQuestionnaireId,
      });

      notify.success(
        t("questionnaire.draft.questionnaireDraft"),
        t("common.created").toLowerCase(),
      );

      return createdQuestionnaireId;
    } else {
      return null;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return null;
  }
};

export const updateQuestionnaireDraft = async (
  questionnaireId: number,
  actions: QuestionnaireDraftActionDto[],
): Promise<boolean> => {
  try {
    const response = await http.put<UpdateQuestionnaireDraftResponse>(
      `/questionnaires/${questionnaireId}/draft`,
      { actions },
    );

    if (response.data.questionnaire) {
      questionnaireDraftEvents.post({
        action: "update",
        questionnaire: response.data.questionnaire,
      });
    }

    return response.status === 200;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return false;
  }
};

export const publishQuestionnaireDraft = async (
  request: PublishQuestionnaireDraft,
): Promise<number | null> => {
  try {
    const response = await http.post<PublishQuestionnaireDraftResponse>(
      `/questionnaires/${request.questionnaireId}/draft/publish`,
    );

    notify.success(t("questionnaire.draft.questionnaireDraft"), t("questionnaire.draft.published"));

    return response.data.questionnaireId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return null;
  }
};

export const deleteQuestionnaireDraft = async (questionnaireId: number): Promise<boolean> => {
  try {
    const response = await http.delete(`/questionnaires/${questionnaireId}/draft`);

    if (response.status === 200) {
      questionnaireDraftEvents.post({
        action: "delete",
        id: questionnaireId,
      });

      notify.success(
        t("questionnaire.draft.questionnaireDraft"),
        t("common.actions.delete").toLowerCase(),
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return false;
  }
};

export const getQuestionnaireDraftHistory = async (
  req: GetQuestionnaireDraftHistory,
): Promise<QuestionnaireDraftHistoryActionDto[] | null> => {
  try {
    const {
      data: { history },
    } = await http.get<{ history: QuestionnaireDraftHistoryActionDto[] }>(
      `/questionnaires/${req.questionnaireId}/draft/history`,
    );

    return history;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaire.draft.history.globalHistoryTitle"),
    });
  }

  return null;
};

export const getQuestionnaireDraftPropertyHistory = async (
  req: GetQuestionnaireDraftPropertyHistory,
): Promise<QuestionnaireDraftPropertyHistoryDto[] | null> => {
  try {
    const {
      data: { history },
    } = await http.get<{ history: QuestionnaireDraftPropertyHistoryDto[] }>(
      `/questionnaires/${req.questionnaireId}/draft/history/${req.targetActionId}/${req.type}/${req.property}`,
    );

    return history;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaire.draft.history.propertyHistoryTitle.header", {
        property: req.property,
        type: req.type,
      }),
    });
  }

  return null;
};

export const insertIntoQuestionnaireDraft = async (
  request: InsertQuestionnaireDraftAreas,
): Promise<boolean> => {
  try {
    const response = await http.post<InsertQuestionnaireDraftAreasResponse>(
      `/questionnaires/${request.questionnaireId}/draft/insert`,
      request,
    );

    if (response.data.questionnaire) {
      questionnaireDraftEvents.post({
        action: "update",
        questionnaire: response.data.questionnaire,
      });

      notify.success(
        t("questionnaire.draft.questionnaireDraft"),
        t("common.updated").toLowerCase(),
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.updated").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return false;
  }
};

export const insertIntoQuestionnaireDraftArea = async (
  request: InsertQuestionnaireDraftCategories,
): Promise<boolean> => {
  try {
    const response = await http.post<InsertQuestionnaireDraftCategoriesResponse>(
      `/questionnaires/${request.questionnaireId}/draft/area/${request.toAreaId}/insert`,
      request,
    );

    if (response.data.questionnaire) {
      questionnaireDraftEvents.post({
        action: "update",
        questionnaire: response.data.questionnaire,
      });

      notify.success(
        t("questionnaire.draft.questionnaireDraft"),
        t("common.updated").toLowerCase(),
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.updated").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return false;
  }
};

export const insertIntoQuestionnaireDraftCategory = async (
  request: InsertQuestionnaireDraftQuestions,
): Promise<boolean> => {
  try {
    const response = await http.post<InsertQuestionnaireDraftQuestionsResponse>(
      `/questionnaires/${request.questionnaireId}/draft/category/${request.toCategoryId}/insert`,
      request,
    );

    if (response.data.questionnaire) {
      questionnaireDraftEvents.post({
        action: "update",
        questionnaire: response.data.questionnaire,
      });

      notify.success(
        t("questionnaire.draft.questionnaireDraft"),
        t("common.updated").toLowerCase(),
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.updated").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return false;
  }
};

export const revertQuestionnaireDraft = async (
  questionnaireId: number,
  actionId: number,
): Promise<boolean> => {
  try {
    const response = await http.delete<RevertQuestionnaireDraftResponse>(
      `/questionnaires/${questionnaireId}/draft/history/${actionId}/revert`,
    );

    if (response.status === 200) {
      const questionnaire = await getQuestionnaireDraft(questionnaireId);

      if (!questionnaire) {
        return true;
      }

      questionnaireDraftEvents.post({
        action: "update",
        questionnaire,
      });

      notify.success(
        t("questionnaire.draft.questionnaireDraft"),
        t("common.updated").toLowerCase(),
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.updated").toLowerCase(),
      entity: t("questionnaire.draft.questionnaireDraft"),
    });
    return false;
  }
};
