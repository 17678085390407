<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import type { ReportMailRecipient } from "../types";

const props = defineProps<{
  recipient: ReportMailRecipient;
  isMobileRequired: boolean;
}>();

const emit = defineEmits<{
  "delete:recipient": [ReportMailRecipient];
  "update:recipient": [ReportMailRecipient];
  "edit:recipient": [void];
}>();

const { t } = useI18n({ useScope: "global" });

const userSummary = computed(() =>
  [
    `${props.recipient.firstName} ${props.recipient.lastName}`,
    props.recipient.title,
    props.recipient.company,
  ]
    .filter((v) => v?.length)
    .join(", "),
);

const toggleIncludeRecipient = () => {
  const updatedRecipient = {
    ...props.recipient,
    includeRecipient: !props.recipient.includeRecipient,
  };
  emit("update:recipient", updatedRecipient);
};
</script>

<template>
  <div class="report-mail-recipient-sum">
    <BaseCheckbox :checked="Boolean(recipient.includeRecipient)" @change="toggleIncludeRecipient" />

    <div class="report-mail-recipient-sum__info">
      <p
        v-if="!!recipient.firstName"
        v-truncation-title
        class="report-mail-recipient-sum__user-summary"
      >
        {{ userSummary }}
      </p>
      <p v-if="recipient.email">{{ recipient.email }}</p>
      <p v-if="recipient.mobile">{{ recipient.mobile }}</p>
      <p
        v-if="recipient.includeRecommendationResponseLink"
        class="report-mail-recipient-sum__include-link"
      >
        {{ t("survey.surveyReportMail.includeRedommendationResponseLink") }}
        <BaseIcon icon="check" />
      </p>

      <div v-if="recipient.reminderDates.length" class="report-mail-recipient-sum__reminder-dates">
        <BasePill v-for="(date, i) in recipient.reminderDates" :key="i" :label="date" />
      </div>
    </div>

    <div class="report-mail-recipient-sum__controls">
      <BaseButton
        variant="outlined"
        size="small"
        :title="t('common.actions.edit')"
        @click="$emit('edit:recipient')"
      >
        <BaseIcon icon="edit" />
      </BaseButton>

      <BaseButton
        variant="outlined"
        size="small"
        color="error"
        :title="t('common.actions.delete')"
        @click="$emit('delete:recipient', recipient)"
      >
        <BaseIcon icon="trash" />
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-mail-recipient-sum {
  display: flex;
  gap: $spacing-4;
  align-items: center;
  flex: 1;

  &__info {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;
    min-width: 0;
  }

  &__user-summary {
    font-weight: $font-medium;
  }

  &__include-link {
    display: flex;
    align-items: center;
    gap: $spacing-2;
  }

  &__reminder-dates {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-1;
    margin-top: $spacing-1;
  }

  &__controls {
    display: flex;
    gap: $spacing-2;
    margin-left: auto;
  }
}
</style>
