import { Ref, ref } from "vue";

export interface MultiRef {
  addRef(el: MultiRefItem): void;
  refs: Ref<MultiRefItem[]>;
}

export interface MultiRefItem {
  el: HTMLElement;
  key: string;
}

export const useMultiRef = function (): MultiRef {
  const refs = ref<MultiRefItem[]>([]);

  const addRef = (refItem: MultiRefItem) => {
    const index = refs.value.findIndex((i) => i.key === refItem.key);

    const itemExists = index > -1;

    if (!itemExists) {
      refs.value.push(refItem);
    }
  };

  return { addRef, refs };
};
