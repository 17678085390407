import { ref, readonly } from "vue";
import type { SearchBlueLossValuesResponse } from "@/types/_generated/api";
import { getBlueLossValuesService } from "@/services/dataAnalysis";
import { DaatServerFilter } from "./useFilterServer";

export const useGetBlueLossValues = () => {
  const isLoadingBlueLossValues = ref(false);
  const hasLossValuesError = ref(false);
  const blueLossValues = ref<SearchBlueLossValuesResponse["blueLossValues"] | null>(null);

  const getBlueLossValues = async (moduleId: number, filter: DaatServerFilter) => {
    isLoadingBlueLossValues.value = true;
    hasLossValuesError.value = false;

    try {
      const response = await getBlueLossValuesService(moduleId, filter);
      blueLossValues.value = response.blueLossValues;
    } catch {
      hasLossValuesError.value = true;
    } finally {
      isLoadingBlueLossValues.value = false;
    }
  };

  return {
    blueLossValues,
    hasLossValuesError: readonly(hasLossValuesError),
    isLoadingBlueLossValues: readonly(isLoadingBlueLossValues),
    getBlueLossValues,
  };
};
