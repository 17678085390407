<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SurveyFileType, SurveyReportFileDto } from "@/types/_generated/api";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";

defineProps<{
  surveyId: number | null;
  reportFile: SurveyReportFileDto;
}>();

const { t } = useI18n({ useScope: "global" });

const reportTypeTitle: Record<SurveyFileType, string> = {
  ["UwReport"]: t("survey.uploadReport.uWReport"),
  ["RecommendationReport"]: t("survey.uploadReport.recommendationReport"),
  ["OtherReport"]: t("survey.uploadReport.otherReport"),
};
</script>

<template>
  <BaseCard has-padding :title="reportTypeTitle[reportFile.type]">
    <template #header>
      <slot name="buttons"></slot>
    </template>

    <BaseFile
      :file-url="`/v1/surveys/${surveyId}/files/${reportFile.id}`"
      :file="{ ...reportFile, fileId: reportFile.id }"
    />
  </BaseCard>
</template>
