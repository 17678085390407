<script setup lang="ts">
import { ref } from "vue";
import { isSelectedToTemplate, toggleSelected } from "@/helpers/questionnaireTreeSelectHelper";
import { QuestionnaireDto } from "@/types/_generated/api";
import { InsertPoint } from "@/types/InsertPoint";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import QuestionnaireNavChevron from "../QuestionnaireNav/QuestionnaireNavChevron.vue";
import QuestionnaireNavFoldingButton from "../QuestionnaireNav/QuestionnaireNavFoldingButton.vue";
import QuestionnaireNavLeaf from "./QuestionnaireCheckboxNavLeaf.vue";

const props = withDefaults(
  defineProps<{
    questionnaire?: QuestionnaireDto;
    insertPoint?: InsertPoint;
    isLoading?: boolean;
  }>(),
  {
    questionnaire: undefined,
    insertPoint: undefined,
    isLoading: false,
  },
);

type Collapsable = "area" | "category";

const collapsed = ref<Set<{ type: Collapsable; id: number }>>(new Set());

const isExpanded = (type: Collapsable, id: number) => !isCollapsed(type, id);

const isCollapsed = (type: Collapsable, id: number) =>
  Array.from(collapsed.value).some((c) => c.type === type && c.id === id);

const toggle = (type: "area" | "category", id: number, value: boolean) => {
  if (value) {
    collapsed.value = new Set([...collapsed.value, { type, id }]);
  } else {
    collapsed.value = new Set(
      Array.from(collapsed.value).filter((c) => !(c.type === type && c.id === id)),
    );
  }
};

const toggleAllCategories = (value: boolean) => {
  if (!props.questionnaire) {
    return;
  }

  if (value) {
    collapsed.value = new Set([
      ...collapsed.value,
      ...(props.questionnaire.areas.flatMap((a) =>
        a.categories.map((c) => ({
          type: "category",
          id: c.categoryId,
        })),
      ) as { type: Collapsable; id: number }[]),
    ]);
  } else {
    collapsed.value = new Set(Array.from(collapsed.value).filter((c) => c.type !== "category"));
  }
};
</script>
<template>
  <div class="questionnaire-nav">
    <div class="questionnaire-nav__controls">
      <QuestionnaireNavFoldingButton @toggle="toggleAllCategories" />
    </div>

    <div v-if="!isLoading && !!questionnaire" class="questionnaire-nav__grid">
      <template v-for="(area, ai) in questionnaire?.areas" :key="ai">
        <!-- AREA -->
        <div class="questionnaire-nav__grid__area" :title="area.name">
          <div class="questionnaire-nav__grid__area__collapse">
            <QuestionnaireNavChevron
              :expanded="isExpanded('area', area.areaId)"
              @toggle="toggle('area', area.areaId, $event)"
            />
          </div>
          <span class="questionnaire-nav__grid__area__position">
            {{ area.position }}
          </span>
          <a
            class="questionnaire-nav__grid__area__text"
            :href="`#area_${area.areaId}`"
            :title="area.name"
          >
            {{ area.name }}
          </a>

          <BaseCheckbox
            v-if="props.insertPoint === 'Questionnaire'"
            :checked="isSelectedToTemplate('area', area.areaId)"
            @change="
              ($event) => {
                toggleSelected('area', area.areaId, $event, questionnaire);
              }
            "
          />
          <div v-if="props.insertPoint !== 'Questionnaire'">&nbsp;</div>
        </div>

        <template v-for="(category, ci) in area.categories" :key="ci">
          <template v-if="isExpanded('area', area.areaId)">
            <!-- CATEGORY -->
            <div><!--area pos-->&nbsp;</div>
            <div class="questionnaire-nav__grid__category__collapse">
              <QuestionnaireNavChevron
                :expanded="isExpanded('category', category.categoryId)"
                @toggle="toggle('category', category.categoryId, $event)"
              />
            </div>
            <QuestionnaireNavLeaf
              :check-box="insertPoint && ['Questionnaire', 'Area'].includes(insertPoint)"
              :hash="`#category_${category.categoryId}`"
              :position="category.position.toString()"
              :label="category.name"
              :is-selected-to-template="isSelectedToTemplate('category', category.categoryId)"
              @update:selected-to-template="
                ($event) => {
                  toggleSelected('category', category.categoryId, $event, props.questionnaire);
                }
              "
            />

            <template v-for="(question, qi) in category.questions" :key="qi">
              <template v-if="isExpanded('category', category.categoryId)">
                <div><!--collapse pos-->&nbsp;</div>
                <div><!--area pos-->&nbsp;</div>
                <div><!--category pos-->&nbsp;</div>
                <QuestionnaireNavLeaf
                  :check-box="true"
                  :hash="`#question_${question.questionId}`"
                  :is-visible="
                    isExpanded('area', area.areaId) && isExpanded('category', category.categoryId)
                  "
                  :position="question.position.toString()"
                  :label="question.text"
                  is-question
                  :is-selected-to-template="isSelectedToTemplate('question', question.questionId)"
                  @update:selected-to-template="
                    ($event) => {
                      toggleSelected('question', question.questionId, $event, props.questionnaire);
                    }
                  "
                />
              </template>
            </template>
          </template>
        </template>
      </template>
    </div>

    <div v-if="isLoading" class="questionnaire-nav__skeleton">
      <BaseSkeleton :set="10" height="30px" mb="1rem" width="100%" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.questionnaire-nav {
  $lineHeight: 30px;

  &__controls {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: right;
    border-bottom: 1px solid $primary-3;
  }

  &__grid {
    display: grid;
    padding: $spacing-3;
    max-width: 22rem;
    overflow-x: hidden;
    align-items: center;
    grid-template-columns: auto auto auto auto 1fr $lineHeight;

    // row name
    &__area {
      display: contents;
      box-sizing: content-box;
      line-height: $lineHeight;
      cursor: pointer;

      & > * {
        margin-bottom: $spacing-6;
      }

      &:not(:first-of-type) > * {
        margin-top: $spacing-6;
      }

      &__position {
        text-align: center;
      }

      &__text {
        text-decoration: none;
        font-size: $text-xl;
        grid-column: span 3;
        line-height: $lineHeight;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__area__collapse,
    &__category__collapse {
      & > * {
        color: $primary-5;
        &:hover {
          color: $primary-7;
          background-color: transparent;
        }
      }
    }
  }

  &__skeleton {
    padding: $spacing-5;
  }
}
</style>
