<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { RecommendationTrackingFilter } from "@/types/_generated/api";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";

defineProps<{
  recommendationHeadline?: RecommendationTrackingFilter["recommendationHeadline"];
}>();

defineEmits<{
  "update:rec-headline": [string];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div>
    <BaseLabel has-spacing>
      {{ t("userWeb.recommendationTracking.recHeadline") }}
    </BaseLabel>

    <UWTextField
      :value="recommendationHeadline ?? ''"
      @input="$emit('update:rec-headline', $event.trim())"
    />
  </div>
</template>
