<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { bytesToSize } from "@/helpers/bytesToSize";

interface Props {
  allowedFileTypes?: string[];
  maxSize?: number;
  minSize?: number;
}

defineProps<Props>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="base-file-area-restrictions">
    <small v-if="allowedFileTypes">
      {{
        t("library.fileUpload.uploadTypesDialog", {
          types: allowedFileTypes?.join(", "),
        })
      }}
    </small>
    <small v-if="maxSize">
      {{ t("library.fileUpload.maxFileSize", { size: bytesToSize(maxSize) }) }}
    </small>
    <small v-if="minSize">
      {{ t("library.fileUpload.minFileSize", { size: bytesToSize(minSize) }) }}
    </small>
  </div>
</template>

<style scoped lang="scss">
.base-file-area-restrictions {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  align-items: center;
}
</style>
