import { type Ref, computed, isRef } from "vue";
import type { ColumnDefinition } from "@/types/ColumnDefinition";
import type { BaseFilterKey, CustomFilterFunc } from "@/types/Models";
import { useFilter } from "./useFilter";

export const useTableFilter = <T extends object, TCustomFilter extends CustomFilterFunc<T>>(
  items: T[] | Ref<T[]>,
  columns: ColumnDefinition<T>[] | Ref<ColumnDefinition<T>[]>,
  customFilters?: TCustomFilter[] | Ref<TCustomFilter[]>,
) => {
  const searchKeys = computed(() => {
    const keys: BaseFilterKey<T>[] = [];

    for (const { searchKey } of isRef(columns) ? columns.value : columns) {
      if (searchKey) {
        keys.push(searchKey);
      }
    }

    return keys;
  });

  return useFilter(items, searchKeys, customFilters);
};
