<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import type { RecommendationTrackingFilter } from "@/types/_generated/api";
import BaseRadioGroup, {
  type BaseRadioGroupOption,
} from "@/components/base/BaseRadioGroup/BaseRadioGroup.vue";
import ModFilterDateRange from "@/components/mods/Filter/DateRange/ModFilterDateRange.vue";
import RecTrackingFilterYear from "./RecTrackingFilterYear.vue";

enum DateGranularity {
  Date = "date",
  Year = "year",
}

defineProps<{
  surveyDateMin?: RecommendationTrackingFilter["surveyDateMin"];
  surveyDateMax?: RecommendationTrackingFilter["surveyDateMax"];
}>();

const emit = defineEmits<{
  "update:start-date": [string];
  "update:end-date": [string];
}>();

const { t } = useI18n({ useScope: "global" });

const dateGranularityOptions: BaseRadioGroupOption[] = [
  { label: t("common.dates.fullDate"), value: DateGranularity.Date },
  { label: t("common.dates.year"), value: DateGranularity.Year },
];

const dateGranularity = ref<DateGranularity>(DateGranularity.Date);

const handleDateGranularityChange = (value: string) => {
  dateGranularity.value = value as DateGranularity;
};

const updateRangeFromYear = (year: string) => {
  if (!year) {
    emit("update:start-date", "");
    emit("update:end-date", "");
    return;
  }

  emit("update:start-date", `${year}-01-01`);
  emit("update:end-date", `${year}-12-31`);
};
</script>

<template>
  <div class="rec-tracking-filter-date">
    <BaseRadioGroup
      horizontal
      :value="dateGranularity"
      :options="dateGranularityOptions"
      @input="handleDateGranularityChange"
    />

    <div>
      <ModFilterDateRange
        v-if="dateGranularity === DateGranularity.Date"
        :start-date="surveyDateMin ?? ''"
        :end-date="surveyDateMax ?? ''"
        @update:start-date="$emit('update:start-date', $event)"
        @update:end-date="$emit('update:end-date', $event)"
      />

      <RecTrackingFilterYear
        v-if="dateGranularity === DateGranularity.Year"
        :survey-date-min="surveyDateMin ?? ''"
        :survey-date-max="surveyDateMax ?? ''"
        @update:year="updateRangeFromYear"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.rec-tracking-filter-date {
  display: flex;
  flex-direction: column;
  gap: $spacing-3;
}
</style>
