import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { CustomDefinitionKey, SiteDefinition } from "@/types/SiteDefinitions";
import { binaryOptions } from "@/helpers/options";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import { EditSitePermission } from "../components/UserSitePermissionEditDrawer/UserSitePermissionsEditDrawer.vue";

export const useEditSitePermissionsTableColumns = (siteDefinitions: Ref<SiteDefinition[]>) => {
  const { t } = useI18n({ useScope: "global" });

  const columns = computed(() => [
    ...siteDefinitions.value.map(
      (siteDef) =>
        new ColumnDefinition<EditSitePermission>({
          title: siteDef?.name,
          key: `custom${siteDef.customDefinitionNumber}` as CustomDefinitionKey,
        }),
    ),
    new ColumnDefinition({
      title: t("users.isExplicit"),
      key: "isExplicit",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
    }),
    new ColumnDefinition({
      title: t("users.isImplicit"),
      key: "isImplicit",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
    }),
  ]);

  return { columns };
};
