<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import LogoBlueWTWWhite from "@/assets/logos/LogoBlueWTWWhite.vue";
import { authorize } from "@/plugins/can";
import { AdminRouteName } from "@/router/RouteName";
import { Role } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import UWDropdown, {
  type DropdownOptions,
} from "@/components/base/UserWeb/Dropdown/UWDropdown.vue";

const { t } = useI18n({ useScope: "global" });

type AdminHeaderMenu = DropdownOptions & {
  hasAccess: () => boolean;
};

const defaultRoutes: AdminHeaderMenu[] = [
  {
    label: t("navigation.clients"),
    name: AdminRouteName.Clients,
    hasAccess: authorize.hasModulePermissions,
  },
  {
    label: t("navigation.modules"),
    name: AdminRouteName.Modules,
    hasAccess: authorize.hasModulePermissions,
  },
  {
    label: t("navigation.templates"),
    name: AdminRouteName.Templates,
    hasAccess: () => authorize.isSuperiorOrEqualTo(Role.INT),
  },
];

const adminRoutes: AdminHeaderMenu[] = [
  {
    divider: true,
    label: t("navigation.userAdministration"),
    name: AdminRouteName.Users,
    hasAccess: () => authorize.hasRole(Role.SystemOwner, Role.SA, Role.BA, Role.INT),
  },
  {
    label: t("navigation.systemAdministration"),
    name: AdminRouteName.SystemAdmin,
    hasAccess: () => authorize.hasRole(Role.SystemOwner),
  },
];

const routes = computed<AdminHeaderMenu[]>(() => {
  // Filter out routes that the user does not have access to
  const filteredRoutes = [...defaultRoutes, ...adminRoutes].filter((item) => item.hasAccess());
  return filteredRoutes;
});
</script>

<template>
  <nav class="admin-header-menu">
    <RouterLink
      class="admin-header-menu__link"
      :to="{ name: AdminRouteName.Root }"
      :aria-label="t('navigation.dashboard')"
    >
      <div class="admin-header-menu__logo">
        <LogoBlueWTWWhite class="admin-header-menu__logo__icon" />
        {{ t("navigation.admin") }}
      </div>
    </RouterLink>

    <UWDropdown :options="routes">
      <template #activator="{ toggleDropdown }">
        <BaseButton variant="outlined" color="light" :aria-haspopup="true" @click="toggleDropdown">
          <BaseIcon icon="menu" />
          {{ t("navigation.menu") }}
        </BaseButton>
      </template>
    </UWDropdown>
  </nav>
</template>

<style scoped lang="scss">
.admin-header-menu {
  display: flex;
  align-items: center;
  gap: $spacing-4;

  &__link {
    transition: opacity $duration-100;

    &:hover {
      opacity: 0.8;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $spacing-2;
    font-size: $text-xs;
    color: $primary-0;

    &__icon {
      height: 0.75rem;
      flex-shrink: 0;
    }
  }
}
</style>
