export const addCookieBanner = () => {
  const oneTrustOtAutoBlockJS = document.createElement("script");
  const oneTrustCookieBannerCdn = document.createElement("script");
  const oneTrustFunctionCall = document.createElement("script");

  oneTrustOtAutoBlockJS.setAttribute(
    "src",
    "https://cdn.cookielaw.org/consent/c344427a-81cd-4e7e-beff-881075868c0d-test/OtAutoBlock.js",
  );
  oneTrustOtAutoBlockJS.setAttribute("type", "text/javascript");

  oneTrustCookieBannerCdn.setAttribute(
    "src",
    "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
  );

  oneTrustCookieBannerCdn.setAttribute("type", "text/javascript");
  oneTrustCookieBannerCdn.setAttribute("charset", "UTF-8");
  oneTrustCookieBannerCdn.setAttribute(
    "data-domain-script",
    "c344427a-81cd-4e7e-beff-881075868c0d-test",
  );

  oneTrustFunctionCall.setAttribute("type", "text/javascript");
  oneTrustFunctionCall.innerText = "function OptanonWrapper() { }";

  document.head.appendChild(oneTrustCookieBannerCdn);
  document.head.appendChild(oneTrustCookieBannerCdn);
  document.head.appendChild(oneTrustFunctionCall);
};
