import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  GetCurrentQuestionnaire,
  QuestionnaireDto,
  GetCurrentQuestionnaireResponse,
  GetQuestionnaireResponse,
  QuestionnaireLeanDto,
  GetQuestionnairesResponse,
  CreateQuestionnaireTemplateResponse,
  QuestionnaireTemplateLeanDto,
  GetQuestionnaireTemplatesResponse,
} from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getCurrentQuestionnaire = async (
  request: GetCurrentQuestionnaire,
): Promise<QuestionnaireDto | null> => {
  try {
    const {
      data: { questionnaire },
    } = await http.get<GetCurrentQuestionnaireResponse>(
      `/modules/${request.moduleId}/questionnaires/current`,
    );

    return questionnaire;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaire.questionnaire"),
    });
  }

  return null;
};

export const getQuestionnaire = async (
  questionnaireId: number,
): Promise<QuestionnaireDto | null> => {
  try {
    const {
      data: { questionnaire },
    } = await http.get<GetQuestionnaireResponse>(`/questionnaires/${questionnaireId}`);

    return questionnaire;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaire.questionnaire"),
    });
  }

  return null;
};

export const getQuestionnaires = async (
  includeTemplates: boolean = false,
): Promise<QuestionnaireLeanDto[] | null> => {
  const query = includeTemplates ? "?includeTemplates=true" : "";

  try {
    const {
      data: { questionnaires },
    } = await http.get<GetQuestionnairesResponse>(`/questionnaires${query}`);

    return questionnaires;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaire.questionnaires"),
    });
  }

  return null;
};

export const createQuestionnaireTemplate = async (): Promise<number | null> => {
  try {
    const {
      data: { questionnaireId },
    } = await http.post<CreateQuestionnaireTemplateResponse>(`/questionnaires/templates`);

    return questionnaireId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("questionnaireTemplates.questionnaireTemplate"),
    });
  }

  return null;
};

export const getQuestionnaireTemplates = async (): Promise<
  QuestionnaireTemplateLeanDto[] | null
> => {
  try {
    const {
      data: { questionnaires },
    } = await http.get<GetQuestionnaireTemplatesResponse>("/questionnaires/templates");

    return questionnaires;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("questionnaireTemplates.questionnaireTemplate"),
    });
  }

  return null;
};
