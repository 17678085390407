import { reactive, type DeepReadonly, readonly, ref } from "vue";
import { useI18n } from "vue-i18n";
import { type UpdateModule, Status } from "@/types/_generated/api";
import type { ErrorMessage } from "../types";
import { ModuleEditTab } from "../constants/ModuleEditTab";

type ValidationRule = {
  tab: ModuleEditTab;
  maxLength?: number;
  required?: boolean;
  requiredIfPublished?: boolean;
};

type ValidationRules = Partial<Record<keyof ErrorMessage, ValidationRule>>;

const validationRules: ValidationRules = {
  name: { tab: ModuleEditTab.General, maxLength: 100, required: true },
  address: { tab: ModuleEditTab.General, maxLength: 400 },
  city: { tab: ModuleEditTab.General, maxLength: 150 },
  country: { tab: ModuleEditTab.General, maxLength: 100 },
  phone: { tab: ModuleEditTab.General, maxLength: 100 },
  poBox: { tab: ModuleEditTab.General, maxLength: 250 },
  web: { tab: ModuleEditTab.General, maxLength: 150 },
  zipCode: { tab: ModuleEditTab.General, maxLength: 100 },
  willisOffice: { tab: ModuleEditTab.General, maxLength: 100 },
  willisCountryOrUnit: { tab: ModuleEditTab.General, maxLength: 50 },
  siteDefinition1: { tab: ModuleEditTab.SiteDefinition, maxLength: 100, requiredIfPublished: true },
  siteDefinition2: { tab: ModuleEditTab.SiteDefinition, maxLength: 100 },
  siteDefinition3: { tab: ModuleEditTab.SiteDefinition, maxLength: 100 },
  siteDefinition4: { tab: ModuleEditTab.SiteDefinition, maxLength: 100 },
  userWebText: { tab: ModuleEditTab.UserWeb, maxLength: 32767 },
  preSurveyMailSubject: { tab: ModuleEditTab.EmailTemplates, maxLength: 100 },
  preSurveyMailBody: { tab: ModuleEditTab.EmailTemplates, maxLength: 32767 },
  reportMailSubject: { tab: ModuleEditTab.EmailTemplates, maxLength: 100 },
  reportMailBody: { tab: ModuleEditTab.EmailTemplates, maxLength: 32767 },
  abeyanceShort: { tab: ModuleEditTab.BlueRating, maxLength: 1 },
  abeyanceTranslation: { tab: ModuleEditTab.BlueRating, maxLength: 50 },
  nameTranslation: { tab: ModuleEditTab.BlueRating, maxLength: 50 },
  priorityTranslation: { tab: ModuleEditTab.BlueRating, maxLength: 50 },
  locationTranslation: { tab: ModuleEditTab.BlueRating, maxLength: 50 },
  textForBlue: { tab: ModuleEditTab.BlueRating, maxLength: 100 },
  textForGreen: { tab: ModuleEditTab.BlueRating, maxLength: 100 },
  textForRed: { tab: ModuleEditTab.BlueRating, maxLength: 100 },
  textForYellow: { tab: ModuleEditTab.BlueRating, maxLength: 100 },
  currencyCode: { tab: ModuleEditTab.BlueRating, maxLength: 4 },
};

export const useModuleValidation = () => {
  const { t } = useI18n({ useScope: "global" });

  const errorMessage = reactive({
    name: "",
    siteDefinition1: "",
    siteDefinition2: "",
    siteDefinition3: "",
    siteDefinition4: "",
    willisOffice: "",
    willisCountryOrUnit: "",
    userWebText: "",
    preSurveyMailSubject: "",
    preSurveyMailBody: "",
    reportMailSubject: "",
    reportMailBody: "",
    abeyanceShort: "",
    abeyanceTranslation: "",
    nameTranslation: "",
    priorityTranslation: "",
    locationTranslation: "",
    textForBlue: "",
    textForGreen: "",
    textForRed: "",
    textForYellow: "",
    currencyCode: "",
    address: "",
    city: "",
    country: "",
    phone: "",
    poBox: "",
    web: "",
    zipCode: "",
  }) as ErrorMessage;

  const tabsWithErrors = ref(new Set<ModuleEditTab>());

  const resetErrorMessage = (field: keyof ErrorMessage) => {
    errorMessage[field] = "";
  };

  const validateField = <K extends keyof ValidationRules>(
    field: K,
    value: DeepReadonly<UpdateModule[K]>,
    isPublished: boolean,
  ): string => {
    const rules = validationRules[field];
    if (!rules) return "";

    if (rules.maxLength && typeof value === "string" && value.length > rules.maxLength) {
      return t("validations.maxLength", { max: rules.maxLength });
    }

    if (rules.required && (typeof value === "string" ? value.length < 1 : !value)) {
      return t("validations.required");
    }

    if (rules.requiredIfPublished && isPublished && !value) {
      return t("validations.requiredIfPublished");
    }

    return "";
  };

  const isFormValid = (moduleForm: DeepReadonly<UpdateModule>): boolean => {
    // Validate all fields
    Object.keys(validationRules).forEach((field) => {
      const key = field as keyof typeof validationRules;
      const value = moduleForm[key];
      errorMessage[key] = validateField(key, value, moduleForm.status === Status.Published);
    });

    // Validate tabs
    tabsWithErrors.value.clear();
    Object.entries(validationRules).forEach(([field, rule]) => {
      if (rule && errorMessage[field as keyof ErrorMessage]) {
        tabsWithErrors.value.add(rule.tab);
      }
    });

    return tabsWithErrors.value.size === 0;
  };

  return {
    errorMessage: readonly(errorMessage),
    tabsWithErrors: readonly(tabsWithErrors),
    resetErrorMessage,
    isFormValid,
  };
};
