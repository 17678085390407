<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    min?: number;
    max?: number;
    primaryProgress: number;
    secondaryProgress?: number;
  }>(),
  {
    min: 0,
    max: 100,
    secondaryProgress: 0,
  },
);

const primaryProgressWidth = computed(() => {
  return {
    width: `${Math.min(100, (Math.max(props.min, props.primaryProgress) / props.max) * 100)}%`,
  };
});

const secondaryProgressStyle = computed(() => {
  return {
    width: `${Math.min(100, (Math.max(props.min, props.secondaryProgress) / props.max) * 100)}%`,
  };
});
</script>

<template>
  <div class="base-progress-bar">
    <div
      class="base-progress-bar__primary"
      role="progressbar"
      :style="primaryProgressWidth"
      :aria-valuenow="primaryProgress"
      :aria-valuemin="min"
      :aria-valuemax="max"
    ></div>
    <div class="base-progress-bar__secondary" :style="secondaryProgressStyle"></div>
  </div>
</template>

<style scoped lang="scss">
.base-progress-bar {
  display: flex;
  background-color: $primary-0;
  border-radius: $rounded-full;
  border: 1px solid $primary-3;
  min-width: 200px;
  overflow: hidden;
  height: 100%;
  align-self: center;
  height: 15px;

  &__primary,
  &__secondary {
    height: 15px;
  }

  &__primary {
    background-color: $secondary-4;
  }

  &__secondary {
    background-color: $secondary-2;
  }
}
</style>
