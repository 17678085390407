import { ref, readonly, Ref } from "vue";
import type {
  UpdateBlueRatingRecommendation,
  BlueRatingRecommendationDto,
} from "@/types/_generated/api";
import type { ErrorMessage, RecommendationForm } from "../types";

export const useRecommendationForm = (resetErrorMessage: (field: keyof ErrorMessage) => void) => {
  const recommendationForm = ref<UpdateBlueRatingRecommendation | null>(null);

  const updateRecommendationForm = (form: BlueRatingRecommendationDto) => {
    recommendationForm.value = { ...form };
  };

  const updateRecommendationField = <K extends keyof UpdateBlueRatingRecommendation>(
    updatedField: K,
    value: UpdateBlueRatingRecommendation[K],
  ) => {
    if (recommendationForm.value) {
      resetErrorMessage(updatedField);
      recommendationForm.value[updatedField] = value;
    }
  };

  return {
    recommendationForm: readonly(recommendationForm) as Ref<RecommendationForm | null>,
    updateRecommendationForm,
    updateRecommendationField,
  };
};
