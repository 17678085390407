<script setup lang="ts">
export interface Props {
  disabled?: boolean;
  value: string;
}

defineProps<Props>();

const emit = defineEmits<{
  change: [value: string];
}>();

const handleSelectChange = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  const newValue = target.value;
  emit("change", newValue);
};
</script>

<template>
  <select class="base-select" :disabled="disabled" :value="value" @change="handleSelectChange">
    <slot></slot>
  </select>
</template>

<style scoped lang="scss">
.base-select {
  /* Styling */
  padding: $spacing-2 $spacing-8 $spacing-2 $spacing-3;
  border: 1px solid $primary-7;
  border-radius: $rounded-base;
  height: $shared-component-height;
  font-size: $text-sm;

  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.05 16.01'%3E%3Cpath fill='%238FA3B3' d='M12,20a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1,0-1.42,1,1,0,0,1,1.42,0L12,17.61l3.3-3.18a1,1,0,1,1,1.49,1.33l-.11.11-4,3.86A1.07,1.07,0,0,1,12,20Z' transform='translate(-6.98 -3.99)'/%3E%3Cpath fill='%238FA3B3' d='M12,4a1,1,0,0,1,.71.29l4,4a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L12,6.39,8.72,9.57A1,1,0,1,1,7.23,8.24l.11-.11,4-3.86A1.07,1.07,0,0,1,12,4Z' transform='translate(-6.98 -3.99)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right $spacing-3 top 50%;
  background-size: 0.45rem auto;

  /* Overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
