<script setup lang="ts">
import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";
import { Ref, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import {
  getQuestionnaireDraft,
  publishQuestionnaireDraft,
  deleteQuestionnaireDraft,
} from "@/services/questionnaire-draft";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import QuestionnaireDraft from "@/views/Admin/Questionnaire/components/Draft/QuestionnaireDraft.vue";
import QuestionnaireDraftGlobalHistoryDrawer from "@/views/Admin/Questionnaire/components/Draft/QuestionnaireDraftGlobalHistoryDrawer/QuestionnaireDraftGlobalHistoryDrawer.vue";
import QuestionnaireDraftPropertyHistoryDrawer from "@/views/Admin/Questionnaire/components/Draft/QuestionnaireDraftPropertyHistoryDrawer/QuestionnaireDraftPropertyHistoryDrawer.vue";
import QuestionnaireDraftAddFromTemplateDrawer from "@/views/Admin/Questionnaire/components/Draft/QuestionnaireDraftTemplateDrawer/QuestionnaireDraftAddFromTemplateDrawer.vue";
import { useVuelidateChildErrors } from "@/views/Admin/Questionnaire/composables/useQuestionnaireNavValidationErrors";
import AppOverflowContainer from "@/components/app/AppOverflowContainer.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { useFetchQuestionnaire } from "./composables/useFetchQuestionnaire";
import { useQuestionnaireNavigation } from "./composables/useQuestionnaireNavigation";
import QuestionnaireNav from "/src/views/Admin/Questionnaire/components/QuestionnaireNav/QuestionnaireNav.vue";

const props = defineProps<{
  moduleId: number | null;
  questionnaireId: number | null;
}>();

// This is a child view of ViewQuestionnaire.vue which should have loaded the questionnaire
const { t } = useI18n({ useScope: "global" });
const { questionnaire } = useQuestionnaireStore();
const draft = useQuestionnaireDraftStore();
const v$ = useVuelidate({ $autoDirty: true });
const dialog = useDialog();
const { fetchQuestionnaire } = useFetchQuestionnaire();
const { navigateToLatestQuestionnaire } = useQuestionnaireNavigation();

const isLoading = ref(false);

onBeforeMount(async () => {
  isLoading.value = true;

  if (!questionnaire) {
    throw new Error("Questionnaire not loaded");
  }

  draft.draft = await getQuestionnaireDraft(questionnaire.questionnaireId);

  isLoading.value = false;
});

const { errors } = useVuelidateChildErrors(v$ as Ref<Validation<ValidationArgs<unknown>, object>>);

const publishDraft = async () => {
  if (!questionnaire?.questionnaireId) {
    return;
  }

  const isConfirmed = await dialog.openDialog({
    title: t("questionnaire.draft.publishDialog.title"),
    description: t("questionnaire.draft.publishDialog.text"),
  });

  if (!isConfirmed) {
    return;
  }

  const questionnaireId = await publishQuestionnaireDraft({
    questionnaireId: questionnaire.questionnaireId,
  });

  if (questionnaireId) {
    await fetchQuestionnaire(props.moduleId, questionnaireId);
    navigateToLatestQuestionnaire(props.moduleId, questionnaireId, true);
  }
};

const deleteDraft = async () => {
  if (!questionnaire?.questionnaireId) {
    return;
  }

  const isConfirmed = await dialog.openDeletePrompt(t("questionnaire.draft.draft"));

  if (!isConfirmed) {
    return;
  }

  await deleteQuestionnaireDraft(questionnaire.questionnaireId);

  questionnaire!.hasDraft = false;

  navigateToLatestQuestionnaire(props.moduleId, props.questionnaireId, true);
};
</script>

<template>
  <main v-if="questionnaire" class="view-questionnaire-draft">
    <AppOverflowContainer :remove-navigation="true" :margin="'3rem'">
      <QuestionnaireNav :questionnaire="draft.draft" :is-loading="isLoading" :errors="errors" />
    </AppOverflowContainer>

    <div class="view-questionnaire-draft__viewer">
      <div v-if="isLoading || draft.draft">
        <div class="view-questionnaire-draft__controls">
          <BaseButton color="error" variant="outlined" @click="deleteDraft">
            {{ t("common.actions.delete") }}
          </BaseButton>

          <BaseButton variant="outlined" @click="draft.showGlobalHistoryDrawer = true">
            {{ t("questionnaire.draft.history.openGlobalHistory") }}
          </BaseButton>

          <BaseButton
            :disabled="!!v$.$errors?.length || draft.autoSave.isAutoSaving"
            :title="
              !!v$.$errors?.length
                ? t('questionnaire.draft.errorsNeedToBeFixed', {
                    count: v$.$errors.length,
                  })
                : ''
            "
            @click="publishDraft"
          >
            {{ t("questionnaire.draft.publish") }}
          </BaseButton>
        </div>

        <AppOverflowContainer :remove-navigation="true" :margin="'7.5rem'">
          <QuestionnaireDraft
            :draft="draft.draft || undefined"
            :is-loading="isLoading"
            :questionnaire-id="questionnaire.questionnaireId"
          />
        </AppOverflowContainer>
      </div>

      <div v-else class="view-questionnaire-draft__viewer--empty">
        {{ t("questionnaire.draft.notFound") }}
      </div>
    </div>
  </main>

  <QuestionnaireDraftPropertyHistoryDrawer
    v-if="draft.propertyHistory.show && questionnaire"
    :questionnaire-id="questionnaire.questionnaireId"
    :property-name="draft.propertyHistory.property!"
    :type="draft.propertyHistory.type!"
    :target-name="draft.propertyHistory.targetName!"
    :target-action-id="draft.propertyHistory.targetActionId!"
    @close="draft.closePropertyHistory"
  />

  <QuestionnaireDraftGlobalHistoryDrawer
    v-if="draft.showGlobalHistoryDrawer && questionnaire"
    :questionnaire-id="questionnaire.questionnaireId"
    @close="draft.showGlobalHistoryDrawer = false"
  />

  <QuestionnaireDraftAddFromTemplateDrawer
    v-if="draft.templateDrawer.visible && questionnaire"
    :questionnaire-id="questionnaire.questionnaireId"
    :insert-point="draft.templateDrawer.insertPoint"
    :insert-into-id="draft.templateDrawer.insertIntoId"
    @close="() => draft.templateDrawer.hide()"
  />
</template>

<style scoped lang="scss">
.view-questionnaire-draft {
  display: grid;
  grid-template-columns: 350px 1fr;

  &__viewer {
    &--empty {
      text-align: center;
      font-size: $text-lg;
      color: $primary-5;
      font-style: italic;
      padding: $spacing-4;
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-2;
    padding: 0 $spacing-4;
    margin: $spacing-4 0;
  }
}
</style>
