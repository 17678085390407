<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import currencies from "@/assets/currencies.json";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { ModuleForm, ErrorMessage, UpdateModuleField } from "./types";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  moduleForm: ModuleForm;
  errorMessage: ErrorMessage;
}>();

const emit = defineEmits<{
  "update:field": UpdateModuleField;
}>();

const remainingCharactersCount = computed(() => {
  const maxLength = 500;
  const currentLength = props.moduleForm.recommendationTypes.join(";").length;

  return maxLength - currentLength;
});

const setCurrency = (event: string | number | null | undefined) => {
  const currencyCode = String(event ?? "")
    .trim()
    .toUpperCase();

  emit("update:field", "currencyCode", currencyCode);
};
</script>

<template>
  <div>
    <BaseCard :title="t('modules.spoeSettings')">
      <div
        class="edit-module-drawer-blue-rating__content edit-module-drawer-blue-rating__content__two-columns"
      >
        <div>
          <BaseCheckbox
            :label="t('modules.requireSPOECheck')"
            :description="t('modules.requireSPOECheckDescription')"
            :checked="moduleForm.requireSpoeCheck"
            @change="$emit('update:field', 'requireSpoeCheck', $event)"
          />
        </div>
        <BaseCheckbox
          :label="t('modules.allowSPOECheckEXT')"
          :checked="moduleForm.allowSpoeCheckByExt"
          @change="$emit('update:field', 'allowSpoeCheckByExt', $event)"
        />
      </div>
    </BaseCard>

    <BaseCard :title="t('modules.recommendationSettings')">
      <div class="edit-module-drawer-blue-rating__content">
        <BasePillsInput
          :add-on-space="false"
          :label="t('modules.recommendationtTypes')"
          :values="[...moduleForm.recommendationTypes]"
          :max-length="remainingCharactersCount"
          @add="
            $emit('update:field', 'recommendationTypes', [
              ...moduleForm.recommendationTypes,
              $event,
            ])
          "
        >
          <template #pill="{ value, index }">
            <BasePill
              :key="index"
              :label="value"
              clickable
              @click="
                $emit('update:field', 'recommendationTypes', [
                  ...moduleForm.recommendationTypes.filter((_, i) => i !== index),
                ])
              "
            />
          </template>
        </BasePillsInput>

        <div class="edit-module-drawer-blue-rating__content__three-columns">
          <BaseCheckbox
            :label="t('modules.forceToUseOverrideCategoryColor')"
            :checked="moduleForm.forceOverrideCategoryColor"
            @change="$emit('update:field', 'forceOverrideCategoryColor', $event)"
          />

          <BaseCheckbox
            :label="t('modules.forceToUseName')"
            :checked="moduleForm.forceName"
            @change="$emit('update:field', 'forceName', $event)"
          />

          <BaseCheckbox
            :label="t('modules.forceToUseLocation')"
            :checked="moduleForm.forceLocation"
            @change="$emit('update:field', 'forceLocation', $event)"
          />

          <BaseCheckbox
            :label="t('modules.forceToUsePriority')"
            :checked="moduleForm.forcePriority"
            @change="$emit('update:field', 'forcePriority', $event)"
          />

          <BaseCheckbox
            :label="t('modules.forceToUseType')"
            :checked="moduleForm.forceType"
            @change="$emit('update:field', 'forceType', $event)"
          />
        </div>
      </div>
    </BaseCard>

    <BaseCard :title="t('modules.translations')">
      <div class="edit-module-drawer-blue-rating__content">
        <div class="edit-module-drawer-blue-rating__content__two-columns">
          <BaseTextField
            :maxlength="1"
            :label="t('modules.abeyanceShort')"
            :error-message="errorMessage.abeyanceShort"
            :value="moduleForm.abeyanceShort"
            @update:value="$emit('update:field', 'abeyanceShort', String($event))"
          />

          <BaseTextField
            :label="t('modules.abeyanceTranslation')"
            :description="t('modules.abeyanceTranslationDescription')"
            :error-message="errorMessage.abeyanceTranslation"
            :value="moduleForm.abeyanceTranslation"
            @update:value="$emit('update:field', 'abeyanceTranslation', String($event))"
          />
        </div>

        <div class="edit-module-drawer-blue-rating__content__three-columns">
          <BaseTextField
            :label="t('modules.nameTranslation')"
            :error-message="errorMessage.nameTranslation"
            :value="moduleForm.nameTranslation"
            @update:value="$emit('update:field', 'nameTranslation', String($event))"
          />
          <BaseTextField
            :label="t('modules.priorityTranslation')"
            :error-message="errorMessage.priorityTranslation"
            :value="moduleForm.priorityTranslation"
            @update:value="$emit('update:field', 'priorityTranslation', String($event))"
          />
          <BaseTextField
            :label="t('modules.locationTranslation')"
            :error-message="errorMessage.locationTranslation"
            :value="moduleForm.locationTranslation"
            @update:value="$emit('update:field', 'locationTranslation', String($event))"
          />
        </div>
      </div>
    </BaseCard>

    <BaseCard :title="t('modules.colorDescriptions')">
      <div class="edit-module-drawer-blue-rating__content">
        <div class="edit-module-drawer-blue-rating__content__two-columns">
          <BaseTextField
            :label="t('modules.textForBlue')"
            :error-message="errorMessage.textForBlue"
            :value="moduleForm.textForBlue"
            @update:value="$emit('update:field', 'textForBlue', String($event))"
          />
          <BaseTextField
            :label="t('modules.textForGreen')"
            :error-message="errorMessage.textForGreen"
            :value="moduleForm.textForGreen"
            @update:value="$emit('update:field', 'textForGreen', String($event))"
          />
          <BaseTextField
            :label="t('modules.textForYellow')"
            :error-message="errorMessage.textForYellow"
            :value="moduleForm.textForYellow"
            @update:value="$emit('update:field', 'textForYellow', String($event))"
          />
          <BaseTextField
            :label="t('modules.textForRed')"
            :error-message="errorMessage.textForRed"
            :value="moduleForm.textForRed"
            @update:value="$emit('update:field', 'textForRed', String($event))"
          />
        </div>
      </div>
    </BaseCard>

    <BaseCard :title="t('modules.other')">
      <div class="edit-module-drawer-blue-rating__content">
        <BaseTextField
          :label="t('modules.currencyCode')"
          :error-message="errorMessage.currencyCode"
          :value="moduleForm.currencyCode"
          :list="currencies"
          :maxlength="4"
          @update:value="setCurrency"
        />

        <div class="edit-module-drawer-blue-rating__content__two-columns">
          <BaseCheckbox
            :label="t('modules.showAForAbeyance')"
            :checked="moduleForm.showAForAbeyance"
            @change="$emit('update:field', 'showAForAbeyance', $event)"
          />

          <BaseCheckbox
            :label="t('modules.showCforALLsiteResponseCompleted')"
            :checked="moduleForm.showCForAllCompletedOnCategory"
            @change="$emit('update:field', 'showCForAllCompletedOnCategory', $event)"
          />
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<style scoped lang="scss">
.edit-module-drawer-blue-rating__content {
  @include grid-columns(1);
  margin-bottom: $spacing-6;

  &__two-columns {
    @include grid-columns(2);
  }

  &__three-columns {
    @include grid-columns(3);
  }
}
</style>
