import { ref } from "vue";
import { useRouter } from "vue-router";
import { AdminRouteName } from "@/router/RouteName";
import {
  endImpersonation as endImpersonationService,
  impersonate as impersonateService,
} from "@/services/impersonate";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";

export const useImpersonation = () => {
  const router = useRouter();
  const isLoadingImpersonation = ref(false);

  const impersonate = async (userId: number) => {
    isLoadingImpersonation.value = true;

    const response = await impersonateService(userId);

    isLoadingImpersonation.value = false;

    if (!response) {
      throw new Error("User not found");
    }

    const { user, isImpersonated } = response;

    const app = useAppStore();
    const auth = useAuthStore();

    app.$patch({ user });
    app.setAppLocale(user.languageCode);

    auth.isImpersonated = isImpersonated;

    router.push({ name: AdminRouteName.AdminDashboard });
  };

  const endImpersonation = async () => {
    isLoadingImpersonation.value = true;

    const response = await endImpersonationService();

    isLoadingImpersonation.value = false;

    if (!response) {
      throw new Error("User not found");
    }

    const { user, isImpersonated } = response;

    const app = useAppStore();
    const auth = useAuthStore();

    app.$patch({ user });
    app.user!.modulePermissions = undefined;
    app.user!.sitePermissions = undefined;
    app.setAppLocale(user.languageCode);

    auth.isImpersonated = isImpersonated;
  };

  return {
    impersonate,
    endImpersonation,
    isLoadingImpersonation,
  };
};
