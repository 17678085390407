import { BlueColor, CategoryDto, QuestionDto } from "@/types/_generated/api";

export const categoryColors: { limit: keyof CategoryDto; color: BlueColor }[] = [
  { limit: "blueLimit", color: BlueColor.Blue },
  { limit: "greenLimit", color: BlueColor.Green },
  { limit: "yellowLimit", color: BlueColor.Yellow },
];

export const questionColors: { score: keyof QuestionDto; color: BlueColor }[] = [
  { score: "blueScore", color: BlueColor.Blue },
  { score: "greenScore", color: BlueColor.Green },
  { score: "yellowScore", color: BlueColor.Yellow },
  { score: "redScore", color: BlueColor.Red },
];
