<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useGetModule } from "@/composables/services/useGetModule";
import { languages } from "@/helpers/options";
import { libraryEvents } from "@/services/library";
import { Language, LibraryFileDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";
import BaseFileUpload from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import { useLibraryFileActions } from "@/components/shared/ModuleLibrary/composables/useLibraryFileActions";
import { useLibraryFileForm } from "@/components/shared/ModuleLibrary/composables/useLibraryFileForm";
import { useLibraryFileUpload } from "@/components/shared/ModuleLibrary/composables/useLibraryFileUpload";

const { moduleId, selectedFile } = defineProps<{
  moduleId: number;
  selectedFile: LibraryFileDto | null;
}>();

defineEmits<{
  "close-drawer": [void];
}>();

const { t } = useI18n({ useScope: "global" });

const { isLoadingModule, module, getModule } = useGetModule();
const { form, setFormFromExistingFile } = useLibraryFileForm();
const { fileUploadRef, getBody, triggerUpload } = useLibraryFileUpload(moduleId, form);
const { isProcessingFile, updateFile, deleteFile } = useLibraryFileActions(moduleId);

const isLoading = computed(() => isLoadingModule.value || isProcessingFile.value);

const isEditMode = computed(() => !!selectedFile);

const handleDelete = async (closeDrawer: () => void, fileId: string) => {
  const success = await deleteFile(fileId);
  if (success) closeDrawer();
};

const handleSubmit = async (closeDrawer: () => void) => {
  if (isEditMode.value && selectedFile) {
    await updateFile({ ...form, fileId: selectedFile.fileId, moduleId });
  } else {
    await triggerUpload();
  }

  libraryEvents.post({ action: isEditMode.value ? "update" : "create" });
  closeDrawer();
};

onMounted(async () => {
  await getModule(moduleId);

  if (selectedFile) {
    setFormFromExistingFile(selectedFile);
  } else {
    // Use default values for new file
    form.language = module.value?.language ?? Language.English;
  }
});
</script>

<template>
  <UWDrawer
    :drawer-title="isEditMode ? t('common.actions.edit') : t('common.actions.upload')"
    :is-loading="isLoading"
    size="small"
    @close-drawer="$emit('close-drawer')"
  >
    <div class="module-library-drawer">
      <BaseSelect
        :label="t('common.language')"
        :value="form.language"
        @change="form.language = $event as Language"
      >
        <BaseOption v-for="option in languages" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>

      <BasePillsInput
        :add-on-space="false"
        :label="t('library.fileUpload.tags')"
        :values="form.tags"
        :maxlength="50"
        @add="form.tags = [...form.tags, $event]"
      >
        <template #pill="{ value, index }">
          <BasePill
            :key="index"
            :label="value"
            clickable
            @click="form.tags = form.tags.filter((_, i) => i !== index)"
          />
        </template>
      </BasePillsInput>

      <BaseCheckbox
        :label="t('library.fileUpload.isVisibleInUserWeb')"
        :checked="form.isVisibleInUserWeb"
        @change="form.isVisibleInUserWeb = !form.isVisibleInUserWeb"
      />

      <BaseFile
        v-if="selectedFile"
        :file="selectedFile"
        :file-url="`/v1/modules/${moduleId}/library-files/${selectedFile.fileId}`"
      />

      <BaseFileUpload
        v-else
        ref="fileUploadRef"
        hide-upload-button
        vertical
        :max-files="1"
        :file-type="FileFormat.Document"
        :url="`/modules/${moduleId}/library-files`"
        :body="getBody"
      />
    </div>

    <template #footer-left="{ onCloseDrawer }">
      <BaseButton :disabled="isLoading" @click="handleSubmit(onCloseDrawer)">
        {{ t("common.actions.confirm") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="onCloseDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right="{ onCloseDrawer }">
      <BaseButton
        v-if="isEditMode && selectedFile?.fileId"
        variant="outlined"
        color="error"
        :disabled="isLoading"
        @click="handleDelete(onCloseDrawer, selectedFile?.fileId)"
      >
        {{ t("common.actions.delete") }}
      </BaseButton>
    </template>
  </UWDrawer>
</template>

<style scoped lang="scss">
.module-library-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}
</style>
