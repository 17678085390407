import { ref, readonly } from "vue";
import type { UpdateRecReportSettings } from "@/types/_generated/api";
import { updateRecReportSettingsService } from "@/services/recommendationReportSettings";

export const useUpdateSettings = () => {
  const isUpdatingSettings = ref(false);

  const updateSettings = async (payload: UpdateRecReportSettings) => {
    isUpdatingSettings.value = true;

    try {
      await updateRecReportSettingsService(payload);
    } finally {
      isUpdatingSettings.value = false;
    }
  };

  return {
    isUpdatingSettings: readonly(isUpdatingSettings),
    updateSettings,
  };
};
