<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { authorize } from "@/plugins/can";
import { Role } from "@/types/_generated/api";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseEntityLink from "../base/BaseEntityLink.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{ label: string; userId?: number; userRole?: Role }>();

const emit = defineEmits<{
  click: [userId: number];
}>();

const isDisabled = computed(
  () =>
    !props.userId ||
    !props.userRole ||
    // If the user is not the same as the logged in user and the logged in user is not a superior to the user
    (!authorize.isSelf(props.userId) && !authorize.isSuperiorTo(props.userRole)),
);

const onClick = () => {
  if (!props.userId) {
    throw new Error("User ID is required");
  }

  emit("click", props.userId);
};
</script>

<template>
  <BaseEntityLink
    :label="label"
    :title="t('navigation.entityLink.userDetails')"
    :disabled="isDisabled"
    @click="onClick"
  >
    <template #icon>
      <BaseIcon icon="info" />
    </template>
  </BaseEntityLink>
</template>
