<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";

type Props = {
  publishRiskManagerComments: boolean;
  isSaving: boolean;
};
defineProps<Props>();
const emit = defineEmits<{
  save: [];
  "update:publishRiskManagerComments": [boolean];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="rm-comments-save">
    <div class="rm-comments-save__buttons">
      <div class="rm-comments-save__buttons__save-options">
        <BaseCheckbox
          :checked="publishRiskManagerComments"
          :label="t('userWeb.spoeCheck.publishRiskManagerComments')"
          @change="emit('update:publishRiskManagerComments', !publishRiskManagerComments)"
        />
      </div>

      <BaseButton :disabled="isSaving" @click="emit('save')">
        {{ t("common.actions.save") }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rm-comments-save {
  margin-top: $spacing-16;
  padding-top: $spacing-4;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: $spacing-4;
  border-top: 1px solid $primary-5;

  &__buttons {
    display: flex;
    align-items: center;
    gap: $spacing-4;

    &__save-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacing-1;
    }
  }
}
</style>
