import { ComputedRef, ref } from "vue";
import { useI18n } from "vue-i18n";
import { bytesToSize } from "@/helpers/bytesToSize";
import { getFileExtension } from "@/helpers/getFileExtension";

export const useValidateFile = (
  validations: ComputedRef<{
    maxSize?: number;
    minSize?: number;
    maxFiles?: number;
    allowedFileTypes?: string[];
  }>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const errors = ref<string[]>([]);

  const addError = (message: string, fileName?: string) => {
    const imageText = fileName ? `${fileName}: ${message}` : message;
    errors.value.push(imageText);
  };

  const validateFiles = (fileList: FileList | null): boolean => {
    const inputFiles = Array.from(fileList ?? []);

    const { maxSize, minSize, maxFiles, allowedFileTypes } = validations.value;

    if (maxFiles && inputFiles.length > maxFiles) {
      const maxFilesText =
        maxFiles === 1 ? t("library.fileUpload.file") : t("library.fileUpload.files");

      addError(
        t("library.fileUpload.errors.maxFiles", {
          maxFiles,
          text: `${maxFilesText}`,
        }),
      );

      return false;
    }

    for (const file of inputFiles) {
      if (
        allowedFileTypes?.length &&
        !allowedFileTypes?.includes(getFileExtension(file.name).toLowerCase())
      ) {
        addError(
          t("library.fileUpload.errors.fileType", {
            types: allowedFileTypes.join(", "),
          }),
          file.name,
        );
      }

      if (maxSize && file.size > maxSize!) {
        addError(
          t("library.fileUpload.errors.fileSize", {
            size: `${bytesToSize(maxSize)}`,
          }),
          file.name,
        );
      }

      if (minSize && file.size < minSize!) {
        addError(
          t("library.fileUpload.errors.fileMinSize", {
            size: `${bytesToSize(minSize)}`,
          }),
          file.name,
        );
      }
    }

    return !errors.value.length;
  };

  return {
    validateFiles,
    errors,
  };
};
