<script setup lang="ts">
import { computed } from "vue";
import router from "@/router";
import { RecRespRouteName } from "@/router/RouteName";
import RecResponseHeader from "@/components/app/RecResponse/RecResponseHeader.vue";

const shouldShowHeader = computed(() => {
  const currentRouteName = router.currentRoute.value.name;
  return (
    currentRouteName !== RecRespRouteName.Login && currentRouteName !== RecRespRouteName.SignedOut
  );
});
</script>

<template>
  <RecResponseHeader v-if="shouldShowHeader" />
  <router-view></router-view>
</template>
