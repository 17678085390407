import { reactive } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import { FilterChartOptions } from "../constants/FilterChartOptions";

export class DaatClientFilter {
  selectedChartOptions: Set<string> = new Set([
    FilterChartOptions.Autoscale,
    FilterChartOptions.Percentage,
  ]);
}

export const useFilterClient = () => {
  const clientFilter = reactive<DaatClientFilter>(new DaatClientFilter());

  const updateClientFilter = (newFilter: DaatClientFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(clientFilter, newFilter)) return;

    // Update the filter state with the incoming new values
    Object.assign(clientFilter, newFilter);
  };

  return {
    clientFilter,
    updateClientFilter,
  };
};
