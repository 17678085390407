<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.75C0 1.33579 0.335786 1 0.75 1H15.25C15.6642 1 16 1.33579 16 1.75C16 2.16421 15.6642 2.5 15.25 2.5H0.75C0.335786 2.5 0 2.16421 0 1.75Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 14.25C0 13.8358 0.335786 13.5 0.75 13.5H15.25C15.6642 13.5 16 13.8358 16 14.25C16 14.6642 15.6642 15 15.25 15H0.75C0.335786 15 0 14.6642 0 14.25Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8C0 7.58579 0.335786 7.25 0.75 7.25H15.25C15.6642 7.25 16 7.58579 16 8C16 8.41421 15.6642 8.75 15.25 8.75H0.75C0.335786 8.75 0 8.41421 0 8Z"
    />
  </svg>
</template>
