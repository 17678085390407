import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { printSupportInfo } from "@/helpers/supportInfo";
import { setupI18n } from "@/i18n";
import { UserWebRouteName, AdminRouteName } from "@/router/RouteName";
import { getApplicationSettings } from "@/services/applicationSettings";
import { CurrentUserDto, GetApplicationSettingsResponse, Role } from "@/types/_generated/api";

export type Settings = Omit<GetApplicationSettingsResponse, "responseStatus"> & {
  navigation: {
    show: boolean;
    minimized: boolean;
  };
  locale: string;
};

export const useAppStore = defineStore("appStore", () => {
  const defaultSettings: Settings = {
    navigation: {
      show: false,
      minimized: false,
    },
    locale: "en",
    // GetApplicationSettingsResponse below
    bingMapKey: undefined,
    documentFileExtensions: [],
    recommendationResponseFileExtensions: [],
    documentMaxSizeInBytes: 0,
    imageFileExtensions: [],
    imageMaxSizeInBytes: 0,
    version: "",
  };

  const settings = ref<Settings>(defaultSettings);
  const router = useRouter();

  const supportedLocales = ref([
    { icon: "IconEnglish", title: "English", value: "en" },
    { icon: "IconSwedish", title: "Svenska", value: "sv" },
    { icon: "IconRussian", title: "русский", value: "ru" },
  ]);

  const user = ref<CurrentUserDto>();

  const isAdmin = computed(() =>
    user.value ? [Role.SystemOwner, Role.SA, Role.BA].includes(user.value.role) : false,
  );

  const getCurrentLanguage = computed(
    () => supportedLocales.value.find((item) => item.value === settings.value.locale)?.title,
  );

  const setAppLocale = (locale: string) => {
    setupI18n({ locale });
    settings.value.locale = locale;
  };

  const loadSettings = async () => {
    const data = await getApplicationSettings();
    if (data) {
      settings.value = { ...settings.value, ...data };
    }
  };

  const setUser = async (currentUser: CurrentUserDto) => {
    if (!user.value && currentUser) {
      await loadSettings();
    }
    user.value = currentUser;
    setAppLocale(currentUser.languageCode);

    printSupportInfo(settings.value, currentUser);
  };

  const redirectOnLogin = () => {
    if (router.currentRoute.value.query?.go) {
      router.push(decodeURIComponent(router.currentRoute.value.query.go as string));
    } else if (user.value?.role === Role.EXT && user.value?.allowUserWeb) {
      router.push({ name: UserWebRouteName.Modules });
    } else {
      router.push({ name: AdminRouteName.AdminDashboard });
    }
  };

  const $reset = () => {
    settings.value = defaultSettings;
    user.value = undefined;
  };

  return {
    settings,
    supportedLocales,
    user,
    getCurrentLanguage,
    isAdmin,
    redirectOnLogin,
    setAppLocale,
    setUser,
    $reset,
  };
});
