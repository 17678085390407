<script setup lang="ts">
import { computed, onBeforeMount, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useBroadcastService } from "@/composables/useBroadcastService";
import useDesign from "@/composables/useDesign";
import { moveElement } from "@/helpers/array";
import { recommendationEvents } from "@/services/recommendations";
import { notify } from "@/stores/notifications";
import { ModuleDto, Status, BlueRatingDto, SurveyorStatus } from "@/types/_generated/api";
import { useHandleRecommendationImages } from "@/views/Admin/BlueRating/composables/useHandleRecommendationImages";
import { useHandleRecommendations } from "@/views/Admin/BlueRating/composables/useHandleRecommendations";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import RecommendationImageList from "@/components/shared/RecommendationImageList.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ResponseAndCommentTimeline from "@/components/shared/RecommendationResponse/ResponseAndCommentTimeline.vue";
import ResponseFileList from "@/components/shared/RecommendationResponse/ResponseFileList.vue";
import BlueRatingCostBenefit from "./BlueRatingCostBenefit.vue";
import BlueRatingRecommendationDrawerSubtitle from "./BlueRatingRecommendationDrawerSubtitle.vue";
import BlueRatingRecommendationDrawerGeneral from "./BlueRatingRecommendationGeneral.vue";
import { useBlueRatingRecommendationDrawerTabs } from "./composables/useBlueRatingRecommendationDrawerTabs";
import { useRecommendationForm } from "./composables/useRecommendationForm";
import { useRecommendationValidation } from "./composables/useRecommendationValidation";
import { useUpdateRecImagePosition } from "./composables/useUpdateRecImagePosition";
import { RecommendationDrawerTabs } from "./constants/RecommendationDrawerTabs";

const { t } = useI18n({ useScope: "global" });
const {
  mediaQuery: { isWidthLessThan },
} = useDesign();

const props = defineProps<{
  survey: BlueRatingDto;
  recommendationId: number;
  module: ModuleDto;
}>();

const emits = defineEmits<{
  close: [void];
}>();

const {
  recommendation,
  isHandlingRecommendation,
  loadRecommendation,
  saveRecommendation,
  deleteRecommendation,
  copyToStandardRecommendation,
  discardUnsavedChanges,
} = useHandleRecommendations(
  toRef(() => props.survey),
  toRef(() => props.module),
);

const { errorMessage, tabsWithErrors, resetErrorMessage, isFormValid } =
  useRecommendationValidation();
const { activeTab, changeTab, tabs } = useBlueRatingRecommendationDrawerTabs(tabsWithErrors);
const { recommendationForm, updateRecommendationField, updateRecommendationForm } =
  useRecommendationForm(resetErrorMessage);

const { isUpdatingRecImagePosition, updateRecImagePosition } = useUpdateRecImagePosition();

const { deleteRecommendationImage, reloadRecommendationImages, saveRecommendationImageCaption } =
  useHandleRecommendationImages(recommendation);

useBroadcastService(
  recommendationEvents,
  async () => await loadRecommendation(props.survey.surveyId, props.recommendationId),
  ["create", "update"],
);

const drawerWidth = computed(() =>
  isWidthLessThan("lg")
    ? "100"
    : isWidthLessThan("xl")
      ? "75"
      : isWidthLessThan("4xl")
        ? "50"
        : "30",
);

const closeDrawer = async () => {
  if (await discardUnsavedChanges(recommendationForm.value)) {
    emits("close");
  }
};

const updateImagePositions = async (fileId: string, currentIndex: number, newIndex: number) => {
  if (!recommendation.value) {
    throw new Error("Recommendation has not been loaded");
  }

  moveElement(recommendation.value.images, currentIndex, newIndex);

  recommendation.value.images = recommendation.value.images.map((image, index) => ({
    ...image,
    position: index + 1,
  }));

  await updateRecImagePosition(props.survey.surveyId, props.recommendationId, fileId, newIndex + 1);
};

const isReadOnly = computed(() => {
  if (props.survey.status === Status.Published) {
    return true;
  }

  switch (recommendation.value?.surveyorStatus) {
    case SurveyorStatus.Completed:
    case SurveyorStatus.Rejected:
    case SurveyorStatus.Withdrawn:
      return true;
    default:
      return false;
  }
});

watch(
  () => recommendationForm.value?.surveyorStatus,
  () => {
    if (
      recommendationForm.value?.surveyorStatus === SurveyorStatus.Completed &&
      !recommendationForm.value?.completedDate
    ) {
      updateRecommendationField("completedDate", props.survey.surveyDate);
    }
  },
);

const onSaveRecommendation = async () => {
  if (!recommendationForm.value) return;
  if (!isFormValid(recommendationForm.value, props.module)) return;

  if (recommendationForm.value.surveyorStatus !== SurveyorStatus.Abeyance) {
    updateRecommendationField("abeyanceDate", undefined);
    updateRecommendationField("abeyanceComment", "");
  }

  if (recommendationForm.value.surveyorStatus !== SurveyorStatus.Completed) {
    updateRecommendationField("completedDate", undefined);
    updateRecommendationField("completedComment", "");
  }

  if (!recommendationForm.value.surveyId) {
    updateRecommendationField("surveyId", props.survey.surveyId);
  }

  await saveRecommendation(recommendationForm.value);
};

const onCopyToStandardRecommendation = async () => {
  if (!recommendationForm.value) return;
  if (!isFormValid(recommendationForm.value, props.module)) return;

  await copyToStandardRecommendation(recommendationForm.value);
};

const onDeleteRecommendation = async () => {
  await deleteRecommendation();
  emits("close");
};

onBeforeMount(async () => {
  await loadRecommendation(props.survey.surveyId, props.recommendationId);
  if (!recommendation.value) return;

  updateRecommendationForm(recommendation.value);
});
</script>

<template>
  <BaseDrawer
    :width="drawerWidth"
    class="blue-rating-recommendation"
    :is-loading="isHandlingRecommendation"
    @close="closeDrawer"
  >
    <template #title>
      {{
        recommendation?.isGeneralRecommendation
          ? t("blueRating.recommendation.editGeneralRecommendation")
          : t("blueRating.recommendation.editRecommendation")
      }}
      <span class="blue-rating-recommendation__title">
        {{ t("surveys.survey") }}
        {{ survey.surveyDate }}
        <BaseStatusBadge :status="survey.status" />
      </span>
    </template>
    <template #subtitle>
      <BlueRatingRecommendationDrawerSubtitle
        v-if="recommendation"
        :recommendation="recommendation"
        @close-drawer="closeDrawer"
      />
    </template>

    <BaseDrawerTabs
      v-if="recommendationForm"
      :current-tab="activeTab"
      :tabs="tabs"
      @change="changeTab"
    >
      <BlueRatingRecommendationDrawerGeneral
        v-if="recommendation && activeTab === RecommendationDrawerTabs.General"
        :disabled="isReadOnly"
        :survey="survey"
        :module="module"
        :recommendation="recommendationForm"
        :spoe-check-comment="recommendation.spoeCheckComment"
        :error-message="errorMessage"
        @update="updateRecommendationField"
      />

      <RecommendationImageList
        v-if="recommendation && activeTab === RecommendationDrawerTabs.Images"
        :disabled="isReadOnly || isUpdatingRecImagePosition"
        :recommendation-id="recommendation.recommendationId"
        :url="`surveys/${survey.surveyId}/recommendations/${recommendation.recommendationId}/files`"
        :src="`/v1/surveys/${survey.surveyId}/recommendations/${recommendation.recommendationId}/images?fileId=`"
        :images="recommendation.images"
        @delete="deleteRecommendationImage"
        @files-uploaded="
          () => {
            notify.success(
              t('standardRecommendations.standardRecommendation.image'),
              t('common.uploaded').toLowerCase(),
            );
            reloadRecommendationImages();
          }
        "
        @update:caption="saveRecommendationImageCaption"
        @update:position="updateImagePositions"
      />

      <BlueRatingCostBenefit
        v-if="recommendation && activeTab === RecommendationDrawerTabs.CostBenefit"
        :disabled="isReadOnly"
        :description="recommendation?.rcbaDescription"
        :rcba="recommendationForm"
        @update="updateRecommendationField"
      />

      <div
        v-if="recommendation && activeTab === RecommendationDrawerTabs.SiteResponseSummary"
        class="blue-rating-recommendation__summary"
      >
        <h3>{{ t("blueRating.recommendation.recommendationResponse.title") }}</h3>
        <h3>{{ t("blueRating.recommendation.riskManagerComments") }}</h3>

        <LatestRecommendationResponse
          :currency="module.currencyCode"
          :recommendation-response="recommendation.responseHistory"
        >
          <template #filelist="{ response }">
            <ResponseFileList :module-id="module.moduleId" :response="response" />
          </template>
        </LatestRecommendationResponse>
        <LatestRiskManagerComment :comments="recommendation.riskManagerCommentHistory" />
      </div>
      <div
        v-if="recommendation && activeTab === RecommendationDrawerTabs.SiteResponseHistory"
        class="blue-rating-recommendation__history"
      >
        <ResponseAndCommentTimeline
          :currency="module.currencyCode"
          :module-id="module.moduleId"
          :response-history="recommendation.responseHistory.history"
          :risk-manager-comment-history="recommendation.riskManagerCommentHistory"
          :title="t('recommendationResponse.responseHistory')"
        />
      </div>
    </BaseDrawerTabs>

    <template #footer-left>
      <BaseButton @click="onSaveRecommendation">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseButton
        v-if="recommendationForm"
        variant="outlined"
        @click="onCopyToStandardRecommendation"
      >
        {{ t("blueRating.recommendation.copyToStandardRecommendation") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton
        v-if="recommendation && !recommendation.parentRecommendationId"
        color="error"
        variant="outlined"
        @click="onDeleteRecommendation"
      >
        {{ t("blueRating.recommendation.deleteRecommendation") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.blue-rating-recommendation {
  &__title {
    margin-left: $spacing-2;
    font-weight: normal;
    font-size: $text-sm;
  }

  &__summary {
    padding: $spacing-8;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-8;
  }

  &__history {
    padding: $spacing-8;
  }
}
</style>
