<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5C6 2.23858 8.23859 0 11 0C13.7614 0 16 2.23858 16 5C16 7.76141 13.7614 10 11 10C10.7808 10 10.5646 9.98586 10.3523 9.95835L10.0303 10.2803C9.88968 10.421 9.69891 10.5 9.5 10.5H8.5V11.5C8.5 11.6989 8.42098 11.8897 8.28033 12.0303L7.03033 13.2803C6.88968 13.421 6.69891 13.5 6.5 13.5H5.5V14.5C5.5 14.6989 5.42098 14.8897 5.28033 15.0303L4.53033 15.7803C4.38968 15.921 4.19891 16 4 16H0.75C0.335786 16 0 15.6642 0 15.25V12C0 11.8011 0.079018 11.6103 0.219671 11.4697L6.04165 5.64771C6.01414 5.4354 6 5.21919 6 5ZM11 1.5C9.06701 1.5 7.5 3.067 7.5 5C7.5 5.25645 7.52744 5.50557 7.57929 5.74491C7.63329 5.99414 7.55695 6.25373 7.37663 6.43405L1.5 12.3107V14.5H3.68934L4 14.1893V12.75C4 12.3358 4.33579 12 4.75 12H6.18934L7 11.1893V9.75C7 9.33579 7.33579 9 7.75 9H9.18934L9.56597 8.62337C9.7463 8.44304 10.0059 8.3667 10.2551 8.42071C10.4944 8.47256 10.7436 8.5 11 8.5C12.933 8.5 14.5 6.93299 14.5 5C14.5 3.067 12.933 1.5 11 1.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 4C11 3.44769 11.4478 3 12 3C12.5522 3 13 3.44769 13 4C13 4.55231 12.5522 5 12 5C11.4478 5 11 4.55231 11 4Z"
    />
  </svg>
</template>
