<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton, { type Color } from "./BaseButton.vue";

const { t } = useI18n({ useScope: "global" });

export interface Props {
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  confirmColor?: Color;
  hideCancel?: boolean;
  size?: "medium" | "large";
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  description: "",
  cancelText: "",
  confirmText: "",
  confirmColor: undefined,
  hideCancel: false,
  size: "medium",
});

defineEmits<{
  (event: "cancel"): void;
  (event: "confirm"): void;
}>();
</script>

<template>
  <div class="base-modal" role="dialog" :aria-label="title">
    <div class="base-modal__background" @click="$emit('cancel')" />

    <div class="base-modal__card" :class="`base-modal__card--${props.size}`">
      <div class="base-modal__card__content">
        <p v-if="title" class="base-modal__card__title">
          {{ title }}
        </p>

        <div class="base-modal__card__desc">
          <slot>
            <p v-if="description">
              {{ description }}
            </p>
          </slot>
        </div>
      </div>

      <div class="base-modal__card__controls">
        <slot name="controls">
          <BaseButton :color="confirmColor" @click="$emit('confirm')">
            {{ confirmText ? confirmText : t("common.actions.confirm") }}
          </BaseButton>
          <BaseButton v-if="!hideCancel" variant="outlined" @click="$emit('cancel')">
            {{ cancelText ? cancelText : t("common.actions.cancel") }}
          </BaseButton>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: $z-20;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 2, 30, 0.5);
  }

  &__card {
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    width: 100%;
    background: $primary-0;
    border-radius: $rounded-base;
    box-shadow: 0 4px 6px -2px rgba(54, 64, 73, 0.1);
    z-index: 1;

    &--large {
      max-width: 40rem;
    }
    &--large &__desc {
      max-height: 30rem;
    }

    &__content {
      padding: $spacing-5 $spacing-6;
      border-radius: $rounded-base $rounded-base 0 0;
    }

    &__title {
      font-weight: $font-medium;
      margin-bottom: $spacing-3;
    }

    &__desc {
      max-height: 20rem;
      overflow: auto;
      color: $primary-8;
      line-height: $leading-relaxed;
    }

    &__controls {
      display: flex;
      padding: $spacing-5 $spacing-6;
      border-top: 1px solid $primary-2;
      border-radius: 0 0 $rounded-base $rounded-base;
      gap: $spacing-2;
    }
  }
}
</style>
