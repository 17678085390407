<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BlueColor } from "@/types/_generated/api";
import { HorizontalSizes, VerticalSizes } from "@/types/CSSUnits";

const { t } = useI18n({ useScope: "global" });

withDefaults(
  defineProps<{
    recommendationCount?: number;
    color?: BlueColor;
    size?: `${number}${HorizontalSizes | VerticalSizes}`;
    round?: boolean;
    circle?: boolean;
  }>(),
  {
    recommendationCount: 0,
    color: BlueColor.NotSet,
    size: "100%",
    round: false,
    circle: false,
  },
);
</script>

<template>
  <div
    :class="{
      'blue-rating-color-ball': true,
      'blue-rating-color-ball--round': round,
      'blue-rating-color-ball--circle': circle,
      [`blue-rating-color-ball--${color.toLowerCase()}`]: true,
    }"
  >
    <span
      v-if="recommendationCount"
      :title="t('blueRating.nav.recommendationCount', { entity: $props.recommendationCount })"
    >
      {{ recommendationCount }}
    </span>

    <span v-else>&nbsp;</span>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-color-ball {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $text-sm;
  border-radius: 0 $rounded-base $rounded-base 0;
  width: v-bind(size);
  height: v-bind(size);

  &--round {
    border-radius: $rounded-base;
  }

  &--circle {
    border-radius: $rounded-full;
    width: 10px;
    height: 10px;
  }

  @include blue-rating-styles;
}
</style>
