<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BlueRatingCategoryDto } from "@/types/_generated/api";
import BaseDropdownMenu, {
  MenuOption,
} from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

interface Props {
  category: BlueRatingCategoryDto;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "new-recommendation": [BlueRatingCategoryDto];
  "copy-standard-rec": [BlueRatingCategoryDto];
}>();

const { t } = useI18n({ useScope: "global" });

const recommendationMenuOptions: MenuOption[] = [
  {
    label: t("blueRating.recommendation.addRecommendation"),
    action: () => emit("new-recommendation", props.category),
  },
  {
    label: t("blueRating.recommendation.copyFromStandardRec"),
    action: () => emit("copy-standard-rec", props.category),
  },
];
</script>

<template>
  <BaseDropdownMenu
    :options="recommendationMenuOptions"
    :position="['bottom', 'left']"
    variant="outlined"
  >
    <template #icon>
      <BaseIcon icon="plus" />
    </template>
  </BaseDropdownMenu>
</template>
