<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { authorize } from "@/plugins/can";
import { Role } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";

interface Props {
  isCreate: boolean;
  standardRecommendationId?: number;
}

defineProps<Props>();

const emit = defineEmits<{
  save: [void];
  delete: [number];
}>();

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();

const onClickDelete = async (standardRecommendationId: number) => {
  const isConfirmed = await dialog.openDeletePrompt(
    t("standardRecommendations.standardRecommendation.deleteStandardRecommendation"),
  );

  if (isConfirmed) {
    emit("delete", standardRecommendationId);
  }
};
</script>

<template>
  <BaseButton @click="emit('save')">
    {{ isCreate ? t("common.actions.create") : t("common.actions.save") }}
  </BaseButton>

  <BaseButton
    v-if="authorize.isSuperiorOrEqualTo(Role.BA) && standardRecommendationId"
    color="error"
    variant="outlined"
    @click="onClickDelete(standardRecommendationId)"
  >
    {{ t("standardRecommendations.standardRecommendation.deleteStandardRecommendation") }}
  </BaseButton>
</template>
