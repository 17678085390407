<template>
  <main class="rec-resp-view-layout">
    <slot></slot>
  </main>
</template>

<style scoped lang="scss">
.rec-resp-view-layout {
  height: $view-height;
  overflow: auto;
  padding: $spacing-8;
}
</style>
