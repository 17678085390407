<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { StorageSchema } from "@/composables/useStorage/StorageSchema";
import { OptionValue } from "@/types/Option";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseOption from "../../BaseSelect/BaseOption.vue";
import BaseSelect from "../../BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    start: number;
    end: number;
    storageKey?: keyof StorageSchema;
    totalRowCount?: number;
    rowsPerPage: number;
    isLoading?: boolean;
  }>(),
  {
    rows: () => [],
    storageKey: undefined,
    totalRowCount: 0,
    isLoading: false,
  },
);

const rowsPerPageOptions = [10, 25, 50, 100] as const;

const emits = defineEmits<{
  (event: "onPageNavigation", value: { start: number; end: number }): void;
  (event: "onRowsPerPage", value: number): void;
}>();

const onPageNavigation = (direction: "previous" | "next") => {
  let { start, end } = props;

  if (direction === "previous") {
    start -= props.rowsPerPage;
    end -= props.rowsPerPage;
  } else if (direction === "next") {
    start += props.rowsPerPage;
    end += props.rowsPerPage;
  }

  emits("onPageNavigation", { start, end });
};
</script>

<template>
  <div class="base-grid-table-page-controls">
    <span class="base-grid-table-page-controls__rows-per-page">
      {{ t("library.table.rowsPerPage") }}&colon;
    </span>

    <BaseSelect
      :value="rowsPerPage"
      @change="(event: OptionValue) => emits('onRowsPerPage', Number(event))"
    >
      <BaseOption
        v-for="option in rowsPerPageOptions"
        :key="option"
        :value="option"
        :title="option.toString()"
      >
        {{ option }}
      </BaseOption>
    </BaseSelect>

    <div>
      <span>
        {{ totalRowCount > 0 ? `${start + 1} - ` : "" }}
        {{
          t("library.table.countOfTotal", {
            count: end > totalRowCount ? totalRowCount : end,
            total: totalRowCount,
          })
        }}
      </span>
    </div>

    <BaseButton
      variant="outlined"
      :disabled="start === 0"
      :title="t('common.actions.previous')"
      @click="() => onPageNavigation('previous')"
    >
      <BaseIcon icon="chevron-left" />
    </BaseButton>

    <BaseButton
      variant="outlined"
      :disabled="end >= totalRowCount"
      :title="t('common.actions.next')"
      @click="() => onPageNavigation('next')"
    >
      <BaseIcon icon="chevron-right" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.base-grid-table-page-controls {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: $spacing-4;
  padding: $spacing-4 0;
  border-top: 1px solid $primary-4;
  justify-content: end;
  background: $primary-1;
  font-size: $text-sm;

  &__rows-per-page {
    display: flex;
    text-align: end;
    padding-left: $spacing-4;
  }
}
</style>
