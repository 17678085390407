import { ref, readonly } from "vue";
import { deleteRecReportSettingsService } from "@/services/recommendationReportSettings";

export const useDeleteSettings = (getSettings: () => Promise<void>) => {
  const isDeletingSettings = ref(false);

  const deleteSettings = async (moduleId: number, settingId?: number) => {
    if (!settingId) {
      throw new Error("SettingId is required");
    }

    isDeletingSettings.value = true;

    try {
      await deleteRecReportSettingsService({ moduleId, settingId });
      getSettings(); // Fetch the settings again to update the UI
    } finally {
      isDeletingSettings.value = false;
    }
  };

  return {
    isDeletingSettings: readonly(isDeletingSettings),
    deleteSettings,
  };
};
