import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { OS, userOS } from "@/helpers/detectOS";
import type { EditorButton } from "../components/BaseEditorButton.vue";
import { Editor } from "../constants";

const getShortcut = (command: Editor.Command) => {
  const supportedOS: Partial<OS>[] = [OS.Windows, OS.macOS];

  // If the user OS is not supported, return an empty string
  if (!supportedOS.includes(userOS)) return "";

  const shortcuts: Partial<Record<Editor.Command, { [key in OS]?: string }>> = {
    [Editor.Command.Bold]: {
      [OS.Windows]: "Ctrl-B",
      [OS.macOS]: "⌘B",
    },
    [Editor.Command.Italic]: {
      [OS.Windows]: "Ctrl-I",
      [OS.macOS]: "⌘I",
    },
    [Editor.Command.Underline]: {
      [OS.Windows]: "Ctrl-U",
      [OS.macOS]: "⌘U",
    },
    [Editor.Command.Strikethrough]: {
      [OS.Windows]: "Ctrl-Shift-7",
      [OS.macOS]: "⇧⌘7",
    },
    [Editor.Command.UnorderedList]: {
      [OS.Windows]: "Ctrl-Shift-8",
      [OS.macOS]: "⇧⌘8",
    },
    [Editor.Command.OrderedList]: {
      [OS.Windows]: "Ctrl-Shift-9",
      [OS.macOS]: "⇧⌘9",
    },
  };

  return shortcuts[command]?.[userOS];
};

export const useToolbarConfig = (enableImageUpload?: boolean) => {
  const { t } = useI18n({ useScope: "global" });

  const toolbarButtons = ref<EditorButton[]>([
    {
      command: Editor.Command.Bold,
      icon: "bold",
      title: `${t("library.editor.bold")}
${getShortcut(Editor.Command.Bold)}`, // Intentional formatting to have it on a new line
    },
    {
      command: Editor.Command.Italic,
      icon: "italic",
      title: `${t("library.editor.italic")}
${getShortcut(Editor.Command.Italic)}`,
    },
    {
      command: Editor.Command.Underline,
      icon: "underline",
      title: `${t("library.editor.underline")}
${getShortcut(Editor.Command.Underline)}`,
    },
    {
      command: Editor.Command.Strikethrough,
      icon: "strikethrough",
      title: `${t("library.editor.strikethrough")}
${getShortcut(Editor.Command.Strikethrough)}`,
      hasTrailingDivider: true,
    },
    {
      command: Editor.Command.TextSize,
      icon: "text-size",
      title: t("library.editor.textSize"),
      hasTrailingDivider: true,
    },
    {
      command: Editor.Command.TextColor,
      icon: "text-color",
      title: t("library.editor.textColor"),
      hasTrailingDivider: true,
    },
    {
      command: Editor.Command.UnorderedList,
      icon: "unordered-list",
      title: `${t("library.editor.unorderedList")}
${getShortcut(Editor.Command.UnorderedList)}`,
    },
    {
      command: Editor.Command.OrderedList,
      icon: "ordered-list",
      title: `${t("library.editor.orderedList")}
${getShortcut(Editor.Command.OrderedList)}`,
      hasTrailingDivider: true,
    },
    {
      command: Editor.Command.ClearFormatting,
      icon: "text-format",
      title: t("library.editor.clearFormatting"),
      hasTrailingDivider: !!enableImageUpload,
    },
  ]);

  if (enableImageUpload) {
    toolbarButtons.value.push({
      command: Editor.Command.Image,
      icon: "image",
      title: t("library.editor.image"),
    });
  }

  return { toolbarButtons };
};
