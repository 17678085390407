<script setup lang="ts">
import { toRef } from "vue";
import { RecReportTextDto } from "@/types/_generated/api";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { SectionKeys, SectionFormData, UpdateFormField } from "../types";
import { useFormStructure } from "../composables/useFormStructure";
import { FieldType } from "../constants";
import RecReportFormResetButton from "./RecReportFormResetButton.vue";

const props = defineProps<{
  isLoading: boolean;
  selectedSectionKey: SectionKeys;
  selectedSectionForm: SectionFormData;
}>();

const emit = defineEmits<{
  "reset:form-field": [Omit<UpdateFormField, "value">];
  "update:form-field": [UpdateFormField];
}>();

const { currentSectionData } = useFormStructure(toRef(props, "selectedSectionKey"));

const updateField = (key: UpdateFormField["key"], value: UpdateFormField["value"]) => {
  emit("update:form-field", { sectionKey: props.selectedSectionKey, key, value });
};

const getTextValue = (value: UpdateFormField["value"]) => {
  if (typeof value === "string") return value;

  const recReportText = value as RecReportTextDto;
  return recReportText.custom || recReportText.default;
};

const isCustomValue = (value: UpdateFormField["value"]) => {
  if (props.isLoading) return false;
  return Boolean((value as RecReportTextDto).custom);
};

const resetField = (key: UpdateFormField["key"]) => {
  emit("reset:form-field", { sectionKey: props.selectedSectionKey, key });
};
</script>

<template>
  <div class="rec-report-section-form">
    <div v-for="(field, index) in currentSectionData" :key="field.key">
      <BaseCheckbox
        v-if="field.type === FieldType.Checkbox"
        :disabled="isLoading"
        :label="field.label"
        :checked="Boolean(selectedSectionForm[field.key as UpdateFormField['key']])"
        @change="updateField(field.key as UpdateFormField['key'], $event)"
      />

      <div v-else-if="field.type === FieldType.Text">
        <div class="rec-report-section-form__label">
          <BaseLabel>{{ field.label }}</BaseLabel>
          <RecReportFormResetButton
            v-if="isCustomValue(selectedSectionForm[field.key as UpdateFormField['key']])"
            @click="resetField(field.key as UpdateFormField['key'])"
          />
        </div>
        <BaseTextField
          :disabled="isLoading"
          :value="getTextValue(selectedSectionForm[field.key as UpdateFormField['key']])"
          @update:value="updateField(field.key as UpdateFormField['key'], $event)"
        />
      </div>

      <div v-else-if="field.type === FieldType.TextArea">
        <div class="rec-report-section-form__label">
          <BaseLabel>{{ field.label }}</BaseLabel>
          <RecReportFormResetButton
            v-if="isCustomValue(selectedSectionForm[field.key as UpdateFormField['key']])"
            @click="resetField(field.key as UpdateFormField['key'])"
          />
        </div>
        <BaseTextArea
          :disabled="isLoading"
          :value="getTextValue(selectedSectionForm[field.key as UpdateFormField['key']])"
          @update:value="updateField(field.key as UpdateFormField['key'], $event)"
        />
      </div>

      <div v-else-if="field.type === FieldType.Editor">
        <div class="rec-report-section-form__label">
          <BaseLabel>{{ field.label }}</BaseLabel>
          <RecReportFormResetButton
            v-if="isCustomValue(selectedSectionForm[field.key as UpdateFormField['key']])"
            @click="resetField(field.key as UpdateFormField['key'])"
          />
        </div>
        <BaseEditor
          :key="`${selectedSectionForm.sectionType}-${field.key}`"
          :disabled="isLoading"
          :enable-image-upload="field.enableImageUpload"
          :value="getTextValue(selectedSectionForm[field.key as UpdateFormField['key']])"
          @input="updateField(field.key as UpdateFormField['key'], $event)"
        />
      </div>

      <div v-else-if="field.type === FieldType.Select">
        <BaseLabel has-spacing>{{ field.label }}</BaseLabel>
        <BaseSelect
          :disabled="isLoading"
          :value="String(selectedSectionForm[field.key as UpdateFormField['key']])"
          @change="updateField(field.key as UpdateFormField['key'], $event)"
        >
          <BaseOption v-for="option in field.options" :key="option.value" :value="option.value">
            {{ option.title }}
          </BaseOption>
        </BaseSelect>
      </div>

      <BaseDivider
        v-if="index !== currentSectionData.length - 1"
        class="rec-report-section-form__divider"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.rec-report-section-form {
  display: flex;
  flex-direction: column;

  &__divider {
    margin: $spacing-4 0;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-2;
  }
}
</style>
