<script setup lang="ts">
import { computed } from "vue";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { toPercent } from "@/helpers/formatNumber";
import { SiteResponseStatus } from "@/types/_generated/api";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import { BORDER } from "@/components/base/BaseChart/constants/style";

const props = defineProps<{
  chartData: TrackedRecommendation[];
}>();

const { statusData } = useSiteResponseStatusChartMetaData();

const groupedBySiteResponseStatus = computed(() =>
  props.chartData.reduce(
    (acc, recommendation) => {
      const { siteResponseStatus } = recommendation;
      acc[siteResponseStatus]++;
      return acc;
    },
    {
      Rejected: 0,
      Completed: 0,
      InProgress: 0,
      UnderReview: 0,
      NoProgress: 0,
      NotSet: 0,
    },
  ),
);

const seriesData = computed(() => {
  return Object.entries(groupedBySiteResponseStatus.value)
    .map(([status, count]) => {
      const statusKey = statusData[status as SiteResponseStatus];
      return {
        name: statusKey.label,
        value: count,
        itemStyle: {
          color: statusKey.color,
          borderColor: BORDER.COLOR,
          borderWidth: BORDER.WIDTH,
        },
      };
    })
    .filter((item) => item.value > 0);
});

const option = {
  title: {
    left: "center",
  },
  tooltip: {
    trigger: "item",
    formatter: (params: { name: string; value: number }) => {
      return `${params.name}: ${params.value}`;
    },
  },
  legend: {
    type: "scroll",
    orient: "horizontal",
    left: "center",
    data: seriesData.value.map((item) => item.name),
    itemStyle: {
      borderWidth: 0.5,
    },
  },
  series: [
    {
      type: "pie",
      radius: "55%",
      center: ["50%", "50%"],
      data: seriesData.value,
      label: {
        formatter: (params: { name: string; percent: number }) => {
          return `${params.name}: ${toPercent(params.percent)}`;
        },
      },
    },
  ],
};
</script>

<template>
  <BaseChart :option="option" />
</template>
