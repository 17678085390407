import { ref } from "vue";
import { getSurveyFilesService } from "@/services/surveys";
import { SurveyReportFileDto } from "@/types/_generated/api";

export const useGetSurveyReportFiles = () => {
  const isLoadingSurveyReportFiles = ref(false);
  const hasError = ref(false);
  const surveyReportFiles = ref<SurveyReportFileDto[] | null>(null);

  const getSurveyReportFiles = async (surveyId: number) => {
    isLoadingSurveyReportFiles.value = true;
    hasError.value = false;

    try {
      const response = await getSurveyFilesService(surveyId);
      surveyReportFiles.value = response?.files ?? null;
    } catch {
      hasError.value = true;
    } finally {
      isLoadingSurveyReportFiles.value = false;
    }
  };

  return {
    isLoadingSurveyReportFiles,
    hasError,
    getSurveyReportFiles,
    surveyReportFiles,
  };
};
