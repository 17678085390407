<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { RecReportSettingsScope } from "@/types/_generated/api";
import BaseRadioGroup, {
  type BaseRadioGroupOption,
} from "@/components/base/BaseRadioGroup/BaseRadioGroup.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  isLoading: boolean;
  selectedScope: RecReportSettingsScope;
}>();

defineEmits<{
  "update:selected-scope": [RecReportSettingsScope];
}>();

const options: BaseRadioGroupOption[] = [
  { label: t("module.recReportSettings.scope.internal"), value: RecReportSettingsScope.Internal },
  { label: t("module.recReportSettings.scope.external"), value: RecReportSettingsScope.External },
];
</script>

<template>
  <BaseRadioGroup
    horizontal
    :disabled="isLoading"
    :options="options"
    :value="selectedScope"
    @input="$emit('update:selected-scope', $event as RecReportSettingsScope)"
  />
</template>
