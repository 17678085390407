<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { UpdateFormField } from "@/composables/validation/types";
import { roles } from "@/helpers/options";
import { isSuperior } from "@/plugins/can";
import { Role, UserDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import { UserForm } from "./types";
import UserEditDrawerAccessPermissionDrawer from "./UserEditDrawerAccessPermissionDrawer.vue";

const props = defineProps<{
  userId: number | null;
  currentUserRole?: Role;
  isEditingUser: boolean;
  userDto: UserDto | null;
  userForm: UserForm;
}>();

defineEmits<{
  "update:field": UpdateFormField<UserForm>;
  "update:role": [Role];
}>();

const { t } = useI18n({ useScope: "global" });

const showPermissionDrawer = ref(false);

const availableRoles = computed(() => {
  if (props.currentUserRole === Role.SystemOwner) {
    return roles;
  }

  return props.currentUserRole
    ? roles.filter(
        (role) => props.currentUserRole && isSuperior(props.currentUserRole, role.value as Role),
      )
    : [];
});
</script>

<template>
  <div class="user-edit-access">
    <BaseDataDisplayListTitle>{{ t("users.access") }}</BaseDataDisplayListTitle>

    <div class="user-edit-access__fields">
      <BaseSelect
        :value="userForm.role"
        :label="t('common.role')"
        @change="$emit('update:role', $event as Role)"
      >
        <BaseOption v-for="option in availableRoles" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>

      <BaseButton
        v-if="isEditingUser && userDto?.role !== Role.SystemOwner && userDto?.role !== Role.SA"
        variant="outlined"
        @click="showPermissionDrawer = true"
      >
        {{ t("users.permissions.editPermissions") }}
      </BaseButton>
    </div>

    <div class="user-edit-access__fields user-edit-access__fields--checkboxes">
      <BaseCheckbox
        :label="t('users.lockedOut')"
        :checked="Boolean(userForm.isLockedOut)"
        @change="$emit('update:field', 'isLockedOut', $event)"
      />

      <template v-if="userForm.role === Role.EXT">
        <BaseCheckbox
          :label="t('users.allowUserWeb')"
          :checked="Boolean(userForm.allowUserWeb)"
          @change="$emit('update:field', 'allowUserWeb', $event)"
        />
        <BaseCheckbox
          :label="t('users.allowRmComment')"
          :checked="Boolean(userForm.allowRmComment)"
          @change="$emit('update:field', 'allowRmComment', $event)"
        />
        <BaseCheckbox
          :label="t('users.allowReportMail')"
          :checked="Boolean(userForm.allowReportMail)"
          @change="$emit('update:field', 'allowReportMail', $event)"
        />
      </template>

      <BaseCheckbox
        v-if="isEditingUser && userDto?.isArchived"
        :label="t('common.archived')"
        :checked="Boolean(userForm.isArchived)"
        @change="$emit('update:field', 'isArchived', $event)"
      />
    </div>

    <UserEditDrawerAccessPermissionDrawer
      v-if="showPermissionDrawer && userId"
      :user-id="userId"
      @close="showPermissionDrawer = false"
    />
  </div>
</template>

<style scoped lang="scss">
.user-edit-access {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__fields {
    display: flex;
    align-items: flex-end;
    gap: $spacing-4;

    &--checkboxes {
      flex-wrap: wrap;
    }
  }
}
</style>
