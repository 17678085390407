import { useI18n } from "vue-i18n";
import { enumToOptions } from "@/helpers/mappers/enumToOptions";
import { binaryOptions } from "@/helpers/options";
import { Role } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import { SiteUserRow } from "../components/SitePermissionsTable.vue";

export const useSiteUsersTableColumns = () => {
  const { t } = useI18n({ useScope: "global" });

  const columns = [
    new ColumnDefinition({ title: t("common.name"), key: "userFullName" }),
    new ColumnDefinition({
      title: t("common.role"),
      key: "userRole",
      searchOptions: enumToOptions(Role),
    }),
    new ColumnDefinition({
      title: t("users.isImplicit"),
      key: "isImplicit",
      searchOptions: binaryOptions,
      type: ColumnDataType.Boolean,
    }),
    new ColumnDefinition({
      title: t("users.createSite"),
      key: "createSite",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.createSurvey"),
      key: "createSurvey",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.editSurvey"),
      key: "editSurvey",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.uploadStdRec"),
      key: "uploadStdRec",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.editQuestionnaire"),
      key: "editQuestionnaire",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("common.actions.uploadFiles"),
      key: "uploadFiles",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
  ] as ColumnDefinition<SiteUserRow>[];

  return { columns };
};
