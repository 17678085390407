<script setup lang="ts">
import { computed } from "vue";
import type { SiteLeanDto } from "@/types/_generated/api";
import type { CustomDefinitionKey } from "@/types/SiteDefinitions";
import { customLocaleCompare } from "@/helpers/customLocaleCompare";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

export interface SiteDefinitionsSelectOption {
  siteId: SiteLeanDto["siteId"];
  disabled: boolean;
  selected: boolean;
  customKey: CustomDefinitionKey;
  customValue: string;
}

const emit = defineEmits<{
  "update:deselectedValues": [customKey: CustomDefinitionKey, customValue: string];
}>();

const props = defineProps<{
  options: SiteDefinitionsSelectOption[];
}>();

// Sorting so that disabled options come last, and then alphabetically by customValue (label)
const sortedOptions = computed(() => {
  return props.options.toSorted((a, b) => {
    if (a.disabled !== b.disabled) {
      return a.disabled ? 1 : -1;
    }
    return customLocaleCompare(a.customValue, b.customValue);
  });
});

// Map to multiselect options
const selectOptions = computed(() => {
  return sortedOptions.value.map((entry) => ({
    value: entry,
    disabled: entry.disabled,
    label: entry.customValue,
  }));
});

// Determine which options should be checked
const checkedOptions = computed(() => {
  return new Set(props.options.filter((entry) => entry.selected).map((entry) => entry));
});

const handleSelectionChange = (updatedSelectedOptions: SiteDefinitionsSelectOption[]) => {
  const updatedSet = new Set(updatedSelectedOptions); // Incoming selected options (from the multiselect component)
  const previousSet = new Set(checkedOptions.value); // Previously checked (selected) options
  const allOptions = new Set([...updatedSelectedOptions, ...checkedOptions.value]); // Combine both sets

  // Iterate through the combined set of options
  allOptions.forEach((option) => {
    const wasSelected = previousSet.has(option);
    const isSelected = updatedSet.has(option);

    // Only emit if there's a change in selection state
    if (wasSelected !== isSelected) {
      emit("update:deselectedValues", option.customKey, option.customValue);
    }
  });
};
</script>

<template>
  <ModMultiselect
    disable-sorting
    :options="selectOptions"
    :selected-options="checkedOptions"
    @update:selected="handleSelectionChange"
  />
</template>
