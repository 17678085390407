import { readonly, ref } from "vue";
import { getBlueRatingSummaryService } from "@/services/executiveSummary";
import { GetBlueRatingSummaryResponse } from "@/types/_generated/api";

export const useGetBlueRatingSummary = () => {
  const isLoadingBlueRatingSummary = ref(false);
  const hasError = ref(false);
  const blueRatingSummary = ref<GetBlueRatingSummaryResponse | null>(null);

  const getBlueRatingSummary = async (moduleId: number) => {
    isLoadingBlueRatingSummary.value = true;
    hasError.value = false;

    try {
      blueRatingSummary.value = await getBlueRatingSummaryService(moduleId);
    } catch {
      hasError.value = true;
    } finally {
      isLoadingBlueRatingSummary.value = false;
    }
  };

  return {
    getBlueRatingSummary,
    hasError: readonly(hasError),
    isLoadingBlueRatingSummary: readonly(isLoadingBlueRatingSummary),
    blueRatingSummary,
  };
};
