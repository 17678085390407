import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { IndustryDto, GetIndustriesResponse, GetRiskTypesResponse } from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getIndustries = async (): Promise<IndustryDto[] | null> => {
  try {
    const {
      data: { industries },
    } = await http.get<GetIndustriesResponse>(`/meta-data/industries`);

    return industries;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("metaData.industries"),
    });
  }

  return null;
};

export const getRiskTypes = async (): Promise<string[]> => {
  try {
    const {
      data: { riskTypes },
    } = await http.get<GetRiskTypesResponse>(`/meta-data/risk-types`);

    return riskTypes;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("metaData.riskTypes"),
    });
  }

  return [];
};
