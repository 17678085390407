<script setup lang="ts">
import { onMounted, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import type { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import type { Breadcrumb } from "@/types/Breadcrumb";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import { authorize } from "@/plugins/can";
import { AdminRouteName, UserWebRouteName } from "@/router/RouteName";
import { getModuleService, moduleEvents } from "@/services/modules";
import { BroadcastMessage } from "@/services/shared/BroadcastService";
import { createSite, sitesEvents } from "@/services/sites";
import { ExportSites, type ModuleDto, Role, Status } from "@/types/_generated/api";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import BaseDropdownMenu, {
  type MenuOption,
} from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";
import ModuleDrawers from "./components/Drawers/ModuleDrawers.vue";
import { ModuleDrawer } from "./constants";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const router = useRouter();

const module = ref<ModuleDto>();
const isLoading = ref(false);
const breadcrumbs = ref<Breadcrumb[]>([]);
const siteId = ref<number | null>(null);
const currentDrawer = ref<ModuleDrawer | null>(null);

const TABS: NavTab[] = [
  { title: t("sites.sites"), path: AdminRouteName.ModuleSites },
  { title: t("modules.userPermissionsList"), path: AdminRouteName.ModuleUsers },
  { title: t("modules.details"), path: AdminRouteName.ModuleDetails },
  { title: t("modules.library"), path: AdminRouteName.ModuleLibrary },
];

const { blobDownload } = useDownloadBlob();

// A create event that triggers from the new site button
// and opens the drawer in this component.
// However there is also a SiteEditDrawer in ModuleSitesTable
// that will open when user edits a site in the table.
useBroadcastService(
  sitesEvents,
  async (message: BroadcastMessage) => openNewSiteDrawer(message.id as number),
  ["create"],
);

useBroadcastService(moduleEvents, async (message: BroadcastMessage) => {
  if (message.action === "update" && message.id === props.moduleId) {
    module.value = (await getModuleService(props.moduleId)) as ModuleDto;
  }
});

const actionOptions = computed(() => {
  const options: MenuOption[] = [
    {
      label: t("module.recReportSettings.title"),
      disabled: !authorize.isSuperiorOrEqualTo(Role.BA),
      action: () => (currentDrawer.value = ModuleDrawer.RecReportSettings),
    },
    {
      label: t("navigation.questionnaire"),
      disabled: !authorize.isSuperiorTo(Role.EXT),
      action: () =>
        router.push({
          name: AdminRouteName.Questionnaire,
          params: { moduleId: props.moduleId, questionnaireId: null },
        }),
    },
    {
      label: `${t("common.actions.edit")} ${t("navigation.module").toLowerCase()}`,
      disabled: !authorize.isSuperiorTo(Role.INT),
      action: () => (currentDrawer.value = ModuleDrawer.ModuleEdit),
    },
    {
      label: t("modules.importSites"),
      disabled: !authorize.isSuperiorTo(Role.INT),
      action: () => (currentDrawer.value = ModuleDrawer.ModuleImportSites),
    },
    {
      label: t("modules.exportSites"),
      disabled: !authorize.isSuperiorTo(Role.INT),
      action: () => blobDownload.get<ExportSites>(`/v1/modules/${props.moduleId}/sites/export`),
    },
    {
      label: t("sites.newSite"),
      disabled: !authorize.hasModulePermission(props.moduleId, "createSite"),
      action: () => createSite(props.moduleId),
    },
    {
      label: t("standardRecommendations.newStandardRecommendation"),
      disabled: !authorize.isSuperiorOrEqualTo(Role.INT),
      action: () => (currentDrawer.value = ModuleDrawer.StandardRecEdit),
    },
  ];

  // Filter out disabled options
  const filteredOptions = options.filter((option) => !option.disabled);

  return filteredOptions;
});

const openNewSiteDrawer = (id: number) => {
  siteId.value = id;
  currentDrawer.value = ModuleDrawer.SiteEdit;
};

const closeDrawer = () => {
  siteId.value = null;
  currentDrawer.value = null;
};

const initializeModule = async () => {
  isLoading.value = true;

  try {
    const moduleData = await getModuleService(props.moduleId);
    if (!moduleData) return;

    module.value = moduleData;

    breadcrumbs.value = [
      {
        title: t("navigation.clients"),
        route: { name: AdminRouteName.Clients },
      },
      {
        title: module.value.clientName,
        route: { name: AdminRouteName.Client, params: { clientId: module.value.clientId } },
      },
      { title: module.value.name },
    ];
  } finally {
    isLoading.value = false;
  }
};

onMounted(initializeModule);
</script>

<template>
  <AdminHeader
    :breadcrumbs="breadcrumbs"
    :user-web="
      module?.status === Status.Published && { name: UserWebRouteName.Module, params: { moduleId } }
    "
  />

  <AdminViewLayout :tabs="TABS">
    <template #controls>
      <BaseDropdownMenu
        v-if="actionOptions.some((o) => !o.disabled)"
        :options="actionOptions"
        :position="['bottom', 'left']"
        variant="outlined"
      />
    </template>

    <router-view />

    <ModuleDrawers
      :current-drawer="currentDrawer"
      :module-id="moduleId"
      :site-id="siteId"
      :client-id="module?.clientId"
      @close-drawer="closeDrawer"
    />
  </AdminViewLayout>
</template>
