import type { DeepReadonly } from "vue";
import type {
  ModuleLeanDto,
  GetModulesResponse,
  ModuleDto,
  GetModuleResponse,
  ModuleSettingsDto,
  GetModuleSettingsResponse,
  SiteDefinitionSuggestionsDto,
  GetSiteDefinitionSuggestionsResponse,
  CreateModuleResponse,
  UpdateModule,
  GetModules,
} from "@/types/_generated/api";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const moduleEvents = new BroadcastService("modules");

const { t } = i18n.global;

export const getModules = async (request?: GetModules): Promise<ModuleLeanDto[] | null> => {
  try {
    const {
      data: { modules },
    } = await http.get<GetModulesResponse>(`/modules`, {
      params: request,
    });
    return modules;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("modules.modules"),
    });
  }
  return null;
};

export const getModuleService = async (moduleId: number): Promise<ModuleDto | null> => {
  try {
    const {
      data: { module },
    } = await http.get<GetModuleResponse>(`/modules/${moduleId}`);
    return module;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("modules.module"),
    });
  }
  return null;
};

export const getModuleSettings = async (moduleId: number): Promise<ModuleSettingsDto | null> => {
  try {
    const {
      data: { settings },
    } = await http.get<GetModuleSettingsResponse>(`/modules/${moduleId}/settings`);
    return settings;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("modules.moduleSettings"),
    });
  }
  return null;
};

export const getSiteDefinitionSuggestions = async (
  moduleId: number,
): Promise<SiteDefinitionSuggestionsDto[] | null> => {
  try {
    const {
      data: { siteDefinitionSuggestions },
    } = await http.get<GetSiteDefinitionSuggestionsResponse>(
      `/modules/${moduleId}/site-definition-suggestions`,
    );
    return siteDefinitionSuggestions;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("modules.siteDefinitionSuggestions"),
    });
  }
  return null;
};

export const createModule = async (clientId: number): Promise<number | null> => {
  try {
    const {
      data: { moduleId },
    } = await http.post<CreateModuleResponse>(`/clients/${clientId}/modules`);

    moduleEvents.post({ action: "create", id: moduleId });

    notify.success(t("modules.module"), t("common.created").toLowerCase());

    return moduleId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("modules.module"),
    });
  }
  return null;
};

export const updateModuleService = async (data: DeepReadonly<UpdateModule>): Promise<boolean> => {
  try {
    await http.put<CreateModuleResponse>(`/modules/${data.moduleId}`, data);

    moduleEvents.post({ action: "update", id: data.moduleId });

    notify.success(t("modules.module"), t("common.updated").toLowerCase());
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("modules.module"),
    });

    return false;
  }

  return true;
};
