<script setup lang="ts">
import { type DeepReadonly, computed } from "vue";
import { useI18n } from "vue-i18n";
import { authorize } from "@/plugins/can";
import { Status, Role, type UpdateSite } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { UpdateSiteField, ErrorMessages } from "./types";
import { useLocationLookup } from "./composables/useLocationLookup";

const { siteForm } = defineProps<{
  siteForm: DeepReadonly<UpdateSite>;
  errorMessages: ErrorMessages;
}>();

const emit = defineEmits<{
  "update:field": UpdateSiteField;
}>();

const { t } = useI18n({ useScope: "global" });
const { getAddressFromLocation, getLocationFromAddress } = useLocationLookup();

const statusOptions = computed(() => {
  const enumToI18nKeyMap = {
    [Status.History]: "archived",
    [Status.UnderConstruction]: "underConstruction",
    [Status.Published]: "published",
    [Status.Complete]: "completed",
  };

  const isAuthorized = authorize.hasRole(Role.SystemOwner, Role.SA, Role.BA);

  // Exclude Status.Complete from select options
  // And History if not authorized to see it
  const filteredOptions = Object.values(Status).filter(
    (value) => value !== Status.Complete && (isAuthorized || value !== Status.History),
  );

  return filteredOptions.map((value) => ({
    title: t(`sites.siteStatus.${enumToI18nKeyMap[value]}`),
    value,
  }));
});

const setCoordinatesFromAddress = async () => {
  const location = await getLocationFromAddress(
    siteForm.address,
    siteForm.city,
    siteForm.country,
    siteForm.zipCode,
  );

  if (location) {
    emit("update:field", "latitude", location.latitude);
    emit("update:field", "longitude", location.longitude);
  }
};

const setAddressFromCoordinates = async () => {
  if (!siteForm.latitude || !siteForm.longitude) return;

  const address = await getAddressFromLocation(siteForm.latitude, siteForm.longitude);

  if (address) {
    emit("update:field", "address", address.addressLine ?? siteForm.address);
    emit("update:field", "city", address.locality ?? siteForm.city);
    emit("update:field", "country", address.countryRegion ?? siteForm.country);
    emit("update:field", "zipCode", address.postalCode ?? siteForm.zipCode);
  }
};
</script>

<template>
  <div class="site-edit-facility-info">
    <div class="site-edit-facility-info__row site-edit-facility-info__row--custom">
      <BaseTextField
        :label="t('sites.address')"
        :value="siteForm.address"
        :error-message="errorMessages.address"
        @update:value="$emit('update:field', 'address', String($event))"
      />

      <BaseTextField
        :label="t('sites.poBox')"
        :value="siteForm.poBox"
        :error-message="errorMessages.poBox"
        @update:value="$emit('update:field', 'poBox', String($event))"
      />

      <BaseTextField
        :label="t('sites.zipCode')"
        :value="siteForm.zipCode"
        :error-message="errorMessages.zipCode"
        @update:value="$emit('update:field', 'zipCode', String($event))"
      />
    </div>

    <div class="site-edit-facility-info__row site-edit-facility-info__row--two-columns-auto">
      <BaseTextField
        :label="t('sites.city')"
        :value="siteForm.city"
        :error-message="errorMessages.city"
        @update:value="$emit('update:field', 'city', String($event))"
      />

      <BaseTextField
        :label="t('sites.country')"
        :value="siteForm.country"
        :error-message="errorMessages.country"
        @update:value="$emit('update:field', 'country', String($event))"
      />

      <div>
        <BaseLabel has-spacing class="site-edit-facility-info__hidden-label">-</BaseLabel>
        <BaseButton
          variant="outlined"
          :disabled="!siteForm.latitude || !siteForm.longitude"
          :title="t('sites.editSiteDrawer.getAddressFromCoordinates')"
          @click="setAddressFromCoordinates"
        >
          <BaseIcon size="medium" icon="building" />
        </BaseButton>
      </div>
    </div>

    <div class="site-edit-facility-info__row site-edit-facility-info__row--three-columns">
      <BaseTextField
        :label="t('sites.phone')"
        :value="siteForm.phone"
        :error-message="errorMessages.phone"
        @update:value="$emit('update:field', 'phone', String($event))"
      />

      <BaseTextField
        :label="t('sites.web')"
        :value="siteForm.web"
        :error-message="errorMessages.web"
        @update:value="$emit('update:field', 'web', String($event))"
      />

      <BaseSelect
        :label="t('sites.status')"
        :error-message="errorMessages.status"
        :value="siteForm.status"
        @change="$emit('update:field', 'status', $event)"
      >
        <BaseOption v-for="option in statusOptions" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>
    </div>

    <div class="site-edit-facility-info__row site-edit-facility-info__row--two-columns-auto">
      <BaseTextField
        type="number"
        :label="t('sites.latitude')"
        :min="-90"
        :max="90"
        :value="siteForm.latitude"
        :error-message="errorMessages.latitude"
        @update:value="$emit('update:field', 'latitude', Number($event))"
      />

      <BaseTextField
        type="number"
        :label="t('sites.longitude')"
        :min="-180"
        :max="180"
        :value="siteForm.longitude"
        :error-message="errorMessages.longitude"
        @update:value="$emit('update:field', 'longitude', Number($event))"
      />

      <div>
        <BaseLabel has-spacing class="site-edit-facility-info__hidden-label">-</BaseLabel>
        <BaseButton
          variant="outlined"
          :disabled="!siteForm.address && !siteForm.zipCode && !siteForm.city && !siteForm.country"
          :title="t('sites.editSiteDrawer.getCoordinatesFromAddress')"
          @click="setCoordinatesFromAddress"
        >
          <BaseIcon size="medium" icon="map-pin" />
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.site-edit-facility-info {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__row {
    display: grid;
    gap: $spacing-4;

    &--three-columns {
      grid-template-columns: repeat(3, 1fr);
    }

    &--two-columns-auto {
      grid-template-columns: 1fr 1fr auto;
    }

    &--custom {
      grid-template-columns: 2fr 1fr 1fr;
    }
  }

  // This will make the button stay in place when validation errors are shown
  &__hidden-label {
    visibility: hidden;
  }
}
</style>
