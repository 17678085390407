import { CategoryDto, AreaDto } from "@/types/_generated/api";

export const calculateTotalBlueScore = (data: CategoryDto | AreaDto): number => {
  let totalBlueScore = 0;

  if ("questions" in data) {
    for (const question of data.questions) {
      totalBlueScore += question.blueScore;
    }
  } else if ("categories" in data) {
    for (const category of data.categories) {
      for (const question of category.questions) {
        totalBlueScore += question.blueScore;
      }
    }
  }

  return totalBlueScore;
};
