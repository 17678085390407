export enum OS {
  Windows = "Windows",
  macOS = "macOS",
  Linux = "Linux",
  Android = "Android",
  iOS = "iOS",
  Unknown = "Unknown",
}

const detectOS = () => {
  const { userAgent } = navigator;

  switch (true) {
    case userAgent.indexOf("Win") !== -1:
      return OS.Windows;
    case userAgent.indexOf("like Mac") !== -1:
      return OS.iOS;
    case userAgent.indexOf("Mac") !== -1:
      return OS.macOS;
    case userAgent.indexOf("Linux") !== -1:
      return OS.Linux;
    case userAgent.indexOf("Android") !== -1:
      return OS.Android;
    default:
      return OS.Unknown;
  }
};

const userOS = detectOS();

export { userOS };
