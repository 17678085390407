<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

defineEmits<{ toggle: [collapsed: boolean] }>();

const expanded = ref(true);
</script>

<template>
  <BaseButton
    :title="expanded ? t('blueRating.nav.unfold') : t('blueRating.nav.fold')"
    variant="text"
    @click="
      () => {
        expanded = !expanded;
        $emit('toggle', !expanded);
      }
    "
  >
    <BaseIcon :icon="expanded ? 'unfold' : 'fold'" />
  </BaseButton>
</template>
