<script setup lang="ts">
import { ref } from "vue";
import { useEventListener } from "@/composables/useEventListener";
import { vClickOutside } from "@/directives/clickOutside";
import { vFocusTrap } from "@/directives/focusTrap";

enum PopoverState {
  Open = "open",
  Closed = "closed",
}

const emit = defineEmits<{
  close: [payload?: unknown];
}>();

const popoverState = ref(PopoverState.Open);

const closePopover = (payload?: unknown) => {
  popoverState.value = PopoverState.Closed;
  setTimeout(() => {
    emit("close", payload);
  }, 75); // Must be slightly faster than animation duration to prevent flicker
};

useEventListener(document, "keyup", (event: KeyboardEvent) => {
  if (event.key === "Escape") closePopover();
});
</script>

<template>
  <div
    v-focus-trap
    v-click-outside="() => closePopover()"
    class="base-popover"
    :class="`base-popover--${popoverState}`"
    role="dialog"
    aria-modal="true"
  >
    <slot :close-popover="closePopover" />
  </div>
</template>

<style scoped lang="scss">
.base-popover {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: $z-10;
  margin: $spacing-1 auto 0 auto;
  min-width: max-content;
  max-width: 25rem;
  background-color: $primary-1;
  border: 1px solid $primary-5;
  border-radius: $rounded-base;
  box-shadow: $shadow-lg;
  transition: opacity 0.3s ease-in-out;

  &--open {
    animation: fadeIn $duration-100 ease-in;
  }

  &--closed {
    animation: fadeOut $duration-100;
  }
}
</style>
