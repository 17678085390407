import { ref, readonly } from "vue";
import { getClientsService } from "@/services/clients";
import { ClientDto } from "@/types/_generated/api";

export const useGetClients = () => {
  const isLoadingClients = ref(false);
  const hasError = ref(false);
  const clients = ref<ClientDto[] | null>(null);

  const getClients = async () => {
    isLoadingClients.value = true;
    hasError.value = false;

    try {
      clients.value = await getClientsService();
    } catch {
      hasError.value = true;
    } finally {
      isLoadingClients.value = false;
    }
  };

  return {
    clients,
    hasError: readonly(hasError),
    isLoadingClients: readonly(isLoadingClients),
    getClients,
  };
};
