<script setup lang="ts">
import { computed, ref } from "vue";
import { useUniqueId } from "@/composables/useUniqueId";
import { HorizontalSizes, VerticalSizes } from "@/types/CSSUnits";

const props = defineProps<{
  src: string;
  alt: string;
  objectFit?: "contain" | "cover";
  maxHeight?: `${number}${VerticalSizes}`;
  maxWidth?: `${number}${HorizontalSizes}`;
}>();

const uniqueId = useUniqueId(`base-image`);

const style = () => ({
  maxWidth: props.maxWidth,
  maxHeight: props.maxHeight,
  objectFit: props.objectFit,
});

const isResized = ref(false);

const updateIsResized = () => {
  const img = document.getElementById(uniqueId) as HTMLImageElement;

  isResized.value = img.width > 0 && img.width < img.naturalWidth;
};

const openInNewTab = () => {
  window.open(props.src, "_blank");
};

const cursorType = computed(() => (isResized.value ? "pointer" : "default"));
</script>

<template>
  <img
    :id="uniqueId"
    class="base-image"
    :src="src"
    :alt="alt"
    :style="style()"
    @click="isResized && openInNewTab()"
    @mouseenter="updateIsResized()"
  />
</template>

<style scoped lang="scss">
.base-image {
  position: relative;

  &:hover {
    cursor: v-bind("cursorType");
  }
}
</style>
