import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  GetDashboardAverageScoreResponse,
  GetDashboardRecResponseRatioResponse,
  GetDashboardSurveyHistoryResponse,
  SurveyHistoryEntryDto,
} from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getDashboardAverageScoreService = async (moduleId: number): Promise<number | null> => {
  try {
    const { data } = await http.get<GetDashboardAverageScoreResponse>(
      `dashboard/${moduleId}/average-score`,
    );

    return data.totalAverageScore;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("dashboard.averageScore"),
    });
  }
  return null;
};

export const getDashboardRecResponseRatioService = async (
  moduleId: number,
): Promise<number | null> => {
  try {
    const { data } = await http.get<GetDashboardRecResponseRatioResponse>(
      `dashboard/${moduleId}/ratio`,
    );

    return data.recommendationResponseRatio;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("userWeb.dashboard.recResponseRatio"),
    });
  }
  return null;
};

export const getDashboardSurveyHistoryService = async (
  moduleId: number,
): Promise<SurveyHistoryEntryDto[] | null> => {
  try {
    const { data } = await http.get<GetDashboardSurveyHistoryResponse>(
      `dashboard/${moduleId}/survey-history`,
    );

    return data.surveyHistory;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("userWeb.dashboard.surveyHistory"),
    });
  }
  return null;
};
