<script setup lang="ts">
import { reactive, computed } from "vue";
import { useI18n } from "vue-i18n";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { useCredentialVerification } from "../composables/useCredentialVerification";

const { t } = useI18n({ useScope: "global" });
const { verifyCredentials } = useCredentialVerification();

const props = defineProps<{
  expiryMinutes: number | null;
  email: string;
  password: string;
}>();

defineEmits<{
  "view-change": [view: ViewAuthStatus];
}>();

const form = reactive({
  mfaCode: "",
  isLoading: false,
  errorMessage: "",
});

const isSubmitDisabled = computed(() => form.mfaCode.length !== 6);

const submitMfa = async () => {
  form.errorMessage = "";
  form.isLoading = true;

  try {
    const result = await verifyCredentials(props.email, props.password, { mfaCode: form.mfaCode });
    if (result?.errorMessage) {
      form.errorMessage = t("signIn.2fa.invalidCode");
    }
  } finally {
    form.isLoading = false;
  }
};
</script>

<template>
  <div class="auth-mfa-form">
    <BaseAlert severity="info">
      {{ t("signIn.2fa.alert", { value: expiryMinutes, unit: t("signIn.2fa.timeUnits.minutes") }) }}
    </BaseAlert>

    <form class="auth-mfa-form__form" @submit.prevent="submitMfa">
      <BaseTextField
        autofocus
        autocomplete="one-time-code"
        :label="t('signIn.2fa.code')"
        :disabled="form.isLoading"
        :value="form.mfaCode"
        @update:value="form.mfaCode = String($event)"
      />

      <BaseAlert v-if="form.errorMessage && !form.isLoading" severity="error">
        <p>{{ form.errorMessage }}</p>
      </BaseAlert>

      <BaseButton type="submit" :disabled="form.isLoading || isSubmitDisabled">
        {{ t("common.actions.confirm") }}
      </BaseButton>
    </form>

    <BaseButton variant="text" @click="$emit('view-change', ViewAuthStatus.SignIn)">
      {{ t("signIn.return") }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.auth-mfa-form {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    width: 100%;
  }
}
</style>
