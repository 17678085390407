import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useDialog } from "@/composables/useDialog";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { AdminRouteName } from "@/router/RouteName";
import {
  deleteBlueRatingRecommendation,
  getBlueRatingRecommendationService,
  updateBlueRatingRecommendation,
} from "@/services/recommendations";
import { copyToStandardRecommendation as copyToStandardRecService } from "@/services/standardRecommendations";
import {
  CopyToStandardRecommendation,
  ModuleDto,
  Status,
  BlueRatingDto,
  BlueRatingRecommendationDto,
} from "@/types/_generated/api";
import { RecommendationForm } from "../components/BlueRatingRecommendations/types";

export const useHandleRecommendations = (
  survey: Ref<BlueRatingDto | null>,
  module: Ref<ModuleDto | null>,
) => {
  const isLoading = ref(false);
  const dialog = useDialog();
  const router = useRouter();
  const { t } = useI18n({ useScope: "global" });
  const { setCompareBase, discardUnsavedChanges, isModified } = useUnsavedChanges();
  const recommendation = ref<BlueRatingRecommendationDto | null>(null);

  const loadRecommendation = async (surveyId: number, recommendationId: number) => {
    isLoading.value = true;

    recommendation.value = await getBlueRatingRecommendationService(surveyId, recommendationId);

    setCompareBase(recommendation);

    isLoading.value = false;
  };

  const save = async (recommendationForm: RecommendationForm) => {
    if (!recommendationForm) {
      return;
    }

    if (!module.value) {
      return;
    }

    isLoading.value = true;

    const id = await updateBlueRatingRecommendation(recommendationForm);

    if (id !== null) {
      setCompareBase(recommendationForm);
    }

    isLoading.value = false;
  };

  const removeRecommendation = async () => {
    if (!recommendation.value) {
      return;
    }

    if (survey.value?.status === Status.Published) {
      await dialog.openDialog({
        title: t("blueRating.recommendation.cannotDeletePublishedTitle"),
        description: t("blueRating.recommendation.cannotDeletePublished"),
        confirmText: t("common.actions.ok"),
        hideCancelButton: true,
      });
      return;
    }

    const isConfirmed = await dialog.openDeletePrompt(
      t("blueRating.recommendation.recommendation"),
    );

    if (isConfirmed) {
      await deleteBlueRatingRecommendation(
        recommendation.value.surveyId,
        recommendation.value!.recommendationId,
      );
    }
  };

  const copyToStandardRecommendation = async (recommendationForm: RecommendationForm) => {
    if (!recommendationForm) {
      return;
    }

    if (!module.value) {
      return;
    }

    if (!survey.value) {
      return;
    }

    const carryOn = await dialog.openDialog({
      title: t("blueRating.copyToStandardRecommendationDialog.createTitle"),
      description: t("blueRating.copyToStandardRecommendationDialog.createDescription"),
      confirmText: t("common.actions.create"),
    });

    if (!carryOn) {
      return;
    }

    isLoading.value = true;

    // Only update the recommendation if it's in an editable state
    if (isModified(recommendationForm)) {
      const recId = await updateBlueRatingRecommendation(recommendationForm);

      // If the recommendation was not saved, don't proceed with the copy
      if (recId === null) {
        isLoading.value = false;
        return;
      }
    }

    const standardRecommendation: CopyToStandardRecommendation = {
      recommendationId: recommendationForm.recommendationId,
      clientId: module.value.clientId,
      moduleId: module.value.moduleId,
    };

    const id = await copyToStandardRecService(standardRecommendation);

    const viewStdRec = id
      ? await dialog.openDialog({
          title: t("blueRating.copyToStandardRecommendationDialog.editTitle"),
          description: t("blueRating.copyToStandardRecommendationDialog.editDescription"),
          confirmText: t("common.actions.view"),
        })
      : false;

    if (viewStdRec) {
      router.push({
        name: AdminRouteName.StandardRecommendations,
        query: { standardRecommendationId: id },
      });
    }

    isLoading.value = false;
  };

  return {
    recommendation,
    isHandlingRecommendation: isLoading,
    loadRecommendation,
    saveRecommendation: save,
    deleteRecommendation: removeRecommendation,
    copyToStandardRecommendation,
    discardUnsavedChanges,
  };
};
