<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <span class="base-input-required">({{ t("common.required") }})</span>
</template>

<style scoped lang="scss">
.base-input-required {
  color: $primary-7;
  font-size: $text-xs;
}
</style>
