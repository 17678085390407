import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { Status } from "@/types/_generated/api";

// Used for entities where status handles archived state (not users where it's a boolean)
export const useArchivedStatusFilter = (showArchived: Ref<boolean>) => {
  const { t } = useI18n({ useScope: "global" });

  const filterArchived = (item: { status: Status }) => {
    if (item.status !== Status.History) {
      return true;
    }

    return showArchived.value;
  };

  const archivedMenuOption = {
    label: t("common.showArchived"),
    checked: showArchived,
    action: () => (showArchived.value = !showArchived.value),
  };

  return { filterArchived, archivedMenuOption };
};
