<script setup lang="ts">
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

export type Severity = "success" | "info" | "warning" | "error";

interface Props {
  severity?: Severity;
}

const props = withDefaults(defineProps<Props>(), {
  severity: "info",
});

const getIcon = (severity: Severity) => {
  switch (severity) {
    case "success":
      return "check-circle";
    case "warning":
      return "warning";
    case "error":
      return "cross-circle";
    case "info":
    default:
      return "info";
  }
};
</script>

<template>
  <div :class="`base-alert base-alert--${props.severity}`">
    <BaseIcon :icon="getIcon(props.severity)" size="medium" class="base-alert__icon" />

    <slot />
  </div>
</template>

<style scoped lang="scss">
@mixin alert-styles($color, $bg-color, $border-color) {
  color: $color;
  background-color: $bg-color;
  border: 1px solid $border-color;
}

.base-alert {
  display: flex;
  gap: $spacing-3;
  padding: $spacing-4;
  border-radius: $rounded-md;
  line-height: $leading-normal;
  word-break: break-word;
  font-size: $text-sm;

  &__icon {
    margin-top: $spacing-1;
  }

  &--info {
    @include alert-styles($info-5, $info-1, $info-3);
  }

  &--success {
    @include alert-styles($success-5, $success-1, $success-3);
  }

  &--warning {
    @include alert-styles($warning-5, $warning-1, $warning-3);
  }

  &--error {
    @include alert-styles($error-5, $error-1, $error-3);
  }
}
</style>
