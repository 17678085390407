<script setup lang="ts">
import { useI18n } from "vue-i18n";
import UWCheckbox from "@/components/base/UserWeb/Inputs/Checkbox/UWCheckbox.vue";

const { treatNotApplicableAsZero } = defineProps<{
  treatNotApplicableAsZero: boolean;
}>();

const emit = defineEmits<{
  "update:treatNotApplicableAsZero": [boolean];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <UWCheckbox
    :checked="treatNotApplicableAsZero"
    @change="emit('update:treatNotApplicableAsZero', $event)"
  >
    {{ t("userWeb.blueRatingTable.treatNotApplicableAsZero") }}
  </UWCheckbox>
</template>
