import { defineStore } from "pinia";
import { ref } from "vue";

export const useSessionStore = defineStore("sessionStore", () => {
  const initialCountdownValue = 1800;

  const timer = ref(initialCountdownValue);

  ref<number>(setInterval(() => (timer.value = timer.value - 1), initialCountdownValue));

  const reset = () => (timer.value = initialCountdownValue);

  return {
    timer,
    reset,
  };
});
