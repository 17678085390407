import { reactive, type DeepReadonly, readonly, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ModuleDto, UpdateBlueRatingRecommendation } from "@/types/_generated/api";
import { RecommendationDrawerTabs } from "../constants/RecommendationDrawerTabs";
import { ErrorMessage } from "../types";

type ValidationRule = {
  tab: RecommendationDrawerTabs;
  maxLength?: number;
  requiredByModuleSetting?: boolean;
};

type ValidationRules = Partial<Record<keyof ErrorMessage, ValidationRule>>;

const validationRules: ValidationRules = {
  priority: {
    tab: RecommendationDrawerTabs.General,
    maxLength: 500,
    requiredByModuleSetting: true,
  },
  type: { tab: RecommendationDrawerTabs.General, maxLength: 50, requiredByModuleSetting: true },
  header: {
    tab: RecommendationDrawerTabs.General,
    maxLength: 32767,
    requiredByModuleSetting: true,
  },
  location: {
    tab: RecommendationDrawerTabs.General,
    maxLength: 500,
    requiredByModuleSetting: true,
  },
  categoryColorOverride: {
    tab: RecommendationDrawerTabs.General,
    maxLength: 100,
    requiredByModuleSetting: true,
  },
};

export const useRecommendationValidation = () => {
  const { t } = useI18n({ useScope: "global" });

  const errorMessage = reactive({
    priority: "",
    type: "",
    header: "",
    location: "",
    categoryColorOverride: "",
  }) as ErrorMessage;

  const tabsWithErrors = ref(new Set<RecommendationDrawerTabs>());

  const resetErrorMessage = (field: keyof ErrorMessage) => {
    errorMessage[field] = "";
  };

  const validateField = <K extends keyof ValidationRules>(
    field: K,
    value: DeepReadonly<UpdateBlueRatingRecommendation[K]>,
    module: DeepReadonly<ModuleDto>,
    recommendation: DeepReadonly<UpdateBlueRatingRecommendation>,
  ): string => {
    const rules = validationRules[field];
    if (!rules) return "";

    if (rules.maxLength && typeof value === "string" && value.length > rules.maxLength) {
      return t("validations.maxLength", { max: rules.maxLength });
    }

    if (rules.requiredByModuleSetting) {
      switch (field) {
        case "priority":
          if (module.forcePriority && (typeof value === "string" ? value.length < 1 : !value)) {
            return t("validations.required");
          }
          break;
        case "type":
          if (module.forceType && (typeof value === "string" ? value.length < 1 : !value)) {
            return t("validations.required");
          }
          break;
        case "header":
          if (module.forceName && (typeof value === "string" ? value.length < 1 : !value)) {
            return t("validations.required");
          }
          break;
        case "location":
          if (module.forceLocation && (typeof value === "string" ? value.length < 1 : !value)) {
            return t("validations.required");
          }
          break;
        case "categoryColorOverride":
          if (
            module.forceOverrideCategoryColor &&
            !recommendation.isGeneralRecommendation &&
            (!recommendation.categoryColorOverride ||
              recommendation.categoryColorOverride === "NotSet")
          ) {
            return t("validations.required");
          }
          break;
        default:
          throw new Error(`Unexpected field: ${field}`);
      }
    }

    return "";
  };

  const isFormValid = (
    recommendationForm: DeepReadonly<UpdateBlueRatingRecommendation>,
    module: DeepReadonly<ModuleDto>,
  ): boolean => {
    // Validate all fields
    Object.keys(validationRules).forEach((field) => {
      const key = field as keyof typeof validationRules;
      const value = recommendationForm[key];
      errorMessage[key] = validateField(key, value, module, recommendationForm);
    });

    // Validate tabs
    tabsWithErrors.value.clear();
    Object.entries(validationRules).forEach(([field, rule]) => {
      if (rule && errorMessage[field as keyof ErrorMessage]) {
        tabsWithErrors.value.add(rule.tab);
      }
    });

    return tabsWithErrors.value.size === 0;
  };

  return {
    errorMessage: readonly(errorMessage),
    tabsWithErrors: readonly(tabsWithErrors),
    resetErrorMessage,
    isFormValid,
  };
};
