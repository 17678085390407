<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  text: string;
  errorMessage?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:value": [string];
}>();

const errors = computed(() => {
  return props.errorMessage ? [{ $message: props.errorMessage } as ErrorObject] : [];
});
</script>

<template>
  <div>
    <BaseLabel has-spacing>
      {{ t("standardRecommendations.recommendation") }}
    </BaseLabel>
    <BaseEditor :value="text" :errors="errors" @input="emit('update:value', $event)" />
  </div>
</template>
