import { isAxiosError, AxiosHeaders } from "axios";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { handleError } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import { MfaMethod, MfaOption } from "@/types/_generated/api";

const BLUE_HEADERS = {
  LOCKED_OUT: "x-blue-locked-out",
  UNVERIFIED_MFA: "x-blue-unverified-mfa-code",
  MFA_METHOD: "x-blue-mfa-method",
  MFA_EXPIRY: "x-blue-mfa-expiry-in-minutes",
} as const;

const getHeaderResult = (
  errorMessage: string,
  mfaOption: MfaOption | null,
  mfaExpiry: number | null,
) => ({ errorMessage, mfaOption, mfaExpiry });

export const useCredentialVerification = () => {
  const { t } = useI18n({ useScope: "global" });
  const auth = useAuthStore();
  const app = useAppStore();

  const isLoadingVerification = ref(false);

  const parseHeaders = (headers: Partial<AxiosHeaders>) => {
    const mfaExpiryMinutes = Number(headers[BLUE_HEADERS.MFA_EXPIRY]);
    const mfaMethod = headers[BLUE_HEADERS.MFA_METHOD];

    if (headers[BLUE_HEADERS.LOCKED_OUT] === "true") {
      return getHeaderResult(t("signIn.lockedOut"), null, null);
    }

    if (headers[BLUE_HEADERS.UNVERIFIED_MFA] === "true") {
      return getHeaderResult("", null, mfaExpiryMinutes);
    }

    if (mfaMethod === MfaOption.Sms || mfaMethod === MfaOption.Email) {
      return getHeaderResult("", mfaMethod, mfaExpiryMinutes);
    }

    if (mfaMethod === MfaOption.EmailorSms) {
      return getHeaderResult("", mfaMethod, null);
    }

    return getHeaderResult(t("signIn.wrongCredentials"), null, null);
  };

  const verifyCredentials = async (
    email: string,
    password: string,
    meta?: { mfaCode?: string; mfaMethod?: MfaMethod },
  ) => {
    isLoadingVerification.value = true;

    try {
      await auth.signInCredentials(email, password, meta);
      await auth.handleSuccessfulLogin();
      app.redirectOnLogin();
    } catch (error) {
      if (!isAxiosError(error) || !error.response || error.response.status !== 401) {
        handleError(error);
        return;
      }

      return parseHeaders(error.response.headers);
    } finally {
      isLoadingVerification.value = false;
    }
  };

  return {
    isLoadingVerification,
    verifyCredentials,
  };
};
