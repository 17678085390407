<script setup lang="ts" generic="T">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { Sorting } from "@/helpers/sort";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import BaseDropdownMenu, {
  MenuOption,
} from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  columns: ColumnDefinition<T>[];
  hiddenColumns: Sorting<T>["key"][];
}>();

const emits = defineEmits<{
  (event: "toggle-column", column: Sorting<T>["key"]): void;
}>();

const options = computed<MenuOption[]>(() =>
  props.columns.map((c) => ({
    label: c.title,
    checked: !props.hiddenColumns.includes(c.key),
    keepMenuOpen: true,
    action: () => emits("toggle-column", c.key),
  })),
);
</script>

<template>
  <BaseDropdownMenu
    variant="outlined"
    :title="t('library.table.columnToggle')"
    :options="options"
    :scroll="true"
  >
    <template #icon>
      <BaseIcon icon="columns" size="medium" />
    </template>
  </BaseDropdownMenu>
</template>
