<script setup lang="ts">
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { updateUserPasswordService } from "@/services/users";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import PasswordForm from "@/components/shared/PasswordForm.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  userId: number | null;
}>();

const state = reactive({
  password1: "",
  password2: "",
});

const emit = defineEmits<{
  closeModal: [void];
}>();

const changePassword = async () => {
  if (!props.userId) return;

  const success = await updateUserPasswordService({
    userId: props.userId,
    password: state.password1,
  });

  if (success) closeModal();
};

const closeModal = () => emit("closeModal");
</script>

<template>
  <BaseModal :title="t('users.changePassword')" @close="closeModal">
    <PasswordForm v-model:password1="state.password1" v-model:password2="state.password2" />

    <template #controls>
      <BaseButton @click="changePassword">
        {{ t("common.actions.confirm") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="closeModal">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
