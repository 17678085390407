<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.75 3.5C2.05964 3.5 1.5 4.05964 1.5 4.75V13.25C1.5 13.9404 2.05964 14.5 2.75 14.5H13.25C13.9404 14.5 14.5 13.9404 14.5 13.25V4.75C14.5 4.05964 13.9404 3.5 13.25 3.5H2.75ZM0 4.75C0 3.23122 1.23122 2 2.75 2H13.25C14.7688 2 16 3.23122 16 4.75V13.25C16 14.7688 14.7688 16 13.25 16H2.75C1.23122 16 0 14.7688 0 13.25V4.75Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 0C4.41421 0 4.75 0.335786 4.75 0.75V4.25C4.75 4.66421 4.41421 5 4 5C3.58579 5 3.25 4.66421 3.25 4.25V0.75C3.25 0.335786 3.58579 0 4 0Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 0C12.4142 0 12.75 0.335786 12.75 0.75V4.25C12.75 4.66421 12.4142 5 12 5C11.5858 5 11.25 4.66421 11.25 4.25V0.75C11.25 0.335786 11.5858 0 12 0Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 6.75C3 6.33579 3.33579 6 3.75 6H12.25C12.6642 6 13 6.33579 13 6.75C13 7.16421 12.6642 7.5 12.25 7.5H3.75C3.33579 7.5 3 7.16421 3 6.75Z"
    />
  </svg>
</template>
