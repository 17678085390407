import { ref, readonly, onMounted } from "vue";
import { getDashboardAverageScoreService } from "@/services/dashboard";

export const useGetDashboardAverageScore = (moduleId: number) => {
  const isLoadingAverageScore = ref(false);
  const hasError = ref(false);
  const averageScore = ref<number | null>(null);

  const getDashboardAverageScore = async () => {
    isLoadingAverageScore.value = true;
    hasError.value = false;

    try {
      averageScore.value = await getDashboardAverageScoreService(moduleId);
    } catch {
      hasError.value = true;
    } finally {
      isLoadingAverageScore.value = false;
    }
  };

  onMounted(() => getDashboardAverageScore());

  return {
    averageScore,
    hasError: readonly(hasError),
    isLoadingAverageScore: readonly(isLoadingAverageScore),
  };
};
