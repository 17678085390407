import { ref } from "vue";
import { getClient as getClientService } from "@/services/clients";

export const useGetClient = () => {
  const isLoadingClient = ref(false);

  const getClient = async (clientId: number) => {
    isLoadingClient.value = true;
    const client = await getClientService(clientId);
    isLoadingClient.value = false;
    return client;
  };
  return {
    isLoadingClient,
    getClient,
  };
};
