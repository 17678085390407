<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onBeforeMount, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { useQuestionnaireDraftClient } from "@/composables/useQuestionnaireDraftClient";
import { useQuestionnaireDraftValidation } from "@/composables/useQuestionnareDraftValidation";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import {
  AreaDto,
  CategoryDto,
  QuestionDto,
  QuestionnaireDraftActionType,
} from "@/types/_generated/api";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BaseValidationErrors from "@/components/base/BaseValidationErrors.vue";
import QuestionnaireDescriptionToggle from "@/components/shared/Questionnaire/QuestionnaireDescriptionToggle.vue";
import QuestionnaireDraftAddButtons from "../QuestionnaireDraftAddButtons.vue";
import QuestionnaireDraftEntityWrapper from "../QuestionnaireDraftEntityWrapper.vue";
import QuestionnaireDraftInputWrapper from "../QuestionnaireDraftInputWrapper.vue";
import QuestionnaireDraftPosition from "../QuestionnaireDraftPosition.vue";
import QuestionnaireDraftQuestionColor from "./QuestionnaireDraftQuestionColor.vue";
import QuestionnaireDraftQuestionColorCheckbox from "./QuestionnaireDraftQuestionColorCheckbox.vue";

const props = defineProps<{
  realQuestionnaireId: number;
  area: AreaDto;
  category: CategoryDto;
  question: QuestionDto;
  hideQuestionGradingDescriptions: boolean;
}>();

const showQuestionGradingDescription = ref(true);

watch(
  () => props.hideQuestionGradingDescriptions,
  (value) => (showQuestionGradingDescription.value = value),
);

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();
const store = useQuestionnaireStore();
const draft = useQuestionnaireDraftStore();
const client = useQuestionnaireDraftClient(store.questionnaire!.questionnaireId!);
const { questionRules } = useQuestionnaireDraftValidation();

const questionRef = toRef(() => props.question);

const v$ = useVuelidate(questionRules, questionRef, {
  $registerAs: `question_${props.question.questionId}`,
  $autoDirty: true,
});

onBeforeMount(() => v$.value.$touch());

const isLinkedWithHistory = computed(
  () => props.question.basedOnQuestionId && store.questionnaire?.moduleId,
);

const deleteQuestion = async () => {
  const entity = t("questionnaire.types.question");

  const isConfirmed = await dialog.openDialog({
    title: t("common.deleteQuestionnairePrompt.title", { entity }),
    description: `${t("common.deleteQuestionnairePrompt.questionMessage", { entity })} ${
      isLinkedWithHistory.value
        ? t("common.deleteQuestionnairePrompt.historyWarning", { entity })
        : ""
    }`,
    confirmText: t("common.actions.delete"),
  });

  if (isConfirmed) {
    client.question.remove(props.question.questionId);
  }
};
</script>

<template>
  <QuestionnaireDraftEntityWrapper
    :id="`question_${question.questionId}`"
    :title="question.text"
    :position="`${area.position}.${category.position}.${question.position}`"
    :type="QuestionnaireDraftActionType.Question"
    @delete="deleteQuestion"
  >
    <template #default>
      <div class="questionnaire-draft-question">
        <div class="questionnaire-draft-question__name">
          <QuestionnaireDraftInputWrapper
            :id="question.questionId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Question"
            property-name="Text"
            :target-name="question.text"
          >
            <template #default="{ update }">
              <BaseTextField
                :value="question.text"
                :placeholder="t('questionnaire.draft.placeholders.title')"
                @update:value="update"
              />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
        <div class="questionnaire-draft-question__name__validation">
          <BaseValidationErrors :errors="v$.text?.$errors" />
        </div>

        <div class="questionnaire-draft-question__position">
          <QuestionnaireDraftPosition
            :levels="[
              {
                count: area.categories.length,
                current: category.position,
                type: QuestionnaireDraftActionType.Category,
              },
              {
                count: category.questions.length,
                current: question.position,
                type: QuestionnaireDraftActionType.Question,
              },
            ]"
            @update="
              ({ type, position }) => {
                if (type === QuestionnaireDraftActionType.Category) {
                  const categoryId = draft.draft?.areas
                    .find((a) => a.position === area.position)
                    ?.categories.find((c) => c.position === position)?.categoryId;

                  client.question.moveToCategory(question.questionId, categoryId!);
                }

                if (type === 'Question') {
                  client.question.moveTo(question.questionId, position);
                }
              }
            "
          />
        </div>

        <div class="questionnaire-draft-question__description">
          <QuestionnaireDraftInputWrapper
            :id="question.questionId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Question"
            property-name="Description"
            :target-name="question.text"
          >
            <template #default="{ update }">
              <BaseTextArea :value="question.description" @update:value="update($event)" />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>

        <div class="questionnaire-draft-question__score">
          <QuestionnaireDraftQuestionColor
            :real-questionnaire-id="realQuestionnaireId"
            color="Blue"
            :question-id="question.questionId"
            :question-text="question.text"
            :score="question.blueScore"
          />
          <QuestionnaireDraftQuestionColor
            :real-questionnaire-id="realQuestionnaireId"
            color="Green"
            :question-id="question.questionId"
            :question-text="question.text"
            :score="question.greenScore"
          />
          <QuestionnaireDraftQuestionColor
            :real-questionnaire-id="realQuestionnaireId"
            color="Yellow"
            :question-id="question.questionId"
            :question-text="question.text"
            :score="question.yellowScore"
          />
          <QuestionnaireDraftQuestionColor
            :real-questionnaire-id="realQuestionnaireId"
            color="Red"
            :question-id="question.questionId"
            :question-text="question.text"
            :score="question.redScore"
          />
        </div>

        <div class="questionnaire-draft-question__text">
          <QuestionnaireDraftInputWrapper
            v-if="showQuestionGradingDescription"
            :id="question.questionId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Question"
            :property-name="'BlueText'"
            :label="t('questionnaire.draft.labels.description')"
            :target-name="question.text"
          >
            <template #default="{ update }">
              <BaseTextArea :value="question.blueText ?? ''" @update:value="update($event)" />
            </template>
          </QuestionnaireDraftInputWrapper>

          <QuestionnaireDraftInputWrapper
            v-if="showQuestionGradingDescription"
            :id="question.questionId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Question"
            :property-name="'GreenText'"
            :label="t('questionnaire.draft.labels.description')"
            :target-name="question.text"
          >
            <template #default="{ update }">
              <BaseTextArea :value="question.greenText ?? ''" @update:value="update($event)" />
            </template>
          </QuestionnaireDraftInputWrapper>

          <QuestionnaireDraftInputWrapper
            v-if="showQuestionGradingDescription"
            :id="question.questionId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Question"
            :property-name="'YellowText'"
            :label="t('questionnaire.draft.labels.description')"
            :target-name="question.text"
          >
            <template #default="{ update }">
              <BaseTextArea :value="question.yellowText ?? ''" @update:value="update($event)" />
            </template>
          </QuestionnaireDraftInputWrapper>

          <QuestionnaireDraftInputWrapper
            v-if="showQuestionGradingDescription"
            :id="question.questionId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Question"
            :property-name="'RedText'"
            :label="t('questionnaire.draft.labels.description')"
            :target-name="question.text"
          >
            <template #default="{ update }">
              <BaseTextArea :value="question.redText ?? ''" @update:value="update($event)" />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>

        <div class="questionnaire-draft-question__checkbox">
          <div></div>

          <QuestionnaireDraftQuestionColorCheckbox
            :real-questionnaire-id="realQuestionnaireId"
            :question-id="question.questionId"
            :question-text="question.text"
            color="Green"
            :overrides-category-color="question.greenOverridesCategoryColor"
          />

          <QuestionnaireDraftQuestionColorCheckbox
            :real-questionnaire-id="realQuestionnaireId"
            :question-id="question.questionId"
            :question-text="question.text"
            color="Yellow"
            :overrides-category-color="question.yellowOverridesCategoryColor"
          />

          <QuestionnaireDraftQuestionColorCheckbox
            :real-questionnaire-id="realQuestionnaireId"
            :question-id="question.questionId"
            :question-text="question.text"
            color="Red"
            :overrides-category-color="question.redOverridesCategoryColor"
          />
        </div>

        <div class="questionnaire-draft-question__validation__blue">
          <BaseValidationErrors :errors="v$.blueScore?.$errors" />
        </div>
        <div class="questionnaire-draft-question__validation__green">
          <BaseValidationErrors :errors="v$.greenScore?.$errors" />
        </div>
        <div class="questionnaire-draft-question__validation__yellow">
          <BaseValidationErrors :errors="v$.yellowScore?.$errors" />
        </div>
        <div class="questionnaire-draft-question__validation__red">
          <BaseValidationErrors :errors="v$.redScore?.$errors" />
        </div>
      </div>
    </template>
    <template #controls>
      <div>
        <QuestionnaireDescriptionToggle
          :show-description="showQuestionGradingDescription"
          @toggle="showQuestionGradingDescription = !showQuestionGradingDescription"
        />
      </div>
    </template>
  </QuestionnaireDraftEntityWrapper>

  <BaseButtonRow>
    <QuestionnaireDraftAddButtons
      v-if="
        question.position === category.questions.length &&
        area.position === draft.draft!.areas.length
      "
      :insert-into-id="draft.draft!.questionnaireId"
      :type="'Area'"
    />

    <QuestionnaireDraftAddButtons
      v-if="
        question.position === category.questions.length &&
        category.position === area.categories.length
      "
      :insert-into-id="area.areaId"
      :type="'Category'"
    />

    <QuestionnaireDraftAddButtons
      v-if="question.position === category.questions.length"
      :insert-into-id="category.categoryId"
      :type="'Question'"
    />
  </BaseButtonRow>
</template>

<style lang="scss">
.questionnaire-draft-question {
  display: grid;
  grid-template-areas:
    "name       name       position   position"
    "name-val   name-val   name-val   name-val"
    "desc       desc       desc       desc"
    "desc-val   desc-val   desc-val   desc-val"
    "score      score      score      score"
    "text       text       text       text"
    "checkbox   checkbox   checkbox   checkbox"
    "validation-blue validation-green validation-yellow validation-red";
  grid-template-columns: repeat(4, 1fr);
  gap: $spacing-4;
  align-items: flex-end;

  .base-select__wrapper__label > .base-label {
    font-size: $text-base;
    font-weight: inherit;
  }

  &__name {
    grid-area: name;
  }
  &__name__validation {
    grid-area: name-val;
  }
  &__position {
    grid-area: position;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-4;
  }
  &__description {
    grid-area: desc;
  }
  &__score {
    grid-area: score;
  }
  &__text {
    grid-area: text;
  }
  &__checkbox {
    grid-area: checkbox;
  }
  &__validation {
    &__blue {
      grid-area: validation-blue;
    }
    &__green {
      grid-area: validation-green;
    }
    &__yellow {
      grid-area: validation-yellow;
    }
    &__red {
      grid-area: validation-red;
    }
  }
  &__score,
  &__text,
  &__checkbox,
  &__validation {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-4;
  }
}

:deep(.questionnaire-draft__input-wrapper) {
  margin-bottom: 0;
}
</style>
