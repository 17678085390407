<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { ModuleDto, SiteDto, SurveyLeanDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";

type Props = {
  moduleName: ModuleDto["name"];
  siteName: SiteDto["name"];
  surveyDate: SurveyLeanDto["surveyDate"];
};

defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const print = () => {
  window.print();
};
</script>

<template>
  <div class="online-report-header">
    <h2 class="online-report-header__title">
      <slot>{{ t("userWeb.onlineReport.title") }}</slot>
      {{ moduleName }}
      {{ siteName }}
      <p class="online-report-header__title__subtitle">
        {{ t("userWeb.onlineReport.recommendationsFromSurvey", { date: surveyDate }) }}
      </p>
    </h2>
    <div class="online-report-header__filter">
      <slot name="settings"></slot>
      <div class="online-report-header__filter__print">
        <BaseButton variant="outlined" @click="print()">
          {{ t("userWeb.onlineReport.print") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.online-report-header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: $spacing-8;

  &__title {
    font-size: $text-lg;
    line-height: $leading-relaxed;
    font-weight: $font-semibold;

    &__subtitle {
      font-size: $text-base;
      font-weight: $font-medium;
    }
  }

  &__filter {
    display: flex;
    align-items: center;

    @media print {
      display: none;
    }

    &__print {
      display: flex;
      justify-content: flex-end;
      padding-left: $spacing-4;
    }
  }
}
</style>
