<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useTableFilter } from "@/composables/useTableFilter";
import { AdminRouteName } from "@/router/RouteName";
import { getQuestionnaireTemplates, createQuestionnaireTemplate } from "@/services/questionnaire";
import { QuestionnaireTemplateLeanDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";

const { t } = useI18n({ useScope: "global" });
const router = useRouter();

const isLoading = ref(false);
const questionnaires = ref<QuestionnaireTemplateLeanDto[]>([]);

onBeforeMount(async () => {
  isLoading.value = true;

  questionnaires.value = (await getQuestionnaireTemplates()) || [];

  isLoading.value = false;
});

const columns = [
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.questionnaireName"),
    key: "questionnaireName",
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.industries"),
    key: "industries",
    type: ColumnDataType.Array,
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.riskTypes"),
    key: "riskTypes",
    type: ColumnDataType.Array,
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.tags"),
    key: "tags",
    type: ColumnDataType.Array,
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.insertBy"),
    key: "insertByFullName",
  }),
] as ColumnDefinition<QuestionnaireTemplateLeanDto>[];

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(questionnaires, columns);

const hasRoleTemplate = (questionnaireId: number) => {
  router.push({
    name: AdminRouteName.QuestionnaireTemplateCurrent,
    params: { questionnaireId },
  });
};

const createQuestionnaireTemplateDraft = async () => {
  const questionnaireId = await createQuestionnaireTemplate();

  if (questionnaireId) {
    router.push({
      name: AdminRouteName.QuestionnaireTemplateDraft,
      params: { questionnaireId },
    });
  }
};
</script>

<template>
  <BaseGridTable
    enable-row-click
    :rows="result.result"
    :is-loading="isLoading"
    :columns="columns"
    @click:row="(row) => hasRoleTemplate(row.questionnaireId)"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <BaseButton @click="createQuestionnaireTemplateDraft">
        {{ t("questionnaireTemplates.newQuestionnaireTemplate") }}
      </BaseButton>
    </template>

    <template #column-industries="{ row }">
      {{ [...row.industries].join(", ") }}
    </template>
    <template #column-riskTypes="{ row }">
      {{ [...row.riskTypes].join(", ") }}
    </template>
    <template #column-tags="{ row }">
      {{ [...row.tags].join(", ") }}
    </template>
  </BaseGridTable>
</template>
