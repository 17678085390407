import { ref, readonly } from "vue";
import type { SearchExecutiveSummaryResponse } from "@/types/_generated/api";
import type { ExecSummaryServerFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import { getExecSummaryService } from "@/services/executiveSummary";

export const useGetExecSummary = () => {
  const isLoadingTableData = ref(false);
  const errorFetchingTableData = ref<boolean>(false);
  const executiveSummary = ref<SearchExecutiveSummaryResponse>();

  const getExecSummary = async (moduleId: number, filter?: ExecSummaryServerFilter) => {
    isLoadingTableData.value = true;
    errorFetchingTableData.value = false;

    try {
      const response = await getExecSummaryService(moduleId, filter);
      executiveSummary.value = response;
    } catch (_error) {
      errorFetchingTableData.value = true;
    } finally {
      isLoadingTableData.value = false;
    }
  };

  return {
    isLoadingTableData: readonly(isLoadingTableData),
    errorFetchingTableData: readonly(errorFetchingTableData),
    executiveSummary,
    getExecSummary,
  };
};
