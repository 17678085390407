<template>
  <h3 class="exec-summary-cat-rec-title">
    <slot></slot>
  </h3>
</template>

<style scoped lang="scss">
.exec-summary-cat-rec-title {
  font-size: $text-base;
  font-weight: $font-medium;
  margin-bottom: $spacing-8;
}
</style>
