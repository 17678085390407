export type RouteName = AppRouteName | AdminRouteName | UserWebRouteName;

export enum AppRouteName {
  Auth = "AdminWeb_Auth",
  Licenses = "AdminWeb_Licenses",
  ResetPassword = "AdminWeb_ResetPassword",
  SsoResponse = "AdminWeb_SsoResponse",
  TermsOfService = "AdminWeb_TermsOfService",
}

export enum AdminRouteName {
  AccountSettings = "AdminWeb_AccountSettings",
  ActivityLog = "AdminWeb_ActivityLog",
  AdminDashboard = "AdminWeb_Start",
  BlueRating = "AdminWeb_BlueRating",
  BlueRatingCategory = "AdminWeb_BlueRatingCategory",
  BlueRatingRecommendations = "AdminWeb_BlueRatingRecommendations",
  Client = "AdminWeb_Client",
  ClientDetails = "AdminWeb_ClientDetails",
  ClientModules = "AdminWeb_ClientModules",
  Clients = "AdminWeb_Clients",
  ClientUsers = "AdminWeb_ClientUsers",
  ErrorLog = "AdminWeb_ErrorLog",
  LoginAttemptLog = "AdminWeb_LoginAttemptLog",
  Module = "AdminWeb_Module",
  ModuleDetails = "AdminWeb_ModuleDetails",
  ModuleLibrary = "AdminWeb_ModuleLibrary",
  Modules = "AdminWeb_Modules",
  ModuleSites = "AdminWeb_ModuleSites",
  ModuleUsers = "AdminWeb_ModuleUsers",
  Questionnaire = "AdminWeb_Questionnaire",
  QuestionnaireCurrent = "AdminWeb_QuestionnaireCurrent",
  QuestionnaireDraft = "AdminWeb_QuestionnaireDraft",
  QuestionnaireTemplate = "AdminWeb_QuestionnaireTemplate",
  QuestionnaireTemplateCurrent = "AdminWeb_QuestionnaireTemplateCurrent",
  QuestionnaireTemplateDraft = "AdminWeb_QuestionnaireTemplateDraft",
  QuestionnaireTemplates = "AdminWeb_QuestionnaireTemplates",
  Root = "AdminWeb_AdminWebStart",
  ServiceMessages = "AdminWeb_ServiceMessages",
  Site = "AdminWeb_Site",
  SiteDetails = "AdminWeb_SiteDetails",
  SiteExemptionsTable = "AdminWeb_SiteExemptions",
  SitePermissionTable = "AdminWeb_SitePermission",
  SiteSurveys = "AdminWeb_SiteSurveys",
  StandardRecommendations = "AdminWeb_StandardRecommendations",
  SystemAdmin = "AdminWeb_SystemAdministration",
  Template = "AdminWeb_Template",
  Templates = "AdminWeb_Templates",
  Users = "AdminWeb_Users",
}

export enum UserWebRouteName {
  Root = "UserWeb_Root",
  DAAT = "UserWeb_DAAT",
  DAATBlueTrend = "UserWeb_DAAT_BlueTrend",
  DAATLossValues = "UserWeb_DAAT_LossValues",
  DAATRawData = "UserWeb_DAAT_RawData",
  Dashboard = "UserWeb_Dashboard",
  ExecutiveSummary = "UserWeb_ExecutiveSummary",
  Library = "UserWeb_Library",
  Module = "UserWeb_Module",
  Modules = "UserWeb_Modules",
  OnlineReport = "UserWeb_OnlineReport",
  Questionnaire = "UserWeb_Questionnaire",
  RecTracking = "UserWeb_RecTracking",
  RecTrackingDetailed = "UserWeb_RecTracking_Detailed",
  RecTrackingOverview = "UserWeb_RecTracking_Overview",
  Site = "UserWeb_Site",
  SiteBlueRating = "UserWeb_Site_BlueRating",
  SiteDetails = "UserWeb_Site_Details",
  SiteRecTracking = "UserWeb_Site_RecTracking",
  SiteReports = "UserWeb_Site_Reports",
  Sites = "UserWeb_Sites",
  SpoeCheck = "UserWeb_SpoeCheck",
}

export enum RecRespRouteName {
  Auth = "RecResp_Auth",
  Summary = "RecResp_Summary",
  SummaryDetailed = "RecResp_Summary_Detailed",
  SummaryOverview = "RecResp_Summary_Overview",
  Respond = "RecResp_Respond",
  Login = "RecResp_Login",
  SignedOut = "RecResp_SignedOut",
}
