import type { Directive } from "vue";

const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const focusTrap: Directive = {
  beforeMount(el) {
    const trapFocus = (event: KeyboardEvent) => {
      const focusableNodes = el.querySelectorAll(focusableElements);
      const firstFocusableElement = focusableNodes[0] as HTMLElement | null;
      const lastFocusableElement = focusableNodes[focusableNodes.length - 1] as HTMLElement | null;

      if (event.key === "Tab") {
        // If the Shift key is held down when the Tab key is pressed,
        // that means the user is trying to move the focus backwards.
        if (event.shiftKey) {
          // If the currently focused element is the first focusable element,
          // move the focus to the last focusable element.
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement?.focus();
            event.preventDefault();
          }
        } else {
          // If only the Tab key is pressed, the user is trying to move the focus forwards.
          if (document.activeElement === lastFocusableElement) {
            // If the currently focused element is the last focusable element,
            // move the focus to the first focusable element.
            firstFocusableElement?.focus();
            event.preventDefault();
          }
        }
      }
    };

    el.trapFocus = trapFocus;
    window.addEventListener("keydown", trapFocus);
  },
  mounted(el) {
    const focusableNodes = el.querySelectorAll(focusableElements);
    const firstFocusableElement = focusableNodes[0] as HTMLElement | null;

    // Automatically move focus to the first element when the trap is activated.
    firstFocusableElement?.focus();
  },
  unmounted(el) {
    window.removeEventListener("keydown", el.trapFocus);
  },
};

export { focusTrap as vFocusTrap };
