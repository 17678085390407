import { Ref, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AdminRouteName } from "@/router/RouteName";
import { getModuleService } from "@/services/modules";
import { ModuleDto } from "@/types/_generated/api";

export const useQuestionnaireBreadcrumbs = (
  moduleId: number | null,
  questionnaireName: Ref<string>,
  currentTab: Ref<string>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const module = ref<ModuleDto | null>(null);

  const loadModule = async (moduleId: number) => {
    module.value = await getModuleService(moduleId);
  };

  if (currentTab.value === "current" && moduleId) {
    loadModule(moduleId);
  }

  const questionnaireBreadcrumbs = computed(() =>
    !module.value
      ? []
      : [
          {
            title: module.value?.clientName,
            route: {
              name: AdminRouteName.Client,
              params: { clientId: module.value?.clientId },
            },
          },
          {
            title: module.value?.name,
            route: {
              name: AdminRouteName.Module,
              params: { moduleId },
            },
          },
          {
            title: t("questionnaire.questionnaire"),
            route: {
              name: AdminRouteName.Questionnaire,
              params: { moduleId },
            },
          },
          {
            title:
              currentTab.value === "current"
                ? t("questionnaire.current")
                : t("questionnaire.draft.draft"),
          },
        ],
  );

  const templateBreadcrumbs = computed(() => [
    {
      title: t("navigation.templates"),
      route: {
        name: AdminRouteName.Templates,
      },
    },
    {
      title: t("questionnaireTemplates.questionnaireTemplates"),
      route: {
        name: AdminRouteName.QuestionnaireTemplates,
      },
    },
    {
      title: questionnaireName.value,
      route: {
        name: AdminRouteName.QuestionnaireTemplateCurrent,
      },
    },
    {
      title:
        currentTab.value === "current"
          ? t("questionnaire.current")
          : t("questionnaire.draft.draft"),
    },
  ]);

  return { questionnaireBreadcrumbs, templateBreadcrumbs };
};
