<script setup lang="ts" generic="T">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { BaseFilterKey } from "@/types/Models";
import type { Option } from "@/types/Option";
import BaseOption from "./BaseSelect/BaseOption.vue";
import BaseSelect from "./BaseSelect/BaseSelect.vue";
import BaseTextField from "./BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    searchPhrase: string;
    searchKey?: BaseFilterKey<T>;
    searchKeys?: BaseFilterKey<T>[];
  }>(),
  {
    searchKey: undefined,
    searchKeys: () => [],
  },
);

defineEmits<{
  (event: "update:search-key", value: BaseFilterKey<T>): void;
  (event: "update:search-phrase", value: string): void;
}>();

const searchKeyOptions = computed(() =>
  props.searchKeys.map(({ name, title }) => ({ value: name, title })),
);

const searchKeyValue = computed(
  () =>
    (props.searchKey?.fixedOptions ?? []).find((o: Option) => o.value === props.searchPhrase)
      ?.value ?? "",
);
</script>

<template>
  <div class="base-filter">
    <BaseSelect
      :value="searchKey?.name ?? ''"
      @change="$emit('update:search-key', searchKeys.find((k) => k.name === $event)!)"
    >
      <BaseOption :value="''">
        {{ t("library.table.search.column") }}
      </BaseOption>
      <BaseOption v-for="key in searchKeyOptions" :key="key.value" :value="key.value">
        {{ key.title }}
      </BaseOption>
    </BaseSelect>

    <BaseSelect
      v-if="searchKey?.fixedOptions"
      :aria-label="t('library.table.search.value')"
      :value="searchKeyValue"
      @change="$emit('update:search-phrase', $event)"
    >
      <BaseOption :value="''">
        {{ t("library.table.search.value") }}
      </BaseOption>
      <BaseOption
        v-for="option in searchKey?.fixedOptions"
        :key="option.value"
        :value="option.value"
      >
        {{ option.title }}
      </BaseOption>
    </BaseSelect>

    <BaseTextField
      v-else
      :placeholder="t('library.table.search.phrase')"
      :aria-label="t('library.table.search.phrase')"
      :value="searchPhrase"
      @clear="$emit('update:search-phrase', '')"
      @update:value="$emit('update:search-phrase', $event as string)"
    />
  </div>
</template>

<style scoped lang="scss">
.base-filter {
  display: flex;
  gap: $spacing-2;
}
</style>
