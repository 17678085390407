<script setup lang="ts">
import { type DeepReadonly, computed } from "vue";
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { ExecutiveReportLossScenarioDto } from "@/types/_generated/api";
import { FilterColumn } from "../../../constants/FilterColumn";
import { ExecSummaryClientFilter } from "../../../types/FilterTypes";

interface Props {
  currencyCode: string;
  selectedColumns: ExecSummaryClientFilter["selectedColumns"];
  categoryColumnCount: number;
  lossScenarioColumns: DeepReadonly<ExecutiveReportLossScenarioDto[]>;
}

const props = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const columnCountBeforeLossScenarios = computed(() => {
  const baseCount = 2; // Starting with Site name and Survey type already counted as 2
  const optionalCounts = [
    props.selectedColumns.has(FilterColumn.Score) ? 1 : 0,
    props.selectedColumns.has(FilterColumn.Percentage) ? 1 : 0,
  ];

  return baseCount + props.categoryColumnCount + optionalCounts.reduce((a, b) => a + b, 0);
});
</script>

<template>
  <tr>
    <th :colspan="columnCountBeforeLossScenarios"></th>
    <th :colspan="lossScenarioColumns.length * 3">
      {{ `${t("userWeb.lossScenarios")} (${currencyCode})` }}
    </th>
  </tr>

  <tr>
    <th :colspan="columnCountBeforeLossScenarios"></th>
    <template v-for="(lossScenario, index) in lossScenarioColumns" :key="lossScenario.id">
      <th
        v-truncation-title
        class="exec-summary-table-header-loss-scenarios-subheader"
        colspan="3"
        :data-dark="index % 2 === 0"
      >
        {{ lossScenario.name }}
      </th>
    </template>
  </tr>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";

.exec-summary-table-header-loss-scenarios-subheader {
  max-width: 12rem; // Needed for truncation to work
}
</style>
