<script setup lang="ts">
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { UserWebRouteName } from "@/router/RouteName";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import SitesTable from "./components/SitesTable.vue";

defineProps<{ moduleId: number }>();

const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const { setBreadcrumbs } = useBreadcrumbs();

watch(
  () => route.name,
  (newRouteName) => {
    if (newRouteName === UserWebRouteName.Sites) {
      setBreadcrumbs([{ label: t("navigation.sites") }]);
    }
  },
  { immediate: true },
);

const tabs = [
  { title: t("navigation.details"), path: UserWebRouteName.SiteDetails },
  { title: t("sites.blueRating"), path: UserWebRouteName.SiteBlueRating },
  { title: t("sites.recommendationTracking"), path: UserWebRouteName.SiteRecTracking },
  { title: t("sites.reports"), path: UserWebRouteName.SiteReports },
];
</script>

<template>
  <UserWebViewLayout v-if="route.name === UserWebRouteName.Sites">
    <SitesTable :module-id="moduleId" />
  </UserWebViewLayout>

  <UserWebViewLayout v-else :tabs="tabs">
    <router-view></router-view>
  </UserWebViewLayout>
</template>
