<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BlueColor } from "@/types/_generated/api";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";

const { t } = useI18n({ useScope: "global" });
defineProps<{
  totalScore: number;
}>();
</script>

<template>
  <BaseBlueRatingStatusBadge :status="BlueColor.NotSet">
    {{ `${t("questionnaire.properties.totalLimit")}: ${totalScore}` }}
  </BaseBlueRatingStatusBadge>
</template>
