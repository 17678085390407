<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { LossScenarioDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

defineProps<{
  lossScenario: LossScenarioDto;
  isTemplateView?: boolean;
}>();

defineEmits<{
  "update:scenario": [LossScenarioDto];
  "delete:scenario": [void];
  "select:scenario": [LossScenarioDto];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="module-edit-loss">
    <div class="module-edit-loss__form">
      <BaseTextField
        :label="t('common.name')"
        :value="lossScenario.name"
        :maxlength="50"
        :disabled="isTemplateView"
        @update:value="
          $emit('update:scenario', {
            ...lossScenario,
            name: String($event),
          })
        "
      />
      <BaseTextArea
        :label="t('modules.propertyDamage')"
        :value="lossScenario.propertyDamage"
        :maxlength="1000"
        :disabled="isTemplateView"
        @update:value="
          $emit('update:scenario', {
            ...lossScenario,
            propertyDamage: String($event),
          })
        "
      />
      <BaseTextArea
        :label="t('modules.businessInterruption')"
        :value="lossScenario.businessInterruption"
        :maxlength="1000"
        :disabled="isTemplateView"
        @update:value="
          $emit('update:scenario', {
            ...lossScenario,
            businessInterruption: String($event),
          })
        "
      />
    </div>

    <div v-if="!isTemplateView" class="module-edit-loss__button">
      <BaseButton
        variant="text"
        color="error"
        :title="t('common.actions.delete')"
        @click="$emit('delete:scenario')"
      >
        <BaseIcon icon="trash" />
      </BaseButton>
    </div>

    <div v-if="isTemplateView" class="module-edit-loss__button">
      <BaseButton variant="outlined" @click="$emit('select:scenario', lossScenario)">
        {{ t("common.actions.select") }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-edit-loss {
  display: flex;
  align-items: center;
  gap: $spacing-4;
  padding: $spacing-4;
  border: 1px solid $primary-4;
  border-radius: $rounded-base;

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-4;
  }
}
</style>
