import * as validators from "@vuelidate/validators";
import { i18n } from "@/i18n";
import { LoginType } from "@/types/_generated/api";

// Non-Vuelidate validators

export const containsNoAtSymbol = (value: string, loginType?: LoginType): boolean => {
  if (loginType === LoginType.Blue) return true;
  return value.indexOf("@") === -1;
};

export const isValidMobileNumber = (value: string): boolean => {
  if (!value) return true;
  const mobileRegex = /^\+\d{2}[1-9]{1}\d{8,}$/;
  return regex(mobileRegex)(value);
};

// Vuelidate validators

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// Create your i18n message instance. Used for vue-i18n@9
export const withI18nMessage = createI18nMessage({
  t: i18n.global.t.bind(i18n),
});
// for vue-i18n@8
// const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required);

// arrow functions won't work here
// use function(this: { objName?: Partial<Obj> } | undefined) { ... }
// as argument for requiredIf
export const requiredIf = (...args: Parameters<typeof validators.requiredIf>) =>
  withI18nMessage(validators.requiredIf(...args), {
    withArguments: true,
  });

export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
});

export const minLength = (length: number) => withI18nMessage(validators.minLength(length));

export const sameAs = withI18nMessage(validators.sameAs, {
  withArguments: true,
});

export const regex = (pattern: RegExp) => (value: string) => new RegExp(pattern).test(value);

export const emailRegex = /^[a-zA-ZåäöÅÄÖ0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const email = withI18nMessage((value: string) => !value || regex(emailRegex)(value));

export const mobile = withI18nMessage(isValidMobileNumber);

export const minValue = withI18nMessage(validators.minValue, {
  withArguments: true,
});

export const between = (min: number, max: number) =>
  withI18nMessage(validators.between(min, max), {
    withArguments: true,
  });

export const integer = withI18nMessage(validators.integer);

export const fieldIsRequired = (param: string) =>
  withI18nMessage(
    {
      $validator: required.$validator,
      $params: {
        field: param,
      },
    },
    {
      withArguments: true,
    },
  );
