<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { QuestionnaireDraftActionType } from "@/types/_generated/api";
import BaseTextField from "@/components/base/BaseTextField.vue";
import QuestionnaireDraftInputWrapper from "../QuestionnaireDraftInputWrapper.vue";

const { t } = useI18n({ useScope: "global" });

type Color = "Red" | "Yellow" | "Green" | "Blue";

defineProps<{
  realQuestionnaireId: number;
  questionId: number;
  questionText: string;
  color: Color;
  score: number;
}>();
</script>

<template>
  <div class="questionnaire-draft-question__color">
    <QuestionnaireDraftInputWrapper
      :id="questionId"
      :real-questionnaire-id="realQuestionnaireId"
      :color="color"
      :type="QuestionnaireDraftActionType.Question"
      :class="{
        'questionnaire-draft-question__color__score': true,
        [`questionnaire-draft-question__color__score--${color.toLowerCase()}`]: true,
      }"
      :property-name="`${color}Score`"
      :label="t(`questionnaire.draft.colors.${color.toLowerCase()}`)"
      :target-name="questionText"
      :debounce-time="1000"
    >
      <template #default="{ update }">
        <BaseTextField
          class="questionnaire-draft-question__color__text-field"
          type="number"
          :value="score"
          @update:value="update($event)"
        />
      </template>
    </QuestionnaireDraftInputWrapper>
  </div>
</template>

<style scoped lang="scss">
:deep(.base-label) {
  text-align: center;
}
.questionnaire-draft-question__color {
  &__text-field {
    :deep(.base-textfield__box) {
      border-radius: 0 0 $rounded-base $rounded-base;
    }

    :deep(.base-textfield__label) {
      display: flex;
      justify-content: center;
    }
  }

  &__score {
    :deep(.questionnaire-draft__input-wrapper__header) {
      border-radius: $rounded-base;
      display: grid;
      grid-template-columns: 1fr auto;
    }
    :deep(.questionnaire-draft__input-wrapper__header__label) {
      text-align: center;
      padding-left: 37px;
    }

    :deep(.questionnaire-draft-input__history-btn):hover {
      background-color: transparent;
    }

    @include blue-rating-styles;
  }
}
</style>
