<script setup lang="ts">
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import { BlueTrendResultDto, SearchBlueTrendChartExcel } from "@/types/_generated/api";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import BaseChartHeader from "@/components/base/BaseChart/components/BaseChartHeader.vue";
import { Y_AXIS_STYLE } from "@/components/base/BaseChart/constants/style";
import ModInfoState, {
  type Props as ModInfoStateProps,
} from "@/components/mods/InfoState/ModInfoState.vue";
import { useBlueTrendChartData } from "./composables/useBlueTrendChartData";
import { DaatClientFilter } from "./composables/useFilterClient";
import { DaatServerFilter } from "./composables/useFilterServer";
import { FilterChartOptions } from "./constants/FilterChartOptions";
import { tooltipPercent, tooltipPoints } from "./helpers/chartFormating";

interface Props {
  moduleId: number;
  isLoading: boolean;
  hasError: boolean;
  clientFilter: DaatClientFilter;
  data: BlueTrendResultDto[] | null;
  serverFilter: DaatServerFilter;
}

const {
  clientFilter,
  data = [],
  isLoading,
  hasError,
  moduleId,
  serverFilter,
} = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });
const { blobDownload } = useDownloadBlob();

const { years, series } = useBlueTrendChartData(
  toRef(() => data ?? []),
  toRef(() => clientFilter),
);

const option = computed(() => ({
  grid: {
    left: 50,
    right: 50,
    containLabel: true,
  },
  tooltip: {
    trigger: "item",
    formatter: (params: { seriesName: string; name: string; value: number }) => {
      const base = `<b>
      ${
        clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage)
          ? tooltipPercent(params)
          : tooltipPoints(params)
      }
      </b>`;

      if (params.seriesName?.trim().length) {
        return `${params.seriesName}<br />${base}`;
      }

      return base;
    },
  },
  legend: {
    show: true,
    type: "scroll",
  },
  title: {
    left: "center",
  },
  xAxis: {
    type: "category",
    data: [...years.value],
    scale: clientFilter.selectedChartOptions.has(FilterChartOptions.Autoscale),
  },
  yAxis: {
    type: "value",
    scale: clientFilter.selectedChartOptions.has(FilterChartOptions.Autoscale),
    name: t("userWeb.daat.blueTrendChart.averageScore"),
    interval: clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage) ? 10 : null,
    axisLabel: {
      formatter: clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage)
        ? "{value} %"
        : "{value} p",
    },
    data: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    ...Y_AXIS_STYLE,
  },
  series: series.value,
}));

const currentInfoState = computed<ModInfoStateProps["state"] | null>(() => {
  if (hasError) {
    return "error";
  } else if (data?.length === 0) {
    return "empty";
  }
  return null;
});

const downloadExcel = () => {
  const url = `/v1/modules/${moduleId}/data-analysis/blue-trend/excel`;
  blobDownload.post<SearchBlueTrendChartExcel>(url, {
    moduleId,
    autoScaleYAxis: clientFilter.selectedChartOptions.has(FilterChartOptions.Autoscale),
    percentageOnYAxis: clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage),
    ...serverFilter,
  });
};
</script>

<template>
  <div class="daat-blue-trend-view">
    <ModInfoState v-if="currentInfoState && !isLoading" :state="currentInfoState" />

    <template v-else>
      <BaseChartHeader @download:excel="downloadExcel" />
      <BaseChart class="daat-blue-trend-view__chart" :option="option" :is-loading="isLoading" />
    </template>
  </div>
</template>

<style scoped lang="scss">
.daat-blue-trend-view {
  &__chart {
    height: 40rem;
  }
}
</style>
