import { useI18n } from "vue-i18n";
import { useTabs } from "@/composables/useTabs";

export enum SurveyReportMailTab {
  Main = "Main",
  History = "History",
}

export const useSurveyReportMailTabs = () => {
  const { t } = useI18n({ useScope: "global" });

  const tabs = [
    { name: SurveyReportMailTab.Main, label: t("survey.surveyReportMail.newReportMail") },
    { name: SurveyReportMailTab.History, label: t("survey.surveyReportMail.history") },
  ];

  const { activeTab, changeTab } = useTabs(tabs, SurveyReportMailTab.Main);

  return {
    tabs,
    activeTab,
    changeTab,
  };
};
