import { Ref, computed, onBeforeMount, ref } from "vue";
import { Sorting } from "@/helpers/sort";
import { ColumnDefinition } from "@/types/ColumnDefinition";

export const useToggleHiddenColumns = <T>(columns: Ref<ColumnDefinition<T>[]>) => {
  const hiddenColumns = ref([]) as Ref<Sorting<T>["key"][]>;

  const visibleColumns = computed(() =>
    columns.value.filter((column) => !hiddenColumns.value.includes(column.key)),
  );

  onBeforeMount(() => {
    hiddenColumns.value = columns.value
      .filter((column) => column.defaultHidden)
      .map((column) => column.key);
  });

  const toggle = (key: Sorting<T>["key"]) => {
    if (hiddenColumns.value.includes(key)) {
      hiddenColumns.value = hiddenColumns.value.filter((k) => k !== key);
    } else {
      hiddenColumns.value.push(key);
    }
  };

  return {
    hiddenColumns,
    visibleColumns,
    toggleColumnVisibility: toggle,
  };
};
