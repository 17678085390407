const units = ["bytes", "Kb", "Mb"];

export const bytesToSize = (sizeInBytes: number) => {
  let l = 0,
    n = sizeInBytes || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};
