<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  value: string;
  errorMessage?: string;
}>();

defineEmits<{
  "update:value": [string];
}>();

const errors = computed(() =>
  props.errorMessage ? [{ $message: props.errorMessage } as ErrorObject] : undefined,
);
</script>

<template>
  <BaseTextField
    :placeholder="t('common.email')"
    :value="value"
    :errors="errors"
    @update:value="$emit('update:value', $event as string)"
  />
</template>
