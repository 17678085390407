import { ref, readonly } from "vue";
import { createRecReportSettingsService } from "@/services/recommendationReportSettings";
import { RecReportSettingsScope } from "@/types/_generated/api";

export const useCreateSettings = (getSettings: () => Promise<void>) => {
  const isCreatingSettings = ref(false);

  const createSettings = async (moduleId: number, scope: RecReportSettingsScope) => {
    isCreatingSettings.value = true;

    try {
      await createRecReportSettingsService({ moduleId, scope });
      getSettings(); // Fetch the settings again to update the UI
    } finally {
      isCreatingSettings.value = false;
    }
  };

  return {
    isCreatingSettings: readonly(isCreatingSettings),
    createSettings,
  };
};
