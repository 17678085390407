import { updateUserService } from "@/services/users";
import { LoginType } from "@/types/_generated/api";
import type { UserForm } from "../types";

export const useUpdateUser = () => {
  const updateUser = async (userForm: UserForm) => {
    const updatedUser = { ...userForm };

    // Username must be empty if login type is Blue
    if (userForm.loginType === LoginType.Blue) {
      updatedUser.userName = undefined;
    }

    await updateUserService(updatedUser);
  };

  return {
    updateUser,
  };
};
