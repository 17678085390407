import { ref, readonly } from "vue";
import type { SiteLeanDto } from "@/types/_generated/api";
import type { SiteDefinitionProperty } from "@/types/SiteDefinitions";
import { getModuleSites as getModuleSitesService } from "@/services/sites";

export type SiteDefinitions = Record<SiteDefinitionProperty, string | undefined>;

export type SitesData = SiteDefinitions & {
  sites: SiteLeanDto[];
};

export const useGetSitesData = () => {
  const sitesData = ref<SitesData>({
    siteDefinition1: "",
    siteDefinition2: "",
    siteDefinition3: "",
    siteDefinition4: "",
    sites: [],
  });
  const isLoadingSites = ref(false);
  const hasError = ref(false);

  const getSitesData = async (moduleId: number, includeOnlyPublished?: boolean) => {
    isLoadingSites.value = true;
    hasError.value = false;

    try {
      const response = await getModuleSitesService(moduleId, includeOnlyPublished);

      if (response === null) {
        throw new Error("Failed to fetch sites");
      }

      sitesData.value = {
        siteDefinition1: response.moduleSiteDefinition1,
        siteDefinition2: response.moduleSiteDefinition2,
        siteDefinition3: response.moduleSiteDefinition3,
        siteDefinition4: response.moduleSiteDefinition4,
        sites: response.sites,
      };
    } catch (_error) {
      hasError.value = true;
    } finally {
      isLoadingSites.value = false;
    }
  };

  return {
    sitesData: readonly(sitesData),
    isLoadingSites: readonly(isLoadingSites),
    hasError: readonly(hasError),
    getSitesData,
  };
};
