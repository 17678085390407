import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { enumToOptions } from "@/helpers/mappers/enumToOptions";
import { binaryOptions } from "@/helpers/options";
import { useAppStore } from "@/stores/app";
import { LoginType, Role, UserLeanDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";

export const useClientUsersTableColumns = (showLockedAndArchived: Ref<boolean>) => {
  const { t } = useI18n({ useScope: "global" });

  const app = useAppStore();

  const clientUsersTableColumns = computed(() => {
    const columns: ColumnDefinition<UserLeanDto>[] = [
      new ColumnDefinition({ title: t("common.name"), key: "fullName" }),
      new ColumnDefinition({ title: t("common.email"), key: "email" }),
      new ColumnDefinition({
        title: t("common.role"),
        key: "role",
        searchOptions: enumToOptions(Role),
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: t("users.loginType"),
        key: "loginType",
        searchOptions: enumToOptions(LoginType),
        autoWidth: true,
      }),
    ];

    if (app.isAdmin && showLockedAndArchived.value) {
      columns.push(
        new ColumnDefinition({
          title: t("common.archived"),
          key: "isArchived",
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
        new ColumnDefinition({
          title: t("users.lockedOut"),
          key: "lockedDate",
          type: ColumnDataType.Date,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
      );
    }
    return columns;
  });

  return {
    clientUsersTableColumns,
  };
};
