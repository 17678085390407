<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { authorize } from "@/plugins/can";
import { createQuestionnaireDraft } from "@/services/questionnaire-draft";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import QuestionnaireNav from "@/views/Admin/Questionnaire/components/QuestionnaireNav/QuestionnaireNav.vue";
import AppOverflowContainer from "@/components/app/AppOverflowContainer.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import QuestionnaireViewer from "@/components/shared/Questionnaire/QuestionnaireViewer.vue";
import { useQuestionnaireNavigation } from "./composables/useQuestionnaireNavigation";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  moduleId: number | null;
}>();

// This is a child view of ViewQuestionnaire.vue which should have loaded the questionnaire
const { questionnaire } = useQuestionnaireStore();
const { navigateToDraft } = useQuestionnaireNavigation();

const newQuestionnaireDraft = async () => {
  if (!questionnaire?.questionnaireId) {
    return;
  }
  const response = await createQuestionnaireDraft({
    questionnaireId: questionnaire.questionnaireId,
  });

  if (response) {
    questionnaire!.hasDraft = true;

    navigateToDraft(props.moduleId);
  }
};

const canCreateNewDraft = computed(() => {
  if (questionnaire?.hasDraft) {
    return false;
  }

  const isTemplate = !props.moduleId;

  return isTemplate || authorize.hasModulePermission(props.moduleId, "editQuestionnaire");
});
</script>

<template>
  <div class="view-questionnaire-current">
    <AppOverflowContainer :remove-navigation="true" :margin="'3rem'">
      <QuestionnaireNav :questionnaire="questionnaire || undefined" />
    </AppOverflowContainer>

    <div class="view-questionnaire-current__viewer">
      <div>
        <div class="view-questionnaire-current__controls">
          <BaseButton v-if="canCreateNewDraft" @click="newQuestionnaireDraft">
            {{ t("questionnaire.draft.create") }}
          </BaseButton>
        </div>

        <AppOverflowContainer :remove-navigation="true" :margin="'7.5rem'">
          <QuestionnaireViewer :questionnaire="questionnaire || undefined" />
        </AppOverflowContainer>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view-questionnaire-current {
  display: grid;
  grid-template-columns: 350px 1fr;

  &__controls {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-2;
    padding: 0 $spacing-4;
    margin: $spacing-4 0;
  }
}
</style>
