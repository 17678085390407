<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseButton from "../BaseButton.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  errors: string[];
}>();

defineEmits<{
  (e: "close"): void;
}>();
</script>

<template>
  <Transition name="fade">
    <div v-show="errors?.length" class="base-file-upload__error-overlay">
      <BaseIcon icon="warning" size="xxlarge" />
      <div v-for="err in errors" :key="err">
        {{ err }}
      </div>

      <BaseButton
        v-if="errors.length"
        class="base-file-upload__error-overlay__close"
        variant="text"
        :title="t('common.actions.close')"
        @click="() => $emit('close')"
      >
        <BaseIcon icon="close" />
      </BaseButton>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.base-file-upload__error-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0.8;
  justify-content: center;
  gap: $spacing-2;
  padding: $spacing-4;
  position: absolute;
  z-index: 1;
  color: $primary-2;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);

  &__close {
    position: absolute;
    top: $spacing-2;
    right: $spacing-2;
    z-index: 2;

    &:hover {
      svg {
        color: black;
      }
    }

    svg {
      color: white;
    }
  }
}
</style>
