import { ref } from "vue";
import { getLastPublishedSurveyService } from "@/services/surveys";

export const useGetLastPublishedSurvey = () => {
  const isLoadingBlueRating = ref(false);

  const getLastPublishedSurvey = async (siteId: number) => {
    isLoadingBlueRating.value = true;

    const response = await getLastPublishedSurveyService(siteId);

    isLoadingBlueRating.value = false;

    return response;
  };

  return {
    isLoadingBlueRating,
    getLastPublishedSurvey,
  };
};
