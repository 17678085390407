<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useSitePermissionFilterService } from "@/composables/services/useSitePermissionFilterService";
import { useDialog } from "@/composables/useDialog";
import { SitePermissionFilterDto } from "@/types/_generated/api";
import { CustomDefinitionNumber, SiteDefinition } from "@/types/SiteDefinitions";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import UserSiteDefinitionFilter from "./UserSiteDefinitionFilter.vue";

const dialog = useDialog();
const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  moduleId: number;
  hasChanges: boolean;
  userId: number;
  siteDefinitions: SiteDefinition[];
}>();

const emits = defineEmits<{
  (event: "evaluate", value: number[]): void;
  (event: "delete"): void;
  (event: "save"): void;
}>();

const {
  isLoadingSitePermissionFilter,
  getSitePermissionFilter,
  deleteSitePermissionFilter,
  evaluateSitePermissionFilter,
  saveSitePermissionFilter,
} = useSitePermissionFilterService();

const originalFilter = ref<SitePermissionFilterDto[]>([]);
const permissionFilter = ref<SitePermissionFilterDto[]>([]);

const isEditing = computed(
  () =>
    permissionFilter.value.length == 0 ||
    permissionFilter.value.some((f) => f.values.length == 0) ||
    props.siteDefinitions.length !== permissionFilter.value.length,
);

const updatePermissionFilter = async (event: {
  customDefinitionNumber: CustomDefinitionNumber;
  values: string[];
}) => {
  let filter = permissionFilter.value.find(
    (f) => f.customDefinitionNumber === event.customDefinitionNumber,
  );

  if (filter) {
    permissionFilter.value = [
      ...permissionFilter.value.filter(
        (f) => f.customDefinitionNumber !== event.customDefinitionNumber,
      ),
      event,
    ];
  } else {
    filter = {
      customDefinitionNumber: event.customDefinitionNumber,
      values: event.values,
    };

    permissionFilter.value = [...permissionFilter.value, filter];
  }

  evaluateFilter();
};

const isValidFilter = (sitePermissionFilter: SitePermissionFilterDto[]) => {
  if (sitePermissionFilter.length === 0) {
    return false;
  }

  if (sitePermissionFilter.length !== props.siteDefinitions.length) {
    return false;
  }

  for (const filter of sitePermissionFilter) {
    if (filter.values.length === 0) {
      return false;
    }
  }

  return true;
};

const addValue = (customDefinitionNumber: CustomDefinitionNumber, value: string) => {
  const currentFilter = permissionFilter.value.find(
    (f) => f.customDefinitionNumber === customDefinitionNumber,
  );

  const filter = {
    customDefinitionNumber,
    values: currentFilter?.values ?? [],
  };

  if (value === "[All]" || filter.values.length === 0 || filter.values.includes("[All]")) {
    filter.values = [value];
  } else {
    filter.values.push(value);
  }

  updatePermissionFilter(filter);
};

const removeValue = (customDefinitionNumber: CustomDefinitionNumber, value: string) => {
  const currentFilter = permissionFilter.value.find(
    (f) => f.customDefinitionNumber === customDefinitionNumber,
  );

  const filter = {
    customDefinitionNumber,
    values: currentFilter?.values ?? [],
  };

  if (!filter) {
    throw new Error("Filter not found");
  }

  if (!filter.values?.length) {
    throw new Error("Filter values not found");
  }

  if (filter.values.length === 1) {
    filter.values = [];
  } else {
    filter.values = filter.values.filter((v) => v !== value);
  }

  updatePermissionFilter(filter);
};

const updateOriginalFilter = () => (originalFilter.value = [...permissionFilter.value]);

const deleteFilter = async () => {
  if (await dialog.openDeletePrompt(t("users.permissions.deleteFilterDescription"))) {
    await deleteSitePermissionFilter(props.userId, props.moduleId);

    permissionFilter.value = [];
    updateOriginalFilter();

    emits("save");
  }
};

const evaluateFilter = async () => {
  const isValid = isValidFilter(permissionFilter.value);

  if (isValid) {
    const result = await evaluateSitePermissionFilter({
      moduleId: props.moduleId,
      filter: permissionFilter.value,
    });

    emits(
      "evaluate",
      result!.map((r) => r.siteId),
    );
  } else {
    emits("evaluate", []);
  }
};

const saveFilter = async () => {
  await saveSitePermissionFilter(props.userId, props.moduleId, permissionFilter.value);
  updateOriginalFilter();
  emits("save");
};

const reset = async () => {
  permissionFilter.value = Array.from(originalFilter.value);

  await evaluateFilter();
  emits("save");
};

onBeforeMount(async () => {
  const filter = await getSitePermissionFilter(props.userId, props.moduleId);

  if (filter) {
    permissionFilter.value = filter;
    updateOriginalFilter();
  }
});
</script>

<template>
  <BaseCard :title="t('users.permissions.sitePermissionFilter')" has-padding>
    <template #header>
      <BaseButton
        :disabled="!hasChanges || isEditing || isLoadingSitePermissionFilter"
        @click="saveFilter"
      >
        {{ t("users.permissions.saveFilter") }}
      </BaseButton>

      <BaseButton
        :disabled="!hasChanges || isLoadingSitePermissionFilter"
        variant="outlined"
        @click="reset"
      >
        {{ t("users.permissions.resetFilter") }}
      </BaseButton>

      <BaseButton
        :disabled="originalFilter.length === 0 || isLoadingSitePermissionFilter"
        color="error"
        variant="outlined"
        @click="deleteFilter"
      >
        {{ t("users.permissions.deleteFilter") }}
      </BaseButton>
    </template>

    <div class="user-site-permission__filter-description">
      {{ t("users.permissions.sitePermissionsDescription") }}
    </div>
    <div class="user-site-permission-filter">
      <UserSiteDefinitionFilter
        v-for="(siteDefinition, i) in props.siteDefinitions"
        :key="i"
        :site-definition="siteDefinition"
        :permission-filter="permissionFilter"
        @add="addValue(siteDefinition.customDefinitionNumber, $event)"
        @remove="removeValue(siteDefinition.customDefinitionNumber, $event)"
      />
    </div>
  </BaseCard>
</template>

<style scoped lang="scss">
.user-site-permission-filter {
  display: flex;
}

.user-site-permission-filter-description {
  font-size: $text-sm;
  padding: $spacing-3;
}
</style>
