import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { GetApplicationSettingsResponse } from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getApplicationSettings = async (): Promise<GetApplicationSettingsResponse | null> => {
  try {
    const { data } = await http.get<GetApplicationSettingsResponse>(`/application-settings`);
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("common.settings"),
    });
  }
  return null;
};
