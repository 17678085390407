<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { UpdateFormField, ValidationErrors } from "@/composables/validation/types";
import { loginTypes } from "@/helpers/options";
import { LoginType, type UserDto, Role, MfaOption } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import PasswordForm from "@/components/shared/PasswordForm.vue";
import { type UserForm } from "./types";
import UserEditDrawerAccountPasswordModal from "./UserEditDrawerAccountPasswordModal.vue";
import UserEditDrawerMfaSelect from "./UserEditDrawerMfaSelect.vue";

const props = defineProps<{
  password1: string;
  password2: string;
  sendPasswordResetEmail: boolean;
  isEditingUser: boolean;
  userDto: UserDto | null;
  errorMessage: ValidationErrors<UserForm>;
  userForm: UserForm;
}>();

const emit = defineEmits<{
  "update:password-reset-email": [boolean];
  "update:password1": [string];
  "update:password2": [string];
  "update:field": UpdateFormField<UserForm>;
}>();

const { t } = useI18n({ useScope: "global" });

const showPasswordModal = ref(false);

const togglePasswordModal = () => {
  showPasswordModal.value = !showPasswordModal.value;
};

const updateResetEmail = (event: boolean) => {
  // Reset passwords
  emit("update:password1", "");
  emit("update:password2", "");

  emit("update:password-reset-email", event);
};

const isChangePasswordDisabled = computed(
  // Disabled until saving when switching from LoginType.INT/EXT to LoginType.Blue
  () => props.userForm.loginType === LoginType.Blue && props.userDto?.loginType !== LoginType.Blue,
);

const showChangePassword = computed(() => {
  // Disabled until saving when switching from LoginType.INT/EXT to LoginType.Blue
  return props.isEditingUser && props.userForm.loginType === LoginType.Blue;
});

const updateLoginType = (event: string) => {
  if (!props.isEditingUser && event === LoginType.Blue) {
    emit("update:password-reset-email", true);
  }

  emit("update:field", "loginType", event);
};
</script>

<template>
  <div class="user-edit-account">
    <BaseDataDisplayListTitle>{{ t("common.account") }}</BaseDataDisplayListTitle>

    <div class="user-edit-account__fields">
      <BaseSelect
        :label="t('users.loginType')"
        :value="userForm.loginType"
        @change="updateLoginType"
      >
        <BaseOption v-for="loginType in loginTypes" :key="loginType.value" :value="loginType.value">
          {{ loginType.title }}
        </BaseOption>
      </BaseSelect>

      <UserEditDrawerMfaSelect
        v-if="userForm.role !== Role.EXT"
        :role="userForm.role"
        :value="userForm.mfa ?? MfaOption.EmailorSms"
        :error-message="errorMessage.mfa"
        @change="$emit('update:field', 'mfa', $event)"
      />

      <BaseTextField
        :label="t('common.email')"
        required
        :error-message="errorMessage.email"
        :value="userForm.email"
        @update:value="$emit('update:field', 'email', String($event))"
      />

      <BaseTextField
        v-if="userForm.loginType !== LoginType.Blue"
        :label="t('common.username')"
        required
        :error-message="errorMessage.userName"
        :value="userForm.userName"
        @update:value="$emit('update:field', 'userName', String($event))"
      />

      <div v-if="showChangePassword">
        <BaseLabel has-spacing class="user-edit-account__hidden-label">-</BaseLabel>
        <BaseButton
          :disabled="isChangePasswordDisabled"
          variant="outlined"
          :title="isChangePasswordDisabled ? t('users.changePasswordWarning') : ''"
          @click="togglePasswordModal"
        >
          {{ t("users.changePassword") }}
        </BaseButton>
      </div>
    </div>

    <BaseCheckbox
      v-if="!isEditingUser && userForm.loginType === LoginType.Blue"
      :checked="sendPasswordResetEmail"
      :label="t('users.explicitlySetPassword')"
      @change="updateResetEmail"
    />

    <PasswordForm
      v-if="userForm.loginType === LoginType.Blue && !sendPasswordResetEmail && !isEditingUser"
      :password1="password1"
      :password2="password2"
      @update:password1="$emit('update:password1', $event)"
      @update:password2="$emit('update:password2', $event)"
    />
  </div>

  <UserEditDrawerAccountPasswordModal
    v-if="showPasswordModal"
    :user-id="userForm.userId"
    @close-modal="togglePasswordModal"
  />
</template>

<style scoped lang="scss">
.user-edit-account {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__hidden-label {
    visibility: hidden;
  }

  &__fields {
    display: grid;
    grid-template-columns: auto auto 1fr 1fr;
    gap: $spacing-4;
  }
}
</style>
