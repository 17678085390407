<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { Editor } from "../constants";

export type PopoverForm = {
  type: Editor.Popover.Form;
  label: string;
  placeholder: string;
  confirmButtonText: string;
};

interface Props {
  content: PopoverForm;
}

defineProps<Props>();

const emit = defineEmits<{
  action: [payload?: unknown];
}>();

const { t } = useI18n({ useScope: "global" });

const linkInput = ref("");

const onConfirm = (link: string) => {
  emit("action", link);
};

const onSubmitLinkForm = (event: Event) => {
  event.preventDefault();
  onConfirm(linkInput.value);
};
</script>

<template>
  <form class="base-editor-popover-form" @submit="onSubmitLinkForm">
    <div>
      <BaseLabel>{{ content.label }}</BaseLabel>
      <BaseTextField
        :value="linkInput"
        :placeholder="content.placeholder"
        @input="linkInput = $event"
      />
    </div>

    <div class="base-editor-popover-form__controls">
      <BaseButton type="button" variant="outlined" @click="$emit('action')">
        {{ t("common.actions.cancel") }}
      </BaseButton>

      <BaseButton type="submit">
        {{ content.confirmButtonText }}
      </BaseButton>
    </div>
  </form>
</template>

<style scoped lang="scss">
.base-editor-popover-form {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: $spacing-5;

  &__controls {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-2;
  }
}
</style>
