<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onBeforeMount, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { ValueType, useQuestionnaireDraftClient } from "@/composables/useQuestionnaireDraftClient";
import { useQuestionnaireDraftValidation } from "@/composables/useQuestionnareDraftValidation";
import { languages } from "@/helpers/options";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import { AreaDto, QuestionnaireDraftActionType } from "@/types/_generated/api";
import { useQuestionnaireMetaDataOptions } from "@/views/Admin/Questionnaire/composables/useQuestionnaireMetaDataOptions";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BaseValidationErrors from "@/components/base/BaseValidationErrors.vue";
import QuestionnaireDraftAddButtons from "./QuestionnaireDraftAddButtons.vue";
import QuestionnaireDraftEntityWrapper from "./QuestionnaireDraftEntityWrapper.vue";
import QuestionnaireDraftInputWrapper from "./QuestionnaireDraftInputWrapper.vue";
import QuestionnaireDraftPosition from "./QuestionnaireDraftPosition.vue";

const props = defineProps<{
  realQuestionnaireId: number;
  area: AreaDto;
  areaCount: number;
}>();

defineEmits<{
  (event: "delete"): void;
}>();

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();
const store = useQuestionnaireStore();
const draft = useQuestionnaireDraftStore();
const client = useQuestionnaireDraftClient(store.questionnaire!.questionnaireId!);
const { industryOptions, riskTypeOptions } = useQuestionnaireMetaDataOptions();

const areaRef = toRef(() => props.area);
const { areaRules } = useQuestionnaireDraftValidation();

const v$ = useVuelidate(areaRules, areaRef, {
  $registerAs: `area_${props.area.areaId}`,
  $autoDirty: true,
});

onBeforeMount(() => v$.value.$touch());

const isLinkedWithHistory = computed(
  () =>
    props.area.categories.some(
      (c) => c.basedOnCategoryId || c.questions.some((q) => q.basedOnQuestionId),
    ) && store.questionnaire?.moduleId,
);

const tags = computed(() =>
  (props.area.tags || "")
    .split(",")
    .map((x) => (x || "").trim())
    .filter((x) => Boolean(x)),
);

const addTag = (tag: string, update: (value: ValueType) => void) => {
  update([...new Set([...tags.value, tag])].join(","));
};

const removeTag = (tag: string, update: (value: ValueType) => void) => {
  update([...tags.value.filter((x) => x !== tag)].join(","));
};

const deleteArea = async () => {
  const entity = t("questionnaire.types.area");

  const isConfirmed = await dialog.openDialog({
    title: t("common.deleteQuestionnairePrompt.title", { entity }),
    description: `${t("common.deleteQuestionnairePrompt.message", { entity })} ${
      isLinkedWithHistory.value
        ? t("common.deleteQuestionnairePrompt.historyWarning", { entity })
        : ""
    }`,
    confirmText: t("common.actions.delete"),
  });

  if (isConfirmed) {
    client.area.remove(props.area.areaId, draft.draft!);
  }
};
</script>

<template>
  <QuestionnaireDraftEntityWrapper
    :id="`area_${area.areaId}`"
    :title="area.name"
    :position="`${area.position}`"
    :type="QuestionnaireDraftActionType.Area"
    @delete="deleteArea"
  >
    <template #default>
      <div class="questionnaire-draft-area">
        <div class="questionnaire-draft-area__name">
          <QuestionnaireDraftInputWrapper
            :id="area.areaId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Area"
            property-name="Name"
            :target-name="area.name"
          >
            <template #default="{ update }">
              <BaseTextField
                :placeholder="t('questionnaire.draft.placeholders.areaName')"
                :value="area.name"
                @update:value="update"
              />
            </template>
          </QuestionnaireDraftInputWrapper>

          <QuestionnaireDraftPosition
            :levels="[
              { count: areaCount, current: area.position, type: QuestionnaireDraftActionType.Area },
            ]"
            @update="({ position }) => client.area.moveTo(area.areaId, position)"
          />
        </div>

        <div class="questionnaire-draft-area__name__validation">
          <BaseValidationErrors :errors="v$.name?.$errors" />
        </div>

        <div class="questionnaire-draft-area__country">
          <QuestionnaireDraftInputWrapper
            :id="area.areaId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Area"
            property-name="WillisCountryOrUnit"
            :target-name="area.name"
          >
            <template #default="{ update }">
              <BaseTextField :value="area.willisCountryOrUnit" @update:value="update" />
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>

        <div class="questionnaire-draft-area__industry">
          <QuestionnaireDraftInputWrapper
            :id="area.areaId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Area"
            property-name="Industry"
            :target-name="area.name"
            :debounce-time="0"
          >
            <template #default="{ update }">
              <BaseSelect :value="area.industry" @change="($event) => update($event as string)">
                <BaseOption
                  v-for="option in industryOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.title }}
                </BaseOption>
              </BaseSelect>
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>

        <div class="questionnaire-draft-area__risk">
          <QuestionnaireDraftInputWrapper
            :id="area.areaId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Area"
            property-name="RiskType"
            :target-name="area.name"
            :debounce-time="0"
          >
            <template #default="{ update }">
              <BaseSelect :value="area.riskType" @change="($event) => update($event as string)">
                <BaseOption
                  v-for="option in riskTypeOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.title }}
                </BaseOption>
              </BaseSelect>
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>

        <div class="questionnaire-draft-area__language">
          <QuestionnaireDraftInputWrapper
            :id="area.areaId"
            :real-questionnaire-id="realQuestionnaireId"
            :type="QuestionnaireDraftActionType.Area"
            property-name="Language"
            :target-name="area.name"
            :debounce-time="0"
          >
            <template #default="{ update }">
              <BaseSelect :value="area.language" @change="($event) => update($event as string)">
                <BaseOption v-for="option in languages" :key="option.value" :value="option.value">
                  {{ option.title }}
                </BaseOption>
              </BaseSelect>
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>

        <div class="questionnaire-draft-area__language__validation">
          <BaseValidationErrors :errors="v$.language?.$errors" />
        </div>

        <div class="questionnaire-draft-area__tags">
          <QuestionnaireDraftInputWrapper
            :id="area.areaId"
            :real-questionnaire-id="realQuestionnaireId"
            class="questionnaire-draft-area__main__tags"
            :type="QuestionnaireDraftActionType.Area"
            property-name="Tags"
            :target-name="area.name"
            :debounce-time="0"
          >
            <template #default="{ update }">
              <BasePillsInput :values="tags" @add="(tag: string) => addTag(tag, update)">
                <template #pill="{ value, index }">
                  <BasePill
                    :key="index"
                    :label="value"
                    clickable
                    @click.stop="() => removeTag(value, update)"
                  />
                </template>
              </BasePillsInput>
            </template>
          </QuestionnaireDraftInputWrapper>
        </div>
      </div>
    </template>
  </QuestionnaireDraftEntityWrapper>

  <BaseButtonRow>
    <QuestionnaireDraftAddButtons
      v-if="area.position === draft.draft?.areas.length && area.categories.length === 0"
      :type="'Area'"
      :insert-into-id="draft.draft?.questionnaireId"
    />

    <QuestionnaireDraftAddButtons
      v-if="area.categories.length === 0"
      :type="'Category'"
      :insert-into-id="area.areaId"
    />
  </BaseButtonRow>
</template>

<style lang="scss">
.questionnaire-draft-area {
  display: grid;
  gap: $spacing-4;
  grid-template-areas:
    "name        name         name     name"
    "name-val    name-val     name-val name-val"
    "country     industry     risk     language"
    "country-val industry-val risk-val language-val"
    "tags        tags         tags     tags"
    "tags-val    tags-val     tags-val tags-val";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  .base-select__wrapper__label > .base-label {
    font-size: $text-base;
    font-weight: inherit;
  }

  &__name {
    grid-area: name;
    display: grid;
    grid-template-columns: 8fr 1fr;
    gap: $spacing-4;

    > .base-select__wrapper {
      justify-content: end;
    }
  }
  &__name__validation {
    grid-area: name-val;
  }
  &__country {
    grid-area: country;
  }
  &__country__validation {
    grid-area: country-val;
  }
  &__industry {
    grid-area: industry;
  }
  &__industry__validation {
    grid-area: industry-val;
  }
  &__risk {
    grid-area: risk;
  }
  &__risk__validation {
    grid-area: risk-val;
  }
  &__language {
    grid-area: language;
  }
  &__language__validation {
    grid-area: language-val;
  }
  &__tags {
    grid-area: tags;
  }
  &__tags__validation {
    grid-area: tags-val;
  }
}
</style>
