import {
  ExecReportSortOrder,
  ExecutiveReportFilter,
  SiteDefinitionFilter,
  SurveyType,
} from "@/types/_generated/api";
import { FilterColumn } from "../constants/FilterColumn";

export class ExecSummaryServerFilter
  implements Omit<ExecutiveReportFilter, "siteIds" | "surveyType" | "includeOnlyLatestSurvey">
{
  startDate?: string;
  endDate?: string;
  surveyRound: number;
  treatNotApplicableAsZero: boolean;
  excludedSiteDefinitions: SiteDefinitionFilter;
  categoryId?: number;
  excludedCategoryIds: number[];
  groupBySiteDefinition?: number;
  sortBy: ExecReportSortOrder;
  sortByLossScenarioId?: number;

  constructor(filter?: Partial<ExecSummaryServerFilter>) {
    this.surveyRound = 0;
    this.treatNotApplicableAsZero = false;
    this.excludedSiteDefinitions = {
      custom1: [],
      custom2: [],
      custom3: [],
      custom4: [],
    };
    this.excludedCategoryIds = [];
    this.sortBy = ExecReportSortOrder.Alphabetical;

    if (filter) {
      Object.assign(this, filter);
    }
  }
}

export class ExecSummaryClientFilter {
  surveyType: SurveyType = SurveyType.WEXT;
  selectedColumns: Set<string> = new Set([FilterColumn.Percentage]);

  constructor(filter?: Partial<ExecSummaryClientFilter>) {
    if (filter) {
      Object.assign(this, filter);
    }
  }
}
