<script setup lang="ts">
import { ref, watch } from "vue";
import { BlueColor, QuestionDto } from "@/types/_generated/api";
import QuestionnaireDescriptionToggle from "@/components/shared/Questionnaire/QuestionnaireDescriptionToggle.vue";
import QuestionnaireQuestionColor from "./QuestionnaireQuestionColor.vue";
import QuestionnaireViewerEntityWrapper from "./QuestionnaireViewerEntityWrapper.vue";

const props = defineProps<{
  question: QuestionDto;
  categoryPosition: number;
  areaPosition: number;
  hideQuestionGradingDescriptions: boolean;
}>();

const showQuestionGradingDescription = ref(true);

watch(
  () => props.hideQuestionGradingDescriptions,
  (value) => (showQuestionGradingDescription.value = value),
);
</script>

<template>
  <QuestionnaireViewerEntityWrapper
    :id="`question_${question.questionId}`"
    class="questionnaire__question"
    :title="question.text"
    :position="`${areaPosition}.${categoryPosition}.${question.position}`"
  >
    <div v-if="question.description.length" class="questionnaire__question__description">
      {{ question.description }}
    </div>

    <div class="questionnaire__question__wrapper">
      <QuestionnaireQuestionColor
        :score="question.blueScore"
        :text="question.blueText"
        :color="BlueColor.Blue"
        :show-description="showQuestionGradingDescription"
      />
      <QuestionnaireQuestionColor
        :score="question.greenScore"
        :text="question.greenText"
        :color="BlueColor.Green"
        :show-description="showQuestionGradingDescription"
      />
      <QuestionnaireQuestionColor
        :score="question.yellowScore"
        :text="question.yellowText"
        :color="BlueColor.Yellow"
        :show-description="showQuestionGradingDescription"
      />
      <QuestionnaireQuestionColor
        :score="question.redScore"
        :text="question.redText"
        :color="BlueColor.Red"
        :show-description="showQuestionGradingDescription"
      />
    </div>

    <template #controls>
      <div>
        <QuestionnaireDescriptionToggle
          :show-description="showQuestionGradingDescription"
          @toggle="showQuestionGradingDescription = !showQuestionGradingDescription"
        />
      </div>
    </template>
  </QuestionnaireViewerEntityWrapper>
</template>
<style scoped lang="scss">
.questionnaire {
  &__question {
    margin-left: 8rem;

    &__description {
      padding-top: $spacing-5;
      padding-bottom: $spacing-5;
      color: $primary-7;
      font-size: $text-base;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: $spacing-4;
    }
  }
}
</style>
