<script setup lang="ts">
import Squire from "squire-rte";
import { useTemplateRef } from "vue";
import { useColorPicker } from "../composables/useColorPicker";
import { useEditorPopover } from "../composables/useEditorPopover";
import { useToolbarActions } from "../composables/useToolbarActions";
import { useToolbarConfig } from "../composables/useToolbarConfig";
import { useToolbarState } from "../composables/useToolbarState";
import { Editor } from "../constants";
import BaseEditorButton from "./BaseEditorButton.vue";
import BaseEditorColorPicker from "./BaseEditorColorPicker.vue";
import BaseEditorImageUpload from "./BaseEditorImageUpload.vue";
import BaseEditorPopover from "./BaseEditorPopover.vue";

interface Props {
  editor: Squire | null;
  disabled?: boolean;
  enableImageUpload?: boolean;
}

const { editor, enableImageUpload } = defineProps<Props>();

const { toolbarButtons } = useToolbarConfig(enableImageUpload);
const { isActive } = useToolbarState(editor);
const { executeCommand } = useToolbarActions(editor);
const { isPopoverVisible, togglePopover, getPopoverContent } = useEditorPopover();
const { colorInputId, openColorPicker } = useColorPicker();
const imageUpload = useTemplateRef("imageUpload");

const onEditorCommand = (command: Editor.Command, attribute?: string) => {
  if (command === Editor.Command.TextSize) {
    togglePopover(Editor.Command.TextSize);
  }
  if (command === Editor.Command.Image && !attribute) {
    imageUpload.value?.[0]?.input?.click();
    return;
  }
  if (command === Editor.Command.TextColor && !attribute) {
    openColorPicker();
    return;
  }

  executeCommand(command, attribute);
};
</script>

<template>
  <div class="base-editor-toolbar">
    <template v-for="button in toolbarButtons" :key="button.command">
      <BaseEditorButton
        :button="button"
        :is-active="Boolean(isActive[button.command])"
        :disabled="disabled"
        @click="onEditorCommand"
      >
        <BaseEditorPopover
          v-if="isPopoverVisible(button.command)"
          :content="getPopoverContent(button.command)"
          @action="onEditorCommand(button.command, $event)"
        />

        <BaseEditorColorPicker
          v-if="button.command === Editor.Command.TextColor"
          :id="colorInputId"
          @input="onEditorCommand(Editor.Command.TextColor, $event)"
        />

        <BaseEditorImageUpload
          v-if="button.command === Editor.Command.Image"
          ref="imageUpload"
          @upload="onEditorCommand(Editor.Command.Image, $event)"
        />
      </BaseEditorButton>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.base-editor-toolbar {
  display: flex;
  gap: $spacing-2;
  padding: $spacing-2;
  border: 1px solid $primary-6;
  border-bottom: none;
  border-radius: $rounded-base $rounded-base 0 0;
  background: $primary-1;
}
</style>
