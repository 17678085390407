import { computed, Ref } from "vue";
import { type SiteLeanDto } from "@/types/_generated/api";
import { MapPin } from "@/components/base/BaseMap/types";

// transform SiteDto to MapPin
const transformCoordinates = (sites: SiteLeanDto[]): MapPin[] => {
  return sites.map(({ latitude, longitude, custom1, siteId }) => ({
    latitude,
    longitude,
    pinText: custom1,
    id: siteId,
  }));
};

export const useGetCoordinates = (sites: Ref<SiteLeanDto[]>) => {
  const coordinates = computed(() => {
    const sitesWithCoordinates = sites.value.filter(
      (s) => s.latitude != null || s.longitude != null,
    );

    return transformCoordinates(sitesWithCoordinates);
  });

  return {
    coordinates,
  };
};
