import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { SiteDefinitions } from "@/composables/services/useGetSites";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useSiteDefinitionColumns } from "@/composables/useSiteDefinitionColumns";
import { sortBlueColor, sortSiteResponseStatus, sortSurveyorStatus } from "@/helpers/sort";
import { addZeroPadding } from "@/helpers/zeroPadding";
import { ColumnDefinition } from "@/types/ColumnDefinition";

export const useRecommendationTrackingColumns = (siteDefinitions: Ref<SiteDefinitions | null>) => {
  const { t } = useI18n({ useScope: "global" });

  const { siteDefinitionColumns } =
    useSiteDefinitionColumns<TrackedRecommendation>(siteDefinitions);

  const columns = computed<ColumnDefinition<TrackedRecommendation>[]>(() => [
    ...siteDefinitionColumns.value,

    new ColumnDefinition({
      title: t("userWeb.recommendation.surveyDate"),
      key: "surveyDate",
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendationTracking.surveyType"),
      key: "surveyType",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.category"),
      key: "categoryName",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.blueRating"),
      key: "color",
      sortValueFunc: (item: TrackedRecommendation) => sortBlueColor(item.color),
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.priority"),
      key: "priority",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.recommendationNumber"),
      key: "fullPosition",
      sortValueFunc: (item: TrackedRecommendation) => addZeroPadding(item.fullPosition, 4, "."),
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.type"),
      key: "type",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.header"),
      key: "heading",
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.rcbaCostToComplete"),
      key: "rcbaCostToComplete",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("blueRating.recommendation.costBenefitRatio"),
      key: "costBenefitRatio",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.surveyorStatus"),
      key: "surveyorStatus",
      defaultHidden: true,
      sortValueFunc: (item: TrackedRecommendation) => sortSurveyorStatus(item.surveyorStatus),
    }),
    new ColumnDefinition({
      title: t("userWeb.recommendation.responseStatus"),
      key: "siteResponseStatus",
      sortValueFunc: (item: TrackedRecommendation) =>
        sortSiteResponseStatus(item.siteResponseStatus),
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.siteComment"),
      key: "siteResponseComment",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.costToComplete"),
      key: "siteResponseCostToComplete",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.responsiblePerson"),
      key: "responsiblePerson",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.dueDate"),
      key: "dueDate",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("userWeb.siteRecommendationTracking.riskManagerComment"),
      key: "riskManagerComment",
      defaultHidden: true,
    }),
  ]);

  return { columns };
};
