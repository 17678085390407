<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useValidateForm } from "@/composables/validation/useValidateForm";
import { required } from "@/composables/validation/validators";
import {
  createExemptionService,
  updateExemptionService,
  deleteExemption as deleteExemptionService,
} from "@/services/siteExemptions";
import { SiteExemptionDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  siteId: number;
  exemption: SiteExemptionDto | null;
}>();

const emit = defineEmits<{
  close: [void];
}>();

const exemption = ref<SiteExemptionDto>(
  props.exemption
    ? { ...props.exemption, reviewedDate: props.exemption.addedDate }
    : {
        exemptionId: 0,
        number: "",
        location: "",
        comment: "",
        text: "",
        addedDate: new Date().toISOString().substring(0, 10),
        reviewedDate: undefined,
        addedByFullName: "",
        reviewedByFullName: undefined,
        addedByUserId: 0,
        reviewedByUserId: undefined,
      },
);
const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges(exemption);

const isEditMode = computed(() => !!exemption.value.exemptionId);

const { isValid, errors, updateFormField } = useValidateForm<SiteExemptionDto>(exemption, {
  number: { required },
  text: { required },
  addedDate: { required },
});

const isLoading = ref(false);

const saveExemption = async () => {
  if (!isValid()) {
    return;
  }

  isLoading.value = true;

  if (isEditMode.value) {
    await updateExemptionService({ siteId: props.siteId, ...exemption.value });
  } else {
    await createExemptionService({ siteId: props.siteId, ...exemption.value });
  }

  isLoading.value = false;

  emit("close");
};

const deleteExemption = async () => {
  if (await deleteExemptionService(props.siteId, exemption.value.exemptionId)) {
    emit("close");
  }
};

const closeDrawer = async () => {
  if (await discardUnsavedChanges(exemption)) {
    emit("close");
  }
};

const setReviewDate = (date: string) => {
  const reviewedDate = (exemption.value.reviewedDate = date.length ? date : undefined);
  updateFormField("reviewedDate", reviewedDate);
};

onMounted(() => setCompareBase(exemption.value));
</script>

<template>
  <BaseDrawer
    width="30"
    :title="`${isEditMode ? t('common.actions.edit') : t('common.actions.create')} ${t('sites.exemptions.exemption').toLowerCase()}`"
    @close="closeDrawer"
  >
    <div class="exemption-edit-drawer">
      <div class="exemption-edit-drawer__inputs">
        <BaseTextField
          required
          :error-message="errors.number"
          :label="t('sites.exemptions.number')"
          :value="exemption.number"
          :disabled="isLoading"
          @update:value="updateFormField('number', $event as string)"
        />

        <BaseTextField
          :label="t('sites.exemptions.location')"
          :value="exemption.location"
          :disabled="isLoading"
          @update:value="updateFormField('location', $event as string)"
        />

        <BaseTextArea
          :label="t('sites.exemptions.comment')"
          :value="exemption.comment"
          :disabled="isLoading"
          @update:value="updateFormField('comment', $event as string)"
        />

        <BaseTextArea
          required
          :error-message="errors.text"
          :label="t('sites.exemptions.text')"
          :value="exemption.text"
          :disabled="isLoading"
          @update:value="updateFormField('text', $event as string)"
        />

        <BaseTextField
          v-if="!isEditMode"
          required
          type="date"
          :error-message="errors.addedDate"
          :label="t('sites.exemptions.addedDate')"
          :value="exemption.addedDate"
          :disabled="isLoading"
          @update:value="updateFormField('addedDate', $event as string)"
        />

        <BaseTextField
          v-if="isEditMode"
          type="date"
          :min="new Date().toISOString().substring(0, 10)"
          :label="t('sites.exemptions.reviewedDate')"
          :value="exemption.reviewedDate"
          :error-message="errors.reviewedDate"
          :disabled="isLoading"
          @update:value="setReviewDate($event as string)"
        />
      </div>

      <BaseDataDisplayList v-if="isEditMode">
        <BaseDataDisplayListItem :label="t('sites.exemptions.addedBy')">
          <p>{{ exemption.addedByFullName }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('sites.exemptions.addedDate')">
          <p>{{ exemption.addedDate }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem
          v-if="exemption.reviewedByFullName"
          :label="t('sites.exemptions.reviewedBy')"
        >
          <p>{{ exemption.reviewedByFullName }}</p>
        </BaseDataDisplayListItem>
      </BaseDataDisplayList>
    </div>

    <template #footer-left>
      <BaseButton :disabled="isLoading" @click="saveExemption">
        {{ isEditMode ? t("common.actions.save") : t("common.actions.create") }}
      </BaseButton>

      <BaseButton variant="outlined" :disabled="isLoading" @click="closeDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton
        v-if="isEditMode"
        color="error"
        variant="outlined"
        :disabled="isLoading"
        @click="deleteExemption"
      >
        {{ t("common.actions.delete") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style lang="scss" scoped>
.exemption-edit-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  padding: $spacing-6;

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
