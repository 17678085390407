<script setup lang="ts" generic="T">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWCheckbox from "@/components/base/UserWeb/Inputs/Checkbox/UWCheckbox.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";
import type { MultiselectOption, MultiselectSelectedOptions } from "./types";
import { useMultiselectSearch } from "./composables/useMultiselectSearch";
import { useMultiselectSelect } from "./composables/useMultiselectSelect";
import { useMultiselectSort } from "./composables/useMultiselectSort";

interface Props {
  noSpacing?: boolean;
  disableSorting?: boolean;
  label?: string;
  options: MultiselectOption<T>[];
  selectedOptions: MultiselectSelectedOptions<T>;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:selected": [options: T[]];
}>();

const { t } = useI18n({ useScope: "global" });

const { sortedOptions } = useMultiselectSort(toRef(props, "options"), props.disableSorting);

const { searchTerm, showSearchInput, filteredOptions, handleSearchInput } = useMultiselectSearch(
  props.options.length,
  sortedOptions,
);

const { allOptionsSelected, allOptionsDisabled, handleSelectAll, handleSelectOption } =
  useMultiselectSelect(emit, toRef(props, "options"), toRef(props, "selectedOptions"));
</script>

<template>
  <div class="mod-multiselect">
    <BaseLabel v-if="label">{{ label }}</BaseLabel>

    <div :class="{ 'mod-multiselect__content': !noSpacing }">
      <UWTextField
        v-if="showSearchInput"
        class="mod-multiselect__search"
        type="search"
        :placeholder="`${t('common.actions.search')}..`"
        :value="searchTerm"
        @input="handleSearchInput"
      />

      <ul>
        <li v-if="!searchTerm">
          <UWCheckbox
            class="mod-multiselect__checkbox"
            :disabled="allOptionsDisabled"
            :checked="allOptionsSelected"
            @mousedown.prevent
            @change="handleSelectAll"
          >
            {{ t("common.actions.selectAll") }}
          </UWCheckbox>
        </li>

        <li v-for="option in filteredOptions" :key="`${option.value}`">
          <UWCheckbox
            class="mod-multiselect__checkbox"
            :disabled="option.disabled"
            :checked="selectedOptions.has(option.value)"
            @mousedown.prevent
            @change="handleSelectOption(option.value)"
          >
            <span v-truncation-title>{{ option.label }}</span>
          </UWCheckbox>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mod-multiselect {
  display: flex;
  flex-direction: column;

  &__content {
    margin-top: $spacing-2;
    padding: 0 $spacing-2;
  }

  &__search {
    margin-bottom: $spacing-2;
  }

  &__checkbox {
    line-height: $leading-relaxed;
  }

  ul {
    max-height: 14rem;
    overflow: auto;

    li {
      &:hover {
        background: $primary-3;
      }
    }
  }
}
</style>
