<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { RecResponseRespondStatus } from "@/types/_generated/api";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseProgressBar from "@/components/base/BaseProgressBar.vue";

defineProps<{
  respondedCount: number;
  respondedMaxCount: number;
  responseStatus: RecResponseRespondStatus;
}>();

defineEmits<{
  submit: [];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <template
    v-if="
      ![RecResponseRespondStatus.Submitted, RecResponseRespondStatus.NeedsSubmit].includes(
        responseStatus,
      )
    "
  >
    <span v-if="respondedCount < respondedMaxCount" class="external-response-progress__title">
      {{
        t("recommendationResponse.progress", {
          count: respondedCount,
          max: respondedMaxCount,
        })
      }}
    </span>

    <BaseProgressBar
      v-if="respondedCount < respondedMaxCount"
      :primary-progress="respondedCount"
      :max="respondedMaxCount"
    />

    <transition name="bounce" appear>
      <BaseButton
        v-if="
          respondedCount === respondedMaxCount ||
          responseStatus === RecResponseRespondStatus.NeedsSubmit
        "
        class="external-response-progress__submit"
        @click="$emit('submit')"
      >
        {{ t("recommendationResponse.submitRecommendationResponse") }}
      </BaseButton>
    </transition>
  </template>

  <div v-else class="external-response-progress__re-submit">
    <BaseAlert v-if="responseStatus === RecResponseRespondStatus.Submitted" severity="info">
      <div class="external-response-progress__description">
        <p class="external-response-progress__description__status">
          {{ t("recommendationResponse.responseStatus.submitted") }}
        </p>
        <p>
          {{ t("recommendationResponse.additionalChangesCanBeMade") }}
        </p>
      </div>
    </BaseAlert>

    <BaseButton
      class="external-response-progress__submit"
      variant="outlined"
      @click="$emit('submit')"
    >
      {{
        responseStatus === RecResponseRespondStatus.Submitted
          ? t("recommendationResponse.reSubmitRecommendationResponse")
          : t("recommendationResponse.submitRecommendationResponse")
      }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.external-response-progress {
  &__title {
    font-size: $text-sm;
  }
  &__description {
    &__status {
      font-weight: $font-semibold;
    }
  }
  &__submit {
    width: 100%;
    transition: transform $duration-300 cubic-bezier(0.47, 1.64, 0.41, 0.8);
  }

  &__re-submit {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}

.bounce-enter-to {
  transform: scale(1.2);
}
.bounce-leave-active {
  display: none;
}
</style>
