<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { toBase64 } from "@/helpers/toBase64";
import { type SiteDto, SiteFileType } from "@/types/_generated/api";
import BaseFileUpload, {
  type FileUpload,
} from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";
import BaseImage from "@/components/base/BaseImage.vue";

const props = defineProps<{
  site: SiteDto | null;
  moduleId: number;
  siteId: number;
}>();

const emit = defineEmits<{
  "upload:image": [fileId: string];
}>();

const { t } = useI18n({ useScope: "global" });

const reportImageFileId = ref<string>();

const uploadUrl = `/sites/${props.siteId}/files`;

const getBody = async (files: FileUpload[]) => {
  const images = [];
  for (const file of files) {
    images.push({
      fileName: file.file.name,
      file: await toBase64(file.file),
      rotation: file.rotation,
    });
  }

  return {
    moduleId: props.moduleId,
    siteId: props.siteId,
    file: images[0],
    type: SiteFileType.ReportImage,
  };
};

const uploadFile = (fileId?: string) => {
  if (!fileId) return;

  reportImageFileId.value = fileId;
  emit("upload:image", fileId);
};

onMounted(() => {
  if (props.site?.reportImageFileId) {
    reportImageFileId.value = props.site.reportImageFileId;
  }
});
</script>

<template>
  <div class="site-edit-report-image">
    <BaseFileUpload
      vertical
      :max-files="1"
      :file-type="FileFormat.Image"
      :body="getBody"
      :url="uploadUrl"
      @files-uploaded="uploadFile"
    />

    <BaseImage
      v-if="reportImageFileId"
      :src="`/v1/sites/${siteId}/files/${reportImageFileId}`"
      :alt="t('sites.siteReportImage')"
      max-width="25rem"
    />
  </div>
</template>

<style scoped lang="scss">
.site-edit-report-image {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: $spacing-6;
}
</style>
