<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { BlueColor, BlueRatingQuestionDto, SurveyorStatus } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";

const props = defineProps<{
  readOnly: boolean;
  question: BlueRatingQuestionDto;
}>();

defineEmits(["click"]);

const { t } = useI18n({ useScope: "global" });

const allRecommendationsAreHandled = computed(() => {
  const handledStatuses = [SurveyorStatus.Completed, SurveyorStatus.Withdrawn];

  return props.question.recommendations.every((r) => handledStatuses.includes(r.surveyorStatus));
});

const disabled = computed(() => props.readOnly || !allRecommendationsAreHandled.value);

const title = computed(() => {
  if (props.readOnly) {
    return "";
  }

  if (disabled.value) {
    return t("blueRating.naDisabledWhenUnhandledRecommendations");
  }

  return t("blueRating.setQuestionToN/A");
});
</script>

<template>
  <BaseButton
    :class="{
      [`blue-rating-question__na-button`]: question.value === BlueColor.Gray,
    }"
    :disabled="disabled"
    variant="outlined"
    :title="title"
    @click="$emit('click')"
  >
    N/A
  </BaseButton>
</template>

<style scoped lang="scss">
.blue-rating-question__na-button {
  background: $rating-notset;
}
</style>
