<script setup lang="ts">
import { ref } from "vue";
import type { QuestionnaireDto } from "@/types/_generated/api";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import QuestionnaireArea from "./QuestionnaireArea.vue";
import QuestionnaireCategory from "./QuestionnaireCategory.vue";
import QuestionnaireQuestion from "./QuestionnaireQuestion.vue";
import QuestionnaireViewerHeader from "./QuestionnaireViewerHeader.vue";

defineProps<{
  questionnaire?: QuestionnaireDto;
}>();

// CategoryIds for categories where question grading descriptions should be hidden
const hideGradingDescriptions = ref<number[]>([]);
</script>

<template>
  <main class="questionnaire-viewer">
    <div v-if="!!questionnaire" class="questionnaire-viewer__main">
      <QuestionnaireViewerHeader v-if="questionnaire" :questionnaire="questionnaire" />

      <div
        v-for="area in questionnaire?.areas"
        :key="area.areaId"
        class="questionnaire-viewer__main__areas"
      >
        <QuestionnaireArea :area="area" />
        <template v-for="category in area.categories" :key="category.categoryId">
          <QuestionnaireCategory
            :category="category"
            :area-position="area.position"
            :hide-question-grading-descriptions="
              hideGradingDescriptions.includes(category.categoryId)
            "
            @toggle-gradient-description="
              () => {
                if (hideGradingDescriptions.includes(category.categoryId)) {
                  hideGradingDescriptions = hideGradingDescriptions.filter(
                    (id) => id !== category.categoryId,
                  );
                } else {
                  hideGradingDescriptions = [...hideGradingDescriptions, category.categoryId];
                }
              }
            "
          />

          <div
            v-for="question in category.questions"
            :key="question.questionId"
            class="questionnaire-viewer__main__questions"
          >
            <QuestionnaireQuestion
              :question="question"
              :area-position="area.position"
              :category-position="category.position"
              :hide-question-grading-descriptions="
                !hideGradingDescriptions.includes(category.categoryId)
              "
            />
          </div>
        </template>
      </div>
    </div>

    <div v-if="!questionnaire" class="questionnaire-viewer__skeleton">
      <BaseSkeleton :set="4" height="100px" mb="1rem" width="100%" />
    </div>
  </main>
</template>

<style scoped lang="scss">
.questionnaire-viewer {
  &__main {
    padding: $spacing-5;
    margin: auto;
    max-width: 1500px;
  }

  &__skeleton {
    padding: $spacing-5;
  }
}

.questionnaire-viewer__isLoading {
  margin-top: $spacing-5;
  padding: $spacing-10;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: $spacing-5;
  align-items: flex-start;
}
</style>
