import { ComputedRef, computed, ref } from "vue";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";

export const useRecommendationNavigation = (
  recommendations: ComputedRef<BlueRatingRecommendationDto[]>,
  contentScrollSelector?: string,
) => {
  const selectedRecommendation = ref<BlueRatingRecommendationDto | null>(null);

  const scrollToTop = () =>
    contentScrollSelector && document.querySelector(contentScrollSelector)?.scrollTo(0, 0);

  const selectRecommendationById = (recommendationId?: number) => {
    if (!recommendationId) {
      selectedRecommendation.value = null;
      return;
    }

    selectedRecommendation.value =
      recommendations.value.find((r) => r.recommendationId === recommendationId) ?? null;

    scrollToTop();
  };

  const indexOfSelectedRecommendation = computed(() =>
    recommendations.value.findIndex(
      (r) => r.recommendationId === selectedRecommendation.value?.recommendationId,
    ),
  );

  const selectPreviousRecommendation = () => {
    if (indexOfSelectedRecommendation.value > 0) {
      selectedRecommendation.value =
        recommendations.value[indexOfSelectedRecommendation.value - 1] ?? null;
    }
    scrollToTop();
  };

  const selectNextRecommendation = () => {
    if (indexOfSelectedRecommendation.value < recommendations.value.length - 1) {
      selectedRecommendation.value =
        recommendations.value[indexOfSelectedRecommendation.value + 1] ?? null;
    }
    scrollToTop();
  };

  return {
    selectedRecommendation: computed(() => selectedRecommendation.value),
    selectRecommendationById,
    indexOfSelectedRecommendation,
    selectPreviousRecommendation,
    selectNextRecommendation,
  };
};
