import { RouteRecordRaw } from "vue-router";
import { AppRouteName } from "@/router/RouteName";
import ViewAuth from "@/views/Auth/ViewAuth.vue";
import ViewResetPassword from "@/views/Auth/ViewResetPassword.vue";
import ViewSsoResponse from "@/views/Auth/ViewSsoResponse.vue";
import ViewTermsOfService from "@/views/Auth/ViewTermsOfService.vue";
import ViewLicenses from "@/views/ViewLicenses.vue";

export const appRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: AppRouteName.Auth,
    component: ViewAuth,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/sso",
    name: AppRouteName.SsoResponse,
    component: ViewSsoResponse,
    props: ({ query: { username, token } }) => ({
      username,
      token,
    }),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/terms-of-service",
    name: AppRouteName.TermsOfService,
    component: ViewTermsOfService,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/reset-password",
    name: AppRouteName.ResetPassword,
    component: ViewResetPassword,
    props: (route) => ({ token: route.query.token, email: route.query.email }),
    meta: {
      isPublic: true,
    },
  },
  {
    name: AppRouteName.Licenses,
    path: "/licenses",
    component: ViewLicenses,
  },
];
