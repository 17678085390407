import { updateQuestionnaireDraft } from "@/services/questionnaire-draft";
import {
  CategoryDto,
  DraftAction,
  QuestionDto,
  QuestionnaireDraftActionDto,
  QuestionnaireDraftActionType,
  QuestionnaireDto,
} from "@/types/_generated/api";

/* eslint-disable func-style */
export type ValueType = string | number | boolean;

type QuestionnaireProperties = "Name";

type AreaProperties =
  | "Language"
  | "WillisCountryOrUnit"
  | "Position"
  | "Industry"
  | "Name"
  | "RiskType"
  | "Tags";
type CategoryProperties =
  | "AreaId"
  | "BlueLimit"
  | "GreenLimit"
  | "Position"
  | "YellowLimit"
  | "Name"
  | "GradeBy";
type QuestionProperties =
  | "CategoryId"
  | "BlueScore"
  | "GreenScore"
  | "GreenOverridesCategoryColor"
  | "Position"
  | "RedScore"
  | "RedOverridesCategoryColor"
  | "YellowScore"
  | "YellowOverridesCategoryColor"
  | "BlueText"
  | "Description"
  | "GreenText"
  | "RedText"
  | "Text"
  | "YellowText";

export type DraftProperties =
  | QuestionnaireProperties
  | AreaProperties
  | CategoryProperties
  | QuestionProperties;

const toValue = (value: ValueType): string => {
  switch (typeof value) {
    case "string":
      return value ?? "";
    case "undefined":
      return "";
    case "number":
      return value.toString();
    case "boolean":
      return value ? "True" : "False";
    default:
      throw new Error("Unsupported value type");
  }
};

export const getQuestionsFromArea = (
  areaId: number,
  questionnaire: QuestionnaireDto,
): QuestionDto[] =>
  questionnaire.areas
    .find((a) => a.areaId === areaId)
    ?.categories.map((c) => c.questions)
    .flat() ?? [];

export const getCategoriesFromArea = (
  areaId: number,
  questionnaire: QuestionnaireDto,
): CategoryDto[] => questionnaire.areas.find((a) => a.areaId === areaId)?.categories ?? [];

export const getQuestionsFromCategory = (
  categoryId: number,
  questionnaire: QuestionnaireDto,
): QuestionDto[] =>
  questionnaire.areas
    .map((a) => a.categories)
    .flat()
    .find((c) => c.categoryId === categoryId)?.questions ?? [];

export const useQuestionnaireDraftClient = (realQuestionnaireId: number) => {
  const add = async (type: QuestionnaireDraftActionType, parentId: number): Promise<boolean> =>
    updateQuestionnaireDraft(realQuestionnaireId, [
      {
        action: DraftAction.Create,
        type,
        targetActionId: parentId,
      },
    ]);

  async function update(
    type: QuestionnaireDraftActionType.Questionnaire,
    id: number,
    property: QuestionnaireProperties,
    value: ValueType,
  ): Promise<boolean>;
  async function update(
    type: QuestionnaireDraftActionType.Area,
    id: number,
    property: AreaProperties,
    value: ValueType,
  ): Promise<boolean>;
  async function update(
    type: QuestionnaireDraftActionType.Category,
    id: number,
    property: CategoryProperties,
    value: ValueType,
  ): Promise<boolean>;
  async function update(
    type: QuestionnaireDraftActionType.Question,
    id: number,
    property: QuestionProperties,
    value: ValueType,
  ): Promise<boolean>;
  async function update(
    type: QuestionnaireDraftActionType,
    id: number,
    property: DraftProperties,
    value: ValueType,
  ): Promise<boolean>;

  async function update(
    type: QuestionnaireDraftActionType,
    id: number,
    property: DraftProperties,
    value: ValueType,
  ): Promise<boolean> {
    return updateQuestionnaireDraft(realQuestionnaireId, [
      {
        action: DraftAction.Update,
        type,
        targetActionId: id,
        property,
        value: toValue(value),
      },
    ]);
  }

  const remove = async (
    type: Exclude<QuestionnaireDraftActionType, "Questionnaire">,
    id: number,
    questionnaire: QuestionnaireDto | null,
  ): Promise<boolean> => {
    const actions: QuestionnaireDraftActionDto[] = [];
    if (type !== "Question" && !questionnaire) {
      throw new Error("Questionnaire param is required unless type is Question");
    }

    if (type === "Area") {
      actions.push(
        ...getQuestionsFromArea(id, questionnaire!).map(
          (q) =>
            ({
              action: "Delete",
              type: "Question",
              targetActionId: q.questionId,
            }) as QuestionnaireDraftActionDto,
        ),
      );
      actions.push(
        ...getCategoriesFromArea(id, questionnaire!).map(
          (c) =>
            ({
              action: "Delete",
              type: "Category",
              targetActionId: c.categoryId,
            }) as QuestionnaireDraftActionDto,
        ),
      );
    }
    if (type === "Category") {
      actions.push(
        ...getQuestionsFromCategory(id, questionnaire!).map(
          (q) =>
            ({
              action: "Delete",
              type: "Question",
              targetActionId: q.questionId,
            }) as QuestionnaireDraftActionDto,
        ),
      );
    }

    actions.push({
      action: DraftAction.Delete,
      type,
      targetActionId: id,
    });

    return updateQuestionnaireDraft(realQuestionnaireId, actions);
  };
  return {
    updatePropertyValue: update,
    removeEntity: remove,
    questionnaire: {
      update: async (
        questionnaireId: number,
        property: QuestionnaireProperties,
        value: ValueType,
      ) =>
        await update(QuestionnaireDraftActionType.Questionnaire, questionnaireId, property, value),
    },
    area: {
      add: async (questionnaireId: number) =>
        await add(QuestionnaireDraftActionType.Area, questionnaireId),
      remove: async (areaId: number, questionnaire: QuestionnaireDto) =>
        await remove(QuestionnaireDraftActionType.Area, areaId, questionnaire),
      update: async (areaId: number, property: AreaProperties, value: ValueType) =>
        await update(QuestionnaireDraftActionType.Area, areaId, property, value),
      moveTo: async (areaId: number, position: number) =>
        await update(QuestionnaireDraftActionType.Area, areaId, "Position", position),
    },
    category: {
      add: async (areaId: number) => await add(QuestionnaireDraftActionType.Category, areaId),
      remove: async (categoryId: number, questionnaire: QuestionnaireDto) =>
        await remove(QuestionnaireDraftActionType.Category, categoryId, questionnaire),
      update: async (categoryId: number, property: CategoryProperties, value: ValueType) =>
        await update(QuestionnaireDraftActionType.Category, categoryId, property, value),
      moveTo: async (categoryId: number, position: number) =>
        await update(QuestionnaireDraftActionType.Category, categoryId, "Position", position),
      moveToArea: async (categoryId: number, areaId: number) =>
        await update(QuestionnaireDraftActionType.Category, categoryId, "AreaId", areaId),
    },
    question: {
      add: async (categoryId: number) =>
        await add(QuestionnaireDraftActionType.Question, categoryId),
      remove: async (questionId: number) =>
        await remove(QuestionnaireDraftActionType.Question, questionId, null),
      update: async (questionId: number, property: QuestionProperties, value: ValueType) =>
        await update(QuestionnaireDraftActionType.Question, questionId, property, value),
      moveTo: async (questionId: number, position: number) =>
        await update(QuestionnaireDraftActionType.Question, questionId, "Position", position),
      moveToCategory: async (questionId: number, categoryId: number) =>
        await update(QuestionnaireDraftActionType.Question, questionId, "CategoryId", categoryId),
    },
  };
};
