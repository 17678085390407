import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { updateLibraryFileService, deleteLibraryFileService } from "@/services/library";
import { UpdateLibraryFile } from "@/types/_generated/api";

export const useLibraryFileActions = (moduleId: number) => {
  const { t } = useI18n({ useScope: "global" });
  const dialog = useDialog();

  const isProcessingFile = ref(false);

  const updateFile = async (form: UpdateLibraryFile) => {
    try {
      isProcessingFile.value = true;
      await updateLibraryFileService(form);
    } finally {
      isProcessingFile.value = false;
    }
  };

  const deleteFile = async (fileId: string) => {
    const isConfirmed = await dialog.openDeletePrompt(t("library.fileUpload.file"));
    if (!isConfirmed) return false;

    try {
      isProcessingFile.value = true;
      await deleteLibraryFileService(moduleId, fileId);
      return true;
    } finally {
      isProcessingFile.value = false;
    }
  };

  return {
    isProcessingFile,
    updateFile,
    deleteFile,
  };
};
