import { ref } from "vue";
import { getModules as getModulesService } from "@/services/modules";
import { GetModules, ModuleLeanDto } from "@/types/_generated/api";

export const useGetModules = () => {
  const isLoadingModules = ref(false);
  const modules = ref<ModuleLeanDto[]>([]);

  const getModules = async (request?: GetModules) => {
    isLoadingModules.value = true;

    modules.value = (await getModulesService(request)) ?? [];

    isLoadingModules.value = false;
  };

  return { getModules, isLoadingModules, modules };
};
