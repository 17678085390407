import { createUserService } from "@/services/users";
import { LoginType, CreateUser } from "@/types/_generated/api";
import type { UserForm } from "../types";

export const useCreateUser = (emit: (event: "close") => void) => {
  const createUser = async (
    userForm: UserForm,
    sendPasswordResetEmail: boolean,
    password1: string,
  ) => {
    const createdUser = { ...userForm } as CreateUser;

    if (userForm.loginType === LoginType.Blue && !sendPasswordResetEmail) {
      createdUser.password = password1;
    }

    const userId = await createUserService(createdUser);
    if (userId) {
      emit("close");
    }
  };

  return {
    createUser,
  };
};
