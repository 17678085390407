<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import BaseButton from "../base/BaseButton.vue";
import BaseModal from "../base/BaseModal.vue";

const { t } = useI18n({ useScope: "global" });

const {
  unsavedChangesDialog: { showDialog, ok, cancel },
} = useUnsavedChanges();
</script>

<template>
  <BaseModal v-if="showDialog" @cancel="cancel">
    <p>
      {{ t("common.unsavedChangesWarning") }}
    </p>
    <template #controls>
      <BaseButton @click="ok">
        {{ t("common.actions.ok") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="cancel">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
