import { defineStore } from "pinia";
import { ref } from "vue";
import { BlueRating } from "@/types/Survey";

export const useBlueRatingStore = defineStore("blueRatingStore", () => {
  const currentSurvey = ref<BlueRating>();

  const $reset = () => {
    currentSurvey.value = undefined;
  };

  return {
    survey: currentSurvey,
    $reset,
  };
});
