<script setup lang="ts">
import { useI18n } from "vue-i18n";
import router from "@/router";
import { AppRouteName } from "@/router/RouteName";
import BaseButton from "@/components/base/BaseButton.vue";

const { t } = useI18n({ useScope: "global" });

const hasRoleAuth = () => {
  router.push({ name: AppRouteName.Auth });
};
</script>

<template>
  <div class="pagenotfound">
    <main class="pagenotfound__main">
      <h1 class="pagenotfound__title">
        {{ t("errors.pageNotFound") }}
      </h1>
      <p class="pagenotfound__subtitle">
        {{ t("errors.404") }}
      </p>

      <div class="pagenotfound__button-wrapper">
        <BaseButton @click="hasRoleAuth">
          {{ t("pageNotFound.clickHere") }}
        </BaseButton>

        <p class="pagenotfound__button-wrapper-text">{{ t("errors.goBack") }}.</p>
      </div>
    </main>
  </div>
</template>

<style scoped lang="scss">
.pagenotfound {
  .pagenotfound__sg-logo {
    height: 1.5rem;
  }

  .pagenotfound__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pagenotfound__title {
      font-weight: $font-bold;
      font-size: 2em;
      margin: 0.67em 0;
    }

    .pagenotfound__subtitle {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-10;
    }

    .pagenotfound__button-wrapper {
      display: flex;
      align-items: center;

      .pagenotfound__button-wrapper-text {
        margin-left: $spacing-2;
      }
    }
  }
}
</style>
