<script setup lang="ts">
import { EditRecImageDto, RotateDegrees } from "@/types/_generated/api";
import RecImageListItem from "./RecImageListItem.vue";

export type EditRecImage = EditRecImageDto & { fileSize: number };

interface Props {
  baseUrl: string;
  images: EditRecImage[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  update: [images: EditRecImage[]];
  "update:caption": [{ index: number; caption: string }];
  "update:rotation": [{ index: number; rotation: RotateDegrees }];
}>();

const onUpdatePosition = ({ index, direction }: { index: number; direction: "up" | "down" }) => {
  const images = [...props.images];
  const image = images[index];
  images.splice(index, 1);

  if (direction === "up") {
    images.splice(index - 1, 0, image);
  } else {
    images.splice(index + 1, 0, image);
  }

  emit("update", images);
};

const onDelete = (index: number) => {
  const images = [...props.images];
  images.splice(index, 1);
  emit("update", images);
};
</script>

<template>
  <div class="rec-image-list">
    <RecImageListItem
      v-for="(image, index) in images"
      :key="image.fileName"
      :base-url="baseUrl"
      :image="image"
      :index="index"
      :is-last="index === images.length - 1"
      @update:caption="emit('update:caption', $event)"
      @update:position="onUpdatePosition"
      @update:rotation="emit('update:rotation', $event)"
      @delete="onDelete"
    />
  </div>
</template>

<style scoped lang="scss">
.rec-image-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
}
</style>
