import { ref, readonly } from "vue";
import { RecReportSettingsScope } from "@/types/_generated/api";
import type { SelectedSection } from "../types";

export const useSettingsSelection = () => {
  const selectedScope = ref<RecReportSettingsScope>(RecReportSettingsScope.Internal);
  const selectedSection = ref<SelectedSection | null>(null);

  const setSelectedScope = (scope: RecReportSettingsScope) => {
    selectedScope.value = scope;
    selectedSection.value = null; // Close any (possibly) open drawer extension
  };

  const setSelectedSection = (section: SelectedSection | null) => {
    const isAlreadySelected = selectedSection.value?.key === section?.key;

    // Close the drawer extension
    if (section === null || isAlreadySelected) {
      selectedSection.value = null;
      return;
    }

    selectedSection.value = section;
  };

  const closeSelectedSection = () => setSelectedSection(null);

  return {
    selectedScope: readonly(selectedScope),
    selectedSection: readonly(selectedSection),
    setSelectedScope,
    setSelectedSection,
    closeSelectedSection,
  };
};
