import { readonly, Ref, ref } from "vue";
import type { ValidationErrors, ValidationRule, ValidationRules } from "./types";

export const useValidateForm = <T>(form: Ref<T>, rules: ValidationRules<T>) => {
  const errors = ref({} as ValidationErrors<T>);

  const updateFormField = <K extends keyof T>(field: K, value: T[K]) => {
    form.value[field] = value;
    errors.value[field] = "";
  };

  const validateField = <K extends keyof T>(field: K, value: T[K]): string => {
    const fieldRules = rules[field];

    if (!fieldRules || fieldRules.validateIf?.() === false) {
      return "";
    }

    // This function will cause a type error if a new rule is added and not handled in the switch statement
    // This makes sure that a type error will be present when:
    // - A rule is used in a form but not included in ValidationRule
    // - A new rule is added to ValidationRule but not handled in the switch statement
    const validateRule = (rule: keyof ValidationRule, value: T[K]): string => {
      switch (rule) {
        case "validateIf":
          return "";
        case "required":
          return fieldRules.required!(value as string | undefined) || "";
        case "minLength":
          return fieldRules.minLength!(value as string | undefined) || "";
        case "maxLength":
          return fieldRules.maxLength!(value as string | undefined) || "";
        case "between":
          return fieldRules.between!(value as number | undefined) || "";
        case "mobilePhoneNumber":
          return fieldRules.mobilePhoneNumber!(value as string) || "";
        case "custom":
          return fieldRules.custom!(value as string | undefined) || "";
        case "email":
          return fieldRules.email!(value as string | undefined) || "";
        default:
          // Causes type error when a new rule is added and not handled here
          return ensureExhaustive(rule);
      }
    };

    for (const rule in fieldRules) {
      const error = validateRule(rule as keyof ValidationRule, value);
      if (error) return error;
    }

    return "";
  };

  const ensureExhaustive = (x: never): never => {
    throw new Error(`Unexpected value: ${x}`);
  };

  const isValid = (): boolean => {
    resetValidation();

    let isValid = true;

    for (const field in rules) {
      const value = form.value[field];
      errors.value[field] = validateField(field, value);

      if (errors.value[field]) {
        isValid = false;
      }
    }

    return isValid;
  };

  const resetValidation = () => {
    for (const field in rules) {
      errors.value[field] = "";
    }
  };

  return { isValid, errors: readonly(errors), resetValidation, updateFormField };
};
