import { ref, readonly } from "vue";
import { searchStandardRecommendationsService } from "@/services/standardRecommendations";
import {
  StandardRecommendationFilter,
  StandardRecommendationLeanDto,
} from "@/types/_generated/api";

export const useSearchStandardRecommendations = () => {
  const isLoadingStandardRecommendations = ref(false);
  const hasError = ref(false);
  const standardRecommendations = ref<StandardRecommendationLeanDto[]>([]);

  const searchStandardRecommendations = async (filter?: StandardRecommendationFilter) => {
    isLoadingStandardRecommendations.value = true;
    hasError.value = false;

    try {
      standardRecommendations.value = (await searchStandardRecommendationsService(filter)) ?? [];
    } catch {
      hasError.value = true;
    } finally {
      isLoadingStandardRecommendations.value = false;
    }
  };

  return {
    standardRecommendations,
    hasError: readonly(hasError),
    isLoadingStandardRecommendations: readonly(isLoadingStandardRecommendations),
    searchStandardRecommendations,
  };
};
