<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWDatePicker from "@/components/base/UserWeb/Inputs/DatePicker/UWDatePicker.vue";

defineProps<{
  startDate: string;
  endDate: string;
}>();

defineEmits<{
  "update:start-date": [string];
  "update:end-date": [string];
}>();

const { t } = useI18n({ useScope: "global" });

const today = computed(() => {
  const date = new Date();
  return date.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
});
</script>

<template>
  <div class="mod-filter-date-range">
    <div>
      <BaseLabel has-spacing>{{ t("userWeb.filter.dateStart") }}</BaseLabel>
      <UWDatePicker
        type="date"
        :value="startDate"
        :max="endDate || today"
        @input="$emit('update:start-date', $event)"
      />
    </div>

    <div>
      <BaseLabel has-spacing>{{ t("userWeb.filter.dateEnd") }}</BaseLabel>
      <UWDatePicker
        type="date"
        :min="startDate"
        :value="endDate"
        :max="today"
        @input="$emit('update:end-date', $event)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mod-filter-date-range {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-4;
}
</style>
