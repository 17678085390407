<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { getIconForFileType } from "@/helpers/getIconForFileType";
import { getLanguage } from "@/helpers/getLanguage";
import { Language } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";

defineProps<{
  file: { fileId: string; fileName: string; language?: Language; tags?: string[] };
  fileUrl: string;
  edit?: boolean;
}>();

defineEmits<{
  delete: [fileId: string];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="base-file">
    <BaseIcon :icon="getIconForFileType(file.fileName)" size="medium" />

    <div class="base-file__content">
      <a
        v-truncation-title
        class="base-file__content__link"
        :href="fileUrl"
        target="_blank"
        rel="noopener noreferrer"
        :title="file.fileName"
        :download="file.fileName"
      >
        {{ file.fileName }}
      </a>

      <small v-if="file.language" class="base-file__content__language">
        {{ t("survey.preSurveyEmail.language", { language: getLanguage(file.language) }) }}
      </small>

      <div v-if="file.tags && file.tags.length > 0" class="base-file__content__tags">
        <BasePill v-for="tag in file.tags" :key="tag" :label="tag" />
      </div>
    </div>

    <BaseButton
      v-if="edit"
      class="base-file__delete-btn"
      :title="t('common.actions.delete')"
      variant="text"
      color="error"
      @click="$emit('delete', file.fileId)"
    >
      <BaseIcon icon="trash" size="small" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.base-file {
  display: flex;
  gap: $spacing-3;
  align-items: center;
  background-color: $primary-2;
  border-radius: $rounded-base;
  border: 1px solid $primary-4;
  padding: $spacing-3;
  font-size: $text-sm;
  line-height: $leading-none;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
    overflow: hidden;

    &__link {
      &:hover {
        text-decoration: underline;
      }
    }

    &__language {
      color: $primary-7;
    }

    &__tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: $spacing-1;
    }
  }

  &__delete-btn {
    margin-left: auto;
  }
}
</style>
