<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { UpdateFormField, ValidationErrors } from "@/composables/validation/types";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { UserForm } from "./types";

defineProps<{
  errorMessage: ValidationErrors<UserForm>;
  userForm: UserForm;
}>();

defineEmits<{
  "update:field": UpdateFormField<UserForm>;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="user-edit-company">
    <BaseDataDisplayListTitle>{{ t("users.company") }}</BaseDataDisplayListTitle>

    <div class="user-edit-company__fields">
      <BaseTextField
        :label="t('common.name')"
        :error-message="errorMessage.company"
        :value="userForm.company"
        @update:value="$emit('update:field', 'company', String($event))"
      />
      <BaseTextField
        :label="t('users.professionalTitle')"
        :error-message="errorMessage.title"
        :value="userForm.title"
        @update:value="$emit('update:field', 'title', String($event))"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-edit-company {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-4;
  }
}
</style>
