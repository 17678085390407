<script setup lang="ts">
import { computed } from "vue";
import { HorizontalSizes, VerticalSizes } from "@/types/CSSUnits";

const props = withDefaults(
  defineProps<{
    type?: "box" | "circle";
    set?: number;
    height?: `${number}${VerticalSizes}`;
    width?: `${number}${HorizontalSizes}`;
    mb?: `${number}${VerticalSizes}` | null;
    mr?: `${number}${HorizontalSizes}` | null;
  }>(),
  {
    type: "box",
    set: 1,
    height: "150px",
    width: "150px",
    mb: null,
    mr: null,
  },
);

const classType = computed(() => ({
  "base-skeleton__type": true,
  [`base-skeleton__type--${props.type}`]: true,
}));

const style = computed(() => ({
  width: props.width as string,
  height: props.height as string,
  marginBottom: props.mb as string,
  marginRight: props.mr as string,
}));
</script>

<template>
  <div class="base-skeletons">
    <div v-for="(_, index) in set" :key="index" class="base-skeleton" :style="style">
      <div class="base-skeleton__animation" :style="{ animationDelay: `${index * 50}ms` }" />
      <div :class="classType" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-skeletons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .base-skeleton {
    position: relative;
    display: inline-block;
    animation: fadeIn 1s ease-in-out;

    &__animation {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      animation: flash 1.5s ease-in-out infinite;
    }

    &__type {
      width: 100%;
      height: 100%;
      background: $primary-4;

      &--box {
        border-radius: $rounded-base;
      }
      &--circle {
        border-radius: $rounded-full;
      }
    }

    @keyframes flash {
      from {
        background-position-x: -100%;
      }
      to {
        background-position-x: 200%;
      }
    }
  }
}
</style>
