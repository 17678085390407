export const getInitials = (fullName: string): string => {
  const names = fullName.split(" ").filter(Boolean);

  if (names.length === 0) {
    return "";
  }

  if (names.length === 1) {
    return names[0][0].toUpperCase();
  }

  const [firstName] = names;
  const lastName = names[names.length - 1];
  return (firstName[0] + lastName[0]).toUpperCase();
};
