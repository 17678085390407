<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { vTruncationTitle } from "@/directives/truncationTitle";
import router from "@/router";
import { UserWebRouteName } from "@/router/RouteName";
import { getModuleService } from "@/services/modules";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const route = useRoute();
const { breadcrumbs } = useBreadcrumbs();

const moduleName = ref("");

const hasBreadcrumbs = computed(() => breadcrumbs.value.length > 0);
const isCurrentRouteDashboard = computed(
  () => router.currentRoute.value.name === UserWebRouteName.Dashboard,
);
const shouldShowBreadcrumbs = computed(() => moduleName.value || hasBreadcrumbs.value);
const isLastBreadcrumb = (index: number) => index < breadcrumbs.value.length - 1;

const fetchModuleName = async (moduleId: number) => {
  if (!isNaN(moduleId)) {
    const module = await getModuleService(moduleId);
    moduleName.value = module?.name || "";
  } else {
    moduleName.value = "";
  }
};

watch(
  () => route.params.moduleId,
  (newModuleId) => {
    if (newModuleId) {
      fetchModuleName(Number(newModuleId));
    } else {
      moduleName.value = "";
    }
  },
  { immediate: true },
);
</script>

<template>
  <ul v-if="shouldShowBreadcrumbs" class="user-web-header-breadcrumbs">
    <BaseIcon icon="divider" size="medium" class="user-web-header-breadcrumbs__divider" />

    <li v-if="moduleName">
      <span v-if="isCurrentRouteDashboard" v-truncation-title>
        {{ moduleName }}
      </span>
      <template v-else>
        <RouterLink v-truncation-title :to="{ name: UserWebRouteName.Dashboard }">
          {{ moduleName }}
        </RouterLink>
        <BaseIcon icon="slash" class="user-web-header-breadcrumbs__slash" />
      </template>
    </li>

    <template v-if="hasBreadcrumbs">
      <li v-for="(nav, index) in breadcrumbs" :key="index">
        <RouterLink v-if="nav.name" v-truncation-title :to="{ name: nav.name }">
          {{ nav.label }}
        </RouterLink>
        <span v-else v-truncation-title>{{ nav.label }}</span>
        <BaseIcon
          v-if="isLastBreadcrumb(index)"
          icon="slash"
          class="user-web-header-breadcrumbs__slash"
        />
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">
.user-web-header-breadcrumbs {
  display: flex;
  align-items: center;
  color: $primary-0;
  min-width: 0;

  &__divider {
    margin: 0 $spacing-6;
  }

  &__slash {
    margin: 0 $spacing-3;
  }

  li {
    display: flex;
    align-items: center;
    min-width: 0;

    &:last-child {
      font-weight: $font-medium;
    }

    a {
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
</style>
