<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { Status } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

withDefaults(
  defineProps<{
    value: Status;
    errors?: ErrorObject[];
  }>(),
  {
    errors: () => [],
  },
);

const { t } = useI18n({ useScope: "global" });

const emit = defineEmits<{
  (event: "update:value", option: Status): void;
}>();

const clientStatus: readonly Option[] = [
  {
    title: t(`common.statuses.${Status.Published}`),
    value: Status.Published,
  },
  {
    title: t(`common.statuses.${Status.UnderConstruction}`),
    value: Status.UnderConstruction,
  },
  {
    title: t(`common.archived`),
    value: Status.History,
  },
];
</script>

<template>
  <BaseSelect
    :label="t('common.status')"
    :value="value"
    @change="emit('update:value', $event as Status)"
  >
    <BaseOption v-for="option in clientStatus" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
