import { Ref, computed } from "vue";
import type { ExecutiveReportSurveyDto } from "@/types/_generated/api";
import { sortByKeys } from "@/helpers/sort";
import type { SurveyGroup } from "../types/SurveyGroup";

export const useGroupedSurveys = (surveys: Ref<ExecutiveReportSurveyDto[]>) => {
  const groupedSurveys = computed(() => {
    const groups = surveys.value.reduce((acc, survey) => {
      // Find existing group or create a new one
      let group = acc.find((g) => g.groupName === survey.groupName);

      if (!group) {
        group = { groupName: survey.groupName, groupSurveys: [] };
        acc.push(group);
      }

      // Add the survey to the group
      group.groupSurveys.push(survey);

      return acc;
    }, [] as SurveyGroup[]);

    // Sort groups by group name
    return sortByKeys(groups, { key: "groupName" });
  });

  return {
    groupedSurveys,
  };
};
