<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 4.75C0 4.33579 0.358172 4 0.8 4H2.4C3.43093 4 4.26667 4.78351 4.26667 5.75V7.25C4.26667 8.21652 3.43092 9 2.4 9H1.6V11.25C1.6 11.6642 1.24183 12 0.8 12C0.358172 12 0 11.6642 0 11.25V4.75ZM1.6 7.5H2.4C2.54728 7.5 2.66667 7.38808 2.66667 7.25V5.75C2.66667 5.61193 2.54727 5.5 2.4 5.5H1.6V7.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33331 4.75C5.33331 4.33579 5.69149 4 6.13331 4H7.73331C9.35339 4 10.6666 5.23122 10.6666 6.75V9.25C10.6666 10.7688 9.35338 12 7.73331 12H6.13331C5.69149 12 5.33331 11.6642 5.33331 11.25V4.75ZM6.93331 5.5V10.5H7.73331C8.46973 10.5 9.06665 9.94039 9.06665 9.25V6.75C9.06665 6.05964 8.46972 5.5 7.73331 5.5H6.93331Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7333 4.75C11.7333 4.33579 12.0915 4 12.5333 4H15.2C15.6418 4 16 4.33579 16 4.75C16 5.16421 15.6418 5.5 15.2 5.5H13.3333V7H15.2C15.6418 7 16 7.33579 16 7.75C16 8.16421 15.6418 8.5 15.2 8.5H13.3333V11.25C13.3333 11.6642 12.9752 12 12.5333 12C12.0915 12 11.7333 11.6642 11.7333 11.25V4.75Z"
    />
  </svg>
</template>
