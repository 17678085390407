<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  copyDataFromPreviousSurvey?: boolean;
  lastPublishDate?: string;
}>();

defineEmits<{
  "update:value": [boolean];
}>();
</script>

<template>
  <BaseCheckbox
    :checked="copyDataFromPreviousSurvey ?? false"
    :label="
      t('survey.editSurvey.copyDataFromLastSurvey', {
        date: lastPublishDate,
      })
    "
    @change="$emit('update:value', !copyDataFromPreviousSurvey)"
  />
</template>
