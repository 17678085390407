import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import {
  BaseActionMessage,
  BroadcastMessage,
  BroadcastService,
} from "@/services/shared/BroadcastService";

export const useBroadcastService = <T extends BaseActionMessage = BroadcastMessage>(
  service: BroadcastService<T>,
  ...args: Parameters<BroadcastService<T>["subscribe"]>
) => {
  const token = ref<symbol>();

  onBeforeMount(() => {
    token.value = service.subscribe(...args);
  });

  onBeforeUnmount(() => {
    service.unsubscribe(token.value!);
  });
};
