<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SitePermissionFilterDto } from "@/types/_generated/api";
import { SiteDefinition } from "@/types/SiteDefinitions";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const props = defineProps<{
  siteDefinition: SiteDefinition;
  permissionFilter: SitePermissionFilterDto[];
}>();

defineEmits<{
  add: [string];
}>();

const { t } = useI18n({ useScope: "global" });

const toOptions = (values: string[], customDefinitionNumber: number) => {
  const options = [
    { title: t("users.permissions.all"), value: "[All]" },
    ...values.map((v) => ({ title: v, value: v })),
  ];

  const selectedValues = props.permissionFilter.find(
    (f) => f.customDefinitionNumber === customDefinitionNumber,
  )?.values;

  return options.filter((value) => !selectedValues?.includes(value.value));
};
</script>

<template>
  <BaseSelect v-if="siteDefinition.values.length" :value="''" @change="$emit('add', $event)">
    <BaseOption :value="''" disabled selected>
      {{ siteDefinition.name }}
    </BaseOption>
    <BaseOption
      v-for="option in toOptions(siteDefinition.values, siteDefinition.customDefinitionNumber)"
      :key="option.value"
      :value="option.value"
    >
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
