<script setup lang="ts" generic="T extends object">
import { toRef, computed } from "vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import DashboardTableEmpty from "./components/DashboardTableEmpty.vue";
import DashboardTableTitle from "./components/DashboardTableTitle.vue";
import { useDashboardTable } from "./composables/useDashboardTable";
import { vRowLink } from "./directives/rowLink";

const props = defineProps<{
  isLoading: boolean;
  title: string;
  columns: { title: string; key: keyof T }[];
  rows: T[];
  sortBy?: keyof T;
}>();

const { sortedRows, getRouterLink, handleRowClick, getDisplayValue } = useDashboardTable({
  rows: toRef(props, "rows"),
  sortBy: toRef(props, "sortBy"),
});

const isNumericColumn = computed(() => {
  if (props.rows.length === 0) return props.columns.map(() => false);

  return props.columns.map((column) => {
    // Check if all non-null values in this column are numbers
    return props.rows.every((row) => {
      const value = row[column.key];
      return value === null || value === undefined || typeof value === "number";
    });
  });
});
</script>

<template>
  <div class="dashboard-table" :class="{ 'dashboard-table--loading': isLoading }">
    <DashboardTableTitle>
      {{ title }}
    </DashboardTableTitle>

    <table>
      <thead v-if="rows.length">
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            :data-text-right="isNumericColumn[index]"
          >
            {{ column.title }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(row, rowIndex) in sortedRows"
          :key="rowIndex"
          v-row-link="Boolean(getRouterLink(row))"
          @keypress.enter="handleRowClick(row)"
          @click="handleRowClick(row)"
        >
          <td
            v-for="(column, columnIndex) in columns"
            :key="column.key"
            :data-text-right="isNumericColumn[columnIndex]"
          >
            {{ getDisplayValue(row, column.key) }}
          </td>
        </tr>

        <tr v-if="isLoading">
          <td v-for="index in columns.length" :key="index">
            <BaseSkeleton width="100%" height="0.75rem" />
          </td>
        </tr>
      </tbody>
    </table>

    <DashboardTableEmpty v-if="!isLoading && !rows.length" />
  </div>
</template>

<style scoped lang="scss">
.dashboard-table {
  display: flex;
  flex-direction: column;
  border: 1px solid $primary-4;
  border-radius: $rounded-base;
  max-height: 100%;
  overflow: auto;

  &--loading {
    min-height: 0;
    overflow: hidden;
  }

  table {
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: $text-sm;

    thead {
      th {
        font-weight: $font-medium;
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: $primary-2;
        transition: background-color $duration-100;
      }

      [data-row-link="true"]:hover {
        cursor: pointer;
        background-color: $primary-3;
      }
    }

    th,
    td {
      white-space: nowrap;
      vertical-align: middle;
      padding: $spacing-2 $spacing-3;

      &[data-text-right="true"] {
        text-align: right;
      }
    }
  }
}
</style>
