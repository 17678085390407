<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="survey-edit-drawer__participants__copy-message">
    <span>{{ t("survey.participants.copyExplanation.part1") }}</span>
    <BaseIcon icon="copy" size="medium" />
    <span>{{ t("survey.participants.copyExplanation.part2") }}</span>
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer__participants__copy-message {
  display: flex;
  font-size: $text-sm;
  color: $primary-7;
  align-items: center;
  gap: $spacing-2;
}
</style>
