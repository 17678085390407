import { ref, readonly, onMounted } from "vue";
import { getDashboardRecResponseRatioService } from "@/services/dashboard";

export const useGetDashboardRecResponseRatio = (moduleId: number) => {
  const isLoadingRatio = ref(false);
  const hasError = ref(false);
  const ratio = ref<number | null>(null);

  const getDashboardRecResponseRatio = async () => {
    isLoadingRatio.value = true;
    hasError.value = false;

    try {
      ratio.value = await getDashboardRecResponseRatioService(moduleId);
    } catch {
      hasError.value = true;
    } finally {
      isLoadingRatio.value = false;
    }
  };

  onMounted(() => getDashboardRecResponseRatio());

  return {
    ratio,
    hasError: readonly(hasError),
    isLoadingRatio: readonly(isLoadingRatio),
  };
};
