<script setup lang="ts">
import { computed } from "vue";
import UWInputErrorMessage from "../InputErrorMessage/UWInputErrorMessage.vue";

export interface Props {
  value: string;
  type?: "text" | "number" | "search" | "email" | "password";
  disabled?: boolean;
  placeholder?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  errorMessage?: string;
}

const props = defineProps<Props>();

defineEmits<{
  input: [value: string];
}>();

const inputCss = computed(() => ({
  "base-textfield__input": true,
  "base-textfield__input--with-counter": !!props.maxLength,
  "base-textfield__input--has-error": props.errorMessage,
}));
</script>

<template>
  <div class="base-textfield">
    <input
      :class="inputCss"
      spellcheck="false"
      :disabled="disabled"
      :min="min"
      :max="max"
      :maxlength="maxLength"
      :placeholder="disabled ? '' : placeholder"
      :type="type"
      :value="value"
      @input="$emit('input', ($event.target as HTMLInputElement).value)"
    />

    <span v-if="maxLength" class="base-textfield__counter">
      {{ value.length }} / {{ maxLength }}
    </span>

    <UWInputErrorMessage v-if="errorMessage">
      {{ errorMessage }}
    </UWInputErrorMessage>
  </div>
</template>

<style scoped lang="scss">
.base-textfield {
  position: relative;

  &__input {
    padding: $spacing-2 $spacing-3;
    border: 1px solid $primary-7;
    border-radius: $rounded-base;
    height: $shared-component-height;
    width: 100%;
    font-size: $text-sm;

    &--with-counter {
      padding-right: $spacing-16;
    }

    &--has-error {
      border-color: $error-3;
    }
  }

  &__counter {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: $spacing-3;
    font-size: $text-xs;
    color: $primary-8;
    font-variant-numeric: tabular-nums;
  }
}
</style>
