<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useTableFilter } from "@/composables/useTableFilter";
import { getIconForFileType } from "@/helpers/getIconForFileType";
import { binaryOptions } from "@/helpers/options";
import { LibraryFileDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseIcon, { type Props as BaseIconProps } from "@/components/base/BaseIcon/BaseIcon.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";

type LibraryFile = { icon: BaseIconProps["icon"] } & LibraryFileDto;

const props = defineProps<{
  isLoading: boolean;
  moduleId: number;
  files: LibraryFileDto[] | null;
  isUserWeb: boolean;
  canModifyFiles: boolean;
}>();

defineEmits<{
  "open-drawer": [file: LibraryFileDto | null];
}>();

const { t } = useI18n({ useScope: "global" });

const columns = computed(() => {
  const columns: ColumnDefinition<LibraryFile>[] = [
    new ColumnDefinition({
      title: t("modules.libraryTable.fileType"),
      key: "icon",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.libraryTable.id"),
      key: "fileId",
      defaultHidden: true,
    }),
    new ColumnDefinition({
      title: t("modules.libraryTable.fileName"),
      key: "fileName",
    }),
    new ColumnDefinition({
      title: t("modules.libraryTable.language"),
      key: "language",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.libraryTable.tags"),
      key: "tags",
    }),
  ];

  if (!props.isUserWeb) {
    columns.push(
      new ColumnDefinition({
        title: t("modules.libraryTable.visibleInUserWeb"),
        key: "isVisibleInUserWeb",
        type: ColumnDataType.Boolean,
        searchOptions: binaryOptions,
        autoWidth: true,
      }),
    );
  }

  return columns;
});

const libraryFiles = computed<LibraryFile[]>(() => {
  if (!props.files) return [];

  return props.files.map((file) => ({
    ...file,
    icon: getIconForFileType(file.fileName),
  }));
});

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(libraryFiles, columns);
</script>

<template>
  <BaseGridTable
    :enable-row-click="canModifyFiles"
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="moduleLibraryTable"
    :default-sort="[{ key: 'fileName' }]"
    @click:row="$emit('open-drawer', $event)"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <BaseButton v-if="canModifyFiles" @click="$emit('open-drawer', null)">
        {{ t("common.actions.uploadFiles") }}
      </BaseButton>
    </template>

    <template #column-icon="{ row }">
      <BaseIcon :icon="row.icon" size="large" />
    </template>

    <template v-if="isUserWeb" #column-fileName="{ row: { fileName, fileId } }">
      <a
        :href="`/v1/modules/${moduleId}/library-files/${fileId}`"
        target="_blank"
        rel="noopener noreferrer"
        :title="fileName"
        :download="fileName"
      >
        {{ fileName }}
      </a>
    </template>

    <template #column-tags="{ row }">
      <span class="module-library-table__tags">
        <template v-for="(tag, i) in row.tags" :key="i">
          <BasePill :label="tag" />
        </template>
      </span>
    </template>
  </BaseGridTable>
</template>

<style scoped lang="scss">
.module-library-table {
  &__tags {
    display: flex;
    gap: $spacing-1;
  }
}
</style>
