/**
 * Deeply compares two objects or arrays to determine if they are equivalent.
 * This comparison is thorough, checking nested structures and ignoring
 * the order of elements in arrays. It handles arrays, objects, and primitive types.
 *
 * @param {T} object1 The first object to compare.
 * @param {T} object2 The second object to compare.
 * @returns {boolean} `true` if the objects are deeply equal, otherwise `false`.
 * @template T Extends Record<string, unknown> to ensure the parameters are objects.
 */
export const isDeepEqual = <T>(object1: T, object2: T): boolean => {
  if (object1 === object2) return true;

  // Check if both objects are instances of Set and if so, use Set-specific comparison logic
  if (object1 instanceof Set && object2 instanceof Set) {
    return compareSets(object1, object2);
  }

  // Further checks if objects are indeed object-like
  if (!isObject(object1) || !isObject(object2)) {
    return false; // If one or both are not objects (and different), they are not equal
  }

  const objKeys1 = Object.keys(object1) as (keyof T)[];
  const objKeys2 = Object.keys(object2) as (keyof T)[];

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const val1 = object1[key];
    const val2 = object2[key];

    // Recursively compare both objects or nested arrays
    if (isObject(val1) && isObject(val2)) {
      if (!isDeepEqual(val1, val2)) return false;
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!compareArrays(val1, val2)) return false;
    } else if (val1 !== val2) {
      return false;
    }
  }
  return true;
};

// Helper function to determine if a value is an object (including arrays)
const isObject = (object: unknown): object is Record<string, unknown> => {
  return typeof object === "object" && object !== null;
};

// Helper function to compare arrays independently of order
const compareArrays = <T>(array1: T[], array2: T[]): boolean => {
  if (array1.length !== array2.length) return false;

  // Sort and compare arrays with primitive elements directly
  if (array1.every((item) => typeof item !== "object")) {
    return array1.sort().toString() === array2.sort().toString();
  }

  // Checks that each item in both arrays is present in the other, ensuring mutual inclusivity regardless of order
  const allItemsInArray1FoundInArray2 = array1.every((item1) =>
    array2.some((item2) => isDeepEqual(item1, item2)),
  );
  const allItemsInArray2FoundInArray1 = array2.every((item2) =>
    array1.some((item1) => isDeepEqual(item2, item1)),
  );

  return allItemsInArray1FoundInArray2 && allItemsInArray2FoundInArray1;
};

// Helper function to compare sets
const compareSets = <T>(set1: Set<T>, set2: Set<T>): boolean => {
  if (set1.size !== set2.size) return false;
  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }
  return true;
};
