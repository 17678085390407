<script setup lang="ts">
import { onMounted, computed, ref } from "vue";
import { useGetLibraryFiles } from "@/composables/services/useGetLibraryFiles";
import { authorize } from "@/plugins/can";
import { libraryEvents } from "@/services/library";
import { LibraryFileDto } from "@/types/_generated/api";
import ModuleLibraryDrawer from "./ModuleLibraryDrawer.vue";
import ModuleLibraryTable from "./ModuleLibraryTable.vue";

const { moduleId, isUserWeb } = defineProps<{
  moduleId: number;
  isUserWeb: boolean;
}>();

const { getLibraryFiles, libraryFiles, hasError, isLoadingLibraryFiles } = useGetLibraryFiles();

const selectedFile = ref<LibraryFileDto | null>(null);
const showLibraryDrawer = ref(false);

const canModifyFiles = computed(() => {
  if (isUserWeb) {
    return false;
  }
  return authorize.hasModulePermission(moduleId, "uploadFiles");
});

libraryEvents.subscribe(() => getLibraryFiles(moduleId, isUserWeb));

const toggleDrawer = (file: LibraryFileDto | null) => {
  selectedFile.value = file;
  showLibraryDrawer.value = !showLibraryDrawer.value;
};

onMounted(() => getLibraryFiles(moduleId, isUserWeb));
</script>

<template>
  <ModuleLibraryTable
    v-if="!hasError"
    :module-id="moduleId"
    :files="libraryFiles"
    :is-loading="isLoadingLibraryFiles"
    :is-user-web="isUserWeb"
    :can-modify-files="canModifyFiles"
    @open-drawer="toggleDrawer"
  />

  <ModuleLibraryDrawer
    v-if="showLibraryDrawer && canModifyFiles"
    :module-id="moduleId"
    :selected-file="selectedFile"
    @close-drawer="toggleDrawer(null)"
  />
</template>
