<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(defineProps<{ disabled?: boolean; label: string; title: string }>(), {
  disabled: false,
});
defineEmits<{
  (event: "click"): void;
}>();
const title = computed(() => (props.disabled ? "" : props.title));
</script>

<template>
  <div
    class="base-entity-link"
    :class="{ 'base-entity-link--enabled': !disabled }"
    :title="title"
    @click="!disabled && $emit('click')"
  >
    <div
      :class="{
        'base-entity-link__icon': true,
        'base-entity-link__icon--visible': !disabled,
      }"
    >
      <slot name="icon"></slot>
    </div>

    <a v-if="!disabled" class="base-entity-link__label">{{ label }}</a>
    <span v-if="disabled" class="base-entity-link__label">{{ label }}</span>
  </div>
</template>

<style scoped lang="scss">
.base-entity-link {
  display: flex;

  &--enabled {
    cursor: pointer;
  }

  &__icon {
    margin-right: $spacing-3;
    visibility: hidden;
  }

  &__icon--visible {
    visibility: visible;
  }
  &__label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
