<script setup lang="ts">
import { ref } from "vue";
import type { ReportMailRecipient } from "../types";
import ReportMailRecipientForm from "./ReportMailRecipientForm.vue";
import ReportMailRecipientListItemSum from "./ReportMailRecipientListItemSum.vue";

defineProps<{
  recipient: ReportMailRecipient;
  isMobileRequired: boolean;
}>();

const emit = defineEmits<{
  "delete:recipient": [ReportMailRecipient];
  "update:recipient": [ReportMailRecipient];
}>();

const isEditingRecipient = ref(false);

const confirmRecipient = (updatedRecipient: ReportMailRecipient) => {
  isEditingRecipient.value = false;
  emit("update:recipient", updatedRecipient);
};
</script>

<template>
  <div class="report-mail-recipient-list-item">
    <ReportMailRecipientForm
      v-if="isEditingRecipient"
      is-editing-form
      :incoming-recipient="recipient"
      :is-mobile-required="isMobileRequired"
      @edit:recipient="isEditingRecipient = false"
      @confirm:recipient="confirmRecipient"
    />

    <ReportMailRecipientListItemSum
      v-else
      :recipient="recipient"
      :is-mobile-required="isMobileRequired"
      @edit:recipient="isEditingRecipient = true"
      @delete:recipient="$emit('delete:recipient', $event)"
      @update:recipient="$emit('update:recipient', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.report-mail-recipient-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-3;
  background-color: $primary-0;
  padding: $spacing-3;
  border: 1px solid $primary-6;
  border-radius: $rounded-base;
}
</style>
