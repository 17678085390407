import { RouteRecordRaw } from "vue-router";
import DashboardView from "@/views/UserWeb/Dashboard/DashboardView.vue";
import DAATView from "@/views/UserWeb/DataAnalysisAndTrends/DAATView.vue";
import ExecSummaryView from "@/views/UserWeb/ExecutiveSummary/ExecSummaryView.vue";
import ViewLibrary from "@/views/UserWeb/Library/ViewLibrary.vue";
import ViewModules from "@/views/UserWeb/Modules/ViewModules.vue";
import ViewQuestionnaire from "@/views/UserWeb/Questionnaire/ViewQuestionnaire.vue";
import ViewRecTracking from "@/views/UserWeb/RecTracking/ViewRecTracking.vue";
import ViewSite from "@/views/UserWeb/Sites/ViewSite.vue";
import ViewSites from "@/views/UserWeb/Sites/ViewSites.vue";
import ViewOnlineReport from "@/views/UserWeb/Surveys/ViewOnlineReport.vue";
import ViewSpoeCheck from "@/views/UserWeb/Surveys/ViewSpoeCheck.vue";
import ViewUserWeb from "@/views/UserWeb/ViewUserWeb.vue";
import { UserWebRouteName } from "../RouteName";

export const userWebRoutes: RouteRecordRaw[] = [
  {
    path: "/user-web",
    name: UserWebRouteName.Root,
    redirect: { name: UserWebRouteName.Modules },
    component: ViewUserWeb,
    children: [
      {
        path: "modules",
        name: UserWebRouteName.Modules,
        component: ViewModules,
      },
      {
        path: "modules/:moduleId",
        name: UserWebRouteName.Module,
        redirect: { name: UserWebRouteName.Dashboard },
        children: [
          {
            path: "dashboard",
            name: UserWebRouteName.Dashboard,
            component: DashboardView,
            props: (route) => ({ moduleId: Number(route.params.moduleId) }),
          },
          {
            path: "sites",
            name: UserWebRouteName.Sites,
            component: ViewSites,
            props: (route) => ({ moduleId: Number(route.params.moduleId) }),
            children: [
              {
                path: ":siteId",
                redirect: { name: UserWebRouteName.SiteDetails },
                name: UserWebRouteName.Site,
                component: ViewSite,
                props: (route) => ({
                  moduleId: Number(route.params.moduleId),
                  siteId: Number(route.params.siteId),
                }),
                children: [
                  {
                    path: "details",
                    name: UserWebRouteName.SiteDetails,
                    component: ViewSite,
                  },
                  {
                    path: "rec-tracking",
                    name: UserWebRouteName.SiteRecTracking,
                    component: ViewSite,
                  },
                  {
                    path: "blue-rating",
                    name: UserWebRouteName.SiteBlueRating,
                    component: ViewSite,
                  },
                  {
                    path: "reports",
                    name: UserWebRouteName.SiteReports,
                    component: ViewSite,
                  },
                ],
              },
            ],
          },
          {
            path: "sites/:siteId/surveys/:surveyId/spoe-check",
            name: UserWebRouteName.SpoeCheck,
            component: ViewSpoeCheck,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
              surveyId: Number(route.params.surveyId),
            }),
          },
          {
            path: "sites/:siteId/surveys/:surveyId/online-report",
            name: UserWebRouteName.OnlineReport,
            component: ViewOnlineReport,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
              surveyId: Number(route.params.surveyId),
            }),
          },
          {
            path: "library",
            name: UserWebRouteName.Library,
            component: ViewLibrary,
            props: (route) => ({ moduleId: Number(route.params.moduleId) }),
          },
          {
            path: "questionnaire",
            name: UserWebRouteName.Questionnaire,
            component: ViewQuestionnaire,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            path: "executive-summary",
            name: UserWebRouteName.ExecutiveSummary,
            component: ExecSummaryView,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            path: "rec-tracking",
            redirect: { name: UserWebRouteName.RecTrackingOverview },
            name: UserWebRouteName.RecTracking,
            component: ViewRecTracking,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            children: [
              {
                path: "overview",
                name: UserWebRouteName.RecTrackingOverview,
                component: ViewRecTracking,
              },
              {
                path: "detailed",
                name: UserWebRouteName.RecTrackingDetailed,
                component: ViewRecTracking,
              },
            ],
          },
          {
            path: "data-analysis-and-trends",
            redirect: { name: UserWebRouteName.DAATBlueTrend },
            name: UserWebRouteName.DAAT,
            component: DAATView,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            children: [
              {
                path: "blue-trend",
                name: UserWebRouteName.DAATBlueTrend,
                component: DAATView,
              },
              {
                path: "loss-values",
                name: UserWebRouteName.DAATLossValues,
                component: DAATView,
              },
              {
                path: "raw-data",
                name: UserWebRouteName.DAATRawData,
                component: DAATView,
              },
            ],
          },
        ],
      },
    ],
  },
];
