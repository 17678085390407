<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { RecReportSettingsScope } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInfoState from "@/components/base/BaseInfoState/BaseInfoState.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";

defineProps<{
  isLoading: boolean;
  selectedScope: RecReportSettingsScope;
}>();

defineEmits<{
  createSection: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="rec-report-state">
    <BaseSpinner v-if="isLoading" />

    <div v-else class="rec-report-state__empty">
      <BaseInfoState
        :title="t('module.recReportSettings.empty.title')"
        :description="
          t('module.recReportSettings.empty.description', { scope: selectedScope.toLowerCase() })
        "
        variant="empty"
      />
      <BaseButton size="small" @click="$emit('createSection')">
        {{ t("module.recReportSettings.empty.create") }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rec-report-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
