<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import type { SurveyLeanDto, SurveyReportFileDto } from "@/types/_generated/api";
import { useDrawerState } from "@/composables/useDrawerState";
import { formatDate } from "@/helpers/formatDate";
import { UserWebRouteName } from "@/router/RouteName";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import { useHasReportMailPermission } from "@/components/shared/ReportMailDrawer/composables/useHasReportMailPermission";
import ReportMailDrawer from "@/components/shared/ReportMailDrawer/ReportMailDrawer.vue";
import SiteReport from "./components/SiteReport.vue";

const props = defineProps<{
  isLoading: boolean;
  hasLoadingError: boolean;
  moduleId: number;
  currentSurveyId: number | null;
  currentSurveyReportFiles: SurveyReportFileDto[];
  surveys: SurveyLeanDto[];
}>();

defineEmits<{
  "update:surveyId": [number];
}>();

const { t } = useI18n({ useScope: "global" });

const router = useRouter();

const {
  showDrawer: showReportMailDrawer,
  openDrawer: openReportMailDrawer,
  closeDrawer: closeReportMailDrawer,
} = useDrawerState();

const { hasReportMailPermission } = useHasReportMailPermission(props.moduleId);

const surveyOptions = computed(() =>
  props.surveys.map((survey) => ({
    value: survey.surveyId,
    title: formatDate.asDateOnly(survey.surveyDate),
  })),
);

const sortedReportFiles = computed(() =>
  // Sorted by report type order: RecommendationReport, UWReport, OtherReport
  props.currentSurveyReportFiles.toSorted((a, b) => {
    const reportTypeOrder = ["RecommendationReport", "UwReport", "OtherReport"];
    return reportTypeOrder.indexOf(a.type) - reportTypeOrder.indexOf(b.type);
  }),
);
</script>

<template>
  <div class="view-site-reports">
    <BaseSpinner v-if="isLoading" class="view-site-reports__spinner" />

    <div v-else class="view-site-reports__content">
      <BaseSelect
        v-if="currentSurveyId"
        :value="currentSurveyId"
        :options="surveyOptions"
        :label="t('surveys.surveyDate')"
        :disabled="isLoading"
        @change="$emit('update:surveyId', parseInt($event as string))"
      >
        <BaseOption v-for="option in surveyOptions" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>

      <div v-for="(reportFile, reportIndex) in sortedReportFiles" :key="reportIndex">
        <SiteReport
          v-if="reportFile.type === 'RecommendationReport'"
          :survey-id="currentSurveyId"
          :report-file="reportFile"
        >
          <template #buttons>
            <BaseButton
              variant="outlined"
              @click="
                router.push({
                  name: UserWebRouteName.OnlineReport,
                  params: { surveyId: currentSurveyId },
                })
              "
            >
              {{ t("survey.uploadReport.onlineReport") }}
            </BaseButton>

            <BaseButton
              v-if="hasReportMailPermission"
              variant="outlined"
              @click="openReportMailDrawer"
            >
              {{ t("survey.surveyReportMail.surveyReportMail") }}
            </BaseButton>
          </template>
        </SiteReport>

        <SiteReport v-else :survey-id="currentSurveyId" :report-file="reportFile" />
      </div>
    </div>
  </div>

  <ReportMailDrawer
    v-if="currentSurveyId && showReportMailDrawer"
    :survey-id="currentSurveyId"
    :module-id="moduleId"
    @close="closeReportMailDrawer"
  />
</template>

<style scoped lang="scss">
.view-site-reports {
  display: flex;
  flex-direction: column;

  &__spinner {
    height: calc($view-height - ($header-height * 2));
  }

  &__content {
    display: flex;
    align-items: center;
    gap: $spacing-8;
  }
}
</style>
