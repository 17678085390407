import {
  Language,
  LoginType,
  MfaOption,
  Role,
  UserDto,
  type UpdateUser,
} from "@/types/_generated/api";

export class UserForm implements UpdateUser {
  userId = 0;
  allowReportMail = false;
  allowRmComment = false;
  allowUserWeb = false;
  city: string | undefined = undefined;
  company: string | undefined = undefined;
  country: string | undefined = undefined;
  email: string | undefined = undefined;
  firstName: string | undefined = undefined;
  lastName: string | undefined = undefined;
  isArchived = false;
  isLockedOut = false;
  language = Language.English;
  loginType = LoginType.Blue;
  mobilePhoneNumber = "";
  phoneNumber: string | undefined = undefined;
  role = Role.EXT;
  title: string | undefined = undefined;
  userName: string | undefined = undefined;
  mfa = MfaOption.EmailorSms;
  sendPasswordResetEmail = true;
  password1 = "";
  password2 = "";

  constructor(user?: UserDto) {
    if (user) {
      Object.assign(this, user);
    }
  }
}
