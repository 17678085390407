<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { MfaOption, RecSortOrder } from "@/types/_generated/api";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDivier from "@/components/base/BaseDivider.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import type { ModuleForm, ErrorMessage, UpdateModuleField } from "./types";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  moduleForm: ModuleForm;
  errorMessage: ErrorMessage;
}>();

defineEmits<{
  "update:field": UpdateModuleField;
}>();

const enumToI18nKeyMap = {
  [RecSortOrder.AgeAscending]: "ageAscending",
  [RecSortOrder.AgeDescending]: "ageDescending",
  [RecSortOrder.Grading]: "grading",
  [RecSortOrder.Questionnaire]: "questionnaire",
};

const recSortOrderOptions = computed(() =>
  Object.values(RecSortOrder).map((value) => ({
    title: t(`modules.recSortOrder.${enumToI18nKeyMap[value]}`),
    value,
  })),
);
</script>

<template>
  <div class="module-edit-user-web">
    <div>
      <BaseLabel has-spacing>
        {{ t("modules.userWebText") }}
      </BaseLabel>
      <BaseEditor
        :custom-height="{ min: '150px' }"
        :error-message="errorMessage.userWebText"
        :value="moduleForm.userWebText"
        @input="$emit('update:field', 'userWebText', $event)"
      />
    </div>

    <BaseDivier />

    <div class="module-edit-user-web__two-columns">
      <BaseCheckbox
        :label="t('modules.hasExecSumIncludeSiteSummary')"
        :checked="moduleForm.hasExecSumIncludeSiteSummary"
        @change="$emit('update:field', 'hasExecSumIncludeSiteSummary', $event)"
      />

      <BaseSelect
        :label="t('modules.userWebLoginType')"
        :value="moduleForm.userWebLoginType"
        @change="$emit('update:field', 'userWebLoginType', $event)"
      >
        <BaseOption v-for="option in Object.values(MfaOption)" :key="option" :value="option">
          {{ t(`common.mfa.${option.toLowerCase()}`) }}
        </BaseOption>
      </BaseSelect>
    </div>

    <div class="module-edit-user-web__two-columns">
      <BaseCheckbox
        :label="t('modules.hasSiteResponseBlueRating')"
        :checked="moduleForm.hasSiteResponseBlueRating"
        @change="$emit('update:field', 'hasSiteResponseBlueRating', $event)"
      />

      <BaseSelect
        :value="moduleForm.recSortOrder"
        :label="t('modules.recommendationSortOrder')"
        @change="$emit('update:field', 'recSortOrder', $event)"
      >
        <BaseOption v-for="option in recSortOrderOptions" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-edit-user-web {
  @include grid-columns(1);

  &__two-columns {
    @include grid-columns(2);
  }
}
</style>
