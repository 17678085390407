<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { BlueColor, BlueRatingCategoryDto, BlueRatingQuestionDto } from "@/types/_generated/api";
import { BlueRatingQuestionColors } from "@/types/Survey";
import { State } from "@/components/base/BaseAutoSaveStatus.vue";
import BlueRatingAddRecMenu from "../BlueRatingAddRecMenu.vue";
import BlueRatingCard from "../BlueRatingCard.vue";
import BlueRatingDescriptionToggle from "../BlueRatingDescriptionToggle.vue";
import BlueRatingQuestionField from "./BlueRatingQuestionField.vue";
import BlueRatingQuestionNotApplicableButton from "./BlueRatingQuestionNotApplicableButton.vue";

interface Props {
  surveyId: number;
  category: BlueRatingCategoryDto;
  readOnly: boolean;
  question: BlueRatingQuestionDto;
  showDescription: boolean;
  setReplySaveState: State;
}

const props = defineProps<Props>();
const showQuestionDescription = ref(false);

const emits = defineEmits<{
  "update:value": [{ questionId: number; value: BlueColor }];
  "edit-recommendation": [number];
  "new-recommendation": [void];
  "copy-standard-rec": [void];
}>();

const { t } = useI18n({ useScope: "global" });

const updateQuestionValue = (value: BlueColor) =>
  emits("update:value", {
    questionId: props.question.questionId,
    value: value === props.question.value ? BlueColor.NotSet : value,
  });

const colors = ["blue", "green", "yellow", "red"] as (keyof BlueRatingQuestionColors)[];

watch(
  () => props.showDescription,
  (value) => (showQuestionDescription.value = value),
);
</script>

<template>
  <div class="blue-rating-question">
    <BlueRatingCard
      :id="`question_${question.questionId}`"
      :position="question.fullPosition"
      :title="question.text"
      :recommendations="question.recommendations"
      @click:recommendation="$emit('edit-recommendation', $event)"
    >
      <template #controls>
        <BlueRatingAddRecMenu
          :title="
            question.value === BlueColor.Gray
              ? t('blueRating.recommendation.addRecommendationOnGrey')
              : t('blueRating.recommendation.addRecommendation')
          "
          :category="category"
          :disabled="readOnly || question.value === BlueColor.Gray"
          @new-recommendation="$emit('new-recommendation')"
          @copy-standard-rec="$emit('copy-standard-rec')"
        />

        <BlueRatingDescriptionToggle
          :show-description="showQuestionDescription"
          @toggle="showQuestionDescription = !showQuestionDescription"
        />

        <BlueRatingQuestionNotApplicableButton
          :read-only="readOnly"
          :question="question"
          @click="updateQuestionValue(BlueColor.Gray)"
        />
      </template>

      <div v-if="question.description" class="blue-rating-question__description">
        {{ question.description }}
      </div>
      <div class="blue-rating-question__ratings">
        <div
          v-for="(color, i) in colors"
          :key="i"
          class="blue-rating-question__rating"
          :class="{
            [`blue-rating-question__rating--${question[color].color.toLowerCase()}`]:
              true && question[color].color === question.value,
            'blue-rating-question__rating--disabled':
              question.recommendations.length > 0 && (color === 'blue' || color === 'gray'),
          }"
        >
          <BlueRatingQuestionField
            :id="question.questionId"
            :disabled="
              readOnly ||
              ((color === 'blue' || color === 'gray') && !!question.recommendations.length)
            "
            :rating="question[color]"
            :value="question.value || BlueColor.NotSet"
            :set-reply-save-state="setReplySaveState"
            @update:value="updateQuestionValue"
          />
          <div v-if="showQuestionDescription" class="blue-rating-question__rating__description">
            {{ question[color]!.description }}
          </div>
        </div>
      </div>
    </BlueRatingCard>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-question {
  font-size: $text-sm;
  line-height: $leading-tight;
  margin-top: $spacing-8;

  [id^="question_"] {
    scroll-margin-top: 1rem;
  }

  &__description {
    padding: $spacing-3;
    display: flex;
    flex-direction: column;
    color: $primary-7;
  }

  &__ratings {
    border-top: 1px solid $primary-4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__rating {
    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not(:last-child) {
      border-right: 1px solid $primary-3;
    }

    &__description {
      padding: $spacing-4;
      color: $primary-9;
    }

    @include blue-rating-styles(0.1);
  }
}

:deep(.base-button) {
  padding: $spacing-3 $spacing-2;
}

:deep(.base-card) {
  padding: 0;
}
</style>
