<script setup lang="ts">
import { computed } from "vue";
import { LossScenarioDto } from "@/types/_generated/api";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";
import type { DaatServerFilter } from "../composables/useFilterServer";

const { lossScenarios, selectedOptions } = defineProps<{
  selectedOptions: DaatServerFilter["lossScenarioIds"];
  lossScenarios: LossScenarioDto[];
}>();

defineEmits<{
  "update:selected": [Set<number>];
}>();

const options = computed(() =>
  lossScenarios.map((lossScenario) => {
    if (!lossScenario.id) {
      throw new Error("Loss scenario ID is required");
    }

    return {
      label: lossScenario.name,
      value: lossScenario.id,
    };
  }),
);
</script>

<template>
  <ModMultiselect
    :options="options"
    :selected-options="selectedOptions"
    @update:selected="$emit('update:selected', new Set($event))"
  />
</template>
