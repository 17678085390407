<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  label: string;
  tags: string[];
}

defineProps<Props>();

const emit = defineEmits<{
  add: [tag: string];
  delete: [index: number];
}>();
</script>

<template>
  <BasePillsInput
    :label="t('standardRecommendations.questionTags')"
    :values="tags"
    :max-length="50"
    @add="emit('add', $event)"
  >
    <template #pill="{ value, index }">
      <BasePill :key="index" clickable @click="emit('delete', index)">
        <template #label>
          {{ value }}
        </template>
      </BasePill>
    </template>
  </BasePillsInput>
</template>
