import { ref, readonly, onMounted } from "vue";
import type { RecReportSettingsDto } from "@/types/_generated/api";
import { getAllRecReportSettingsService } from "@/services/recommendationReportSettings";

export const useGetAllSettings = (
  moduleId: number,
  applyFetchedData: (settings: RecReportSettingsDto[]) => void,
) => {
  const isFetchingSettings = ref(false);

  const getSettings = async () => {
    isFetchingSettings.value = true;

    try {
      const response = await getAllRecReportSettingsService({ moduleId });
      applyFetchedData(response.settings);
    } finally {
      isFetchingSettings.value = false;
    }
  };

  onMounted(() => getSettings());

  return {
    isFetchingSettings: readonly(isFetchingSettings),
    getSettings,
  };
};
