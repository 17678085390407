<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetUsers } from "@/composables/services/useGetUsers";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { modulePermissionEvents } from "@/services/permissions";
import { userEvents } from "@/services/users";
import { UserLeanDto } from "@/types/_generated/api";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import UsersTable from "./components/UsersTable.vue";

const { t } = useI18n({ useScope: "global" });

const { isLoadingUsers, getUsers } = useGetUsers();

const users = ref<UserLeanDto[] | null>();

useBroadcastService(userEvents, async () => (users.value = await getUsers()));
useBroadcastService(modulePermissionEvents, async () => (users.value = await getUsers()));

onBeforeMount(async () => (users.value = await getUsers()));
</script>

<template>
  <AdminHeader :breadcrumbs="[{ title: t('navigation.userAdministration') }]" />

  <AdminViewLayout>
    <UsersTable :is-loading="isLoadingUsers" :users="users ?? []" />
  </AdminViewLayout>
</template>
