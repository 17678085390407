import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import { RecommendationResponseDto, UpdateRecommendationResponse } from "@/types/_generated/api";
import { BaseCRUDMessage, BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const recommendationResponseEvents = new BroadcastService<BaseCRUDMessage>(
  "recommendationResponse",
);

const { t } = i18n.global;

export const getRecommendationResponse = async (
  moduleId: number,
  recommendationResponseId: number,
): Promise<RecommendationResponseDto | null> => {
  try {
    const {
      data: { recommendationResponse },
    } = await http.get<{ recommendationResponse: RecommendationResponseDto }>(
      `/modules/${moduleId}/recommendation-responses/${recommendationResponseId}`,
    );
    return recommendationResponse;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("recommendationResponse.recommendationResponse"),
    });
  }
  return null;
};

export const updateRecommendationResponse = async (
  moduleId: number,
  settings: UpdateRecommendationResponse,
): Promise<boolean> => {
  try {
    await http.put(
      `/modules/${moduleId}/recommendation-responses/${settings.recommendationResponseId}`,
      settings,
    );

    notify.success(
      t("recommendationResponse.recommendationResponse"),
      t("common.updated").toLowerCase(),
    );
    recommendationResponseEvents.post({ action: "update" });

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("recommendationResponse.recommendationResponse"),
    });
  }
  return false;
};

export const deleteRecommendationResponseFile = async (
  moduleId: number,
  recommendationResponseId: number,
  fileId: string,
): Promise<boolean> => {
  try {
    await http.delete(
      `/modules/${moduleId}/recommendation-responses/${recommendationResponseId}/files/${fileId}`,
    );
    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("library.fileUpload.file"),
    });
    return false;
  }
};
