<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { BlueRatingRecommendationDto, SiteResponseStatus } from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import ExternalResponseFileList from "@/views/RecResponse/Respond/components/ExternalResponseFileList.vue";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import RecommendationProperties from "@/components/shared/RecommendationProperties/RecommendationProperties.vue";
import RecommendationRcbaProperties from "@/components/shared/RecommendationProperties/RecommendationRcbaProperties.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ResponseFileList from "@/components/shared/RecommendationResponse/ResponseFileList.vue";

defineProps<{
  isLoading: boolean;
  trackedRecommendation: TrackedRecommendation;
  recommendation: BlueRatingRecommendationDto | null;
  moduleColorTranslations: ModuleColorTranslations;
  isRecResponse?: boolean;
  moduleId?: number;
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <UWDrawer
    :drawer-title="`${trackedRecommendation.fullPosition} - ${trackedRecommendation.heading}`"
    @close-drawer="$emit('closeDrawer')"
  >
    <div class="rec-tracking-table-drawer">
      <!-- Not found on BlueRating Recommendation -->
      <BaseDataDisplayListItem :label="t('userWeb.recommendation.surveyDate')">
        <p>{{ trackedRecommendation.surveyDate }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.category')">
        <p>{{ trackedRecommendation.categoryName }}</p>
      </BaseDataDisplayListItem>

      <!-- Show available data in same order as RecommendationProperties -->
      <template v-if="isLoading || !recommendation">
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.blueRating')">
          <BaseBlueRatingStatusBadge
            :status="trackedRecommendation.color"
            :translations="moduleColorTranslations"
          />
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.surveyorStatus')">
          <BaseStatusBadge
            :status="trackedRecommendation.surveyorStatus"
            :translations="moduleColorTranslations"
          />
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.name')">
          <p>{{ trackedRecommendation.heading }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem
          :is-loading="isLoading"
          :label="t('userWeb.recommendation.location')"
        />
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.priority')">
          <p>{{ trackedRecommendation.priority }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.recommmendation')">
          <div>
            <BaseSkeleton mb="5px" height="20px" width="400px" />
            <BaseSkeleton mb="5px" height="20px" width="375px" />
            <BaseSkeleton height="20px" width="390px" />
          </div>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.comment')">
          <div>
            <BaseSkeleton mb="5px" height="20px" width="400px" />
            <BaseSkeleton mb="5px" height="20px" width="375px" />
            <BaseSkeleton height="20px" width="390px" />
          </div>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('userWeb.recommendation.type')">
          <p>{{ trackedRecommendation.type }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.images')">
          <div class="rec-tracking-table-drawer__image-skeleton">
            <BaseSkeleton height="3rem" width="3rem" />
            <BaseSkeleton height="3rem" width="3rem" />
            <BaseSkeleton height="3rem" width="3rem" />
          </div>
        </BaseDataDisplayListItem>
      </template>

      <template v-else>
        <RecommendationProperties
          :recommendation="recommendation"
          :categories="[]"
          :module-translations="moduleColorTranslations"
        />

        <RecommendationRcbaProperties :rcba="recommendation" />

        <LatestRecommendationResponse
          v-if="recommendation.responseHistory.status !== SiteResponseStatus.NotSet"
          :recommendation-response="recommendation.responseHistory"
          :currency="''"
        >
          <template #filelist="{ response }">
            <ExternalResponseFileList v-if="isRecResponse" :response="response" />
            <ResponseFileList v-else-if="moduleId" :module-id="moduleId" :response="response" />
          </template>
        </LatestRecommendationResponse>

        <LatestRiskManagerComment :comments="recommendation.riskManagerCommentHistory" />
      </template>
    </div>
  </UWDrawer>
</template>

<style scoped lang="scss">
.rec-tracking-table-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  &__image-skeleton {
    display: flex;
    gap: $spacing-4;
  }
}
</style>
