import { type Ref, reactive, computed, readonly } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import {
  type ExecutiveReportSurveyDto,
  type GetBlueRatingSummaryResponse,
  SurveyType,
} from "@/types/_generated/api";
import type { SiteBlueRatingFilter } from "../types/SiteBlueRatingFilter";
import { FilterColumn } from "../constants/FilterColumn";

export const useSiteBlueRatingFilter = (
  blueRatingSummary: Ref<GetBlueRatingSummaryResponse | null>,
) => {
  const tableFilter = reactive<SiteBlueRatingFilter>({
    surveyType: SurveyType.WEXT,
    selectedColumns: new Set([FilterColumn.Percentage]),
  });

  const updateTableFilter = (newFilter: SiteBlueRatingFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(tableFilter, newFilter)) return;

    // Update the filter state with the incoming new values
    Object.assign(tableFilter, newFilter);
  };

  const filterSurveyType = (surveys: ExecutiveReportSurveyDto[]) => {
    // "WEXT" is the default value for the surveyType filter and means no filtering
    if (tableFilter.surveyType === "WEXT") return surveys;

    return surveys.filter((survey) => survey.type === tableFilter.surveyType);
  };

  const filteredData = computed(() => {
    if (!blueRatingSummary.value) return null;

    return {
      ...blueRatingSummary.value,
      surveys: [...filterSurveyType([...blueRatingSummary.value.surveys])],
    };
  });

  const hasNoSurveys = computed(() => {
    return !filteredData.value || filteredData.value.surveys.length === 0;
  });

  return {
    filteredData: readonly(filteredData),
    hasNoSurveys: readonly(hasNoSurveys),
    tableFilter,
    updateTableFilter,
  };
};
