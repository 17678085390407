<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseInfoState, {
  type Props as BaseInfoStateProps,
} from "@/components/base/BaseInfoState/BaseInfoState.vue";

export interface Props {
  state: BaseInfoStateProps["variant"];
  customMessage?: {
    type: Props["state"];
    title: string;
    description: string;
  }[];
}

const props = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const message = computed(() => {
  const customMessageType = props.customMessage?.find((m) => m.type === props.state);

  return {
    title: customMessageType?.title ?? t(`mods.infoState.defaults.${props.state}.title`),
    description:
      customMessageType?.description ?? t(`mods.infoState.defaults.${props.state}.description`),
  };
});
</script>

<template>
  <BaseInfoState
    class="mod-info-state"
    :variant="state"
    :title="message.title"
    :description="message.description"
  />
</template>

<style lang="scss" scoped>
.mod-info-state {
  height: 100%;
}
</style>
