<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import type { ErrorLogEntryDto } from "@/types/_generated/api";
import { displayValue } from "@/helpers/displayValue";
import { formatDate } from "@/helpers/formatDate";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";

defineProps<{
  log: ErrorLogEntryDto;
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });

const showCopySuccess = ref(false);

const copyToClipboard = async (value: string) => {
  await navigator.clipboard.writeText(value);
  showCopySuccess.value = true;
  setTimeout(() => {
    showCopySuccess.value = false;
  }, 1000);
};
</script>

<template>
  <UWDrawer :drawer-title="t('common.event')" @close-drawer="$emit('closeDrawer')">
    <div class="system-error-log-drawer">
      <BaseDataDisplayList>
        <BaseDataDisplayListItem :label="t('systemLog.errorLog.timestamp')">
          <p>{{ formatDate.asDateAndTime(displayValue(log.timeStamp)) }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('systemLog.errorLog.activity')">
          <p>{{ displayValue(log.activity) }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('systemLog.errorLog.message')">
          <p>{{ displayValue(log.message) }}</p>
        </BaseDataDisplayListItem>
      </BaseDataDisplayList>

      <div class="system-error-log-drawer__exception">
        <BaseDataDisplayListTitle>
          {{ t("systemLog.errorLog.exception") }}
        </BaseDataDisplayListTitle>

        <code>{{ displayValue(log.exception) }}</code>
      </div>
    </div>

    <template #footer-left>
      <BaseButton variant="outlined" size="small" @click="copyToClipboard(log.exception)">
        <BaseIcon :icon="showCopySuccess ? 'check' : 'copy'" />
        <span>{{ t("systemLog.errorLog.copyException") }}</span>
      </BaseButton>
    </template>
  </UWDrawer>
</template>

<style scoped lang="scss">
.system-error-log-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &__exception {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;

    code {
      overflow: auto;
    }
  }
}
</style>
