<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.13172 8.17485C6.86163 7.53941 6.12692 7.24294 5.49071 7.51267L5.49069 7.51268L2.26504 8.88018C2.26504 8.88018 2.26504 8.88018 2.26503 8.88018C1.62878 9.14994 1.33196 9.88383 1.60201 10.5192L1.60202 10.5193L2.97123 13.741C2.97123 13.741 2.97123 13.741 2.97124 13.7411C3.24131 14.3765 3.97603 14.673 4.61226 14.4032L4.61227 14.4032L7.8379 13.0357C7.8379 13.0357 7.83791 13.0357 7.83791 13.0357C8.4742 12.766 8.77104 12.0321 8.50097 11.3967M8.50097 11.3966L7.13172 8.17487C7.13172 8.17486 7.13172 8.17486 7.13172 8.17485M4.90391 6.13191C6.30365 5.53847 7.91997 6.19078 8.51414 7.58873L8.51414 7.58874L9.8834 10.8105L9.8834 10.8106C10.4776 12.2087 9.82447 13.823 8.4247 14.4165L8.4247 14.4165L5.19908 15.784C3.79933 16.3774 2.18297 15.7251 1.58881 14.3272L1.5888 14.3271L0.219595 11.1054C0.219592 11.1054 0.21959 11.1053 0.219587 11.1053C-0.374567 9.70736 0.278487 8.09287 1.67823 7.49942L1.67824 7.49942L4.90389 6.13192C4.9039 6.13192 4.9039 6.13192 4.90391 6.13191Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.96473 1.76988C6.11082 -0.767514 10.7306 -0.576687 13.654 2.34315C16.782 5.46734 16.782 10.656 13.654 13.7802C13.3608 14.0731 12.8853 14.0731 12.5921 13.7802C12.2988 13.4873 12.2988 13.0125 12.5921 12.7196C15.1336 10.1812 15.1336 5.94222 12.5921 3.40381C10.2174 1.03206 6.46332 0.876173 3.90824 2.9369C3.58558 3.19713 3.11281 3.14684 2.85226 2.82457C2.59172 2.50231 2.64207 2.03011 2.96473 1.76988Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.73394 0C3.14866 0 3.48485 0.335786 3.48485 0.75V2.5H5.23697C5.65169 2.5 5.98788 2.83579 5.98788 3.25C5.98788 3.66421 5.65169 4 5.23697 4H2.73394C2.31923 4 1.98303 3.66421 1.98303 3.25V0.75C1.98303 0.335786 2.31923 0 2.73394 0Z"
    />
  </svg>
</template>

<style scoped lang="scss">
svg {
  transform: scaleX(-1);
}
</style>
