<script setup lang="ts">
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { RecommendationRiskManagerCommentDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseEmailLink from "@/components/base/BaseEmailLink.vue";
import { useRiskManagerComment } from "./composables/useRiskManagerComment";

const props = defineProps<{
  comments: RecommendationRiskManagerCommentDto[];
  title?: string;
}>();

const { t } = useI18n({ useScope: "global" });

const { lockedComments } = useRiskManagerComment(toRef(() => props.comments));

const comment = computed(() => (lockedComments.value.length ? lockedComments.value[0] : null));
</script>

<template>
  <BaseDataDisplayList v-if="comment" :title="title" class="latest-risk-manager-comment">
    <BaseDataDisplayListItem
      v-if="comment.comment"
      :label="t('blueRating.siteResponse.companyComment')"
    >
      <p>{{ comment.comment }}</p>
      <i class="latest-risk-manager-comment__by">
        <template v-if="comment.email">
          <BaseEmailLink :label="comment.email" :email="comment.email" /> -
        </template>
        {{ formatDate.asDateAndTime(comment.insertDate) }}
      </i>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>

<style lang="scss" scoped>
.latest-risk-manager-comment {
  &__by {
    display: block;
    font-size: $text-xs;
    color: $primary-6;
    margin-top: $spacing-2;
  }
}
</style>
