<template>
  <div class="base-button-row">
    <div class="base-button-row__controls">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-button-row {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 50px;
  margin: $spacing-10 0;

  &:empty {
    display: none;
  }

  &__controls {
    display: flex;
    gap: $spacing-4;
    align-items: center;
    justify-content: center;
    position: absolute;
    min-width: 100%;
  }
}
</style>
