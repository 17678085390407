import { UnwrapRef, readonly, ref } from "vue";

export type Tab<TTabName> = {
  label: string;
  name: TTabName;
  icon?: "warning";
  action?: () => void;
};

// Used to manage tabs in a component or composable
// Most of the time it's used in another composable to make typed tabs easier to work with
export const useTabs = <TTabNames>(tabs: Tab<TTabNames>[], initialActiveTab?: TTabNames) => {
  const activeTab = ref<TTabNames>(initialActiveTab ?? tabs[0].name);

  const changeTab = (tabName: UnwrapRef<TTabNames>) => {
    activeTab.value = tabName;
  };

  return {
    activeTab: readonly(activeTab),
    changeTab,
  };
};
