import { ref, readonly, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { UserWebRouteName } from "@/router/RouteName";
import { getDashboardSurveyHistoryService } from "@/services/dashboard";
import { SurveyHistoryEntryDto } from "@/types/_generated/api";
import { DashboardHistoryItem } from "../types";

const transformSurveyHistoryData = (
  moduleId: number,
  history: SurveyHistoryEntryDto[],
): DashboardHistoryItem[] => {
  return history.map((item) => ({
    site: item.siteName,
    date: formatDate.asDateOnly(item.sentDate),
    routerLink: {
      name: UserWebRouteName.Site,
      params: { siteId: item.siteId, moduleId },
    },
  }));
};

export const useGetDashboardSurveyHistory = (moduleId: number) => {
  const { t } = useI18n({ useScope: "global" });
  const isLoadingSurveyHistory = ref(false);
  const hasError = ref(false);
  const surveyHistory = ref<DashboardHistoryItem[]>([]);

  const historyColumns: { title: string; key: keyof DashboardHistoryItem }[] = [
    { title: t("userWeb.dashboard.table.site"), key: "site" },
    { title: t("userWeb.dashboard.table.date"), key: "date" },
  ];

  const getDashboardSurveyHistory = async () => {
    isLoadingSurveyHistory.value = true;
    hasError.value = false;

    try {
      const response = await getDashboardSurveyHistoryService(moduleId);

      surveyHistory.value = transformSurveyHistoryData(moduleId, response ?? []);
    } catch {
      hasError.value = true;
    } finally {
      isLoadingSurveyHistory.value = false;
    }
  };

  onMounted(() => getDashboardSurveyHistory());

  return {
    surveyHistory,
    historyColumns,
    hasError: readonly(hasError),
    isLoadingSurveyHistory: readonly(isLoadingSurveyHistory),
  };
};
