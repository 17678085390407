<template>
  <tfoot>
    <tr>
      <td></td>
    </tr>

    <slot></slot>
  </tfoot>
</template>

<style scoped lang="scss">
@import "./styles/styles";
</style>
