<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BlueColor } from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

const props = defineProps<{
  recommendationColors: Set<BlueColor>;
  translations: ModuleColorTranslations | null;
}>();

defineEmits<{
  "update:selected": [options: BlueColor[]];
}>();

const { t } = useI18n({ useScope: "global" });

const getColorLabel = (color: BlueColor) => {
  if (!props.translations) {
    return t(`common.blueColors.${color}`);
  }

  switch (color) {
    case BlueColor.Blue:
      return t("common.blueColors.blue");
    case BlueColor.Green:
      return t("common.blueColors.green");
    case BlueColor.Yellow:
      return t("common.blueColors.yellow");
    case BlueColor.Red:
      return t("common.blueColors.red");
    case BlueColor.Gray:
      return t("common.blueColors.gray");
    case BlueColor.NotSet:
      return t("common.blueColors.notSet");
    default:
      return t(`common.blueColors.${String(color)}`);
  }
};

const blueColors = Object.values(BlueColor).map((color) => ({
  value: color,
  label: getColorLabel(color),
}));
</script>

<template>
  <BaseAccordion :label="t('userWeb.recommendation.blueRating')">
    <ModMultiselect
      :options="blueColors"
      :selected-options="recommendationColors"
      @update:selected="$emit('update:selected', $event)"
    />
  </BaseAccordion>
</template>
