<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { AdminRouteName, UserWebRouteName } from "@/router/RouteName";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import { Role } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";

const { t } = useI18n({ useScope: "global" });
const auth = useAuthStore();
const app = useAppStore();
const router = useRouter();

const accept = () => {
  const routeName =
    app.user?.role === Role.EXT ? UserWebRouteName.Modules : AdminRouteName.AdminDashboard;
  auth.acceptTermsOfService().then(() => router.push({ name: routeName }));
};
</script>

<template>
  <main class="auth-terms-of-service">
    <div class="auth-terms-of-service__inner">
      <h1>Terms Of Use</h1>
      <p>
        WTW Blue ("System") is an electronic information system designed for use by clients and
        business contacts of Willis Towers Watson. All use of the System is subject to these Terms
        of Use, including the LIMITATION OF LIABILITY set out at clause 13 below. Please read these
        Terms of Use carefully. If you do not agree to be bound by these Terms of Use, you should
        cease using the System immediately. From time to time Willis Towers Watson may deem it
        necessary to revise these Terms of Use. You should access the System periodically in order
        to review any such changes. In these Terms of Use: "Content" shall mean any data posted by
        you or any other users other than Willis Towers Watson onto the System "Willis Towers
        Watson" shall mean Willis Towers Watson of 51 Lime Street, London EC3M 7DQ.
      </p>

      <h2>1. Authorisation</h2>

      <p>
        1.1 The System may be used only by persons who have been issued by Willis Towers Watson with
        written authority, including a user name and password, for access to the System. Willis
        Towers Watson may from time to time issue you with an updated password. If you have not
        received written authority from Willis Towers Watson to use the System, you must exit the
        System immediately. Where your authorisation specifies a location from which access may be
        made, access shall be made only from that location.
      </p>

      <p>
        1.2 You shall keep your password confidential at all times and use all reasonable endeavours
        to prevent unauthorised access to the System. You shall promptly inform us if you leave your
        employment or service, or if the nature of your employment or service changes so that you
        should no longer have access to the System.
      </p>

      <h2>2. Proper Use</h2>

      <p>
        2.1 You are permitted to use the System only to submit content, view, copy and print-out
        materials from the System.
      </p>

      <p>
        2.2 You will only use the System in the course of your normal internal business activities
        and not for private, personal reasons and not for the benefit of any third party.
      </p>

      <p>
        2.3 You will not hack or attempt to hack against or inside the System, inject any form of
        computer viral material or any other detrimental substance into the System or perform any
        unauthorised activities against or inside the System.
      </p>

      <p>
        2.4 No part of the System may be reproduced or stored on any other system or included in any
        public or private electronic retrieval system or services without Willis Towers Watsons'
        prior written consent.
      </p>

      <h2>3. Content</h2>

      <p>
        3.1 You must observe any standards specified by Willis Towers Watson for the format, content
        or mode of transmission of messages and for the use of Internet browsers.
      </p>

      <p>
        3.2 You must ensure that you have obtained all necessary licences and consents for any
        content you post onto the System. Willis Towers Watson shall not be responsible for the
        content of material submitted by you or any other person for use on the System and shall not
        be deemed to be the author, publisher or distributor of any such material. Willis Towers
        Watson makes no representations or warranties as to the content of the System. You should
        verify the accuracy of all content not provided by you and other third parties before
        relying on it.
      </p>

      <p>
        3.3 You must ensure that no content which you post onto the System contains any
        pornographic, obscene, offensive, indecent, abusive, menacing, unlawful, blasphemous
        materials or constitutes or may constitute an invasion of any person's privacy, an
        infringement of any person's intellectual property or other rights, contravenes data
        protection legislation or is or contains any material which is or may be considered
        defamatory, a malicious falsehood or seditions libel, a contempt of court, or anything which
        is likely to incite or be capable of inciting violence, racial hatred, sadism, cruelty or
        which may encourage any unlawful or illegal act or omission.
      </p>

      <p>
        3.4 Willis Towers Watson shall fully co-operate with any law enforcement authorities or
        court order requesting or directing Willis Towers Watson to disclose the identity or locate
        anyone posting any material in breach of any of the provisions of this clause 3.
      </p>

      <p>
        3.5 Willis Towers Watson reserves the right, at any time and from time to time, to remove
        from the System any content if it appears that by posting such content you have breached the
        provisions of clause 3.3 above, provided that Willis Towers Watson accepts no liability to
        monitor any content posted on the System.
      </p>

      <h2>4. Nature of service</h2>

      <p>
        The System is designed for sharing information and communication only. No products or
        services to which it may make reference and no statements contained on it constitute either
        an offer or an invitation to treat. Anyone reading this site is recommended to seek specific
        advice from a suitably qualified person before dealing with any situation which may be
        covered by any information contained in any part of this site or before embarking on any
        course of action. The material on this site is given for general information purpose only
        and does not constitute legal or professional advice. The views of contributors to this site
        are not necessary those of the Service Provider or any other member of the Willis Towers
        Watson Group of companies with whom you may deal.
      </p>

      <h2>5. Confidentiality, privacy and data protection</h2>

      <p>
        5.1 No details of the System which are not publicly available shall be disclosed by you to
        any third party without the prior written consent of Willis Towers Watson. All content shall
        be kept strictly confidential and shall not be disclosed to any third party without the
        written consent of Willis Towers Watson except as may be strictly necessary for the purposes
        of the proper use of the System. Users must take all reasonable steps to ensure the
        confidentiality and security of all messages and transmissions, including implementing
        up-to-date firewalls.
      </p>

      <p>
        5.2 Willis Towers Watson may collect information about your usage of the System as well as
        information about you from content you post on the System. Such information will enable
        Willis Towers Watson to provide you with access to all parts of the System and where
        applicable, supply the services you have requested. In particular, we may use your
        information to notify you about important changes and developments to the System.
      </p>

      <p>
        5.3 Information which you provide to us, including content, may be held on our computers in
        the USA and other countries outside of the EEA may be accessed by third parties some of whom
        are located in the USA and other countries outside of the EEA in order to assist us in
        providing the System to you.
      </p>

      <p>
        5.4 In making the System available to you and operating the System Willis Towers Watson
        shall at all times comply with the provisions of the General Data Protection Regulation, EU
        (2016/679) as amended from to time (”GDPR”).
      </p>

      <p>
        5.5 You warrant that, in respect of any personal data forming part of any content posted by
        you on the System, all such personal data has been collected and processed in accordance
        with the GDPR DPA, including, without limitation, any obligation to contain consent from the
        Data Subject (as defined in the GDPR) and you shall indemnify Willis Towers Watson for any
        loss or damage which may arise, directly or indirectly, as a result of your failure to
        comply with any obligation under the GDPR.
      </p>

      <p>
        5.6 In respect of any personal data posted on the System, you shall be the Data Controller
        and Willis Towers Watson shall be Data Processor (both terms as defined in the GDPR)
      </p>

      <h2>6. Compliance with legislation</h2>

      <p>
        You must comply with all relevant legislation in each country and state from which you
        access the System or which may have jurisdiction over the use of the System. If access from
        any country or state would render use or provision of the System unlawful (either on the
        part of the user or Willis Towers Watson) then such access must not be attempted.
      </p>

      <h2>7. Intellectual property</h2>

      <p>
        All intellectual property rights in the System and the content of all services comprised
        within the System are owned by the Service Provider. You are granted a licence to use the
        System solely as an information and communication service and not as an advisory service.
        You acknowledge that this licence may be withdrawn immediately in the case of any use in
        contravention of these terms. You shall ensure that you have unrestricted rights to deal
        with all content supplied by you to use on the System and that no licence shall be required
        by any person who has been granted access to WTW Blue to download that data for use on their
        own systems. The Service Provider may reproduce for the purposes of its business and the
        business of any of its associated or subsidiary companies any data supplied by users and may
        store such data without limit of time. With regard to all messages and documents placed in
        the private areas of WTW Blue no data may be shared without express written agreement
        obtained in advance from all parties except where such data is used to create a backup.
      </p>

      <h2>8. Changes</h2>

      <p>
        The System is constantly subject to development and improvement. Willis Towers Watson may
        make changes to the design of the System or to any of the services comprised within the
        System at any time.
      </p>

      <h2>9. Payment and VAT</h2>

      <p>
        Your access to the System is conditional on your compliance with these Terms of Use and
        payment in advance of any applicable access fees (as may be notified to You by Willis Towers
        Watson from time to time). Willis Towers Watson may terminate your access to the System on
        written notice if these fees are not paid when requested by Willis Towers Watson. Any
        periodic fees shall be subject to review. All sums payable under this Agreement (or any
        separate agreement as to fees or costs) ("the Sums") are exclusive of VAT or any other
        applicable tax or duty payable upon such Sums, which shall be added to the Sums (if
        applicable) at the rate prevailing at the relevant tax point. Any VAT, duty or tax payable
        shall be payable by the party responsible for paying the Sum upon which such VAT, duty or
        tax is charged. For the avoidance of doubt, this provision shall apply also to any VAT, duty
        or tax assessed as due retrospectively. "Value Added Tax" or "VAT" means Value Added Tax
        charged in accordance with the Value Added Tax Act 1994 (as amended) and all rules and
        regulations made under that Act together with any related interest penalties, fines and
        charges
      </p>

      <h2>10. Links to/from other websites</h2>

      <p>
        10.1 Links to third party websites are provided solely for your convenience. If you use any
        of these links to access those websites, you will exit the System. Willis Towers Watson has
        not reviewed all of these third party websites and does not control and is not responsible
        for such websites or their content or their availability. Willis Towers Watson makes no
        representations nor does it endorse any such websites. You access such websites entirely at
        your own risk.
      </p>

      <p>
        10.2 You agree that you will not create, post display, publish or distribute any link to any
        page of the System for any purpose without the prior written consent of Willis Towers
        Watson.
      </p>

      <h2>11. Records</h2>

      <p>
        Willis Towers Watson will comply with reasonable requests from authorised users for copies
        of documents concerning them which are held on the System, subject to its right to levy a
        reasonable charge in relation thereto but advises you that you should always maintain a
        written copy of any content as Willis Towers Watson cannot guarantee the length of time for
        which it shall maintain copies of such documents, or that it shall provide the System.
      </p>

      <h2>12. Suspension and Termination</h2>

      <p>
        12.1 Willis Towers Watson may terminate your access to the System at any time on giving to
        you 7 days' written notice. Where such notice expires before the end of any period in
        respect of which the user has made a usage payment to Willis Towers Watson then an
        appropriate refund shall be made in respect of the period during which you were unable to
        access to the System.
      </p>

      <p>
        12.2 Notwithstanding the provisions of clause 12.1 above, Willis Towers Watson may, without
        liability to you, withdraw your authorisation to use the System at any time either on a
        temporary or permanent basis if Willis Towers Watson reasonably believes that a breach of
        security or any of these Terms of Use by you has occurred or is likely to occur.
      </p>

      <p>
        12.3 Willis Towers Watson may, without liability to you, suspend access to the System at any
        time from time to time if maintenance work or updating is required to the System or if
        Willis Towers Watson considers that a breach of security or the Terms of Use by any third
        party has occurred or is likely to occur.
      </p>

      <h2>13. Liability of Willis Towers Watson</h2>

      <p>13.1 WILLIS TOWERS WATSON WARRANTS THAT:</p>

      <p>
        13.1.1 IT SHALL USE REASONABLE CARE AND SKILL IN MAKING THE SERVICE AVAILABLE TO YOU DURING
        NORMAL BUSINESS HOURS; AND
      </p>

      <p>
        13.1.2 IT IS THE OWNER OF, OR HAS THE RIGHT TO USE, ALL INTELLECTUAL PROPERTY RIGHTS IN THE
        SYSTEM. ALL OTHER WARRANTIES ARE EXPRESSLY EXCLUDED TO THE EXTENT PERMITTED BY LAW. IN
        PARTICULAR, WILLIS TOWERS WATSON MAKES NO REPRESENTATION OR WARRANTY THAT ACCESS TO THE
        SYSTEM WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT THE SYSTEM IS SUITABLE FOR YOUR
        PARTICULAR BUSINESS NEEDS. YOU SHALL BE RESPONSIBLE FOR THE CONFIGURATION OF YOUR OWN IT
        SYSTEMS TO ENABLE YOU TO ACCESS THE SYSTEM. WILLIS TOWERS WATSON SHALL HAVE NO
        RESPONSIBILITY FOR THE USE THAT YOU MAKE OF THE SYSTEM OR ANY CONTENT POSTED ON THE SYSTEM.
        WILLIS TOWERS WATSON MAKES NO REPRESENTATION OR WARRANTY OR THAT THE SYSTEM IS FREE FROM
        INFECTION BY VIRUSES OR ANYTHING ELSE THAT HAS CONTAMINATING OR DESTRUCTIVE PROPERTIES.
        WILLIS TOWERS WATSON CANNOT GUARANTEE THE SECURITY OF THE SYSTEM AS IT IS INTERNET BASED.
        WILLIS TOWERS WATSON STRONGLY RECOMMENDS YOU INSTALL UP-TO-DATE VIRUS CHECKING SOFTWARE.
      </p>

      <p>
        13.2 THE MAXIMUM AGGREGATE LIABILITY OF WILLIS TOWERS WATSON AND ANY MEMBER OF THE WILLIS
        TOWERS WATSON GROUP OF COMPANIES WITH WHOM YOU DEAL IN RELATION TO THE SYSTEM IN RESPECT OF
        ANY LOSS OR DAMAGE (WHETHER ARISING IN CONTRACT, TORT, INCLUDING NEGLIGENCE, OR OTHERWISE)
        WHICH YOU OR ANY OTHER PERSON MAY SUFFER OR INCUR ARISING IN CONNECTION WITH THIS AGREEMENT
        OR RELATING IN ANY WAY TO THE USE OF OR ACCESS TO THE SYSTEM OR SYSTEM-RELATED SERVICES
        WHICH MAY BE SUPPLIED BY WILLIS TOWERS WATSON INCLUDING, BUT NOT LIMITED TO, THOSE ARISING
        FROM ANY SYSTEM MALFUNCTIONS OR INTERRUPTIONS, PROBLEMS WITH THE INTERNET OR WILLIS TOWERS
        WATSON, THIRD PARTY ACTIONS, OR OTHER ELECTRONIC COMMUNICATION SYSTEMS PROBLEMS,
        DIFFICULTIES OR FAILURES SHALL BE LIMITED TO THE GREATER OF THREE TIMES THE ANNUAL ACCESS
        FEES PAYABLE BY YOU IN RESPECT OF THE SYSTEM OR £5,000.
      </p>

      <p>
        13.3 NOTWITHSTANDING THE ABOVE, WILLIS TOWERS WATSON SHALL NOT BE RESPONSIBLE FOR ANY BREACH
        OF THIS AGREEMENT CAUSED BY ANY CIRCUMSTANCES BEYOND ITS REASONABLE CONTROL. IN PARTICULAR,
        WILLIS TOWERS WATSON SHALL NOT BE LIABLE TO YOU FOR ANY FAILURE OR INTERRUPTION OF THE
        SYSTEM OR ANY SYSTEM RELATED SERVICES IT MAY PROVIDE TO YOU CONNECTED WITH ANY BREAKDOWN OR
        FAILURE OF THE INTERNET OR ANY TELECOMMUNICATION SYSTEMS OF ANY COMPUTER HARDWARE OR
        SOFTWARE USED BY YOU IN CONNECTION WITH THE SYSTEM.
      </p>

      <p>
        13.4 NOTHING IN THIS AGREEMENT EXCLUDES, OR IS INTENDED TO EXCLUDE, ANY LIABILITY WILLIS
        TOWERS WATSON (OR ANY RELEVANT MEMBER OF THE WILLIS TOWERS WATSON GROUP OF COMPANIES) MAY
        HAVE FOR DEATH OR PERSONAL INJURY CAUSED BY WILLIS TOWERS WATSONS' NEGLIGENCE OR FRAUD.
      </p>

      <h2>14. Amendment of Terms of Use</h2>

      <p>
        Willis Towers Watson may amend these Terms of Use at any time and from time to time on
        giving not less than 30 days' notice (which may be communicated by Willis Towers Watson
        posting a notice on the System). Continued use of the System following notice of amendment
        of these Terms of Use shall constitute your acceptance of the amended Terms of Use.
      </p>

      <h2>15. Law and jurisdiction</h2>

      These terms are governed by English law. Any dispute or difference arising out of or in
      connection with these terms or in relation to the System shall be subject to the exclusive
      jurisdiction of the English courts.

      <h2>16. General</h2>

      <p>
        16.1 Should any provision of these Terms of Use be declared invalid or unenforceable by a
        court of competent jurisdiction, such decision will not affect the validity or
        enforceability of any of the other provisions of these Terms of Use, which other provisions
        will remain in full force and effect, and the application of such invalid or unenforceable
        provisions to persons or circumstances other than those as to which it is held invalid or
        unenforceable will be valid and be enforced to the fullest extent permitted by law.
      </p>

      <p>
        16.2 Your right to use the System is personal. You may not assign, sub-license or otherwise
        transfer your right to use the System to any other person.
      </p>

      <p>
        16.3 Failure by Willis Towers Watson to exercise any right or remedy under these Terms of
        Use does not constitute a waiver of that right or remedy.
      </p>

      <p>
        16.4 The descriptive headings of these Terms of Use are intended for reference only and do
        not affect the construction or interpretation of this document.
      </p>

      <p>
        16.5 No person who is not a party to these Terms of Use shall have any right under the
        Contract (Rights of Third Parties) Act 1999 to enforce any provision of these Terms of Use
        with the exception of members of the Willis Towers Watson Group of Companies who may enforce
        provisions for their benefit.
      </p>

      <p>
        16.6 These Terms of Use constitutes the entire agreement between you and Willis Towers
        Watson in relation to its subject matter and supersedes any previous agreement or
        understanding between the parties. You acknowledge and agree that using the System and
        thereby agreeing to be bound by these Terms of Use you do not rely on, and shall have no
        remedy in respect of, any statement, representation, warranty or understanding (whether
        negligently or innocently made but excluding any liability for fraud) of any person (whether
        party to these Terms of Use or not) other than as expressly set out in these Terms of Use.
      </p>
      <div class="auth-terms-of-service__controls">
        <BaseButton variant="outlined">
          {{ t("signIn.refuse") }}
        </BaseButton>

        <BaseButton @click="accept">
          {{ t("signIn.accept") }}
        </BaseButton>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.auth-terms-of-service {
  background-color: $primary-2;
  overflow-y: auto;
  height: 100%;

  &__inner {
    background-color: $primary-0;
    margin: 0 auto;
    width: 40%;
    padding: $spacing-6;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    padding: $spacing-12 0;
  }

  h1 {
    margin-top: 0;
    font-size: 2em;
    margin-bottom: 0.67em;
    font-weight: $font-bold;
  }

  h2 {
    padding: $spacing-4 0;
    font-size: $text-xl;
    font-weight: $font-bold;
  }

  p:not(:last-of-type) {
    margin-bottom: $spacing-4;
  }
}
</style>
