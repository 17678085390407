import type { BlueRatingCategoryDto } from "@/types/_generated/api";

export const useCategoryRecommendations = () => {
  const getCategoryByRecommendation = (
    recommendationid: number,
    categories: BlueRatingCategoryDto[],
  ): BlueRatingCategoryDto | undefined =>
    categories.find((category) =>
      category.recommendations.some((rec) => rec.recommendationId === recommendationid),
    );

  const getCategoryNameByRecommendation = (
    recommendationid: number,
    categories: BlueRatingCategoryDto[],
  ): string => getCategoryByRecommendation(recommendationid, categories)?.name ?? "";

  return {
    getCategoryByRecommendation,
    getCategoryNameByRecommendation,
  };
};
