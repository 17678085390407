<script setup lang="ts">
import { computed, type DeepReadonly } from "vue";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { SiteLeanDto } from "@/types/_generated/api";
import type { CustomDefinitionKey } from "@/types/SiteDefinitions";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import ModFilterSiteDefinitionsSelect, {
  type SiteDefinitionsSelectOption,
} from "./ModFilterSiteDefinitionsSelect.vue";

type SiteDefinitionsFilter = Omit<SitesData, "sites">;

interface Props {
  sitesData: DeepReadonly<SitesData>;
  excludedSets: { [K in CustomDefinitionKey]: Set<string> };
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:excluded-sets": [key: CustomDefinitionKey, value: Set<string>];
}>();

const getSelectOptions = (customKey: CustomDefinitionKey) => {
  const uniqueOptions = new Map<string, SiteDefinitionsSelectOption>();

  for (const site of filteredSites.value) {
    const customValue = site[customKey];
    if (typeof customValue !== "string" || customValue.trim() === "") continue; // Skip if not a string or empty
    const disabled = shouldDisable(customKey, site.siteId);

    if (!uniqueOptions.has(customValue)) {
      uniqueOptions.set(customValue, {
        siteId: site.siteId,
        disabled,
        selected: !disabled && !props.excludedSets[customKey].has(customValue),
        customKey,
        customValue,
      });
    }
  }

  return [...uniqueOptions.values()];
};

const toggleDeselection = (customKey: CustomDefinitionKey, customValue: string) => {
  // Toggle the value in the Set: remove if present, add if not
  const valuesSet = props.excludedSets[customKey];

  if (valuesSet.has(customValue)) {
    valuesSet.delete(customValue);
  } else {
    valuesSet.add(customValue);
  }

  emit("update:excluded-sets", customKey, valuesSet);
};

const shouldDisable = (customKey: CustomDefinitionKey, siteId: SiteLeanDto["siteId"]) => {
  // Find the site object matching the siteId
  const site = filteredSites.value.find((site) => site.siteId === siteId);
  if (!site) return false; // Early exit if site is not found

  const customKeys: CustomDefinitionKey[] = ["custom1", "custom2", "custom3", "custom4"];

  // Find all sites that have the same value for the current custom key
  const relatedSites = filteredSites.value.filter((s) => s[customKey] === site[customKey]);

  // Check if all related sites are excluded in at least one other custom level
  const allOtherLevelsExcluded = relatedSites.every((site) =>
    customKeys.some((otherKey) => {
      if (otherKey === customKey) return false; // Skip the current custom key
      const value = site[otherKey];
      // Check if the value is in the excluded set for this custom key
      return typeof value === "string" && props.excludedSets[otherKey].has(value);
    }),
  );

  // Return true if all related sites are excluded in at least one other level, false otherwise
  return allOtherLevelsExcluded;
};

// Transform data to fit in the multiselect component
const transformedData = computed(() => {
  // Helper to check if a key is a valid siteDefinition key
  const isValidKey = (key: string): key is keyof SiteDefinitionsFilter =>
    key.startsWith("moduleSiteDefinition") || key.startsWith("siteDefinition");

  // Helper to check if a value is a non-empty string
  const isNonEmptyString = (value: unknown): value is string =>
    typeof value === "string" && value.trim() !== "";

  // Filter out invalid or empty siteDefinitions
  const validEntries = Object.entries(props.sitesData).filter(
    (entry): entry is [keyof SiteDefinitionsFilter, string] => {
      const [key, value] = entry;
      return isValidKey(key) && isNonEmptyString(value);
    },
  );

  // Create the siteDefinitions objects with its categoryName and options
  const siteDefinitions = validEntries.map(([_, categoryName], index) => ({
    categoryName,
    options: getSelectOptions(`custom${index + 1}` as CustomDefinitionKey),
  }));

  // Filter out siteDefinitions with no options
  const nonEmptySiteDefinitions = siteDefinitions.filter((def) => def.options.length > 0);

  // Reverse the array to match the desired display order
  return nonEmptySiteDefinitions.toReversed();
});

// Exclude sites with no published surveys
const filteredSites = computed(() => props.sitesData.sites.filter((site) => site.surveyCount > 0));
</script>

<template>
  <template v-for="(siteDefinition, index) in transformedData" :key="index">
    <BaseAccordion :label="siteDefinition.categoryName" expanded-by-default>
      <ModFilterSiteDefinitionsSelect
        :options="siteDefinition.options"
        @update:deselected-values="toggleDeselection"
      />
    </BaseAccordion>
  </template>
</template>
