import { ref } from "vue";
import { getIndustries, getRiskTypes } from "@/services/meta-data";
import { Option } from "@/types/Option";

export const useQuestionnaireMetaDataOptions = () => {
  const industryOptions = ref<Option[]>([]);
  const riskTypeOptions = ref<Option[]>([]);

  const fetchMetaData = async () => {
    if (!industryOptions.value.length) {
      const industries = (await getIndustries()) ?? [];
      industryOptions.value = industries.map((i) => ({
        value: i.name,
        title: i.name,
      }));
    }

    if (!riskTypeOptions.value.length) {
      const riskTypes = (await getRiskTypes()) ?? [];
      riskTypeOptions.value = riskTypes.map((r) => ({ value: r, title: r }));
    }
  };

  fetchMetaData();

  return {
    industryOptions,
    riskTypeOptions,
  };
};
