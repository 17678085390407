<script setup lang="ts">
import { computed } from "vue";
import type { TableLegend } from "@/components/shared/BlueRatingTable/types/types";
import type {
  ExecSummaryClientFilter,
  ExecSummaryServerFilter,
} from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import {
  type SearchExecutiveSummaryResponse,
  type ExecutiveReportLossScenarioDto,
  type ModuleDto,
  ExecReportSortOrder,
} from "@/types/_generated/api";
import BlueRatingTableDynamicHeight from "@/components/shared/BlueRatingTable/BlueRatingTableDynamicHeight.vue";
import BlueRatingTableLegend from "@/components/shared/BlueRatingTable/BlueRatingTableLegend.vue";
import type { ExecutiveSummaryDrawer } from "../../types/ExecSummaryDrawers";
import type { SurveyGroup } from "../../types/SurveyGroup";
import { useTableRows } from "../../composables/useTableRows";
import { DrawerOption } from "../../constants/DrawerOption";
import ExecSummaryTableGroupRow from "./ExecSummaryTableCells/ExecSummaryTableGroupRow.vue";
import ExecSummaryTableRow from "./ExecSummaryTableCells/ExecSummaryTableRow.vue";
import ExecSummaryTableFooter from "./ExecSummaryTableFooter.vue";
import ExecSummaryTableHeader from "./ExecSummaryTableHeader/ExecSummaryTableHeader.vue";

const props = defineProps<{
  legend: TableLegend | null;
  currencyCode: string;
  abeyanceShort: ModuleDto["abeyanceShort"];
  clientFilter: ExecSummaryClientFilter;
  serverFilter: ExecSummaryServerFilter;
  executiveSummary: SearchExecutiveSummaryResponse;
  groupBy?: number;
  groupedSurveys: SurveyGroup[];
  lossScenarios: ExecutiveReportLossScenarioDto[];
}>();

const emit = defineEmits<{
  openDrawer: [ExecutiveSummaryDrawer];
}>();

const { tableRows } = useTableRows(
  computed(() => Boolean(props.groupBy)),
  computed(() => props.groupedSurveys),
  computed(() => props.executiveSummary.surveys),
);

const onCellClick = (surveyId: number, index: number) => {
  emit("openDrawer", { type: DrawerOption.CatRec, params: { surveyId, index } });
};

const onHeaderClick = (categoryIndex: number) => {
  emit("openDrawer", { type: DrawerOption.CatDrilldown, params: { categoryIndex } });
};
</script>

<template>
  <div class="exec-summary-table">
    <BlueRatingTableDynamicHeight>
      <table>
        <ExecSummaryTableHeader
          v-if="executiveSummary.categories"
          :currency-code="currencyCode"
          :client-filter="clientFilter"
          :included-categories="executiveSummary.includedCategories"
          :loss-scenario-columns="lossScenarios"
          @open-drawer="onHeaderClick"
        />

        <tbody v-if="executiveSummary.surveys">
          <template v-for="(row, index) in tableRows" :key="index">
            <ExecSummaryTableGroupRow v-if="'label' in row" :label="row.label" />
            <ExecSummaryTableRow
              v-else
              :row="row.survey"
              :client-filter="clientFilter"
              :loss-scenarios="lossScenarios"
              :abeyance-short="abeyanceShort"
              @open-drawer="onCellClick($event.surveyId, $event.index)"
            />
          </template>
        </tbody>

        <ExecSummaryTableFooter
          v-if="serverFilter.sortBy !== ExecReportSortOrder.Score"
          :client-filter="clientFilter"
          :areas="executiveSummary.areas"
          :included-categories="executiveSummary.includedCategories"
          :groups="executiveSummary.groups"
          :total-average="executiveSummary.scorePercentAverage"
        />

        <BlueRatingTableLegend v-if="legend" :legend="legend" />
      </table>
    </BlueRatingTableDynamicHeight>
  </div>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";

.exec-summary-table {
  padding-top: $table-padding-top;
  overflow: auto;
}
</style>
