import { ref } from "vue";
import { useI18n } from "vue-i18n";
import type { CreateLibraryFile, LibraryFileDto } from "@/types/_generated/api";
import { toBase64 } from "@/helpers/toBase64";
import { notify } from "@/stores/notifications";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";

export const useLibraryFileUpload = (
  moduleId: number,
  form: Pick<LibraryFileDto, "language" | "isVisibleInUserWeb" | "tags">,
) => {
  const { t } = useI18n({ useScope: "global" });

  const fileUploadRef = ref<InstanceType<typeof BaseFileUpload> | null>(null);

  // Prepares the request body for file upload
  const getBody = async (
    files: FileUpload[],
  ): Promise<Record<keyof CreateLibraryFile, unknown>> => ({
    file: {
      fileName: files[0].file.name,
      file: await toBase64(files[0].file),
      fileSize: files[0].file.size,
    },
    moduleId,
    ...form,
  });

  const triggerUpload = async () => {
    try {
      if (!fileUploadRef.value) {
        throw new Error("File upload reference not found");
      }

      await fileUploadRef.value?.confirmUpload(); // Triggers the method in the file upload component
      notify.success(t("library.fileUpload.file"), t("common.uploaded").toLowerCase());
    } catch (error) {
      notify.failure(t("library.fileUpload.file"), t("common.upload").toLowerCase(), String(error));
      throw error;
    }
  };

  return {
    fileUploadRef,
    getBody,
    triggerUpload,
  };
};
