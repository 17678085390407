<script setup lang="ts">
import { computed } from "vue";
import { QuestionnaireDto } from "@/types/_generated/api";
import QuestionnaireViewerTotalScore from "./QuestionnaireViewerTotalScore.vue";

const props = defineProps<{
  questionnaire: QuestionnaireDto;
}>();

const totalBlueScore = computed(() => {
  if (!props.questionnaire) {
    return 0;
  }

  return props.questionnaire.areas
    .flatMap((area) => area.categories)
    .flatMap((category) => category.questions)
    .reduce((total, question) => total + question.blueScore, 0);
});
</script>

<template>
  <div class="questionnaire-viewer__header">
    <h2 class="questionnaire-viewer__header__title">
      {{ questionnaire.name }}
    </h2>

    <QuestionnaireViewerTotalScore :total-score="totalBlueScore" />
  </div>
</template>

<style scoped lang="scss">
.questionnaire-viewer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-size: $text-xl;
    font-weight: $font-medium;
  }
}
</style>
