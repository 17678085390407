import { reactive, readonly } from "vue";
import { type UpdateSite, type SiteDto, Status } from "@/types/_generated/api";

export const useSiteEditDrawerForm = (
  resetValidationStateField: (field: keyof UpdateSite) => void,
) => {
  const siteEditForm = reactive<UpdateSite>({
    siteId: 0,
    moduleId: 0,
    address: "",
    city: "",
    country: "",
    custom1: "",
    custom2: "",
    custom3: "",
    custom4: "",
    latitude: undefined,
    longitude: undefined,
    phone: "",
    poBox: "",
    status: Status.UnderConstruction,
    web: "",
    zipCode: "",
    surveyorId: undefined,
    nextSurveyDate: undefined,
  });

  const updateSiteEditForm = (form: SiteDto) => {
    Object.assign(siteEditForm, form);
  };

  const updateSiteEditField = <K extends keyof UpdateSite>(
    updatedField: K,
    value: UpdateSite[K],
  ) => {
    resetValidationStateField(updatedField);
    siteEditForm[updatedField] = value;
  };

  return {
    siteEditForm: readonly(siteEditForm),
    updateSiteEditForm,
    updateSiteEditField,
  };
};
