<script setup lang="ts">
import { computed, type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import { ExecReportSortOrder, ExecutiveReportLossScenarioDto } from "@/types/_generated/api";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWOptionPlaceholder from "@/components/base/UserWeb/Inputs/Select/UWOptionPlaceholder.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";

const { currentSortOrder, sortByLossScenarioId, lossScenarios } = defineProps<{
  currentSortOrder: ExecReportSortOrder;
  sortByLossScenarioId?: number;
  lossScenarios?: DeepReadonly<ExecutiveReportLossScenarioDto[]>;
}>();

const emit = defineEmits<{
  sortTable: [ExecReportSortOrder, number?];
}>();

const { t } = useI18n({ useScope: "global" });

const selectedSortOrder = computed(() => {
  if (sortByLossScenarioId) {
    return sortByLossScenarioId.toString();
  }

  return currentSortOrder.toString();
});

const onChange = (value: string) => {
  const sortOrder = value as ExecReportSortOrder;

  const simpleSortOrder = [ExecReportSortOrder.Alphabetical, ExecReportSortOrder.Score];

  if (simpleSortOrder.includes(sortOrder)) {
    emit("sortTable", sortOrder);
  } else {
    const lossScenarioId = parseInt(value);

    emit("sortTable", ExecReportSortOrder.LossScenario, lossScenarioId);
  }
};
</script>

<template>
  <UWSelect :value="selectedSortOrder" @change="onChange">
    <UWOptionPlaceholder>
      {{ t("userWeb.executiveSummary.toolbar.sortBy.title") }}
    </UWOptionPlaceholder>
    <UWOption :value="ExecReportSortOrder.Alphabetical">
      {{ t("userWeb.executiveSummary.toolbar.sortBy.alphabetical") }}
    </UWOption>
    <UWOption :value="ExecReportSortOrder.Score">
      {{ t("userWeb.executiveSummary.toolbar.sortBy.score") }}
    </UWOption>
    <UWOption
      v-for="lossScenario in lossScenarios"
      :key="lossScenario.id"
      :value="lossScenario.id.toString()"
    >
      {{ lossScenario.name }}
    </UWOption>
  </UWSelect>
</template>
