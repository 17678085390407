<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { RecommendationTrackingFilter } from "@/types/_generated/api";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWOptionPlaceholder from "@/components/base/UserWeb/Inputs/Select/UWOptionPlaceholder.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";

const props = defineProps<{
  surveyDateMin: RecommendationTrackingFilter["surveyDateMin"];
  surveyDateMax: RecommendationTrackingFilter["surveyDateMax"];
}>();

const emit = defineEmits<{
  "update:year": [string];
}>();

const { t } = useI18n({ useScope: "global" });

// Convert the survey dayes to a year
const year = computed(() => {
  const date = props.surveyDateMin ?? props.surveyDateMax;
  if (!date) return "";
  const yearNum = new Date(date).getFullYear();
  return isNaN(yearNum) ? "" : yearNum.toString();
});

// Create a list of years from 2000 to the current year
const yearOptions = computed(() => {
  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  return Array(currentYear - startYear + 1)
    .fill(0)
    .map((_, index) => (currentYear - index).toString())
    .toSorted()
    .toReversed();
});

const handleYearChange = (selectedYear: string) => {
  emit("update:year", selectedYear);
};
</script>

<template>
  <div>
    <BaseLabel has-spacing>{{ t("common.dates.year") }}</BaseLabel>
    <UWSelect :value="year" @change="handleYearChange">
      <UWOptionPlaceholder>{{ t("common.dates.yearPlaceholder") }}</UWOptionPlaceholder>
      <UWOption value="">-- {{ t("common.none") }}--</UWOption>
      <UWOption v-for="option in yearOptions" :key="option" :value="option">
        {{ option }}
      </UWOption>
    </UWSelect>
  </div>
</template>
