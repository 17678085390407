import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { SaveSpoeCheck } from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const saveSpoeCheck = async (settings: SaveSpoeCheck): Promise<boolean> => {
  try {
    await http.put(`/surveys/${settings.surveyId}/spoe-check`, settings);

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("sites.sites"),
    });
  }
  return false;
};
