<template>
  <div class="base-spinner">
    <div class="base-spinner__rings">
      <div v-for="index in 4" :key="index" class="base-spinner__ring"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-10;

  &__rings {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  &__ring {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $primary-4;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-4 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
