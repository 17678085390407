export enum FieldType {
  Text = "textfield",
  TextArea = "textarea",
  Editor = "editor",
  Checkbox = "checkbox",
  Select = "select",
}

export enum MoveDirection {
  Up = "up",
  Down = "down",
}
