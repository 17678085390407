import { ref, readonly } from "vue";
import { getBlueTrendService } from "@/services/dataAnalysis";
import { BlueTrendResultDto, IDataAnalysisFilter } from "@/types/_generated/api";

export const useGetBlueTrend = () => {
  const isLoadingBlueTrend = ref(false);
  const hasBlueTrendError = ref(false);
  const blueTrend = ref<BlueTrendResultDto[] | null>(null);

  const getBlueTrend = async (moduleId: number, filter: IDataAnalysisFilter) => {
    isLoadingBlueTrend.value = true;
    hasBlueTrendError.value = false;

    try {
      blueTrend.value = await getBlueTrendService(moduleId, filter);
    } catch {
      hasBlueTrendError.value = true;
    } finally {
      isLoadingBlueTrend.value = false;
    }
  };

  return {
    blueTrend,
    hasBlueTrendError: readonly(hasBlueTrendError),
    isLoadingBlueTrend: readonly(isLoadingBlueTrend),
    getBlueTrend,
  };
};
