<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { StandardRecommendationDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";

defineProps<{
  standardRecommendation: StandardRecommendationDto;
  isLoading: boolean;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="standard-recommendation-detail">
    <BaseDataDisplayList>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('standardRecommendations.name')">
        <p>{{ standardRecommendation.header }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        :is-loading="isLoading"
        :label="t('standardRecommendations.recommendation')"
      >
        <BaseSafeHtml
          class="standard-recommendation__general__recommendation__text"
          :html="standardRecommendation.text"
        />
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        :is-loading="isLoading"
        :label="t('standardRecommendations.country')"
      >
        <p>{{ standardRecommendation.country }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('standardRecommendations.type')">
        <p>{{ standardRecommendation.type }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('standardRecommendations.client')">
        <p>{{ standardRecommendation.clientName }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('standardRecommendations.module')">
        <p>{{ standardRecommendation.moduleName }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        :is-loading="isLoading"
        :label="t('standardRecommendations.categoryTags')"
      >
        <div class="standard-recommendation-detail__pills">
          <BasePill v-for="(category, index) in standardRecommendation?.categories" :key="index">
            <template #label>
              {{ category }}
            </template>
          </BasePill>
        </div>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        :is-loading="isLoading"
        :label="t('standardRecommendations.questionTags')"
      >
        <div class="standard-recommendation-detail__pills">
          <BasePill v-for="(question, index) in standardRecommendation?.questions" :key="index">
            <template #label>
              {{ question }}
            </template>
          </BasePill>
        </div>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>
  </div>
</template>

<style scoped lang="scss">
.standard-recommendation-detail {
  padding: $spacing-6;

  &__pills {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-2;
  }
}
</style>
