<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { ErrorObject } from "@vuelidate/core";
import type { SurveyReportRecipientDto } from "@/types/_generated/api";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import ReportMailRecipientListItem from "./ReportMailRecipientListItem.vue";

defineProps<{
  recipients: SurveyReportRecipientDto[];
  errors: ErrorObject[];
  isMobileRequired: boolean;
}>();

defineEmits<{
  "update:recipient": [{ index: number; value: SurveyReportRecipientDto }];
  "delete:recipient": [number];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <BaseCard :title="t('survey.surveyReportMail.recipients')" class="report-mail-recipient-list">
    <div class="report-mail-recipient-list__recipients">
      <ReportMailRecipientListItem
        v-for="(recipient, index) in recipients"
        :key="index"
        :recipient="recipient"
        :is-mobile-required="isMobileRequired"
        @update:recipient="$emit('update:recipient', { index, value: $event })"
        @delete:recipient="$emit('delete:recipient', index)"
      />
    </div>

    <div v-if="errors.length" class="report-mail-recipient-list__error-message">
      {{ t("survey.surveyReportMail.recipientListError") }}
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
.report-mail-recipient-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;

  &__recipients {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    min-width: 0;
  }

  &__error-message {
    margin-top: $spacing-3;
    color: $error-4;
  }
}
</style>
