import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { GetRecentActivityResponse } from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getRecentActivityService = async (
  count?: number,
): Promise<GetRecentActivityResponse | null> => {
  try {
    const { data } = await http.get<GetRecentActivityResponse>("/activities/recent", {
      params: { count },
    });
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("navigation.dashboard"),
    });
    throw error;
  }
};
