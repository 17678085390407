import { isProxy, toRaw } from "vue";

export const deepCopy = <T>(obj: T) => {
  // If object is a reactive proxy, convert it to a raw object first.
  // structuredClone cant handle proxy object
  const normalized = isProxy(obj) ? toRaw(obj) : obj;

  const clone = structuredClone(normalized);

  return clone;
};
