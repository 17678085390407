<script setup lang="ts" generic="T">
import { computed } from "vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";

const props = defineProps<{ checkedRows: T[]; rows: T[] }>();

defineEmits<{ (event: "update:checked", value: T[]): void }>();

const allChecked = computed(
  () => props.checkedRows?.length > 0 && props.checkedRows?.length === props.rows.length,
);

const id = `check-all-${Math.random()}`.replace("0.", "");
</script>

<template>
  <span class="base-grid-table__check-all">
    <BaseCheckbox
      :id="id"
      :disabled="rows.length === 0"
      :indeterminate="props.checkedRows?.length > 0 && !allChecked"
      :checked="allChecked"
      @change="$emit('update:checked', allChecked ? [] : rows)"
    />
  </span>
</template>

<style scoped lang="scss">
.base-grid-table__check-all {
  background-color: $primary-1;
  align-self: center;
  padding: $spacing-4;
  height: 100%;
  border-bottom: 1px solid $primary-3;
  position: sticky;
  top: 0;
}
</style>
