import { Ref, ref } from "vue";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { ModuleRules } from "@/constants/ModulePermission";
import { saveUserModulePermissions } from "@/services/permissions";
import {
  UserModulePermissionDto,
  SaveModulePermissionDto,
  ModuleLeanDto,
} from "@/types/_generated/api";

export type EditUserModulePermission = Omit<UserModulePermissionDto, "modulePermissionId"> &
  SaveModulePermissionDto & {
    hasAccess: boolean;
  };

export const useEditUserModulePermissions = (
  userId: number,
  modulePermissions: Ref<EditUserModulePermission[]>,
  emitClose: () => void,
) => {
  const isSavingModulePermission = ref(false);
  const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

  const toEditUserModulePermissions = (
    userId: number,
    modules: ModuleLeanDto[],
    modulePermissions: UserModulePermissionDto[],
  ): EditUserModulePermission[] =>
    modules.map((m) => {
      const mp = modulePermissions.find((mp) => mp.moduleId == m.moduleId);

      return {
        userId,
        modulePermissionId: mp?.modulePermissionId,
        moduleName: m.name,
        moduleId: m.moduleId,
        clientId: m.clientId,
        clientName: m.clientName,
        createSite: mp?.createSite,
        createSurvey: mp?.createSurvey,
        editQuestionnaire: mp?.editQuestionnaire,
        editSurvey: mp?.editSurvey,
        uploadFiles: mp?.uploadFiles,
        uploadStdRec: mp?.uploadStdRec,
        hasAccess: !!mp,
      };
    });

  const remove = (mp: EditUserModulePermission) => {
    ModuleRules.forEach((r) => (mp[r] = false));
    mp.hasAccess = false;
  };

  const add = (mp: EditUserModulePermission) => {
    mp.hasAccess = true;
  };

  const save = async () => {
    isSavingModulePermission.value = true;
    const permissionsWithAccess = modulePermissions.value.filter((mp) => mp.hasAccess);
    await saveUserModulePermissions(userId, permissionsWithAccess);
    isSavingModulePermission.value = false;

    emitClose();
    setCompareBase(permissionsWithAccess);
  };

  return {
    isSavingModulePermission,
    toEditUserModulePermissions,
    save,
    remove,
    add,
    setCompareBase,
    discardUnsavedChanges,
  };
};
