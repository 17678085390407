import { computed } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import { useQueryTyped } from "./useQueryTyped";

export enum Drawer {
  QuestionnaireChangeLogDrawer = "questionnaireChangeLogDrawer",
}

export enum BlueRatingSurveyTab {
  Survey = "survey",
  Recommendations = "recommendations",
}

export type Tab = BlueRatingSurveyTab;

export interface QueryNavigation {
  ["BlueRatingNavigation"]: {
    tab?: BlueRatingSurveyTab;
    areaId: number;
    categoryId: number;
    questionId?: number;
  };
  [Drawer.QuestionnaireChangeLogDrawer]: {
    moduleId: number;
    questionnaireId: number;
  };
}

export const useQueryNavigation = function () {
  const { currentRoute, push, replace } = useRouter();
  const currentRouteName = computed(() => currentRoute.value.name?.toString());

  const { query } = useQueryTyped();

  const changeTab = (tab: Tab) =>
    replace({
      name: currentRouteName.value,
      query: { ...currentRoute.value.query, tab },
      params: currentRoute.value.params,
    });

  const open = <T extends keyof QueryNavigation>(drawer: T, data?: QueryNavigation[T]) => {
    const route = {
      name: currentRouteName.value,
      query: { ...currentRoute.value.query, drawer },
      params: currentRoute.value.params,
    };

    if (data) {
      route.query = { ...route.query, ...data };
    }

    return push(route as RouteLocationRaw);
  };

  const pushQuery = <T extends keyof QueryNavigation>(query: QueryNavigation[T]) => {
    push({
      name: currentRouteName.value,
      params: currentRoute.value.params,
      query: { ...currentRoute.value.query, ...(query || {}) },
    });
  };

  const addQuery = <T extends keyof QueryNavigation>(query: QueryNavigation[T]) => {
    replace({
      name: currentRoute.value.name!,
      params: currentRoute.value.params,
      query: query || undefined,
    });
  };

  return {
    query,
    open,
    changeTab,
    pushQuery,
    addQuery,
  };
};
