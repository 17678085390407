<script setup lang="ts">
import LogoBlueWTWPurple from "@/assets/logos/LogoBlueWTWPurple.vue";
import { useAppStore } from "@/stores/app";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const app = useAppStore();

interface Props {
  heading?: string;
}

defineProps<Props>();
</script>

<template>
  <main class="auth-view">
    <LogoBlueWTWPurple class="auth-view__icon" />
    <h1 v-if="heading" class="auth-view__title">{{ heading }}</h1>

    <slot />

    <div class="auth-view__language">
      <BaseIcon icon="languages" size="medium" />

      <BaseSelect :value="app.settings.locale" @change="app.setAppLocale($event as string)">
        <BaseOption
          v-for="(locale, index) in app.supportedLocales"
          :key="`${index}_${locale.value}`"
          :value="locale.value"
        >
          {{ locale.title }}
        </BaseOption>
      </BaseSelect>
    </div>
  </main>
</template>

<style scoped lang="scss">
.auth-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: $spacing-8;
  max-width: 27.5rem;
  margin: 0 auto;

  &__icon {
    height: 2rem;
    flex-shrink: 0;
    margin-bottom: $spacing-8;
  }

  &__title {
    font-size: $text-2xl;
    font-weight: $font-bold;
    margin: 0;
    margin-bottom: $spacing-8;
    text-align: center;
  }

  &__language {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: $spacing-2;
    margin-bottom: $spacing-8;

    @media (max-width: $screen-md) {
      position: relative;
      margin-top: $spacing-8;
    }
  }
}
</style>
