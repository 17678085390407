import type { ExecSummaryServerFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  SearchExecutiveSummaryResponse,
  GetBlueRatingSummaryResponse,
} from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getExecSummaryService = async (
  moduleId: number,
  filter?: ExecSummaryServerFilter,
): Promise<SearchExecutiveSummaryResponse> => {
  try {
    const { data } = await http.post<SearchExecutiveSummaryResponse>(
      `/modules/${moduleId}/executive-summary`,
      {
        filter,
      },
    );

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("navigation.executiveSummary"),
    });
    throw error;
  }
};

export const getBlueRatingSummaryService = async (
  siteId: number,
): Promise<GetBlueRatingSummaryResponse> => {
  try {
    const { data } = await http.get<GetBlueRatingSummaryResponse>(
      `/sites/${siteId}/blue-rating-summary`,
    );
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("sites.blueRating"),
    });
    throw error;
  }
};
