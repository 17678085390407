import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { Tab, useTabs } from "@/composables/useTabs";
import { RecommendationDrawerTabs } from "../constants/RecommendationDrawerTabs";

export const useBlueRatingRecommendationDrawerTabs = (
  tabsWithErrors: Readonly<Ref<ReadonlySet<RecommendationDrawerTabs>>>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const getTabIcon = (tab: RecommendationDrawerTabs) =>
    tabsWithErrors.value.has(tab) ? "warning" : undefined;

  const tabs = computed<Tab<RecommendationDrawerTabs>[]>(() => [
    {
      label: t("blueRating.recommendation.general"),
      name: RecommendationDrawerTabs.General,
      icon: getTabIcon(RecommendationDrawerTabs.General),
    },
    {
      label: t("blueRating.recommendation.uploadPhotos"),
      name: RecommendationDrawerTabs.Images,
      icon: getTabIcon(RecommendationDrawerTabs.Images),
    },
    {
      label: t("blueRating.recommendation.costBenefitAnalysis"),
      name: RecommendationDrawerTabs.CostBenefit,
      icon: getTabIcon(RecommendationDrawerTabs.CostBenefit),
    },
    {
      label: t("blueRating.recommendation.recommendationResponse.summary"),
      name: RecommendationDrawerTabs.SiteResponseSummary,
      icon: getTabIcon(RecommendationDrawerTabs.SiteResponseSummary),
    },
    {
      label: t("blueRating.recommendation.recommendationResponse.history"),
      name: RecommendationDrawerTabs.SiteResponseHistory,
      icon: getTabIcon(RecommendationDrawerTabs.SiteResponseHistory),
    },
  ]);

  const { activeTab, changeTab } = useTabs(tabs.value, RecommendationDrawerTabs.General);

  return {
    activeTab,
    changeTab,
    tabs,
  };
};
