import { ref } from "vue";
import type { UserDto } from "@/types/_generated/api";
import { getUserService } from "@/services/users";

export const useGetUser = () => {
  const isLoadingUser = ref(false);
  const userDto = ref<UserDto | null>(null);

  const getUser = async (userId: number) => {
    isLoadingUser.value = true;

    const user = await getUserService(userId);
    userDto.value = user;
    isLoadingUser.value = false;

    if (!user) {
      throw Error("User not found");
    }

    return user;
  };

  return {
    isLoadingUser,
    userDto,
    getUser,
  };
};
