<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Option } from "@/types/Option";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  clientId?: number;
  clientOptions: Option[];
  disabled?: boolean;
  errorMessage?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:value": [number | undefined];
}>();

const errors = computed(() => {
  return props.errorMessage ? [{ $message: props.errorMessage } as ErrorObject] : [];
});
</script>

<template>
  <BaseSelect
    :label="t('standardRecommendations.client')"
    :value="clientId"
    :disabled="disabled"
    :errors="errors"
    @change="emit('update:value', Number($event))"
  >
    <BaseOption :value="''">
      {{ t("common.select") }}
    </BaseOption>
    <BaseOption v-for="option in clientOptions" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
