import { nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/stores/app";
import type { MapPin } from "../types";
import { defaultCoordinates } from "../constants/defaultCoordinates";
import { loadScript } from "../helpers/loadScript";

// Declare the global initializeMap function
declare global {
  interface Window {
    initializeMap: () => void;
  }
}

// Global callback function to initialize the map
window.initializeMap = () => {
  throw new Error("Map initialization not bound");
};

// Helper function to get map options
const getMapOptions = (coordinates: MapPin[]): Microsoft.Maps.IMapLoadOptions => {
  if (coordinates.length > 0) {
    return {
      center: new Microsoft.Maps.Location(coordinates[0].latitude, coordinates[0].longitude),
    };
  }
  return {
    center: new Microsoft.Maps.Location(defaultCoordinates.longitude, defaultCoordinates.latitude),
  };
};

// Helper function to check if a coordinate is valid
const isValidCoordinate = (pin: MapPin): boolean => {
  const { latitude, longitude } = pin;

  const isLatitudeValid = typeof latitude === "number" && latitude >= -90 && latitude <= 90;
  const isLongitudeValid = typeof longitude === "number" && longitude >= -180 && longitude <= 180;

  // The coordinate is valid only if both latitude and longitude are valid
  return isLatitudeValid && isLongitudeValid;
};

export const useMap = () => {
  const { t } = useI18n({ useScope: "global" });
  const app = useAppStore();

  const isLoadingMap = ref(false);
  const map = ref<Microsoft.Maps.Map | null>();
  const pins = ref<Microsoft.Maps.Pushpin[]>([]);

  // Helper function to create the map
  const createMap = (id: string, pins: MapPin[]): string | undefined => {
    try {
      const options = getMapOptions(pins);
      map.value = new Microsoft.Maps.Map(`#${id}`, options);
      return undefined;
    } catch (error) {
      return (error as Error)?.message ?? t("map.status.unknownError");
    } finally {
      isLoadingMap.value = false;
      // Reset the global callback
      window.initializeMap = () => {
        throw new Error("Map initialization not bound");
      };
    }
  };

  /**
   * Asynchronously load the Bing Maps API and create a map instance.
   * Note: Loading multiple maps in parallel is not supported.
   * @param id HTML element id (without leading #)
   * @param pin Array of MapPin to initialize the map
   * @returns
   */
  const loadMap = async (id: string, pins: MapPin[] = []): Promise<string | undefined> => {
    // Check if the Bing Maps API key is set
    if (!app.settings.bingMapKey) {
      return t("map.status.noApiKey");
    }

    isLoadingMap.value = true;

    if (window.Microsoft?.Maps?.Map) {
      await nextTick();
      return createMap(id, pins);
    }

    return new Promise((resolve) => {
      loadScript(async () => {
        await nextTick();
        resolve(createMap(id, pins));
      }, app.settings.bingMapKey);
    });
  };

  // Helper function to add a single pin
  const addPin = (
    mapPin: MapPin,
    index: number,
    isMovable: boolean,
    onMove?: (index: number, latitude: number, longitude: number) => void,
    onClickPin?: (coord: MapPin) => void,
    clickablePins?: boolean,
  ) => {
    const location = new Microsoft.Maps.Location(mapPin.latitude, mapPin.longitude);

    const $default = "#9333ea";

    const pin = new Microsoft.Maps.Pushpin(location, {
      draggable: isMovable,
      visible: true,
      title: "",
      color: $default,
      cursor: clickablePins ? "pointer" : "default",
    });

    map.value?.entities.push(pin);
    pins.value.push(pin);

    if (isMovable && onMove) {
      Microsoft.Maps.Events.addHandler(pin, "dragend", (e: Microsoft.Maps.IMouseEventArgs) =>
        onMove(index, e.location.latitude, e.location.longitude),
      );
    }

    Microsoft.Maps.Events.addHandler(pin, "mouseover", () => {
      pin.setOptions({ title: mapPin.pinText });
    });

    Microsoft.Maps.Events.addHandler(pin, "mouseout", () => {
      pin.setOptions({ title: "" });
    });

    if (onClickPin) {
      Microsoft.Maps.Events.addHandler(pin, "click", () => {
        onClickPin(mapPin);
      });
    }
  };

  // Helper function to set the map view
  const setMapView = (pins: MapPin[]) => {
    if (!map.value || pins.length === 0) return;

    const locations = pins.map((pin) => new Microsoft.Maps.Location(pin.latitude, pin.longitude));

    // Set view based on number of pins
    if (pins.length === 1) {
      // For a single pin, set the center and use a default zoom level
      map.value.setView({ center: locations[0] });
    } else {
      // For multiple pins, fit the view to include all pins
      map.value.setView({ bounds: Microsoft.Maps.LocationRect.fromLocations(locations) });
    }
  };

  /**
   * Set multiple pins on the map or update their locations.
   * @param coordinates Array of coordinates
   * @param isMovable Whether pins are movable
   * @param onMove Callback function when a pin is moved
   */
  const setPins = (
    mapPins: MapPin[],
    isMovable: boolean,
    onMove?: (index: number, latitude: number, longitude: number) => void,
    onClickPin?: (pin: MapPin) => void,
    clickablePins?: boolean,
  ): void => {
    if (!map.value) return;

    // Clear existing pins
    map.value.entities.clear();
    pins.value = [];

    // Filter out invalid coordinates
    const validCoordinates = mapPins.filter((pin) => isValidCoordinate(pin));

    // Add new pins for valid coordinates
    validCoordinates.forEach((coord, index) =>
      addPin(coord, index, isMovable, onMove, onClickPin, clickablePins),
    );

    // Set map view based on valid coordinates
    setMapView(validCoordinates);
  };

  return {
    map,
    isLoadingMap,
    loadMap,
    setPins,
  };
};
