<script setup lang="ts">
import { computed } from "vue";
import type {
  ExecutiveReportInsuredValueDto,
  ExecutiveReportLossScenarioDto,
} from "@/types/_generated/api";
import { toInteger } from "@/helpers/formatNumber";

interface Props {
  lossScenarios: ExecutiveReportLossScenarioDto[];
  insuredValues: ExecutiveReportInsuredValueDto[];
}

const props = defineProps<Props>();

const filteredInsuredValues = computed(() =>
  props.insuredValues.filter((insuredValue) =>
    props.lossScenarios.some((lossScenario) => lossScenario.id === insuredValue.lossScenarioId),
  ),
);
</script>

<template>
  <template v-for="(insuredValue, index) in filteredInsuredValues" :key="index">
    <td :data-dark="index % 2 === 0" data-right-align>
      {{ insuredValue.pd ? toInteger(insuredValue.pd) : "" }}
    </td>
    <td :data-dark="index % 2 === 0" data-right-align>
      {{ insuredValue.bi ? toInteger(insuredValue.bi) : "" }}
    </td>
    <td :data-dark="index % 2 === 0" data-right-align>
      {{ insuredValue.pdBi ? toInteger(insuredValue.pdBi) : "" }}
    </td>
  </template>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
