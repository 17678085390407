import dayjs from "dayjs";
import { DateFormat } from "@/constants/DateFormat";

const format = (date: Date | string | undefined, format: DateFormat) =>
  date === undefined ? "" : dayjs(date).format(format);

const asDateOnly = (date: Date | string | undefined) => format(date, DateFormat.Iso8601DateOnly);

const asDateAndTime = (date: Date | string | undefined) =>
  format(date, DateFormat.Iso8601DateAndTime);

const fromNow = (date: Date | string | undefined, noSuffix?: boolean) =>
  date === undefined ? "" : dayjs(date).fromNow(noSuffix);

export const formatDate = {
  fromNow,
  format,
  asDateOnly,
  asDateAndTime,
};
