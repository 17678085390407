import { computed, onMounted, onUnmounted, ref } from "vue";
import { useAuthStore } from "@/stores/auth";

export default function useDesign() {
  const auth = useAuthStore();

  const breakpoints = {
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
    "3xl": 1920,
    "4xl": 2560,
  };

  const defaults = {
    navigationHeight: 64,
    mobileMenuHeight: 64,
    impersonationHeight: 30,
  };

  // Track window inner width
  const windowInnerWidth = ref(window.innerWidth);
  const setInnerWidth = () => (windowInnerWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", setInnerWidth));
  onUnmounted(() => window.removeEventListener("resize", setInnerWidth));

  // Media queries
  const isWidthLessThan = (breakpoint: keyof typeof breakpoints) =>
    windowInnerWidth.value < breakpoints[breakpoint];

  // Element heights
  const impersonationHeight = computed(() =>
    auth.isImpersonated ? defaults.impersonationHeight : 0,
  );

  const navigationHeight = computed(() =>
    isWidthLessThan("md")
      ? defaults.mobileMenuHeight + defaults.navigationHeight
      : defaults.navigationHeight,
  );

  const topHeight = computed(() => navigationHeight.value + impersonationHeight.value);

  return {
    mediaQuery: { isWidthLessThan },
    defaults: {
      navigationHeight: `${defaults.navigationHeight}px`,
      mobileMenuHeight: `${defaults.mobileMenuHeight}px`,
      impersonationHeight: `${defaults.impersonationHeight}px`,
    },
    navigationHeight: computed(() => `${navigationHeight.value}px`),
    impersonationHeight: computed(() => `${impersonationHeight.value}px`),
    topHeight: computed(() => `${topHeight.value}px`),
  };
}
