<script setup lang="ts">
import { ref, watch } from "vue";
import { vTruncationTitle } from "@/directives/truncationTitle";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";

const props = defineProps<{
  isLoading: boolean;
  title: string;
  percentage: number | null;
}>();

const displayedPercentage = ref(0);

const animateValue = (start: number, end: number, duration: number) => {
  let startTimestamp: number | null = null;
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    displayedPercentage.value = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

watch(
  () => [props.isLoading, props.percentage],
  () => animateValue(0, props.percentage ?? 0, 300),
  { immediate: true },
);
</script>

<template>
  <div class="dashboard-percentage">
    <dt v-truncation-title class="dashboard-percentage__title">{{ title }}</dt>
    <dd v-if="!isLoading" class="dashboard-percentage__number">{{ displayedPercentage }}%</dd>
    <BaseSkeleton v-else width="4rem" height="2.15rem" />
  </div>
</template>

<style scoped lang="scss">
.dashboard-percentage {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  border: 1px solid $primary-4;
  border-radius: $rounded-base;
  padding: $spacing-6;

  &__title {
    font-size: $text-sm;
    font-weight: $font-semibold;
    flex-shrink: 0;
  }

  &__number {
    font-size: $text-3xl;
    font-weight: $font-semibold;
    letter-spacing: -0.025em;
    color: $secondary-4;
  }
}
</style>
