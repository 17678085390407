import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useModuleTranslations } from "@/composables/useModuleTranslations";
import { SurveyorStatus, ModuleDto } from "@/types/_generated/api";

export const useSurveyorStatus = (module: ModuleDto) => {
  const { t } = useI18n({ useScope: "global" });
  const { moduleTranslations } = useModuleTranslations(toRef(module));

  const getSurveyorStatus = (status: SurveyorStatus) => {
    // Use custom translation for Abeyance
    if (status === SurveyorStatus.Abeyance) {
      return moduleTranslations.value?.abeyanceTranslation;
    }
    return t(`common.statuses.${status}`);
  };

  return {
    getSurveyorStatus,
  };
};
