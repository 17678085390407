<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetUsers } from "@/composables/services/useGetUsers";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { modulePermissionEvents } from "@/services/permissions";
import { userEvents } from "@/services/users";
import { useAppStore } from "@/stores/app";
import { UserLeanDto } from "@/types/_generated/api";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseEmailLink from "@/components/base/BaseEmailLink.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import UserLink from "@/components/shared/UserLink.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";
import { useClientUsersTableColumns } from "../composables/useClientUsersTableColumns";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  clientId: number;
}>();

const users = ref<UserLeanDto[]>([]);
const showLockedAndArchived = ref(false);

const app = useAppStore();

const { getUsers, isLoadingUsers } = useGetUsers();

useBroadcastService(modulePermissionEvents, async () => loadUsers());
useBroadcastService(userEvents, async () => loadUsers());

const loadUsers = async () => {
  users.value = (await getUsers({ clientId: props.clientId })) || [];
};

onBeforeMount(async () => loadUsers());

const { clientUsersTableColumns } = useClientUsersTableColumns(showLockedAndArchived);

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(users, clientUsersTableColumns, [
    (item) => showLockedAndArchived.value || (!item.lockedDate && !item.isArchived),
  ]);

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="clientUsersTableColumns"
    :is-loading="isLoadingUsers"
    storage-key="clientUsersTable"
    :default-sort="[{ key: 'fullName' }]"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
      <BaseCheckbox
        v-if="app.isAdmin"
        id="show-locked-out-archived"
        :checked="showLockedAndArchived"
        :label="t('users.showLockedOut')"
        @change="showLockedAndArchived = !showLockedAndArchived"
      />
    </template>

    <template #header-fullName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #column-fullName="{ row }">
      <UserLink
        class="base-table__custom-cell"
        :label="row.fullName"
        :user-id="row.id"
        :user-role="row.role"
        @click="openUserDetailDrawer(row.id)"
      />
    </template>

    <template #column-email="{ row }">
      <BaseEmailLink class="base-table__custom-cell" :email="row.email" />
    </template>
  </BaseGridTable>

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>
