import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  StandardRecommendationLeanDto,
  SearchStandardRecommendationsResponse,
  StandardRecommendationDto,
  GetStandardRecommendationResponse,
  CreateStandardRecommendation,
  UpdateStandardRecommendation,
  CopyToStandardRecommendation,
  StandardRecommendationFilter,
  SaveStandardRecImages,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const sdtRecommendationEvents = new BroadcastService("standard-recommendations");

const { t } = i18n.global;

export const searchStandardRecommendationsService = async (
  filter?: StandardRecommendationFilter,
): Promise<StandardRecommendationLeanDto[] | null> => {
  try {
    const {
      data: { standardRecommendations },
    } = await http.post<SearchStandardRecommendationsResponse>(`/standard-recommendations/search`, {
      filter,
    });

    return standardRecommendations;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }

  return null;
};

export const getStandardRecommendation = async (
  standardRecommendationId: number,
): Promise<StandardRecommendationDto | null> => {
  try {
    const {
      data: { standardRecommendation },
    } = await http.get<GetStandardRecommendationResponse>(
      `/standard-recommendations/${standardRecommendationId}`,
    );

    return standardRecommendation || null;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }

  return null;
};

export const createStandardRecommendation = async (
  standardRecommendation: CreateStandardRecommendation,
): Promise<number | null> => {
  try {
    const {
      data: { standardRecommendationId },
    } = await http.post(`/standard-recommendations`, standardRecommendation);

    sdtRecommendationEvents.post({
      action: "create",
      id: standardRecommendationId,
    });

    notify.success(
      t("standardRecommendations.standardRecommendation.standardRecommendation"),
      t("common.created").toLowerCase(),
    );

    return standardRecommendationId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }

  return null;
};

export const updateStandardRecommendation = async (
  standardRecommendation: UpdateStandardRecommendation,
): Promise<boolean> => {
  try {
    const {
      data: { standardRecommendationId },
    } = await http.put(
      `/standard-recommendations/${standardRecommendation.standardRecommendationId}`,
      standardRecommendation,
    );

    sdtRecommendationEvents.post({
      action: "update",
      id: standardRecommendationId,
    });
    notify.success(
      t("standardRecommendations.standardRecommendation.standardRecommendation"),
      t("common.updated").toLowerCase(),
    );
    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }
  return false;
};

export const deleteStandardRecommendation = async (standardRecommendationId: number) => {
  try {
    await http.delete(`/standard-recommendations/${standardRecommendationId}`);

    sdtRecommendationEvents.post({
      action: "delete",
      id: standardRecommendationId,
    });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }
};

export const copyToStandardRecommendation = async (
  dto: CopyToStandardRecommendation,
): Promise<number | null> => {
  try {
    const {
      data: { standardRecommendationId },
    } = await http.post(`/recommendations/${dto.recommendationId}/standard-recommendations`, dto);

    sdtRecommendationEvents.post({
      action: "create",
      id: standardRecommendationId,
    });

    notify.success(
      t("standardRecommendations.standardRecommendation.standardRecommendation"),
      t("common.created").toLowerCase(),
    );

    return standardRecommendationId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }

  return null;
};

export const saveStandardRecImagesService = async (req: SaveStandardRecImages): Promise<null> => {
  try {
    const { data } = await http.put(
      `/standard-recommendations/${req.standardRecommendationId}/images`,
      req,
    );

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("standardRecommendations.standardRecommendation.standardRecommendation"),
    });
  }
  return null;
};
