<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
defineProps<{ folded: boolean }>();
defineEmits<{ (event: "fold"): void; (event: "unfold"): void }>();
</script>

<template>
  <BaseButton
    :title="folded ? t('blueRating.nav.unfold') : t('blueRating.nav.fold')"
    variant="text"
    @click="folded ? $emit('unfold') : $emit('fold')"
  >
    <BaseIcon :icon="folded ? 'unfold' : 'fold'" />
  </BaseButton>
</template>
