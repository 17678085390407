<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { getModulePermissions, getSitePermissions } from "@/services/permissions";
import { BaseModulePermissionDto, SitePermissionDto, Role } from "@/types/_generated/api";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import UserLink from "@/components/shared/UserLink.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";
import { useSiteUsersTableColumns } from "../composables/useSiteUsersTableColumns";

export type SiteUserRow = SitePermissionDto & Omit<BaseModulePermissionDto, "moduleId">;

const { moduleId, siteId } = defineProps<{ moduleId: number; siteId: number }>();

const rows = ref<SiteUserRow[]>([]);
const { columns } = useSiteUsersTableColumns();
const isLoading = ref(false);

onBeforeMount(async () => {
  isLoading.value = true;

  const [sitePermissions, modulePermissions] = await Promise.all([
    getSitePermissions(siteId),
    await getModulePermissions({ moduleId, onlyActive: true }),
  ]);

  for (const mp of modulePermissions || []) {
    const isExt = mp.userRole === Role.EXT;
    const isExtWithoutSitePermission =
      isExt && !sitePermissions?.some((s) => s.userId === mp.userId);

    // EXT must have explicit permissions for site
    if (isExtWithoutSitePermission) {
      continue;
    }

    const isImplicit = isExt
      ? sitePermissions?.find((sp) => sp.userId === mp.userId)?.isImplicit
      : false;

    const row = {
      userId: mp.userId,
      userFullName: mp.userName,
      userRole: mp.userRole,
      siteId,
      isImplicit: isImplicit === true,
      createSite: mp.createSite,
      createSurvey: mp.createSurvey,
      editQuestionnaire: mp.editQuestionnaire,
      editSurvey: mp.editSurvey,
      uploadFiles: mp.uploadFiles,
      uploadStdRec: mp.uploadStdRec,
    };

    rows.value.push(row);
  }

  isLoading.value = false;
});

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(rows, columns);

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();
</script>

<template>
  <BaseGridTable
    enable-row-click
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="sitePermissionsTable"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>
    <template #column-userFullName="{ row }">
      <UserLink
        :user-id="row.userId"
        :user-role="row.userRole"
        :label="row.userFullName"
        @click="openUserDetailDrawer"
      />
    </template>
  </BaseGridTable>

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>
