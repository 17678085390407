<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <span :title="t('sites.reminderEmailCount')">
    <BaseIcon icon="email" size="medium" />
  </span>
</template>
