import { reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { Drawer, Tab } from "../query-navigation/useQueryNavigation";

export const useQueryTyped = function () {
  const route = useRoute();

  const query = reactive<{
    drawer?: Drawer;
    clientId?: number;
    recommendationId?: number;
    userId?: number;
    userName?: string;
    moduleId?: number;
    siteId?: number;
    surveyId?: number;
    tab?: Tab;
    serviceMessageId?: number;
    questionnaireId?: number;
    standardRecommendationId?: number;
  }>({});

  watch(
    route,
    ({
      query: {
        drawer,
        clientId,
        userId,
        recommendationId,
        moduleId,
        tab,
        surveyId,
        serviceMessageId,
        questionnaireId,
        standardRecommendationId,
      },
    }) => {
      query.drawer = drawer as Drawer;
      query.clientId = parseInt(clientId as string);
      query.userId = parseInt(userId as string);
      query.recommendationId = parseInt(recommendationId as string);
      query.moduleId = parseInt(moduleId as string);
      query.tab = tab as Tab;
      query.surveyId = parseInt(surveyId as string);
      query.questionnaireId = parseInt(questionnaireId as string);
      query.standardRecommendationId = parseInt(standardRecommendationId as string);
      query.serviceMessageId =
        typeof serviceMessageId === "undefined" ? undefined : parseInt(serviceMessageId as string);
    },
  );

  return {
    query,
  };
};
