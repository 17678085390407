<script setup lang="ts">
export interface Props {
  checked: boolean;
  disabled?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  change: [value: boolean];
}>();

const onChange = (event: Event) => {
  const input = event.target as HTMLInputElement;
  emit("change", input.checked);
};
</script>

<template>
  <label class="base-checkbox" :class="{ 'base-checkbox--disabled': disabled }">
    <input
      class="base-checkbox__input"
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @change="onChange"
    />
    <slot />
  </label>
</template>

<style lang="scss" scoped>
.base-checkbox {
  display: flex;
  align-items: center;
  gap: $spacing-2;
  user-select: none;

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__input {
    display: inline-block;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    background-origin: border-box;
    border: 1px solid $primary-8;
    border-radius: $rounded-base;
    appearance: none;

    &:checked {
      background-color: $secondary-4;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-color: transparent;
    }
  }
}
</style>
