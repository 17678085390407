import Squire from "squire-rte";
import { Editor } from "../constants";

export const useToolbarActions = (editor: Squire | null) => {
  const executeCommand = (command: Editor.Command, attribute = "") => {
    if (!editor) return;

    switch (command) {
      case Editor.Command.Bold:
        if (editor.hasFormat(Editor.Format.Bold)) {
          editor.removeBold();
        } else {
          editor.bold();
        }
        break;
      case Editor.Command.Italic:
        if (editor.hasFormat(Editor.Format.Italic)) {
          editor.removeItalic();
        } else {
          editor.italic();
        }
        break;
      case Editor.Command.Underline:
        if (editor.hasFormat(Editor.Format.Underline)) {
          editor.removeUnderline();
        } else {
          editor.underline();
        }
        break;
      case Editor.Command.Strikethrough:
        if (editor.hasFormat(Editor.Format.Strikethrough)) {
          editor.removeStrikethrough();
        } else {
          editor.strikethrough();
        }
        break;
      case Editor.Command.TextSize:
        if (attribute) editor.setFontSize(attribute);
        break;
      case Editor.Command.TextColor:
        if (attribute) editor.setTextColor(attribute);
        break;
      case Editor.Command.UnorderedList:
        if (editor.hasFormat(Editor.Format.UnorderedList)) {
          editor.removeList();
        } else {
          editor.makeUnorderedList();
        }
        break;
      case Editor.Command.OrderedList:
        if (editor.hasFormat(Editor.Format.OrderedList)) {
          editor.removeList();
        } else {
          editor.makeOrderedList();
        }
        break;
      case Editor.Command.ClearFormatting:
        editor.removeAllFormatting();
        break;
      case Editor.Command.Image:
        if (attribute) {
          editor.insertImage(attribute, {
            alt: "",
            style:
              "display: block; max-width: 100%; height: auto; margin: 1em auto; text-align: center;",
            align: "center",
          });
        }
        break;
      default:
        throw new Error(`Unknown editor command: ${command}`);
    }
  };

  return { executeCommand };
};
