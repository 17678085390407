<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <p class="dashboard-table-empty">
    {{ t("userWeb.dashboard.noData") }}
  </p>
</template>

<style scoped lang="scss">
.dashboard-table-empty {
  font-size: $text-sm;
  color: $primary-6;
  padding: $spacing-3;
  text-align: center;
}
</style>
