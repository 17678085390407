<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import ModuleLibrary from "@/components/shared/ModuleLibrary/ModuleLibrary.vue";

defineProps<{ moduleId: number }>();

const { t } = useI18n({ useScope: "global" });
const { setBreadcrumbs } = useBreadcrumbs();

setBreadcrumbs([{ label: t("navigation.library") }]);
</script>

<template>
  <UserWebViewLayout>
    <ModuleLibrary :module-id="moduleId" :is-user-web="true" />
  </UserWebViewLayout>
</template>
