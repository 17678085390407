<script setup lang="ts">
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { siteResponseSortOrder } from "@/constants/SortOrder";
import {
  BlueColor,
  RecommendationTrackingFilter,
  SiteResponseStatus,
} from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import { BlueRatingStatusColor } from "@/components/base/BaseChart/constants/colors";
import { useTrackedRecsGroupedBySiteResponseStatus } from "../composables/useTrackedRecsGroupedBySiteResponseStatus";
import RecTrackingStackBarChart from "./RecTrackingStackBarChart.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  chartData: TrackedRecommendation[];
  filter: RecommendationTrackingFilter;
  translations: ModuleColorTranslations;
}>();

const { statusData } = useSiteResponseStatusChartMetaData();
const { trackedRecsGroupedBySiteResponseStatus } = useTrackedRecsGroupedBySiteResponseStatus(
  toRef(props.chartData),
);

const BLUE_RATINGS: BlueColor[] = Object.values(BlueColor).filter((color) =>
  props.chartData.some((rec) => rec.color === color),
);

const orderedStatuses = computed(
  () => Object.keys(siteResponseSortOrder).reverse() as SiteResponseStatus[],
);

const chartColors = computed(() => {
  const colors: Record<string, string> = {};

  BLUE_RATINGS.forEach((color) => {
    colors[color] = BlueRatingStatusColor[color];
  });

  return colors;
});

const chartLabels = computed(() => {
  return orderedStatuses.value.map((status) => statusData[status].label);
});

const getLegendText = (color: BlueColor) => {
  switch (color) {
    case BlueColor.Blue:
      return t("userWeb.recommendationTracking.siteResponseBlueRatingChart.legend.blue");
    case BlueColor.Green:
      return t("userWeb.recommendationTracking.siteResponseBlueRatingChart.legend.green");
    case BlueColor.Yellow:
      return t("userWeb.recommendationTracking.siteResponseBlueRatingChart.legend.yellow");
    case BlueColor.Red:
      return t("userWeb.recommendationTracking.siteResponseBlueRatingChart.legend.red");
    case BlueColor.Gray:
      return t("userWeb.recommendationTracking.siteResponseBlueRatingChart.legend.gray");
    case BlueColor.NotSet:
      return t("userWeb.recommendationTracking.siteResponseBlueRatingChart.legend.notSet");
    default:
      return "Unknown color";
  }
};

const chartSeries = computed(() => {
  return BLUE_RATINGS.map((color) => ({
    key: color,
    label: getLegendText(color),
  }));
});

const generatedChartData = computed(() => {
  const statusCount = orderedStatuses.value.length;
  // Initialize a 2D array: rows for BLUE_RATINGS, columns for statuses
  const data: number[][] = [...Array(BLUE_RATINGS.length)].map(() => Array(statusCount).fill(0));

  orderedStatuses.value.forEach((status, statusIndex) => {
    const dataForStatus = trackedRecsGroupedBySiteResponseStatus.value[status];

    dataForStatus.forEach((item) => {
      const ratingIndex = BLUE_RATINGS.indexOf(item.color);
      if (ratingIndex !== -1) {
        data[ratingIndex][statusIndex]++;
      }
    });
  });

  return data;
});
</script>

<template>
  <RecTrackingStackBarChart
    :colors="chartColors"
    :series="chartSeries"
    :labels="chartLabels"
    :data="generatedChartData"
  />
</template>
