<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetSite } from "@/composables/services/useGetSite";
import { AdminRouteName, UserWebRouteName } from "@/router/RouteName";
import { useAppStore } from "@/stores/app";
import { Status } from "@/types/_generated/api";
import SiteEditDrawer from "@/views/Admin/Sites/components/SiteEditDrawer/SiteEditDrawer.vue";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import SurveyEditDrawer from "./components/SurveyEditDrawer/SurveyEditDrawer.vue";

const props = defineProps<{
  moduleId: number;
  siteId: number;
}>();

const app = useAppStore();
const { t } = useI18n({ useScope: "global" });
const { module, getModule } = useGetModule();
const { siteData: site, getSite } = useGetSite();

const editSite = ref<number | null>(null);
const showSurveyEditDrawer = ref(false);

const TABS: NavTab[] = [
  { title: t("sites.surveys"), path: AdminRouteName.SiteSurveys },
  { title: t("navigation.sitePermissions"), path: AdminRouteName.SitePermissionTable },
  { title: t("sites.exemptions.exemptions"), path: AdminRouteName.SiteExemptionsTable },
  { title: t("navigation.details"), path: AdminRouteName.SiteDetails },
];

const breadcrumbs = computed(() => [
  {
    title: module.value?.clientName,
    route: {
      name: AdminRouteName.Client,
      params: { clientId: module.value?.clientId || 0 },
    },
  },
  {
    title: module.value?.name,
    route: {
      name: AdminRouteName.Module,
      params: { moduleId: module.value?.moduleId },
    },
  },
  { title: site.value?.name || t("sites.site") },
]);

const showUserWebButton = computed(
  () => module.value?.status === Status.Published && site.value?.status === Status.Published,
);

onBeforeMount(async () => {
  await getModule(props.moduleId);
  await getSite(props.siteId);
});
</script>

<template>
  <AdminHeader
    :breadcrumbs="breadcrumbs"
    :user-web="
      showUserWebButton && {
        name: UserWebRouteName.Site,
        params: { moduleId, siteId },
      }
    "
  />

  <AdminViewLayout :tabs="TABS">
    <template #controls>
      <BaseButton
        v-if="$authorize.hasModulePermission(moduleId, 'createSite')"
        variant="outlined"
        @click="() => (editSite = siteId)"
      >
        {{ t("sites.editSiteDrawer.editSite") }}
      </BaseButton>

      <BaseButton
        v-if="$authorize.hasModulePermission(moduleId, 'createSurvey')"
        @click="showSurveyEditDrawer = true"
      >
        {{ t("survey.createNewSurvey") }}
      </BaseButton>
    </template>

    <router-view />

    <SiteEditDrawer
      v-if="editSite"
      :site-id="editSite"
      :module-id="props.moduleId"
      @close-drawer="editSite = null"
    />

    <SurveyEditDrawer
      v-if="showSurveyEditDrawer"
      :module-id="moduleId"
      :site-id="siteId"
      :current-user-id="app.user?.id"
      @close="showSurveyEditDrawer = false"
    />
  </AdminViewLayout>
</template>
