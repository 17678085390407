<script setup lang="ts">
import { BarChart, PieChart, LineChart, ScatterChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { use, type EChartsCoreOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";

use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  CanvasRenderer,
  BarChart,
  PieChart,
  LineChart,
  ScatterChart,
]);

defineProps<{
  isLoading?: boolean;
  option: EChartsCoreOption;
}>();
</script>

<template>
  <v-chart :option="option" autoresize :loading="isLoading" />
</template>
