<script setup lang="ts" generic="T">
import { computed } from "vue";
import type { Sorting } from "@/helpers/sort";
import { SortOrder } from "@/constants/SortOrder";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const props = defineProps<{
  column: ColumnDefinition<T>;
  sortKey?: Sorting<T>["key"];
  sortOrder: SortOrder;
}>();

const icon = computed(() => {
  if (props.sortOrder === SortOrder.None || props.sortOrder === SortOrder.Ascending) {
    return "arrow-up";
  } else if (props.sortOrder === SortOrder.Descending) {
    return "arrow-down";
  }
  return null;
});
</script>

<template>
  <span v-show="sortKey === column?.key" class="base-grid-table-header-sort">
    <BaseIcon v-if="icon" :icon="icon" />
  </span>
</template>

<style scoped lang="scss">
.base-grid-table-header-sort {
  color: $primary-5;
  margin-left: $spacing-2;
}
</style>
