import { ref } from "vue";
import { getModuleSettings as getModuleSettingsService } from "@/services/modules";

export const useGetModuleSettings = () => {
  const isLoadingModuleSettings = ref(false);

  const getModuleSettings = async (moduleId: number) => {
    isLoadingModuleSettings.value = true;

    const moduleSettings = await getModuleSettingsService(moduleId);

    isLoadingModuleSettings.value = false;

    return moduleSettings;
  };

  return { getModuleSettings, isLoadingModuleSettings };
};
