import type { CustomFilterFunc, FilterKey, FilterResult, ValueFunc } from "@/types/Models";

const compareValues = (value: string, searchPhrase: string) =>
  value.toLowerCase().includes(searchPhrase.toLowerCase());

const getCompareValue = (value: object, getValue?: ValueFunc) => String(getValue?.(value) || value);

const isMatch = (searchPhrase: string, value: object, getValue?: ValueFunc) => {
  if (searchPhrase == "") {
    return true;
  }

  if (Array.isArray(value)) {
    return value.some((v) => compareValues(getCompareValue(v, getValue), searchPhrase));
  }

  return compareValues(getCompareValue(value, getValue), searchPhrase);
};

export const filter = <T extends object>(
  items: T[],
  searchPhrase: string,
  searchKey?: FilterKey<T>,
  searchableKeys?: FilterKey<T>[],
  customFilters?: CustomFilterFunc<T>[],
): FilterResult<T> => ({
  all: items,
  result: items
    .filter((item) => !customFilters || customFilters.some((cf) => cf(item)))
    .filter((item) => {
      let entries = Object.entries(item);

      if (searchableKeys) {
        entries = entries.filter(([k]) => searchableKeys.some((sk) => sk.name === k));
      }

      if (searchKey) {
        entries = entries.filter(([k]) => k === searchKey?.name);
      }

      return entries.some(([key, value]) =>
        isMatch(searchPhrase, value, searchableKeys?.find((c) => c.name === key)?.getCompareValue),
      );
    }),
});
