<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.09502 4.29289C7.28255 4.10536 7.53691 4 7.80212 4C8.06734 4 8.32169 4.10536 8.50923 4.29289C8.69677 4.48043 8.80212 4.73478 8.80212 5C8.80212 5.26522 8.69677 5.51957 8.50923 5.70711C8.32169 5.89464 8.06734 6 7.80212 6C7.53691 6 7.28255 5.89464 7.09502 5.70711C6.90748 5.51957 6.80212 5.26522 6.80212 5C6.80212 4.73478 6.90748 4.48043 7.09502 4.29289Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.80212 1.5C5.86087 1.5 4.30212 3.05263 4.30212 4.95C4.30212 5.5431 4.51095 6.19239 4.87525 6.86314C5.23722 7.52958 5.72929 8.17428 6.23736 8.74511C6.7435 9.31377 7.252 9.7941 7.6349 10.1327C7.69391 10.1849 7.74981 10.2336 7.80212 10.2786C7.85443 10.2336 7.91034 10.1849 7.96935 10.1327C8.35224 9.7941 8.86075 9.31377 9.36689 8.74511C9.87496 8.17428 10.367 7.52958 10.729 6.86314C11.0933 6.19239 11.3021 5.5431 11.3021 4.95C11.3021 3.05263 9.74337 1.5 7.80212 1.5ZM7.80212 11.25C7.34654 11.8458 7.34639 11.8457 7.34622 11.8455L7.3447 11.8444L7.34132 11.8418L7.33003 11.833L7.28997 11.8017C7.25576 11.7747 7.20685 11.7357 7.14535 11.6855C7.02241 11.5852 6.84874 11.4399 6.64123 11.2564C6.22724 10.8903 5.67325 10.3675 5.11689 9.74239C4.56246 9.11947 3.99203 8.37979 3.55712 7.57905C3.12455 6.78261 2.80212 5.8819 2.80212 4.95C2.80212 2.20737 5.04938 0 7.80212 0C10.5549 0 12.8021 2.20737 12.8021 4.95C12.8021 5.8819 12.4797 6.78261 12.0471 7.57905C11.6122 8.37979 11.0418 9.11947 10.4874 9.74239C9.931 10.3675 9.377 10.8903 8.96302 11.2564C8.7555 11.4399 8.58184 11.5852 8.4589 11.6855C8.39739 11.7357 8.34849 11.7747 8.31428 11.8017L8.27422 11.833L8.26292 11.8418L8.25955 11.8444L8.25843 11.8452C8.25827 11.8453 8.25771 11.8458 7.80212 11.25ZM7.80212 11.25L8.25843 11.8452C7.98952 12.0509 7.61514 12.0512 7.34622 11.8455L7.80212 11.25Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.59881 9.0101C1.19011 8.94274 0.804187 9.21944 0.736824 9.62814L0.0246236 13.9491C0.0246223 13.9491 0.0246211 13.9491 0.0246198 13.9491C-0.0153186 14.1914 -0.00663722 14.4403 0.050457 14.6793C0.107561 14.9183 0.212629 15.1454 0.362252 15.3433C0.512022 15.5413 0.703972 15.7066 0.928078 15.8223C1.15258 15.9382 1.40149 16.0002 1.65637 16.0001C2.07059 16 2.40632 15.6642 2.40625 15.25C2.40618 14.8358 2.07034 14.5 1.65613 14.5001C1.64553 14.5001 1.63218 14.4977 1.61622 14.4895C1.59986 14.481 1.579 14.4654 1.55866 14.4385C1.53817 14.4114 1.51998 14.375 1.50941 14.3308C1.49883 14.2865 1.49707 14.2391 1.50465 14.1931L1.50465 14.1931L2.21685 9.87209C2.28422 9.46339 2.00751 9.07746 1.59881 9.0101Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4012 9.0101C14.8099 8.94274 15.1958 9.21944 15.2632 9.62814L15.9754 13.9491C15.9754 13.9491 15.9754 13.9491 15.9754 13.9491C16.0153 14.1914 16.0066 14.4403 15.9495 14.6793C15.8924 14.9183 15.7874 15.1454 15.6377 15.3433C15.488 15.5413 15.296 15.7066 15.0719 15.8223C14.8474 15.9382 14.5985 16.0002 14.3436 16.0001C13.9294 16 13.5937 15.6642 13.5938 15.25C13.5938 14.8358 13.9297 14.5 14.3439 14.5001C14.3545 14.5001 14.3678 14.4977 14.3838 14.4895C14.4001 14.481 14.421 14.4654 14.4413 14.4385C14.4618 14.4114 14.48 14.375 14.4906 14.3308C14.5012 14.2865 14.5029 14.2391 14.4954 14.1931L14.4953 14.1931L13.7831 9.87209C13.7158 9.46339 13.9925 9.07746 14.4012 9.0101Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.90625 15.25C0.90625 14.8358 1.24204 14.5 1.65625 14.5H14.3438C14.758 14.5 15.0938 14.8358 15.0938 15.25C15.0938 15.6642 14.758 16 14.3438 16H1.65625C1.24204 16 0.90625 15.6642 0.90625 15.25Z"
    />
  </svg>
</template>
