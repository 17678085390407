import { ref } from "vue";
import { QuestionnaireDto } from "@/types/_generated/api";

type SelectType = "area" | "category" | "question";

// Define the SelectsTemplates type
export type TemplateSelection = {
  type: SelectType;
  id: number;
};

export const selectedTemplateParts = ref<TemplateSelection[]>([]);

export const toggleSelected = (
  type: SelectType,
  id: number,
  checked: boolean,
  questionnaire: QuestionnaireDto | undefined,
) => {
  if (!questionnaire || !checked) {
    removeById(type, id, questionnaire);
    return;
  }

  selectedTemplateParts.value.push(
    { type, id },
    ...getChildren(id, type, questionnaire),
    ...getParents(id, type, questionnaire),
  );

  // remove duplicates
  selectedTemplateParts.value = selectedTemplateParts.value.filter(
    (template, index, self) =>
      self.findIndex((t) => t.type === template.type && t.id === template.id) === index,
  );
};

const getChildren = (
  id: number,
  type: SelectType,
  questionnaire: QuestionnaireDto,
): TemplateSelection[] => {
  if (type === "question") {
    return [];
  }

  if (type === "category") {
    const category = getCategory(id, questionnaire);

    if (!category) {
      return [];
    }

    return category.questions.map((question) => ({
      type: "question",
      id: question.questionId,
    }));
  }

  if (type === "area") {
    const area = getArea(id, questionnaire);

    if (!area) {
      return [];
    }

    return [
      ...area.categories.map((category) => ({
        type: "category",
        id: category.categoryId,
      })),
      ...area.categories
        .map((category) => getChildren(category.categoryId, "category", questionnaire))
        .flat(),
    ] as TemplateSelection[];
  }

  return [];
};

const getParents = (
  id: number,
  type: SelectType,
  questionnaire: QuestionnaireDto,
): TemplateSelection[] => {
  if (type === "question") {
    const question = getQuestion(id, questionnaire);

    if (!question) {
      return [];
    }

    const category = getCategory(question.categoryId, questionnaire);

    if (!category) {
      return [];
    }

    const area = getArea(category.areaId, questionnaire);

    if (!area) {
      return [{ type: "category", id: category.categoryId }];
    }

    return [
      { type: "area", id: area.areaId },
      { type: "category", id: category.categoryId },
    ];
  }

  if (type === "category") {
    const category = getCategory(id, questionnaire);

    if (!category) {
      return [];
    }

    const area = getArea(category.areaId, questionnaire);

    if (!area) {
      return [];
    }

    return [{ type: "area", id: area.areaId }];
  }

  return [];
};

const removeById = (type: SelectType, id: number, questionnaire: QuestionnaireDto | undefined) => {
  if (!questionnaire) {
    return;
  }

  removeTemplates([{ type, id }, ...getChildren(id, type, questionnaire)]);
};

const getQuestion = (id: number, questionnaire: QuestionnaireDto) =>
  questionnaire.areas
    .flatMap((area) => area.categories.flatMap((category) => category.questions))
    .find((question) => question.questionId === id);

const getCategory = (id: number, questionnaire: QuestionnaireDto) =>
  questionnaire.areas
    .flatMap((area) => area.categories)
    .find((category) => category.categoryId === id);

const getArea = (id: number, questionnaire: QuestionnaireDto) =>
  questionnaire.areas.find((area) => area.areaId === id);

const removeTemplates = (templatesToRemove: TemplateSelection[]) => {
  selectedTemplateParts.value = selectedTemplateParts.value.filter(
    (template) =>
      !templatesToRemove.some(
        (removeTemplate) =>
          template.type === removeTemplate.type && template.id === removeTemplate.id,
      ),
  );
};

export const isSelectedToTemplate = (type: SelectType, id: number) =>
  selectedTemplateParts.value.some((template) => template.id === id && template.type === type);
