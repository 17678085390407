import { computed, type Ref } from "vue";
import { customLocaleCompare } from "@/helpers/customLocaleCompare";
import type { MultiselectOption } from "../types";

export const useMultiselectSort = <T>(
  options: Ref<MultiselectOption<T>[]>,
  disableSorting: boolean,
) => {
  const sortedOptions = computed(() => {
    if (disableSorting) {
      // Return options as is, without sorting
      return options.value;
    } else {
      // Sort options alphabetically by name
      return options.value.toSorted((a, b) => customLocaleCompare(a.label, b.label));
    }
  });

  return {
    sortedOptions,
  };
};
