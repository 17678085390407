// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Editor {
  export enum Command {
    Bold = "bold",
    Italic = "italic",
    Underline = "underline",
    Strikethrough = "strikethrough",
    TextColor = "textColor",
    UnorderedList = "unorderedList",
    OrderedList = "orderedList",
    ClearFormatting = "removeAllFormatting",
    TextSize = "textSize",
    Image = "image",
  }

  export enum Format {
    Bold = "B",
    Italic = "I",
    Underline = "U",
    Strikethrough = "S",
    UnorderedList = "UL",
    OrderedList = "OL",
  }

  export enum Popover {
    Form = "form",
    List = "list",
    ImageUpload = "imageUpload",
  }
}
