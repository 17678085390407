import { i18n } from "@/i18n";
import { Language } from "@/types/_generated/api";

const { t } = i18n.global;

export const getLanguage = (languageId: number | Language) => {
  if (typeof languageId === "number") {
    switch (languageId) {
      case 1:
        return t("common.languages.en");
      case 2:
        return t("common.languages.se");
      case 3:
        return t("common.languages.no");
      case 4:
        return t("common.languages.da");
      case 5:
        return t("common.languages.nl");
      case 6:
        return t("common.languages.ru");
      default:
        return "";
    }
  }

  switch (languageId) {
    case Language.English:
      return t("common.languages.en");
    case Language.Swedish:
      return t("common.languages.se");
    case Language.Norwegian:
      return t("common.languages.no");
    case Language.Danish:
      return t("common.languages.da");
    case Language.Dutch:
      return t("common.languages.nl");
    case Language.Russian:
      return t("common.languages.ru");
    default:
      return "";
  }
};
