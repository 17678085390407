<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useArchivedStatusFilter } from "@/composables/useArchivedStatusFilter";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useTableFilter } from "@/composables/useTableFilter";
import { AdminRouteName } from "@/router/RouteName";
import { clientEvents, getClientsService } from "@/services/clients";
import { useAppStore } from "@/stores/app";
import { ClientDto } from "@/types/_generated/api";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";

defineProps<{
  isAdmin: boolean;
}>();

defineEmits<{
  "create-client": [void];
  "edit-client": [number];
}>();

const app = useAppStore();

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const showArchived = ref(false);
const clients = ref<ClientDto[]>([]);

useBroadcastService(clientEvents, async () => {
  clients.value = (await getClientsService()) || [];
});

const { filterArchived, archivedMenuOption } = useArchivedStatusFilter(showArchived);

onBeforeMount(async () => {
  isLoading.value = true;

  clients.value = (await getClientsService()) || [];

  isLoading.value = false;
});

const columns: ColumnDefinition<ClientDto>[] = [
  new ColumnDefinition({ title: t("common.name"), key: "name" }),
  new ColumnDefinition({ title: t("clients.office"), key: "willisOffice" }),
  new ColumnDefinition({
    title: t("clients.country"),
    key: "willisCountryOrUnit",
  }),
  new ColumnDefinition({
    title: t("common.status"),
    key: "status",
    maxWidth: "140px",
  }),
  new ColumnDefinition({
    title: t("clients.modules"),
    key: "moduleCount",
    maxWidth: "120px",
  }),
  new ColumnDefinition({
    title: t("clients.sites"),
    key: "siteCount",
    autoWidth: true,
  }),
];

const title = t("navigation.goTo", {
  entity: t("clients.client").toLowerCase(),
});

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(clients, columns, [filterArchived]);
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :is-loading="isLoading"
    :columns="columns"
    storage-key="clientsTable"
    :default-sort="[{ key: 'name' }]"
    :custom-options="app.isAdmin ? [archivedMenuOption] : []"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <BaseButton v-if="isAdmin" @click="$emit('create-client')">
        {{ t("clients.newClient") }}
      </BaseButton>
    </template>

    <template #column-name="{ cell, row: { clientId } }">
      <router-link
        :to="{
          name: AdminRouteName.Client,
          params: { clientId: clientId },
        }"
        :title="title"
      >
        {{ cell }}
      </router-link>
    </template>

    <template #column-status="{ row }">
      <BaseStatusBadge :status="row.status" />
    </template>

    <template #actions="{ row: { clientId } }">
      <EditIconButton
        v-if="app.isAdmin"
        :title="t('clients.edit')"
        @click="$emit('edit-client', clientId)"
      />
    </template>
  </BaseGridTable>
</template>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
</style>
