<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseEntityLink from "../base/BaseEntityLink.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{ label: string; clientId: number }>();

defineEmits<{
  click: [clientId: number];
}>();
</script>

<template>
  <BaseEntityLink
    :label="label"
    :title="t('navigation.entityLink.clientDetails')"
    @click="$emit('click', clientId)"
  >
    <template #icon>
      <BaseIcon icon="info" />
    </template>
  </BaseEntityLink>
</template>
