<script setup lang="ts">
import { computed } from "vue";
import { vTruncationTitle } from "@/directives/truncationTitle";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import { useRecResponseSurveyInfo } from "./composables/useRecResponseSurveyInfo";

const { surveyInfo } = useRecResponseSurveyInfo();

const headers = computed(() => {
  const titles = [surveyInfo.moduleName, surveyInfo.siteName, surveyInfo.surveyDate];

  for (let i = 0; i < titles.length; i += 1) {
    if (titles[i].length === 0) {
      return titles.slice(0, i);
    }
  }
  return titles;
});
</script>

<template>
  <BaseIcon
    v-if="headers.length"
    icon="divider"
    size="medium"
    class="rec-resp-sub-header__divider"
  />
  <h2
    v-for="(title, index) in headers"
    :key="`rec-resp-sub-header_${index}`"
    v-truncation-title
    class="rec-resp-sub-header__title"
  >
    {{ title }}
  </h2>
</template>

<style scoped lang="scss">
.rec-resp-sub-header {
  &__title {
    font-size: $text-sm;
    font-weight: $font-medium;
  }

  &__divider {
    margin: 0 $spacing-4;
  }
}
</style>
