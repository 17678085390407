<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useSearchRecommendationTracking } from "@/composables/services/useSearchRecommendationTracking";
import { RecRespRouteName } from "@/router/RouteName";
import { RecResponseRespondStatus, type RecResponseSummaryDto } from "@/types/_generated/api";
import { useRecResponseSurveyInfo } from "@/components/app/RecResponse/composables/useRecResponseSurveyInfo";
import RecResponseViewLayout from "@/components/app/RecResponse/RecResponseViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseNavTabs, { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import { RecTrackingFilter } from "@/components/shared/RecTracking/composables/useFilterRecTracking";
import { useRecResponseSurvey } from "../Respond/composables/useRecResponseSurvey";
import SummaryDetailedRecTracking from "./components/SummaryDetailedRecTracking.vue";
import SummaryFiles from "./components/SummaryFiles.vue";
import SummaryOverviewRecTracking from "./components/SummaryOverviewRecTracking.vue";
import { getRecResponseSummaryService } from "./services/recResponse";

const router = useRouter();
const { t } = useI18n({ useScope: "global" });

const isLoadingSummary = ref(false);

//Get the default filter from useFilterRecTracking.ts
const activeFilter = ref<RecTrackingFilter>(new RecTrackingFilter());

const summary = ref<RecResponseSummaryDto | null>(null);
const { submitRecResponses } = useRecResponseSurvey();
const { setSurveyInfo } = useRecResponseSurveyInfo();
const { convertToTrackedRecommendations } = useSearchRecommendationTracking();

const trackedRecs = computed(() =>
  summary.value ? convertToTrackedRecommendations(summary.value.recTracking) : [],
);

const TABS: NavTab[] = [
  {
    title: t("userWeb.recommendationTracking.overview"),
    path: RecRespRouteName.SummaryOverview,
  },
  {
    title: t("userWeb.recommendationTracking.detailed"),
    path: RecRespRouteName.SummaryDetailed,
  },
];

const statusMessage = computed(() => {
  switch (summary.value?.surveyInfo.responseStatus) {
    case RecResponseRespondStatus.NeedsResponse:
      return t("recommendationResponse.responseStatus.needsResponse");
    case RecResponseRespondStatus.Submitted:
      return t("recommendationResponse.responseStatus.submitted");
    case RecResponseRespondStatus.NeedsSubmit:
      return t("recommendationResponse.responseStatus.needsSubmit");
    default:
      return "";
  }
});

onMounted(async () => {
  isLoadingSummary.value = true;
  summary.value = (await getRecResponseSummaryService()) ?? null;

  if (summary.value) {
    setSurveyInfo(summary.value.surveyInfo);
  }

  isLoadingSummary.value = false;
});

const submitRecommendationResponses = async () => {
  isLoadingSummary.value = true;

  await submitRecResponses();
  summary.value = (await getRecResponseSummaryService()) ?? null;

  isLoadingSummary.value = false;
};
</script>

<template>
  <RecResponseViewLayout v-if="!isLoadingSummary && summary" class="view-rec-resp-summary">
    <SummaryFiles
      v-if="summary.report || summary.attachments"
      :report="summary.report"
      :attachments="summary.attachments"
    />

    <div v-if="summary.showResponse" class="view-rec-resp-summary__response">
      <p class="view-rec-resp-summary__response__header">
        {{ statusMessage }}
      </p>

      <BaseButton
        v-if="summary.surveyInfo.responseStatus === RecResponseRespondStatus.NeedsResponse"
        @click="router.push({ name: RecRespRouteName.Respond })"
      >
        {{ t("recommendationResponse.respondToRecommendations") }}
      </BaseButton>

      <div
        v-else-if="
          [RecResponseRespondStatus.NeedsSubmit, RecResponseRespondStatus.Submitted].includes(
            summary.surveyInfo.responseStatus,
          )
        "
        class="view-rec-resp-summary__response__buttons"
      >
        <BaseButton variant="outlined" @click="router.push({ name: RecRespRouteName.Respond })">
          {{ t("recommendationResponse.respondToRecommendations") }}
        </BaseButton>
        <!-- If status is submited show re-submit text instead of submit -->
        <BaseButton @click="submitRecommendationResponses">
          {{
            summary.surveyInfo.responseStatus === RecResponseRespondStatus.Submitted
              ? t("recommendationResponse.reSubmitRecommendationResponse")
              : t("recommendationResponse.submitRecommendationResponse")
          }}
        </BaseButton>
      </div>
    </div>

    <hr v-if="summary.showResponse" />

    <BaseNavTabs class="view-rec-resp-summary__rec-tracking" :tabs="TABS">
      <SummaryDetailedRecTracking
        v-if="router.currentRoute.value.name === RecRespRouteName.SummaryDetailed"
        :is-loading="isLoadingSummary"
        :module-color-translations="summary.moduleTranslations"
        :module-currency="summary.moduleCurrency"
        :tracked-recommendations="trackedRecs"
        :blue-rating-recommendations="summary.recommendations"
      />

      <SummaryOverviewRecTracking
        v-if="router.currentRoute.value.name === RecRespRouteName.SummaryOverview"
        :filter="activeFilter"
        :recommendation-tracking="trackedRecs"
        :module-translations="summary.moduleTranslations"
      />
    </BaseNavTabs>
  </RecResponseViewLayout>
</template>

<style scoped lang="scss">
.view-rec-resp-summary {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;

  &__response {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__header {
      font-size: $text-base;
      font-weight: $font-semibold;
      margin-bottom: $spacing-2;
      white-space: nowrap;
    }

    &__buttons {
      display: flex;
      gap: $spacing-4;
    }
  }

  &__rec-tracking {
    display: block;
  }
}
</style>
