// Helper function to load the Bing Maps script
export const loadScript = (callback: () => void, key?: string) => {
  const script = document.createElement("script");
  script.src = `https://www.bing.com/api/maps/mapcontrol?callback=initializeMap&key=${key}`;
  script.async = true;
  script.defer = true;
  script.type = "text/javascript";
  document.body.appendChild(script);
  window.initializeMap = callback;
};
