import { computed, Ref } from "vue";
import { useGetClients } from "@/composables/services/useGetClients";
import { useGetModules } from "@/composables/services/useGetModules";

export const useClientAndModuleOptions = (clientId: Ref<number | undefined>) => {
  const { getClients, clients, isLoadingClients } = useGetClients();
  const { getModules, modules, isLoadingModules } = useGetModules();

  const isLoadingOptions = computed(() => isLoadingClients.value || isLoadingModules.value);

  const loadOptions = async () => {
    await Promise.all([getClients(), getModules()]);
  };

  const clientOptions = computed(() =>
    (clients.value ?? []).map((client) => ({
      title: client.name,
      value: client.clientId,
    })),
  );

  const moduleOptions = computed(() => {
    let allModules = modules.value ?? [];

    if (clientId.value) {
      allModules = allModules.filter((module) => module.clientId === clientId.value);
    }

    return allModules.map((module) => ({
      title: module.name,
      value: module.moduleId,
    }));
  });

  return {
    isLoadingOptions,
    clientOptions,
    moduleOptions,
    loadOptions,
  };
};
