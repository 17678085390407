<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { Option } from "@/types/Option";
import { toCamelCase } from "@/helpers/toCamelCase";
import { LossEstimateType } from "@/types/_generated/api";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

defineProps<{
  value: LossEstimateType;
  disabled?: boolean;
}>();

defineEmits<{
  update: [LossEstimateType];
}>();

const { t } = useI18n({ useScope: "global" });

const lossEstimateOptions: readonly Option[] = [
  {
    title: t(`enums.lossEstimateType.${toCamelCase(LossEstimateType.Monetary)}`),
    value: LossEstimateType.Monetary,
  },
  {
    title: t(`enums.lossEstimateType.${toCamelCase(LossEstimateType.Percent)}`),
    value: LossEstimateType.Percent,
  },
];
</script>

<template>
  <BaseSelect
    :label="t('enums.lossEstimateTypeLabel')"
    :value="value"
    :disabled="disabled"
    @change="($event: string) => $emit('update', $event as LossEstimateType)"
  >
    <BaseOption v-for="option in lossEstimateOptions" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
