<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.950627 3.39C1.34089 3.52 1.77117 3.31 1.90125 2.92L2.39158 1.5H2.41159L1.24082 0.33L1.14075 0.51L0.480317 2.44C0.350231 2.83 0.56037 3.26 0.950627 3.39ZM6.2141 5.29L3.08203 14.5H0.750495C0.340225 14.5 0 14.84 0 15.25C0 15.66 0.340225 16 0.750495 16H6.49429C6.90456 16 7.24478 15.66 7.24478 15.25C7.24478 14.84 6.90456 14.5 6.49429 14.5H4.67309L7.40489 6.48L6.22411 5.3L6.2141 5.29ZM14.1994 1.5L13.8792 2.44C13.841 2.55278 13.8303 2.67302 13.8479 2.79075C13.8655 2.90849 13.9109 3.02036 13.9803 3.11709C14.0497 3.21383 14.1412 3.29266 14.2472 3.34707C14.3531 3.40149 14.4705 3.42991 14.5896 3.43C14.8998 3.43 15.19 3.23 15.3001 2.92L15.9605 0.99C15.9987 0.877216 16.0094 0.756984 15.9918 0.639246C15.9742 0.521508 15.9288 0.409645 15.8594 0.312907C15.79 0.21617 15.6985 0.137336 15.5925 0.0829253C15.4866 0.0285145 15.3692 8.93756e-05 15.2501 0H3.53233L5.03332 1.5H7.50495L6.87454 3.34L8.05532 4.52L9.086 1.5H14.1994Z"
    />
    <path
      d="M15.2281 16C15.1264 16.0005 15.0256 15.9806 14.9316 15.9416C14.8376 15.9026 14.7524 15.8452 14.6809 15.7729L0.224589 1.31892C-0.0748628 1.01952 -0.0748628 0.523955 0.224589 0.224552C0.52404 -0.0748507 1.01968 -0.0748507 1.31914 0.224552L15.7754 14.6785C16.0749 14.9779 16.0749 15.4735 15.7754 15.7729C15.7039 15.8452 15.6187 15.9026 15.5247 15.9416C15.4307 15.9806 15.3299 16.0005 15.2281 16Z"
    />
  </svg>
</template>
