<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { ModuleForm, ErrorMessage, UpdateModuleField } from "./types";

const { t } = useI18n({ useScope: "global" });

const { moduleForm } = defineProps<{
  moduleForm: ModuleForm;
  errorMessage: ErrorMessage;
}>();

const emit = defineEmits<{
  "update:field": UpdateModuleField;
}>();

const definitionNumbers: CustomDefinitionNumber[] = [1, 2, 3, 4];

// Site definitions in use
const siteDefinitionsInUse = ref<number>(
  computed(
    () =>
      [
        moduleForm.siteDefinition1,
        moduleForm.siteDefinition2,
        moduleForm.siteDefinition3,
        moduleForm.siteDefinition4,
      ].filter((x) => !!x).length,
  ).value,
);

// Site definitions to display in the UI
const activeDefinitionNumbers = computed(() =>
  definitionNumbers.slice(0, siteDefinitionsInUse.value),
);

const addSiteDefinition = () => {
  if (siteDefinitionsInUse.value < 4) {
    siteDefinitionsInUse.value++;
  }
};

const removeSiteDefinition = (index: CustomDefinitionNumber) => {
  emit("update:field", `siteDefinition${index}`, "");
  emit("update:field", `suggestions${index}`, []);
  emit("update:field", `forceSuggestion${index}`, false);
  siteDefinitionsInUse.value = Math.max(1, siteDefinitionsInUse.value - 1);
};
</script>

<template>
  <div class="module-edit-site-definitions">
    <div
      v-for="index of activeDefinitionNumbers"
      :key="index"
      class="module-edit-site-definitions__item"
    >
      <BaseTextField
        :label="t('common.name')"
        :error-message="errorMessage[`siteDefinition${index}`]"
        :value="moduleForm[`siteDefinition${index}`]"
        @update:value="$emit('update:field', `siteDefinition${index}`, String($event))"
      />

      <div>
        <BaseLabel has-spacing>{{ t("modules.suggestions") }}</BaseLabel>
        <BasePillsInput
          :values="[...moduleForm[`suggestions${index}`]]"
          :add-on-space="false"
          :max-length="50"
          @add="
            $emit('update:field', `suggestions${index}`, [
              ...moduleForm[`suggestions${index}`],
              $event,
            ])
          "
        >
          <template #pill="{ value, index: idx }">
            <BasePill
              :label="value"
              clickable
              @click="
                $emit('update:field', `suggestions${index}`, [
                  ...moduleForm[`suggestions${index}`].filter((_, i) => i !== idx),
                ])
              "
            />
          </template>
        </BasePillsInput>
      </div>

      <BaseCheckbox
        :disabled="moduleForm[`suggestions${index}`].length === 0"
        :label="t('modules.forceSuggestion')"
        :checked="moduleForm[`forceSuggestion${index}`]"
        @change="$emit('update:field', `forceSuggestion${index}`, $event)"
      />

      <div class="module-edit-site-definitions__delete">
        <BaseButton
          variant="text"
          color="error"
          :title="t('common.actions.delete')"
          @click="removeSiteDefinition(index)"
        >
          <BaseIcon icon="trash" />
        </BaseButton>
      </div>
    </div>

    <BaseButtonRow v-if="siteDefinitionsInUse < 4">
      <BaseButton variant="outlined" @click="addSiteDefinition">
        <BaseIcon icon="plus" />
        {{ t("modules.addSiteDefinition") }}
      </BaseButton>
    </BaseButtonRow>
  </div>
</template>

<style scoped lang="scss">
.module-edit-site-definitions {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
    gap: $spacing-4;
    padding: $spacing-4;
    border: 1px solid $primary-4;
    border-radius: $rounded-base;
  }

  &__delete {
    display: grid;
    align-items: center;
  }
}
</style>
