<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { ReportMailDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";

const { t } = useI18n({ useScope: "global" });

withDefaults(
  defineProps<{
    history: ReportMailDto[] | null;
    isLoading: boolean;
  }>(),
  {
    history: () => [],
  },
);

defineEmits<{
  onToggleActive: [id: ReportMailDto["id"], isActive: boolean];
}>();

const columns = [
  new ColumnDefinition({
    title: t("survey.surveyReportMail.subject"),
    key: "subject",
    defaultHidden: true,
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.body"),
    key: "body",
    defaultHidden: true,
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.recipient"),
    key: "recipient",
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.code"),
    key: "code",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.link"),
    key: "link",
    autoWidth: false,
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.type"),
    key: "type",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.active"),
    key: "isActive",
    type: ColumnDataType.Boolean,
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("survey.surveyReportMail.sent"),
    key: "sentDate",
    type: ColumnDataType.Date,
    autoWidth: true,
  }),
] as ColumnDefinition<ReportMailDto>[];

const getType = (type: ReportMailDto["type"]) => {
  switch (type.toLowerCase()) {
    case "reportsandresponse":
      return t("survey.surveyReportMail.reportsAndResponse");
    case "reportsonly":
      return t("survey.surveyReportMail.reports");
    default:
      return "";
  }
};
</script>

<template>
  <BaseGridTable enable-row-click :columns="columns" :rows="history ?? []" :is-loading="isLoading">
    <template #column-link="{ row }">
      <a :href="row.link" target="_blank" :title="row.link">{{ row.link }}</a>
    </template>

    <template #column-type="{ row }">
      <span>{{ getType(row.type) }}</span>
    </template>

    <template #column-isActive="{ row }">
      <BaseCheckbox
        :checked="row.isActive"
        @change="() => $emit('onToggleActive', row.id, !row.isActive)"
      />
    </template>

    <template #column-sentDate="{ row }">
      <span :title="formatDate.asDateAndTime(row.sentDate)">
        {{ formatDate.asDateOnly(row.sentDate) }}
      </span>
    </template>
  </BaseGridTable>
</template>
