import { Ref, computed } from "vue";
import { customLocaleCompare } from "@/helpers/customLocaleCompare";
import { Role, UserLeanDto } from "@/types/_generated/api";

export const useGetModuleUsersOptionsGroups = (
  users: Ref<UserLeanDto[] | null>,
  filter?: (option: UserLeanDto) => boolean,
) => {
  const toOption = (user: UserLeanDto) => ({
    title: user.fullName,
    value: user.id,
  });
  const getOptionsForRole = (role: string) => {
    let usersForRole = users.value?.filter((user) => user.role === role) ?? [];

    if (filter) {
      usersForRole = usersForRole.filter(filter);
    }

    return usersForRole.map(toOption);
  };

  const moduleUserOptions = computed(() =>
    [Role.SA, Role.BA, Role.INT, Role.EXT]
      .map((role) => {
        const allRoleOptions =
          role === Role.SA // SystemOwner is most of the time the same as SA
            ? [...getOptionsForRole(Role.SystemOwner), ...getOptionsForRole(role)]
            : getOptionsForRole(role);

        const sortedOptions = allRoleOptions.sort((a, b) => customLocaleCompare(a.title, b.title));

        return {
          group: role,
          options: sortedOptions,
        };
      })
      .filter((group) => group.options.length > 0),
  );

  return moduleUserOptions;
};
