<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <span class="user-site-permission-filter-empty">
    {{ t("users.permissions.pleaseSelect") }}
  </span>
</template>

<style scoped lang="scss">
.user-site-permission-filter-empty {
  padding: $spacing-2;
  color: $primary-5;
}
</style>
