import { Status, BlueRatingDto } from "@/types/_generated/api";

export const getQuestionById = (survey: BlueRatingDto, questionId: number) =>
  getQuestions(survey).find((q) => q.questionId === questionId);

export const getQuestionCategory = (survey: BlueRatingDto, questionId: number) =>
  getCategories(survey).find((c) => c.questions.some((q) => q.questionId === questionId));

export const getCategoryById = (survey: BlueRatingDto, categoryId: number) =>
  getCategories(survey).find((c) => c.categoryId === categoryId);

export const getRecommendationById = (survey: BlueRatingDto, recommendationId: number) =>
  getRecommendations(survey).find((r) => r.recommendationId === recommendationId);

export const getCategories = (survey: BlueRatingDto) =>
  survey.areas.map((a) => a.categories).flat();

export const getQuestions = (survey: BlueRatingDto) =>
  getCategories(survey)
    .map((c) => c.questions)
    .flat();

export const getRecommendations = (survey: BlueRatingDto) => [
  ...getCategories(survey)
    .map((c) => c.recommendations)
    .flat(),
  ...getQuestions(survey)
    .map((q) => q.recommendations)
    .flat(),
];

export const isSurveyPublished = (survey?: BlueRatingDto): boolean =>
  survey?.status === Status.Published;
