import type { DashboardTableLink } from "@/components/shared/Dashboard/types";
import { formatDate } from "@/helpers/formatDate";
import { AdminRouteName } from "@/router/RouteName";
import type { RecentActivityItem } from "../types";

export const createRecentActivityItem = (
  name: string,
  lastVisit: string,
  routeName: AdminRouteName,
  params: DashboardTableLink["routerLink"]["params"],
): RecentActivityItem => ({
  title: name,
  lastVisit: formatDate.fromNow(lastVisit),
  routerLink: { name: routeName, params },
});
