<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseRadioGroup, {
  type BaseRadioGroupOption,
} from "@/components/base/BaseRadioGroup/BaseRadioGroup.vue";

type Props = {
  approved: boolean;
  notifySurveyor: boolean;
  hasSpoeCheck: boolean;
  isSavingSpoeCheck: boolean;
};

defineProps<Props>();

const emit = defineEmits<{
  save: [];
  "update:approved": [boolean];
  "update:notifySurveyor": [boolean];
}>();

const { t } = useI18n({ useScope: "global" });

const options: BaseRadioGroupOption[] = [
  { label: t("userWeb.spoeCheck.approve"), value: "true" },
  { label: t("userWeb.spoeCheck.reject"), value: "false" },
];
</script>

<template>
  <div class="spoe-check-save">
    <div class="spoe-check-save__status">
      <BaseLabel>{{ t("userWeb.spoeCheck.spoeCheckStatus") }}</BaseLabel>
      <BaseRadioGroup
        class="spoe-check-save__status__radio"
        horizontal
        :value="approved ? 'true' : 'false'"
        :options="options"
        @input="emit('update:approved', $event === 'true')"
      />
    </div>
    <div class="spoe-check-save__buttons">
      <div class="spoe-check-save__buttons__save-options">
        <BaseCheckbox
          :checked="hasSpoeCheck !== approved || notifySurveyor"
          :disabled="hasSpoeCheck !== approved"
          :label="t('userWeb.spoeCheck.notifySurveyor')"
          @change="emit('update:notifySurveyor', !notifySurveyor)"
        />
      </div>

      <BaseButton :disabled="isSavingSpoeCheck" @click="emit('save')">
        {{ t("common.actions.save") }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.spoe-check-save {
  margin-top: $spacing-16;
  padding-top: $spacing-4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4;
  border-top: 1px solid $primary-5;

  &__status {
    display: flex;
    align-items: center;
    gap: $spacing-4;

    &__radio {
      font-size: $text-sm;
      font-weight: $font-medium;
    }
  }

  &__buttons {
    border-left: 1px solid $primary-5;
    padding-left: $spacing-4;

    display: flex;
    align-items: center;
    gap: $spacing-4;

    &__save-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacing-1;
    }
  }
}
</style>
