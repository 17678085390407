import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { archiveUserService } from "@/services/users";

export const useArchiveUser = (emit: (event: "close") => void) => {
  const { t } = useI18n({ useScope: "global" });
  const dialog = useDialog();

  const archiveUser = async (userId: number | null) => {
    const isConfirmed = await dialog.openDialog({
      title: t("common.actions.archive"),
      description: t("users.archiveUsersPrompt"),
    });

    if (isConfirmed && userId) {
      await archiveUserService(userId);
      emit("close");
    }
  };

  return {
    archiveUser,
  };
};
