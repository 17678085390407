import { generateUniqueId } from "@/helpers/generateUniqueId";

export const useColorPicker = () => {
  const colorInputId = `color-input-${generateUniqueId()}`;

  const openColorPicker = () => {
    const colorInput = document.getElementById(colorInputId) as HTMLInputElement;
    colorInput.click();
  };

  return { colorInputId, openColorPicker };
};
