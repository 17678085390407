<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.394571 4.08979C0.759274 3.89341 1.21412 4.02987 1.4105 4.39457L4.9105 10.8946C5.10688 11.2593 4.97042 11.7141 4.60572 11.9105C4.24102 12.1069 3.78617 11.9704 3.58979 11.6057L0.0897921 5.10572C-0.106586 4.74102 0.0298675 4.28617 0.394571 4.08979Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75012 4.00014C7.16434 4.00014 7.50012 4.33593 7.50012 4.75014V10.5001H9.25012C9.66434 10.5001 10.0001 10.8359 10.0001 11.2501C10.0001 11.6644 9.66434 12.0001 9.25012 12.0001H6.75012C6.33591 12.0001 6.00012 11.6644 6.00012 11.2501V4.75014C6.00012 4.33593 6.33591 4.00014 6.75012 4.00014Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0001 6.50014C11.0001 5.11943 12.1194 4.00014 13.5001 4.00014H15.2501C15.6643 4.00014 16.0001 4.33593 16.0001 4.75014C16.0001 5.16436 15.6643 5.50014 15.2501 5.50014H13.5001C12.9478 5.50014 12.5001 5.94786 12.5001 6.50014C12.5001 7.05243 12.9478 7.50014 13.5001 7.50014H13.7501C14.9927 7.50014 16.0001 8.50753 16.0001 9.75014C16.0001 10.9928 14.9927 12.0001 13.7501 12.0001H11.7501C11.3359 12.0001 11.0001 11.6644 11.0001 11.2501C11.0001 10.8359 11.3359 10.5001 11.7501 10.5001H13.7501C14.1643 10.5001 14.5001 10.1643 14.5001 9.75014C14.5001 9.33596 14.1643 9.00014 13.7501 9.00014H13.5001C12.1194 9.00014 11.0001 7.88086 11.0001 6.50014Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.60572 4.08979C4.97042 4.28617 5.10688 4.74102 4.9105 5.10572L1.4105 11.6057C1.21412 11.9704 0.759274 12.1069 0.394571 11.9105C0.0298675 11.7141 -0.106586 11.2593 0.0897921 10.8946L3.58979 4.39457C3.78617 4.02987 4.24102 3.89341 4.60572 4.08979Z"
    />
  </svg>
</template>
