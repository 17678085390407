<script setup lang="ts">
import { toRef } from "vue";
import { useTableFilter } from "@/composables/useTableFilter";
import { Role, UserModulePermissionDto } from "@/types/_generated/api";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import { useUserPermissionsTableColumns } from "../../composables/useUserPermissionsTableColumns";

const props = defineProps<{
  userRole: Role;
  permissions: UserModulePermissionDto[];
}>();

const { userPermissionsTableColumns } = useUserPermissionsTableColumns(toRef(() => props.userRole));

const { result, searchPhrase, searchKey, searchKeys, setSearchKey, setSearchPhrase } =
  useTableFilter(
    toRef(() => props.permissions),
    userPermissionsTableColumns,
  );
</script>

<template>
  <BaseFilter
    class="user-details__permissions__controls"
    :search-phrase="searchPhrase"
    :search-key="searchKey"
    :search-keys="searchKeys"
    @update:search-phrase="setSearchPhrase($event)"
    @update:search-key="setSearchKey($event)"
  />

  <BaseGridTable :columns="userPermissionsTableColumns" :rows="result.result" />
</template>
