<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 15.25C0 14.8358 0.335786 14.5 0.75 14.5H15.25C15.6642 14.5 16 14.8358 16 15.25C16 15.6642 15.6642 16 15.25 16H0.75C0.335786 16 0 15.6642 0 15.25Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75 0C4.16421 0 4.5 0.335786 4.5 0.75V7C4.5 8.93299 6.067 10.5 8 10.5C9.93299 10.5 11.5 8.93299 11.5 7V0.75C11.5 0.335786 11.8358 0 12.25 0C12.6642 0 13 0.335786 13 0.75V7C13 9.76141 10.7614 12 8 12C5.23858 12 3 9.76141 3 7V0.75C3 0.335786 3.33579 0 3.75 0Z"
    />
  </svg>
</template>
