import { FileDto } from "@/types/_generated/api";

export const useImageUpload = () => {
  const getImageDataUrl = (files: FileDto[]) => {
    const [file] = files;
    const { fileName } = file;
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);

    return `data:image/${extension};base64,${file.file}`;
  };

  const loadImage = (src: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });

  const resizeImage = (image: HTMLImageElement, maxWidth: number) => {
    const aspectRatio = image.width / image.height;
    const newWidth = maxWidth;
    const newHeight = newWidth / aspectRatio;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Could not get 2d context");
    }

    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.drawImage(image, 0, 0, newWidth, newHeight);

    return canvas.toDataURL("image/jpeg");
  };

  const handleImageUpload = async (files: FileDto[]) => {
    const maxWidth = 850;
    const originalDataUrl = getImageDataUrl(files);

    if (!maxWidth) {
      return originalDataUrl;
    }

    const image = await loadImage(originalDataUrl);

    return resizeImage(image, maxWidth);
  };
  return {
    handleImageUpload,
  };
};
