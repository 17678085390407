<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useUniqueId } from "@/composables/useUniqueId";
import { QuestionnaireDraftActionType } from "@/types/_generated/api";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import QuestionnaireDraftInputWrapper from "../QuestionnaireDraftInputWrapper.vue";

const { t } = useI18n({ useScope: "global" });

type Color = "Red" | "Yellow" | "Green" | "Blue";

defineProps<{
  realQuestionnaireId: number;
  questionId: number;
  questionText: string;
  color: Color;
  overridesCategoryColor: boolean;
}>();

const checkboxId = useUniqueId("base_checkbox");
</script>

<template>
  <QuestionnaireDraftInputWrapper
    v-if="color !== 'Blue'"
    :id="questionId"
    :real-questionnaire-id="realQuestionnaireId"
    :type="QuestionnaireDraftActionType.Question"
    :property-name="`${color as Extract<Color, 'Red' | 'Yellow' | 'Green'>}OverridesCategoryColor`"
    :label="
      t('questionnaire.draft.labels.overridesCategoryColor', {
        color: color.toLowerCase(),
      })
    "
    orientation="horizontal-reversed"
    :target-name="questionText"
    :debounce-time="0"
    :label-for="checkboxId"
  >
    <template #default="{ update }">
      <BaseCheckbox :id="checkboxId" :checked="overridesCategoryColor" @change="update($event)" />
    </template>
  </QuestionnaireDraftInputWrapper>
</template>
