<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { arrayOfNumbers } from "@/helpers/array";
import { QuestionnaireDraftActionType } from "@/types/_generated/api";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

type PositionLevel = {
  count: number;
  current: number;
  type: QuestionnaireDraftActionType;
};

defineProps<{
  levels: PositionLevel[];
}>();

defineEmits<{
  update: [
    {
      type: QuestionnaireDraftActionType;
      position: number;
    },
  ];
}>();
</script>

<template>
  <BaseSelect
    v-for="(level, i) in levels"
    :key="i"
    :label="t(`questionnaire.draft.position.${level.type.toLowerCase()}`)"
    :value="level.current"
    @change="$emit('update', { type: level.type, position: Number($event) })"
  >
    <BaseOption v-for="n in arrayOfNumbers(level.count, 1)" :key="n" :value="n">
      {{ n }}
    </BaseOption>
  </BaseSelect>
</template>

<style scoped lang="scss">
.questionnaire-draft__position {
  :deep(.base-select) {
    width: 100%;
    max-width: 100%;
  }
}
</style>
