<script setup lang="ts">
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useGetSites } from "@/composables/services/useGetSites";
import router from "@/router";
import { UserWebRouteName } from "@/router/RouteName";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import { MapPin } from "@/components/base/BaseMap/types";
import DashboardTable from "@/components/shared/Dashboard/DashboardTable.vue";
import DashboardMap from "./components/DashboardMap.vue";
import DashboardPercentage from "./components/DashboardPercentage.vue";
import { useGetCoordinates } from "./composables/useGetCoordinates";
import { useGetDashboardAverageScore } from "./composables/useGetDashboardAverageScore";
import { useGetDashboardRecResponseRatio } from "./composables/useGetDashboardRecResponseRatio";
import { useGetDashboardSurveyHistory } from "./composables/useGetDashboardSurveyHistory";
import { useRecResponseHistory } from "./composables/useRecResponseHistory";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });

const { sites, getSites, isLoadingSites } = useGetSites();
const { isLoadingAverageScore, averageScore } = useGetDashboardAverageScore(props.moduleId);
const { isLoadingRatio, ratio } = useGetDashboardRecResponseRatio(props.moduleId);
const { isLoadingSurveyHistory, historyColumns, surveyHistory } = useGetDashboardSurveyHistory(
  props.moduleId,
);
const { siteResponseHistory } = useRecResponseHistory(sites, props.moduleId);
const { coordinates } = useGetCoordinates(sites);

const goToSite = ({ pin }: { pin: MapPin }) => {
  if (pin.id) {
    router.push(
      router.resolve({
        name: UserWebRouteName.Site,
        params: { moduleId: props.moduleId.toString(), siteId: pin.id.toString() },
      }).href,
    );
  } else {
    throw new Error("Site ID is missing");
  }
};

onMounted(() => getSites(props.moduleId, true));
</script>

<template>
  <UserWebViewLayout>
    <div class="dashboard-view">
      <DashboardTable
        class="dashboard-view__surveyHistory"
        :is-loading="isLoadingSurveyHistory"
        sort-by="date"
        :title="t('userWeb.dashboard.surveyHistory')"
        :columns="historyColumns"
        :rows="surveyHistory"
      />

      <DashboardTable
        class="dashboard-view__siteResponseHistory"
        :is-loading="isLoadingSites"
        sort-by="date"
        :title="t('userWeb.dashboard.siteResponseHistory')"
        :columns="historyColumns"
        :rows="siteResponseHistory"
      />

      <DashboardMap
        class="dashboard-view__map"
        :is-loading-data="isLoadingSites"
        :pins="coordinates"
        @click:pin="goToSite"
      />

      <DashboardPercentage
        class="dashboard__recResponseRatio"
        :is-loading="isLoadingRatio"
        :title="t('userWeb.dashboard.recResponseRatio')"
        :percentage="ratio"
      />

      <DashboardPercentage
        class="dashboard-view__avgTotalScore"
        :is-loading="isLoadingAverageScore"
        :title="t('userWeb.dashboard.averageTotalScore')"
        :percentage="averageScore"
      />
    </div>
  </UserWebViewLayout>
</template>

<style scoped lang="scss">
$spacing: $spacing-4;

.dashboard-view {
  display: grid;
  grid-template-areas:
    "surveyHistory siteResponseHistory recResponseRatio avgTotalScore"
    "surveyHistory siteResponseHistory map map"
    "surveyHistory siteResponseHistory map map";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 1fr;
  align-items: start;
  gap: $spacing;
  height: 100%;

  &__surveyHistory {
    grid-area: surveyHistory;
  }
  &__siteResponseHistory {
    grid-area: siteResponseHistory;
  }
  &__map {
    grid-area: map;
  }
  &__recResponseRatio {
    grid-area: recResponseRatio;
  }
  &__avgTotalScore {
    grid-area: avgTotalScore;
  }
  &__insuredSites {
    grid-area: insuredSites;
  }
}
</style>
