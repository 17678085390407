<script setup lang="ts">
import { computed, toRef } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { siteResponseSortOrder } from "@/constants/SortOrder";
import { SiteResponseStatus } from "@/types/_generated/api";
import { useTrackedRecsGroupedBySiteResponseStatus } from "../composables/useTrackedRecsGroupedBySiteResponseStatus";
import RecTrackingStackBarChart from "./RecTrackingStackBarChart.vue";

const props = defineProps<{
  chartData: TrackedRecommendation[];
}>();

const { trackedRecsGroupedBySiteResponseStatus } = useTrackedRecsGroupedBySiteResponseStatus(
  toRef(props.chartData),
);
const { statusData } = useSiteResponseStatusChartMetaData();

const orderedStatuses = computed(
  () => Object.keys(siteResponseSortOrder).reverse() as SiteResponseStatus[],
);

const categoryIdToNameMap = computed(() => {
  return props.chartData.reduce((map, { categoryId, categoryName }) => {
    if (categoryId && !map.has(categoryId)) {
      map.set(categoryId, categoryName);
    }
    return map;
  }, new Map<number, string>());
});

const categoryIds = computed(() => [...categoryIdToNameMap.value.keys()]);

const chartColors = computed(() =>
  Object.fromEntries(orderedStatuses.value.map((status) => [status, statusData[status].color])),
);

const chartSeries = computed(() =>
  orderedStatuses.value.map((status) => ({
    key: status,
    label: statusData[status].label,
  })),
);

const generatedChartData = computed(() => {
  // Initialize a 2D array: rows for statuses, columns for categories
  const data: number[][] = [...Array(orderedStatuses.value.length)].map(() =>
    Array(categoryIds.value.length).fill(0),
  );

  orderedStatuses.value.forEach((status, statusIndex) => {
    const dataForStatus = trackedRecsGroupedBySiteResponseStatus.value[status];

    dataForStatus.forEach(({ categoryId }) => {
      const categoryIndex = categoryId ? categoryIds.value.indexOf(categoryId) : -1;
      if (categoryIndex !== -1) {
        // Increment the count for this status and category combination
        data[statusIndex][categoryIndex]++;
      }
    });
  });

  // Calculate totals and sort categories
  const totals = data[0].map((_, colIndex) => data.reduce((sum, row) => sum + row[colIndex], 0));
  const sortedIndices = totals
    .map((total, index) => ({ total, index }))
    .sort((a, b) => b.total - a.total)
    .map(({ index }) => index);

  // Sort data and category names
  const sortedData = data.map((row) => sortedIndices.map((index) => row[index]));
  const sortedCategoryNames = sortedIndices.map(
    (index) => [...categoryIdToNameMap.value.values()][index],
  );

  return { sortedData, sortedCategoryNames };
});
</script>

<template>
  <RecTrackingStackBarChart
    :colors="chartColors"
    :series="chartSeries"
    :labels="generatedChartData.sortedCategoryNames"
    :data="generatedChartData.sortedData"
  />
</template>
