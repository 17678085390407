import { Ref, computed } from "vue";
import { SortOrder } from "@/constants/SortOrder";
import { sortByKeys } from "@/helpers/sort";
import { RecommendationRiskManagerCommentDto } from "@/types/_generated/api";

export const useRiskManagerComment = (comments: Ref<RecommendationRiskManagerCommentDto[]>) => {
  const lockedComments = computed(() =>
    sortByKeys(
      comments.value.filter((c) => c.locked),
      { key: "insertDate", order: SortOrder.Descending },
    ),
  );

  return { lockedComments };
};
