<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";

const props = defineProps<{
  label?: string;
  min?: number;
  max?: number;
}>();

const emit = defineEmits<{
  "update:min": [number | undefined];
  "update:max": [number | undefined];
}>();

const { t } = useI18n({ useScope: "global" });

const minValue = computed(() => props.min?.toString() ?? "");
const maxValue = computed(() => props.max?.toString() ?? "");

const updateMin = (value: string) => {
  emit("update:min", value ? Number(value) : undefined);
};

const updateMax = (value: string) => {
  emit("update:max", value ? Number(value) : undefined);
};

const getLabelText = (type: "min" | "max") => {
  if (props.label) {
    return `${props.label} (${t(`userWeb.recommendationTracking.filter.${type}`)})`;
  }
  return t(`userWeb.recommendationTracking.filter.${type}`);
};
</script>

<template>
  <div class="mod-filter-number-range">
    <div>
      <BaseLabel has-spacing>
        {{ getLabelText("min") }}
      </BaseLabel>

      <UWTextField type="number" :max="max" :value="minValue" @input="updateMin($event.trim())" />
    </div>

    <div>
      <BaseLabel has-spacing>
        {{ getLabelText("max") }}
      </BaseLabel>

      <UWTextField type="number" :min="min" :value="maxValue" @input="updateMax($event.trim())" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mod-filter-number-range {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-4;
}
</style>
