<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useSiteResponseStatusTranslations } from "@/composables/translations/useSiteResponseStatusTranslations";
import { SiteResponseStatus } from "@/types/_generated/api";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

interface Props {
  siteResponseStatuses?: SiteResponseStatus[];
}

defineProps<Props>();

defineEmits<{
  "update:selected": [options: SiteResponseStatus[]];
}>();

const { t } = useI18n({ useScope: "global" });

const { translations } = useSiteResponseStatusTranslations();

const options = Object.values(SiteResponseStatus).map((status) => ({
  value: status,
  label: translations[status],
}));
</script>

<template>
  <BaseAccordion :label="t('userWeb.recommendation.responseStatus')">
    <ModMultiselect
      :options="options"
      :selected-options="new Set(siteResponseStatuses)"
      @update:selected="$emit('update:selected', $event)"
    />
  </BaseAccordion>
</template>
