<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetClient } from "@/composables/services/useGetClient";
import { ClientDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";

const props = defineProps<{
  clientId: number;
}>();

const client = ref<ClientDto | null>(null);

const { t } = useI18n({ useScope: "global" });
const { isLoadingClient, getClient } = useGetClient();

onBeforeMount(async () => {
  client.value = await getClient(props.clientId);
});
</script>

<template>
  <div class="client-details">
    <BaseDataDisplayList>
      <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('clients.client')">
        <p>{{ client?.name }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('clients.country')">
        <p>{{ client?.willisCountryOrUnit }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('clients.office')">
        <p>{{ client?.willisOffice }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('common.status')">
        <p>{{ client?.status ? t(`common.statuses.${client?.status}`) : "" }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>
  </div>
</template>

<style scoped lang="scss">
.client-details {
  display: grid;
  gap: $spacing-8;
  grid-template-columns: repeat(3, 1fr);
}
</style>
