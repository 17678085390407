<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  showDescription: boolean;
}>();

defineEmits<{
  toggle: [void];
}>();
</script>

<template>
  <BaseButton
    :title="
      showDescription
        ? t('blueRating.descriptionToggle.hide')
        : t('blueRating.descriptionToggle.show')
    "
    variant="outlined"
    @click="$emit('toggle')"
  >
    <BaseIcon :icon="showDescription ? 'eye-closed' : 'eye'" />
  </BaseButton>
</template>
