<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BlueRatingRecommendationLeanDto } from "@/types/_generated/api";
import BlueRatingInlineRecommendation from "./BlueRatingInlineRecommendation.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  title: string;
  position: string;
  recommendations: BlueRatingRecommendationLeanDto[];
}>();

defineEmits<{
  "click:recommendation": [number];
}>();
</script>

<template>
  <div class="blue-rating-card__wrapper">
    <span class="blue-rating-card__wrapper__position">{{ position }}</span>
    <div class="blue-rating-card">
      <div class="blue-rating-card__title">
        <h3>{{ title }}</h3>
      </div>

      <div class="blue-rating-card__main">
        <slot></slot>
      </div>

      <div v-if="recommendations.length" class="blue-rating-card__recommendations">
        <div class="blue-rating-card__recommendations__title">
          {{ t("blueRating.recommendations") }}
        </div>
        <BlueRatingInlineRecommendation
          v-for="(r, i) in recommendations"
          :key="i"
          :recommendation="r"
          @click="$emit('click:recommendation', r.recommendationId)"
        />
      </div>
    </div>

    <div class="blue-rating-card__controls">
      <slot name="controls"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-card__wrapper {
  display: flex;
  font-size: $text-sm;

  &__position {
    text-align: center;
    align-self: start;
    border-radius: $rounded-base;
    padding: $spacing-2;
    background-color: $primary-2;
    border: 1px solid $primary-3;
    margin-right: $spacing-2;
    font-variant-numeric: tabular-nums;
  }

  .blue-rating-card {
    display: flex;
    flex-direction: column;
    border-radius: $rounded-base;
    background-color: $primary-2;
    border: 1px solid $primary-3;
    width: 100%;

    &__controls {
      max-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: $spacing-2;
      margin-left: $spacing-4;
    }

    &__title {
      background-color: $primary-3;
      font-size: $text-base;
      font-weight: $font-medium;
      padding: $spacing-4;
    }

    &__recommendations {
      &__title {
        background-color: $primary-3;
        padding: $spacing-3;
        font-weight: $font-medium;
      }
    }
  }
}
</style>
