import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { handleError } from "@/services/shared/handleError";

const { t } = i18n.global;

export const requestResendService = async (email: string): Promise<void> => {
  try {
    await http.post(`/recommendation-response/request-mail-resend`, { email });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.request").toLowerCase(),
      entity: t("survey.surveyReportMail.surveyReportMail"),
    });
  }
};
