import { Directive } from "vue";

/**
 * Type definition for the cleanup function
 */
type CleanupFunction = () => void;

/**
 * Extended HTMLElement interface to include our cleanup function
 */
interface TruncationTitleElement extends HTMLElement {
  _truncationTitleCleanup?: CleanupFunction;
}

/**
 * Sets the title attribute of an element to its text content if the text is truncated.
 * Uses a MutationObserver to ensure the truncate class persists through class changes.
 * @example
 * <div v-truncation-title>
 *  This text will have a title attribute if it is truncated.
 * </div>
 */
const truncationTitle: Directive<TruncationTitleElement> = {
  mounted(element) {
    // Add the 'truncate' class to the element
    element.classList.add("truncate");

    // Check if the element's text is truncated
    const isTruncated = () => element.scrollWidth > element.clientWidth;

    // Create a MutationObserver to watch for class changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class" &&
          !element.classList.contains("truncate")
        ) {
          element.classList.add("truncate");
        }
      });
    });

    // Start observing the element for class changes
    observer.observe(element, {
      attributes: true,
      attributeFilter: ["class"],
    });

    // Event listener for 'mouseover' event
    const handleMouseover = () => {
      if (isTruncated()) {
        element.title = element.textContent || "";
      } else {
        element.removeAttribute("title");
      }
    };

    element.addEventListener("mouseover", handleMouseover);

    // Cleanup function
    const cleanup: CleanupFunction = () => {
      observer.disconnect();
      element.removeEventListener("mouseover", handleMouseover);
    };

    // Store the cleanup function on the element
    element._truncationTitleCleanup = cleanup;
  },

  unmounted(element) {
    // Call the cleanup function when the directive is unmounted
    if (element._truncationTitleCleanup) {
      element._truncationTitleCleanup();
    }
  },
};

export { truncationTitle as vTruncationTitle };
