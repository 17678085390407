<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useTableFilter } from "@/composables/useTableFilter";
import { authorize } from "@/plugins/can";
import {
  searchStandardRecommendationsService,
  sdtRecommendationEvents,
  getStandardRecommendation,
} from "@/services/standardRecommendations";
import {
  Role,
  StandardRecommendationDto,
  StandardRecommendationLeanDto,
} from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseEntityLink from "@/components/base/BaseEntityLink.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import StandardRecommendationDetailDrawer from "./StandardRecommendationDetailDrawer.vue";
import StandardRecEditDrawer from "./StandardRecommendationEditDrawer/StandardRecEditDrawer.vue";
import { useStandardRecColumns } from "./useStandardRecColumns";

const props = defineProps<{
  standardRecommendationId?: number;
}>();

const { t } = useI18n({ useScope: "global" });
const isLoading = ref(false);

const stdRecommendations = ref<StandardRecommendationLeanDto[]>([]);
const selectedStandardRec = ref<StandardRecommendationDto | null>();
const showEditDrawer = ref(false);
const showStandardRecommendationDetailDrawer = ref(false);

useBroadcastService(sdtRecommendationEvents, async () => {
  stdRecommendations.value = (await searchStandardRecommendationsService()) || [];
});

const { columns } = useStandardRecColumns();

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(stdRecommendations, columns);

const closeDrawer = () => {
  showEditDrawer.value = false;
  showStandardRecommendationDetailDrawer.value = false;
  selectedStandardRec.value = null;
};

const openDrawer = async (drawer: "edit" | "detail", standardRecommendationId?: number) => {
  selectedStandardRec.value = standardRecommendationId
    ? await getStandardRecommendation(standardRecommendationId)
    : null;

  if (drawer === "edit") {
    showEditDrawer.value = true;
  } else {
    showStandardRecommendationDetailDrawer.value = true;
  }
};

const canEditStandardRecommendation = (recommendation: StandardRecommendationLeanDto) => {
  const isGlobal = !recommendation.clientId && !recommendation.moduleId;

  if (isGlobal) {
    return authorize.isSuperiorOrEqualTo(Role.SA);
  }

  return authorize.hasModulePermission(recommendation.moduleId, "uploadStdRec");
};

onMounted(async () => {
  isLoading.value = true;
  stdRecommendations.value = (await searchStandardRecommendationsService()) ?? [];
  isLoading.value = false;

  if (props.standardRecommendationId) {
    openDrawer("detail", props.standardRecommendationId);
  }
});
</script>

<template>
  <BaseGridTable :rows="result.result" :is-loading="isLoading" :columns="columns">
    <template #header-header="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <BaseButton
        v-if="authorize.isSuperiorOrEqualTo(Role.INT)"
        class="view-template__controls__button"
        @click="openDrawer('edit')"
      >
        {{ t("standardRecommendations.newStandardRecommendation") }}
      </BaseButton>
    </template>

    <template #column-header="{ row }">
      <BaseEntityLink
        :label="row.header"
        :title="t('standardRecommendations.showStandardRecommendationDetails')"
        @click="() => openDrawer('detail', row.standardRecommendationId)"
      >
        <template #icon>
          <BaseIcon icon="info" />
        </template>
      </BaseEntityLink>
    </template>
    <template #column-categories="{ row }">
      <template v-if="row.categories && row.categories.length > 0">
        <BasePill v-for="c in row.categories" :key="c" :label="c" />
      </template>
      <template v-else> &nbsp; </template>
    </template>

    <template #column-questions="{ row }">
      <template v-if="row.questions && row.questions.length > 0">
        <BasePill v-for="q in row.questions" :key="q" :label="q" />
      </template>
      <template v-else> &nbsp; </template>
    </template>

    <template #actions="{ row }">
      <EditIconButton
        v-if="canEditStandardRecommendation(row)"
        :title="`${t('common.actions.edit')} ${row.header}`"
        @click="openDrawer('edit', row.standardRecommendationId)"
      />
    </template>
  </BaseGridTable>

  <StandardRecommendationDetailDrawer
    v-if="showStandardRecommendationDetailDrawer && selectedStandardRec"
    :standard-recommendation="selectedStandardRec"
    @close="closeDrawer"
    @edit="openDrawer('edit', $event)"
  />

  <StandardRecEditDrawer
    v-if="showEditDrawer"
    :standard-recommendation="selectedStandardRec"
    @close="showEditDrawer = false"
  />
</template>
