import type {
  CreateRecReportSettings,
  CreateRecReportSettingsResponse,
  GetRecReportSettings,
  GetRecReportSettingsResponse,
  GetAllRecReportSettings,
  GetAllRecReportSettingsResponse,
  UpdateRecReportSettings,
  UpdateRecReportSettingsResponse,
  DeleteRecReportSettings,
  DeleteRecReportSettingsResponse,
} from "@/types/_generated/api";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const createRecReportSettingsService = async (
  payload: CreateRecReportSettings,
): Promise<CreateRecReportSettingsResponse> => {
  try {
    const { data } = await http.post(`/modules/${payload.moduleId}/rec-report-settings`, payload);

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("module.recReportSettings.title"),
    });

    throw error;
  }
};

export const getRecReportSettingsService = async (
  payload: GetRecReportSettings,
): Promise<GetRecReportSettingsResponse> => {
  try {
    const url = payload.moduleId
      ? `/modules/${payload.moduleId}/rec-report-settings/${payload.settingId}`
      : `/rec-report-settings/${payload.settingId}`;

    const { data } = await http.get(url);

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("module.recReportSettings.title"),
    });

    throw error;
  }
};

export const getAllRecReportSettingsService = async (
  payload: GetAllRecReportSettings,
): Promise<GetAllRecReportSettingsResponse> => {
  try {
    const { data } = await http.get(`/modules/${payload.moduleId}/rec-report-settings`);
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("module.recReportSettings.title"),
    });

    throw error;
  }
};

export const updateRecReportSettingsService = async (
  payload: UpdateRecReportSettings,
): Promise<UpdateRecReportSettingsResponse> => {
  try {
    const { data } = await http.put(
      `/modules/${payload.moduleId}/rec-report-settings/${payload.settingId}`,
      payload,
    );

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("module.recReportSettings.title"),
    });

    throw error;
  }
};

export const deleteRecReportSettingsService = async (
  payload: DeleteRecReportSettings,
): Promise<DeleteRecReportSettingsResponse> => {
  try {
    const { data } = await http.delete(
      `/modules/${payload.moduleId}/rec-report-settings/${payload.settingId}`,
    );

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("module.recReportSettings.title"),
    });

    throw error;
  }
};
