import { IStorage } from "./IStorage";
import { StorageSchema } from "./StorageSchema";

type WebStorageType = "localStorage" | "sessionStorage";

class WebStorage implements IStorage {
  private storage: Storage;

  constructor(type: WebStorageType) {
    this.storage = window[type];
  }

  set<T extends keyof StorageSchema>(key: T, value: StorageSchema[T]): void {
    const current = this.get(key) ?? {};
    this.storage.setItem(key, JSON.stringify({ ...current, ...value }));
  }

  get<T extends keyof StorageSchema>(key: T): StorageSchema[T] | null {
    return JSON.parse(this.storage.getItem(key)!) as StorageSchema[T];
  }

  remove<T extends keyof StorageSchema>(key: T): void {
    this.storage.removeItem(key);
  }

  isSupported(): boolean {
    const testKey = "__test__";

    try {
      this.storage.setItem(testKey, "1");
      this.storage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  }
}

export const localStorage = new WebStorage("localStorage");
export const sessionStorage = new WebStorage("sessionStorage");
