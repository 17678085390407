import { readonly, ref } from "vue";

export const useDrawerState = <T>() => {
  const showDrawer = ref(false);
  const drawerData = ref<T | null>(null);

  const openDrawer = (optionalData: typeof drawerData.value = null) => {
    showDrawer.value = true;
    drawerData.value = optionalData;
  };

  const closeDrawer = () => {
    showDrawer.value = false;
    drawerData.value = null;
  };

  return {
    showDrawer: readonly(showDrawer),
    drawerData: readonly(drawerData),
    openDrawer,
    closeDrawer,
  };
};
