<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { formatDate } from "@/helpers/formatDate";
import {
  getQuestionnaireDraftHistory,
  revertQuestionnaireDraft,
} from "@/services/questionnaire-draft";
import { QuestionnaireDraftHistoryActionDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import QuestionnaireDraftGlobalHistory from "./QuestionnaireDraftGlobalHistory.vue";

const props = defineProps<{
  questionnaireId: number;
}>();

const isLoading = ref(false);
const selectedAction = ref<QuestionnaireDraftHistoryActionDto | null>(null);
const history = ref<QuestionnaireDraftHistoryActionDto[] | null>([]);

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();

const emits = defineEmits<{
  (event: "close"): void;
}>();

onBeforeMount(async () => {
  isLoading.value = true;

  history.value = await getQuestionnaireDraftHistory({
    questionnaireId: props.questionnaireId,
  });

  isLoading.value = false;
});

const revertToAction = async () => {
  if (!selectedAction.value) {
    return;
  }

  const result = await dialog.openDialog({
    title: "",
    description: t("questionnaire.draft.history.revertDialog.text", {
      insertDate: formatDate.asDateAndTime(selectedAction.value?.insertDate),
    }),
  });

  if (result) {
    await revertQuestionnaireDraft(props.questionnaireId, selectedAction.value.actionId);

    emits("close");
  }
};
</script>

<template>
  <BaseDrawer
    :is-loading="isLoading"
    class="questionnaire-draft__global-history-drawer"
    :title="t('questionnaire.draft.history.globalHistoryTitle')"
    :width="'auto'"
    @close="emits('close')"
  >
    <QuestionnaireDraftGlobalHistory
      v-if="history && !isLoading"
      :history="history"
      :selected="selectedAction"
      @click="selectedAction = $event"
    />
    <BaseSkeleton v-if="isLoading" class="skeleton" :set="3" width="100%" height="44px" mb="1rem" />

    <template #footer-left>
      <BaseButton :disabled="!selectedAction" @click="() => revertToAction()">
        {{ t("questionnaire.draft.history.revert") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.questionnaire-draft__global-history-drawer {
  max-width: 50vw;
}
</style>
