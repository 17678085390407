<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetUser } from "@/composables/services/useGetUser";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { ValidationRules } from "@/composables/validation/types";
import { useValidateForm } from "@/composables/validation/useValidateForm";
import {
  maxLength,
  required,
  mobilePhoneNumber,
  validateIf,
  email,
} from "@/composables/validation/validators";
import { authorize } from "@/plugins/can";
import { useAppStore } from "@/stores/app";
import { LoginType, MfaOption, Role } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import { useArchiveUser } from "./composables/useArchiveUser";
import { useCreateUser } from "./composables/useCreateUser";
import { useUpdateUser } from "./composables/useUpdateUser";
import { UserForm } from "./types";
import UserEditDrawerAccess from "./UserEditDrawerAccess.vue";
import UserEditDrawerAccount from "./UserEditDrawerAccount.vue";
import UserEditDrawerCompany from "./UserEditDrawerCompany.vue";
import UserEditDrawerLocation from "./UserEditDrawerLocation.vue";
import UserEditDrawerPersonal from "./UserEditDrawerPersonal.vue";

const props = defineProps<{
  userId: number | null;
}>();

const emit = defineEmits<{
  close: [void];
}>();

const app = useAppStore();
const { t } = useI18n({ useScope: "global" });

const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();
const { isLoadingUser, userDto, getUser } = useGetUser();

const userForm = ref(new UserForm());

const { createUser } = useCreateUser(emit);
const { updateUser } = useUpdateUser();
const { archiveUser } = useArchiveUser(emit);

const validationRules: ValidationRules<UserForm> = {
  email: { required, email, maxLength: maxLength(320) },
  firstName: { required, maxLength: maxLength(200) },
  lastName: { required, maxLength: maxLength(200) },
  city: { maxLength: maxLength(200) },
  company: { maxLength: maxLength(200) },
  country: { maxLength: maxLength(100) },
  mobilePhoneNumber: {
    required: validateIf(
      () => userForm.value.role !== Role.EXT && userForm.value.mfa === MfaOption.Sms,
      required,
    ),
    maxLength: maxLength(100),
    mobilePhoneNumber: validateIf(
      () => userForm.value.mobilePhoneNumber.length > 0,
      mobilePhoneNumber,
    ),
  },
  phoneNumber: { maxLength: maxLength(200) },
  title: { maxLength: maxLength(200) },
  userName: {
    validateIf: () => userForm.value.loginType != LoginType.Blue,
    required,
    maxLength: maxLength(255),
    custom: (v) => (v?.indexOf("@") === -1 ? "" : t("validations.notEmail")),
  },
  mfa: {
    validateIf: () => userForm.value.role !== Role.EXT,
    required,
    custom: (v) => (!v || v === MfaOption.None ? t("validations.required") : ""),
  },
};

const { isValid, errors, updateFormField } = useValidateForm(userForm, validationRules);

const isEditingUser = computed(() => !!props.userId);

const drawerTitle = computed(() =>
  isEditingUser.value
    ? t("users.editUserDrawerTitle", {
        fullName: `${userDto.value?.firstName} ${userDto.value?.lastName}`,
      })
    : t("users.createUser"),
);

const showArchiveButton = computed(() => {
  return (
    userDto.value &&
    isEditingUser &&
    !userDto.value.isArchived &&
    authorize.isSuperiorTo(Role.INT) &&
    authorize.isSuperiorTo(userDto.value.role)
  );
});

const closeDrawer = async () => {
  if (await discardUnsavedChanges(userForm)) emit("close");
};

const confirmUser = async () => {
  // Validate the form
  if (!isValid()) return;

  const form = userForm.value;

  // Update or create the user
  if (isEditingUser.value) {
    await updateUser(form);
  } else {
    await createUser(form, form.sendPasswordResetEmail, form.password1);
  }
  setCompareBase(userForm);
  emit("close");
};

onMounted(async () => {
  if (props.userId) {
    const user = await getUser(props.userId); // Fetch the current user
    userForm.value = new UserForm(user); // Set the form with the user data
    updateFormField("userId", props.userId); // Set the userId on the form
    setCompareBase(user);
  }
});
</script>

<template>
  <BaseDrawer width="50" :title="drawerTitle" :is-loading="isLoadingUser" @close="closeDrawer">
    <div class="user-edit-drawer">
      <UserEditDrawerAccount
        :password1="userForm.password1"
        :password2="userForm.password2"
        :send-password-reset-email="userForm.sendPasswordResetEmail"
        :is-editing-user="isEditingUser"
        :user-dto="userDto"
        :error-message="errors"
        :user-form="userForm"
        @update:password-reset-email="updateFormField('sendPasswordResetEmail', $event)"
        @update:password1="updateFormField('password1', $event)"
        @update:password2="updateFormField('password2', $event)"
        @update:field="updateFormField"
      />

      <UserEditDrawerAccess
        :user-id="userId"
        :current-user-role="app.user?.role"
        :is-editing-user="isEditingUser"
        :user-dto="userDto"
        :user-form="userForm"
        @update:role="
          (event) => {
            updateFormField('role', event);

            if (event === Role.EXT) {
              updateFormField('mfa', MfaOption.None);
            }
          }
        "
        @update:field="updateFormField"
      />

      <UserEditDrawerPersonal
        :error-message="errors"
        :user-form="userForm"
        @update:field="updateFormField"
      />

      <UserEditDrawerLocation
        :error-message="errors"
        :user-form="userForm"
        @update:field="updateFormField"
      />

      <UserEditDrawerCompany
        :error-message="errors"
        :user-form="userForm"
        @update:field="updateFormField"
      />
    </div>

    <template #footer-left>
      <BaseButton @click="confirmUser">
        {{ t("common.actions.confirm") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="closeDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton
        v-if="showArchiveButton"
        variant="outlined"
        color="error"
        @click="archiveUser(userId)"
      >
        {{ t("common.actions.archive") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.user-edit-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  padding: $spacing-4 $spacing-6;
}
</style>
