<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{ show: boolean }>();
defineEmits<{ (event: "toggle"): void }>();
</script>

<template>
  <BaseButton
    variant="text"
    :title="show ? t('blueRating.nav.hideNav') : t('blueRating.nav.showNav')"
    @click="$emit('toggle')"
  >
    <BaseIcon :icon="show ? 'arrow-left' : 'arrow-right'" />
  </BaseButton>
</template>
