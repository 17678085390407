import { computed, type Ref, type DeepReadonly } from "vue";
import type { ExecutiveReportAreaDto, ExecutiveReportCategoryDto } from "@/types/_generated/api";

export type CombinedAreaData = {
  name: string;
  percent: number;
  count: number;
};

export const useCombinedAreaData = (
  areas: Ref<ExecutiveReportAreaDto[]> | DeepReadonly<Ref<ExecutiveReportAreaDto[]>>,
  categories: Ref<ExecutiveReportCategoryDto[]> | DeepReadonly<Ref<ExecutiveReportCategoryDto[]>>,
) => {
  const combinedAreaData = computed<CombinedAreaData[]>(() => {
    // Create a map to count categories per area
    const areaCountMap = new Map<number, number>();

    // Count categories for each area
    categories.value.forEach((category) => {
      const currentCount = areaCountMap.get(category.areaId) || 0;
      areaCountMap.set(category.areaId, currentCount + 1);
    });

    // Filter and combine areas with their counts
    return areas.value
      .map((area) => ({
        name: area.areaName,
        percent: area.scorePercentAverage,
        count: areaCountMap.get(area.areaId) || 0, // Used to determine the colspan of the area in the footer
      }))
      .filter((area) => area.count > 0); // Filter out areas with no categories
  });

  return {
    combinedAreaData,
  };
};
