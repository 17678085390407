import { IStorage } from "./IStorage";
import { localStorage, sessionStorage } from "./WebStorage";

export const useStorage = () => {
  const availableStorage: readonly IStorage[] = [localStorage, sessionStorage] as const;

  const [storage] = availableStorage.filter((s: IStorage) => s.isSupported());

  return storage;
};
