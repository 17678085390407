<script setup lang="ts">
import { computed } from "vue";
import { vTruncationTitle } from "@/directives/truncationTitle";

type CellAbbreviation = { isVisible: boolean; abbreviation: string };

const props = defineProps<{
  color?: string;
  clickable?: boolean;
  completed?: CellAbbreviation;
  abeyance?: CellAbbreviation;
}>();

const classes = computed(() => ({
  [`blue-rating-table-cell-color--${props.color ? props.color.toLowerCase() : "value-missing"}`]:
    true,
  "blue-rating-table-cell-color--clickable": props.clickable,
}));

const displayedAbbreviation = computed(() =>
  // If both abeyance and completed are visible, abeyance should take precedence
  props.abeyance?.isVisible ? props.abeyance?.abbreviation : props.completed?.abbreviation,
);
</script>

<template>
  <td class="blue-rating-table-cell-color" :class="classes">
    <span
      v-if="completed?.isVisible || abeyance?.isVisible"
      class="blue-rating-table-cell-color__abbreviation"
    >
      <span v-truncation-title>{{ displayedAbbreviation }}</span>
    </span>
  </td>
</template>

<style scoped lang="scss">
.blue-rating-table-cell-color {
  position: relative;

  @include blue-rating-styles;

  &--value-missing {
    background: transparent;
  }

  &--clickable {
    cursor: pointer;
    transition: opacity $duration-100;

    &:hover {
      opacity: 0.8;
    }
  }

  &__abbreviation {
    position: absolute;
    inset: $spacing-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
