import { Ref, computed } from "vue";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { SiteDefinitions } from "./services/useGetSites";

export const useSiteDefinitionColumns = <
  T extends { custom1?: string; custom2?: string; custom3?: string; custom4?: string },
>(
  siteDefinitions: Ref<SiteDefinitions | null>,
) => {
  const siteDefinitionColumns = computed<ColumnDefinition<T>[]>(() => {
    const columns: ColumnDefinition<T>[] = [];

    for (let i = 1; i <= 4; i++) {
      const customDefinitionNumber = i as CustomDefinitionNumber;

      const siteDefinitionKey: keyof SiteDefinitions = `siteDefinition${customDefinitionNumber}`;
      const siteDefinition = siteDefinitions.value?.[siteDefinitionKey];
      const siteDefinitionHasValue = siteDefinition && siteDefinition.length > 0;

      if (siteDefinitionHasValue) {
        const customDefinitionKey = `custom${customDefinitionNumber}` as Extract<keyof T, string>;

        const defaultHidden = customDefinitionNumber >= 2;

        columns.push(
          new ColumnDefinition<T>({
            title: siteDefinition ?? "",
            key: customDefinitionKey,
            defaultHidden,
          }),
        );
      }
    }

    return columns;
  });

  return { siteDefinitionColumns };
};
