import { useI18n } from "vue-i18n";
import { SiteResponseStatus } from "@/types/_generated/api";
import { SiteResponseStatusColor } from "@/components/base/BaseChart/constants/colors";

type SiteResponseStatusChartMetaData = Record<SiteResponseStatus, { label: string; color: string }>;

export const useSiteResponseStatusChartMetaData = () => {
  const { t } = useI18n({ useScope: "global" });

  const statusData: SiteResponseStatusChartMetaData = {
    NotSet: { label: t("common.statuses.NotSet"), color: SiteResponseStatusColor.NotSet },
    NoProgress: {
      label: t("common.statuses.NoProgress"),
      color: SiteResponseStatusColor.NoProgress,
    },
    UnderReview: {
      label: t("common.statuses.UnderReview"),
      color: SiteResponseStatusColor.UnderReview,
    },
    Completed: { label: t("common.statuses.Completed"), color: SiteResponseStatusColor.Completed },
    Rejected: { label: t("common.statuses.Rejected"), color: SiteResponseStatusColor.Rejected },
    InProgress: {
      label: t("common.statuses.InProgress"),
      color: SiteResponseStatusColor.InProgress,
    },
  };

  return {
    statusData,
  };
};
