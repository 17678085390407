export const useDebounce = () => {
  let timeout: ReturnType<typeof setTimeout>;

  return (fn: () => void, wait = 300) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn();
    }, wait);
  };
};
