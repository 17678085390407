<script setup lang="ts">
import { useUniqueId } from "@/composables/useUniqueId";

defineProps<{
  value?: string | number;
  disabled?: boolean;
  max?: number;
}>();

defineEmits<{
  (event: "update:value", value: number | undefined): void;
}>();

const id = useUniqueId("rcba_number_field");

const getValue = (event: Event): number | undefined => {
  const { value } = event.target as HTMLInputElement;
  if (value === "") return undefined;
  return Number(value);
};
</script>

<template>
  <div class="rcba-number-field">
    <div
      class="rcba-number-field__box"
      :class="{
        'rcba-number-field__box--disabled': disabled,
      }"
    >
      <input
        :id="id"
        class="rcba-number-field__input"
        type="number"
        :value="value"
        spellcheck="false"
        :disabled="disabled"
        :max="max"
        @input="$emit('update:value', getValue($event))"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "/src/components/base/styles.scss";

.rcba-number-field {
  display: flex;
  flex-direction: column;

  &__box {
    position: relative;
    display: flex;
    @include input;

    &--disabled {
      @include input-disabled;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: $spacing-2;
  }

  &__input {
    width: 100%;
    background: inherit;
    padding: $spacing-3;
    border: none;
    color: inherit;
    border-radius: $rounded-base;
  }
}
</style>
