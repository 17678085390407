<script setup lang="ts" generic="T">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useUniqueId } from "@/composables/useUniqueId";
import BaseInputRequired from "@/components/base/BaseInputRequired.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import type { BasePillsEvents, BasePillsProps } from "./types";
import BasePillsList from "./BasePillsList.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<
    {
      values: T[];
      addOnEnter?: boolean;
      addOnSpace?: boolean;
      addOnTab?: boolean;
      maxLength?: number;
      notValid?: boolean;
    } & BasePillsProps
  >(),
  {
    values: () => [],
    label: "",
    placeholder: "",
    errors: () => [],
    color: "#f1f5f9",
    textColor: "#111010",
    block: false,
    imgColor: "",
    addOnEnter: true,
    addOnSpace: true,
    addOnTab: true,
    maxLength: undefined,
    notValid: false,
  },
);

const emit = defineEmits<BasePillsEvents>();

const newPill = ref("");
const input = ref();
const id = useUniqueId("base-pills__input");

const maxLengthError = ref(false);

const isNewPillInvalid = ref(props.notValid);

defineExpose({ input });

const addPill = (label: string) => {
  if (!label?.trim().length || (props.maxLength && label.length >= props.maxLength)) {
    return;
  }

  emit("add", label.trim());

  if (!isNewPillInvalid.value) {
    newPill.value = "";
  }
};

watch(newPill, () => {
  if (props.maxLength) {
    maxLengthError.value = newPill.value.length >= props.maxLength;
  }
});

watch(
  () => props.notValid,
  (newValue) => {
    isNewPillInvalid.value = newValue;

    // Clear `newPill` if validation becomes valid and there's a value
    if (!newValue && newPill.value.trim().length) {
      newPill.value = "";
      isNewPillInvalid.value = true;
    }
  },
);
</script>

<template>
  <div class="base-pills">
    <div v-if="label" class="base-pills__label">
      <BaseLabel has-spacing :for-id="id">
        {{ label }}
      </BaseLabel>
      <BaseInputRequired v-if="required" />
    </div>

    <div
      :class="{
        'base-pills__box': !$slots.input,
        'base-pills__box--error':
          (props.errors && props.errors.length) ||
          (maxLength && newPill?.length && newPill?.length > maxLength),
        'base-pills__box--disabled': disabled || readonly,
      }"
    >
      <input
        :id="id"
        v-model="newPill"
        :disabled="disabled"
        :placeholder="placeholder"
        class="base-pills__input"
        type="text"
        :maxlength="props.maxLength"
        @keydown.enter.prevent="addOnEnter ? addPill(newPill) : undefined"
        @keydown.space.prevent="addOnSpace ? addPill(newPill) : undefined"
        @keydown.tab.prevent="addOnTab ? addPill(newPill) : undefined"
        @blur="addPill(newPill)"
      />
    </div>

    <BasePillsList v-if="values.length" :is-below-input="!errors?.length">
      <template v-for="(value, i) in values" :key="i">
        <slot name="pill" :value="value" :index="i"></slot>
      </template>
    </BasePillsList>

    <p v-if="newPill && maxLengthError" class="base-pills__error">
      {{ t("validations.maxLengthLengthReached") }}
    </p>

    <div v-if="errors?.length">
      <p v-for="({ $message }, i) in errors" :key="i" class="base-pills__error">
        {{ $message }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../styles";

.base-pills {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__box {
    position: relative;
    @include input;
    border-radius: $rounded-base $rounded-base 0 0;

    &--disabled {
      @include input-disabled;
    }

    &--error {
      @include input-error;
      border-radius: $rounded-base;
    }
  }

  &__input {
    width: 100%;
    background: inherit;
    padding: $spacing-3;
    border: none;
    color: inherit;
    border-radius: $rounded-base;
  }

  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin: $spacing-1 0;
    font-size: $text-sm;
  }
}
</style>
