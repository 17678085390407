import { reactive } from "vue";
import { Language, LibraryFileDto } from "@/types/_generated/api";

export const useLibraryFileForm = () => {
  const form = reactive<Pick<LibraryFileDto, "language" | "isVisibleInUserWeb" | "tags">>({
    language: Language.English,
    isVisibleInUserWeb: false,
    tags: [],
  });

  const setFormFromExistingFile = (file: LibraryFileDto) => {
    form.language = file.language;
    form.isVisibleInUserWeb = file.isVisibleInUserWeb;
    form.tags = [...file.tags];
  };

  return {
    form,
    setFormFromExistingFile,
  };
};
