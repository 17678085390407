<script setup lang="ts">
import { onBeforeMount, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import type { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { useGetSites } from "@/composables/services/useGetSites";
import { useArchivedStatusFilter } from "@/composables/useArchivedStatusFilter";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useTableFilter } from "@/composables/useTableFilter";
import { AdminRouteName } from "@/router/RouteName";
import { getModuleSites, sitesEvents } from "@/services/sites";
import { useAppStore } from "@/stores/app";
import { useModuleSiteTableColumns } from "@/views/Admin/Modules/composables/useModuleSiteTableColumns";
import SiteEditDrawer from "@/views/Admin/Sites/components/SiteEditDrawer/SiteEditDrawer.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import DaysWithNoResponseColumnHeading from "@/components/shared/Sites/DaysWithNoResponseColumnHeading.vue";
import RecommendationResponseColumnHeading from "@/components/shared/Sites/RecommendationResponseColumnHeading.vue";
import ReminderEmailCountColumnHeading from "@/components/shared/Sites/ReminderEmailCountColumnHeading.vue";
import ReportDownLoadStatusColumnHeading from "@/components/shared/Sites/ReportDownLoadStatusColumnHeading.vue";
import SiteRecommendationStatusCell from "@/components/shared/Sites/SiteRecommendationStatusCell.vue";

const props = defineProps<{ moduleId: number }>();

const { t } = useI18n({ useScope: "global" });
const showArchived = ref(false);
const editSite = ref<number | null>(null);

// A edit click in the table will open the drawer in this component
// and the create event will open the drawer in the parent component.
useBroadcastService(sitesEvents, async () => {
  sites.value = (await getModuleSites(props.moduleId))?.sites ?? [];
});

const app = useAppStore();
const { filterArchived, archivedMenuOption } = useArchivedStatusFilter(showArchived);

const { getSites, isLoadingSites, siteDefinitions, sites } = useGetSites();
const { columns } = useModuleSiteTableColumns(siteDefinitions);

const getCustomColumnSlotName = (i: number): `column-custom${CustomDefinitionNumber}` => {
  return `column-custom${i as CustomDefinitionNumber}`;
};

onBeforeMount(async () => await getSites(props.moduleId));

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(toRef(sites), columns, [filterArchived]);
</script>

<template>
  <SiteEditDrawer
    v-if="editSite"
    :site-id="editSite"
    :module-id="props.moduleId"
    @close-drawer="editSite = null"
  />

  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoadingSites"
    storage-key="moduleSitesTable"
    :custom-options="app.isAdmin ? [archivedMenuOption] : []"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #header-reportDownloadStatus>
      <ReportDownLoadStatusColumnHeading />
    </template>

    <template #header-recResponseSubmitDate>
      <RecommendationResponseColumnHeading />
    </template>

    <template #header-reminderEmailCount>
      <ReminderEmailCountColumnHeading />
    </template>

    <template #header-daysWithNoResponse>
      <DaysWithNoResponseColumnHeading />
    </template>

    <template #column-name="{ cell, row }">
      <router-link :to="{ name: AdminRouteName.Site, params: { siteId: row.siteId } }" :title="cell"
        >{{ row.name.length > 0 ? cell : t("sites.site") }}
      </router-link>
    </template>

    <template
      v-for="i in [1, 2, 3, 4] as CustomDefinitionNumber[]"
      :key="i"
      #[getCustomColumnSlotName(i)]="{ cell, row }"
    >
      <router-link
        :to="{ name: AdminRouteName.Site, params: { siteId: row.siteId } }"
        :title="row[`custom${i}`]"
      >
        {{ cell }}
      </router-link>
    </template>

    <template #column-status="{ row }">
      <BaseStatusBadge :status="row.status" />
    </template>
    <template #column-latestSurveyStatus="{ row }">
      <BaseStatusBadge v-if="row.latestSurveyStatus" :status="row.latestSurveyStatus" />
    </template>
    <template #actions="{ row }">
      <template v-if="$authorize.hasModulePermission(moduleId, 'createSite')">
        <EditIconButton :title="t('common.actions.edit')" @click="() => (editSite = row.siteId)" />
      </template>
    </template>
    <template #column-reportDownloadStatus="{ row }">
      <SiteRecommendationStatusCell :value="row.reportDownloadStatus" />
    </template>
    <template #column-recResponseSubmitDate="{ row }">
      <SiteRecommendationStatusCell :value="row.recResponseSubmitDate ? 'Green' : 'None'" />
    </template>
  </BaseGridTable>
</template>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
</style>
