<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.11808 2.19015C8.658 1.27012 7.34732 1.26992 6.88696 2.18974L1.63325 12.6868C1.21725 13.5179 1.82051 14.497 2.7487 14.497H13.2513C14.1793 14.497 14.7826 13.5183 14.367 12.6872M14.367 12.6872L9.11808 2.19015C9.11808 2.19015 9.11808 2.19016 9.11808 2.19015M5.54842 1.51745C6.56126 -0.506252 9.44473 -0.505676 10.4569 1.51834L15.7058 12.0154C16.62 13.8439 15.2929 15.997 13.2513 15.997H2.7487C0.706686 15.997 -0.620513 13.8431 0.294722 12.0145L5.54842 1.51745C5.54843 1.51745 5.54842 1.51745 5.54842 1.51745Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99933 4.99702C8.55064 4.99702 8.99757 5.44474 8.99757 5.99702V7.99702C8.99757 8.54931 8.55064 8.99702 7.99933 8.99702C7.44802 8.99702 7.0011 8.54931 7.0011 7.99702V5.99702C7.0011 5.44474 7.44802 4.99702 7.99933 4.99702Z"
    />
    <path
      d="M7.99933 12.997C8.55064 12.997 8.99757 12.5493 8.99757 11.997C8.99757 11.4447 8.55064 10.997 7.99933 10.997C7.44802 10.997 7.0011 11.4447 7.0011 11.997C7.0011 12.5493 7.44802 12.997 7.99933 12.997Z"
    />
  </svg>
</template>
