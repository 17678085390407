<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.734 1.58399C11.1139 1.2041 11.0048 0.563875 10.4943 0.396505C9.7094 0.139177 8.87095 0 8 0C3.58173 0 0 3.58173 0 8C0 8.87095 0.139177 9.7094 0.396505 10.4943C0.563875 11.0048 1.2041 11.1139 1.58399 10.734V10.734C1.80405 10.514 1.86864 10.1857 1.77839 9.88783C1.59734 9.29039 1.5 8.65656 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C8.65656 1.5 9.29039 1.59734 9.88783 1.77839C10.1857 1.86864 10.514 1.80405 10.734 1.58399V1.58399ZM4.53911 14.1429C4.7795 13.9025 5.1471 13.852 5.45998 13.985C6.24028 14.3166 7.09872 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 7.09872 14.3166 6.24028 13.985 5.45999C13.852 5.1471 13.9025 4.7795 14.1429 4.53911V4.53911C14.4966 4.18537 15.0898 4.25675 15.2956 4.71273C15.7481 5.71553 16 6.82835 16 8C16 12.4183 12.4183 16 8 16C6.82835 16 5.71553 15.7481 4.71273 15.2956C4.25675 15.0898 4.18537 14.4966 4.53911 14.1429V14.1429Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7803 0.21967C16.0732 0.512563 16.0732 0.987437 15.7803 1.28033L1.28033 15.7803C0.987437 16.0732 0.512563 16.0732 0.21967 15.7803C-0.0732233 15.4874 -0.0732233 15.0126 0.21967 14.7197L14.7197 0.21967C15.0126 -0.0732233 15.4874 -0.0732233 15.7803 0.21967Z"
    />
  </svg>
</template>
