<script setup lang="ts">
import { computed } from "vue";
import UWInputErrorMessage from "../InputErrorMessage/UWInputErrorMessage.vue";

export interface Props {
  value: string;
  type: "date" | "time" | "datetime-local";
  min?: string;
  max?: string;
  disabled?: boolean;
  errorMessage?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  input: [value: string];
}>();

const styleClasses = computed(() => ({
  "base-datepicker": true,
  "base-datepicker--has-error": props.errorMessage,
}));

// Function to format the date to a value that the input can understand
const formatDateTime = (dateStr: string) => {
  // Format only if the type is 'datetime-local' and the string is longer than the expected format
  if (props.type === "datetime-local" && dateStr.length > 16) {
    const [date, time] = dateStr.split(" ");
    const [year, month, day] = date.split("-");
    const [hour, minute] = time.split(":");
    return `${year}-${month}-${day}T${hour}:${minute}`;
  }
  return dateStr;
};

const toISOFormat = (dateStr: string) => {
  // Assuming dateStr could be in "2023-10-18 10:29" format or already in ISO format
  if (dateStr.includes("T")) return dateStr; // Already in ISO format

  // Convert to ISO format
  return dateStr.replace(" ", "T");
};

// Corrects the input to within the range of the min/max value
// Since the datepicker UI can't be 100% trusted
const adjustValueWithinRange = (input: HTMLInputElement) => {
  const { rangeUnderflow, rangeOverflow } = input.validity;

  if (props.min && rangeUnderflow) {
    // Use the min value if it's not in the acceptable range
    return toISOFormat(props.min);
  } else if (props.max && rangeOverflow) {
    // Use the max value if it's not in the acceptable range
    return toISOFormat(props.max);
  } else {
    // Use the value from the input if it's in the acceptable range
    return input.value;
  }
};

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement;

  // Ensure that the input value is within the min and max range
  const finalValue = adjustValueWithinRange(input);

  // Sync the DOM input value with the final emitted value
  input.value = finalValue;

  // Emit the final value
  emit("input", finalValue);
};

const formattedValue = computed(() => formatDateTime(props.value));
</script>

<template>
  <input
    :class="styleClasses"
    :disabled="disabled"
    :type="type"
    :min="min"
    :max="max"
    :value="formattedValue"
    @input="handleInput"
  />

  <UWInputErrorMessage v-if="errorMessage">
    {{ errorMessage }}
  </UWInputErrorMessage>
</template>

<style scoped lang="scss">
.base-datepicker {
  padding: $spacing-2 $spacing-3;
  border: 1px solid $primary-7;
  border-radius: $rounded-base;
  height: $shared-component-height;
  width: 100%;
  font-size: $text-sm;

  &--has-error {
    border-color: $error-3;
  }
}
</style>
