import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  GetActiveServiceMessagesResponse,
  GetServiceMessagesResponse,
  GetServiceMessageResponse,
  SaveServiceMessage,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const serviceMessageEvents = new BroadcastService("service-messages");

export const getActiveServiceMessages = async (): Promise<
  GetActiveServiceMessagesResponse["messages"] | null
> => {
  try {
    const {
      data: { messages },
    } = await http.get<GetActiveServiceMessagesResponse>("/service-messages/active");
    return messages;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("serviceMessages.activeServiceMessages").toLowerCase(),
    });
  }
  return null;
};

export const getServiceMessages = async (): Promise<
  GetServiceMessagesResponse["messages"] | null
> => {
  try {
    const {
      data: { messages },
    } = await http.get<GetServiceMessagesResponse>("/service-messages");
    return messages;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("serviceMessages.serviceMessages").toLowerCase(),
    });
  }
  return null;
};

export const getServiceMessage = async (
  id: number,
): Promise<GetServiceMessageResponse["message"] | null> => {
  try {
    const {
      data: { message },
    } = await http.get<GetServiceMessageResponse>(`/service-messages/${id}`);
    return message;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("serviceMessages.serviceMessage").toLowerCase(),
    });
  }
  return null;
};

export const saveServiceMessage = async (message: SaveServiceMessage): Promise<boolean> => {
  try {
    await http.put(`/service-messages/`, message);

    serviceMessageEvents.post({ action: "update", id: message.id });

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("serviceMessages.serviceMessage").toLowerCase(),
    });
  }
  return false;
};
