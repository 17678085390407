<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BlueRatingRecommendationDto, SurveyorStatus } from "@/types/_generated/api";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

const slotNames = [
  "properties",
  "rcba",
  "recommendation-response",
  "risk-manager-comment",
  "response-timeline",
  "comments",
] as const;

defineProps<{
  recommendation: BlueRatingRecommendationDto;
  hideStatus?: boolean;
  abeyanceTranslation?: string;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="online-report-recommendation">
    <div class="online-report-recommendation__header">
      <h2 class="online-report-recommendation__header__title">
        {{ t("userWeb.onlineReport.recommendations.recommendation") }}
        {{ recommendation.fullPosition }}
      </h2>
      <BaseStatusBadge v-if="!hideStatus" :status="recommendation.surveyorStatus">
        <template
          v-if="abeyanceTranslation && recommendation.surveyorStatus === SurveyorStatus.Abeyance"
        >
          {{ abeyanceTranslation }}
        </template>
      </BaseStatusBadge>
    </div>

    <div class="online-report-recommendation__content">
      <template
        v-for="(slot, index) of slotNames"
        :key="`online-report-recommendation-slot-${index}`"
      >
        <slot :name="slot"></slot>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.online-report-recommendation {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: $spacing-2 0;
    border-bottom: 1px solid $primary-4;
    margin-bottom: $spacing-4;

    &__title {
      font-size: $text-lg;
      font-weight: $font-semibold;
    }
  }

  &__content {
    gap: $spacing-4;
    display: flex;
    flex-direction: column;
  }

  @media print {
    page-break-after: always;
    page-break-inside: avoid;

    &:last-child {
      page-break-after: auto;
    }
  }
}
</style>
