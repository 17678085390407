import { reactive } from "vue";
import type { ReportMailRecipient } from "../types";

export const useReportMailRecipients = () => {
  const recipients = reactive<ReportMailRecipient[]>([]);

  const addRecipient = (addedRecipient: ReportMailRecipient) => {
    recipients.push(addedRecipient);
  };

  const deleteRecipient = (recipientIndex: number) => {
    recipients.splice(recipientIndex, 1);
  };

  const updateRecipient = (updatedRecipient: { index: number; value: ReportMailRecipient }) => {
    const { index, value } = updatedRecipient;

    // Check if the index is valid
    if (index >= 0 && index < recipients.length) {
      recipients[index] = { ...recipients[index], ...value };
    } else {
      throw new Error("Invalid index. Recipient not updated.");
    }
  };

  return {
    recipients,
    addRecipient,
    deleteRecipient,
    updateRecipient,
  };
};
