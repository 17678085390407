<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { convertHtmlToPlainText } from "@/helpers/convertHtmlToPlainText";
import { BlueRatingRecommendationLeanDto } from "@/types/_generated/api";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

interface Props {
  recommendation: BlueRatingRecommendationLeanDto;
}

defineProps<Props>();
defineEmits<{
  click: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div
    class="blue-rating-inline-recommendation"
    :title="
      recommendation.isGeneralRecommendation
        ? t('blueRating.recommendation.editGeneralRecommendation')
        : t('blueRating.recommendation.editRecommendation')
    "
    @click="$emit('click')"
  >
    <span>{{ recommendation.fullPosition }}</span>
    <span class="blue-rating-inline-recommendation__title">
      {{ recommendation.header }}
    </span>
    <span class="blue-rating-inline-recommendation__text">
      {{ convertHtmlToPlainText(recommendation.text) }}
    </span>
    <BaseIcon
      v-if="recommendation.isKeyRecommendation"
      class="blue-rating-inline-recommendation__attributes"
      :title="t('blueRating.recommendation.properties.isKeyRecommendation')"
      icon="key"
    />
  </div>
</template>

<style scoped lang="scss">
.blue-rating-inline-recommendation {
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) 2fr auto;
  gap: $spacing-2;
  align-items: center;

  border-radius: $rounded-base;
  padding: $spacing-3;

  cursor: pointer;
  &:hover {
    background-color: $primary-2;
  }

  &__icon,
  &__attributes {
    align-items: center;
  }

  &__title,
  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__title {
    color: $primary-8;
  }
  &__text {
    color: $primary-6;
    font-size: $text-sm;
  }
}
</style>
