import { reactive } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import {
  IDataAnalysisFilter,
  LossScenarioDto,
  SiteDefinitionFilter,
  SurveyType,
} from "@/types/_generated/api";

export class DaatServerFilter implements IDataAnalysisFilter {
  groupBySiteDefinition?: number | undefined;
  surveyDateMin?: string | undefined;
  surveyDateMax?: string | undefined;
  surveyType?: SurveyType | undefined;
  excludedSiteDefinitions: SiteDefinitionFilter;
  lossScenarioIds: Set<number> = new Set();

  constructor(lossScenarios?: LossScenarioDto[]) {
    this.excludedSiteDefinitions = {
      custom1: [],
      custom2: [],
      custom3: [],
      custom4: [],
    };

    this.setLossScenarioIds(lossScenarios ?? []);
  }

  setLossScenarioIds(lossScenarios: LossScenarioDto[]) {
    this.lossScenarioIds = new Set(
      lossScenarios.map((ls) => {
        if (!ls.id) {
          throw new Error("Loss scenario ID is required");
        }

        return ls.id;
      }),
    );
  }
}

export const useFilterServer = (onUpdateFilter: () => Promise<void>) => {
  const serverFilter = reactive<DaatServerFilter>(new DaatServerFilter());

  const updateServerFilter = async (newFilter: DaatServerFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(serverFilter, newFilter)) return;

    // Update the filter state with the incoming new values
    Object.assign(serverFilter, newFilter);

    await onUpdateFilter();
  };

  return {
    serverFilter,
    updateServerFilter,
  };
};
