<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { SortOrder } from "@/constants/SortOrder";
import { getActivityLog } from "@/services/system-logs";
import { ActivityLogEntryDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import UserLink from "@/components/shared/UserLink.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const log = ref<ActivityLogEntryDto[]>([]);

onBeforeMount(async () => {
  isLoading.value = true;
  log.value = (await getActivityLog()) ?? [];
  isLoading.value = false;
});

const columns: ColumnDefinition<ActivityLogEntryDto>[] = [
  new ColumnDefinition({
    title: t("systemLog.id"),
    key: "id",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.currentUser"),
    key: "currentUserAuthName",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.impersonatedBy"),
    key: "impersonatedByUserAuthName",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.activity"),
    key: "type",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.user"),
    key: "userAuthName",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.module"),
    key: "moduleName",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.siteId"),
    key: "siteId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "Site Definition 1",
    key: "siteDefinition1",
  }),
  new ColumnDefinition({
    title: "Site Definition 2",
    key: "siteDefinition2",
  }),
  new ColumnDefinition({
    title: "Site Definition 3",
    key: "siteDefinition3",
  }),
  new ColumnDefinition({
    title: "Site Definition 4",
    key: "siteDefinition4",
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.surveyDate"),
    key: "surveyDate",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.userAgent"),
    key: "userAgent",
  }),
  new ColumnDefinition({
    title: t("systemLog.activityLog.date"),
    key: "insertDate",
    autoWidth: true,
    type: ColumnDataType.DateTime,
  }),
];

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(log, columns);

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :columns="columns"
    :is-loading="isLoading"
    :default-sort="[{ key: 'insertDate', order: SortOrder.Descending }]"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>
    <template #header-currentUserAuthName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #header-userAuthName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #header-impersonatedByUserAuthName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>
    <template #column-currentUserAuthName="{ row }">
      <UserLink
        v-if="!!(row.currentUserAuthName && row.currentUserRole)"
        :label="row.currentUserAuthName"
        :user-id="row.currentUserId"
        :user-role="row.currentUserRole"
        @click="openUserDetailDrawer"
      />
    </template>
    <template #column-userAuthName="{ row }">
      <UserLink
        v-if="!!(row.userAuthName && row.userRole)"
        :label="row.userAuthName"
        :user-id="row.userId"
        :user-role="row.userRole"
        @click="openUserDetailDrawer"
      />
    </template>
  </BaseGridTable>

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>
