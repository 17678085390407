import { FileDto } from "@/types/_generated/api";

const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const convertFiles = async (fileList: FileList | null) => {
  const files = Array.from(fileList ?? []);
  const filesAsBase64: FileDto[] = [];

  for (const file of files) {
    const fileAsBase64 = await toBase64(file);
    const strippedType = (fileAsBase64 as string)?.split(",")[1];
    filesAsBase64.push({ fileName: file.name, file: strippedType, fileSize: file.size });
  }

  return filesAsBase64;
};
