<script setup lang="ts">
import { UserDto, UserModulePermissionDto } from "@/types/_generated/api";
import UserDetailsPermissionsTable from "./UserDetailsPermissionsTable.vue";

const props = defineProps<{
  user: UserDto;
  userModulePermissions: UserModulePermissionDto[];
}>();
</script>

<template>
  <UserDetailsPermissionsTable :user-role="props.user.role" :permissions="userModulePermissions" />
</template>
