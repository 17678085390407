<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import { AdminRouteName } from "@/router/RouteName";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";

const { t } = useI18n({ useScope: "global" });

const TABS: NavTab[] = [
  { title: t("systemLog.loginAttemptLog"), path: AdminRouteName.LoginAttemptLog },
  { title: t("systemLog.errorLog.title"), path: AdminRouteName.ErrorLog },
  { title: t("systemLog.activityLog.title"), path: AdminRouteName.ActivityLog },
  { title: t("serviceMessages.serviceMessages"), path: AdminRouteName.ServiceMessages },
];
</script>

<template>
  <AdminHeader :breadcrumbs="[{ title: t('navigation.systemAdministration') }]" />

  <AdminViewLayout :tabs="TABS">
    <router-view />
  </AdminViewLayout>
</template>
