<script setup lang="ts">
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useModuleUsersOptions } from "@/composables/useModuleUsersOptions";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import type { ReportMailRecipient } from "../types";
import ReportMailRecipientForm from "./ReportMailRecipientForm.vue";

const props = defineProps<{
  moduleId: number;
  isMobileRequired: boolean;
}>();

const emit = defineEmits<{
  "add:recipient": [ReportMailRecipient];
}>();

const { t } = useI18n({ useScope: "global" });
const { moduleUserOptions, moduleUsers } = useModuleUsersOptions(props.moduleId);

const initialRecipientState: ReportMailRecipient = {
  email: "",
  mobile: "",
  includeRecommendationResponseLink: false,
  reminderDates: [],
  continueRemindingWhenSiteResponseIsGiven: false,
  firstName: "",
  lastName: "",
  company: "",
  title: "",
};

const selectedRecipient = reactive<ReportMailRecipient>({ ...initialRecipientState });

const resetSelectedRecipient = () => {
  Object.assign(selectedRecipient, initialRecipientState);
};

const updateFromModuleUser = (userId: string) => {
  const user = moduleUsers.value?.find((u) => u.id === Number(userId));
  if (!user) throw new Error("User not found");

  Object.assign(selectedRecipient, {
    ...initialRecipientState,
    email: user.email,
    mobile: user.mobilePhoneNumber,
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company,
    title: user.title,
  });
};

const addRecipient = (recipient: ReportMailRecipient) => {
  resetSelectedRecipient();

  // Newly added recipients should be included by default
  emit("add:recipient", { ...recipient, includeRecipient: true });
};
</script>

<template>
  <div class="report-mail-add-recipient">
    <BaseSelect
      :label="t('survey.surveyReportMail.selectExistingUser')"
      @change="updateFromModuleUser($event)"
    >
      <BaseOption v-for="option in moduleUserOptions" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseSelect>

    <div class="report-mail-add-recipient__manual">
      <hr />
      {{ t("survey.surveyReportMail.orFillIn") }}
      <hr />
    </div>

    <ReportMailRecipientForm
      :incoming-recipient="selectedRecipient"
      :is-mobile-required="isMobileRequired"
      @confirm:recipient="addRecipient"
    />
  </div>
</template>

<style lang="scss" scoped>
.report-mail-add-recipient {
  display: grid;
  gap: $spacing-4;

  &__manual {
    display: flex;
    align-items: center;
    text-align: center;

    hr {
      display: inline-block;
      border-top: 1px solid $primary-4;
      width: 100%;
      margin: $spacing-4;
    }
  }
}
</style>
