import { Ref } from "vue";
import { BlueRatingNavigation, BlueRating } from "@/types/Survey";

export const useBlueRatingNavToggle = (survey: Ref<BlueRating | null>) => {
  const collapseOrExpandAll = function () {
    if (!survey.value) {
      return;
    }

    if (survey.value.expanded === undefined) {
      survey.value.expanded = false;
    } else {
      survey.value.expanded = !survey.value.expanded;
    }

    for (const area of survey.value.areas) {
      area.expanded = survey.value.expanded;
      for (const category of area.categories) {
        category.expanded = survey.value.expanded;
      }
    }
  };

  const toggleExpanded = function (payload: { pointer: BlueRatingNavigation; value: boolean }) {
    if (!survey.value) {
      return;
    }

    const { areaId, categoryId } = payload.pointer;

    const area = survey.value.areas.find((a) => a.areaId === areaId);

    if (area && !categoryId) {
      area.expanded = payload.value;
    }

    const category = area?.categories.find((c) => c.categoryId === categoryId);

    if (category) {
      category.expanded = payload.value;
    }
  };

  return { collapseOrExpandAll, toggleExpanded };
};
