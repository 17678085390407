<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { UpdateFormField, ValidationErrors } from "@/composables/validation/types";
import BaseDataDisplayListTitle from "@/components/base/BaseDataDisplay/BaseDataDisplayListTitle.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { UserForm } from "./types";

defineProps<{
  errorMessage: ValidationErrors<UserForm>;
  userForm: UserForm;
}>();

defineEmits<{
  "update:field": UpdateFormField<UserForm>;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="user-edit-location">
    <BaseDataDisplayListTitle>{{ t("users.location") }}</BaseDataDisplayListTitle>

    <div class="user-edit-location__fields">
      <BaseTextField
        :label="t('common.city')"
        :error-message="errorMessage.city"
        :value="userForm.city"
        @update:value="$emit('update:field', 'city', String($event))"
      />
      <BaseTextField
        :label="t('common.country')"
        :error-message="errorMessage.country"
        :value="userForm.country"
        @update:value="$emit('update:field', 'country', String($event))"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-edit-location {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-4;
  }
}
</style>
