<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useGetSite } from "@/composables/services/useGetSite";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { BroadcastMessage } from "@/services/shared/BroadcastService";
import { sitesEvents } from "@/services/sites";
import SiteDetailsDefinitions from "@/components/shared/Sites/SiteDetailsDefinitions.vue";
import SiteDetailsInfo from "@/components/shared/Sites/SiteDetailsInfo.vue";
import SiteDetailsMap from "@/components/shared/Sites/SiteDetailsMap.vue";
import SiteReportImage from "@/components/shared/Sites/SiteReportImage.vue";
import SiteDetailsSurveyInfo from "./SiteDetailsSurveyInfo.vue";

const props = defineProps<{ siteId: number }>();

const { getSite, siteData, isLoadingSite } = useGetSite();

useBroadcastService(sitesEvents, async (message: BroadcastMessage) => {
  if (message.action === "update" && message.id) {
    await getSite(message.id as number);
  }
});

onBeforeMount(async () => await getSite(props.siteId));
</script>

<template>
  <div class="site-details">
    <div class="site-details__side">
      <SiteDetailsSurveyInfo :is-loading-site="isLoadingSite" :site="siteData" />
      <SiteDetailsDefinitions :is-loading-site="isLoadingSite" :site="siteData" />
      <SiteDetailsInfo :is-loading-site="isLoadingSite" :site="siteData" />
    </div>

    <div class="site-details__side">
      <SiteDetailsMap
        :is-loading-site="isLoadingSite"
        :latitude="siteData?.latitude"
        :longitude="siteData?.longitude"
      />
      <SiteReportImage
        v-if="siteData?.reportImageFileId"
        :site-id="siteId"
        :file-id="siteData.reportImageFileId"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.site-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $spacing-8;

  &__side {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
  }
}
</style>
