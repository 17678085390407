import { createPinia } from "pinia";
import { createApp } from "vue";
import { CanPlugin } from "@/plugins";
import App from "./App.vue";
import { i18n } from "./i18n";
import router from "./router";
import { handleError } from "./services/shared/handleError";
import { isDevelopment } from "./stores/dev";

const app = createApp(App);

app.config.errorHandler = function (error) {
  handleError(error);

  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

app.use(createPinia()).use(router).use(i18n).use(CanPlugin).mount("#app");
