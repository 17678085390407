<script setup lang="ts">
import { useI18n } from "vue-i18n";
import AuthWrapper from "@/views/Auth/components/AuthWrapper.vue";
import BaseAlert from "@/components/base/BaseAlert.vue";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <AuthWrapper>
    <BaseAlert severity="info"> {{ t("signIn.signedOutMessage") }}. </BaseAlert>
  </AuthWrapper>
</template>
