/**
 * Returns color mix string for use with style or css.
 * If only @param i is provided, it calculates a color from i and mixes with $primary-4
 * If @param j is also provided, it calculates a color from both i and j and mixes with $primary-5
 * If @param k is also provided, it calculates a color from all three and mixes with $primary-6
 * @param i number
 * @param j number or undefined
 * @param k number or undefined
 * @returns string: `color-mix(in srgb, ${$primary_(3|4|5)} 75%, hsl(${0-360}, 100%, 87.5%)`
 */
export const useColorMix = (i: number, j?: number, k?: number) => {
  const h = (i * 48 + (j ?? 0) * 40 + (k ?? 0) * 32) % 360;

  let primary = "#cbd5e1";
  if (typeof j !== "undefined") primary = "#94a3b8";
  if (typeof k !== "undefined") primary = "#64748b";

  // hsl is (rotation in degrees, saturation, lightness)
  // 100% lightness used with color-mix will mix with white
  // and return the {primary} color
  return `color-mix(in srgb, ${primary} 75%, hsl(${h}, 100%, 87.5%)`;
};
