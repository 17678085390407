<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { RecommendationTrackingFilter } from "@/types/_generated/api";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";

defineProps<{
  recommendationNumber?: RecommendationTrackingFilter["recommendationNumber"];
}>();

defineEmits<{
  "update:rec-number": [string];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div>
    <BaseLabel has-spacing>
      {{ t("userWeb.recommendationTracking.recNumber") }}
    </BaseLabel>
    <UWTextField
      :value="recommendationNumber ?? ''"
      @input="$emit('update:rec-number', $event.trim())"
    />
  </div>
</template>
