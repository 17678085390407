<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { displayValue } from "@/helpers/displayValue";
import { formatDate } from "@/helpers/formatDate";
import { getUserService } from "@/services/users";
import { useAppStore } from "@/stores/app";
import { UserDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const app = useAppStore();

const isLoadingUser = ref(false);
const user = ref<UserDto>();

onMounted(async () => {
  if (!app.user?.id) return;

  isLoadingUser.value = true;
  const response = await getUserService(app.user.id);
  user.value = response || undefined;
  isLoadingUser.value = false;
});
</script>

<template>
  <UWDrawer
    size="small"
    :drawer-title="t('userMenu.userDetails')"
    @close-drawer="$emit('closeDrawer')"
  >
    <BaseDataDisplayList>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.name')">
        <p>{{ displayValue(user?.fullName) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.username')">
        <p>{{ displayValue(user?.userName) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.email')">
        <p>{{ displayValue(user?.email) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.phone')">
        <p>{{ displayValue(user?.phoneNumber) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.mobile')">
        <p>{{ displayValue(user?.mobilePhoneNumber) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('users.professionalTitle')">
        <p>{{ displayValue(user?.title) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.company')">
        <p>{{ displayValue(user?.company) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.city')">
        <p>{{ displayValue(user?.city) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.country')">
        <p>{{ displayValue(user?.country) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('common.language')">
        <p>{{ displayValue(user?.language) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoadingUser" :label="t('users.lastLogin')">
        <p>{{ displayValue(formatDate.asDateAndTime(user?.lastLogin)) }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>
  </UWDrawer>
</template>
