<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "../BaseButton.vue";
import BaseIcon from "../BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  disabled?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  click: [void];
}>();
</script>

<template>
  <div class="base-filea-area-instructions">
    <span>
      {{ t("library.fileArea.dropHere") }}
    </span>

    <BaseButton :disabled="disabled" variant="outlined" @click="emit('click')">
      <BaseIcon icon="folder-open" />
      {{ t("library.fileUpload.browse") }}
    </BaseButton>

    <span>
      {{ t("library.fileArea.dropHereOr") }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.base-filea-area-instructions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
