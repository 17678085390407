import { isSuperior } from "@/plugins/can";
import { Role } from "@/types/_generated/api";

export const sortByRole = (u1: { role: Role }, u2: { role: Role }, descending = true) => {
  let lhs = u1.role;
  let rhs = u2.role;

  if (!descending) {
    lhs = u2.role;
    rhs = u1.role;
  }

  if (isSuperior(lhs, rhs)) {
    return -1;
  }

  if (isSuperior(rhs, lhs)) {
    return 1;
  }

  return 0;
};
