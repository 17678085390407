<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseDateField from "@/components/base/BaseDateField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  surveyDate?: string;
  errorMessage?: string;
}>();

defineEmits<{
  "update:value": [string];
}>();

const errors = computed(() =>
  props.errorMessage ? [{ $message: props.errorMessage } as ErrorObject] : undefined,
);
</script>

<template>
  <BaseDateField
    :value="surveyDate"
    :label="t('survey.editSurvey.surveyDate')"
    :errors="errors"
    required
    @change:value="$emit('update:value', $event as string)"
  />
</template>
