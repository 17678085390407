import { readonly, ref } from "vue";
import { getLibraryFilesService } from "@/services/library";
import { LibraryFileDto } from "@/types/_generated/api";

export const useGetLibraryFiles = () => {
  const isLoading = ref(false);
  const hasError = ref(false);
  const libraryFiles = ref<LibraryFileDto[] | null>(null);

  const getLibraryFiles = async (moduleId: number, isUserWeb: boolean) => {
    isLoading.value = true;
    hasError.value = false;

    try {
      libraryFiles.value = await getLibraryFilesService(moduleId, {
        onlyVisibleInUserWeb: isUserWeb,
      });
    } catch {
      hasError.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    getLibraryFiles,
    hasError: readonly(hasError),
    isLoadingLibraryFiles: readonly(isLoading),
    libraryFiles,
  };
};
