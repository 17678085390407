<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { MfaMethod } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import { useCredentialVerification } from "../composables/useCredentialVerification";

const props = defineProps<{
  email: string;
  password: string;
}>();

const emit = defineEmits<{
  "update:expiry": [value: number | null];
  "view-change": [view: ViewAuthStatus];
}>();

const { t } = useI18n({ useScope: "global" });
const { isLoadingVerification, verifyCredentials } = useCredentialVerification();

const requestMfaVerification = async (mfaMethod: MfaMethod) => {
  const result = await verifyCredentials(props.email, props.password, { mfaMethod });
  emit("update:expiry", result?.mfaExpiry ?? null);
  emit("view-change", ViewAuthStatus.TwoFactorVerify);
};
</script>

<template>
  <div class="auth-mfa-selector">
    <BaseButton :disabled="isLoadingVerification" @click="requestMfaVerification(MfaMethod.Email)">
      {{ t("common.mfa.email") }}
    </BaseButton>

    <BaseButton
      variant="outlined"
      :disabled="isLoadingVerification"
      @click="requestMfaVerification(MfaMethod.Sms)"
    >
      {{ t("common.mfa.sms") }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.auth-mfa-selector {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;
}
</style>
