import Squire from "squire-rte";
import type { Ref } from "vue";

export const useEventHandler = (
  editorRef: Ref<Squire | null>,
  editorContainerRef: Ref<HTMLElement | null>,
  emit: (event: "input", value: string) => void,
) => {
  const adjustScrollOnPaste = () => {
    setTimeout(() => {
      const editorContainer = editorContainerRef.value;
      if (!editorContainer) return;

      // Scroll to ensure the cursor is visible
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const cursorPosition = selection.getRangeAt(0).getBoundingClientRect();
        const editorPosition = editorContainer.getBoundingClientRect();

        // Check if the cursor is below the viewport of the editor
        if (cursorPosition.bottom > editorPosition.bottom) {
          // Scroll just enough to make the cursor visible at the bottom of the editor
          editorContainer.scrollTop += cursorPosition.bottom - editorPosition.bottom;
        }
      }
    }, 0); // Ensures DOM updates before checking cursor position
  };

  const scrollToBottomIfNeeded = () => {
    const editorContainer = editorContainerRef.value;
    if (!editorContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = editorContainer;
    const threshold = 40; // px
    const isNearBottom = scrollHeight - scrollTop - clientHeight < threshold;
    // Only scroll to the bottom if the user is already near the bottom
    if (isNearBottom) editorContainer.scrollTop = scrollHeight;
  };

  const handleTextInput = () => {
    const editor = editorRef.value;
    if (!editor) return;
    emit("input", editor.getHTML()); // Emit the HTML
  };

  const setupEventListeners = () => {
    const editor = editorRef.value;
    if (editor) {
      editor.addEventListener("input", () => {
        handleTextInput();
        scrollToBottomIfNeeded();
      });
      editor.addEventListener("paste", adjustScrollOnPaste);
    }
    // Cleanup is handled by Squire automatically on destroy
  };

  return { setupEventListeners };
};
