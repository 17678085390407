import { ref } from "vue";
import { requestSpoeCheckService } from "@/services/surveys";

export const useRequestSpoeCheck = () => {
  const isLoadingRequestSpoeCheck = ref(false);

  const requestSpoeCheck = async (surveyId: number) => {
    isLoadingRequestSpoeCheck.value = true;

    await requestSpoeCheckService(surveyId);

    isLoadingRequestSpoeCheck.value = false;
  };

  return {
    requestSpoeCheck,
    isLoadingRequestSpoeCheck,
  };
};
