<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseImage from "@/components/base/BaseImage.vue";

interface Props {
  fileId: string;
  siteId: number;
}

const props = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const url = computed(() => `/v1/sites/${props.siteId}/files/${props.fileId}`);
</script>

<template>
  <BaseImage :src="url" :alt="t('sites.siteReportImage')" max-width="25rem" />
</template>
