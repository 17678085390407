<script setup lang="ts">
import { computed } from "vue";
import IconArrowDown from "@/assets/icons/IconArrowDown.vue";
import IconArrowLeft from "@/assets/icons/IconArrowLeft.vue";
import IconArrowRight from "@/assets/icons/IconArrowRight.vue";
import IconArrowUp from "@/assets/icons/IconArrowUp.vue";
import IconBold from "@/assets/icons/IconBold.vue";
import IconBolt from "@/assets/icons/IconBolt.vue";
import IconBuilding from "@/assets/icons/IconBuilding.vue";
import IconCalendar from "@/assets/icons/IconCalendar.vue";
import IconCheck from "@/assets/icons/IconCheck.vue";
import IconCheckCircle from "@/assets/icons/IconCheckCircle.vue";
import IconChevronDown from "@/assets/icons/IconChevronDown.vue";
import IconChevronLeft from "@/assets/icons/IconChevronLeft.vue";
import IconChevronRight from "@/assets/icons/IconChevronRight.vue";
import IconChevronUp from "@/assets/icons/IconChevronUp.vue";
import IconClose from "@/assets/icons/IconClose.vue";
import IconColumns from "@/assets/icons/IconColumns.vue";
import IconCopy from "@/assets/icons/IconCopy.vue";
import IconCross from "@/assets/icons/IconCross.vue";
import IconCrossCircle from "@/assets/icons/IconCrossCircle.vue";
import IconDivider from "@/assets/icons/IconDivider.vue";
import IconDots from "@/assets/icons/IconDots.vue";
import IconDownload from "@/assets/icons/IconDownload.vue";
import IconEdit from "@/assets/icons/IconEdit.vue";
import IconEmail from "@/assets/icons/IconEmail.vue";
import IconEmpty from "@/assets/icons/IconEmpty.vue";
import IconExcel from "@/assets/icons/IconExcel.vue";
import IconEye from "@/assets/icons/IconEye.vue";
import IconEyeClosed from "@/assets/icons/IconEyeClosed.vue";
import IconFile from "@/assets/icons/IconFile.vue";
import IconFilter from "@/assets/icons/IconFilter.vue";
import IconFold from "@/assets/icons/IconFold.vue";
import IconFolderOpen from "@/assets/icons/IconFolderOpen.vue";
import IconForbidden from "@/assets/icons/IconForbidden.vue";
import IconHistory from "@/assets/icons/IconHistory.vue";
import IconImage from "@/assets/icons/IconImage.vue";
import IconInfo from "@/assets/icons/IconInfo.vue";
import IconItalic from "@/assets/icons/IconItalic.vue";
import IconKebabHorizontal from "@/assets/icons/IconKebabHorizontal.vue";
import IconKey from "@/assets/icons/IconKey.vue";
import IconLanguages from "@/assets/icons/IconLanguages.vue";
import IconMapPin from "@/assets/icons/IconMapPin.vue";
import IconMenu from "@/assets/icons/IconMenu.vue";
import IconOrderedList from "@/assets/icons/IconOrderedList.vue";
import IconPdf from "@/assets/icons/IconPdf.vue";
import IconPerson from "@/assets/icons/IconPerson.vue";
import IconPlus from "@/assets/icons/IconPlus.vue";
import IconQuestion from "@/assets/icons/IconQuestion.vue";
import IconRotate from "@/assets/icons/IconRotate.vue";
import IconSettings from "@/assets/icons/IconSettings.vue";
import IconSignOut from "@/assets/icons/IconSignOut.vue";
import IconSlash from "@/assets/icons/IconSlash.vue";
import IconStrikethrough from "@/assets/icons/IconStrikethrough.vue";
import IconText from "@/assets/icons/IconText.vue";
import IconTextColor from "@/assets/icons/IconTextColor.vue";
import IconTextFormat from "@/assets/icons/IconTextFormat.vue";
import IconTextSize from "@/assets/icons/IconTextSize.vue";
import IconTrash from "@/assets/icons/IconTrash.vue";
import IconUnderline from "@/assets/icons/IconUnderline.vue";
import IconUnfold from "@/assets/icons/IconUnfold.vue";
import IconUnorderedList from "@/assets/icons/IconUnorderedList.vue";
import IconUpload from "@/assets/icons/IconUpload.vue";
import IconWarning from "@/assets/icons/IconWarning.vue";
import IconWord from "@/assets/icons/IconWord.vue";

export interface Props {
  icon: keyof typeof iconMapping;
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge";
}

const props = withDefaults(defineProps<Props>(), {
  size: "small",
});

const iconMapping = {
  "arrow-down": IconArrowDown,
  "arrow-left": IconArrowLeft,
  "arrow-right": IconArrowRight,
  "arrow-up": IconArrowUp,
  bold: IconBold,
  bolt: IconBolt,
  building: IconBuilding,
  calendar: IconCalendar,
  "check-circle": IconCheckCircle,
  check: IconCheck,
  "chevron-down": IconChevronDown,
  "chevron-left": IconChevronLeft,
  "chevron-right": IconChevronRight,
  "chevron-up": IconChevronUp,
  close: IconClose,
  columns: IconColumns,
  copy: IconCopy,
  "cross-circle": IconCrossCircle,
  cross: IconCross,
  divider: IconDivider,
  dots: IconDots,
  download: IconDownload,
  edit: IconEdit,
  email: IconEmail,
  empty: IconEmpty,
  excel: IconExcel,
  "eye-closed": IconEyeClosed,
  eye: IconEye,
  file: IconFile,
  filter: IconFilter,
  fold: IconFold,
  "folder-open": IconFolderOpen,
  forbidden: IconForbidden,
  history: IconHistory,
  image: IconImage,
  info: IconInfo,
  italic: IconItalic,
  "kebab-horizontal": IconKebabHorizontal,
  key: IconKey,
  languages: IconLanguages,
  "map-pin": IconMapPin,
  menu: IconMenu,
  "ordered-list": IconOrderedList,
  pdf: IconPdf,
  person: IconPerson,
  plus: IconPlus,
  question: IconQuestion,
  rotate: IconRotate,
  settings: IconSettings,
  "sign-out": IconSignOut,
  slash: IconSlash,
  strikethrough: IconStrikethrough,
  "text-color": IconTextColor,
  "text-format": IconTextFormat,
  "text-size": IconTextSize,
  text: IconText,
  trash: IconTrash,
  underline: IconUnderline,
  unfold: IconUnfold,
  "unordered-list": IconUnorderedList,
  upload: IconUpload,
  warning: IconWarning,
  word: IconWord,
};

const iconComponent = computed(() => {
  return iconMapping[props.icon];
});

const classes = computed(() => {
  return ["base-icon", `base-icon--${props.size}`];
});
</script>

<template>
  <component :is="iconComponent" :class="classes" />
</template>

<style scoped lang="scss">
.base-icon {
  flex-shrink: 0;

  &--xsmall {
    width: 0.5rem;
    height: 0.5rem;
  }

  &--small {
    width: 0.75rem;
    height: 0.75rem;
  }

  &--medium {
    width: 1rem;
    height: 1rem;
  }

  &--large {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--xlarge {
    width: 1.75rem;
    height: 1.75rem;
  }

  &--xxlarge {
    width: 3rem;
    height: 3rem;
  }
}
</style>
