<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getModuleService } from "@/services/modules";
import { ModuleDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });

const module = ref<ModuleDto | null>(null);
const isLoading = ref(false);

onBeforeMount(async () => {
  isLoading.value = true;

  module.value = await getModuleService(props.moduleId);

  isLoading.value = false;
});
</script>

<template>
  <div class="module-details">
    <BaseDataDisplayList :title="t('common.general')">
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('common.name')">
        <p>{{ module?.name }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('modules.countryOrUnit')">
        <p>{{ module?.willisCountryOrUnit }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('modules.office')">
        <p>{{ module?.willisOffice }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('modules.web')">
        <p>{{ module?.web }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('modules.status')">
        <p>{{ t(`common.statuses.${module?.status}`) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('modules.administrator')">
        <p>{{ module?.administratorName }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>

    <BaseDataDisplayList :title="t('modules.contact')">
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('common.name')">
        <p>{{ module?.contactFullName }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('common.role')">
        <p>{{ module?.contactRole }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('common.email')">
        <p>{{ module?.contactEmail }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('common.mobile')">
        <p>{{ module?.contactMobile }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :is-loading="isLoading" :label="t('common.phone')">
        <p>{{ module?.contactPhone }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>
  </div>
</template>

<style scoped lang="scss">
.module-details {
  display: grid;
  gap: $spacing-8;
  grid-template-columns: repeat(3, 1fr);
}
</style>
