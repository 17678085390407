<script setup lang="ts">
import { computed, ref } from "vue";
import { convertFiles } from "@/helpers/convertFiles";
import { FileDto } from "@/types/_generated/api";
import BaseFileAreaDropzone from "./BaseFileAreaDropzone.vue";
import BaseFileAreaErrors from "./BaseFileAreaErrors.vue";
import BaseFileAreaInstructions from "./BaseFileAreaInstructions.vue";
import BaseFileAreaRestrictions from "./BaseFileAreaRestrictions.vue";
import { useValidateFile } from "./useValidateFile";

interface Props {
  allowedFileTypes?: string[];
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  hideErrors?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  onAdd: [files: FileDto[]];
  onError: [errors: string[]];
}>();

const input = ref<HTMLInputElement>();

const validations = computed(() => ({
  allowedFileTypes: props.allowedFileTypes,
  minSize: props.minSize,
  maxSize: props.maxSize,
  maxFiles: props.maxFiles,
}));

const { validateFiles, errors } = useValidateFile(validations);

const onUpload = async (fileList: FileList | null) => {
  const isValid = validateFiles(fileList);

  if (isValid) {
    // Convert files to base64 and emit the mapped files
    const filesAsBase64: FileDto[] = await convertFiles(fileList);

    emit("onAdd", filesAsBase64);
  } else {
    // Clear the errors after 5 seconds
    setTimeout(() => {
      errors.value = [];
    }, 5000);

    // Clear the input value
    if (input.value) {
      input.value.value = "";
    }

    emit("onError", errors.value);
  }
};
</script>

<template>
  <div class="base-file-area">
    <input
      ref="input"
      class="base-file-area__input"
      type="file"
      :accept="allowedFileTypes?.join(', ')"
      multiple="true"
      @change="onUpload(($event.target as HTMLInputElement).files)"
    />

    <BaseFileAreaDropzone @on-files-dropped="onUpload">
      <BaseFileAreaInstructions @click="() => input?.click()" />
      <BaseFileAreaRestrictions
        :allowed-file-types="allowedFileTypes"
        :max-size="maxSize"
        :min-size="minSize"
      />
    </BaseFileAreaDropzone>

    <slot v-if="!hideErrors" name="errors">
      <BaseFileAreaErrors v-if="errors.length" :errors="errors" />
    </slot>
  </div>
</template>

<style scoped lang="scss">
.base-file-area {
  position: relative;

  &__input {
    display: none;
  }
}
</style>
