<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="questionnare-draft__history-empty">
    <BaseIcon icon="history" />
    <h1>{{ t("questionnaire.draft.history.empty") }}</h1>
  </div>
</template>

<style lang="scss" scoped>
.questionnare-draft__history-empty {
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-5;

  h1 {
    font-size: $text-2xl;
    font-weight: $font-medium;
    margin: 0.67em 0;
  }
}
</style>
