<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { getLanguage } from "@/helpers/getLanguage";
import { AreaDto } from "@/types/_generated/api";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import QuestionnaireViewerEntityWrapper from "./QuestionnaireViewerEntityWrapper.vue";
import QuestionnaireViewerSimpleValue from "./QuestionnaireViewerSimpleValue.vue";
import QuestionnaireViewerTotalScore from "./QuestionnaireViewerTotalScore.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  area: AreaDto;
}>();

const tags = computed(() => {
  if (props.area.tags.trim().length) {
    return props.area.tags
      .trim()
      .split(" ")
      .map((t) => t);
  }

  return [];
});

const calculateTotalBlueScoreForArea = (area: AreaDto): number => {
  let totalBlueScore = 0;

  for (const category of area.categories) {
    for (const question of category.questions) {
      totalBlueScore += question.blueScore;
    }
  }

  return totalBlueScore;
};

const totalBlueScoreForArea = calculateTotalBlueScoreForArea(props.area);
</script>

<template>
  <QuestionnaireViewerEntityWrapper
    :id="`area_${area.areaId}`"
    :title="area.name"
    :position="area.position.toString()"
  >
    <template #header-right>
      <BasePill v-for="tag in tags" :key="tag" :label="tag" />
    </template>
    <div class="questionnaire__area__header__properties">
      <QuestionnaireViewerSimpleValue
        v-if="!!area.willisCountryOrUnit.length"
        :label="t('questionnaire.properties.countryOrUnit')"
        :value="area.willisCountryOrUnit"
      />

      <QuestionnaireViewerSimpleValue
        v-if="!!area.industry"
        :label="t('questionnaire.properties.industry')"
        :value="area.industry"
      />

      <QuestionnaireViewerSimpleValue
        v-if="!!area.riskType"
        :label="t('questionnaire.properties.riskType')"
        :value="area.riskType"
      />

      <QuestionnaireViewerSimpleValue
        v-if="!!area.language"
        :label="t('questionnaire.properties.language')"
        :value="area.language !== undefined ? getLanguage(area.language) : ''"
      />
      <QuestionnaireViewerTotalScore :total-score="totalBlueScoreForArea" />
    </div>
  </QuestionnaireViewerEntityWrapper>
</template>
<style scoped lang="scss">
:deep(.base-card__content) {
  border-top: none;
}
.questionnaire {
  &__area {
    padding-bottom: $spacing-4;

    &__header {
      &__properties {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      &__aside {
        display: flex;
        gap: $spacing-2;
      }
    }
  }
}
</style>
