export const BORDER = {
  WIDTH: 2,
  COLOR: "#fff",
};

export const Y_AXIS_STYLE = {
  nameLocation: "middle",
  nameRotate: 90,
  nameGap: 40,
  nameTextStyle: {
    fontWeight: "bold",
  },
};
