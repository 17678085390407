import { ref, computed, type ComputedRef } from "vue";
import type { MultiselectOption } from "../types";

export const useMultiselectSearch = <T>(
  numberOfOptions: number,
  sortedOptions: ComputedRef<MultiselectOption<T>[]>,
) => {
  const searchTerm = ref("");

  // Search input to filter options
  const handleSearchInput = (value: string) => {
    searchTerm.value = value.trim();
  };

  // Only show search input if there are enough number of options to warrant it
  const showSearchInput = computed(() => numberOfOptions > 15);

  // Filter sorted options based on search term
  const filteredOptions = computed(() => {
    return sortedOptions.value.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.value.toLowerCase()),
    );
  });

  return {
    searchTerm,
    showSearchInput,
    filteredOptions,
    handleSearchInput,
  };
};
