<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.857143C0 0.383756 0.383756 0 0.857143 0H15.1429C15.6162 0 16 0.383756 16 0.857143V2.57143C16 3.04482 15.6162 3.42857 15.1429 3.42857C14.6695 3.42857 14.2857 3.04482 14.2857 2.57143V1.71429H1.71429V2.57143C1.71429 3.04482 1.33053 3.42857 0.857143 3.42857C0.383756 3.42857 0 3.04482 0 2.57143V0.857143Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00001 0.285721C8.4734 0.285721 8.85715 0.669477 8.85715 1.14286V14.2857H9.42858C9.90197 14.2857 10.2857 14.6695 10.2857 15.1429C10.2857 15.6163 9.90197 16 9.42858 16H6.57144C6.09805 16 5.71429 15.6163 5.71429 15.1429C5.71429 14.6695 6.09805 14.2857 6.57144 14.2857H7.14287V1.14286C7.14287 0.669477 7.52662 0.285721 8.00001 0.285721Z"
    />
  </svg>
</template>
