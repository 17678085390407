import http from "@/helpers/http";
import { handleError } from "@/services/shared/handleError";
import {
  CreateRecResponseResponse,
  FileDto,
  GetRecResponseResponse,
  RecResponseSurveyDto,
  SubmitRecommendationResponsesResponse,
  type CreateRecommendationResponseDto,
  type GetRecResponseSurveyResponse,
  type RecommendationResponseDto,
} from "@/types/_generated/api";

export const getRecResponseSurveyService = async (): Promise<
  GetRecResponseSurveyResponse | undefined
> => {
  try {
    const { data } = await http.get<GetRecResponseSurveyResponse>(
      "/recommendation-response/survey",
    );
    return data;
  } catch (error) {
    handleError(error);
  }
  return undefined;
};

export const createRecResponseService = async (
  dto: CreateRecommendationResponseDto,
): Promise<RecommendationResponseDto | undefined> => {
  try {
    const {
      data: { response },
    } = await http.post<CreateRecResponseResponse>("/recommendation-response/response", {
      response: dto,
    });
    return response;
  } catch (error) {
    handleError(error);
  }
  return undefined;
};

export const updateRecResponseService = async (dto: RecommendationResponseDto): Promise<void> => {
  try {
    await http.put("/recommendation-response/response", {
      response: dto,
    });
  } catch (error) {
    handleError(error);
  }
};

export const uploadRecResponseFileService = async (
  file: FileDto,
  responseId: number,
): Promise<void> => {
  try {
    await http.post(`/recommendation-response/response/${responseId}/files`, { file });
  } catch (error) {
    handleError(error);
  }
};

export const deleteRecResponseFileService = async (
  responseId: number,
  fileId: string,
): Promise<void> => {
  try {
    await http.delete(`/recommendation-response/response/${responseId}/files/${fileId}`);
  } catch (error) {
    handleError(error);
  }
};

export const getRecResponseService = async (
  responseId: number,
): Promise<RecommendationResponseDto | undefined> => {
  try {
    const {
      data: { recommendationResponse },
    } = await http.get<GetRecResponseResponse>(`/recommendation-response/response/${responseId}`);
    return recommendationResponse;
  } catch (error) {
    handleError(error);
  }
  return undefined;
};

export const submitRecResponsesService = async (): Promise<RecResponseSurveyDto | undefined> => {
  try {
    const {
      data: { survey },
    } = await http.post<SubmitRecommendationResponsesResponse>(
      "/recommendation-response/submit-responses",
    );

    return survey;
  } catch (error) {
    handleError(error);
  }
  return undefined;
};
