<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { RecReportSettingsDto, BaseRecReportSectionDto } from "@/types/_generated/api";
import type { Sections, SelectedSection, UpdateFormField } from "../types";
import { MoveDirection } from "../constants";
import RecReportSectionsItem from "./RecReportSectionsItem.vue";

const props = defineProps<{
  selectedScopeForm: RecReportSettingsDto;
}>();

defineEmits<{
  "update:form-field": [UpdateFormField];
  "move:section": [SelectedSection, MoveDirection];
  "open:section": [SelectedSection];
}>();

const { t } = useI18n({ useScope: "global" });

const defaultCustomTitle = t("module.recReportSettings.sections.custom");

const sections = computed(() => {
  // Transform the sections into a new object for easier access
  const sectionsObj: Sections = {
    coverPageSection: {
      sectionLabel: t("module.recReportSettings.sections.coverPage"),
      key: "coverPageSection",
      ...props.selectedScopeForm.coverPageSection,
    },
    recSection: {
      sectionLabel: t("module.recReportSettings.sections.rec"),
      key: "recSection",
      ...props.selectedScopeForm.recSection,
    },
    blueRatingSection: {
      sectionLabel: t("module.recReportSettings.sections.blueRating"),
      key: "blueRatingSection",
      ...props.selectedScopeForm.blueRatingSection,
    },
    importantNoticeSection: {
      sectionLabel: t("module.recReportSettings.sections.importantNotice"),
      key: "importantNoticeSection",
      ...props.selectedScopeForm.importantNoticeSection,
    },
    customSection1: {
      sectionLabel: props.selectedScopeForm.customSection1.title || defaultCustomTitle,
      key: "customSection1",
      ...props.selectedScopeForm.customSection1,
    },
    customSection2: {
      sectionLabel: props.selectedScopeForm.customSection2.title || defaultCustomTitle,
      key: "customSection2",
      ...props.selectedScopeForm.customSection2,
    },
    customSection3: {
      sectionLabel: props.selectedScopeForm.customSection3.title || defaultCustomTitle,
      key: "customSection3",
      ...props.selectedScopeForm.customSection3,
    },
    customSection4: {
      sectionLabel: props.selectedScopeForm.customSection4.title || defaultCustomTitle,
      key: "customSection4",
      ...props.selectedScopeForm.customSection4,
    },
    customSection5: {
      sectionLabel: props.selectedScopeForm.customSection5.title || defaultCustomTitle,
      key: "customSection5",
      ...props.selectedScopeForm.customSection5,
    },
  };

  // Sort the sections by position
  const sortedSections = Object.values(sectionsObj).sort(
    (a, b) => (a as BaseRecReportSectionDto).position - (b as BaseRecReportSectionDto).position,
  );

  return sortedSections;
});
</script>

<template>
  <div class="rec-report-sections">
    <RecReportSectionsItem
      v-for="section in sections"
      :key="section.key"
      :number-of-sections="sections.length"
      :section="section"
      @update:form-field="$emit('update:form-field', $event)"
      @move:section="$emit('move:section', section, $event)"
      @open:section="$emit('open:section', section)"
    />
  </div>
</template>

<style scoped lang="scss">
.rec-report-sections {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  height: 100%;
}
</style>
