import {
  SortOrder,
  blueColorSortOrder,
  siteResponseSortOrder,
  surveyorStatusSortOrder,
} from "@/constants/SortOrder";
import { customLocaleCompare } from "@/helpers/customLocaleCompare";
import { BlueColor, SiteResponseStatus, SurveyorStatus } from "@/types/_generated/api";

export type Sorting<T> = { key: Extract<keyof T, string>; order?: SortOrder };

const compareBoolean = (a: boolean, b: boolean) => {
  if (a === b) {
    return 0;
  } else if (a) {
    return -1;
  } else {
    return 1;
  }
};

const compareString = <T, TValue extends T[keyof T]>(a: TValue, b: TValue) => {
  const aCompareValue = typeof a === "string" ? a : "";
  const bCompareValue = typeof b === "string" ? b : "";

  return customLocaleCompare(aCompareValue.toLocaleLowerCase(), bCompareValue.toLocaleLowerCase());
};

const compareNumber = (a: number, b: number) => a - b;

const compareValues = <T>(key: keyof T, a: T, b: T, sortOrder: SortOrder) => {
  const aCompareValue = a[key];
  const bCompareValue = b[key];

  const aIsNull = aCompareValue === null || aCompareValue === undefined;
  const bIsNull = bCompareValue == null || bCompareValue === undefined;

  if (aIsNull && !bIsNull) {
    return sortOrder === SortOrder.Ascending ? 1 : -1;
  }

  if (!aIsNull && bIsNull) {
    return sortOrder === SortOrder.Ascending ? -1 : 1;
  }

  // Values are both null or undefined
  if (aIsNull && bIsNull) {
    return 0;
  }

  // Both have values and are equal
  if (aCompareValue === bCompareValue) {
    return 0;
  }

  if (typeof aCompareValue === "boolean" && typeof bCompareValue === "boolean") {
    return compareBoolean(aCompareValue, bCompareValue);
  }

  if (typeof aCompareValue === "number" && typeof bCompareValue === "number") {
    return compareNumber(aCompareValue, bCompareValue);
  }

  if (typeof aCompareValue === "string" || typeof bCompareValue === "string") {
    return compareString<T, T[keyof T]>(aCompareValue, bCompareValue);
  }

  return 0;
};

export const sortByKeys = <T>(arr: T[], ...params: Sorting<T>[]) => {
  let sortedArray = arr.slice();

  // Sorting needs to be run in reverse order of the params
  const reversedParams = params.toReversed();

  for (const sorting of reversedParams) {
    const key = typeof sorting === "object" ? sorting.key : sorting;
    const order = typeof sorting === "object" ? sorting.order : SortOrder.Ascending;

    sortedArray = sortedArray.toSorted((a, b) => {
      const result = compareValues(key, a, b, order ?? SortOrder.Ascending);

      return result * (order === SortOrder.Descending ? -1 : 1); // Reverses the result if the order is descending);
    });
  }

  return sortedArray;
};

export const sortBlueColor = (color: BlueColor): number => {
  return blueColorSortOrder[color];
};

export const sortSiteResponseStatus = (status: SiteResponseStatus): number => {
  return siteResponseSortOrder[status];
};

export const sortSurveyorStatus = (status: SurveyorStatus): number => {
  return surveyorStatusSortOrder[status];
};
