<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseInfoState from "@/components/base/BaseInfoState/BaseInfoState.vue";

defineProps<{ noAccess: boolean; surveyNotReady: boolean }>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <BaseInfoState
    v-if="noAccess"
    :title="t('userWeb.spoeCheck.states.noAccessTitle')"
    :description="t('userWeb.spoeCheck.states.noAccessDescription')"
    variant="forbidden"
  />
  <BaseInfoState
    v-else-if="surveyNotReady"
    :title="t('userWeb.spoeCheck.states.surveyNotReadyTitle')"
    :description="t('userWeb.spoeCheck.states.surveyNotReadyDescription')"
    variant="error"
  />
</template>
