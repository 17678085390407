import { ref, onMounted, onUnmounted } from "vue";
import { generateUniqueId } from "@/helpers/generateUniqueId";

// A stack of active event listeners
const activeEventListeners = ref<string[]>([]);

// Registers a component in the stack of active event listeners
// Each component gets a unique ID when it registers
const registerComponent = (name: string) => {
  activeEventListeners.value.push(name);
};

// Unregisters a component from the stack of active event listeners
// This is typically called when the component is unmounted
const unregisterComponent = (name: string) => {
  const index = activeEventListeners.value.lastIndexOf(name);
  if (index !== -1) {
    activeEventListeners.value.splice(index, 1);
  }
};

/**
 * `useEventListener` is a composable function for easily adding and removing event listeners in a component.
 * This is especially useful for clearing event listeners when a component is unmounted.
 *
 * Example Usage:
 *
 * import { useEventListener } from "@/composables/useEventListener";
 *
 * useEventListener(document, "keyup", (event: KeyboardEvent) => {
 *  if (event.key === "Escape") $emit("close");
 * });
 */
export const useEventListener = <T extends Event = Event>(
  target: EventTarget, // The target element to listen to. Typically 'document' for key events
  type: string, // The type of event to listen for, e.g., 'keyup'
  listener: (evt: T) => void, // The actual event handler function
  options?: boolean | AddEventListenerOptions, // Optional parameters for addEventListener
) => {
  // Generate a unique ID for each component instance using the composable
  // This ID is used to track which component should respond to the event
  const componentId = generateUniqueId();

  // An enhanced version of the provided listener that first checks if the current component
  // is the topmost in the stack of active event listeners
  const enhancedListener = (event: T) => {
    if (activeEventListeners.value.at(-1) === componentId) {
      listener(event);
    }
  };

  // Register the component and add the event listener when the component is mounted
  onMounted(() => {
    registerComponent(componentId);
    target.addEventListener(type, enhancedListener as EventListener, options);
  });

  // Unregister the component and remove the event listener when the component is unmounted
  onUnmounted(() => {
    unregisterComponent(componentId);
    target.removeEventListener(type, enhancedListener as EventListener, options);
  });
};
