<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import ModInfoState, {
  type Props as ModInfoStateProps,
} from "@/components/mods/InfoState/ModInfoState.vue";
import BlueRatingTableLoading from "@/components/shared/BlueRatingTable/BlueRatingTableLoading.vue";

const props = defineProps<{
  isLoadingTableData: boolean;
  errorFetchingTableData: boolean;
  surveyLength?: number;
}>();

const { t } = useI18n({ useScope: "global" });

const stateConfig: ModInfoStateProps["customMessage"] = [
  {
    type: "empty",
    title: t("userWeb.blueRatingTable.empty.title"),
    description: t("userWeb.blueRatingTable.empty.description"),
  },
  {
    type: "error",
    title: t("userWeb.blueRatingTable.error.title"),
    description: t("userWeb.blueRatingTable.error.description"),
  },
];

const currentInfoState = computed<ModInfoStateProps["state"] | null>(() => {
  if (props.errorFetchingTableData) {
    return "error";
  }

  return props.surveyLength === 0 ? "empty" : null;
});
</script>

<template>
  <BlueRatingTableLoading v-if="isLoadingTableData" />

  <ModInfoState
    v-if="!isLoadingTableData && currentInfoState"
    :state="currentInfoState"
    :custom-message="stateConfig"
  />
</template>
