<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { type Ref, onBeforeMount, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useQuestionnaireDraftValidation } from "@/composables/useQuestionnareDraftValidation";
import { QuestionnaireDraftActionType, QuestionnaireDto } from "@/types/_generated/api";
import { useColorMix } from "@/views/Admin/Questionnaire/composables/useColorMix";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BaseValidationErrors from "@/components/base/BaseValidationErrors.vue";
import QuestionnaireDraftAddButtons from "./QuestionnaireDraftAddButtons.vue";
import QuestionnaireDraftArea from "./QuestionnaireDraftArea.vue";
import QuestionnaireDraftCategory from "./QuestionnaireDraftCategory.vue";
import QuestionnaireDraftInputWrapper from "./QuestionnaireDraftInputWrapper.vue";
import QuestionnaireDraftQuestion from "./QuestionnaireDraftQuestion/QuestionnaireDraftQuestion.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  draft?: QuestionnaireDto;
  questionnaireId: number;
  isLoading: boolean;
}>();

const hideGradingDescriptions = ref<number[]>([]);
const questionnaire = toRef(() => props.draft);
const { questionnaireRules } = useQuestionnaireDraftValidation();

const v$ = useVuelidate(questionnaireRules, questionnaire as Readonly<Ref<QuestionnaireDto>>, {
  $autoDirty: true,
  $registerAs: "questionnaire",
});

onBeforeMount(() => v$.value.$touch());
</script>

<template>
  <div class="questionnaire-draft">
    <BaseSkeleton v-if="!draft || isLoading" :set="4" height="100px" mb="1rem" width="100%" />

    <div v-if="draft && !isLoading">
      <div class="questionnaire-draft__name">
        <QuestionnaireDraftInputWrapper
          :id="draft.questionnaireId"
          :real-questionnaire-id="questionnaireId"
          :type="QuestionnaireDraftActionType.Questionnaire"
          :property-name="'Name'"
          :target-name="draft.name"
          orientation="horizontal"
        >
          <template #label>
            <label class="questionnaire-draft__name-label" for="questionnaire-name">
              {{ t("questionnaire.questionnaireName") }}
            </label>
          </template>
          <template #default="{ update }">
            <div class="questionnaire-draft__name__right">
              <BaseTextField
                class="questionnaire-draft__name-input"
                :value="draft.name"
                @update:value="update"
              />
              <BaseValidationErrors :errors="v$.name?.$errors" />
            </div>
          </template>
        </QuestionnaireDraftInputWrapper>
      </div>

      <div
        v-for="(area, i) in draft.areas"
        :key="area.areaId"
        class="questionnaire-draft__areas"
        :style="{ borderColor: useColorMix(i) }"
      >
        <QuestionnaireDraftArea
          :real-questionnaire-id="questionnaireId!"
          :area="area"
          :area-count="draft.areas?.length ?? 0"
        />

        <div
          v-for="(category, j) in area.categories"
          :key="category.categoryId"
          class="questionnaire-draft__categories"
          :style="{ borderColor: useColorMix(i, j) }"
        >
          <QuestionnaireDraftCategory
            :real-questionnaire-id="questionnaireId"
            :area="area"
            :category="category"
            :hide-question-grading-descriptions="
              hideGradingDescriptions.includes(category.categoryId)
            "
            @toggle-gradient-description="
              () => {
                if (hideGradingDescriptions.includes(category.categoryId)) {
                  hideGradingDescriptions = hideGradingDescriptions.filter(
                    (id) => id !== category.categoryId,
                  );
                } else {
                  hideGradingDescriptions = [...hideGradingDescriptions, category.categoryId];
                }
              }
            "
          />
          <div
            v-for="(question, k) in category.questions"
            :key="question.questionId"
            class="questionnaire-draft__questions"
            :style="{ borderColor: useColorMix(i, j, k) }"
          >
            <QuestionnaireDraftQuestion
              :real-questionnaire-id="questionnaireId"
              :area="area"
              :category="category"
              :question="question"
              :hide-question-grading-descriptions="
                !hideGradingDescriptions.includes(category.categoryId)
              "
            />
          </div>
        </div>
      </div>

      <BaseButtonRow v-if="draft.areas.length === 0">
        <QuestionnaireDraftAddButtons :type="'Area'" :insert-into-id="draft.questionnaireId" />
      </BaseButtonRow>
    </div>
  </div>
</template>

<style scoped lang="scss">
$border-width: 12px;
$outer-padding: $spacing-5;
$inner-padding: $spacing-6;

.questionnaire-draft {
  padding-left: $outer-padding;
  padding-right: $outer-padding;
  padding-bottom: $spacing-5;
  margin: auto;
  max-width: $screen-2xl;

  &__name {
    border-bottom: 1px solid $primary-4;
    margin: 0 0 $spacing-5 0;

    &__right {
      display: flex;
      gap: $spacing-4;
      flex-direction: column;
    }

    &-label {
      font-size: $text-2xl;
      padding: $spacing-5 $spacing-5 $spacing-5 0;
    }
  }

  &__areas,
  &__categories,
  &__questions {
    margin: $spacing-10 0;
  }
}

:deep(.base-validation-errors) {
  // Negate grid gap
  margin-top: -$spacing-4;
}
</style>
