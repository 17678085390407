import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { authorize } from "@/plugins/can";
import { MenuOption } from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";

export const useUsersTableMenuOptions = (showLockedAndArchived: Ref<boolean>) => {
  const { t } = useI18n({ useScope: "global" });

  const menuOptions = computed<MenuOption[]>(() => {
    const options: MenuOption[] = [];

    if (!authorize.isAdmin()) {
      return options;
    }

    options.push({
      label: t("users.showLockedOut"),
      checked: showLockedAndArchived.value,
      action: () => (showLockedAndArchived.value = !showLockedAndArchived.value),
    });

    return options;
  });

  return { menuOptions };
};
