<script setup lang="ts">
import { computed, onMounted, toRef } from "vue";
import { useI18n } from "vue-i18n";
import useDesign from "@/composables/useDesign";
import { deleteStandardRecommendation } from "@/services/standardRecommendations";
import { FileDto, RotateDegrees, StandardRecommendationDto } from "@/types/_generated/api";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import { useClientAndModuleOptions } from "../../composables/useClientAndModuleOptions";
import { useSaveStandardRec } from "../../composables/useSaveStandardRec";
import {
  StandardRecommendationEditDrawerTab,
  useStandardRecommendationEditDrawerTabs,
} from "../../composables/useStandardRecommendationEditDrawerTabs";
import { useStandardRecommendationForm } from "../../composables/useStandardRecommendationForm";
import StandardRecEditClient from "./StandardRecEditClient.vue";
import StandardRecEditCountry from "./StandardRecEditCountry.vue";
import StandardRecEditDrawerActions from "./StandardRecEditDrawerActions.vue";
import StandardRecEditHeader from "./StandardRecEditHeader.vue";
import StandardRecEditModule from "./StandardRecEditModule.vue";
import StandardRecEditText from "./StandardRecEditText.vue";
import StandardRecEditType from "./StandardRecEditType.vue";
import StandardRecImageForm from "./StandardRecImageForm.vue";
import StandardRecTagList from "./StandardRecTagList.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  standardRecommendation?: StandardRecommendationDto | null;
  preSelectedClientId?: number;
  preSelectedModuleId?: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  close: [];
}>();

const {
  mediaQuery: { isWidthLessThan },
} = useDesign();

const { activeTab, changeTab, tabs } = useStandardRecommendationEditDrawerTabs();
const { saveStandardRec, isSavingStandardRec, hasError } = useSaveStandardRec();
const { form, images, errors, validate } = useStandardRecommendationForm(
  toRef(() => props.standardRecommendation),
);
const { clientOptions, moduleOptions, isLoadingOptions, loadOptions } = useClientAndModuleOptions(
  toRef(() => form.clientId),
);

const isLoading = computed(() => isLoadingOptions.value || isSavingStandardRec.value);

const drawerWidth = computed(() =>
  isWidthLessThan("lg") ? "100" : isWidthLessThan("2xl") ? "50" : "30",
);

const addImages = (files: FileDto[]) => {
  const recFiles = files.map((file) => ({
    fileName: file.fileName,
    file: file.file,
    fileSize: file.fileSize,
    rotation: RotateDegrees.None,
    caption: "",
  }));

  images.value = images.value.concat(recFiles);
};

const save = async () => {
  validate();

  if (errors.value.hasErrors()) {
    changeTab(StandardRecommendationEditDrawerTab.General);
    return;
  }

  await saveStandardRec({ ...form }, images.value);

  if (hasError.value) {
    return;
  }

  emit("close");
};

onMounted(async () => {
  form.clientId = props.preSelectedClientId ?? form.clientId;
  form.moduleId = props.preSelectedModuleId ?? form.moduleId;
  loadOptions();
});
</script>

<template>
  <BaseDrawer
    class="standard-rec-edit-drawer"
    :width="drawerWidth"
    :is-loading="isLoading"
    :title="t('standardRecommendations.standardRecommendation.editStandardRecommendation')"
    @close="emit('close')"
  >
    <BaseDrawerTabs :current-tab="activeTab" :tabs="tabs" @change="changeTab" />

    <div v-if="activeTab === StandardRecommendationEditDrawerTab.General">
      <div class="standard-rec-edit-drawer__form">
        <StandardRecEditHeader
          :header="form.header"
          :error-message="errors.header"
          @update:value="form.header = $event"
        />

        <StandardRecEditText
          :text="form.text"
          :error-message="errors.text"
          @update:value="form.text = $event"
        />

        <div class="standard-rec-edit-drawer__form__grid">
          <StandardRecEditClient
            :client-id="form.clientId"
            :client-options="clientOptions"
            :disabled="!!preSelectedClientId"
            :error-message="errors.clientId"
            @update:value="
              (event) => {
                form.clientId = event;
                form.moduleId = undefined;
              }
            "
          />

          <StandardRecEditModule
            :form="form"
            :module-options="moduleOptions"
            :disabled="!!preSelectedModuleId || !form.clientId"
            @update:value="form.moduleId = $event"
          />

          <StandardRecEditCountry :value="form.country" @update:value="form.country = $event" />
          <StandardRecEditType :value="form.type" @update:value="form.type = $event" />

          <StandardRecTagList
            :label="t('standardRecommendations.categoryTags')"
            :tags="form.categories"
            @add="(tag: string) => form.categories.push(tag)"
            @delete="
              (index: number) => (form.categories = form.categories.filter((_, i) => i !== index))
            "
          />

          <StandardRecTagList
            :label="t('standardRecommendations.questionTags')"
            :tags="form.questions"
            @add="(tag: string) => form.questions.push(tag)"
            @delete="
              (index: number) => (form.questions = form.questions.filter((_, i) => i !== index))
            "
          />
        </div>
      </div>
    </div>

    <StandardRecImageForm
      v-if="activeTab === StandardRecommendationEditDrawerTab.Images"
      :standard-rec-id="standardRecommendation?.standardRecommendationId"
      :images="images"
      @add-image="addImages"
      @update="images = $event"
      @update:caption="images[$event.index].caption = $event.caption"
      @update:rotation="
        images[$event.index] = { ...images[$event.index], rotation: $event.rotation }
      "
    />

    <template #footer-left>
      <StandardRecEditDrawerActions
        :is-create="!standardRecommendation"
        @save="save"
        @delete="deleteStandardRecommendation($event)"
      />
    </template>
  </BaseDrawer>
</template>

<style lang="scss" scoped>
.standard-rec-edit-drawer {
  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    padding: $spacing-6;

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $spacing-4;
    }
  }
}
</style>
