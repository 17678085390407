<script setup lang="ts">
import { ref } from "vue";
import { useAppStore } from "@/stores/app";
import UserWebHeaderBreadcrumbs from "./UserWebHeaderBreadcrumbs.vue";
import UserWebHeaderMenu from "./UserWebHeaderMenu.vue";
import UserWebHeaderMore from "./UserWebHeaderMore.vue";
import UserWebHeaderUserDetails from "./UserWebHeaderUserDetails.vue";

const app = useAppStore();

const showUserDetails = ref(false);
</script>

<template>
  <header class="user-web-header">
    <div class="user-web-header__left">
      <UserWebHeaderMenu />
      <UserWebHeaderBreadcrumbs />
    </div>

    <div class="user-web-header__right">
      <UserWebHeaderMore
        :full-name="`${app.user?.firstName} ${app.user?.lastName}`"
        @open-user-details="showUserDetails = true"
      />
      <UserWebHeaderUserDetails v-if="showUserDetails" @close-drawer="showUserDetails = false" />
    </div>
  </header>
</template>

<style scoped lang="scss">
.user-web-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4;
  padding: 0 $spacing-8;
  height: $header-height;
  font-size: $text-sm;
  background-color: $secondary-6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%236b21a8' stroke-width='4' %3E%3Cpath d='M0 0 Q200 100 400 0 T800 0 M0 100 Q200 200 400 100 T800 100 M0 200 Q200 300 400 200 T800 200 M0 300 Q200 400 400 300 T800 300 M0 400 Q200 500 400 400 T800 400 M0 500 Q200 600 400 500 T800 500 M0 600 Q200 700 400 600 T800 600 M0 700 Q200 800 400 700 T800 700'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
  background-size: 200px 200px;

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    min-width: 0;
  }

  @media print {
    display: none;
  }
}
</style>
