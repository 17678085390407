<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getIconForFileType } from "@/helpers/getIconForFileType";
import { isModified } from "@/helpers/isModified";
import {
  CreateFileDto,
  RecommendationResponseDto,
  SiteResponseStatus,
} from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDateField from "@/components/base/BaseDateField.vue";
import BaseFileArea from "@/components/base/BaseFileArea/BaseFileArea.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import SiteResponseStatusDropdown from "@/components/shared/RecommendationResponse/SiteResponseStatusDropdown.vue";
import {
  createRecResponseService,
  deleteRecResponseFileService,
  getRecResponseService,
  updateRecResponseService,
} from "../services/recResponse";
import ExternalResponseFileList from "./ExternalResponseFileList.vue";

const props = defineProps<{
  recommendationId: number;
  responseHistory: RecommendationResponseDto[];
  allowedFileTypes: string[];
  currency: string;
}>();

const emit = defineEmits<{
  "create:response": [response: RecommendationResponseDto];
  "update:response": [response: RecommendationResponseDto];
}>();

const { t } = useI18n({ useScope: "global" });

const newResponse: RecommendationResponseDto = {
  id: 0,
  recommendationId: props.recommendationId,
  isSurveyorAutoChange: false,
  locked: false,
  updateDate: new Date().toISOString(),
  updateBy: undefined,
  status: SiteResponseStatus.NotSet,
  comment: "",
  dueDate: undefined,
  costToComplete: undefined,
  responsiblePerson: undefined,
  files: [],
};

const createFiles = ref<CreateFileDto[]>([]);
const currentResponse = ref<RecommendationResponseDto>({
  ...newResponse,
  status: props.responseHistory[0]?.status ?? SiteResponseStatus.NotSet,
});

onMounted(() => {
  if (!props.responseHistory.length) {
    return;
  }
  const unlocked = props.responseHistory.find((r) => !r.locked);
  if (unlocked) {
    currentResponse.value = { ...unlocked };
  }
});

const isCreate = computed(() => currentResponse.value.id === 0);

const isUnsaved = computed(() => {
  if (isCreate.value) {
    return isModified(newResponse, currentResponse.value);
  }

  const original = props.responseHistory.find((r) => r.id === currentResponse.value.id);
  if (!original) {
    return true;
  }

  return isModified(original, currentResponse.value);
});

const save = async () => {
  if (isCreate.value) {
    const recResponse = await createRecResponseService({
      ...currentResponse.value,
      files: createFiles.value,
    });

    if (recResponse) {
      emit("create:response", recResponse);
      currentResponse.value = { ...recResponse };
    }
  } else {
    await updateRecResponseService(currentResponse.value);

    emit("update:response", { ...currentResponse.value });
  }
};

const deleteFile = async (fileId: string) => {
  if (currentResponse.value.id === 0) {
    throw new Error("Cannot delete file from unsaved response");
  }

  await deleteRecResponseFileService(currentResponse.value.id, fileId);
  await refreshFiles();
};

const refreshFiles = async () => {
  if (currentResponse.value.id === 0) {
    throw new Error("Cannot refresh files from unsaved response");
  }

  const response = await getRecResponseService(currentResponse.value.id);

  if (!response) {
    return;
  }

  currentResponse.value.files = response.files;

  if (response) {
    emit("update:response", response);
  }
};
</script>

<template>
  <div class="external-response-edit">
    <h2 class="external-response-edit__title">
      {{ t("recommendationResponse.respondToRecommendation") }}
      <span v-if="isUnsaved" class="external-response-edit__title__status">
        {{ t("common.notSaved") }}
      </span>
    </h2>
    <div>
      <SiteResponseStatusDropdown
        :status="currentResponse.status"
        :hidden-statuses="[SiteResponseStatus.NotSet]"
        @update="currentResponse.status = $event"
      />
    </div>
    <div>
      <BaseTextArea
        :label="t('recommendationResponse.comment')"
        :value="currentResponse.comment"
        @update:value="currentResponse.comment = $event ?? undefined"
      ></BaseTextArea>
    </div>
    <div>
      <BaseDateField
        :label="t('recommendationResponse.dueDate')"
        :value="currentResponse.dueDate"
        @update:value="currentResponse.dueDate = $event ?? undefined"
      />
    </div>
    <div>
      <BaseTextField
        :label="`${t('recommendationResponse.costToComplete')} (${currency.toLocaleUpperCase()})`"
        :value="currentResponse.costToComplete"
        :type="'number'"
        @update:value="
          currentResponse.costToComplete =
            typeof $event === 'string' ? Number($event) : ($event ?? undefined)
        "
      />
    </div>
    <div>
      <BaseTextField
        :label="t('recommendationResponse.responsiblePerson')"
        :value="currentResponse.responsiblePerson"
        @update:value="
          currentResponse.responsiblePerson = typeof $event !== 'string' ? undefined : $event
        "
      />
    </div>
    <div v-if="isCreate">
      <BaseFileArea
        :allowed-file-types="allowedFileTypes"
        @on-add="($event) => createFiles.push(...$event)"
      />

      <ul v-if="createFiles.length" class="external-response-edit__file-list">
        <li
          v-for="(file, i) in createFiles"
          :key="`new_file_${i}`"
          class="external-response-edit__file-list__file"
        >
          <BaseIcon :icon="getIconForFileType(file.fileName)" size="medium" />
          {{ file.fileName }}
          <BaseButton
            :title="t('common.actions.delete')"
            variant="text"
            color="error"
            @click="() => createFiles.splice(i, 1)"
          >
            <BaseIcon icon="trash" size="small" />
          </BaseButton>
        </li>
      </ul>
    </div>
    <div v-else>
      <ExternalResponseFileList
        :allowed-file-types="allowedFileTypes"
        :response="currentResponse"
        can-edit
        @delete="deleteFile"
        @new="refreshFiles"
      />
    </div>

    <div class="external-response-edit__save">
      <span v-if="currentResponse.status === SiteResponseStatus.NotSet">
        {{ t("recommendationResponse.changeStatusToSave") }}
      </span>
      <BaseButton :disabled="currentResponse.status === SiteResponseStatus.NotSet" @click="save">
        {{ t("common.actions.save") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.external-response-edit {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  padding: $spacing-4;
  border: 1px solid $primary-4;
  border-radius: $rounded-base;
  background-color: $primary-0;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-semibold;
    padding-bottom: $spacing-2;

    &__status {
      font-size: $text-xs;
      color: $primary-6;
      border: 1px solid $primary-4;
      padding: $spacing-1 $spacing-2;
      border-radius: $rounded-full;
    }
  }

  &__file-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-2;
    gap: $spacing-2;

    &__file {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: $spacing-2;
      border: 1px solid $primary-4;
      border-radius: $rounded-base;
      padding-left: $spacing-2;
    }
  }

  &__save {
    margin-top: $spacing-2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-4;
  }
}
</style>
