import { useI18n } from "vue-i18n";
import { StandardRecommendationLeanDto } from "@/types/_generated/api";
import { ColumnDefinition } from "@/types/ColumnDefinition";

export const useStandardRecColumns = () => {
  const { t } = useI18n({ useScope: "global" });

  const columns: ColumnDefinition<StandardRecommendationLeanDto>[] = [
    new ColumnDefinition({
      title: t("standardRecommendations.name"),
      key: "header",
    }),
    new ColumnDefinition({
      title: t("standardRecommendations.client"),
      key: "clientName",
    }),
    new ColumnDefinition({
      title: t("standardRecommendations.module"),
      key: "moduleName",
    }),
    new ColumnDefinition({
      title: t("standardRecommendations.categoryTags"),
      key: "categories",
    }),
    new ColumnDefinition({
      title: t("standardRecommendations.questionTags"),
      key: "questions",
    }),
    new ColumnDefinition({
      title: t("standardRecommendations.country"),
      key: "country",
    }),
    new ColumnDefinition({
      title: t("standardRecommendations.type"),
      key: "type",
    }),
  ];

  return {
    columns,
  };
};
