import { ref } from "vue";
import { getBlueRatingRecommendationService } from "@/services/recommendations";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";

export const useGetRecommendation = () => {
  const isLoadingRecommendation = ref(false);
  const recommendation = ref<BlueRatingRecommendationDto | null>(null);

  const loadRecommendation = async (surveyId: number, recommendationId: number) => {
    isLoadingRecommendation.value = true;
    recommendation.value = await getBlueRatingRecommendationService(surveyId, recommendationId);
    isLoadingRecommendation.value = false;
  };

  return {
    isLoadingRecommendation,
    recommendation,
    loadRecommendation,
  };
};
