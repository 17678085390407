<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Option } from "@/types/Option";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import { StandardRecForm } from "../../composables/useStandardRecommendationForm";

const { t } = useI18n({ useScope: "global" });

interface Props {
  form: StandardRecForm;
  moduleOptions: Option[];
  disabled?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  "update:value": [number | undefined];
}>();
</script>

<template>
  <BaseSelect
    :label="t('standardRecommendations.module')"
    :value="form.moduleId"
    :disabled="disabled"
    @change="emit('update:value', Number($event))"
  >
    <BaseOption :value="''">
      {{ t("common.select") }}
    </BaseOption>
    <BaseOption v-for="option in moduleOptions" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
