<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8C0 7.58579 0.335786 7.25 0.75 7.25H15.25C15.6642 7.25 16 7.58579 16 8C16 8.41421 15.6642 8.75 15.25 8.75H0.75C0.335786 8.75 0 8.41421 0 8Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 3.75C1 1.67894 2.67894 0 4.75 0H11.25C13.3211 0 15 1.67894 15 3.75V4C15 4.41421 14.6642 4.75 14.25 4.75C13.8358 4.75 13.5 4.41421 13.5 4V3.75C13.5 2.50736 12.4927 1.5 11.25 1.5H4.75C3.50736 1.5 2.5 2.50736 2.5 3.75V5C2.5 6.24268 3.50736 7.25 4.75 7.25H11C11.4142 7.25 11.75 7.58579 11.75 8C11.75 8.41421 11.4142 8.75 11 8.75H4.75C2.67894 8.75 1 7.07112 1 5V3.75Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.25 9.75C14.6642 9.75 15 10.0858 15 10.5V12C15 14.0711 13.3211 15.75 11.25 15.75H4.75C2.67894 15.75 1 14.0711 1 12V11.5C1 11.0858 1.33579 10.75 1.75 10.75C2.16421 10.75 2.5 11.0858 2.5 11.5V12C2.5 13.2427 3.50736 14.25 4.75 14.25H11.25C12.4927 14.25 13.5 13.2427 13.5 12V10.5C13.5 10.0858 13.8358 9.75 14.25 9.75Z"
    />
  </svg>
</template>
