<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { RecReportSettingsScope } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import RecReportControlsScope from "./RecReportControlsScope.vue";

defineProps<{
  isLoading: boolean;
  isEmpty: boolean;
  selectedScope: RecReportSettingsScope;
}>();

defineEmits<{
  "delete:setting": [RecReportSettingsScope];
  "update:selected-scope": [RecReportSettingsScope];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="rec-report-controls">
    <div class="rec-report-controls__container">
      <div class="rec-report-controls__side">
        <RecReportControlsScope
          :is-loading="isLoading"
          :selected-scope="selectedScope"
          @update:selected-scope="$emit('update:selected-scope', $event)"
        />
      </div>

      <div class="rec-report-controls__side">
        <BaseButton
          :disabled="isLoading || isEmpty"
          size="small"
          variant="outlined"
          color="error"
          :title="t('common.actions.delete')"
          @click="$emit('delete:setting', selectedScope)"
        >
          <BaseIcon icon="trash" />
        </BaseButton>
      </div>
    </div>

    <BaseDivider />
  </div>
</template>

<style scoped lang="scss">
.rec-report-controls {
  padding-bottom: $spacing-6;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-4;
    margin-bottom: $spacing-6;
  }

  &__side {
    display: flex;
    gap: $spacing-2;
  }
}
</style>
