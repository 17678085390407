import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  GetSitesResponse,
  SiteDto,
  UserLeanDto,
  CreateSiteResponse,
  UpdateSite,
  UpdateSiteResponse,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const sitesEvents = new BroadcastService("sites");

export const getModuleSites = async (
  moduleId: number,
  includeOnlyPublished = false,
): Promise<GetSitesResponse | null> => {
  try {
    const { data } = await http.get<GetSitesResponse>(`/modules/${moduleId}/sites`, {
      params: { includeOnlyPublished },
    });

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("sites.sites"),
    });
  }
  return null;
};

export const getSiteService = async (siteId: number): Promise<SiteDto | null> => {
  try {
    const {
      data: { site },
    } = await http.get<{ site: SiteDto }>(`/sites/${siteId}`);
    return site;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("sites.site"),
    });
  }
  return null;
};

export const getSiteUsers = async (siteId: number): Promise<UserLeanDto[] | null> => {
  try {
    const {
      data: { users },
    } = await http.get<{ users: UserLeanDto[] }>(`/sites/${siteId}/users`);
    return users;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("users.users"),
    });
  }
  return null;
};

export const createSite = async (moduleId: number): Promise<number | null> => {
  try {
    const {
      data: { siteId },
    } = await http.post<CreateSiteResponse>(`/modules/${moduleId}/sites`);

    sitesEvents.post({ action: "create", id: siteId });

    notify.success(t("sites.site"), t("common.created").toLowerCase());

    return siteId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("sites.site"),
    });
  }
  return null;
};

export const updateSite = async (data: UpdateSite): Promise<boolean> => {
  const { moduleId, siteId, ...dataWithoutIds } = data;

  try {
    await http.put<UpdateSiteResponse>(`/modules/${moduleId}/sites/${siteId}`, dataWithoutIds);

    sitesEvents.post({ action: "update", id: siteId });

    notify.success(t("sites.site"), t("common.updated").toLowerCase());

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("sites.site"),
    });
  }
  return false;
};
