import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import {
  deleteBlueRatingRecommendationImage,
  getBlueRatingRecommendationService,
  updateBlueRatingRecommendationImageCaption,
} from "@/services/recommendations";
import {
  BlueRatingRecommendationDto,
  RecommendationImageMetaDataDto,
} from "@/types/_generated/api";

export const useHandleRecommendationImages = (
  recommendation: Ref<BlueRatingRecommendationDto | null>,
) => {
  const dialog = useDialog();
  const { t } = useI18n({ useScope: "global" });

  const deleteImage = async (image: RecommendationImageMetaDataDto) => {
    const isConfirmed = await dialog.openDeletePrompt(t("blueRating.recommendation.image"));

    if (isConfirmed) {
      await deleteBlueRatingRecommendationImage(
        recommendation.value!.surveyId,
        recommendation.value!.recommendationId,
        image.fileId,
      );
      await reloadRecommendationImages();
    }
  };

  const reloadRecommendationImages = async () => {
    const rec = await getBlueRatingRecommendationService(
      recommendation.value!.surveyId,
      recommendation.value!.recommendationId,
    );
    if (recommendation.value) {
      recommendation.value.images = rec?.images || [];
    }
  };

  const saveRecommendationImageCaption = async (image: RecommendationImageMetaDataDto) => {
    await updateBlueRatingRecommendationImageCaption(
      recommendation.value!.surveyId,
      recommendation.value!.recommendationId,
      image.fileId,
      image.caption,
    );
  };

  return {
    deleteRecommendationImage: deleteImage,
    reloadRecommendationImages,
    saveRecommendationImageCaption,
  };
};
