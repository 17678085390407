<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { WidthOptions } from "@/components/base/BaseDrawer/types";
import type { UserDto, UserModulePermissionDto } from "@/types/_generated/api";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { getUserModulePermissions, modulePermissionEvents } from "@/services/permissions";
import { getUserService, userEvents } from "@/services/users";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import { useImpersonation } from "../../composables/useImpersonation";
import UserEditDrawer from "../UserEditDrawer/UserEditDrawer.vue";
import UserDetails from "./UserDetails.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  userId: number;
  width?: WidthOptions;
}>();

defineEmits<{
  close: [void];
}>();

const { impersonate, isLoadingImpersonation } = useImpersonation();

const isLoading = ref(false);
const showEditUserDrawer = ref(false);

const user = ref<UserDto | null>(null);
const userModulePermissions = ref<UserModulePermissionDto[]>([]);

const loadUser = async () => {
  isLoading.value = true;

  const [userRes, userModulePermissionsRes] = await Promise.all([
    getUserService(props.userId),
    getUserModulePermissions(props.userId),
  ]);

  user.value = userRes;
  userModulePermissions.value = userModulePermissionsRes || [];

  isLoading.value = false;
};

onBeforeMount(loadUser);
useBroadcastService(userEvents, loadUser, ["update"]);
useBroadcastService(modulePermissionEvents, loadUser);
</script>

<template>
  <BaseDrawer
    v-if="!showEditUserDrawer"
    :is-loading="isLoading"
    :width="width ?? '50'"
    :title="t('users.detailDrawerTitle')"
    @close="$emit('close')"
  >
    <UserDetails v-if="user" :user="user" :user-module-permissions="userModulePermissions" />

    <template v-if="user && $authorize.isSuperiorTo(user.role)" #footer-left>
      <BaseButton v-if="$authorize.isAdmin()" @click="showEditUserDrawer = true">
        {{ t("users.editUser") }}
      </BaseButton>

      <BaseButton
        v-if="$authorize.isSystemOwner()"
        :disabled="isLoadingImpersonation"
        variant="outlined"
        @click="impersonate(user.id)"
      >
        {{ t("users.impersonateUser") }}
      </BaseButton>
    </template>
  </BaseDrawer>

  <UserEditDrawer v-if="showEditUserDrawer" :user-id="userId" @close="showEditUserDrawer = false" />
</template>
