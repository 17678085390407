<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SiteDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
const { t } = useI18n({ useScope: "global" });
import type { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { displayValue } from "@/helpers/displayValue";

interface Props {
  isLoadingSite: boolean;
  site: SiteDto | null;
}

defineProps<Props>();
</script>

<template>
  <BaseDataDisplayList :title="t('sites.siteDefinitions.title')">
    <BaseDataDisplayListItem
      v-for="number in [1, 2, 3, 4] as CustomDefinitionNumber[]"
      :key="number"
      :is-loading="isLoadingSite"
      :label="site?.[`moduleSiteDefinition${number}`] ?? ''"
    >
      <p>{{ displayValue(site?.[`custom${number}`]) }}</p>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>
