import { type Directive, nextTick } from "vue";

declare global {
  interface HTMLElement {
    _observer?: MutationObserver;
  }
}

const dynamicTableHeight: Directive<HTMLElement> = {
  mounted(el: HTMLElement) {
    const thSelector = "th[data-rotated]";
    const spanSelector = `${thSelector} > span`;

    const setDynamicPadding = async () => {
      const th = el.querySelector(thSelector); // Get the th header
      const spans = el.querySelectorAll(spanSelector); // Get all rotated spans inside the th
      if (!th || !spans.length) return;

      // Get the highest span height
      const maxHeight = Array.from(spans).reduce((max, span) => {
        const rect = span.getBoundingClientRect();
        return Math.max(max, rect.height);
      }, 0);

      const thHeight = th.getBoundingClientRect().height; // Get the th header actual height
      el.style.paddingTop = `${maxHeight - thHeight}px`; // Adjust top padding based on maxHeight
    };

    // Setup a MutationObserver to react to changes in the element
    const observer = new MutationObserver(async () => {
      await nextTick();
      setDynamicPadding();
    });

    // Start observing the element for attributes changes that affect layout and child list changes
    observer.observe(el, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: ["style", "class"],
    });

    // Ensure padding is set after initial mount
    nextTick().then(setDynamicPadding);

    // Cleanup the observer when the element is unmounted
    el._observer = observer; // Store observer in an underscore-prefixed property to signify its private nature
  },
  unmounted(el: HTMLElement) {
    if (el._observer) {
      el._observer.disconnect();
      delete el._observer; // Clean up the observer to prevent memory leaks
    }
  },
};

export { dynamicTableHeight as vDynamicTableHeight };
