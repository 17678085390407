/* Options:
Version: 8.40
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44335

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: Blue.Common.PermissionArgs/*,Blue.Common.Filters/*,Blue.ServiceModel/*,ServiceStack/*
//ExcludeTypes: HttpResult,Authenticate,AssignRoles,AssignRolesResponse,UnAssignRoles,UnAssignRolesResponse,FallbackForClientRoutes
//DefaultImports: 
*/


export interface IReturn<T>
{
}

export interface IReturnVoid
{
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IPost
{
}

export interface IGet
{
}

export interface SurveyPermission extends IPermissionArgs
{
    surveyId: number;
}

export interface IPermissionArgs
{
}

export interface FileDto
{
    fileName: string;
    file: string;
    fileSize: number;
}

export interface RecommendationImageDto extends FileDto
{
    rotation: RotateDegrees;
}

export interface IUploadRecommendationImages
{
    images: RecommendationImageDto[];
}

export interface RecommendationFilter
{
    recommendationIds?: number[];
    categoryIds?: number[];
    recommendationColors?: BlueColor[];
    siteResponseStatuses?: SiteResponseStatus[];
    surveyorStatuses?: SurveyorStatus[];
    rcbaRatioMin?: number;
    rcbaRatioMax?: number;
    rcbaCostToCompleteMin?: number;
    rcbaCostToCompleteMax?: number;
    recommendationNumber?: string;
    recommendationHeadline?: string;
    priority?: string;
    includeRecResponseFiles?: boolean;
}

export interface ReadModulePermission extends IPermissionArgs
{
    moduleId: number;
}

export interface ReadClientPermission extends IPermissionArgs
{
    clientId: number;
}

export interface CreateClientPermission extends IPermissionArgs
{
}

export interface EditClientPermission extends IPermissionArgs
{
    clientId: number;
}

// @DataContract
export interface CspReportDto
{
    // @DataMember(Name="document-uri")
    document_uri: string;

    // @DataMember(Name="referrer")
    referrer: string;

    // @DataMember(Name="violated-directive")
    violated_directive: string;

    // @DataMember(Name="effective-directive")
    effective_directive: string;

    // @DataMember(Name="original-policy")
    original_policy: string;

    // @DataMember(Name="disposition")
    disposition: string;

    // @DataMember(Name="blocked-uri")
    blocked_uri: string;

    // @DataMember(Name="line-number")
    line_number?: number;

    // @DataMember(Name="column-number")
    column_number?: number;

    // @DataMember(Name="source-file")
    source_file: string;

    // @DataMember(Name="status-code")
    status_code?: number;

    // @DataMember(Name="script-sample")
    script_sample: string;
}

export interface SiteDefinitionFilter extends ISiteDefinitionFilter
{
    custom1: string[];
    custom2: string[];
    custom3: string[];
    custom4: string[];
}

export interface IDataAnalysisFilter extends ISurveyFilter
{
    groupBySiteDefinition?: number;
}

export interface ISurveyFilter
{
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    excludedSiteDefinitions: SiteDefinitionFilter;
}

export interface ExecutiveReportFilter
{
    startDate?: string;
    endDate?: string;
    surveyType: SurveyType;
    siteIds: number[];
    surveyRound: number;
    includeOnlyLatestSurvey: boolean;
    treatNotApplicableAsZero: boolean;
    excludedSiteDefinitions: SiteDefinitionFilter;
    categoryId?: number;
    excludedCategoryIds: number[];
    groupBySiteDefinition?: number;
    sortBy: ExecReportSortOrder;
    sortByLossScenarioId?: number;
}

export interface BaseExecSummaryRequest extends ReadModulePermission
{
    filter: ExecutiveReportFilter;
}

export interface ReadSitePermission extends IPermissionArgs
{
    siteId: number;
}

export interface BaseExecSummaryExcelRequest extends BaseExecSummaryRequest
{
    showScore: boolean;
    showScorePercent: boolean;
    treatNotApplicableAsZero?: boolean;
}

export interface CreateFileDto
{
    fileName: string;
    file: string;
}

export interface CreateRecommendationResponseDto
{
    recommendationId: number;
    locked: boolean;
    status: SiteResponseStatus;
    comment?: string;
    dueDate?: string;
    costToComplete?: number;
    responsiblePerson?: string;
    files: CreateFileDto[];
}

export interface RecommendationResponseFileDto
{
    id: number;
    fileId: string;
    fileName: string;
}

export interface RecommendationResponseDto
{
    id: number;
    recommendationId: number;
    isSurveyorAutoChange: boolean;
    locked: boolean;
    updateDate: string;
    updateBy?: string;
    status: SiteResponseStatus;
    comment?: string;
    dueDate?: string;
    costToComplete?: number;
    responsiblePerson?: string;
    files: RecommendationResponseFileDto[];
}

export interface ImpersonatePermission extends IPermissionArgs
{
}

export interface BaseModulePermissionDto
{
    moduleId: number;
    createSite?: boolean;
    createSurvey?: boolean;
    editQuestionnaire?: boolean;
    editSurvey?: boolean;
    uploadFiles?: boolean;
    uploadStdRec?: boolean;
}

export interface SaveModulePermissionDto extends BaseModulePermissionDto
{
    modulePermissionId?: number;
    userId: number;
}

export interface DeleteModulePermissionsPermission extends IPermissionArgs
{
    modulePermissionIds: number[];
}

export interface ReadFullUserPermission extends IPermissionArgs
{
    userId: number;
}

export interface LossScenarioDto
{
    id?: number;
    name: string;
    propertyDamage: string;
    businessInterruption: string;
}

export interface EditModulePermission extends IPermissionArgs
{
    moduleId: number;
}

export interface CreateSitePermission extends IPermissionArgs
{
    moduleId: number;
}

export interface EditQuestionnairePermission extends IPermissionArgs
{
    questionnaireId: number;
}

export interface QuestionnaireDraftActionDto
{
    actionId?: number;
    action: DraftAction;
    type: QuestionnaireDraftActionType;
    targetActionId?: number;
    property?: string;
    value?: string;
}

export interface FromArea
{
    questionnaireId: number;
    areaIds: number[];
    categoryIds: number[];
    questionIds: number[];
}

export interface FromCategory
{
    questionnaireId: number;
    categoryIds: number[];
    questionIds: number[];
}

export interface FromQuestion
{
    questionnaireId: number;
    questionIds: number[];
}

export interface ReadQuestionnairePermission extends IPermissionArgs
{
    questionnaireId: number;
}

export interface EditQuestionnaireTemplatePermission extends IPermissionArgs
{
}

export interface ReadQuestionnaireTemplatesPermission extends IPermissionArgs
{
}

export interface ReadRecommendationResponsePermission extends IPermissionArgs
{
    moduleId: number;
    recommendationResponseId: number;
}

export interface EditRecommendationResponsePermission extends ReadRecommendationResponsePermission
{
}

export interface EditRecReportSettingsPermission extends IPermissionArgs
{
    settingId: number;
    moduleId?: number;
    scope?: RecReportSettingsScope;
}

export interface BaseRecReportSectionDto
{
    id: number;
    settingId: number;
    isVisible?: boolean;
    position: number;
    sectionType: RecReportSectionType;
}

export interface CoverPageSectionDto extends BaseRecReportSectionDto
{
    showBlueStripe?: boolean;
    showSiteImage?: boolean;
}

export interface RecReportTextDto
{
    custom?: string;
    default: string;
}

export interface ImportantNoticeSectionDto extends BaseRecReportSectionDto
{
    title?: RecReportTextDto;
    text?: RecReportTextDto;
}

export interface BlueRatingSectionDto extends BaseRecReportSectionDto
{
    title?: RecReportTextDto;
    introduction?: RecReportTextDto;
    blueTitle?: RecReportTextDto;
    blueText?: RecReportTextDto;
    greenTitle?: RecReportTextDto;
    greenText?: RecReportTextDto;
    yellowTitle?: RecReportTextDto;
    yellowText?: RecReportTextDto;
    redTitle?: RecReportTextDto;
    redText?: RecReportTextDto;
    grayTitle?: RecReportTextDto;
    grayText?: RecReportTextDto;
    closing?: RecReportTextDto;
    showScore?: boolean;
    showScorePercent?: boolean;
}

export interface RecSectionDto extends BaseRecReportSectionDto
{
    title?: RecReportTextDto;
    introduction?: RecReportTextDto;
    priorityTitle1?: RecReportTextDto;
    priorityTitle2?: RecReportTextDto;
    priorityTitle3?: RecReportTextDto;
    priorityTitle4?: RecReportTextDto;
    priorityTitle5?: RecReportTextDto;
    priorityText1?: RecReportTextDto;
    priorityText2?: RecReportTextDto;
    priorityText3?: RecReportTextDto;
    priorityText4?: RecReportTextDto;
    priorityText5?: RecReportTextDto;
    closing?: RecReportTextDto;
    recSortOrder: RecSortOrder;
}

export interface CustomSectionDto extends BaseRecReportSectionDto
{
    title: string;
    text: string;
}

export interface RecReportSettingsDto
{
    id: number;
    scope: RecReportSettingsScope;
    coverPageSection: CoverPageSectionDto;
    importantNoticeSection: ImportantNoticeSectionDto;
    blueRatingSection: BlueRatingSectionDto;
    recSection: RecSectionDto;
    customSection1: CustomSectionDto;
    customSection2: CustomSectionDto;
    customSection3: CustomSectionDto;
    customSection4: CustomSectionDto;
    customSection5: CustomSectionDto;
}

export interface RecommendationTrackingFilter extends RecommendationFilter, ISurveyFilter
{
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    excludedSiteDefinitions: SiteDefinitionFilter;
}

export interface BaseRecTrackingRequest extends ReadModulePermission
{
    siteId?: number;
    filter: RecommendationTrackingFilter;
}

export interface EditSitePermission extends IPermissionArgs
{
    siteId: number;
    moduleId: number;
}

export interface SitePermissionFilterDto
{
    customDefinitionNumber: number;
    values: string[];
}

export interface EditUserPermission extends IPermissionArgs
{
    userId: number;
}

export interface SpoeCheckPermission extends SurveyPermission
{
}

export interface ReadStandardRecommendationPermission extends IPermissionArgs
{
    standardRecommendationId: number;
}

export interface EditRecImageDto
{
    fileId?: string;
    file?: string;
    fileName: string;
    caption: string;
    rotation: RotateDegrees;
}

export interface EditStandardRecImagesPermission extends IPermissionArgs
{
    standardRecommendationId: number;
}

export interface StandardRecommendationFilter
{
    clientId: number;
    moduleId?: number;
    categoryHeader: string;
    questionHeader?: string;
    includeAllClients?: boolean;
}

export interface CreateStandardRecommendationPermission extends IPermissionArgs
{
    clientId?: number;
    moduleId?: number;
}

export interface EditStandardRecommendationPermission extends IPermissionArgs
{
    standardRecommendationId: number;
    clientId?: number;
    moduleId?: number;
}

export interface EmailAddressDto
{
    email: string;
    alias: string;
}

export interface SurveyReportRecipientDto
{
    email: string;
    mobile: string;
    includeRecommendationResponseLink: boolean;
    continueRemindingWhenSiteResponseIsGiven: boolean;
    reminderDates: string[];
}

export interface SurveyParticipantDto
{
    participantId?: number;
    firstName: string;
    lastName: string;
    title: string;
    company: string;
    email: string;
    mobile: string;
}

export interface SurveyInsuredValueDto
{
    surveyId: number;
    lossScenarioId: number;
    propertyDamage: number;
    businessInterruption: number;
}

export interface UserAdministrationPermission extends IPermissionArgs
{
}

export interface CreateUserPermission extends IPermissionArgs
{
    role: Role;
}

export interface ArchiveUserPermission extends IPermissionArgs
{
    userId: number;
}

// @DataContract
export interface UserApiKey
{
    // @DataMember(Order=1)
    key: string;

    // @DataMember(Order=2)
    keyType: string;

    // @DataMember(Order=3)
    expiryDate?: string;

    // @DataMember(Order=4)
    meta: { [index: string]: string; };
}

// @DataContract
export interface ResponseError
{
    // @DataMember(Order=1)
    errorCode: string;

    // @DataMember(Order=2)
    fieldName: string;

    // @DataMember(Order=3)
    message: string;

    // @DataMember(Order=4)
    meta: { [index: string]: string; };
}

// @DataContract
export interface ResponseStatus
{
    // @DataMember(Order=1)
    errorCode: string;

    // @DataMember(Order=2)
    message: string;

    // @DataMember(Order=3)
    stackTrace: string;

    // @DataMember(Order=4)
    errors: ResponseError[];

    // @DataMember(Order=5)
    meta: { [index: string]: string; };
}

export interface BaseResponse
{
    responseStatus: ResponseStatus;
}

export interface RiskCostBenefitAnalysisDto
{
    rcbaDescription: string;
    rcbaProbability: string;
    rcbaPropertyPrior?: number;
    rcbaPropertyAfter?: number;
    rcbaBiPrior?: number;
    rcbaBiAfter?: number;
    rcbaExtraPrior?: number;
    rcbaExtraAfter?: number;
    rcbaCostToComplete?: number;
    rcbaCurrency: string;
    rcbaLossEstimateType: LossEstimateType;
}

export interface RecommendationImageMetaDataDto
{
    fileId: string;
    position: number;
    caption: string;
    fileName: string;
    fileSize: number;
}

export interface RecommendationResponseHistoryDto
{
    status: SiteResponseStatus;
    statusUpdateDate?: string;
    statusUpdateBy?: string;
    comment?: RecommendationResponseValueDto<string>;
    dueDate?: RecommendationResponseValueDto<string>;
    costToComplete?: RecommendationResponseValueDto<number>;
    responsiblePerson?: RecommendationResponseValueDto<string>;
    history: RecommendationResponseDto[];
}

export interface RecommendationRiskManagerCommentDto
{
    id: number;
    recommendationId: number;
    userId: number;
    email?: string;
    locked: boolean;
    comment?: string;
    insertDate: string;
}

export interface BlueRatingRecommendationDto extends RiskCostBenefitAnalysisDto
{
    surveyId: number;
    recommendationId: number;
    parentRecommendationId?: number;
    categoryId?: number;
    questionIds: number[];
    position: number;
    fullPosition: string;
    header: string;
    location: string;
    priority: string;
    effectiveColor: BlueColor;
    categoryColorOverride: BlueColor;
    surveyorStatus: SurveyorStatus;
    originalSurveyDate?: string;
    abeyanceDate?: string;
    abeyanceComment: string;
    completedDate?: string;
    completedComment: string;
    text: string;
    comment: string;
    type: string;
    spoeCheckComment: string;
    isGeneralRecommendation: boolean;
    isKeyRecommendation: boolean;
    images: RecommendationImageMetaDataDto[];
    responseHistory: RecommendationResponseHistoryDto;
    riskManagerCommentHistory: RecommendationRiskManagerCommentDto[];
}

export interface SurveyRatingDto
{
    color: BlueColor;
    text: string;
    score: number;
    isCategoryColorOverride: boolean;
    description: string;
}

export interface BlueRatingRecommendationLeanDto
{
    recommendationId: number;
    surveyId: number;
    categoryId?: number;
    questionIds: number[];
    surveyorStatus: SurveyorStatus;
    categoryColorOverride: BlueColor;
    position: number;
    fullPosition: string;
    header: string;
    text: string;
    type: string;
    priority: string;
    comment: string;
    location: string;
    abeyanceDate?: string;
    abeyanceComment: string;
    completedDate?: string;
    completedComment: string;
    isKeyRecommendation: boolean;
    isGeneralRecommendation: boolean;
}

export interface BlueRatingQuestionDto
{
    questionId: number;
    text: string;
    description: string;
    position: number;
    fullPosition: string;
    blue: SurveyRatingDto;
    green: SurveyRatingDto;
    yellow: SurveyRatingDto;
    red: SurveyRatingDto;
    gray: SurveyRatingDto;
    value?: BlueColor;
    recommendations: BlueRatingRecommendationLeanDto[];
}

export interface BlueRatingCategoryDto
{
    categoryId: number;
    name: string;
    position: number;
    fullPosition: string;
    color: BlueColor;
    gradeBy: CategoryGrading;
    blueLimit: number;
    greenLimit: number;
    yellowLimit: number;
    maxScore: number;
    notes: string;
    questions: BlueRatingQuestionDto[];
    recommendations: BlueRatingRecommendationLeanDto[];
}

export interface BlueRatingAreaDto
{
    areaId: number;
    name: string;
    position: number;
    maxScore: number;
    categories: BlueRatingCategoryDto[];
}

export interface BlueRatingDto
{
    moduleId: number;
    siteId: number;
    surveyId: number;
    status: Status;
    questionnaireId: number;
    maxScore: number;
    areas: BlueRatingAreaDto[];
    surveyDate: string;
    generalRecommendations: BlueRatingRecommendationLeanDto[];
}

export interface ClientDto
{
    clientId: number;
    status: Status;
    name: string;
    willisCountryOrUnit: string;
    willisOffice: string;
    website: string;
    moduleCount: number;
    siteCount: number;
}

export interface SurveyHistoryEntryDto
{
    siteName: string;
    siteId: number;
    sentDate: string;
}

export interface BlueTrendDataPointDto
{
    year: number;
    average: number;
    averageScore: number;
    groupName?: string;
}

export interface BlueTrendResultDto
{
    group?: string;
    dataPoints: BlueTrendDataPointDto[];
}

export interface ExecutiveReportGroupDto
{
    name: string;
    averageScorePercent: number;
}

export interface ExecutiveReportReplyDataDto
{
    score: number;
    color: BlueColor;
}

export interface ExecutiveReportCategoryDataDto
{
    categoryId: number;
    color: BlueColor;
    completed: boolean;
    abeyance: boolean;
    score: number;
    maxScore: number;
    scorePercent: number;
    replies: ExecutiveReportReplyDataDto[];
}

export interface ExecutiveReportInsuredValueDto
{
    lossScenarioId: number;
    pd: number;
    bi: number;
    pdBi: number;
}

export interface ExecutiveReportSurveyDto
{
    surveyId: number;
    surveyDate: string;
    groupName: string;
    siteDefinitions: string[];
    siteId: number;
    type: SurveyType;
    categoryData: ExecutiveReportCategoryDataDto[];
    totalScore: number;
    maxScore: number;
    scorePercent: number;
    insuredValues: ExecutiveReportInsuredValueDto[];
}

export interface ExecutiveReportQuestionDto
{
    position: number;
    description: string;
    text: string;
}

export interface ExecutiveReportCategoryDto
{
    maxScore: number;
    categoryName: string;
    categoryId: number;
    areaId: number;
    areaName: string;
    position: string;
    scorePercentAverage: number;
    isExcluded: boolean;
    questions: ExecutiveReportQuestionDto[];
}

export interface ExecutiveReportAreaDto
{
    areaId: number;
    areaName: string;
    scorePercentAverage: number;
    position: string;
    maxScore: number;
}

export interface ExecutiveReportLossScenarioDto
{
    id: number;
    name: string;
}

export interface RecResponseSurveyInfoDto
{
    moduleName: string;
    siteName: string;
    surveyDate: string;
    responseStatus: RecResponseRespondStatus;
}

export interface ListFileDto
{
    fileId: string;
    fileName: string;
}

export interface RecResponseModuleTranslationsDto
{
    abeyanceTranslation: string;
    textForBlue: string;
    textForGreen: string;
    textForYellow: string;
    textForRed: string;
}

export interface RecommendationTrackingSurveyMetaDataDto
{
    siteId: number;
    surveyId: number;
    surveyDate: string;
    surveyType: SurveyType;
    surveyorName?: string;
    custom1?: string;
    custom2?: string;
    custom3?: string;
    custom4?: string;
}

export interface TrackedRecommendationDto
{
    id: number;
    surveyId: number;
    categoryId?: number;
    latestCategoryId?: number;
    costBenefitRatio: string;
    rcbaCostToComplete?: number;
    fullPosition: string;
    priority: string;
    color: BlueColor;
    heading: string;
    siteResponseStatus: SiteResponseStatus;
    surveyorStatus: SurveyorStatus;
    siteResponseCostToComplete?: number;
    siteResponseComment: string;
    siteResponseResponsiblePerson?: string;
    siteResponseDueDate?: string;
    type: string;
    riskManagerComment?: string;
    isKeyRecommendation: boolean;
}

export interface RecResponseRecTrackingDto
{
    surveyMetaData: RecommendationTrackingSurveyMetaDataDto[];
    trackedRecommendations: TrackedRecommendationDto[];
    categoryNames: { [index: number]: string; };
}

export interface RecResponseSummaryDto
{
    surveyInfo: RecResponseSurveyInfoDto;
    showResponse: boolean;
    report?: ListFileDto;
    attachments?: ListFileDto[];
    moduleTranslations: RecResponseModuleTranslationsDto;
    recTracking: RecResponseRecTrackingDto;
    recommendations: BlueRatingRecommendationDto[];
    moduleCurrency: string;
}

export interface RecResponseSurveyDto
{
    surveyInfo: RecResponseSurveyInfoDto;
    recommendations: BlueRatingRecommendationDto[];
    blueRating: BlueRatingDto;
    moduleTranslations: RecResponseModuleTranslationsDto;
    moduleCurrency: string;
}

export interface CurrentUserDto
{
    id: number;
    firstName: string;
    lastName: string;
    company: string;
    role: Role;
    email: string;
    languageCode: string;
    phoneNumber: string;
    mobilePhoneNumber: string;
    twoFactorAuthentication: boolean;
    hasAcceptedTermsOfService: boolean;
    allowReportMail: boolean;
    allowUserWeb: boolean;
    allowRiskManagerComment: boolean;
    modulePermissions?: BaseModulePermissionDto[];
    sitePermissions?: number[];
}

export interface LibraryFileDto
{
    fileId: string;
    fileName: string;
    language: Language;
    isVisibleInUserWeb: boolean;
    tags: string[];
}

export interface IndustryDto
{
    name: string;
    sector: string;
}

export interface ModulePermissionDto extends BaseModulePermissionDto
{
    modulePermissionId: number;
    userId: number;
    userName: string;
    userRole: Role;
}

export interface UserModulePermissionDto extends BaseModulePermissionDto
{
    modulePermissionId: number;
    clientId: number;
    clientName: string;
    moduleName: string;
}

export interface ModuleLeanDto
{
    moduleId: number;
    name: string;
    clientId: number;
    clientName: string;
    administratorUserId: number;
    administratorRole: Role;
    administratorName: string;
    willisOffice: string;
    willisCountryOrUnit: string;
    status: Status;
}

export interface ModuleDto
{
    moduleId: number;
    name: string;
    status: Status;
    clientId: number;
    clientName: string;
    administratorUserId: number;
    administratorName: string;
    administratorRole: Role;
    willisOffice: string;
    willisCountryOrUnit: string;
    currentQuestionnaireId: number;
    web: string;
    contactId?: number;
    contactRole?: Role;
    contactEmail?: string;
    contactMobile?: string;
    contactFullName?: string;
    contactPhone?: string;
    accountEngineerId: number;
    accountEngineerRole: Role;
    accountEngineerFullName: string;
    accountEngineerEmail: string;
    language: Language;
    reportMailLoginType: ReportMailLoginType;
    recSortOrder: RecSortOrder;
    userWebLoginType: MfaOption;
    allowSpoeCheckByExt: boolean;
    hasExecSumIncludeSiteSummary: boolean;
    hasSiteResponseBlueRating: boolean;
    requireSpoeCheck: boolean;
    currencyCode: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    poBox: string;
    siteDefinition1: string;
    siteDefinition2: string;
    siteDefinition3: string;
    siteDefinition4: string;
    forceSuggestion1: boolean;
    forceSuggestion2: boolean;
    forceSuggestion3: boolean;
    forceSuggestion4: boolean;
    suggestions1: string[];
    suggestions2: string[];
    suggestions3: string[];
    suggestions4: string[];
    recommendationTypes: string[];
    textForBlue: string;
    textForGreen: string;
    textForYellow: string;
    textForRed: string;
    userWebText: string;
    zipCode: string;
    preSurveyMailSubject: string;
    preSurveyMailBody: string;
    reportMailSubject: string;
    reportMailBody: string;
    showAForAbeyance: boolean;
    forceOverrideCategoryColor: boolean;
    forceName: boolean;
    forceLocation: boolean;
    forcePriority: boolean;
    forceType: boolean;
    showCForAllCompletedOnCategory: boolean;
    abeyanceTranslation: string;
    abeyanceShort: string;
    locationTranslation: string;
    nameTranslation: string;
    priorityTranslation: string;
    autoNotifyStakeholders: boolean;
    lossScenarios: LossScenarioDto[];
}

export interface CustomSiteDefinitionDto
{
    customDefinitionNumber: number;
    name: string;
    values: string[];
}

export interface SiteDefinitionSuggestionsDto
{
    forceSuggestion: boolean;
    siteDefinitionName: string;
    siteDefinitionNumber: number;
    suggestions: string[];
}

export interface ModuleSettingsDto
{
    allowSpoeCheckByExt: boolean;
    requireSpoeCheck: boolean;
    currencyCode: string;
    lossScenarios: LossScenarioDto[];
}

export interface QuestionDto
{
    questionId: number;
    basedOnQuestionId?: number;
    categoryId: number;
    position: number;
    description: string;
    text: string;
    blueScore: number;
    blueText: string;
    greenScore: number;
    greenOverridesCategoryColor: boolean;
    greenText: string;
    redScore: number;
    redOverridesCategoryColor: boolean;
    redText: string;
    yellowScore: number;
    yellowOverridesCategoryColor: boolean;
    yellowText: string;
    insertDate: string;
    insertBy: number;
    updateDate?: string;
    updateBy?: number;
}

export interface CategoryDto
{
    categoryId: number;
    basedOnCategoryId?: number;
    areaId: number;
    position: number;
    name: string;
    gradeBy: CategoryGrading;
    blueLimit?: number;
    greenLimit?: number;
    yellowLimit?: number;
    insertDate: string;
    insertBy: number;
    updateDate?: string;
    updateBy?: number;
    questions: QuestionDto[];
}

export interface AreaDto
{
    areaId: number;
    questionnaireId: number;
    language?: Language;
    position: number;
    industry: string;
    name: string;
    riskType: string;
    tags: string;
    willisCountryOrUnit: string;
    insertDate: string;
    insertBy: number;
    updateDate?: string;
    updateBy?: number;
    categories: CategoryDto[];
}

export interface QuestionnaireDto
{
    questionnaireId: number;
    moduleId?: number;
    name: string;
    hasDraft: boolean;
    insertDate: string;
    insertBy: number;
    updateDate?: string;
    updateBy?: number;
    areas: AreaDto[];
}

export interface QuestionnaireDraftHistoryActionDto
{
    actionId: number;
    action: DraftAction;
    type: QuestionnaireDraftActionType;
    property?: string;
    targetName: string;
    value?: string;
    insertBy: number;
    insertByFullName?: string;
    insertDate: string;
}

export interface QuestionnaireDraftPropertyHistoryDto
{
    value?: string;
    insertBy: number;
    insertByFullName?: string;
    insertDate: string;
}

export interface QuestionnaireLeanDto
{
    questionnaireId: number;
    isTemplate: boolean;
    questionnaireName: string;
    questionnaireVersion: number;
    insertDate: string;
    insertBy: number;
    updateDate?: string;
    updateBy?: number;
    areaId: number;
    name: string;
    tags: string[];
    riskType: string;
    industry: string;
}

export interface QuestionnaireTemplateLeanDto
{
    questionnaireId: number;
    questionnaireName: string;
    insertDate: string;
    insertBy: number;
    insertByFullName?: string;
    updateDate?: string;
    updateBy?: number;
    tags: string[];
    riskTypes: string[];
    industries: string[];
}

export interface RecentActivityDto
{
    moduleId?: number;
    siteId?: number;
    surveyId?: number;
    name: string;
    lastVisit: string;
}

export interface ServiceMessageLeanDto
{
    subject: string;
    message: string;
    severity: Severity;
}

export interface ServiceMessageDto extends ServiceMessageLeanDto
{
    id: number;
    active: boolean;
    insertBy: number;
    insertDate: string;
    updateBy?: number;
    updateDate?: string;
}

export interface SiteExemptionDto
{
    exemptionId: number;
    number: string;
    location: string;
    comment: string;
    text: string;
    addedDate: string;
    reviewedDate?: string;
    addedByUserId: number;
    addedByFullName: string;
    reviewedByUserId?: number;
    reviewedByFullName?: string;
}

export interface EvaluatedSitePermissionDto
{
    siteId: number;
    moduleId: number;
    custom1?: string;
    custom2?: string;
    custom3?: string;
    custom4?: string;
}

export interface SitePermissionDto
{
    userId: number;
    userFullName: string;
    userRole: Role;
    sitePermissionId?: number;
    siteId: number;
    isImplicit: boolean;
}

export interface UserSitePermissionDto
{
    sitePermissionId?: number;
    moduleId: number;
    siteId: number;
    isImplicit: boolean;
    custom1?: string;
    custom2?: string;
    custom3?: string;
    custom4?: string;
}

export interface SiteLeanDto
{
    siteId: number;
    custom1?: string;
    custom2?: string;
    custom3?: string;
    custom4?: string;
    name: string;
    surveyCount: number;
    status: Status;
    latestSurveyDate?: string;
    latestSurveyStatus?: Status;
    nextSurveyDate?: string;
    surveyorId?: number;
    surveyorName?: string;
    reportDownloadStatus?: ReportDownloadStatus;
    recResponseSubmitDate?: string;
    reminderEmailCount: number;
    coordinates?: string;
    latitude?: number;
    longitude?: number;
    daysWithNoResponse?: number;
    firstReportMailDate?: string;
}

export interface SiteDto extends SiteLeanDto
{
    address: string;
    city: string;
    country: string;
    moduleSiteDefinition1?: string;
    moduleSiteDefinition2?: string;
    moduleSiteDefinition3?: string;
    moduleSiteDefinition4?: string;
    phone: string;
    poBox: string;
    zipCode: string;
    web: string;
    reportImageFileId?: string;
}

export interface StandardRecommendationLeanDto
{
    standardRecommendationId: number;
    header: string;
    text: string;
    type: string;
    country: string;
    clientId?: number;
    clientName?: string;
    moduleId?: number;
    moduleName?: string;
    categories: string[];
    questions: string[];
}

export interface StandardRecommendationDto extends StandardRecommendationLeanDto
{
    images: RecommendationImageMetaDataDto[];
}

export interface ReportMailDto
{
    id: number;
    subject: string;
    body: string;
    recipient: string;
    link: string;
    code: string;
    type: string;
    isActive: boolean;
    sentDate?: string;
    reminderHandled: boolean;
}

export interface SurveyReportFileDto
{
    id: string;
    fileName: string;
    mimeType: string;
    type: SurveyFileType;
}

export interface SurveyLeanDto
{
    moduleId: number;
    siteId: number;
    surveyId: number;
    surveyDate: string;
    status: Status;
    spoeCheckerId?: number;
    spoeCheckerRole?: Role;
    spoeCheckerName?: string;
    isSpoeCheckApproved?: boolean;
    recResponseSubmitDate?: string;
    surveyorId?: number;
    surveyorName?: string;
    surveyorRole?: Role;
}

export interface SurveyDto
{
    surveyId: number;
    moduleId: number;
    siteId: number;
    spoeCheckerUserId?: number;
    surveyorUserId?: number;
    isSpoeCheckApproved?: boolean;
    status: Status;
    participants: SurveyParticipantDto[];
    recommendationResponseRecipients: number[];
    surveyDate: string;
    copyDataFromPreviousSurvey?: boolean;
    parentsArePublished: boolean;
    insuredValues: SurveyInsuredValueDto[];
}

export interface LoginAttemptDto
{
    loginAttemptId?: number;
    userId?: number;
    userAuthName: string;
    userRole?: Role;
    result: LoginAttemptResult;
    ip: string;
    url: string;
    userAgent: string;
    isSingleSignOn: boolean;
    insertDate: string;
}

export interface ErrorLogEntryDto
{
    id: number;
    userId?: number;
    impersonatedByUserId?: number;
    moduleId?: number;
    siteId?: number;
    surveyId?: number;
    activity: string;
    message: string;
    timeStamp: string;
    exception: string;
    clientIp: string;
}

export interface ActivityLogEntryDto
{
    id: number;
    type: string;
    currentUserId?: number;
    currentUserAuthName?: string;
    currentUserRole?: Role;
    userId?: number;
    userAuthName?: string;
    userRole?: Role;
    moduleId?: number;
    moduleName?: string;
    siteId?: number;
    siteDefinition1?: string;
    siteDefinition2?: string;
    siteDefinition3?: string;
    siteDefinition4?: string;
    surveyId?: number;
    surveyDate?: string;
    impersonatedByUserId?: number;
    impersonatedByUserAuthName?: string;
    ip: string;
    userAgent: string;
    insertDate: string;
}

export interface UserClientDto
{
    name: string;
    clientId: number;
}

export interface UserLeanDto
{
    id: number;
    lastName: string;
    firstName: string;
    fullName: string;
    email: string;
    company: string;
    country: string;
    city: string;
    phoneNumber: string;
    mobilePhoneNumber: string;
    title: string;
    role: Role;
    loginType: LoginType;
    isArchived?: boolean;
    lockedDate?: string;
    clients: UserClientDto[];
}

export interface EditRecordDto
{
    name?: string;
    email?: string;
    date: string;
}

export interface UserDto extends UserLeanDto
{
    language: Language;
    userName: string;
    languageCode: string;
    mfa: MfaOption;
    allowUserWeb: boolean;
    allowReportMail: boolean;
    allowRmComment: boolean;
    lastLogin?: string;
    insert?: EditRecordDto;
    update?: EditRecordDto;
}

export interface ISiteDefinitionFilter
{
    custom1: string[];
    custom2: string[];
    custom3: string[];
    custom4: string[];
}

export interface BlueLossValueDataPointDto
{
    custom1: string;
    lossValue: number;
    score: number;
    scorePercent: number;
}

export interface BlueLossValueResultDto
{
    group: string;
    dataPoints: BlueLossValueDataPointDto[];
}

export interface RecommendationResponseValueDto<T>
{
    value: T;
    updateDate: string;
    updateBy?: string;
}

// @DataContract
export interface AuthenticateResponse extends IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    userId: string;

    // @DataMember(Order=2)
    sessionId: string;

    // @DataMember(Order=3)
    userName: string;

    // @DataMember(Order=4)
    displayName: string;

    // @DataMember(Order=5)
    referrerUrl: string;

    // @DataMember(Order=6)
    bearerToken: string;

    // @DataMember(Order=7)
    refreshToken: string;

    // @DataMember(Order=8)
    refreshTokenExpiry?: string;

    // @DataMember(Order=9)
    profileUrl: string;

    // @DataMember(Order=10)
    roles: string[];

    // @DataMember(Order=11)
    permissions: string[];

    // @DataMember(Order=12)
    responseStatus: ResponseStatus;

    // @DataMember(Order=13)
    meta: { [index: string]: string; };
}

// @DataContract
export interface RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    results: UserApiKey[];

    // @DataMember(Order=2)
    meta: { [index: string]: string; };

    // @DataMember(Order=3)
    responseStatus: ResponseStatus;
}

export interface GetApplicationSettingsResponse extends BaseResponse
{
    bingMapKey?: string;
    imageFileExtensions: string[];
    imageMaxSizeInBytes: number;
    documentFileExtensions: string[];
    recommendationResponseFileExtensions: string[];
    documentMaxSizeInBytes: number;
    version: string;
}

export interface UploadSurveyRecommendationImageResponse extends BaseResponse
{
}

export interface UpdateSurveyRecommendationImageCaptionResponse extends BaseResponse
{
}

export interface SearchBlueRatingRecommendationsResponse extends BaseResponse
{
    recommendations: BlueRatingRecommendationDto[];
}

export interface GetBlueRatingRecommendationResponse extends BaseResponse
{
    recommendation: BlueRatingRecommendationDto;
}

export interface CreateBlueRatingRecommendationResponse extends BaseResponse
{
    recommendationId: number;
}

export interface UpdateBlueRatingRecommendationResponse extends BaseResponse
{
    recommendationId: number;
}

export interface GetBlueRatingResponse extends BaseResponse
{
    blueRating: BlueRatingDto;
}

export interface GetBlueRatingsResponse extends BaseResponse
{
    surveys: BlueRatingDto[];
}

export interface SaveCategoryNotesResponse extends BaseResponse
{
}

export interface CalculateCategoryColorResponse extends BaseResponse
{
    color: BlueColor;
}

export interface GetClientResponse extends BaseResponse
{
    client: ClientDto;
}

export interface GetClientsResponse extends BaseResponse
{
    clients: ClientDto[];
}

export interface CreateClientResponse extends BaseResponse
{
    clientId: number;
}

export interface CreateCspReportResponse extends BaseResponse
{
}

export interface GetDashboardAverageScoreResponse extends BaseResponse
{
    totalAverageScore: number;
}

export interface GetDashboardRecResponseRatioResponse extends BaseResponse
{
    recommendationResponseRatio: number;
}

export interface GetDashboardSurveyHistoryResponse extends BaseResponse
{
    surveyHistory: SurveyHistoryEntryDto[];
}

export interface SearchBlueTrendResponse extends BaseResponse
{
    blueTrend: BlueTrendResultDto[];
}

export interface SearchBlueLossValuesResponse extends BaseResponse
{
    currencyCode: string;
    blueLossValues: { [index: number]: BlueLossValueResultDto[]; };
}

export interface SearchExecutiveSummaryResponse extends BaseResponse
{
    moduleId: number;
    moduleName: string;
    clientId: number;
    questionnaireVersion: number;
    groups: ExecutiveReportGroupDto[];
    surveys: ExecutiveReportSurveyDto[];
    categories: ExecutiveReportCategoryDto[];
    includedCategories: ExecutiveReportCategoryDto[];
    areas: ExecutiveReportAreaDto[];
    lossScenarios: ExecutiveReportLossScenarioDto[];
    scorePercentAverage: number;
}

export interface GetBlueRatingSummaryResponse extends BaseResponse
{
    surveys: ExecutiveReportSurveyDto[];
    categories: ExecutiveReportCategoryDto[];
    areas: ExecutiveReportAreaDto[];
}

export interface GetMfaOptionsResponse extends BaseResponse
{
    mfaOptions: MfaMethod[];
}

export interface RequestMfaResponse extends BaseResponse
{
    expirationTimeHours?: number;
}

export interface GetRecResponseResponse extends BaseResponse
{
    recommendationResponse: RecommendationResponseDto;
}

export interface GetRecResponseSummaryResponse extends BaseResponse
{
    recResponseSummary: RecResponseSummaryDto;
}

export interface GetRecResponseSurveyResponse extends BaseResponse
{
    survey: RecResponseSurveyDto;
    fileExtensions: string[];
}

export interface CreateRecResponseResponse extends BaseResponse
{
    response: RecommendationResponseDto;
}

export interface SubmitRecommendationResponsesResponse extends BaseResponse
{
    survey: RecResponseSurveyDto;
}

export interface ImpersonateUserResponse extends BaseResponse
{
    user?: CurrentUserDto;
    isImpersonated: boolean;
}

export interface EndImpersonationResponse extends BaseResponse
{
    user?: CurrentUserDto;
    isImpersonated: boolean;
}

export interface GetLibraryFilesResponse extends BaseResponse
{
    libraryFiles: LibraryFileDto[];
}

export interface IsAuthenticatedResponse extends BaseResponse
{
    user?: CurrentUserDto;
    isImpersonated: boolean;
    isAuthenticated: boolean;
}

export interface GetSsoSettingsResponse extends BaseResponse
{
    showSsoLogin: boolean;
    url?: string;
}

export interface GetIndustriesResponse extends BaseResponse
{
    industries: IndustryDto[];
}

export interface GetRiskTypesResponse extends BaseResponse
{
    riskTypes: string[];
}

export interface GetModulePermissionsResponse extends BaseResponse
{
    modulePermissions: ModulePermissionDto[];
}

export interface CreateModulePermissionsResponse extends BaseResponse
{
}

export interface UpdateModulePermissionsResponse extends BaseResponse
{
}

export interface DeleteModulePermissionsResponse extends BaseResponse
{
}

export interface GetUserModulePermissionsResponse extends BaseResponse
{
    modulePermissions: UserModulePermissionDto[];
}

export interface SaveUserModulePermissionsResponse extends BaseResponse
{
}

export interface GetModulesResponse extends BaseResponse
{
    modules: ModuleLeanDto[];
}

export interface GetModuleResponse extends BaseResponse
{
    module: ModuleDto;
}

export interface GetEffectiveSiteDefinitionsResponse extends BaseResponse
{
    siteDefinitions: CustomSiteDefinitionDto[];
}

export interface GetSiteDefinitionSuggestionsResponse extends BaseResponse
{
    siteDefinitionSuggestions: SiteDefinitionSuggestionsDto[];
}

export interface CreateModuleResponse extends BaseResponse
{
    moduleId: number;
}

export interface GetModuleSettingsResponse extends BaseResponse
{
    settings: ModuleSettingsDto;
}

export interface SendResetPasswordLinkResponse extends BaseResponse
{
}

export interface ResetPasswordResponse extends BaseResponse
{
}

export interface GetQuestionnaireDraftResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface CreateQuestionnaireDraftResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface UpdateQuestionnaireDraftResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface RevertQuestionnaireDraftResponse extends BaseResponse
{
}

export interface GetQuestionnaireDraftHistoryResponse extends BaseResponse
{
    history: QuestionnaireDraftHistoryActionDto[];
}

export interface GetQuestionnaireDraftPropertyHistoryResponse extends BaseResponse
{
    history: QuestionnaireDraftPropertyHistoryDto[];
}

export interface PublishQuestionnaireDraftResponse extends BaseResponse
{
    questionnaireId: number;
}

export interface InsertQuestionnaireDraftAreasResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface InsertQuestionnaireDraftCategoriesResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface InsertQuestionnaireDraftQuestionsResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface GetCurrentQuestionnaireResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface GetQuestionnaireResponse extends BaseResponse
{
    questionnaire: QuestionnaireDto;
}

export interface UnpublishQuestionnaireResponse extends BaseResponse
{
    currentQuestionnaireId: number;
}

export interface GetQuestionnairesResponse extends BaseResponse
{
    questionnaires: QuestionnaireLeanDto[];
}

export interface CreateQuestionnaireTemplateResponse extends BaseResponse
{
    questionnaireId: number;
}

export interface GetQuestionnaireTemplatesResponse extends BaseResponse
{
    questionnaires: QuestionnaireTemplateLeanDto[];
}

export interface GetRecentActivityResponse extends BaseResponse
{
    modules: RecentActivityDto[];
    sites: RecentActivityDto[];
    surveys: RecentActivityDto[];
}

export interface GetRecommendationResponseResponse extends BaseResponse
{
    recommendationResponse: RecommendationResponseDto;
}

export interface GetAllRecReportSettingsResponse extends BaseResponse
{
    settings: RecReportSettingsDto[];
}

export interface GetRecReportSettingsResponse extends BaseResponse
{
    settings: RecReportSettingsDto;
}

export interface CreateRecReportSettingsResponse extends BaseResponse
{
    id: number;
}

export interface UpdateRecReportSettingsResponse extends BaseResponse
{
}

export interface DeleteRecReportSettingsResponse extends BaseResponse
{
}

export interface SearchRecTrackingResponse extends BaseResponse
{
    surveyMetaData: RecommendationTrackingSurveyMetaDataDto[];
    trackedRecommendations: TrackedRecommendationDto[];
    categoryNames: { [index: number]: string; };
}

export interface GetActiveServiceMessagesResponse extends BaseResponse
{
    messages: ServiceMessageLeanDto[];
}

export interface GetServiceMessagesResponse extends BaseResponse
{
    messages: ServiceMessageDto[];
}

export interface GetServiceMessageResponse extends BaseResponse
{
    message: ServiceMessageDto;
}

export interface SaveServiceMessageResponse extends BaseResponse
{
}

export interface GetSiteExemptionsResponse extends BaseResponse
{
    exemptions: SiteExemptionDto[];
}

export interface CreateSiteExemptionResponse extends BaseResponse
{
    exemptionId: number;
}

export interface UploadSiteFileResponse extends BaseResponse
{
    fileId: string;
}

export interface EvaluateSitePermissionFilterResponse extends BaseResponse
{
    sitePermissions: EvaluatedSitePermissionDto[];
}

export interface GetUserSitePermissionFilterResponse extends BaseResponse
{
    filter: SitePermissionFilterDto[];
}

export interface GetSitePermissionsResponse extends BaseResponse
{
    sitePermissions: SitePermissionDto[];
}

export interface GetUserSitePermissionsResponse extends BaseResponse
{
    sitePermissions: UserSitePermissionDto[];
}

export interface SaveUserSitePermissionsResponse extends BaseResponse
{
}

export interface GetSitesResponse extends BaseResponse
{
    moduleId: number;
    moduleSiteDefinition1?: string;
    moduleSiteDefinition2?: string;
    moduleSiteDefinition3?: string;
    moduleSiteDefinition4?: string;
    sites: SiteLeanDto[];
}

export interface GetSiteResponse extends BaseResponse
{
    site: SiteDto;
}

export interface CreateSiteResponse extends BaseResponse
{
    siteId: number;
}

export interface UpdateSiteResponse extends BaseResponse
{
}

export interface SaveStandardRecImagesResponse extends BaseResponse
{
}

export interface GetStandardRecommendationResponse extends BaseResponse
{
    standardRecommendation: StandardRecommendationDto;
}

export interface SearchStandardRecommendationsResponse extends BaseResponse
{
    standardRecommendations: StandardRecommendationLeanDto[];
}

export interface CopyToStandardRecommendationResponse extends BaseResponse
{
    standardRecommendationId: number;
}

export interface CreateStandardRecommendationResponse extends BaseResponse
{
    standardRecommendationId: number;
}

export interface DeleteStandardRecommendationResponse extends BaseResponse
{
}

export interface UpdateStandardRecommendationResponse extends BaseResponse
{
}

export interface GetSurveyEmailTemplateResponse extends BaseResponse
{
    subject: string;
    body: string;
}

export interface SendPreSurveyEmailResponse extends BaseResponse
{
}

export interface SendSurveyReportMailResponse extends BaseResponse
{
}

export interface GetSurveyReportMailHistoryResponse extends BaseResponse
{
    history: ReportMailDto[];
}

export interface UploadSurveyFileResponse extends BaseResponse
{
}

export interface GetSurveyFilesResponse extends BaseResponse
{
    files: SurveyReportFileDto[];
}

export interface CreateSurveyResponse extends BaseResponse
{
    surveyId: number;
}

export interface UpdateSurveySettingsResponse extends BaseResponse
{
}

export interface GetSurveysResponse extends BaseResponse
{
    surveys: SurveyLeanDto[];
}

export interface GetLastPublishedSurveyResponse extends BaseResponse
{
    survey: SurveyDto;
}

export interface GetSurveyResponse extends BaseResponse
{
    survey: SurveyDto;
}

export interface GetLoginAttemptLogResponse extends BaseResponse
{
    log: LoginAttemptDto[];
}

export interface GetErrorLogResponse extends BaseResponse
{
    errorLog: ErrorLogEntryDto[];
}

export interface GetActivityLogResponse extends BaseResponse
{
    activityLog: ActivityLogEntryDto[];
}

export interface GetUserResponse extends BaseResponse
{
    user?: UserDto;
}

export interface GetUsersResponse extends BaseResponse
{
    users: UserLeanDto[];
}

export interface CreateUserResponse extends BaseResponse
{
    userId: number;
}

export interface UpdateUserResponse extends BaseResponse
{
}

export interface UpdateUserPasswordResponse extends BaseResponse
{
}

export interface ArchiveUserResponse extends BaseResponse
{
    userId: number;
}

// @DataContract
export interface GetApiKeysResponse
{
    // @DataMember(Order=1)
    results: UserApiKey[];

    // @DataMember(Order=2)
    meta: { [index: string]: string; };

    // @DataMember(Order=3)
    responseStatus: ResponseStatus;
}

// @Route("/users/{UserId}/apikeys", "POST")
export interface RegenerateApiKeysForUser extends IReturn<RegenerateApiKeysResponse>
{
    userId: number;
}

// @Route("/v1/application-settings", "GET")
export interface GetApplicationSettings extends IReturn<GetApplicationSettingsResponse>
{
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}/images", "GET")
export interface GetBlueRatingRecommendationImage extends SurveyPermission, IReturn<Blob>
{
    recommendationId: number;
    fileId: string;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}/files", "POST")
export interface UploadBlueRatingRecommendationImages extends SurveyPermission, IReturn<UploadSurveyRecommendationImageResponse>, IUploadRecommendationImages
{
    recommendationId: number;
    images: RecommendationImageDto[];
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}/images/{FileId}", "DELETE")
export interface DeleteSurveyRecommendationImage extends SurveyPermission, IReturnVoid
{
    recommendationId: number;
    fileId: string;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}/images/{FileId}/caption", "PUT")
export interface UpdateBlueRatingRecommendationImageCaption extends SurveyPermission, IReturn<UpdateSurveyRecommendationImageCaptionResponse>
{
    recommendationId: number;
    fileId: string;
    caption: string;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}/images/{FileId}/position/{Position}", "PUT")
export interface UpdateRecImagePosition extends SurveyPermission, IReturnVoid
{
    recommendationId: number;
    fileId: string;
    position: number;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/search", "POST")
export interface SearchBlueRatingRecommendations extends SurveyPermission, IReturn<SearchBlueRatingRecommendationsResponse>
{
    filter?: RecommendationFilter;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}", "GET")
export interface GetBlueRatingRecommendation extends SurveyPermission, IReturn<GetBlueRatingRecommendationResponse>
{
    recommendationId: number;
}

// @Route("/v1/surveys/{SurveyId}/recommendations", "POST")
export interface CreateBlueRatingRecommendation extends SurveyPermission, IReturn<CreateBlueRatingRecommendationResponse>
{
    categoryId?: number;
    questionIds: number[];
    header: string;
    location: string;
    priority: string;
    categoryColorOverride: BlueColor;
    surveyorStatus: SurveyorStatus;
    abeyanceDate?: string;
    abeyanceComment: string;
    completedDate?: string;
    completedComment: string;
    text: string;
    comment: string;
    type: string;
    rcbaDescription: string;
    rcbaProbability: string;
    rcbaPropertyPrior?: number;
    rcbaPropertyAfter?: number;
    rcbaBiPrior?: number;
    rcbaBiAfter?: number;
    rcbaExtraPrior?: number;
    rcbaExtraAfter?: number;
    rcbaCostToComplete?: number;
    isGeneralRecommendation: boolean;
    isKeyRecommendation: boolean;
    rcbaLossEstimateType: LossEstimateType;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}", "PUT")
export interface UpdateBlueRatingRecommendation extends SurveyPermission, IReturn<UpdateBlueRatingRecommendationResponse>
{
    recommendationId: number;
    categoryId?: number;
    questionIds: number[];
    header: string;
    location: string;
    priority: string;
    categoryColorOverride: BlueColor;
    surveyorStatus: SurveyorStatus;
    abeyanceDate?: string;
    abeyanceComment: string;
    completedDate?: string;
    completedComment: string;
    text: string;
    comment: string;
    type: string;
    rcbaDescription: string;
    rcbaProbability: string;
    rcbaPropertyPrior?: number;
    rcbaPropertyAfter?: number;
    rcbaBiPrior?: number;
    rcbaBiAfter?: number;
    rcbaExtraPrior?: number;
    rcbaExtraAfter?: number;
    rcbaCostToComplete?: number;
    isGeneralRecommendation: boolean;
    isKeyRecommendation: boolean;
    rcbaCurrency: string;
    rcbaLossEstimateType: LossEstimateType;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}", "DELETE")
export interface DeleteBlueRatingRecommendation extends SurveyPermission, IReturnVoid
{
    recommendationId: number;
}

// @Route("/v1/surveys/{SurveyId}/recommendations/{RecommendationId}/apply-standard-recommendation/{StandardRecommendationId}", "PUT")
export interface ApplyStandardRecommendation extends SurveyPermission, IReturnVoid
{
    recommendationId: number;
    standardRecommendationId: number;
}

// @Route("/v1/surveys/{SurveyId}/blue-rating", "GET")
export interface GetBlueRating extends SurveyPermission, IReturn<GetBlueRatingResponse>
{
}

// @Route("/v1/surveys/{SurveyId}/reply/{QuestionId}", "PUT")
export interface SaveReply extends SurveyPermission, IReturnVoid
{
    questionId: number;
    value: BlueColor;
}

// @Route("/v1/modules/{ModuleId}/blue-ratings", "GET")
export interface GetBlueRatings extends ReadModulePermission, IReturn<GetBlueRatingsResponse>
{
    siteIds?: number[];
}

// @Route("/v1/surveys/{SurveyId}/notes/{CategoryId}", "PUT")
export interface SaveCategoryNotes extends SurveyPermission, IReturn<SaveCategoryNotesResponse>
{
    categoryId: number;
    notes: string;
}

// @Route("/v1/surveys/{SurveyId}/category-color/{CategoryId}", "GET")
export interface CalculateCategoryColor extends SurveyPermission, IReturn<CalculateCategoryColorResponse>
{
    categoryId: number;
}

// @Route("/v1/clients/{ClientId}", "GET")
export interface GetClient extends ReadClientPermission, IReturn<GetClientResponse>
{
}

// @Route("/v1/clients", "GET")
export interface GetClients extends IReturn<GetClientsResponse>
{
}

// @Route("/v1/clients", "POST")
export interface CreateClient extends CreateClientPermission, IReturn<CreateClientResponse>
{
    name: string;
    status: Status;
    willisCountryOrUnit: string;
    willisOffice: string;
    website: string;
}

// @Route("/v1/clients/{ClientId}", "PUT")
export interface UpdateClient extends EditClientPermission, IReturnVoid
{
    name?: string;
    willisCountryOrUnit?: string;
    willisOffice?: string;
    website?: string;
    status?: Status;
}

// @Route("/v1/csp-report", "POST")
// @DataContract
export interface CreateCspReport extends IReturn<CreateCspReportResponse>
{
    // @DataMember(Name="csp-report")
    csp_report: CspReportDto;
}

// @Route("/v1/dashboard/{ModuleId}/average-score", "GET")
export interface GetDashboardAverageScore extends ReadModulePermission, IReturn<GetDashboardAverageScoreResponse>
{
}

// @Route("/v1/dashboard/{ModuleId}/ratio", "GET")
export interface GetDashboardRecResponseRatio extends ReadModulePermission, IReturn<GetDashboardRecResponseRatioResponse>
{
}

// @Route("/v1/dashboard/{ModuleId}/survey-history", "GET")
export interface GetDashboardSurveyHistory extends ReadModulePermission, IReturn<GetDashboardSurveyHistoryResponse>
{
}

// @Route("/v1/modules/{ModuleId}/data-analysis/blue-trend", "POST")
export interface SearchBlueTrend extends ReadModulePermission, IReturn<SearchBlueTrendResponse>, IDataAnalysisFilter
{
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    groupBySiteDefinition?: number;
    excludedSiteDefinitions: SiteDefinitionFilter;
}

// @Route("/v1/modules/{ModuleId}/data-analysis/blue-loss-values", "POST")
export interface SearchBlueLossValues extends ReadModulePermission, IReturn<SearchBlueLossValuesResponse>, IDataAnalysisFilter
{
    lossScenarioIds: number[];
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    excludedSiteDefinitions: SiteDefinitionFilter;
    groupBySiteDefinition?: number;
}

// @Route("/v1/modules/{ModuleId}/data-analysis/blue-trend/excel", "POST")
export interface SearchBlueTrendChartExcel extends ReadModulePermission, IReturn<Blob>, IDataAnalysisFilter
{
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    groupBySiteDefinition?: number;
    excludedSiteDefinitions: SiteDefinitionFilter;
    autoScaleYAxis: boolean;
    percentageOnYAxis: boolean;
}

// @Route("/v1/modules/{ModuleId}/data-analysis/excel", "POST")
export interface SearchDataAnalysisRawDataExcel extends ReadModulePermission, IReturn<Blob>, IDataAnalysisFilter
{
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    excludedSiteDefinitions: SiteDefinitionFilter;
    groupBySiteDefinition?: number;
    surveyDepth?: SurveyDepth;
}

// @Route("/v1/modules/{ModuleId}/data-analysis/blue-loss-values/excel/{LossScenarioId}", "POST")
export interface SearchBlueLossValuesChartExcel extends ReadModulePermission, IReturn<Blob>, IDataAnalysisFilter
{
    lossScenarioId: number;
    surveyDateMin?: string;
    surveyDateMax?: string;
    surveyType?: SurveyType;
    excludedSiteDefinitions: SiteDefinitionFilter;
    groupBySiteDefinition?: number;
    autoScaleYAxis: boolean;
    percentageOnYAxis: boolean;
}

// @Route("/v1/modules/{ModuleId}/executive-summary", "POST")
export interface SearchExecutiveSummary extends BaseExecSummaryRequest, IReturn<SearchExecutiveSummaryResponse>
{
}

// @Route("/v1/sites/{SiteId}/blue-rating-summary", "GET")
export interface GetBlueRatingSummary extends ReadSitePermission, IReturn<GetBlueRatingSummaryResponse>
{
}

// @Route("/v1/sites/{SiteId}/blue-rating-summary/excel", "GET")
export interface GetBlueRatingSummaryExcel extends ReadSitePermission, IReturn<Blob>
{
    showScore?: boolean;
    showScorePercent?: boolean;
    type?: SurveyType;
}

// @Route("/v1/modules/{ModuleId}/reports/executive-summary/excel", "POST")
export interface SearchExecSummaryExcel extends BaseExecSummaryExcelRequest, IReturn<Blob>
{
    visibleLossScenarioIds: number[];
}

// @Route("/v1/modules/{ModuleId}/reports/executive-summary/excel/{CategoryId}", "POST")
export interface SearchExecSummaryCategoryExcel extends BaseExecSummaryExcelRequest, IReturn<Blob>
{
    categoryId: number;
}

// @Route("/v1/recommendation-response/auth/has-session", "GET")
export interface HasSession extends IReturnVoid
{
}

// @Route("/v1/recommendation-response/auth/mfa-options/{LinkId}", "GET")
export interface GetMfaOptions extends IReturn<GetMfaOptionsResponse>
{
    linkId: string;
}

// @Route("/v1/recommendation-response/auth/request-mfa", "POST")
export interface RequestMfa extends IReturn<RequestMfaResponse>
{
    linkId: string;
    mfaOption: MfaMethod;
}

// @Route("/v1/recommendation-response/auth/verify-mfa", "POST")
export interface VerifyMfa extends IReturnVoid
{
    code: string;
}

// @Route("/v1/recommendation-response/auth/logout", "POST")
export interface Logout extends IReturnVoid
{
}

// @Route("/v1/recommendation-response/response/{RecommendationResponseId}", "GET")
export interface GetRecResponse extends IReturn<GetRecResponseResponse>
{
    recommendationResponseId: number;
}

// @Route("/v1/recommendation-response/summary", "GET")
export interface GetRecResponseSummary extends IReturn<GetRecResponseSummaryResponse>
{
}

// @Route("/v1/recommendation-response/files/{FileId}", "GET")
export interface GetReportFile extends IReturn<Blob>
{
    fileId: string;
}

// @Route("/v1/recommendation-response/recommendations/images/{FileId}", "GET")
export interface GetRecImage extends IReturn<Blob>
{
    fileId: string;
}

// @Route("/v1/recommendation-response/survey", "GET")
export interface GetRecResponseSurvey extends IReturn<GetRecResponseSurveyResponse>
{
}

// @Route("/v1/recommendation-response/response", "POST")
export interface CreateRecResponse extends IReturn<CreateRecResponseResponse>
{
    response: CreateRecommendationResponseDto;
}

// @Route("/v1/recommendation-response/response", "PUT")
export interface UpdateRecResponse extends IReturnVoid
{
    response: RecommendationResponseDto;
}

// @Route("/v1/recommendation-response/response/{RecommendationResponseId}/files/{FileId}", "DELETE")
export interface DeleteRecResponseFile extends IReturnVoid
{
    recommendationResponseId: number;
    fileId: string;
}

// @Route("/v1/recommendation-response/response/{RecommendationResponseId}/files/{FileId}", "GET")
export interface GetRecResponseFile extends IReturn<Blob>
{
    recommendationResponseId: number;
    fileId: string;
}

// @Route("/v1/recommendation-response/response/{RecommendationResponseId}/files", "POST")
export interface UploadRecResponseFile extends IReturnVoid
{
    recommendationResponseId: number;
    file: FileDto;
}

// @Route("/v1/recommendation-response/submit-responses", "POST")
export interface SubmitRecommendationResponses extends IReturn<SubmitRecommendationResponsesResponse>
{
}

// @Route("/v1/recommendation-response/reports/recommendation-tracking/excel/site-response", "GET")
export interface GetRecTrackingChartSiteResponseExcel extends IReturn<Blob>
{
}

// @Route("/v1/recommendation-response/reports/recommendation-tracking/excel/site-response-blue-rating", "GET")
export interface GetRecTrackingChartSiteResponseBlueRatingExcel extends IReturn<Blob>
{
}

// @Route("/v1/recommendation-response/reports/recommendation-tracking/excel", "GET")
export interface GetRecTrackingExcel extends IReturn<Blob>
{
}

// @Route("/v1/recommendation-response/request-mail-resend", "POST")
export interface RequestSurveyReportMail extends IReturnVoid
{
    email: string;
}

// @Route("/v1/admin/impersonate/{UserId}", "POST")
export interface ImpersonateUser extends ImpersonatePermission, IReturn<ImpersonateUserResponse>
{
    userId: number;
}

// @Route("/v1/admin/impersonate/logout")
export interface EndImpersonation extends ImpersonatePermission, IReturn<EndImpersonationResponse>
{
}

// @Route("/v1/modules/{ModuleId}/library-files", "GET")
export interface GetLibraryFiles extends ReadModulePermission, IReturn<GetLibraryFilesResponse>
{
    filterByFileExtensions?: string[];
    onlyVisibleInUserWeb?: boolean;
}

// @Route("/v1/modules/{ModuleId}/library-files/{FileId}", "GET")
export interface GetLibraryFile extends ReadModulePermission, IReturn<Blob>
{
    fileId: string;
}

// @Route("/v1/modules/{ModuleId}/library-files", "POST")
export interface CreateLibraryFile extends ReadModulePermission, IReturnVoid
{
    file: FileDto;
    isVisibleInUserWeb: boolean;
    language: Language;
    tags?: string[];
}

// @Route("/v1/modules/{ModuleId}/library-files/{FileId}", "PUT")
export interface UpdateLibraryFile extends ReadModulePermission, IReturnVoid
{
    fileId: string;
    isVisibleInUserWeb: boolean;
    language: Language;
    tags?: string[];
}

// @Route("/v1/modules/{ModuleId}/library-files/{FileId}", "DELETE")
export interface DeleteLibraryFile extends ReadModulePermission, IReturnVoid
{
    fileId: string;
}

// @Route("/v1/login/is-authenticated", "GET")
export interface IsAuthenticated extends IReturn<IsAuthenticatedResponse>
{
}

// @Route("/v1/login/accept-terms", "PUT")
export interface AcceptTermsOfService extends IReturnVoid
{
}

// @Route("/v1/login/sso-settings", "GET")
export interface GetSsoSettings extends IReturn<GetSsoSettingsResponse>
{
}

// @Route("/v1/meta-data/industries", "GET")
export interface GetIndustries extends IReturn<GetIndustriesResponse>
{
}

// @Route("/v1/meta-data/risk-types", "GET")
export interface GetRiskTypes extends IReturn<GetRiskTypesResponse>
{
}

// @Route("/v1/modules/{ModuleId}/module-permissions", "GET")
export interface GetModulePermissions extends ReadModulePermission, IReturn<GetModulePermissionsResponse>
{
    onlyActive?: boolean;
}

// @Route("/v1/modules/{ModuleId}/module-permissions", "POST")
export interface CreateModulePermissions extends IReturn<CreateModulePermissionsResponse>
{
    modulePermissions: SaveModulePermissionDto[];
    moduleId: number;
}

// @Route("/v1/modules/{ModuleId}/module-permissions", "PUT")
export interface UpdateModulePermissions extends IReturn<UpdateModulePermissionsResponse>
{
    modulePermissions: SaveModulePermissionDto[];
    moduleId: number;
}

// @Route("/v1/modules/{ModuleId}/module-permissions", "DELETE")
export interface DeleteModulePermissions extends DeleteModulePermissionsPermission, IReturn<DeleteModulePermissionsResponse>
{
    moduleId: number;
}

// @Route("/v1/users/{UserId}/module-permissions", "GET")
export interface GetUserModulePermissions extends ReadFullUserPermission, IReturn<GetUserModulePermissionsResponse>
{
}

// @Route("/v1/users/{UserId}/module-permissions", "PUT")
export interface SaveUserModulePermissions extends IReturn<SaveUserModulePermissionsResponse>
{
    modulePermissions: SaveModulePermissionDto[];
    userId: number;
}

// @Route("/v1/modules", "GET")
export interface GetModules extends IReturn<GetModulesResponse>
{
    clientId?: number;
    includeOnlyPublished?: boolean;
}

// @Route("/v1/modules/{ModuleId}", "GET")
export interface GetModule extends ReadModulePermission, IReturn<GetModuleResponse>
{
}

// @Route("/v1/modules/{ModuleId}/effective-site-definitions", "GET")
export interface GetEffectiveSiteDefinitions extends ReadModulePermission, IReturn<GetEffectiveSiteDefinitionsResponse>
{
}

// @Route("/v1/modules/{ModuleId}/site-definition-suggestions", "GET")
export interface GetSiteDefinitionSuggestions extends ReadModulePermission, IReturn<GetSiteDefinitionSuggestionsResponse>
{
}

// @Route("/v1/modules/{ModuleId}/sites/export", "GET")
export interface ExportSites extends ReadModulePermission, IReturn<Blob>
{
}

// @Route("/v1/modules/{ModuleId}/sites/import-template", "GET")
export interface GetSiteImportTemplate extends ReadModulePermission, IReturn<Blob>
{
}

// @Route("/v1/clients/{ClientId}/modules", "POST")
export interface CreateModule extends EditClientPermission, IReturn<CreateModuleResponse>
{
}

// @Route("/v1/modules/{ModuleId}", "PUT")
export interface UpdateModule extends EditModulePermission, IReturnVoid
{
    contactId?: number;
    language: Language;
    reportMailLoginType: ReportMailLoginType;
    recSortOrder: RecSortOrder;
    status: Status;
    userWebLoginType: MfaOption;
    allowSpoeCheckByExt: boolean;
    hasExecSumIncludeSiteSummary: boolean;
    hasSiteResponseBlueRating: boolean;
    requireSpoeCheck: boolean;
    currencyCode: string;
    address: string;
    city: string;
    country: string;
    name: string;
    phone: string;
    poBox: string;
    siteDefinition1: string;
    siteDefinition2: string;
    siteDefinition3: string;
    siteDefinition4: string;
    forceSuggestion1: boolean;
    forceSuggestion2: boolean;
    forceSuggestion3: boolean;
    forceSuggestion4: boolean;
    suggestions1: string[];
    suggestions2: string[];
    suggestions3: string[];
    suggestions4: string[];
    recommendationTypes: string[];
    textForBlue: string;
    textForGreen: string;
    textForYellow: string;
    textForRed: string;
    userWebText: string;
    web: string;
    willisCountryOrUnit: string;
    willisOffice: string;
    accountEngineerId: number;
    zipCode: string;
    preSurveyMailSubject: string;
    preSurveyMailBody: string;
    reportMailSubject: string;
    reportMailBody: string;
    showAForAbeyance: boolean;
    forceOverrideCategoryColor: boolean;
    forceName: boolean;
    forceLocation: boolean;
    forcePriority: boolean;
    forceType: boolean;
    showCForAllCompletedOnCategory: boolean;
    abeyanceTranslation: string;
    abeyanceShort: string;
    locationTranslation: string;
    nameTranslation: string;
    priorityTranslation: string;
    autoNotifyStakeholders: boolean;
    lossScenarios: LossScenarioDto[];
}

// @Route("/v1/modules/{ModuleId}/sites/import", "POST")
export interface ImportSites extends CreateSitePermission
{
}

// @Route("/v1/modules/{ModuleId}/settings", "GET")
export interface GetModuleSettings extends ReadModulePermission, IReturn<GetModuleSettingsResponse>
{
}

// @Route("/v1/auth/forgot-password", "POST")
export interface SendResetPasswordLink extends IReturn<SendResetPasswordLinkResponse>
{
    email: string;
}

// @Route("/v1/auth/reset-password", "POST")
export interface ResetPassword extends IReturn<ResetPasswordResponse>
{
    email: string;
    token: string;
    password: string;
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft", "GET")
export interface GetQuestionnaireDraft extends EditQuestionnairePermission, IReturn<GetQuestionnaireDraftResponse>
{
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft", "POST")
export interface CreateQuestionnaireDraft extends EditQuestionnairePermission, IReturn<CreateQuestionnaireDraftResponse>
{
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft", "PUT")
export interface UpdateQuestionnaireDraft extends EditQuestionnairePermission, IReturn<UpdateQuestionnaireDraftResponse>
{
    actions: QuestionnaireDraftActionDto[];
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/history/{ActionId}/revert", "DELETE")
export interface RevertQuestionnaireDraft extends EditQuestionnairePermission, IReturn<RevertQuestionnaireDraftResponse>
{
    actionId: number;
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/history", "GET")
export interface GetQuestionnaireDraftHistory extends EditQuestionnairePermission, IReturn<GetQuestionnaireDraftHistoryResponse>
{
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/history/{TargetActionId}/{Type}/{Property}", "GET")
export interface GetQuestionnaireDraftPropertyHistory extends EditQuestionnairePermission, IReturn<GetQuestionnaireDraftPropertyHistoryResponse>
{
    targetActionId: number;
    type: QuestionnaireDraftActionType;
    property: string;
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/publish", "POST")
export interface PublishQuestionnaireDraft extends EditQuestionnairePermission, IReturn<PublishQuestionnaireDraftResponse>
{
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft", "DELETE")
export interface DeleteQuestionnaireDraft extends EditQuestionnairePermission, IReturnVoid
{
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/insert", "POST")
export interface InsertQuestionnaireDraftAreas extends EditQuestionnairePermission, IReturn<InsertQuestionnaireDraftAreasResponse>
{
    from: FromArea;
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/area/{ToAreaId}/insert", "POST")
export interface InsertQuestionnaireDraftCategories extends EditQuestionnairePermission, IReturn<InsertQuestionnaireDraftCategoriesResponse>
{
    toAreaId: number;
    from: FromCategory;
}

// @Route("/v1/questionnaires/{QuestionnaireId}/draft/category/{ToCategoryId}/insert", "POST")
export interface InsertQuestionnaireDraftQuestions extends EditQuestionnairePermission, IReturn<InsertQuestionnaireDraftQuestionsResponse>
{
    toCategoryId: number;
    from: FromQuestion;
}

// @Route("/v1/modules/{ModuleId}/questionnaires/current", "GET")
export interface GetCurrentQuestionnaire extends ReadModulePermission, IReturn<GetCurrentQuestionnaireResponse>
{
}

// @Route("/v1/questionnaires/{QuestionnaireId}", "GET")
export interface GetQuestionnaire extends ReadQuestionnairePermission, IReturn<GetQuestionnaireResponse>
{
}

// @Route("/v1/modules/{ModuleId}/questionnaires/{QuestionnaireId}/unpublish", "PUT")
export interface UnpublishQuestionnaire extends EditQuestionnairePermission, IReturn<UnpublishQuestionnaireResponse>
{
    moduleId: number;
}

// @Route("/v1/questionnaires", "GET")
export interface GetQuestionnaires extends IReturn<GetQuestionnairesResponse>
{
    includeTemplates?: boolean;
}

// @Route("/v1/questionnaires/templates", "POST")
export interface CreateQuestionnaireTemplate extends EditQuestionnaireTemplatePermission, IReturn<CreateQuestionnaireTemplateResponse>
{
}

// @Route("/v1/questionnaires/templates", "GET")
export interface GetQuestionnaireTemplates extends ReadQuestionnaireTemplatesPermission, IReturn<GetQuestionnaireTemplatesResponse>
{
}

// @Route("/v1/activities/recent", "GET")
export interface GetRecentActivity extends IReturn<GetRecentActivityResponse>
{
    count?: number;
}

// @Route("/v1/surveys/{SurveyId}/recommendation-report", "GET")
export interface GetRecommendationReport extends SurveyPermission, IReturn<Blob>
{
}

// @Route("/v1/modules/{ModuleId}/recommendation-responses/{RecommendationResponseId}", "GET")
export interface GetRecommendationResponse extends ReadRecommendationResponsePermission, IReturn<GetRecommendationResponseResponse>
{
}

// @Route("/v1/modules/{ModuleId}/recommendation-responses/{RecommendationResponseId}", "PUT")
export interface UpdateRecommendationResponse extends EditRecommendationResponsePermission, IReturnVoid
{
    recommendationId: number;
    status: SiteResponseStatus;
    comment?: string;
    dueDate?: string;
    costToComplete?: number;
    responsiblePerson?: string;
}

// @Route("/v1/modules/{ModuleId}/recommendation-responses/{RecommendationResponseId}", "DELETE")
export interface DeleteRecommendationResponse extends EditRecommendationResponsePermission, IReturnVoid
{
}

// @Route("/v1/modules/{ModuleId}/recommendation-responses/{RecommendationResponseId}/files/{FileId}", "DELETE")
export interface DeleteRecommendationResponseFile extends EditRecommendationResponsePermission, IReturnVoid
{
    fileId: string;
}

// @Route("/v1/modules/{ModuleId}/recommendation-responses/{RecommendationResponseId}/files/{FileId}", "GET")
export interface GetRecommendationResponseFile extends ReadRecommendationResponsePermission, IReturn<Blob>
{
    fileId: string;
}

// @Route("/v1/modules/{ModuleId}/recommendation-responses/{RecommendationResponseId}/files", "POST")
export interface UploadRecommendationResponseFile extends EditRecommendationResponsePermission, IReturnVoid
{
    file: FileDto;
}

// @Route("/v1/modules/{ModuleId}/rec-report-settings", "GET")
export interface GetAllRecReportSettings extends EditModulePermission, IReturn<GetAllRecReportSettingsResponse>
{
}

// @Route("/v1/rec-report-settings", "GET")
// @Route("/v1/modules/{ModuleId}/rec-report-settings/{SettingId}", "GET")
export interface GetRecReportSettings extends EditRecReportSettingsPermission, IReturn<GetRecReportSettingsResponse>
{
}

// @Route("/v1/modules/{ModuleId}/rec-report-settings", "POST")
export interface CreateRecReportSettings extends EditModulePermission, IReturn<CreateRecReportSettingsResponse>
{
    scope: RecReportSettingsScope;
}

// @Route("/v1/rec-report-settings/{SettingId}", "PUT")
// @Route("/v1/modules/{ModuleId}/rec-report-settings/{SettingId}", "PUT")
export interface UpdateRecReportSettings extends EditRecReportSettingsPermission, IReturn<UpdateRecReportSettingsResponse>
{
    settings: RecReportSettingsDto;
}

// @Route("/v1/modules/{ModuleId}/rec-report-settings/{SettingId}", "DELETE")
export interface DeleteRecReportSettings extends EditRecReportSettingsPermission, IReturn<DeleteRecReportSettingsResponse>
{
}

// @Route("/v1/modules/{ModuleId}/reports/recommendation-tracking", "POST")
// @Route("/v1/modules/{ModuleId}/reports/recommendation-tracking", "POST")
export interface SearchRecTracking extends BaseRecTrackingRequest, IReturn<SearchRecTrackingResponse>
{
}

// @Route("/v1/modules/{ModuleId}/reports/recommendation-tracking/excel", "POST")
// @Route("/v1/modules/{ModuleId}/reports/recommendation-tracking", "POST")
export interface SearchRecTrackingExcel extends BaseRecTrackingRequest, IReturn<Blob>
{
}

// @Route("/v1/modules/{ModuleId}/reports/recommendation-tracking/excel/{Chart}", "POST")
// @Route("/v1/modules/{ModuleId}/reports/recommendation-tracking", "POST")
export interface SearchRecTrackingChartExcel extends BaseRecTrackingRequest, IReturn<Blob>
{
    chart: RecTrackingChart;
}

// @Route("/v1/system/scheduled-tasks/survey-report-email/send", "PUT")
export interface SendSurveyReportEmailReminders extends IReturnVoid
{
    key: string;
}

// @Route("/v1/system/scheduled-tasks/login-notifications/send", "PUT")
export interface SendLoginNotifications extends IReturnVoid
{
    key: string;
}

// @Route("/v1/service-messages/active", "GET")
export interface GetActiveServiceMessages extends IReturn<GetActiveServiceMessagesResponse>
{
}

// @Route("/v1/service-messages", "GET")
export interface GetServiceMessages extends IReturn<GetServiceMessagesResponse>
{
}

// @Route("/v1/service-messages/{Id}", "GET")
export interface GetServiceMessage extends IReturn<GetServiceMessageResponse>
{
    id: number;
}

// @Route("/v1/service-messages", "PUT")
export interface SaveServiceMessage extends IReturn<SaveServiceMessageResponse>
{
    id?: number;
    active: boolean;
    subject: string;
    message: string;
    severity: Severity;
}

// @Route("/v1/sites/{SiteId}/exemptions")
export interface GetSiteExemptions extends ReadSitePermission, IReturn<GetSiteExemptionsResponse>
{
}

// @Route("/v1/sites/{SiteId}/exemptions", "POST")
export interface CreateSiteExemption extends ReadSitePermission, IReturn<CreateSiteExemptionResponse>
{
    number: string;
    location: string;
    comment: string;
    text: string;
    addedDate: string;
}

// @Route("/v1/sites/{SiteId}/exemptions/{ExemptionId}", "PUT")
export interface UpdateSiteExemption extends ReadSitePermission, IReturnVoid
{
    exemptionId: number;
    number: string;
    location: string;
    comment: string;
    text: string;
    addedDate: string;
    reviewedDate?: string;
}

// @Route("/v1/sites/{SiteId}/exemptions/{ExemptionId}", "DELETE")
export interface DeleteSiteExemption extends ReadSitePermission, IReturnVoid
{
    exemptionId: number;
}

// @Route("/v1/sites/{SiteId}/files/{FileId}", "GET")
export interface GetSiteFile extends ReadSitePermission, IReturn<Blob>
{
    fileId: string;
}

// @Route("/v1/sites/{SiteId}/files", "POST")
export interface UploadSiteFile extends EditSitePermission, IReturn<UploadSiteFileResponse>
{
    file: FileDto;
    type: SiteFileType;
}

// @Route("/v1/modules/{ModuleId}/evaluate-site-permission-filter", "POST")
export interface EvaluateSitePermissionFilter extends ReadModulePermission, IReturn<EvaluateSitePermissionFilterResponse>
{
    filter: SitePermissionFilterDto[];
}

// @Route("/v1/users/{UserId}/site-permission-filter/{ModuleId}", "GET")
export interface GetUserSitePermissionFilter extends EditUserPermission, IReturn<GetUserSitePermissionFilterResponse>
{
    moduleId: number;
}

// @Route("/v1/users/{UserId}/site-permission-filter/{ModuleId}", "PUT")
export interface SaveSitePermissionFilter extends EditUserPermission, IReturnVoid
{
    moduleId: number;
    filter: SitePermissionFilterDto[];
}

// @Route("/v1/users/{UserId}/site-permission-filter/{ModuleId}", "DELETE")
export interface DeleteSitePermissionFilter extends EditUserPermission, IReturnVoid
{
    moduleId: number;
}

// @Route("/v1/sites/{SiteId}/site-permissions", "GET")
export interface GetSitePermissions extends ReadSitePermission, IReturn<GetSitePermissionsResponse>
{
    userId?: number;
}

// @Route("/v1/users/{UserId}/site-permissions", "GET")
export interface GetUserSitePermissions extends EditUserPermission, IReturn<GetUserSitePermissionsResponse>
{
}

// @Route("/v1/modules/{ModuleId}/site-permissions/users/{UserId}", "POST")
export interface SaveUserSitePermissions extends EditUserPermission, IReturn<SaveUserSitePermissionsResponse>
{
    moduleId: number;
    explicitPermissions: number[];
}

// @Route("/v1/modules/{ModuleId}/sites", "GET")
export interface GetSites extends ReadModulePermission, IReturn<GetSitesResponse>
{
    includeOnlyPublished?: boolean;
    skip?: number;
    take?: number;
}

// @Route("/v1/sites/{SiteId}")
export interface GetSite extends ReadSitePermission, IReturn<GetSiteResponse>
{
}

// @Route("/v1/modules/{ModuleId}/sites")
export interface CreateSite extends CreateSitePermission, IReturn<CreateSiteResponse>
{
}

// @Route("/v1/modules/{ModuleId}/sites/{SiteId}", "PUT")
export interface UpdateSite extends EditSitePermission, IReturn<UpdateSiteResponse>
{
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    status: Status;
    surveyorId?: number;
    nextSurveyDate?: string;
    address: string;
    city: string;
    country: string;
    latitude?: number;
    longitude?: number;
    phone: string;
    poBox: string;
    zipCode: string;
    web: string;
}

// @Route("/v1/surveys/{SurveyId}/spoe-check", "PUT")
export interface SaveSpoeCheck extends SpoeCheckPermission, IReturnVoid
{
    isApproved: boolean;
    notifySurveyor: boolean;
    recommendationSpoeComments: { [index: number]: string; };
}

// @Route("/v1/surveys/{SurveyId}/riskmanagercomments", "PUT")
export interface SaveRiskManagerComments extends SurveyPermission, IReturnVoid
{
    publishRiskManagerComments: boolean;
    recommendationRiskManagerComments: { [index: number]: string; };
}

// @Route("/v1/standard-recommendations/{StandardRecommendationId}/images/{FileId}", "GET")
export interface GetStandardRecommendationImage extends ReadStandardRecommendationPermission, IReturn<Blob>
{
    fileId: string;
}

// @Route("/v1/standard-recommendations/{StandardRecommendationId}/images", "PUT")
export interface SaveStandardRecImages extends EditStandardRecImagesPermission, IReturn<SaveStandardRecImagesResponse>
{
    images: EditRecImageDto[];
}

// @Route("/v1/standard-recommendations/{StandardRecommendationId}", "GET")
export interface GetStandardRecommendation extends ReadStandardRecommendationPermission, IReturn<GetStandardRecommendationResponse>
{
}

// @Route("/v1/standard-recommendations/search", "POST")
export interface SearchStandardRecommendations extends IReturn<SearchStandardRecommendationsResponse>
{
    filter?: StandardRecommendationFilter;
}

// @Route("/v1/recommendations/{RecommendationId}/standard-recommendations", "POST")
export interface CopyToStandardRecommendation extends CreateStandardRecommendationPermission, IReturn<CopyToStandardRecommendationResponse>
{
    recommendationId: number;
}

// @Route("/v1/standard-recommendations", "POST")
export interface CreateStandardRecommendation extends CreateStandardRecommendationPermission, IReturn<CreateStandardRecommendationResponse>
{
    header: string;
    text: string;
    type: string;
    country: string;
    categories: string[];
    questions: string[];
}

// @Route("/v1/standard-recommendations/{StandardRecommendationId}", "DELETE")
export interface DeleteStandardRecommendation extends EditStandardRecommendationPermission, IReturn<DeleteStandardRecommendationResponse>
{
}

// @Route("/v1/standard-recommendations/{StandardRecommendationId}", "PUT")
export interface UpdateStandardRecommendation extends EditStandardRecommendationPermission, IReturn<UpdateStandardRecommendationResponse>
{
    header: string;
    text: string;
    type: string;
    country: string;
    categories: string[];
    questions: string[];
}

// @Route("/v1/sites/{SiteId}/email-templates/{Type}", "GET")
export interface GetSurveyEmailTemplate extends ReadSitePermission, IReturn<GetSurveyEmailTemplateResponse>
{
    type: SurveyEmail;
}

// @Route("/v1/surveys/{SurveyId}/pre-survey-mail", "POST")
export interface SendPreSurveyEmail extends SurveyPermission, IReturn<SendPreSurveyEmailResponse>
{
    subject: string;
    body: string;
    recipients: EmailAddressDto[];
    fileId?: string;
}

// @Route("/v1/surveys/{SurveyId}/send-report", "POST")
export interface SendSurveyReportMail extends SurveyPermission, IReturn<SendSurveyReportMailResponse>
{
    recipients: SurveyReportRecipientDto[];
    subject: string;
    body: string;
    includeLibraryFileIds: string[];
    codeExpirationHours: number;
}

// @Route("/v1/surveys/{SurveyId}/report-mails", "GET")
export interface GetSurveyReportMailHistory extends SurveyPermission, IReturn<GetSurveyReportMailHistoryResponse>
{
}

// @Route("/v1/surveys/{SurveyId}/report-mail/{ReportMailId}", "POST")
export interface UpdateSurveyReportMail extends SurveyPermission, IReturnVoid
{
    reportMailId: number;
    isActive: boolean;
}

// @Route("/v1/surveys/{SurveyId}/request-spoe-check", "POST")
export interface RequestSpoeCheck extends SurveyPermission, IReturnVoid
{
}

// @Route("/v1/surveys/{SurveyId}/files/{Type}", "POST")
export interface UploadSurveyFile extends SurveyPermission, IReturn<UploadSurveyFileResponse>
{
    file: FileDto;
    type: SurveyFileType;
}

// @Route("/v1/surveys/{SurveyId}/files/{FileId}", "DELETE")
export interface DeleteSurveyFile extends SurveyPermission, IReturnVoid
{
    fileId: string;
}

// @Route("/v1/surveys/{SurveyId}/files/{Id}", "GET")
export interface GetSurveyFile extends SurveyPermission, IReturn<Blob>
{
    id: string;
}

// @Route("/v1/surveys/{SurveyId}/files", "GET")
export interface GetSurveyFiles extends SurveyPermission, IReturn<GetSurveyFilesResponse>
{
}

// @Route("/v1/surveys", "POST")
export interface CreateSurvey extends ReadSitePermission, IReturn<CreateSurveyResponse>
{
    surveyorUserId?: number;
    spoeCheckerUserId?: number;
    surveyDate: string;
    participants?: SurveyParticipantDto[];
    recommendationResponseRecipients: number[];
    copyDataFromPreviousSurvey: boolean;
    insuredValues: SurveyInsuredValueDto[];
}

// @Route("/v1/surveys/{SurveyId}", "PUT")
export interface UpdateSurveySettings extends SurveyPermission, IReturn<UpdateSurveySettingsResponse>
{
    status?: Status;
    surveyorUserId?: number;
    spoeCheckerUserId?: number;
    surveyDate: string;
    participants?: SurveyParticipantDto[];
    recommendationResponseRecipients?: number[];
    insuredValues: SurveyInsuredValueDto[];
}

// @Route("/v1/sites/{SiteId}/surveys", "GET")
export interface GetSurveys extends ReadSitePermission, IReturn<GetSurveysResponse>
{
}

// @Route("/v1/sites/{SiteId}/surveys/last-published", "GET")
export interface GetLastPublishedSurvey extends ReadSitePermission, IReturn<GetLastPublishedSurveyResponse>
{
}

// @Route("/v1/surveys/{SurveyId}", "GET")
export interface GetSurvey extends SurveyPermission, IReturn<GetSurveyResponse>
{
}

// @Route("/v1/system/logs/login-attempts", "GET")
export interface GetLoginAttemptLog extends IReturn<GetLoginAttemptLogResponse>
{
}

// @Route("/v1/system/logs/errors", "GET")
export interface GetErrorLog extends IReturn<GetErrorLogResponse>
{
}

// @Route("/v1/system/logs/activities")
export interface GetActivityLog extends IReturn<GetActivityLogResponse>
{
}

// @Route("/v1/users/{UserId}", "GET")
export interface GetUser extends ReadFullUserPermission, IReturn<GetUserResponse>
{
}

// @Route("/v1/users", "GET")
export interface GetUsers extends UserAdministrationPermission, IReturn<GetUsersResponse>
{
    clientId?: number;
    moduleId?: number;
    siteId?: number;
    onlyActive?: boolean;
}

// @Route("/v1/users", "POST")
export interface CreateUser extends CreateUserPermission, IReturn<CreateUserResponse>
{
    language: Language;
    allowRmComment?: boolean;
    allowReportMail?: boolean;
    city: string;
    company: string;
    country: string;
    email: string;
    lastName: string;
    firstName: string;
    mobilePhoneNumber?: string;
    phoneNumber: string;
    title: string;
    userName?: string;
    allowUserWeb?: boolean;
    loginType: LoginType;
    isLockedOut: boolean;
    password?: string;
    mfa: MfaOption;
}

// @Route("/v1/users/{UserId}", "PUT")
export interface UpdateUser extends EditUserPermission, IReturn<UpdateUserResponse>
{
    language?: Language;
    role?: Role;
    allowRmComment?: boolean;
    allowReportMail?: boolean;
    city?: string;
    company?: string;
    country?: string;
    email?: string;
    lastName?: string;
    firstName?: string;
    mobilePhoneNumber?: string;
    phoneNumber?: string;
    title?: string;
    userName?: string;
    allowUserWeb?: boolean;
    loginType?: LoginType;
    isArchived?: boolean;
    isLockedOut?: boolean;
    mfa?: MfaOption;
}

// @Route("/v1/users/{UserId}/password", "PUT")
export interface UpdateUserPassword extends EditUserPermission, IReturn<UpdateUserPasswordResponse>
{
    password: string;
}

// @Route("/v1/users/{UserId}/archive", "PUT")
export interface ArchiveUser extends ArchiveUserPermission, IReturn<ArchiveUserResponse>
{
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export interface GetApiKeys extends IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    environment: string;

    // @DataMember(Order=2)
    meta: { [index: string]: string; };
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export interface RegenerateApiKeys extends IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    environment: string;

    // @DataMember(Order=2)
    meta: { [index: string]: string; };
}



/* Options:
Version: 8.40
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44335

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: Blue.Common.Enums/*
//ExcludeTypes: HttpResult,Authenticate,AssignRoles,AssignRolesResponse,UnAssignRoles,UnAssignRolesResponse,FallbackForClientRoutes
//DefaultImports: 
*/


export enum BlueColor
{
    NotSet = 'NotSet',
    Blue = 'Blue',
    Green = 'Green',
    Yellow = 'Yellow',
    Red = 'Red',
    Gray = 'Gray',
}

export enum SurveyorStatus
{
    NotSet = 'NotSet',
    NoProgress = 'NoProgress',
    UnderReview = 'UnderReview',
    Withdrawn = 'Withdrawn',
    Completed = 'Completed',
    Rejected = 'Rejected',
    InProgress = 'InProgress',
    Abeyance = 'Abeyance',
}

export enum LossEstimateType
{
    Monetary = 'Monetary',
    Percent = 'Percent',
}

export enum Status
{
    Published = 'Published',
    Complete = 'Complete',
    UnderConstruction = 'UnderConstruction',
    History = 'History',
}

export enum SurveyType
{
    W = 'W',
    EXT = 'EXT',
    WEXT = 'WEXT',
    S = 'S',
}

export enum SurveyDepth
{
    Area = 'Area',
    Category = 'Category',
    Question = 'Question',
}

export enum MfaMethod
{
    Email = 'Email',
    Sms = 'Sms',
}

export enum Language
{
    English = 'English',
    Swedish = 'Swedish',
    Norwegian = 'Norwegian',
    Danish = 'Danish',
    Dutch = 'Dutch',
    Russian = 'Russian',
}

export enum ReportMailLoginType
{
    SMSOnly = 'SMSOnly',
    EmailOrSMS = 'EmailOrSMS',
}

export enum RecSortOrder
{
    AgeAscending = 'AgeAscending',
    AgeDescending = 'AgeDescending',
    Grading = 'Grading',
    Questionnaire = 'Questionnaire',
}

export enum MfaOption
{
    None = 'None',
    Email = 'Email',
    Sms = 'Sms',
    EmailorSms = 'EmailorSms',
}

export enum QuestionnaireDraftActionType
{
    Questionnaire = 'Questionnaire',
    Area = 'Area',
    Category = 'Category',
    Question = 'Question',
}

export enum SiteResponseStatus
{
    NotSet = 'NotSet',
    NoProgress = 'NoProgress',
    UnderReview = 'UnderReview',
    Completed = 'Completed',
    Rejected = 'Rejected',
    InProgress = 'InProgress',
}

export enum RecReportSettingsScope
{
    Global = 'Global',
    External = 'External',
    Internal = 'Internal',
}

export enum RecTrackingChart
{
    SiteResponse = 'SiteResponse',
    SiteResponseByBlueRating = 'SiteResponseByBlueRating',
    SiteResponsePerCategory = 'SiteResponsePerCategory',
}

export enum Severity
{
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
}

export enum SiteFileType
{
    ReportImage = 'ReportImage',
}

export enum SurveyEmail
{
    PreSurvey = 'PreSurvey',
    RecommendationReport = 'RecommendationReport',
}

export enum SurveyFileType
{
    OtherReport = 'OtherReport',
    RecommendationReport = 'RecommendationReport',
    UwReport = 'UwReport',
}

export enum LoginType
{
    Blue = 'Blue',
    INT = 'INT',
    EXT = 'EXT',
}

export enum Role
{
    SystemOwner = 'SystemOwner',
    SA = 'SA',
    BA = 'BA',
    INT = 'INT',
    EXT = 'EXT',
}

export enum RotateDegrees
{
    None = 'None',
    Rotate90 = 'Rotate90',
    Rotate180 = 'Rotate180',
    Rotate270 = 'Rotate270',
}

export enum ExecReportSortOrder
{
    Alphabetical = 'Alphabetical',
    LossScenario = 'LossScenario',
    Score = 'Score',
    SurveyDate = 'SurveyDate',
}

export enum DraftAction
{
    Reference = 'Reference',
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
}

export enum ReportDownloadStatus
{
    None = 'None',
    Green = 'Green',
    Red = 'Red',
}

export enum LoginAttemptResult
{
    AccountDoesNotExist = 'AccountDoesNotExist',
    AccountLockedOut = 'AccountLockedOut',
    CodeDateExpired = 'CodeDateExpired',
    EmptyUsernameOrPassword = 'EmptyUsernameOrPassword',
    IncorrectCode = 'IncorrectCode',
    IncorrectIpAddress = 'IncorrectIpAddress',
    IncorrectPassword = 'IncorrectPassword',
    InvalidCredentials = 'InvalidCredentials',
    LinkExpired = 'LinkExpired',
    NoAccessToExternalLogin = 'NoAccessToExternalLogin',
    NoAccessToInternalLogin = 'NoAccessToInternalLogin',
    PasswordExpired = 'PasswordExpired',
    SuccessfulCodeRequest = 'SuccessfulCodeRequest',
    SuccessfulLogin = 'SuccessfulLogin',
    SuccessfulLoginButMultipleModules = 'SuccessfulLoginButMultipleModules',
    SuccessfulLoginCodeSentByEmail = 'SuccessfulLoginCodeSentByEmail',
    SuccessfulLoginCodeSentBySms = 'SuccessfulLoginCodeSentBySms',
    UnknownError = 'UnknownError',
    UserWebNotPublished = 'UserWebNotPublished',
    UserHasNoMobilePhoneNumber = 'UserHasNoMobilePhoneNumber',
}

export enum RecReportSectionType
{
    BlueRating = 'BlueRating',
    Cover = 'Cover',
    Custom = 'Custom',
    ImportantNotice = 'ImportantNotice',
    Recommendations = 'Recommendations',
}

export enum RecResponseRespondStatus
{
    NeedsResponse = 'NeedsResponse',
    NeedsSubmit = 'NeedsSubmit',
    Submitted = 'Submitted',
}

export enum CategoryGrading
{
    DefinedLimits = 'DefinedLimits',
    LowestGradedQuestion = 'LowestGradedQuestion',
}

