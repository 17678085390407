import { ref } from "vue";
import {
  createStandardRecommendation,
  saveStandardRecImagesService,
  updateStandardRecommendation,
} from "@/services/standardRecommendations";
import {
  CreateStandardRecommendation,
  EditRecImageDto,
  UpdateStandardRecommendation,
} from "@/types/_generated/api";
import { StandardRecForm } from "./useStandardRecommendationForm";

export const useSaveStandardRec = () => {
  const isSavingStandardRec = ref(false);
  const hasError = ref(false);

  const saveStandardRec = async (req: StandardRecForm, images: EditRecImageDto[]) => {
    isSavingStandardRec.value = true;

    let standardRecResult;

    const isUpdate = !!req.standardRecommendationId;

    if (isUpdate) {
      standardRecResult = await updateStandardRecommendation(req as UpdateStandardRecommendation);
    } else {
      standardRecResult = await createStandardRecommendation(req as CreateStandardRecommendation);
    }

    const standardRecommendationId =
      typeof standardRecResult === "number" ? standardRecResult : req.standardRecommendationId;

    const imagesResult = await saveStandardRecImagesService({
      standardRecommendationId,
      images,
    });

    if (standardRecResult === null || imagesResult === null) {
      hasError.value = true;
    }

    isSavingStandardRec.value = false;
  };

  return { saveStandardRec, isSavingStandardRec, hasError };
};
