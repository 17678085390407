<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CategoryDto, BlueColor } from "@/types/_generated/api";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import QuestionnaireDescriptionToggle from "./QuestionnaireDescriptionToggle.vue";
import QuestionnaireViewerEntityWrapper from "./QuestionnaireViewerEntityWrapper.vue";
import QuestionnaireViewerTotalScore from "./QuestionnaireViewerTotalScore.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  category: CategoryDto;
  areaPosition: number;
  hideQuestionGradingDescriptions: boolean;
}>();

defineEmits<{
  (event: "toggle-gradient-description"): void;
}>();

const totalPoints = computed(() => {
  return props.category.questions.reduce((total, question) => total + question.blueScore, 0);
});
</script>

<template>
  <QuestionnaireViewerEntityWrapper
    :id="`category_${category.categoryId}`"
    class="questionnaire__category"
    :title="category.name"
    :position="`${areaPosition}.${category.position}`"
  >
    <p>
      {{
        `${t("questionnaire.draft.labels.gradeBy")}: ${t(
          `questionnaire.category.categoryGradings.${category.gradeBy
            .substring(0, 1)
            .toLowerCase()}${category.gradeBy.substring(1)}`,
        )}`
      }}
    </p>

    <div v-if="category.gradeBy === 'DefinedLimits'" class="questionnaire__category__limits">
      <div class="questionnaire__category__limits__inner">
        <BaseBlueRatingStatusBadge :status="BlueColor.Blue" :size="'medium'">
          {{ category.blueLimit }}
        </BaseBlueRatingStatusBadge>
        <BaseBlueRatingStatusBadge :status="BlueColor.Green" :size="'medium'">
          {{ category.greenLimit }}
        </BaseBlueRatingStatusBadge>
        <BaseBlueRatingStatusBadge :status="BlueColor.Yellow" :size="'medium'">
          {{ category.yellowLimit }}
        </BaseBlueRatingStatusBadge>
      </div>

      <QuestionnaireViewerTotalScore :total-score="totalPoints" />
    </div>

    <template #controls>
      <div>
        <QuestionnaireDescriptionToggle
          :show-description="!hideQuestionGradingDescriptions"
          @toggle="$emit('toggle-gradient-description')"
        />
      </div>
    </template>
  </QuestionnaireViewerEntityWrapper>
</template>

<style scoped lang="scss">
.questionnaire__category {
  margin-left: $spacing-16;

  &__limits {
    display: flex;
    justify-content: space-between;

    &__inner {
      display: flex;
      gap: $spacing-2;
    }
  }
}
</style>
