<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { LossScenarioDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import type { ModuleForm, UpdateModuleField } from "./types";
import ModuleEditLossScenario from "./ModuleEditLossScenario.vue";

const props = defineProps<{
  moduleForm: ModuleForm;
}>();

const emit = defineEmits<{
  "update:field": UpdateModuleField;
}>();

const { t } = useI18n({ useScope: "global" });

const showTemplates = ref(false);

const templates: LossScenarioDto[] = [
  {
    name: t("modules.lossScenarioTemplates.cle.name"),
    propertyDamage: t("modules.lossScenarioTemplates.cle.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.cle.bi"),
  },
  {
    name: t("modules.lossScenarioTemplates.dle.name"),
    propertyDamage: t("modules.lossScenarioTemplates.dle.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.dle.bi"),
  },
  {
    name: t("modules.lossScenarioTemplates.nle.name"),
    propertyDamage: t("modules.lossScenarioTemplates.nle.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.nle.bi"),
  },
  {
    name: t("modules.lossScenarioTemplates.ole.name"),
    propertyDamage: t("modules.lossScenarioTemplates.ole.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.ole.bi"),
  },
];

const toggleTemplates = () => (showTemplates.value = !showTemplates.value);

const addScenario = () => {
  emit("update:field", "lossScenarios", [
    ...props.moduleForm.lossScenarios,
    {
      name: "",
      propertyDamage: "",
      businessInterruption: "",
    },
  ]);
};

const updateScenario = (updatedScenario: LossScenarioDto, index: number) => {
  const updatedScenarios = props.moduleForm.lossScenarios.map((s, i) =>
    i === index ? updatedScenario : s,
  );
  emit("update:field", "lossScenarios", updatedScenarios);
};

const deleteScenario = (index: number) => {
  emit(
    "update:field",
    "lossScenarios",
    props.moduleForm.lossScenarios.filter((_, lsIndex) => lsIndex !== index),
  );
};

const selectScenarioTemplate = (scenarioTemplate: LossScenarioDto) => {
  toggleTemplates();
  emit("update:field", "lossScenarios", [...props.moduleForm.lossScenarios, scenarioTemplate]);
};
</script>

<template>
  <div class="edit-module-scenario">
    <div v-show="!showTemplates">
      <div class="edit-module-scenario__list">
        <ModuleEditLossScenario
          v-for="(lossScenario, index) in moduleForm.lossScenarios"
          :key="index"
          :loss-scenario="lossScenario"
          @delete:scenario="deleteScenario(index)"
          @update:scenario="updateScenario($event, index)"
        />
      </div>

      <BaseButtonRow v-if="moduleForm.lossScenarios.length < 5">
        <BaseButton variant="outlined" @click="addScenario">
          <BaseIcon icon="plus" />
          {{ t("modules.addLossScenario") }}
        </BaseButton>

        <BaseButton variant="outlined" @click="toggleTemplates">
          <BaseIcon icon="plus" />
          {{ t("modules.addFromTemplate") }}
        </BaseButton>
      </BaseButtonRow>
    </div>

    <div v-show="showTemplates" class="edit-module-scenario__list">
      <div>
        <BaseButton variant="outlined" @click="toggleTemplates">
          <BaseIcon icon="arrow-left" />
          {{ t("common.actions.back") }}
        </BaseButton>
      </div>

      <div class="edit-module-scenario__list">
        <ModuleEditLossScenario
          v-for="(lossScenario, index) in templates"
          :key="index"
          is-template-view
          :loss-scenario="lossScenario"
          @select:scenario="selectScenarioTemplate"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.edit-module-scenario {
  &__list {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
