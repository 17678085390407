<script setup lang="ts">
import { computed } from "vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import ModInfoState, {
  type Props as ModInfoStateProps,
} from "@/components/mods/InfoState/ModInfoState.vue";

const props = defineProps<{
  isLoading: boolean;
  hasLoadingError: boolean;
  isEmpty: boolean;
}>();

const currentInfoState = computed<ModInfoStateProps["state"] | null>(() => {
  if (props.hasLoadingError) {
    return "error";
  }

  return props.isEmpty ? "empty" : null;
});
</script>

<template>
  <div class="site-blue-rating-states">
    <BaseSpinner v-if="isLoading" class="site-blue-rating-states__spinner" />

    <ModInfoState v-else-if="currentInfoState" :state="currentInfoState" />
  </div>
</template>

<style scoped lang="scss">
.site-blue-rating-states {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc($view-height - ($header-height * 3));
}
</style>
