import { i18n } from "@/i18n";
import { Language, LoginType, Role } from "@/types/_generated/api";
import { Option } from "@/types/Option";

const { t } = i18n.global;

export const currency: readonly Option[] = [
  {
    title: "USD",
    value: 1,
  },
  {
    title: "SEK",
    value: 2,
  },
];

export const languages: readonly Option[] = [
  {
    title: t("common.languages.en"),
    value: Language.English,
  },
  {
    title: t("common.languages.se"),
    value: Language.Swedish,
  },
  {
    title: t("common.languages.no"),
    value: Language.Norwegian,
  },
  {
    title: t("common.languages.da"),
    value: Language.Danish,
  },
  {
    title: t("common.languages.nl"),
    value: Language.Dutch,
  },
  {
    title: t("common.languages.ru"),
    value: Language.Russian,
  },
];

export const roles: readonly Option[] = [
  {
    title: t("users.roles.SA"),
    value: Role.SA,
  },
  {
    title: t("users.roles.BA"),
    value: Role.BA,
  },
  {
    title: t("users.roles.INT"),
    value: Role.INT,
  },
  {
    title: t("users.roles.EXT"),
    value: Role.EXT,
  },
];

export const loginTypes: readonly Option[] = [
  {
    title: t("users.loginTypes.Blue"),
    value: LoginType.Blue,
  },
  {
    title: t("users.loginTypes.INT"),
    value: LoginType.INT,
  },
  {
    title: t("users.loginTypes.EXT"),
    value: LoginType.EXT,
  },
];

export const binaryOptions = [
  { title: t("common.yes"), value: "true" },
  { title: t("common.no"), value: "false" },
];

export const recResponseStatus = [
  { title: t("sites.searchOptions.recResponseStatus.green"), value: "Green" },
  { title: t("sites.searchOptions.recResponseStatus.red"), value: "Red" },
  { title: t("sites.searchOptions.recResponseStatus.none"), value: "None" },
];

export const reportDownloadStatus = [
  { title: t("sites.searchOptions.reportDownloadStatus.green"), value: "Green" },
  { title: t("sites.searchOptions.reportDownloadStatus.red"), value: "Red" },
  { title: t("sites.searchOptions.reportDownloadStatus.none"), value: "None" },
];
