<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { languages } from "@/helpers/options";
import { Status } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseOptionGroup from "@/components/base/BaseSelect/BaseOptionGroup.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { UserGroup } from "../../../composables/useModuleUsers";
import type { ModuleForm, ErrorMessage, UpdateModuleField } from "./types";

defineProps<{
  moduleForm: ModuleForm;
  accountEngineers: UserGroup[];
  contacts: UserGroup[];
  errorMessage: ErrorMessage;
}>();

defineEmits<{
  "update:field": UpdateModuleField;
}>();

const { t } = useI18n({ useScope: "global" });

const statusOptions: readonly Option[] = [
  {
    title: t(`common.statuses.${Status.Published}`),
    value: Status.Published,
  },
  {
    title: t(`common.statuses.${Status.UnderConstruction}`),
    value: Status.UnderConstruction,
  },
  {
    title: t(`common.archived`),
    value: Status.History,
  },
];
</script>

<template>
  <div class="module-edit-general">
    <div class="module-edit-general__row module-edit-general__row--four-columns">
      <BaseTextField
        :label="t('common.name')"
        :error-message="errorMessage.name"
        :value="moduleForm.name"
        @update:value="$emit('update:field', 'name', String($event))"
      />

      <BaseSelect
        :label="t('modules.status')"
        :value="moduleForm.status"
        @change="$emit('update:field', 'status', $event)"
      >
        <BaseOption v-for="option in statusOptions" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>

      <BaseSelect
        :label="t('common.language')"
        :value="moduleForm.language"
        @change="$emit('update:field', 'language', $event)"
      >
        <BaseOption v-for="language in languages" :key="language.value" :value="language.value">
          {{ language.title }}
        </BaseOption>
      </BaseSelect>

      <BaseSelect
        :label="t('modules.moduleContact')"
        :value="moduleForm.contactId"
        @change="$emit('update:field', 'contactId', $event)"
      >
        <BaseOption :value="undefined"></BaseOption>
        <BaseOptionGroup
          v-for="(group, i) in contacts"
          :key="`contact-grp-${i}`"
          :label="group.title"
        >
          <BaseOption v-for="(user, j) in group.users" :key="`contact-${i}-${j}`" :value="user.id">
            {{ user.fullName }}
          </BaseOption>
        </BaseOptionGroup>
      </BaseSelect>
    </div>

    <BaseDivider />

    <div class="module-edit-general__row module-edit-general__row--three-columns">
      <BaseSelect
        :label="t('modules.accountEngineer')"
        :value="moduleForm.accountEngineerId"
        @change="$emit('update:field', 'accountEngineerId', $event)"
      >
        <BaseOptionGroup
          v-for="(group, i) in accountEngineers"
          :key="`accountEngineer-grp-${i}`"
          :label="group.title"
        >
          <BaseOption
            v-for="(user, j) in group.users"
            :key="`accountEngineer-${i}-${j}`"
            :value="user.id"
          >
            {{ user.fullName }}
          </BaseOption>
        </BaseOptionGroup>
      </BaseSelect>

      <BaseTextField
        :label="t('modules.wtwOffice')"
        :error-message="errorMessage.willisOffice"
        :value="moduleForm.willisOffice"
        @update:value="$emit('update:field', 'willisOffice', String($event))"
      />

      <BaseTextField
        :label="t('modules.willisCountryOrUnit')"
        :error-message="errorMessage.willisCountryOrUnit"
        :value="moduleForm.willisCountryOrUnit"
        @update:value="$emit('update:field', 'willisCountryOrUnit', String($event))"
      />
    </div>

    <BaseDivider />

    <div class="module-edit-general__row module-edit-general__row--custom">
      <BaseTextField
        :label="t('modules.address')"
        :error-message="errorMessage.address"
        :value="moduleForm.address"
        @update:value="$emit('update:field', 'address', String($event))"
      />
      <BaseTextField
        :label="t('modules.pOBox')"
        :error-message="errorMessage.poBox"
        :value="moduleForm.poBox"
        @update:value="$emit('update:field', 'poBox', String($event))"
      />
      <BaseTextField
        :label="t('modules.zipCode')"
        :error-message="errorMessage.zipCode"
        :value="moduleForm.zipCode"
        @update:value="$emit('update:field', 'zipCode', String($event))"
      />
    </div>

    <div class="module-edit-general__row module-edit-general__row--two-columns">
      <BaseTextField
        :label="t('modules.city')"
        :error-message="errorMessage.city"
        :value="moduleForm.city"
        @update:value="$emit('update:field', 'city', String($event))"
      />
      <BaseTextField
        :label="t('modules.country')"
        :error-message="errorMessage.country"
        :value="moduleForm.country"
        @update:value="$emit('update:field', 'country', String($event))"
      />
    </div>

    <div class="module-edit-general__row module-edit-general__row--two-columns">
      <BaseTextField
        :label="t('modules.web')"
        :error-message="errorMessage.web"
        :value="moduleForm.web"
        @update:value="$emit('update:field', 'web', String($event))"
      />
      <BaseTextField
        :label="t('modules.phone')"
        :error-message="errorMessage.phone"
        :value="moduleForm.phone"
        @update:value="$emit('update:field', 'phone', String($event))"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-edit-general {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__row {
    display: grid;
    gap: $spacing-4;

    &--two-columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &--three-columns {
      grid-template-columns: repeat(3, 1fr);
    }

    &--four-columns {
      grid-template-columns: repeat(4, 1fr);
    }

    &--custom {
      grid-template-columns: 2fr 1fr 1fr;
    }
  }
}
</style>
