<script setup lang="ts">
import { toRef } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useTableFilter } from "@/composables/useTableFilter";
import { toInteger } from "@/helpers/formatNumber";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

const props = defineProps<{
  columns: ColumnDefinition<TrackedRecommendation>[];
  isLoading: boolean;
  trackedRecommendations: TrackedRecommendation[];
  moduleColorTranslations: ModuleColorTranslations;
}>();

defineEmits<{
  "click:row": [surveyId: number, recommendationId: number];
}>();

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(
    toRef(() => props.trackedRecommendations),
    toRef(() => props.columns),
  );
</script>

<template>
  <BaseGridTable
    enable-row-click
    :columns="columns"
    :rows="result.result"
    :is-loading="isLoading"
    @click:row="$emit('click:row', $event.surveyId, $event.id)"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <slot name="control-actions"></slot>
    </template>

    <template #header-isKeyRecommendation>
      <BaseIcon icon="key" />
    </template>

    <template #column-color="{ row }">
      <BaseBlueRatingStatusBadge :status="row.color" :translations="moduleColorTranslations" />
    </template>

    <template #column-rcbaCostToComplete="{ row }">
      {{ row.rcbaCostToComplete != null ? toInteger(row.rcbaCostToComplete) : "" }}
    </template>

    <template #column-siteResponseStatus="{ row }">
      <BaseStatusBadge :status="row.siteResponseStatus" />
    </template>

    <template #column-siteResponseCostToComplete="{ row }">
      {{ row.siteResponseCostToComplete != null ? toInteger(row.siteResponseCostToComplete) : "" }}
    </template>

    <template #column-surveyorStatus="{ row }">
      <BaseStatusBadge :status="row.surveyorStatus" :translations="moduleColorTranslations" />
    </template>
  </BaseGridTable>
</template>
