<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

defineProps<{
  title?: string;
}>();

const { t } = useI18n({ useScope: "global" });

defineEmits<{
  "download:excel": [void];
}>();
</script>

<template>
  <div class="base-chart-header">
    <h2 class="base-chart-header__title">
      {{ title }}
    </h2>

    <BaseButton
      size="small"
      variant="outlined"
      :title="t('common.actions.exportExcel')"
      @click="$emit('download:excel')"
    >
      <BaseIcon size="medium" icon="excel" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.base-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-4;

  &__title {
    font-size: $text-base;
    font-weight: $font-semibold;
  }
}
</style>
