import { BlueColor, SiteResponseStatus } from "@/types/_generated/api";

export const BlueRatingStatusColor: Record<BlueColor, string> = {
  [BlueColor.NotSet]: "#e6e6e6",
  [BlueColor.Blue]: "#327fef",
  [BlueColor.Green]: "#027d61",
  [BlueColor.Yellow]: "#ffb92b",
  [BlueColor.Red]: "#b1232d",
  [BlueColor.Gray]: "#bfbfbf",
};

export const SiteResponseStatusColor: Record<SiteResponseStatus, string> = {
  [SiteResponseStatus.NotSet]: "#E6E6E6",
  [SiteResponseStatus.NoProgress]: "#808080",
  [SiteResponseStatus.UnderReview]: "#FFB92B",
  [SiteResponseStatus.InProgress]: "#3BDCC9",
  [SiteResponseStatus.Completed]: "#327FEF",
  [SiteResponseStatus.Rejected]: "#F6517F",
};
