import { computed, Ref } from "vue";
import { customLocaleCompare } from "@/helpers/customLocaleCompare";
import { ExecReportSortOrder } from "@/types/_generated/api";
import { SurveyRow, TableRow } from "./useTableRows";

export const useCategoryTableRows = (
  tableRows: Ref<TableRow[]>,
  categoryIndex: number,
  sortOrder: ExecReportSortOrder,
  isGrouped: boolean,
) => {
  const sortedTableRows = computed(() => {
    if (sortOrder !== ExecReportSortOrder.Score) {
      return tableRows.value;
    }

    if (!isGrouped) {
      return tableRows.value.filter((tr) => "survey" in tr).toSorted(sortSurveyGroup);
    }

    return sortTableRows(tableRows.value);
  });

  const sortTableRows = (rows: TableRow[]) => {
    const result: TableRow[] = [];
    let currentGroup: SurveyRow[] = [];

    for (const row of rows) {
      const isSurvey = "survey" in row;

      if (isSurvey) {
        currentGroup.push(row);
      }

      const isGroupLabel = "label" in row;

      if (isGroupLabel) {
        if (currentGroup.length > 0) {
          addGroup(currentGroup, result);
          currentGroup = [];
        }

        result.push(row);
      }
    }

    return result;
  };

  const addGroup = (group: SurveyRow[], result: TableRow[]) =>
    result.push(...group.toSorted(sortSurveyGroup));

  const sortSurveyGroup = (a: SurveyRow, b: SurveyRow) =>
    b.survey.categoryData[categoryIndex].scorePercent -
      a.survey.categoryData[categoryIndex].scorePercent ||
    customLocaleCompare(a.survey.siteDefinitions.join(), b.survey.siteDefinitions.join());

  return { sortedTableRows };
};
