const validUrlStarts = [
  "//",
  "ftp://",
  "ftps://",
  "http://",
  "https://",
  "https://",
  "spdy://",
  "tel:",
  "mailto:",
] as const;

export const formatLink = (link: string | null | undefined) =>
  !link?.length
    ? undefined
    : validUrlStarts.some((x) => link.startsWith(x))
      ? link
      : `https://${link}`;
