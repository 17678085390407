export const truncateLabel = (value: string, maxLength: number): string => {
  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}...`;
  }
  return value;
};

export const tooltipFormatter = (params: { value: number; seriesName: string }[]): string => {
  return params
    .filter((param) => param.value !== 0)
    .map((param) => {
      const seriesName = param.seriesName || "";
      const value = param.value || 0;
      return `<strong>${seriesName}</strong>: ${value}`;
    })
    .join("<br>");
};
