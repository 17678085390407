<script setup lang="ts">
import { useAppStore } from "@/stores/app";
import { FileDto, RotateDegrees } from "@/types/_generated/api";
import BaseFileArea from "@/components/base/BaseFileArea/BaseFileArea.vue";
import RecImageList, { EditRecImage } from "@/components/shared/RecImageList/RecImageList.vue";

interface Props {
  standardRecId?: number;
  images: EditRecImage[];
}

defineProps<Props>();

const emit = defineEmits<{
  addImage: [FileDto[]];
  update: [images: EditRecImage[]];
  "update:caption": [{ index: number; caption: string }];
  "update:rotation": [{ index: number; rotation: RotateDegrees }];
}>();

const app = useAppStore();
</script>

<template>
  <div class="standard-rec-image-form">
    <BaseFileArea
      :allowed-file-types="app.settings.imageFileExtensions"
      :max-size="app.settings.imageMaxSizeInBytes"
      @on-add="emit('addImage', $event)"
    />

    <RecImageList
      :base-url="`standard-recommendations/${standardRecId}/images`"
      :images="images"
      @update="emit('update', $event)"
      @update:caption="emit('update:caption', $event)"
      @update:rotation="emit('update:rotation', $event)"
    />
  </div>
</template>

<style scoped lang="scss">
.standard-rec-image-form {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  padding: $spacing-6;
}
</style>
