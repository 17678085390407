<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { bytesToSize } from "@/helpers/bytesToSize";
import { RotateDegrees } from "@/types/_generated/api";
import BaseDropdownMenu, {
  MenuOption,
} from "@/components/base/BaseDropdownMenu/BaseDropdownMenu.vue";
import BaseImage from "@/components/base/BaseImage.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import { EditRecImage } from "./RecImageList.vue";

interface Props {
  baseUrl: string;
  image: EditRecImage;
  index: number;
  isLast: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  delete: [index: number];
  "update:position": [{ index: number; direction: "up" | "down" }];
  "update:caption": [{ index: number; caption: string }];
  "update:rotation": [{ index: number; rotation: RotateDegrees }];
}>();

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();

const getSource = (image: EditRecImage) => {
  if (image.file) {
    return `data:image/jpeg;base64,${image.file}`;
  }

  return `/v1/${props.baseUrl}/${image.fileId}`;
};

const onDelete = async (index: number) => {
  const result = await dialog.openDeletePrompt(t("common.image"));

  if (result) {
    emit("delete", index);
  }
};

const actions = computed(() => {
  const { index, isLast } = props;

  const actions: MenuOption[] = [];

  if (index !== 0) {
    actions.push({
      icon: "arrow-up",
      label: t("library.orderedList.moveUp"),
      action: () => emit("update:position", { index, direction: "up" }),
    });
  }

  if (!isLast) {
    actions.push({
      icon: "arrow-down",
      label: t("library.orderedList.moveDown"),
      action: () => emit("update:position", { index, direction: "down" }),
    });
  }

  actions.push({
    icon: "rotate",
    label: t("common.actions.rotate"),
    action: () => emit("update:rotation", { index, rotation: nextRotation(props.image.rotation) }),
  });

  actions.push({
    icon: "trash",
    label: t("common.actions.delete"),
    action: () => onDelete(index),
  });

  return actions;
});

const nextRotation = (rotation: RotateDegrees) => {
  switch (rotation) {
    case RotateDegrees.None:
      return RotateDegrees.Rotate90;
    case RotateDegrees.Rotate90:
      return RotateDegrees.Rotate180;
    case RotateDegrees.Rotate180:
      return RotateDegrees.Rotate270;
    case RotateDegrees.Rotate270:
      return RotateDegrees.None;
  }
};
</script>

<template>
  <div class="rec-image-list-item">
    <div>{{ index + 1 }}</div>
    <BaseImage
      class="rec-image-list-item__image"
      :class="`rec-image-list-item__image--${props.image.rotation.toLowerCase()}`"
      :src="getSource(image)"
      :alt="image.fileName"
      width="200"
    />
    <div>
      <BaseTextArea
        :value="image.caption"
        :placeholder="t('blueRating.recommendation.imageCaption')"
        @update:value="emit('update:caption', { index, caption: $event as string })"
      />
      <div>{{ bytesToSize(image.fileSize) }}</div>
    </div>
    <BaseDropdownMenu :options="actions" :position="['top', 'left']" />
  </div>
</template>

<style scoped lang="scss">
.rec-image-list-item {
  display: grid;
  gap: $spacing-6;
  grid-template-columns: auto auto 1fr auto;
  margin-bottom: 1rem;

  &__image {
    align-self: top;

    transition: transform 0.25s;

    &--none {
      transform: rotate(0deg);
    }

    &--rotate90 {
      transform: rotate(90deg);
    }

    &--rotate180 {
      transform: rotate(180deg);
    }

    &--rotate270 {
      transform: rotate(270deg);
    }
  }

  &__position {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-4;
  }
}
</style>
