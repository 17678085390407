import { useI18n } from "vue-i18n";
import { useTabs } from "@/composables/useTabs";

export enum StandardRecommendationDetailDrawerTab {
  General = "General",
  Images = "Images",
}

export const useStandardRecommendationDetailDrawerTabs = () => {
  const { t } = useI18n({ useScope: "global" });

  const tabs = [
    {
      label: t("standardRecommendations.standardRecommendation.general"),
      name: StandardRecommendationDetailDrawerTab.General,
    },
    {
      label: t("blueRating.recommendation.uploadedPhotos"),
      name: StandardRecommendationDetailDrawerTab.Images,
    },
  ];

  const { activeTab, changeTab } = useTabs(tabs, StandardRecommendationDetailDrawerTab.General);

  return {
    tabs,
    changeTab,
    activeTab,
  };
};
