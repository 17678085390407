<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useDesign from "@/composables/useDesign";
import { StandardRecommendationDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import ModImageList from "@/components/mods/ImageList/ModImageList.vue";
import {
  useStandardRecommendationDetailDrawerTabs,
  StandardRecommendationDetailDrawerTab,
} from "../composables/useStandardRecommendationDetailDrawerTabs";
import StandardRecommendationDetail from "./StandardRecommendationDetail.vue";

const props = defineProps<{
  standardRecommendation: StandardRecommendationDto | null;
}>();

const emit = defineEmits<{
  close: [void];
  edit: [standardRecId: number];
}>();

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const showEditDrawer = ref(false);

const { activeTab, changeTab, tabs } = useStandardRecommendationDetailDrawerTabs();

const {
  mediaQuery: { isWidthLessThan },
} = useDesign();

const drawerWidth = computed(() =>
  isWidthLessThan("lg") ? "100" : isWidthLessThan("2xl") ? "50" : "30",
);

const handleEdit = () => {
  const id = props.standardRecommendation?.standardRecommendationId;
  if (id === undefined) {
    return;
  }
  emit("edit", id);
};
</script>

<template>
  <BaseDrawer
    v-show="!showEditDrawer"
    :width="drawerWidth"
    :title="t('standardRecommendations.standardRecommendation.standardRecommendationDetails')"
    @close="$emit('close')"
  >
    <BaseDrawerTabs
      v-if="standardRecommendation"
      :current-tab="activeTab"
      :tabs="tabs"
      @change="changeTab"
    />

    <StandardRecommendationDetail
      v-if="activeTab === StandardRecommendationDetailDrawerTab.General"
      :is-loading="isLoading"
      :standard-recommendation="standardRecommendation!"
    />

    <div class="standard-rec-detail-drawer__images">
      <ModImageList
        v-if="activeTab === StandardRecommendationDetailDrawerTab.Images"
        :url-builder="
          (image) =>
            `/v1/standard-recommendations/${props.standardRecommendation?.standardRecommendationId}/images/${image.fileId}`
        "
        :images="standardRecommendation?.images ?? []"
      />
    </div>

    <template #footer-left>
      <BaseButton @click="handleEdit">
        {{ t("common.actions.edit") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>
<style scoped lang="scss">
.standard-rec-detail-drawer__images {
  padding: $spacing-6;
}
</style>
