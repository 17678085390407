<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import useDesign from "@/composables/useDesign";
import { useAppStore } from "@/stores/app";
import { useImpersonation } from "@/views/Admin/Users/composables/useImpersonation";
import BaseButton from "../base/BaseButton.vue";

const { t } = useI18n({ useScope: "global" });
const app = useAppStore();
const design = useDesign();
const { endImpersonation, isLoadingImpersonation } = useImpersonation();

const fullName = computed(() => `${app.user?.firstName} ${app.user?.lastName}`);
</script>

<template>
  <div class="app__impersonate-warning">
    <BaseButton
      class="app__impersonate-warning__button"
      variant="text"
      :disabled="isLoadingImpersonation"
      @click="endImpersonation"
    >
      {{ t("impersonation.endImpersonation", { fullName }) }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.app__impersonate-warning {
  $height: v-bind("design.defaults.impersonationHeight");

  display: flex;
  align-items: center;
  justify-content: center;

  height: $height;
  overflow: hidden;
  text-align: center;
  background-color: $error-1;

  &__button {
    height: $height;
  }
}
</style>
