import useVuelidate, { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";

export type QuestionnaireEntityId = `${"area" | "category" | "question"}_${number}`;

export type QuestionnaireNavValidationError = {
  id: QuestionnaireEntityId;
  propertyPath: string;
  message: string;
};

export const useVuelidateChildErrors = (v$: ReturnType<typeof useVuelidate>) => {
  const draft = useQuestionnaireDraftStore();

  const getErrors = (id: QuestionnaireEntityId) =>
    v$.value
      ?.$getResultsForChild("questionnaire")
      ?.$getResultsForChild(id)
      ?.$errors.map((e) => mapToQuestionnaireNavError(id, e));

  const mapToQuestionnaireNavError = (id: QuestionnaireEntityId, error: ErrorObject) => ({
    id,
    propertyPath: error.$propertyPath,
    message: error.$message,
  });

  const questionErrors = computed(() =>
    draft.draft?.areas.flatMap((a) =>
      a.categories.flatMap((c) =>
        c.questions.flatMap((q) => getErrors(`question_${q.questionId}`)),
      ),
    ),
  );

  const categoryErrors = computed(() =>
    draft.draft?.areas.flatMap((a) =>
      a.categories.flatMap((c) => getErrors(`category_${c.categoryId}`)),
    ),
  );

  const areaErrors = computed(
    () => draft.draft?.areas.flatMap((a) => getErrors(`area_${a.areaId}`)) ?? [],
  );

  const errors = computed(() =>
    [
      ...(questionErrors.value ?? []),
      ...(categoryErrors.value ?? []),
      ...(areaErrors.value ?? []),
    ].filter((e) => !!e),
  );

  return { errors };
};
