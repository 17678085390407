import { Ref, ref, watchEffect } from "vue";
import { SortOrder } from "@/constants/SortOrder";
import { Sorting, sortByKeys } from "@/helpers/sort";
import { ColumnDefinition } from "@/types/ColumnDefinition";

export const useSortRows = <T>(
  rows: Ref<T[]>,
  columns: Ref<ColumnDefinition<T>[]>,
  defaultSort?: Sorting<T>[],
) => {
  const sortKey = ref<Sorting<T>["key"]>();
  const sortOrder = ref<SortOrder>(SortOrder.None);

  const getNextSortOrder = (currentOrder: SortOrder): SortOrder => {
    switch (currentOrder) {
      case SortOrder.None:
      case SortOrder.Ascending:
        return SortOrder.Descending;
      case SortOrder.Descending:
        return SortOrder.Ascending;
    }
  };

  const sortedRows = ref<T[]>([]) as Ref<T[]>;

  watchEffect(() => (sortedRows.value = rows.value));

  watchEffect(() => {
    const currentColumn = columns.value.find((col) => col.key === sortKey.value);

    if (currentColumn?.sortValueFunc) {
      const sortFunc = currentColumn.sortValueFunc;

      sortedRows.value = sortedRows.value.toSorted((a, b) => {
        const aValue = sortFunc(a);
        const bValue = sortFunc(b);

        if (aValue === bValue) {
          return 0;
        }

        const compareResult = aValue < bValue ? -1 : 1;

        return sortOrder.value === SortOrder.Ascending ? compareResult : -compareResult;
      });
    } else {
      const sortings: Sorting<T>[] = [];

      if (sortKey.value) {
        sortings.push({ key: sortKey.value, order: sortOrder.value });
      }

      if (defaultSort) {
        sortings.push(...defaultSort);
      }

      sortedRows.value = sortByKeys(sortedRows.value, ...sortings);
    }
  });

  // Handle row sorting when a column is clicked
  const sortRows = (key: Sorting<T>["key"]) => {
    if (sortKey.value === key) {
      // If the same column is clicked, cycle through sort orders
      sortOrder.value = getNextSortOrder(sortOrder.value);
    } else {
      // If a new column is clicked, set it as the sort key and start with ascending order
      sortKey.value = key;
      sortOrder.value = SortOrder.Ascending;
    }
  };

  return {
    sortOrder,
    sortKey,
    sortedRows,
    sortRows,
  };
};
