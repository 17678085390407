<script setup lang="ts">
import { ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { SortOrder } from "@/constants/SortOrder";
import { useAppStore } from "@/stores/app";
import { UserLeanDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseEmailLink from "@/components/base/BaseEmailLink.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import ClientLink from "@/components/shared/ClientLink.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import UserLink from "@/components/shared/UserLink.vue";
import ClientDetailsDrawer from "../../Clients/components/ClientDetailsDrawer.vue";
import { useUsersTableColumns } from "../composables/useUsersTableColumns";
import { useUsersTableMenuOptions } from "../composables/useUsersTableMenuOptions";
import UserDetailsDrawer from "./UserDetailsDrawer/UserDetailsDrawer.vue";
import UserEditDrawer from "./UserEditDrawer/UserEditDrawer.vue";

const { t } = useI18n({ useScope: "global" });
const props = defineProps<{
  isLoading: boolean;
  users: UserLeanDto[];
}>();

defineEmits<{
  delete: [UserLeanDto[]];
}>();

const app = useAppStore();

const currentUserId = ref<number | null>(null);
const showEditUserDrawer = ref(false);
const showLockedAndArchived = ref(false);

const { menuOptions } = useUsersTableMenuOptions(showLockedAndArchived);
const { columns } = useUsersTableColumns(showLockedAndArchived);

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(
    toRef(() => props.users),
    columns,
    [(item) => showLockedAndArchived.value || (!item.lockedDate && !item.isArchived)],
  );

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();

const {
  showDrawer: showClientDetailDrawer,
  drawerData: clientDrawerId,
  closeDrawer: closeClientDetailDrawer,
  openDrawer: openClientDetailDrawer,
} = useDrawerState<number>();

const openEditUserDrawer = (userId: number | null) => {
  currentUserId.value = userId;
  showEditUserDrawer.value = true;
};
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="usersTable"
    :default-sort="[{ key: 'email', order: SortOrder.Ascending }]"
    :custom-options="menuOptions"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <BaseButton v-if="app.isAdmin" @click="openEditUserDrawer(null)">
        {{ t("users.createUser") }}
      </BaseButton>
    </template>

    <template #header-fullName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #header-clients="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #column-fullName="{ row }">
      <UserLink
        :label="row.fullName"
        :user-id="row.id"
        :user-role="row.role"
        @click="openUserDetailDrawer"
      />
    </template>

    <template #column-email="{ row }">
      <BaseEmailLink class="base-table__custom-cell" :email="row.email" />
    </template>

    <template #column-clients="{ row }">
      <div class="users-table__client base-table__custom-cell">
        <ClientLink
          v-for="(client, i) in row.clients"
          :key="i"
          :label="client.name"
          :client-id="client.clientId"
          @click="openClientDetailDrawer"
        />
      </div>
    </template>

    <template #actions="{ row }">
      <EditIconButton
        v-if="$authorize.isAdmin() && $authorize.isSuperiorTo(row.role)"
        :title="`${t('common.actions.edit')} ${row.fullName}`"
        @click="openEditUserDrawer(row.id)"
      />
    </template>
  </BaseGridTable>

  <ClientDetailsDrawer
    v-if="showClientDetailDrawer && clientDrawerId"
    :client-id="clientDrawerId"
    @close="closeClientDetailDrawer"
  />

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />

  <UserEditDrawer
    v-if="showEditUserDrawer"
    :user-id="currentUserId"
    @close="showEditUserDrawer = false"
  />
</template>

<style scoped lang="scss">
.users-table__client {
  display: flex;
  gap: $spacing-2;
  flex-direction: column;
}
</style>
