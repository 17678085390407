<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useDownloadBlob } from "@/composables/useDownloadBlob";
import { RecTrackingChart, RecommendationTrackingFilter } from "@/types/_generated/api";
import { ModuleColorTranslations } from "@/types/ModuleTranslations";
import RecTrackingBlueRatingChart from "@/views/UserWeb/RecTracking/components/RecTrackingBlueRatingChart.vue";
import RecTrackingStatusChart from "@/views/UserWeb/RecTracking/components/RecTrackingStatusChart.vue";
import BaseChartHeader from "@/components/base/BaseChart/components/BaseChartHeader.vue";

defineProps<{
  recommendationTracking: TrackedRecommendation[];
  filter: RecommendationTrackingFilter;
  moduleTranslations: ModuleColorTranslations;
}>();

const { t } = useI18n({ useScope: "global" });
const { blobDownload } = useDownloadBlob();

const downloadChart = (chartType: RecTrackingChart) => {
  switch (chartType) {
    case RecTrackingChart.SiteResponse:
      blobDownload.get(
        "/v1/recommendation-response/reports/recommendation-tracking/excel/site-response",
      );
      break;
    case RecTrackingChart.SiteResponseByBlueRating:
      blobDownload.get(
        "/v1/recommendation-response/reports/recommendation-tracking/excel/site-response-blue-rating",
      );
      break;
  }
};
</script>

<template>
  <div class="rec-resp-summary-rec-trac-overview">
    <div class="rec-resp-summary-rec-trac-overview__content">
      <div class="rec-resp-summary-rec-trac-overview__chart">
        <BaseChartHeader
          :title="t('userWeb.recommendation.responseStatus')"
          @download:excel="() => downloadChart(RecTrackingChart.SiteResponse)"
        />
        <RecTrackingStatusChart :chart-data="recommendationTracking" />
      </div>
      <div class="rec-resp-summary-rec-trac-overview__chart">
        <BaseChartHeader
          :title="t('userWeb.recommendationTracking.siteResponseBlueRatingChart.title')"
          @download:excel="() => downloadChart(RecTrackingChart.SiteResponseByBlueRating)"
        />
        <RecTrackingBlueRatingChart
          :chart-data="recommendationTracking"
          :translations="moduleTranslations"
          :filter="filter"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rec-resp-summary-rec-trac-overview {
  margin-top: $spacing-8;
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
  width: 100%;

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-8;

    @media (max-width: $screen-md) {
      grid-template-columns: 1fr;
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    height: 40rem;
  }
}
</style>
