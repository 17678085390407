import { ref, readonly } from "vue";
import type { TableLegend } from "@/components/shared/BlueRatingTable/types/types";
import { getModuleService } from "@/services/modules";
import { ModuleDto } from "@/types/_generated/api";

const mapModuleToLegend = (module: ModuleDto): TableLegend => ({
  blue: module.textForBlue,
  green: module.textForGreen,
  yellow: module.textForYellow,
  red: module.textForRed,
});

export const useGetModuleData = () => {
  const isLoadingModule = ref(false);
  const tableLegend = ref<TableLegend | null>(null);
  const abeyanceShort = ref<ModuleDto["abeyanceShort"]>("");
  const currencyCode = ref<ModuleDto["currencyCode"]>("");

  const getModuleData = async (moduleId: number) => {
    isLoadingModule.value = true;

    try {
      const module = await getModuleService(moduleId);
      if (!module) return;

      tableLegend.value = mapModuleToLegend(module);
      abeyanceShort.value = module.abeyanceShort;
      currencyCode.value = module.currencyCode;
    } catch (_error) {
      tableLegend.value = null;
    } finally {
      isLoadingModule.value = false;
    }
  };

  return {
    tableLegend: readonly(tableLegend),
    currencyCode: readonly(currencyCode),
    abeyanceShort: readonly(abeyanceShort),
    isLoadingModule: readonly(isLoadingModule),
    getModuleData,
  };
};
