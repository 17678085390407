import DOMPurify from "dompurify";
import { onUnmounted } from "vue";

export const useSanitizer = () => {
  const allowedAttributes = ["class", "style", "href", "src", "alt", "align"];
  const allowedStyles: string[] = ["color", "font-size", "font-weight", "font-style"];

  const purifyConfig: DOMPurify.Config = {
    ALLOWED_ATTR: allowedAttributes,
  };

  const filterAttributes = (node: Element) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Filter style attribute
      if (node.hasAttribute("style")) {
        const style = node.getAttribute("style");
        if (style) {
          const styleObj = style.split(";").reduce(
            (acc, style) => {
              const [property, value] = style.split(":").map((str) => str.trim());
              if (allowedStyles.includes(property)) {
                acc[property] = value;
              }
              return acc;
            },
            {} as Record<string, string>,
          );

          const filteredStyle = Object.entries(styleObj)
            .map(([prop, value]) => `${prop}: ${value}`)
            .join("; ");

          if (filteredStyle) {
            node.setAttribute("style", filteredStyle);
          } else {
            node.removeAttribute("style");
          }
        }
      }
    }
  };

  DOMPurify.addHook("afterSanitizeAttributes", filterAttributes);

  const sanitizeHtml = (html: string): string => {
    return String(DOMPurify.sanitize(html, purifyConfig));
  };

  // Clean up DOMPurify hook
  onUnmounted(() => {
    DOMPurify.removeHook("afterSanitizeAttributes");
  });

  return {
    sanitizeHtml,
  };
};
