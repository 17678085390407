<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/stores/app";
import ClientsTable from "@/views/Admin/Clients/components/ClientsTable.vue";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import ClientEditDrawer from "./components/ClientEditDrawer.vue";

const { t } = useI18n({ useScope: "global" });
const app = useAppStore();

const showClientEditDrawer = ref(false);
const selectedClientId = ref<number | null>(null);

const editClient = (clientId: number) => {
  showClientEditDrawer.value = true;
  selectedClientId.value = clientId;
};

const createClient = () => {
  showClientEditDrawer.value = true;
  selectedClientId.value = null;
};
</script>

<template>
  <AdminHeader :breadcrumbs="[{ title: t('navigation.clients') }]" />

  <AdminViewLayout>
    <ClientsTable :is-admin="app.isAdmin" @create-client="createClient" @edit-client="editClient" />

    <ClientEditDrawer
      v-if="showClientEditDrawer"
      :client-id="selectedClientId"
      @close="showClientEditDrawer = false"
    />
  </AdminViewLayout>
</template>
