import { computed, type Ref } from "vue";
import type { MultiselectOption, MultiselectSelectedOptions } from "../types";

export const useMultiselectSelect = <T>(
  emit: (evt: "update:selected", options: T[]) => void,
  options: Ref<MultiselectOption<T>[]>,
  selectedOptions: Ref<MultiselectSelectedOptions<T>>,
) => {
  // Determine if all options are selected (excluding disabled ones)
  const allOptionsSelected = computed(() => {
    const selectableOptions = options.value.filter((option) => !option.disabled);
    return selectableOptions.every((option) => selectedOptions.value.has(option.value));
  });

  // Determine if all options are disabled
  const allOptionsDisabled = computed(() => {
    return options.value.every((option) => option.disabled);
  });

  // Select and deselect all options
  const handleSelectAll = () => {
    const optionsValues = new Set(
      options.value.filter((option) => !option.disabled).map((option) => option.value), // Filter out disabled options
    );

    if (allOptionsSelected.value) {
      // If all options are selected, create a new set excluding the current option group
      const newSelectedOptions = new Set(
        [...selectedOptions.value].filter((opt) => !optionsValues.has(opt)),
      );
      emitSelectedOptions([...newSelectedOptions]);
    } else {
      // If not all options are selected, add the current option group to the selected set
      const newSelectedOptions = new Set([...selectedOptions.value, ...optionsValues]);
      emitSelectedOptions([...newSelectedOptions]);
    }
  };

  // Select and deselect single option
  const handleSelectOption = (option: T) => {
    const updatedSelectedOptions = new Set(selectedOptions.value);

    if (updatedSelectedOptions.has(option)) {
      updatedSelectedOptions.delete(option);
    } else {
      updatedSelectedOptions.add(option);
    }

    emitSelectedOptions([...updatedSelectedOptions]);
  };

  const emitSelectedOptions = (option: T[]) => {
    emit("update:selected", option);
  };

  return {
    allOptionsSelected,
    allOptionsDisabled,
    handleSelectAll,
    handleSelectOption,
  };
};
