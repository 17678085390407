<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.75C0 0.783506 0.783506 0 1.75 0H14.25C15.2165 0 16 0.783516 16 1.75V14.2502C16 15.2167 15.2165 16.0002 14.25 16.0002H1.75C0.783516 16.0002 0 15.2167 0 14.2502V1.75ZM1.75 1.5C1.61193 1.5 1.5 1.61193 1.5 1.75V14.2502C1.5 14.3883 1.61192 14.5002 1.75 14.5002H14.25C14.3881 14.5002 14.5 14.3883 14.5 14.2502V1.75C14.5 1.61192 14.3881 1.5 14.25 1.5H1.75Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 0.25C5.66421 0.25 6 0.585786 6 1V15C6 15.4142 5.66421 15.75 5.25 15.75C4.83579 15.75 4.5 15.4142 4.5 15V1C4.5 0.585786 4.83579 0.25 5.25 0.25Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.75 0.25C11.1642 0.25 11.5 0.585786 11.5 1V15C11.5 15.4142 11.1642 15.75 10.75 15.75C10.3358 15.75 10 15.4142 10 15V1C10 0.585786 10.3358 0.25 10.75 0.25Z"
    />
  </svg>
</template>
