<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useSurveyorStatus } from "@/composables/translations/useSurveyorStatus";
import { SurveyorStatus, ModuleDto } from "@/types/_generated/api";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

const props = defineProps<{
  module: ModuleDto;
  surveyorStatuses?: SurveyorStatus[];
}>();

defineEmits<{
  "update:selected": [options: SurveyorStatus[]];
}>();

const { t } = useI18n({ useScope: "global" });

const { getSurveyorStatus } = useSurveyorStatus(props.module);

const options = Object.values(SurveyorStatus).map((status) => ({
  value: status,
  label: getSurveyorStatus(status),
}));
</script>

<template>
  <BaseAccordion :label="t('userWeb.recommendation.surveyorStatus')">
    <ModMultiselect
      :options="options"
      :selected-options="new Set(surveyorStatuses)"
      @update:selected="$emit('update:selected', $event)"
    />
  </BaseAccordion>
</template>
