<script setup lang="ts">
import { computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useQuestionnaireDraftClient } from "@/composables/useQuestionnaireDraftClient";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import { InsertPoint } from "@/types/InsertPoint";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  type: "Area" | "Category" | "Question";
  insertIntoId: number;
}>();

const store = useQuestionnaireStore();
const draft = useQuestionnaireDraftStore();
const client = useQuestionnaireDraftClient(store.questionnaire!.questionnaireId!);

const getArea = (areaId: number) => draft.draft?.areas.find((x) => x.areaId === areaId);

const getCategory = (categoryId: number) =>
  draft.draft?.areas.flatMap((x) => x.categories).find((y) => y.categoryId === categoryId);

const add = async () => {
  switch (props.type) {
    case "Area":
      await addNewArea();
      break;
    case "Category":
      await addNewCategory();
      break;
    case "Question":
      await addNewQuestion();
      break;
    default:
      throw new Error("Invalid draft action type");
  }
};

const addNewArea = async () => {
  if (!(await client.area.add(props.insertIntoId))) {
    return;
  }

  const addedAreaId = draft.draft?.areas?.[draft.draft?.areas?.length - 1].areaId;

  if (!addedAreaId) {
    return;
  }

  await nextTick();

  location.hash = `#area_${addedAreaId}`;
};

const addNewCategory = async () => {
  if (!(await client.category.add(props.insertIntoId))) {
    return;
  }

  const area = getArea(props.insertIntoId);
  const addedCategoryId = area?.categories[area.categories.length - 1]?.categoryId;

  if (!addedCategoryId) {
    return;
  }

  await nextTick();

  location.hash = `#category_${addedCategoryId}`;
};

const addNewQuestion = async () => {
  if (!(await client.question.add(props.insertIntoId))) {
    return;
  }

  const category = getCategory(props.insertIntoId);
  const addedQuestionId = category?.questions[category.questions.length - 1]?.questionId;

  if (!addedQuestionId) {
    return;
  }

  await nextTick();

  location.hash = `#question_${addedQuestionId}`;
};

const insertPoint = computed((): InsertPoint => {
  switch (props.type) {
    case "Area":
      return "Questionnaire";
    case "Category":
      return "Area";
    case "Question":
      return "Category";
  }
  throw new Error(`Type ${props.type} cannot be converted to a valid insertPoint`);
});
</script>

<template>
  <BaseButton variant="outlined" @click="() => add()">
    <BaseIcon icon="plus" />
    {{
      t("questionnaire.draft.addEntity", {
        entity: t(`questionnaire.types.${type.toLowerCase()}`),
      })
    }}
  </BaseButton>

  <BaseButton
    variant="outlined"
    @click="() => draft.templateDrawer.show(insertPoint, insertIntoId)"
  >
    <BaseIcon icon="plus" />
    {{
      t("questionnaire.draft.addFromTemplate", {
        entity: t(`questionnaire.types.${type.toLowerCase()}`),
      })
    }}
  </BaseButton>
</template>
