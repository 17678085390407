<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

export type State = "idle" | "saving" | "success" | "error";

type Props = {
  state: State;
  showLabel?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  state: "idle",
  showLabel: true,
});

const internalState = ref<State>("idle");

onBeforeMount(() => {
  internalState.value = props.state;
});

watch(
  () => props.state,
  (value) => {
    internalState.value = value;
    if (value === "success") setTimeout(() => (internalState.value = "idle"), 2000);
  },
);

const size = computed(() => (props.showLabel ? "1rem" : "16px"));
</script>

<template>
  <div
    :class="[
      'base-auto-save-status',
      `base-auto-save-status__${internalState}`,
      showLabel ? `base-auto-save-status--show-label` : '',
    ]"
  >
    <small class="base-auto-save-status__info base-auto-save-status__idle-info">
      {{ t("common.autoSaveStatus.idle") }}
    </small>
    <small class="base-auto-save-status__info base-auto-save-status__saving-info">
      {{ t("common.autoSaveStatus.saving") }}
    </small>
    <small class="base-auto-save-status__info base-auto-save-status__success-info">
      {{ t("common.autoSaveStatus.success") }}
    </small>
    <small class="base-auto-save-status__info base-auto-save-status__error-info">
      {{ t("common.autoSaveStatus.error") }}
    </small>
    <span class="base-auto-save-status__icon base-auto-save-status__saving-icon"></span>
    <BaseIcon
      icon="check-circle"
      class="base-auto-save-status__icon base-auto-save-status__success-icon"
    />
    <BaseIcon
      icon="warning"
      class="base-auto-save-status__icon base-auto-save-status__error-icon"
    />
  </div>
</template>
<style scoped lang="scss">
.base-auto-save-status {
  $size: v-bind(size); // Adapt to svg or text
  display: flex;
  position: relative;
  text-align: end;
  min-width: $size;
  min-height: $size;

  &__info {
    display: none;
    position: absolute;
    right: 1.5rem;
    white-space: nowrap;
    opacity: 0;
  }

  &--show-label {
    padding-right: 1.5rem;
  }
  &--show-label &__info {
    display: block;
  }

  &__icon {
    position: absolute;
    right: 0;
    opacity: 0;
    width: $size;
    height: $size;
  }

  &__idle {
    padding-right: 0;
    & &-info {
      right: 0;
      opacity: 1;
      transition: opacity 1s ease-in-out;
    }
  }
  &__saving {
    & &-info {
      opacity: 1;
      animation: fadeIn 4s normal;
    }
    & &-icon {
      border: 4px solid transparent;
      border-top-color: $info-5;
      border-radius: 50%;
      opacity: 1;
      animation:
        fadeIn 4s normal,
        rotate 1s ease infinite;
    }
  }
  &__success {
    & &-info {
      opacity: 1;
      animation: fadeOut 2s normal;
    }
    & &-icon {
      color: inherit;
      animation: fadeOut 2s normal;
    }
  }
  &__error {
    & &-info {
      opacity: 1;
    }
    & &-icon {
      color: $error-5;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
</style>
