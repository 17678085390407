import { ref, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import type { Props as BaseModalProps } from "@/components/base/BaseModal.vue";

interface DialogState {
  isVisible: boolean;
  isDestructive?: boolean;
  title: BaseModalProps["title"];
  description: BaseModalProps["description"];
  confirmText?: BaseModalProps["confirmText"];
  cancelText?: BaseModalProps["cancelText"];
  hideCancelButton?: BaseModalProps["hideCancel"];
}

type DialogOptions = Omit<DialogState, "isVisible">;

const initialDialogState: DialogState = {
  isVisible: false,
  isDestructive: false,
  title: "",
  description: "",
  confirmText: undefined,
  cancelText: undefined,
  hideCancelButton: false,
};

const dialogState = reactive<DialogState>({ ...initialDialogState });

const resolver = ref<(result: boolean) => void>(() => {});

export const useDialogController = () => {
  return {
    ...toRefs(dialogState),
    cancel: () => resolver.value(false),
    confirm: () => resolver.value(true),
  };
};

export const useDialog = () => {
  const { t } = useI18n({ useScope: "global" });

  const resetDialog = () => {
    Object.assign(dialogState, initialDialogState);
  };

  const openDialog = async (options: DialogOptions): Promise<boolean> => {
    Object.assign(dialogState, {
      ...initialDialogState,
      isVisible: true,
      ...options,
    });

    return new Promise((resolve) => {
      resolver.value = (value: boolean) => {
        resolve(value);
        resetDialog();
      };
    });
  };

  const openDeletePrompt = async (entity: string) =>
    openDialog({
      title: t("common.deletePrompt.title", { entity }),
      description: t("common.deletePrompt.message", { entity }),
      isDestructive: true,
    });

  return {
    openDialog,
    openDeletePrompt,
  };
};
