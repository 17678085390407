<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetClient } from "@/composables/services/useGetClient";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { clientEvents } from "@/services/clients";
import { ClientDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import ClientEditDrawer from "./ClientEditDrawer.vue";

const props = defineProps<{
  clientId: number;
}>();

defineEmits<{
  close: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const { isLoadingClient, getClient } = useGetClient();

const showEditDrawer = ref(false);
const client = ref<ClientDto | null>();

const fetchClientData = async () => {
  client.value = await getClient(props.clientId!);
};

useBroadcastService(clientEvents, fetchClientData);

onBeforeMount(fetchClientData);
</script>

<template>
  <BaseDrawer
    v-show="!showEditDrawer"
    :width="'auto'"
    :title="t('clients.details')"
    :is-loading="isLoadingClient"
    @close="$emit('close')"
  >
    <div class="client-details-drawer">
      <BaseDataDisplayList>
        <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('clients.client')">
          <p>{{ client?.name }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('clients.country')">
          <p>{{ client?.willisCountryOrUnit }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('clients.office')">
          <p>{{ client?.willisOffice }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :is-loading="isLoadingClient" :label="t('common.status')">
          <p>{{ client?.status ? t(`common.statuses.${client?.status}`) : "" }}</p>
        </BaseDataDisplayListItem>
      </BaseDataDisplayList>
    </div>

    <template v-if="$authorize.isAdmin()" #footer-left>
      <BaseButton @click="showEditDrawer = true">
        {{ t("common.actions.edit") }}
      </BaseButton>
    </template>
  </BaseDrawer>

  <ClientEditDrawer v-if="showEditDrawer" :client-id="clientId" @close="showEditDrawer = false" />
</template>

<style scoped lang="scss">
.client-details-drawer {
  padding: $spacing-6;
}
</style>
