<script setup lang="ts">
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import DashboardTable from "@/components/shared/Dashboard/DashboardTable.vue";
import { useGetRecentActivity } from "./composables/useGetRecentActivity";

const { t } = useI18n({ useScope: "global" });

const {
  isLoadingData,
  getRecentActivity,
  recentActivityColumns,
  recentActivityModules,
  recentActivitySurveys,
} = useGetRecentActivity();

onMounted(() => getRecentActivity(10));
</script>

<template>
  <AdminHeader :breadcrumbs="[{ title: t('navigation.dashboard') }]" />

  <AdminViewLayout>
    <div class="view-dashboard">
      <DashboardTable
        :is-loading="isLoadingData"
        :title="t('navigation.modules')"
        :columns="recentActivityColumns"
        :rows="recentActivityModules"
      />
      <DashboardTable
        :is-loading="isLoadingData"
        :title="t('sites.surveys')"
        :columns="recentActivityColumns"
        :rows="recentActivitySurveys"
      />
    </div>
  </AdminViewLayout>
</template>

<style scoped lang="scss">
.view-dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: $spacing-8;
}
</style>
