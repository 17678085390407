<template>
  <div class="base-input-error-message">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.base-input-error-message {
  color: $error-4;
  margin-top: $spacing-2;
  line-height: $leading-tight;
}
</style>
