<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { toCamelCase } from "@/helpers/toCamelCase";
import { Status } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

type OptionWithDisabled = Option & { disabled?: boolean };

const props = defineProps<{
  value: Status;
  error?: string;
  requireSpoeCheck: boolean;
  isSpoeApproved: boolean;
  parentsArePublished: boolean;
}>();

const { t } = useI18n({ useScope: "global" });

const emit = defineEmits<{
  "update:value": [Status];
}>();

const options = computed((): readonly OptionWithDisabled[] => {
  const options: OptionWithDisabled[] = Object.keys(Status).map((status) => ({
    title: t(`enums.status.${toCamelCase(status)}`),
    value: status as Status,
  }));

  if (!props.parentsArePublished || (props.requireSpoeCheck && !props.isSpoeApproved)) {
    const publishedOption = options.find((option) => option.value === Status.Published);
    if (publishedOption) publishedOption.disabled = true;
  }

  return options;
});

const errors = computed(() =>
  props.error ? [{ $message: props.error } as ErrorObject] : undefined,
);
</script>

<template>
  <BaseSelect
    :value="value"
    :label="t('enums.statusLabel')"
    :errors="errors"
    @change="emit('update:value', $event as Status)"
  >
    <BaseOption
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="option.disabled"
    >
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
