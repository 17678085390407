<template>
  <hr class="base-divider" />
</template>

<style scoped lang="scss">
.base-divider {
  border: 0;
  border-top: 1px solid $primary-4;
}
</style>
