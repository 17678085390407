import { Directive } from "vue";

/**
 * Makes a table row clickable and keyboard accessible if a condition is met.
 * @example
 * <tr v-row-link="condition">
 *   <!-- row content -->
 * </tr>
 */
const rowLink: Directive<HTMLElement, boolean> = (el, binding) => {
  if (binding.value) {
    el.setAttribute("data-row-link", "true");
    el.setAttribute("tabindex", "0");
    el.setAttribute("role", "button");
  } else {
    el.removeAttribute("data-row-link");
    el.removeAttribute("tabindex");
    el.removeAttribute("role");
  }
};

export { rowLink as vRowLink };
