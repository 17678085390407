<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { bytesToSize } from "@/helpers/bytesToSize";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseButton from "../BaseButton.vue";
import { FileUpload } from "./BaseFileUpload.vue";
import BaseFileUploadThumbnail from "./BaseFileUploadThumbnail.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  file: FileUpload;
  disabled?: boolean;
}>();

defineEmits<{
  (e: "on-rotate", file: FileUpload): void;
  (e: "on-delete", file: FileUpload): void;
}>();

const getImageSrc = (file: FileUpload) => URL.createObjectURL(file.file);
</script>

<template>
  <div
    class="base-upload-file__list__file"
    :class="{
      'base-upload-file__list__file--disabled': disabled,
    }"
  >
    <div class="base-upload-file__list__file__info">
      <BaseFileUploadThumbnail :file="file" :is-image="file.isImage" />
      <div class="base-upload-file__list__file__meta-data">
        <a :href="getImageSrc(file)" :download="file.file.name">
          {{ file.file.name }}
        </a>
        <div>
          {{ bytesToSize(file.file.size) }}
        </div>
      </div>
    </div>
    <div class="base-upload-file__list__file__controls">
      <BaseButton
        v-if="file.isImage"
        variant="text"
        :disabled="disabled"
        title="Rotate"
        @click="$emit('on-rotate', file)"
      >
        <BaseIcon icon="rotate" />
      </BaseButton>

      <BaseButton
        variant="text"
        color="error"
        :disabled="disabled"
        :title="t('common.actions.delete')"
        @click="$emit('on-delete', file)"
      >
        <BaseIcon icon="trash" />
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-upload-file__list__file {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  font-size: $text-sm;
  border-radius: $rounded-base;
  justify-content: space-between;
  gap: $spacing-2;
  padding: $spacing-2;
  border: 1px solid $primary-5;
  background-color: $primary-2;
  margin-bottom: $spacing-2;

  &--disabled {
    opacity: 0.5;
  }

  &__info {
    display: flex;
    gap: $spacing-4;
  }

  &__controls {
    display: flex;
  }

  &__meta-data {
    display: flex;
    gap: $spacing-2;
    flex-direction: column;
  }
}
</style>
