<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useUniqueId } from "@/composables/useUniqueId";
import { BlueColor, SurveyRatingDto } from "@/types/_generated/api";
import BaseAutoSaveStatusVue, { State } from "@/components/base/BaseAutoSaveStatus.vue";

interface Props {
  rating: SurveyRatingDto;
  id: number;
  value: BlueColor;
  disabled: boolean;
  setReplySaveState: State;
}

const props = defineProps<Props>();

defineEmits<{
  (event: "update:value", value: BlueColor): void;
}>();

const { t } = useI18n({ useScope: "global" });

const uid = useUniqueId("bluerating_question_card_field");

const getDisabledRatingColorMessage = (rating: SurveyRatingDto): string => {
  switch (rating.color) {
    case "Blue":
      return t("blueRating.category.questions.disableSetRatingBlueColor");
    case "Gray":
      return t("blueRating.category.questions.disableSetRatingGreyColor");

    default:
      return "Unknown color rating.";
  }
};

const checked = computed(() => props.rating.color === props.value);

const isDisabled = computed(() => props.disabled || props.setReplySaveState === "saving");
</script>

<template>
  <div
    class="blue-rating-question-card__field"
    :class="{
      'blue-rating-question-card__field--checked': checked,
      [`blue-rating-question-card__field--${rating.color.toLowerCase()}`]: checked,
    }"
    :disabled="isDisabled || undefined"
    :title="isDisabled ? getDisabledRatingColorMessage(rating) : rating.text"
    @click="isDisabled ? void 0 : $emit('update:value', rating.color)"
  >
    <input
      v-if="!checked || setReplySaveState === 'idle'"
      :id="uid"
      :name="id.toString()"
      type="radio"
      :class="{
        [`blue-rating-question-card__field__input`]: true,
        [`blue-rating-question-card__field__input--${rating.color.toLowerCase()}`]: true,
      }"
      :disabled="disabled"
      :checked="checked"
    />
    <BaseAutoSaveStatusVue
      v-if="checked && setReplySaveState !== 'idle'"
      :state="setReplySaveState"
      :show-label="false"
    />
    <div class="blue-rating-question-card__field__text">
      {{ `${rating.text}` }}
    </div>
    <div class="blue-rating-question-card__field__text">
      {{ `${rating.score}p` }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-question-card__field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-4;
  cursor: pointer;
  background-color: $primary-1;
  transition: opacity $duration-100;

  &__text {
    user-select: none;
  }

  &[disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled="true"]):hover {
    opacity: 0.8;
  }

  &__input {
    background: inherit;

    &:after {
      display: inline-block;
      position: relative;
      top: -2.5px;
      left: -2px;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      content: "";
      border: 2px solid white;
      background: inherit;
    }

    &:checked {
      &:after {
        border: 2px solid $primary-0;
      }
    }
  }

  &--checked {
    color: $primary-0;
  }

  @include blue-rating-styles;

  &__input {
    @include blue-rating-styles;
  }
}
</style>
