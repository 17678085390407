<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useGetModule } from "@/composables/services/useGetModule";
import { Tab, useTabs } from "@/composables/useTabs";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { updateModuleService } from "@/services/modules";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import { useModuleUsers } from "../../../composables/useModuleUsers";
import { useModuleForm } from "./composables/useModuleForm";
import { useModuleValidation } from "./composables/useModuleValidation";
import { ModuleEditTab } from "./constants/ModuleEditTab";
import ModuleEditDrawerBlueRating from "./ModuleEditDrawerBlueRating.vue";
import ModuleEditDrawerEmailTemplates from "./ModuleEditDrawerEmailTemplates.vue";
import ModuleEditDrawerGeneral from "./ModuleEditDrawerGeneral.vue";
import ModuleEditDrawerLossScenarios from "./ModuleEditDrawerLossScenarios.vue";
import ModuleEditDrawerSiteDefinitions from "./ModuleEditDrawerSiteDefinitions.vue";
import ModuleEditDrawerUserWeb from "./ModuleEditDrawerUserWeb.vue";

const props = defineProps<{
  moduleId: number;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });

const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();
const { isLoadingModule, module, getModule } = useGetModule();
const { isLoadingUsers, contactUsers, accountEngineerUsers, fetchUsers } = useModuleUsers();
const { errorMessage, tabsWithErrors, resetErrorMessage, isFormValid } = useModuleValidation();
const { moduleForm, updateModuleForm, updateModuleField } = useModuleForm(resetErrorMessage);

const isLoading = computed(() => isLoadingModule.value || isLoadingUsers.value);

const getTabIcon = (tab: ModuleEditTab) => (tabsWithErrors.value.has(tab) ? "warning" : undefined);

const tabs = computed<Tab<ModuleEditTab>[]>(() => {
  return [
    {
      label: t("blueRating.recommendation.general"),
      name: ModuleEditTab.General,
      icon: getTabIcon(ModuleEditTab.General),
    },
    {
      label: t("modules.siteDefinition"),
      name: ModuleEditTab.SiteDefinition,
      icon: getTabIcon(ModuleEditTab.SiteDefinition),
    },
    {
      label: t("modules.moduleSettingsBlueRating"),
      name: ModuleEditTab.BlueRating,
      icon: getTabIcon(ModuleEditTab.BlueRating),
    },
    {
      label: t("modules.userWeb"),
      name: ModuleEditTab.UserWeb,
      icon: getTabIcon(ModuleEditTab.UserWeb),
    },
    {
      label: t("modules.emailTemplates"),
      name: ModuleEditTab.EmailTemplates,
      icon: getTabIcon(ModuleEditTab.EmailTemplates),
    },
    {
      label: t("modules.lossScenarioDefinitions"),
      name: ModuleEditTab.LossScenarioDefinitions,
      icon: getTabIcon(ModuleEditTab.LossScenarioDefinitions),
    },
  ];
});

const { activeTab, changeTab } = useTabs(tabs.value, ModuleEditTab.General);

const closeDrawer = async () => {
  if (await discardUnsavedChanges(moduleForm.value)) {
    emit("closeDrawer");
  }
};

const saveChanges = async () => {
  if (!moduleForm.value) return;
  if (!isFormValid(moduleForm.value)) return;

  await updateModuleService(moduleForm.value);
  setCompareBase(moduleForm.value);
  emit("closeDrawer");
};

onMounted(async () => {
  await getModule(props.moduleId);
  if (!module.value) return;

  updateModuleForm(module.value);

  await fetchUsers(module.value);

  setCompareBase(module);
});
</script>

<template>
  <BaseDrawer
    width="auto"
    :is-loading="isLoading"
    :title="t('modules.editModule')"
    class="edit-module-drawer"
    @close="closeDrawer"
  >
    <BaseDrawerTabs :current-tab="activeTab" :tabs="tabs" @change="changeTab" />

    <div v-if="moduleForm" class="edit-module-drawer__container">
      <ModuleEditDrawerGeneral
        v-show="activeTab === ModuleEditTab.General"
        :module-form="moduleForm"
        :contacts="contactUsers"
        :account-engineers="accountEngineerUsers"
        :error-message="errorMessage"
        @update:field="updateModuleField"
      />

      <ModuleEditDrawerSiteDefinitions
        v-show="activeTab === ModuleEditTab.SiteDefinition"
        :module-form="moduleForm"
        :error-message="errorMessage"
        @update:field="updateModuleField"
      />

      <ModuleEditDrawerBlueRating
        v-show="activeTab === ModuleEditTab.BlueRating"
        :module-form="moduleForm"
        :error-message="errorMessage"
        @update:field="updateModuleField"
      />

      <ModuleEditDrawerUserWeb
        v-show="activeTab === ModuleEditTab.UserWeb"
        :module-form="moduleForm"
        :error-message="errorMessage"
        @update:field="updateModuleField"
      />

      <ModuleEditDrawerEmailTemplates
        v-show="activeTab === ModuleEditTab.EmailTemplates"
        :module-form="moduleForm"
        :error-message="errorMessage"
        @update:field="updateModuleField"
      />

      <ModuleEditDrawerLossScenarios
        v-show="activeTab === ModuleEditTab.LossScenarioDefinitions"
        :module-form="moduleForm"
        @update:field="updateModuleField"
      />
    </div>

    <template #footer-left>
      <BaseButton @click="saveChanges">
        {{ t("common.actions.save") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.edit-module-drawer {
  @include grid-columns(1);
  max-width: max(1000px, 40vw);

  &__container {
    padding: $spacing-4 $spacing-6;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }
}
</style>
