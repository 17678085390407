import { SurveyorStatus, type BlueRatingRecommendationDto } from "@/types/_generated/api";

/**
 * Hides recommendations with statuses Completed, Withdrawn and Abeyance
 * and returns the rest sorted by color, age, category, key recommendation, and abeyance status
 * @param recommendations
 * @param categories
 * @param showAbeyance Include recommendations with status Abeyance
 * @param showCompleted Include recommendations with status Completed
 * @returns
 */
export const filterRecommendationsForReports = (
  recommendations: BlueRatingRecommendationDto[],
  showAbeyance: boolean = false,
  showCompleted: boolean = false,
) => {
  const notAllowedStatuses = [SurveyorStatus.Withdrawn];

  if (!showAbeyance) {
    notAllowedStatuses.push(SurveyorStatus.Abeyance);
  }

  if (!showCompleted) {
    notAllowedStatuses.push(SurveyorStatus.Completed);
  }

  return recommendations.filter((r) => !notAllowedStatuses.includes(r.surveyorStatus));
};
