import { computed, type ComputedRef } from "vue";
import type { ExecutiveReportSurveyDto } from "@/types/_generated/api";
import type { SurveyGroup } from "../types/SurveyGroup";

export type SurveyRow = { survey: ExecutiveReportSurveyDto };
export type TableRow = { label: string } | SurveyRow;

export const useTableRows = (
  groupBy: ComputedRef<boolean>,
  groupedSurveys: ComputedRef<SurveyGroup[]>,
  surveys: ComputedRef<ExecutiveReportSurveyDto[]>,
) => {
  const tableRows = computed<TableRow[]>(() => {
    if (groupBy.value) {
      return groupedSurveys.value.flatMap((group) => [
        { label: group.groupName },
        ...group.groupSurveys.map((survey) => ({ survey })),
      ]);
    } else {
      return surveys.value.map((survey) => ({ survey }));
    }
  });

  return { tableRows };
};
