import { computed } from "vue";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";

export const useAuth = () => {
  const app = useAppStore();
  const auth = useAuthStore();

  const isFullyAuthenticated = computed(
    () => auth.isAuthenticated && app.user?.hasAcceptedTermsOfService,
  );

  return { isFullyAuthenticated };
};
