<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { toDegreesMinutesAndSeconds } from "@/helpers/coordinateConverter";
import { displayValue, emptyValue } from "@/helpers/displayValue";
import { formatLink } from "@/helpers/url";
import { SiteDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BasePhoneLink from "@/components/base/BasePhoneLink.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

interface Props {
  isLoadingSite: boolean;
  site: SiteDto | null;
}

interface ExtendedSiteDto extends SiteDto {
  coordinates: string;
}

defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const siteFields: Array<keyof ExtendedSiteDto> = [
  "address",
  "poBox",
  "zipCode",
  "city",
  "country",
  "phone",
  "web",
  "status",
  "latitude",
  "longitude",
  "coordinates",
];
</script>

<template>
  <BaseDataDisplayList :title="t('sites.siteInfo')">
    <template v-for="field in siteFields" :key="field">
      <BaseDataDisplayListItem :is-loading="isLoadingSite" :label="t('sites.' + field)">
        <template v-if="field === 'phone'">
          <BasePhoneLink v-if="site?.phone" :label="site.phone" :phone="site.phone" />
          <p v-else>{{ emptyValue }}</p>
        </template>

        <template v-else-if="field === 'web'">
          <a
            v-if="site?.web"
            :href="formatLink(site.web)"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ site.web }}
          </a>
          <p v-else>{{ emptyValue }}</p>
        </template>

        <template v-else-if="field === 'coordinates'">
          <p v-if="site?.latitude && site?.longitude">
            {{ toDegreesMinutesAndSeconds(site.latitude, site.longitude) }}
          </p>
          <p v-else>{{ emptyValue }}</p>
        </template>

        <template v-else-if="field === 'status'">
          <BaseStatusBadge v-if="site?.status" :status="site?.status" />
          <p v-else>{{ emptyValue }}</p>
        </template>

        <template v-else>
          <p>{{ displayValue(site?.[field]) }}</p>
        </template>
      </BaseDataDisplayListItem>
    </template>
  </BaseDataDisplayList>
</template>
