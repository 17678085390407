import { type Ref, reactive, computed } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import {
  type ExecutiveReportSurveyDto,
  type SearchExecutiveSummaryResponse,
} from "@/types/_generated/api";
import { ExecSummaryClientFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";

export const useFilterClient = (
  execSummaryData: Ref<SearchExecutiveSummaryResponse | undefined>,
) => {
  const clientFilter = reactive<ExecSummaryClientFilter>(new ExecSummaryClientFilter());

  const updateClientFilter = (newFilter: ExecSummaryClientFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(clientFilter, newFilter)) return;
    // Use setTimeout to delay the filter update until after the drawer animation completes
    setTimeout(() => {
      // Update the filter state with the incoming new values
      Object.assign(clientFilter, newFilter);
    }, 200);
  };

  const filterSurveyType = (surveys: ExecutiveReportSurveyDto[]) => {
    // "WEXT" is the default value for the surveyType filter and means no filtering
    if (clientFilter.surveyType === "WEXT") return surveys;

    return surveys.filter((survey) => survey.type === clientFilter.surveyType);
  };

  const filteredData = computed(() => {
    if (!execSummaryData.value) return execSummaryData.value;

    const dataCopy = {
      ...execSummaryData.value,
      categories: [...execSummaryData.value.categories],
      surveys: [...execSummaryData.value.surveys],
    };

    dataCopy.surveys = filterSurveyType(dataCopy.surveys);

    return dataCopy;
  });

  return {
    filteredData,
    clientFilter: computed(() => clientFilter),
    updateClientFilter,
  };
};
