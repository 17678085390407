import { BaseExecSummaryExcelRequest } from "@/types/_generated/api";
import { FilterColumn } from "../constants/FilterColumn";
import { ExecSummaryClientFilter, ExecSummaryServerFilter } from "../types/FilterTypes";

export const mergeFilters = (
  moduleId: number,
  clientFilter: ExecSummaryClientFilter,
  serverFilter: ExecSummaryServerFilter,
): BaseExecSummaryExcelRequest => {
  return {
    moduleId,
    filter: {
      ...serverFilter,
      surveyType: clientFilter.surveyType,
      siteIds: [],
      includeOnlyLatestSurvey: true,
    },
    showScore: clientFilter.selectedColumns.has(FilterColumn.Score),
    showScorePercent: clientFilter.selectedColumns.has(FilterColumn.Percentage),
  };
};
