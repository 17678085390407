import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { DraftProperties } from "@/composables/useQuestionnaireDraftClient";
import { questionnaireDraftEvents } from "@/services/questionnaire-draft";
import { QuestionnaireDraftActionType, QuestionnaireDto } from "@/types/_generated/api";
import { InsertPoint } from "@/types/InsertPoint";

export const useQuestionnaireDraftStore = defineStore("questionnaireDraftStore", () => {
  // Draft
  const draft = ref<QuestionnaireDto | null>(null);

  // Draft auto-saving
  const currentAutoSaves = ref<string[]>([]);
  const isAutoSaving = computed(() => currentAutoSaves.value.length > 0);

  const addAutoSave = (id: string) => {
    if (!currentAutoSaves.value.includes(id)) {
      currentAutoSaves.value = [...currentAutoSaves.value, id];
    }
  };
  const removeAutoSave = (id: string) =>
    (currentAutoSaves.value = currentAutoSaves.value.filter((i) => i !== id));

  // Global History Drawer
  const showGlobalHistoryDrawer = ref(false);

  // Template Drawer
  const showTemplateDrawer = ref(false);
  const templateInsertPoint = ref<InsertPoint>("Questionnaire");
  const templateInsertIntoId = ref<number>(0);

  // Subscribe to events
  questionnaireDraftEvents.subscribe(
    (event) => {
      if (
        event.action !== "update" ||
        !draft.value ||
        !event.questionnaire ||
        draft.value.questionnaireId !== event.questionnaire.questionnaireId
      ) {
        return;
      }
      draft.value = event.questionnaire;
    },
    ["update"],
  );

  // Property History Drawer
  const propertyHistory = reactive<{
    show: boolean;
    property: DraftProperties | null;
    type: QuestionnaireDraftActionType | null;
    targetName: string | null;
    targetActionId: number | null;
  }>({
    show: false,
    property: null,
    type: null,
    targetName: null,
    targetActionId: null,
  });

  const closePropertyHistory = () => {
    propertyHistory.show = false;
    propertyHistory.property = null;
    propertyHistory.type = null;
    propertyHistory.targetName = null;
    propertyHistory.targetActionId = null;
  };

  const openPropertyHistory = (
    actionId: number,
    type: QuestionnaireDraftActionType,
    property: DraftProperties,
    targetName: string,
  ) => {
    propertyHistory.show = true;
    propertyHistory.property = property;
    propertyHistory.type = type;
    propertyHistory.targetName = targetName;
    propertyHistory.targetActionId = actionId;
  };

  return {
    draft,
    autoSave: {
      isAutoSaving,
      addAutoSave,
      removeAutoSave,
    },
    showGlobalHistoryDrawer,
    templateDrawer: {
      show: (insertPoint: InsertPoint, insertIntoId: number) => {
        templateInsertPoint.value = insertPoint;
        templateInsertIntoId.value = insertIntoId;
        showTemplateDrawer.value = true;
      },
      hide: () => {
        showTemplateDrawer.value = false;
      },
      insertPoint: templateInsertPoint,
      insertIntoId: templateInsertIntoId,
      visible: showTemplateDrawer,
    },
    propertyHistory,
    closePropertyHistory,
    openPropertyHistory,
    $reset: () => {
      draft.value = null;
      currentAutoSaves.value = [];
      showGlobalHistoryDrawer.value = false;
      showTemplateDrawer.value = false;
      templateInsertPoint.value = "Questionnaire";
      templateInsertIntoId.value = 0;
      closePropertyHistory();
    },
  };
});
