<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { RecRespRouteName } from "@/router/RouteName";
import { useNotificationsStore } from "@/stores/notifications";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { MfaSelectionResult } from "../types";
import { verifyMfaCodeService } from "../services/auth";

defineProps<{
  verification: MfaSelectionResult;
}>();

const { t } = useI18n({ useScope: "global" });
const router = useRouter();
const notifications = useNotificationsStore();

const mfaCode = ref<string>("");

const isSubmitDisabled = computed(() => {
  return mfaCode.value.length !== 6;
});

const submitMfa = async () => {
  notifications.toasts = [];

  const success = await verifyMfaCodeService(mfaCode.value);

  if (success) {
    router.push({ name: RecRespRouteName.Summary });
  } else {
    notifications.addToast(
      "error",
      t("recResponse.mfa.failedVerification.title"),
      t("recResponse.mfa.failedVerification.description"),
    );
  }
};
</script>

<template>
  <div class="login-mfa">
    <BaseAlert severity="info">
      {{
        t("signIn.2fa.alert", {
          value: verification.expirationTimeHours,
          unit: t("signIn.2fa.timeUnits.hours"),
        })
      }}
    </BaseAlert>

    <form class="login-mfa__form" @submit.prevent="submitMfa">
      <div>
        <BaseLabel has-spacing>{{ t("signIn.2fa.code") }}</BaseLabel>
        <BaseTextField
          autofocus
          autocomplete="one-time-code"
          :value="mfaCode"
          @update:value="mfaCode = String($event)"
        />
      </div>

      <BaseButton type="submit" :disabled="isSubmitDisabled">
        {{ t("common.actions.confirm") }}
      </BaseButton>
    </form>
  </div>
</template>

<style scoped lang="scss">
.login-mfa {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
