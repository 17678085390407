<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDebounce } from "@/composables/useDebounce";
import { bytesToSize } from "@/helpers/bytesToSize";
import { toBase64 } from "@/helpers/toBase64";
import { RecommendationImageDto, RecommendationImageMetaDataDto } from "@/types/_generated/api";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";
import BaseImage from "@/components/base/BaseImage.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseButton from "../base/BaseButton.vue";
import BaseIcon from "../base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  disabled?: boolean;
  recommendationId: number;
  images: RecommendationImageMetaDataDto[];
  url: string;
  src: string;
}>();

const emit = defineEmits<{
  delete: [item: RecommendationImageMetaDataDto];
  "files-uploaded": [void];
  "update:caption": [item: RecommendationImageMetaDataDto];
  "update:position": [fileId: string, currentIndex: number, newIndex: number];
}>();

const getBody = async (files: FileUpload[]) => {
  const images = [];

  for (const file of files) {
    images.push({
      fileName: file.file.name,
      file: await toBase64(file.file),
      rotation: file.rotation,
    } as RecommendationImageDto);
  }

  return {
    recommendationId: props.recommendationId,
    images,
  };
};

const debounce = useDebounce();

const updateCaption = (value: string, item: RecommendationImageMetaDataDto) => {
  item.caption = value;
  debounce(() => emit("update:caption", item));
};
</script>
<template>
  <div class="rec-image-list">
    <BaseFileUpload
      :disabled="disabled"
      :body="getBody"
      :max-files="5"
      :file-type="FileFormat.Image"
      :url="url"
      @files-uploaded="$emit('files-uploaded')"
    />

    <BaseCard :title="t('blueRating.recommendation.uploadedPhotos')">
      <div class="rec-image-list__uploaded">
        <div
          v-for="(item, index) in images"
          :key="item.fileId"
          class="rec-image-list__uploaded__item"
        >
          <div class="rec-image-list__uploaded__item__controls">
            <div class="rec-image-list__uploaded__item__controls__move">
              <BaseButton
                v-if="index != 0"
                :title="t('common.actions.moveUp')"
                :disabled="disabled"
                size="small"
                variant="outlined"
                @click="emit('update:position', item.fileId, index, index - 1)"
              >
                <BaseIcon icon="arrow-up" />
              </BaseButton>
              <BaseButton
                v-if="index != images.length - 1"
                :title="t('common.actions.moveDown')"
                :disabled="disabled"
                size="small"
                variant="outlined"
                @click="emit('update:position', item.fileId, index, index + 1)"
              >
                <BaseIcon icon="arrow-down" />
              </BaseButton>
            </div>
            <BaseButton
              :title="t('common.actions.delete')"
              :disabled="disabled"
              size="small"
              variant="outlined"
              color="error"
              @click="emit('delete', item)"
            >
              <BaseIcon icon="trash" />
            </BaseButton>
          </div>

          <BaseImage
            class="rec-image-list__uploaded__item__image"
            :src="`${src}${item.fileId}`"
            :alt="item.caption"
            :max-width="'200px'"
            :max-height="'200px'"
            :title="item.fileName"
          >
          </BaseImage>

          <div class="rec-image-list__uploaded__item__caption">
            <BaseTextArea
              :disabled="disabled"
              :maxlength="200"
              :value="item.caption"
              :placeholder="t('blueRating.recommendation.imageCaption')"
              @update:value="updateCaption($event as string, item)"
            />

            <small>
              {{ bytesToSize(item.fileSize) }}
            </small>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<style scoped lang="scss">
.rec-image-list {
  padding: $spacing-4;

  &__uploaded {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;

    &__item {
      display: flex;
      background-color: white;
      background-color: $primary-0;
      padding: $spacing-3;
      border: 1px solid $primary-6;
      border-radius: $rounded-base;
      gap: $spacing-4;

      &__caption {
        width: 100%;
      }

      &__image {
        &:hover {
          cursor: zoom-in;
        }
      }

      &__controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__move {
          display: flex;
          flex-direction: column;
          gap: $spacing-1;
        }
      }
    }
  }
}
</style>
