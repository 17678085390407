<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 4.75C0 4.33579 0.335786 4 0.75 4H2.25C3.76878 4 5 5.23122 5 6.75V9.25C5 10.7688 3.76878 12 2.25 12H0.75C0.335786 12 0 11.6642 0 11.25V4.75ZM1.5 5.5V10.5H2.25C2.94036 10.5 3.5 9.94038 3.5 9.25V6.75C3.5 6.05964 2.94036 5.5 2.25 5.5H1.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6.5C6 5.11929 7.11929 4 8.5 4C9.88071 4 11 5.11929 11 6.5V9.5C11 10.8807 9.88071 12 8.5 12C7.11929 12 6 10.8807 6 9.5V6.5ZM8.5 5.5C7.94771 5.5 7.5 5.94771 7.5 6.5V9.5C7.5 10.0523 7.94771 10.5 8.5 10.5C9.05229 10.5 9.5 10.0523 9.5 9.5V6.5C9.5 5.94771 9.05229 5.5 8.5 5.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6.75C12 5.23122 13.2312 4 14.75 4H15.25C15.6642 4 16 4.33579 16 4.75C16 5.16421 15.6642 5.5 15.25 5.5H14.75C14.0596 5.5 13.5 6.05964 13.5 6.75V9.25C13.5 9.94039 14.0596 10.5 14.75 10.5H15.25C15.6642 10.5 16 10.8358 16 11.25C16 11.6642 15.6642 12 15.25 12H14.75C13.2312 12 12 10.7688 12 9.25V6.75Z"
    />
  </svg>
</template>
