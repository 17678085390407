import { AxiosResponse } from "axios";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  BlueColor,
  CalculateCategoryColorResponse,
  BlueRatingDto,
  GetBlueRatingResponse,
  GetBlueRating,
} from "@/types/_generated/api";
import { BaseCRUDMessage, BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const surveyCategoryEvents = new BroadcastService<BaseCRUDMessage>("survey-category");
export const surveyReplyEvents = new BroadcastService<
  BaseCRUDMessage & { categoryId: number } // categoryId is used to identify the category when re-calculating category color
>("survey-replies");

const { t } = i18n.global;

export const getBlueRatingService = async (surveyId: number): Promise<BlueRatingDto | null> => {
  try {
    const {
      data: { blueRating },
    } = await http.get<GetBlueRatingResponse, AxiosResponse<GetBlueRatingResponse, GetBlueRating>>(
      `/surveys/${surveyId}/blue-rating`,
    );

    return blueRating;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("surveys.survey"),
    });
  }

  return null;
};

export const saveCategoryNotesService = async (
  surveyId: number,
  categoryId: number,
  notes: string,
): Promise<boolean> => {
  try {
    await http.put(`/surveys/${surveyId}/notes/${categoryId}`, { notes });

    surveyCategoryEvents.post({ action: "update" });
    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("blueRating.category.notes"),
    });
  }
  return false;
};

export const saveReplyService = async (
  surveyId: number,
  categoryId: number,
  questionId: number,
  value: BlueColor,
): Promise<boolean> => {
  try {
    await http.put(`/surveys/${surveyId}/reply/${questionId}`, {
      questionId,
      value,
    });

    surveyReplyEvents.post({ action: "update", categoryId });

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.save").toLowerCase(),
      entity: t("blueRating.recommendation.reply"),
    });
  }
  return false;
};

export const calculateCategoryColorService = async (
  surveyId: number,
  categoryId: number,
): Promise<BlueColor | null> => {
  try {
    const {
      data: { color },
    } = await http.get<CalculateCategoryColorResponse>(
      `/surveys/${surveyId}/category-color/${categoryId}`,
    );

    return color;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("blueRating.recommendation.properties.color"),
    });
  }

  return null;
};
