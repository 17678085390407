import { ref } from "vue";
import { getSurveyService } from "@/services/surveys";
import {
  LossScenarioDto,
  Status,
  SurveyInsuredValueDto,
  SurveyParticipantDto,
  SurveyDto,
} from "@/types/_generated/api";

export type SurveySettings = {
  surveyorUserId: number | undefined;
  spoeCheckerUserId: number | undefined;
  surveyDate: string;
  participants: SurveyParticipantDto[];
  recommendationResponseRecipients: number[];
  copyDataFromPreviousSurvey: boolean;
  status: Status | undefined;
  insuredValues: SurveyInsuredValueDto[];
};

export const useInitializeSurveySettings = () => {
  const isInitializing = ref(false);
  const survey = ref<SurveyDto | null>(null);

  const initializeSurveySettings = async (
    surveyId?: number | null,
    lossScenarios?: LossScenarioDto[] | null,
    currentUserId?: number | null,
  ) => {
    isInitializing.value = true;

    const surveySettings: SurveySettings = {
      surveyorUserId: currentUserId ?? undefined,
      spoeCheckerUserId: undefined,
      surveyDate: new Date().toISOString(),
      participants: [],
      recommendationResponseRecipients: [],
      copyDataFromPreviousSurvey: false,
      status: undefined,
      insuredValues: [],
    };

    if (surveyId) {
      const response = await getSurveyService(surveyId);

      survey.value = response;

      if (response) {
        surveySettings.surveyorUserId = response.surveyorUserId ?? currentUserId!;
        surveySettings.spoeCheckerUserId = response.spoeCheckerUserId;
        surveySettings.surveyDate = response.surveyDate;
        surveySettings.participants = response.participants;
        surveySettings.recommendationResponseRecipients = response.recommendationResponseRecipients;
        surveySettings.copyDataFromPreviousSurvey = false;
        surveySettings.status = response.status;
        surveySettings.insuredValues = response.insuredValues ?? [];
      }
    }

    // Initialize insured values for each loss scenario
    surveySettings.insuredValues = (lossScenarios || [])?.map((ls) => {
      const existingInsuredValue = surveySettings.insuredValues?.find(
        (iv) => iv.lossScenarioId === ls.id,
      );

      if (existingInsuredValue) {
        return existingInsuredValue;
      }

      return {
        surveyId: surveyId ?? 0,
        lossScenarioId: ls.id!,
        propertyDamage: 0,
        businessInterruption: 0,
      };
    });

    isInitializing.value = false;

    return surveySettings;
  };

  return {
    initializeSurveySettings,
    isInitializing,
    survey,
  };
};
