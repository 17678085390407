<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { UserLeanDto } from "@/types/_generated/api";
import { useGetModuleUsersOptionsGroups } from "@/views/Admin/Sites/composables/useModuleUsersOptionsGroups";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseOptionGroup from "@/components/base/BaseSelect/BaseOptionGroup.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    value?: number;
    placeholder?: string;
    label?: string;
    required?: boolean;
    clearable?: boolean;
    errorMessage?: string;
    moduleUsers?: UserLeanDto[] | null;
    disabled?: boolean;
    filter?: (option: UserLeanDto) => boolean;
  }>(),
  {
    value: undefined,
    placeholder: undefined,
    label: "",
    required: false,
    errorMessage: "",
    moduleUsers: () => [],
    filter: undefined,
  },
);

defineEmits<{
  "update:value": [number];
}>();

const moduleUserOptions = useGetModuleUsersOptionsGroups(
  toRef(() => props.moduleUsers),
  props.filter,
);

const errors = computed(() =>
  props.errorMessage ? [{ $message: props.errorMessage } as ErrorObject] : undefined,
);
</script>

<template>
  <BaseSelect
    :value="value"
    :options="moduleUserOptions"
    :label="label"
    :errors="errors"
    :required="required"
    :disabled="disabled"
    @change="$emit('update:value', parseInt($event as string))"
  >
    <BaseOption :value="''" :disabled="!clearable">
      {{ placeholder ?? t("app.components.moduleUsersSelect.selectUser") }}
    </BaseOption>
    <BaseOptionGroup
      v-for="{ group, options } in moduleUserOptions"
      :key="group"
      :label="t(`users.roles.${group}`)"
    >
      <BaseOption v-for="option in options" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseOptionGroup>
  </BaseSelect>
</template>
