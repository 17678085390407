<script setup lang="ts">
import { ref } from "vue";

defineProps<{
  disabled?: boolean;
}>();

const emit = defineEmits<{
  onFilesDropped: [FileList];
}>();

const isOverDropArea = ref(0);

const onDragEnter = () => isOverDropArea.value++;

const onDragLeave = () => isOverDropArea.value--;

const onDrop = (event: DragEvent) => {
  isOverDropArea.value = 0;

  const files = event.dataTransfer?.files;

  if (files) {
    emit("onFilesDropped", files);
  }
};
</script>

<template>
  <div
    :class="{
      'base-file-area-dropzone': true,
      'base-file-area-dropzone--disabled': disabled,
      'base-file-area-dropzone--drag-over': isOverDropArea && !disabled,
    }"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent
    @drop.prevent="onDrop"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.base-file-area-dropzone {
  display: flex;
  position: relative;
  border-radius: $rounded-base;
  padding: $spacing-6;
  gap: $spacing-6;
  flex-direction: column;
  align-items: center;
  border: 2px dashed $primary-4;
  background-color: $primary-2;
  transition: all 0.2s ease-in-out;

  &--drag-over {
    border: 2px dashed $primary-6;
    background-color: $primary-3;
  }

  &--disabled {
    opacity: 0.5;
  }

  &__text {
    color: $primary-6;
    font-size: $text-lg;
    line-height: $leading-normal;
    text-align: center;
  }
}
</style>
