<script setup lang="ts">
import { type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import type { UpdateSite } from "@/types/_generated/api";
import { toDegreesMinutesAndSeconds } from "@/helpers/coordinateConverter";
import BaseMap from "@/components/base/BaseMap/BaseMap.vue";
import { UpdateSiteField } from "./types";

defineProps<{
  siteForm: DeepReadonly<UpdateSite>;
}>();

const emit = defineEmits<{
  "update:field": UpdateSiteField;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="site-edit-map">
    <p v-if="siteForm.latitude && siteForm.longitude" class="site-edit-map__coordinates">
      {{
        toDegreesMinutesAndSeconds(siteForm.latitude, siteForm.longitude) ??
        t("map.status.invalidCoordinates")
      }}
    </p>

    <BaseMap
      height="small"
      movable-pins
      :pins="[{ latitude: siteForm.latitude, longitude: siteForm.longitude }]"
      @update:pin="
        (event) => {
          emit('update:field', 'latitude', event.latitude);
          emit('update:field', 'longitude', event.longitude);
        }
      "
    />
  </div>
</template>

<style scoped lang="scss">
.site-edit-map {
  position: relative;

  &__coordinates {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: $spacing-2;
    background-color: rgba($primary-0, 0.5);
    font-size: $text-sm;
    z-index: $z-10;
  }
}
</style>
