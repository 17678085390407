import { AxiosResponse } from "axios";
import type {
  RecommendationTrackingFilter,
  SearchRecTracking,
  SearchRecTrackingResponse,
} from "@/types/_generated/api";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const searchRecommendationTrackingService = async (
  moduleId: number,
  filter: RecommendationTrackingFilter,
  siteId?: number,
): Promise<SearchRecTrackingResponse | null> => {
  try {
    const { data } = await http.post<
      SearchRecTrackingResponse,
      AxiosResponse<SearchRecTrackingResponse, SearchRecTracking>,
      SearchRecTracking
    >(`/modules/${moduleId}/reports/recommendation-tracking`, {
      moduleId,
      filter,
      siteId,
    });

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("userWeb.recommendationTracking.recommendationTracking"),
    });
  }
  return null;
};
