import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  GetSiteExemptionsResponse,
  CreateSiteExemption,
  CreateSiteExemptionResponse,
  UpdateSiteExemption,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const siteExemptionEvents = new BroadcastService("siteExemptions");

export const getExemptions = async (
  siteId: number,
): Promise<GetSiteExemptionsResponse["exemptions"] | null> => {
  try {
    const {
      data: { exemptions },
    } = await http.get<GetSiteExemptionsResponse>(`/sites/${siteId}/exemptions`);
    return exemptions;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("sites.exemptions.exemptions"),
    });
  }
  return null;
};

export const createExemptionService = async (
  entity: CreateSiteExemption,
): Promise<number | null> => {
  try {
    const {
      data: { exemptionId },
    } = await http.post<CreateSiteExemptionResponse>(`/sites/${entity.siteId}/exemptions`, entity);

    siteExemptionEvents.post({ action: "create", id: exemptionId });

    notify.success(t("sites.exemptions.exemption"), t("common.created").toLowerCase());

    return exemptionId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("sites.exemptions.exemption"),
    });
  }
  return null;
};

export const updateExemptionService = async (entity: UpdateSiteExemption): Promise<boolean> => {
  try {
    await http.put(`/sites/${entity.siteId}/exemptions/${entity.exemptionId}`, entity);

    siteExemptionEvents.post({ action: "update", id: entity.exemptionId });

    notify.success(t("sites.exemptions.exemption"), t("common.updated").toLowerCase());

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("sites.exemptions.exemption"),
    });
  }
  return false;
};

export const deleteExemption = async (siteId: number, exemptionId: number): Promise<boolean> => {
  try {
    await http.delete(`/sites/${siteId}/exemptions/${exemptionId}`);

    siteExemptionEvents.post({ action: "delete", id: exemptionId });

    notify.success(t("sites.exemptions.exemption"), t("common.deleted").toLowerCase());

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("sites.exemptions.exemption"),
    });
  }
  return false;
};
