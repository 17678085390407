import { computed, Ref } from "vue";
import { filterRecommendationsForReports } from "@/helpers/recommendationSorting";
import {
  BlueRatingDto,
  BlueRatingRecommendationDto,
  SiteResponseStatus,
  SurveyorStatus,
} from "@/types/_generated/api";

export const useRecommendationsToRespond = (
  blueRating: Ref<BlueRatingDto | null>,
  recommendations: Ref<BlueRatingRecommendationDto[]>,
  includeAbeyance: Ref<boolean>,
) => {
  const categories = computed(() => blueRating.value?.areas.flatMap((a) => a.categories) ?? []);

  // Filtered list of recommendations to act as a source for all lists
  const visibleSortedRecs = computed(() =>
    filterRecommendationsForReports(recommendations.value, true, false),
  );

  // Your normal recommendations that needs or has a response
  const recs = computed(() =>
    visibleSortedRecs.value.filter(
      (r) =>
        r.surveyorStatus !== SurveyorStatus.Abeyance &&
        r.responseHistory.history[0]?.status !== SiteResponseStatus.Completed,
    ),
  );

  const allCompletedRecs = computed(() =>
    visibleSortedRecs.value.filter(
      (r) =>
        r.surveyorStatus !== SurveyorStatus.Abeyance &&
        r.responseHistory.history[0]?.status === SiteResponseStatus.Completed,
    ),
  );

  // Recommendations that has been responded as completed and are not abeyance
  const respondedCompletedRecs = computed(() =>
    allCompletedRecs.value.filter((r) => r.responseHistory.history[0]?.locked !== true),
  );

  // Abeyance recommendations where response is optional
  const abeyanceRecs = computed(() =>
    visibleSortedRecs.value.filter((r) => r.surveyorStatus === SurveyorStatus.Abeyance),
  );

  // Number of responded abeyance recommendations
  const respondedAbeyanceRecCount = computed(
    () =>
      abeyanceRecs.value
        .filter((r) => r.responseHistory.history[0]?.locked !== true)
        .filter(
          (r) =>
            (r.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet) !==
            SiteResponseStatus.NotSet,
        ).length,
  );

  // Total number of responded recommendations
  const respondedCount = computed(
    () =>
      recs.value
        .filter((r) => r.responseHistory.history[0]?.locked !== true)
        .filter(
          (r) =>
            (r.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet) !==
            SiteResponseStatus.NotSet,
        ).length +
      respondedCompletedRecs.value.length +
      respondedAbeyanceRecCount.value,
  );

  // Number of recommendations that should be responded to
  const respondedMaxCount = computed(
    () => recs.value.length + respondedCompletedRecs.value.length + respondedAbeyanceRecCount.value,
  );

  // Recommmendations used for navigation
  const navRecs = computed(() => [
    ...allCompletedRecs.value,
    ...recs.value,
    ...(includeAbeyance.value ? abeyanceRecs.value : []),
  ]);

  return {
    categories,
    recs,
    allCompletedRecs,
    abeyanceRecs,
    respondedAbeyanceRecCount,
    respondedCount,
    respondedMaxCount,
    navRecs,
  };
};
