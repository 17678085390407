<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { DefaultBaseResponse } from "@/constants/DefaultBaseResponse";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { getActiveServiceMessages } from "@/services/serviceMessages";
import { useAuthStore } from "@/stores/auth";
import { GetSsoSettingsResponse, ServiceMessageLeanDto, MfaOption } from "@/types/_generated/api";
import BaseAlert, { Severity } from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { useCredentialVerification } from "../composables/useCredentialVerification";

const props = defineProps<{
  email: string;
  password: string;
}>();

const emit = defineEmits<{
  "update:email": [value: string];
  "update:password": [value: string];
  "update:expiry": [value: number | null];
  "view-change": [view: ViewAuthStatus];
}>();

const { t } = useI18n({ useScope: "global" });
const auth = useAuthStore();
const router = useRouter();
const { isLoadingVerification, verifyCredentials } = useCredentialVerification();

const signInErrorMessage = ref("");

const serviceMessages = ref<ServiceMessageLeanDto[]>([]);

const ssoSettings = ref<GetSsoSettingsResponse>(
  Object.assign(new DefaultBaseResponse(), { showSsoLogin: false }),
);

const getSeverity = (message: ServiceMessageLeanDto): Severity => {
  switch (message.severity) {
    case "Information":
      return "info";
    case "Warning":
      return "warning";
    case "Error":
      return "error";
    default:
      return "info";
  }
};

const redirectToSSO = () => {
  let url = ssoSettings.value.url!;
  if (router.currentRoute.value.query?.go) {
    url = url.includes("?")
      ? `${url}&go=${router.currentRoute.value.query.go}`
      : `${url}?go=${router.currentRoute.value.query.go}`;
  }
  document.location.href = url;
};

const signInUser = async () => {
  const result = await verifyCredentials(props.email, props.password);
  if (!result) return;

  const { mfaOption, mfaExpiry, errorMessage } = result;

  if (errorMessage) {
    signInErrorMessage.value = errorMessage;
    return;
  }

  // Handle MFA options
  if (mfaOption || mfaOption === null) {
    if (mfaOption === MfaOption.EmailorSms) {
      emit("view-change", ViewAuthStatus.TwoFactorSelect);
    } else {
      emit("view-change", ViewAuthStatus.TwoFactorVerify);
    }
    emit("update:expiry", mfaExpiry);
  }
};

onMounted(async () => {
  ssoSettings.value = await auth.getSSOSettings();
  serviceMessages.value = (await getActiveServiceMessages()) || [];
});
</script>

<template>
  <div v-if="serviceMessages.length > 0" class="auth-signin-service-messages">
    <BaseAlert
      v-for="(message, index) in serviceMessages"
      :key="index"
      :severity="getSeverity(message)"
    >
      <div>
        <strong>{{ message.subject }}</strong>
        <p>{{ message.message }}</p>
      </div>
    </BaseAlert>
  </div>

  <form class="auth-signin" @submit.prevent="signInUser">
    <div class="auth-signin__input-container">
      <BaseLabel>{{ t("common.emailOrUsername") }}</BaseLabel>
      <BaseTextField
        autocomplete="username"
        :tab="1"
        :disabled="isLoadingVerification"
        :value="email"
        @update:value="$emit('update:email', String($event))"
      />
    </div>

    <div class="auth-signin__input-container">
      <div class="auth-signin__password-label">
        <BaseLabel>{{ t("common.password") }}</BaseLabel>
        <button
          class="auth-signin__forgot-password"
          type="button"
          :tab="3"
          :disabled="isLoadingVerification"
          @click="emit('view-change', ViewAuthStatus.Forgot)"
        >
          {{ t("signIn.forgotPassword") }}?
        </button>
      </div>

      <BaseTextField
        type="password"
        autocomplete="current-password"
        :aria-label="t('common.password')"
        :tab="2"
        :disabled="isLoadingVerification"
        :value="password"
        @update:value="$emit('update:password', String($event))"
      />
    </div>

    <BaseAlert v-if="signInErrorMessage" severity="error">
      <p>{{ signInErrorMessage }}</p>
    </BaseAlert>

    <BaseButton type="submit" :disabled="isLoadingVerification">
      {{ t("signIn.signIn") }}
    </BaseButton>
  </form>

  <BaseButton
    v-if="ssoSettings.showSsoLogin"
    class="auth-signin__sso-btn"
    :disabled="isLoadingVerification"
    variant="text"
    @click="redirectToSSO"
  >
    {{ t("signIn.useSso") }}
  </BaseButton>
</template>

<style scoped lang="scss">
.auth-signin {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
  }

  &__password-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__forgot-password {
    color: $secondary-5;
    font-weight: $font-medium;
    transition: opacity $duration-100 ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &__sso-btn {
    margin-top: $spacing-4;
    width: 100%;
  }
}

.auth-signin-service-messages {
  display: grid;
  grid-template-rows: 1fr;
  gap: $spacing-2;
  margin-bottom: $spacing-8;
}
</style>
