import { createI18nMessage } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import { i18n } from "@/i18n";
import { AreaDto, CategoryDto, QuestionDto, QuestionnaireDto } from "@/types/_generated/api";
import { integer, minLength, minValue, required, requiredIf } from "@/validation/i18n-validators";

/**
 * @returns QuestionDto.greenScore or 0
 */
const mapGreenScore = (q: QuestionDto): number => (q.greenScore as number) ?? 0;

/**
 * @returns QuestionDto.yellowScore or 0
 */
const mapYellowScore = (q: QuestionDto): number => (q.yellowScore as number) ?? 0;

/**
 * @returns QuestionDto.redScore or 0
 */
const mapRedScore = (q: QuestionDto): number => (q.redScore as number) ?? 0;

/**
 * Why does this function exist?
 * - Because it's easier to read and shorter
 * @returns the sum of two numbers
 */
const sum = (p: number, c: number) => p + c;

/**
 * @returns a required validator if category.gradeBy is DefinedLimits
 */
const requiredIfDefinedLimits = requiredIf(function (
  // arrow functions won't work here
  this: { category?: Partial<CategoryDto> } | undefined,
) {
  return this?.category?.gradeBy === "DefinedLimits";
});

export const useQuestionnaireDraftValidation = () => {
  const { t } = useI18n({ useScope: "global" });
  // Create your i18n message instance. Used for vue-i18n@9
  const withI18nMessage = createI18nMessage({
    t: i18n.global.t.bind(i18n),
  });

  /**
   * @returns a validator that checks if the sum of the questions' score is greater than or equal to the category color score
   */
  const minQuestionScore = (color: "blue" | "green" | "yellow") => {
    const mapScore = (q: QuestionDto): number =>
      (q[`${color}Score` as keyof QuestionDto] as number) ?? 0;

    return withI18nMessage({
      $validator: (value: number | undefined, obj: CategoryDto) => {
        const questions = obj.questions ?? [];

        if (obj.gradeBy !== "DefinedLimits" || questions.length === 0) {
          return true;
        }

        const score = questions.map(mapScore).reduce(sum, 0);

        return score >= (value ?? 0);
      },
      $params: {
        color: t(`questionnaire.draft.colors.${color}`),
      },
    });
  };

  /**
   * @returns a validator that checks if the sum of the questions' score is less than or equal to the category color score
   */
  const maxQuestionScore = (color: "blue" | "green" | "yellow") =>
    withI18nMessage({
      $validator: (value: number | undefined, obj: CategoryDto) => {
        const questions = obj.questions ?? [];

        if (obj.gradeBy !== "DefinedLimits" || questions.length === 0) {
          return true;
        }

        const greenScore = questions.map(mapGreenScore).reduce(sum, 0);
        const yellowScore = questions.map(mapYellowScore).reduce(sum, 0);
        const redScore = questions.map(mapRedScore).reduce(sum, 0);

        switch (color) {
          case "blue":
            return (
              greenScore < (value ?? 0) && yellowScore < (value ?? 0) && redScore < (value ?? 0)
            );
          case "green":
            return yellowScore < (value ?? 0) && redScore < (value ?? 0);
          case "yellow":
            return redScore < (value ?? 0);
          default:
            throw new Error("Invalid color");
        }
      },
    });

  const mustBeLargerThanLowerLimit = (color: "blue" | "green") => {
    return withI18nMessage({
      $validator: (value: number | undefined, obj: CategoryDto) => {
        if (
          obj.gradeBy !== "DefinedLimits" ||
          (obj.questions ?? []).length === 0 ||
          value === undefined
        ) {
          return true;
        }

        switch (color) {
          case "blue":
            return value > (obj.greenLimit ?? 0);
          case "green":
            return value > (obj.yellowLimit ?? 0);
          default:
            throw new Error("Invalid color");
        }
      },
      $params: {
        color: t(`questionnaire.draft.colors.${color === "blue" ? "green" : "yellow"}`),
      },
    });
  };

  const questionnaireRules: {
    [k in keyof Partial<QuestionnaireDto>]: object;
  } = {
    name: { required },
    areas: { required, minLength: minLength(1) },
  };

  const areaRules: { [k in keyof Partial<AreaDto>]: object } = {
    name: { required },
    categories: { required, minLength: minLength(1) },
  };

  const categoryRules: { [k in keyof Partial<CategoryDto>]: object } = {
    name: { required },
    gradeBy: { required },
    blueLimit: {
      required: requiredIfDefinedLimits,
      minQuestionScore: minQuestionScore("blue"),
      maxQuestionScore: maxQuestionScore("blue"),
      mustBeLargerThanLowerLimit: mustBeLargerThanLowerLimit("blue"),
    },
    greenLimit: {
      required: requiredIfDefinedLimits,
      minQuestionScore: minQuestionScore("green"),
      maxQuestionScore: maxQuestionScore("green"),
      mustBeLargerThanLowerLimit: mustBeLargerThanLowerLimit("green"),
    },
    yellowLimit: {
      required: requiredIfDefinedLimits,
      minQuestionScore: minQuestionScore("yellow"),
      maxQuestionScore: maxQuestionScore("yellow"),
    },
    questions: { required, minLength: minLength(1) },
  };

  const questionRules: { [k in keyof Partial<QuestionDto>]: object } = {
    text: { required },
    blueScore: { required, integer, minValue: minValue(1) },
    greenScore: { required, integer, minValue: minValue(1) },
    redScore: { required, integer, minValue: minValue(0) },
    yellowScore: { required, integer, minValue: minValue(1) },
  };

  return {
    questionnaireRules,
    areaRules,
    categoryRules,
    questionRules,
  };
};
