<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { isAxiosError } from "axios";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import http from "@/helpers/http";
import { AppRouteName } from "@/router/RouteName";
import { handleError } from "@/services/shared/handleError";
import AuthWrapper from "@/views/Auth/components/AuthWrapper.vue";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import PasswordForm from "@/components/shared/PasswordForm.vue";

const { t } = useI18n({ useScope: "global" });

const password1 = ref("");
const password2 = ref("");
const isLoading = ref(false);
const isPasswordResetComplete = ref(false);
const isExpiredToken = ref(false);

const props = defineProps<{
  email: string;
  token: string;
}>();

const v$ = useVuelidate();
const router = useRouter();

const resetPassword = async () => {
  if (v$.value.$invalid) return;
  isLoading.value = true;

  try {
    await http.post(`auth/reset-password`, {
      email: props.email,
      token: props.token,
      password: password1.value,
    });

    isPasswordResetComplete.value = true;
  } catch (error) {
    if (isAxiosError(error) && error.status === 403) {
      isExpiredToken.value = true;
    } else {
      handleError(error);
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <AuthWrapper class="view-reset-password" :heading="t('common.resetPassword')">
    <form
      v-if="!isPasswordResetComplete"
      class="view-reset-password__form"
      @submit.prevent="resetPassword"
    >
      <PasswordForm
        v-model:password1="password1"
        v-model:password2="password2"
        :is-loading="isLoading"
        show-requirements
      />

      <BaseButton type="submit" :disabled="isLoading">
        {{ t("common.actions.confirm") }}
      </BaseButton>
    </form>

    <div v-if="isExpiredToken">
      <BaseAlert severity="error">
        <p>
          {{ t("signIn.passwordReset.expiredToken") }}
        </p>
      </BaseAlert>

      <BaseButton @click="router.push({ name: AppRouteName.Auth })">
        {{ t("signIn.return") }}
      </BaseButton>
    </div>

    <div v-if="isPasswordResetComplete" class="view-reset-password__success">
      <BaseAlert severity="success">
        <p>
          {{ t("signIn.newPasswordSuccess") }}
        </p>
      </BaseAlert>

      <BaseButton @click="router.push({ name: AppRouteName.Auth })">
        {{ t("signIn.return") }}
      </BaseButton>
    </div>
  </AuthWrapper>
</template>

<style scoped lang="scss">
.view-reset-password {
  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    width: 100%;
  }
}
</style>
