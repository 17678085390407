<script setup lang="ts">
import { ref } from "vue";
import type { RouteParams, RouteRecordName } from "vue-router";
import { vClickOutside } from "@/directives/clickOutside";
import { vFocusTrap } from "@/directives/focusTrap";
import BaseIcon, { type Props as BaseIconProps } from "@/components/base/BaseIcon/BaseIcon.vue";

export type DropdownLink = {
  divider?: boolean;
  label: string;
  icon?: BaseIconProps["icon"];
  name: RouteRecordName;
  params?: RouteParams | undefined;
  action?: never;
};

export type DropdownButton = {
  divider?: boolean;
  label: string;
  icon?: BaseIconProps["icon"];
  action: () => void;
  name?: never;
  params?: never;
};

export type DropdownOptions = DropdownLink | DropdownButton;

defineProps<{
  options: DropdownOptions[];
  position?: "bottom-right" | "bottom-left" | "top-right" | "top-left";
}>();

const isOpen = ref(false);

const ICON_SIZE = "medium";

const toggleDropdown = () => (isOpen.value = !isOpen.value);
</script>

<template>
  <div class="uw-dropdown">
    <slot name="activator" :toggle-dropdown="toggleDropdown"></slot>

    <Transition name="fade">
      <ul
        v-if="isOpen && options.length > 0"
        v-click-outside="() => toggleDropdown()"
        v-focus-trap
        class="uw-dropdown__list"
        :class="`uw-dropdown__list--${position ?? 'bottom-right'}`"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          class="uw-dropdown__list__option"
          :class="{ 'uw-dropdown__list__option--divider': option.divider }"
        >
          <RouterLink
            v-if="option.name"
            :to="{ name: option.name, params: option.params }"
            @click="toggleDropdown"
          >
            <BaseIcon
              v-if="option.icon"
              class="uw-dropdown__icon"
              :size="ICON_SIZE"
              :icon="option.icon"
            />
            {{ option.label }}
          </RouterLink>

          <button
            v-else-if="option.action"
            type="button"
            @click="option.action(), (isOpen = false)"
          >
            <BaseIcon
              v-if="option.icon"
              class="uw-dropdown__icon"
              :size="ICON_SIZE"
              :icon="option.icon"
            />
            {{ option.label }}
          </button>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.uw-dropdown {
  position: relative;

  &__list {
    position: absolute;
    background: $primary-0;
    border: 1px solid $primary-5;
    border-radius: $rounded-base;
    margin-top: $spacing-2;
    box-shadow: $shadow-md;
    z-index: $z-20;

    &--bottom-right {
      left: 0;
      top: 100%;
    }

    &--bottom-left {
      right: 0;
      top: 100%;
    }

    &--top-right {
      left: 0;
      bottom: 100%;
    }

    &--top-left {
      right: 0;
      bottom: 100%;
    }

    &__option {
      &:first-child {
        border-radius: $rounded-base $rounded-base 0 0;
      }

      &:last-child {
        border-radius: 0 0 $rounded-base $rounded-base;
      }

      &--divider {
        border-top: 1px solid $primary-4;
      }

      a,
      button {
        display: flex;
        align-items: center;
        gap: $spacing-2;
        width: 100%;
        padding: $spacing-4;
        font-size: $text-sm;
        white-space: nowrap;
        border-radius: inherit;
        transition: background-color $duration-200;

        &:hover {
          background: $primary-2;
          color: inherit;
        }
      }
    }
  }

  &__icon {
    color: $primary-7;
  }
}
</style>
