<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  value: string;
}

defineProps<Props>();

const emit = defineEmits<{
  "update:value": [string];
}>();
</script>

<template>
  <div>
    <BaseLabel has-spacing>
      {{ t("standardRecommendations.type") }}
    </BaseLabel>
    <UWTextField :value="value" @input="emit('update:value', $event)" />
  </div>
</template>
