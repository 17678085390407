<template>
  <div class="blue-rating-table-toolbar">
    <div class="blue-rating-table-toolbar__side">
      <slot name="left"></slot>
    </div>

    <div class="blue-rating-table-toolbar__side">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-table-toolbar {
  display: flex;
  justify-content: space-between;
  gap: $spacing-2;

  &__side {
    display: flex;
    align-items: center;
    gap: $spacing-2;
  }
}
</style>
