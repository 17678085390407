<script setup lang="ts">
import useDesign from "@/composables/useDesign";

const design = useDesign();
</script>

<template>
  <div class="view-admin">
    <div :class="{ 'view-admin__inner': true }">
      <div class="view-admin__view">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view-admin {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  $impersonation-height: v-bind("design.impersonationHeight.value");
  height: calc(100vh - $impersonation-height);
  max-height: calc(100vh - $impersonation-height);

  &__inner {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100vw;
    max-width: 100vw;

    @media (max-width: $screen-md) {
      flex-direction: column;
    }
  }

  &__view {
    width: 100%;
    overflow-x: hidden;
  }
}
</style>
