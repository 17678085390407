<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{ showDescription: boolean }>();

const emit = defineEmits<{ (event: "toggle"): void }>();

const toggle = () => emit("toggle");
</script>

<template>
  <BaseButton
    v-if="showDescription"
    class="questionnaire-description-toggle"
    :title="t('questionnaire.showQuestionGradingDescriptions')"
    variant="outlined"
    @click="toggle"
  >
    <BaseIcon icon="eye-closed" />
  </BaseButton>

  <BaseButton
    v-else
    class="questionnaire-description-toggle"
    :title="t('questionnaire.hideQuestionGradingDescriptions')"
    variant="outlined"
    @click="toggle"
  >
    <BaseIcon icon="eye" />
  </BaseButton>
</template>

<style scoped lang="scss">
.questionnaire-description-toggle {
  margin-left: $spacing-1;
}
</style>
