<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineEmits<{
  click: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <button class="rec-report-form-reset-button" type="button" @click="$emit('click')">
    {{ t("module.recReportSettings.resetToDefault") }}
  </button>
</template>

<style scoped lang="scss">
.rec-report-form-reset-button {
  font-size: $text-xs;
  color: $primary-7;
  transition: opacity $duration-100;

  &:hover {
    opacity: 0.8;
  }
}
</style>
