<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SurveyType } from "@/types/_generated/api";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";

defineProps<{
  selectedSurveyType: SurveyType;
}>();

defineEmits<{
  "update:surveyType": [SurveyType];
}>();

const { t } = useI18n({ useScope: "global" });

const surveyTypeOptions: Array<{ value: SurveyType; label: string }> = [
  { value: SurveyType.WEXT, label: t("userWeb.filter.surveyType.wext") },
  { value: SurveyType.W, label: t("userWeb.filter.surveyType.w") },
  { value: SurveyType.EXT, label: t("userWeb.filter.surveyType.ext") },
];
</script>

<template>
  <div>
    <BaseLabel has-spacing>{{ t("userWeb.filter.surveyType.title") }}</BaseLabel>
    <UWSelect
      :value="selectedSurveyType"
      @change="$emit('update:surveyType', $event as SurveyType)"
    >
      <UWOption v-for="option in surveyTypeOptions" :key="option.value" :value="option.value">
        {{ option.label }}
      </UWOption>
    </UWSelect>
  </div>
</template>
