<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import currencies from "@/assets/currencies.json";
import { useRcba } from "@/composables/useRcba";
import { toCurrency } from "@/helpers/formatNumber";
import { LossEstimateType, RiskCostBenefitAnalysisDto } from "@/types/_generated/api";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import BlueRatingRcbaNumberField from "./BlueRatingRcbaNumberField.vue";
import LossEstimateTypeDropdown from "./LossEstimateTypeDropdown.vue";

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    rcba: Readonly<RiskCostBenefitAnalysisDto>;
  }>(),
  {
    disabled: false,
    rcba: () => ({
      rcbaDescription: "",
      rcbaProbability: "",
      rcbaPropertyPrior: 0,
      rcbaPropertyAfter: 0,
      rcbaBiPrior: 0,
      rcbaBiAfter: 0,
      rcbaExtraPrior: 0,
      rcbaExtraAfter: 0,
      rcbaCostToComplete: 0,
      rcbaCurrency: "USD",
      rcbaLossEstimateType: LossEstimateType.Percent,
    }),
  },
);

const emit = defineEmits<{
  <Key extends keyof RiskCostBenefitAnalysisDto>(
    event: "update",
    key: Key,
    value: RiskCostBenefitAnalysisDto[Key],
  ): void;
}>();

const { t } = useI18n({ useScope: "global" });

const {
  isValid,
  priorSum,
  afterSum,
  formatSum,
  costBenefitRatio,
  hasExtra,
  hasProbability,
  biSum,
  pdSum,
} = useRcba(toRef(props.rcba));

const setCurrency = (event: string) => {
  const currency = event.trim().toUpperCase();

  emit("update", "rcbaCurrency", currency);
};

const setLossEstimateType = (event: LossEstimateType) => {
  emit("update", "rcbaLossEstimateType", event);

  if (event === LossEstimateType.Percent) {
    emit("update", "rcbaExtraPrior", 0);
    emit("update", "rcbaExtraAfter", 0);
  }
};

const getValue = (value: number | undefined): number | undefined => {
  if (typeof value !== "number") {
    return undefined;
  }

  return props.rcba.rcbaLossEstimateType === LossEstimateType.Percent
    ? Math.min(100, Number(value))
    : Number(value);
};
</script>

<template>
  <section class="blue-rating-cost-benefit">
    <BaseTextField
      class="blue-rating-cost-benefit__two-thirds truncate"
      :label="t('blueRating.recommendation.currency')"
      :value="rcba.rcbaCurrency"
      :disabled="disabled"
      :list="currencies"
      :maxlength="4"
      @change:value="setCurrency(String($event))"
    />

    <LossEstimateTypeDropdown
      :value="rcba.rcbaLossEstimateType"
      :disabled="disabled"
      @change="setLossEstimateType"
    />

    <div
      :class="{
        'blue-rating-cost-benefit__scenario': true,
        'blue-rating-cost-benefit__scenario__full-width': !hasProbability,
      }"
    >
      <BaseLabel has-spacing>
        {{ t("blueRating.recommendation.scenarioDescription") }}
      </BaseLabel>
      <BaseEditor
        :disabled="disabled"
        :custom-height="{ min: '200px' }"
        :value="rcba.rcbaDescription"
        @input="emit('update', 'rcbaDescription', $event)"
      />
    </div>

    <BaseTextField
      v-if="hasProbability"
      :label="t('blueRating.recommendation.probability')"
      :disabled="disabled"
      :value="rcba.rcbaProbability"
      @update:value="emit('update', 'rcbaProbability', String($event))"
    />

    <BaseDivider class="blue-rating-cost-benefit__divider" />

    <span><!--intentionally left blank--></span>
    <BaseLabel class="blue-rating-cost-benefit__align-center">
      {{ t("blueRating.recommendation.toImplementation") }}
    </BaseLabel>
    <BaseLabel class="blue-rating-cost-benefit__align-center">
      {{ t("blueRating.recommendation.afterImplementation") }}
    </BaseLabel>
    <BaseLabel class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.property") }}
    </BaseLabel>

    <BlueRatingRcbaNumberField
      :disabled="disabled"
      :value="rcba.rcbaPropertyPrior || ''"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      @update:value="emit('update', 'rcbaPropertyPrior', getValue($event))"
    />
    <BlueRatingRcbaNumberField
      :value="rcba.rcbaPropertyAfter"
      :disabled="disabled"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      @update:value="emit('update', 'rcbaPropertyAfter', getValue($event))"
    />

    <BaseLabel class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.businessInterruption") }}
    </BaseLabel>
    <BlueRatingRcbaNumberField
      :disabled="disabled"
      :value="rcba.rcbaBiPrior"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      @update:value="emit('update', 'rcbaBiPrior', getValue($event))"
    />
    <BlueRatingRcbaNumberField
      :value="rcba.rcbaBiAfter"
      :disabled="disabled"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      @update:value="emit('update', 'rcbaBiAfter', getValue($event))"
    />

    <BaseLabel v-if="hasExtra" class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.extra") }}
    </BaseLabel>
    <BlueRatingRcbaNumberField
      v-if="hasExtra"
      :disabled="disabled"
      :value="rcba.rcbaExtraPrior"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      @update:value="emit('update', 'rcbaExtraPrior', getValue($event))"
    />
    <BlueRatingRcbaNumberField
      v-if="hasExtra"
      :value="rcba.rcbaExtraAfter"
      :disabled="disabled"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      @update:value="emit('update', 'rcbaExtraAfter', getValue($event))"
    />

    <template v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary">
      <BaseLabel class="blue-rating-cost-benefit__justify-end">
        {{ t("blueRating.recommendation.total") }}
      </BaseLabel>
      <span class="blue-rating-cost-benefit__total">
        {{ formatSum(priorSum) }}
      </span>
      <span class="blue-rating-cost-benefit__total">
        {{ formatSum(afterSum) }}
      </span>
    </template>

    <BaseDivider class="blue-rating-cost-benefit__divider" />

    <BaseLabel class="blue-rating-cost-benefit__two-thirds blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.estimatedCostToComplete", { currency: rcba.rcbaCurrency }) }}
    </BaseLabel>
    <BlueRatingRcbaNumberField
      :disabled="disabled"
      :value="rcba.rcbaCostToComplete || ''"
      @update:value="emit('update', 'rcbaCostToComplete', getValue($event))"
    />

    <template v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary">
      <BaseLabel class="blue-rating-cost-benefit__two-thirds blue-rating-cost-benefit__justify-end">
        {{ t("blueRating.recommendation.estimatedRiskReduction") }}
      </BaseLabel>
      <span v-if="isValid" class="blue-rating-cost-benefit__total">
        {{ formatSum(priorSum - afterSum) }}
      </span>
      <span v-else class="blue-rating-cost-benefit__total">-</span>

      <BaseLabel class="blue-rating-cost-benefit__two-thirds blue-rating-cost-benefit__justify-end">
        {{ t("blueRating.recommendation.costBenefitRatio") }}
      </BaseLabel>
      <span v-if="isValid" class="blue-rating-cost-benefit__total">
        {{ costBenefitRatio }}
      </span>
      <span v-else class="blue-rating-cost-benefit__total"> - </span>
    </template>

    <span
      v-if="rcba.rcbaLossEstimateType === LossEstimateType.Percent && isValid && pdSum"
      class="blue-rating-cost-benefit__row blue-rating-cost-benefit__justify-end"
    >
      {{
        t("blueRating.recommendation.investmentGivesRiskReductionPD", {
          cost: toCurrency(rcba.rcbaCostToComplete ?? 0, props.rcba.rcbaCurrency),
          riskReduction: formatSum(pdSum),
        })
      }}
    </span>
    <span
      v-if="rcba.rcbaLossEstimateType === LossEstimateType.Percent && isValid && biSum"
      class="blue-rating-cost-benefit__row blue-rating-cost-benefit__justify-end"
    >
      {{
        t("blueRating.recommendation.investmentGivesRiskReductionBI", {
          cost: toCurrency(rcba.rcbaCostToComplete ?? 0, props.rcba.rcbaCurrency),
          riskReduction: formatSum(biSum),
        })
      }}
    </span>
  </section>
</template>

<style scoped lang="scss">
.blue-rating-cost-benefit {
  display: grid;
  gap: $spacing-2;
  padding: $spacing-4;

  &__two-thirds {
    padding-right: $spacing-4;
    grid-column: 1/3;
  }

  &__align-center {
    align-self: center;
  }

  &__justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: $spacing-4;
  }

  &__row {
    padding-left: $spacing-4;
    padding-right: $spacing-4;
    grid-column: span 3;
    height: 40px;
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: $spacing-3;
    height: 40px;
  }

  &__scenario {
    grid-column: 1/3;
    margin: $spacing-3 0 $spacing-2 0;
    &__full-width {
      grid-column: 1/4;
    }
  }

  &__divider {
    grid-column: 1/4;
    margin-bottom: $spacing-2;
  }
}
</style>
