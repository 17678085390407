<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { authorize } from "@/plugins/can";
import { AdminRouteName } from "@/router/RouteName";
import { Role } from "@/types/_generated/api";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import { type NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";

const { t } = useI18n({ useScope: "global" });

const tabs = computed(() => {
  const tabs: NavTab[] = [
    {
      title: t("standardRecommendations.standardRecommendations"),
      path: AdminRouteName.StandardRecommendations,
    },
  ];

  if (authorize.isSuperiorOrEqualTo(Role.BA)) {
    tabs.unshift({
      title: t("questionnaireTemplates.questionnaireTemplates"),
      path: AdminRouteName.QuestionnaireTemplates,
    });
  }

  return tabs;
});
</script>

<template>
  <AdminHeader :breadcrumbs="[{ title: t('navigation.templates') }]" />

  <AdminViewLayout :tabs="tabs">
    <router-view />
  </AdminViewLayout>
</template>
