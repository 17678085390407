<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetRecommendations } from "@/composables/services/useGetRecommendations";
import { useModuleTranslations } from "@/composables/useModuleTranslations";
import { SiteResponseStatus, SurveyorStatus } from "@/types/_generated/api";
import RecommendationProperties from "@/components/shared/RecommendationProperties/RecommendationProperties.vue";
import RecommendationRcbaProperties from "@/components/shared/RecommendationProperties/RecommendationRcbaProperties.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ResponseFileList from "@/components/shared/RecommendationResponse/ResponseFileList.vue";
import ExecSummaryCatRecStates from "./ExecSummaryCatRecStates.vue";
import ExecSummaryCatRecTitle from "./ExecSummaryCatRecTitle.vue";

const props = defineProps<{
  moduleId: number;
  surveyId: number;
  categoryId: number;
}>();

const { t } = useI18n({ useScope: "global" });

const { getRecommendations, recommendations, isLoadingRecommendations, hasError } =
  useGetRecommendations();
const { module, isLoadingModule, getModule } = useGetModule();
const { moduleTranslations } = useModuleTranslations(module);

const isLoading = computed(() => isLoadingRecommendations.value || isLoadingModule.value);

onMounted(() => {
  getRecommendations(props.surveyId, {
    categoryIds: [props.categoryId],
    surveyorStatuses: [
      SurveyorStatus.NotSet,
      SurveyorStatus.NoProgress,
      SurveyorStatus.UnderReview,
      SurveyorStatus.InProgress,
      SurveyorStatus.Rejected,
      SurveyorStatus.Abeyance,
    ],
  });
  getModule(props.moduleId);
});
</script>

<template>
  <div v-if="recommendations.length" class="cat-rec-list">
    <ExecSummaryCatRecTitle>
      {{ t("userWeb.executiveSummary.categoryRecommendations.recommendations") }}
    </ExecSummaryCatRecTitle>

    <template v-if="!isLoading && !hasError">
      <div
        v-for="(recommendation, index) in recommendations"
        :key="index"
        class="cat-rec-list__recommendation"
      >
        <div>
          <h3 class="cat-rec-list__recommendation__title">
            {{ recommendation.fullPosition }}
          </h3>
        </div>

        <div class="cat-rec-list__recommendation__right">
          <div class="cat-rec-list__recommendation__right__content">
            <RecommendationProperties
              :module-translations="moduleTranslations"
              :categories="[]"
              :recommendation="recommendation"
            />
            <RecommendationRcbaProperties :rcba="recommendation" />

            <LatestRecommendationResponse
              v-if="recommendation.responseHistory.status !== SiteResponseStatus.NotSet"
              :recommendation-response="recommendation.responseHistory"
              :currency="module?.currencyCode ?? ''"
            >
              <template #filelist="{ response }">
                <ResponseFileList :module-id="moduleId" :response="response" />
              </template>
            </LatestRecommendationResponse>

            <LatestRiskManagerComment :comments="recommendation.riskManagerCommentHistory" />
          </div>
        </div>
      </div>
    </template>
  </div>

  <ExecSummaryCatRecStates v-else :is-loading-data="isLoading" :error-fetching-data="hasError" />
</template>

<style scoped lang="scss">
$border: 1px solid $primary-4;
$spacing: $spacing-8;

.cat-rec-list__recommendation {
  display: grid;
  gap: $spacing;
  grid-template-columns: 1fr 6fr;

  &:last-child {
    .cat-rec-list__recommendation {
      &__right {
        &__content {
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }

  &__title {
    position: sticky;
    top: 0;
    font-weight: $font-medium;
    font-variant-numeric: tabular-nums;
    padding-bottom: $spacing;
  }

  &__right {
    border-left: $border;
    padding-left: $spacing;

    &__content {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;
      padding-bottom: $spacing;
      margin-bottom: $spacing;
      border-bottom: $border;
    }
  }
}
</style>
