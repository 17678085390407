<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { RecommendationTrackingFilter } from "@/types/_generated/api";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWTextField from "@/components/base/UserWeb/Inputs/TextField/UWTextField.vue";

defineProps<{
  label?: string;
  priority?: RecommendationTrackingFilter["priority"];
}>();

defineEmits<{
  "update:priority": [string];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div>
    <BaseLabel has-spacing>
      {{ label ?? t("userWeb.recommendationTracking.filter.priority") }}
    </BaseLabel>
    <UWTextField :value="priority ?? ''" @input="$emit('update:priority', $event.trim())" />
  </div>
</template>
