import Squire from "squire-rte";
import { type Ref, onMounted, onUnmounted, ref, watch } from "vue";
import type { Props } from "../BaseEditor.vue";
import { useSanitizer } from "./useSanitizer";

export const useEditor = (editorContainer: Ref<HTMLElement | null>, props: Props) => {
  const editor = ref<Squire | null>(null);
  const { sanitizeHtml } = useSanitizer();

  onMounted(() => {
    if (editorContainer.value) {
      // Initialize the Squire editor instance
      editor.value = new Squire(editorContainer.value, {
        sanitizeToDOMFragment: (html, _editorInstance) => {
          const sanitizedHTML = sanitizeHtml(html);
          // Convert the sanitized HTML string to a DocumentFragment
          return document.createRange().createContextualFragment(sanitizedHTML);
        },
      });
      // Set the initial HTML content
      editor.value.setHTML(props.value);
      // Set the contenteditable attribute
      editorContainer.value.setAttribute("contenteditable", props.disabled ? "false" : "true");
    }
  });

  // Clean up editor instance
  onUnmounted(() => {
    editor.value?.destroy();
    editor.value = null;
  });

  // Watch for changes in value prop to set externally updated content
  watch(
    () => props.value,
    (newValue) => {
      const newValueIsProvided = newValue !== undefined;
      const contentIsDifferent = editor.value?.getHTML() !== newValue;
      if (newValueIsProvided && contentIsDifferent) {
        editor.value?.setHTML(newValue);
      }
    },
  );

  return { editor };
};
