<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { ref, computed, useId } from "vue";
import { vAutoFocus } from "@/directives/autoFocus";
import BaseInputRequired from "./BaseInputRequired.vue";
import BaseLabel from "./BaseLabel.vue";

type Value = string | null | undefined;

interface Props {
  value?: Value;
  tab?: number;
  autocomplete?: string;
  autofocus?: boolean;
  label?: string;
  placeholder?: string;
  type?: string;
  readonly?: boolean;
  disabled?: boolean;
  errors?: ErrorObject[];
  required?: boolean;
}

const {
  value = undefined,
  tab = undefined,
  autocomplete = undefined,
  label = "",
  placeholder = "",
  type = "date",
  readonly = false,
  disabled = false,
  errors = [],
  required = false,
  autofocus = false,
} = defineProps<Props>();

defineEmits<{
  "update:value": [Value];
  "change:value": [Value];
  clear: [void];
}>();

const input = ref<HTMLInputElement | null>(null);
const id = useId();

const clearInput = () => {
  if (input.value) input.value.value = "";
};

defineExpose({ input, clearInput });

const dateString = computed(() =>
  value ? (value.length > 10 ? value.substring(0, 10) : value) : null,
);
</script>

<template>
  <div class="base-datefield">
    <div v-if="label" class="base-datefield__label">
      <BaseLabel :for-id="id" has-spacing>
        {{ label }}
      </BaseLabel>
      <BaseInputRequired v-if="required" />
    </div>

    <div
      class="base-datefield__box"
      :class="{
        'base-datefield__box--disabled': disabled || readonly,
        'base-datefield__box--error': errors.length,
      }"
    >
      <input
        :id="id"
        ref="input"
        v-auto-focus="autofocus"
        class="base-datefield__input"
        :tabindex="tab"
        :type="type"
        :placeholder="placeholder"
        :value="dateString"
        spellcheck="false"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        @change="$emit('change:value', ($event.target as HTMLInputElement).value)"
        @input="$emit('update:value', ($event.target as HTMLInputElement).value)"
      />
    </div>

    <div v-if="errors.length && !disabled">
      <p v-for="({ $message }, i) in errors" :key="i" class="base-datefield__error">
        {{ $message }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./styles";

.base-datefield {
  display: flex;
  flex-direction: column;

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__box {
    position: relative;
    display: flex;
    @include input;

    &--disabled {
      @include input-disabled;
    }

    &--error {
      @include input-error;
    }
  }

  &__input {
    width: 100%;
    background: inherit;
    padding: $spacing-3;
    border: none;
    color: inherit;
    border-radius: $rounded-base;
  }

  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin-top: $spacing-1;
  }
}
</style>
