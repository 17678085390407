import { readonly, ref } from "vue";
import { useI18n } from "vue-i18n";
import { saveSpoeCheck as saveSpoeCheckService } from "@/services/spoeCheck";
import { notify } from "@/stores/notifications";
import { SaveSpoeCheck } from "@/types/_generated/api";

export const useSaveSpoeCheck = () => {
  const { t } = useI18n({ useScope: "global" });
  const isSavingSpoeCheck = ref(false);

  const saveSpoeCheck = async (settings: SaveSpoeCheck) => {
    isSavingSpoeCheck.value = true;

    const isSuccess = await saveSpoeCheckService(settings);

    if (isSuccess) {
      notify.success(t("survey.spoeCheck"), t("common.saved").toLowerCase());
    }

    isSavingSpoeCheck.value = false;
  };

  return {
    isSavingSpoeCheck: readonly(isSavingSpoeCheck),
    saveSpoeCheck,
  };
};
