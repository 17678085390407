import axios, { AxiosResponse } from "axios";
import { ref } from "vue";

export const useDownloadBlob = () => {
  const isLoading = ref(false);

  const createAndClickAnchor = (url: string, fileName?: string) => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;

    link.download = fileName ?? url.substring(url.lastIndexOf("/") + 1);

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  };

  const downloadBlob = (response: AxiosResponse<Blob | MediaSource>) => {
    // Create a blob URL for the file
    const blobUrl = URL.createObjectURL(response.data);

    const fileName = getFileName(response);

    // Use the helper to download the file
    createAndClickAnchor(blobUrl, fileName);

    // Clean up the object URL after download
    URL.revokeObjectURL(blobUrl);
  };

  const getFileName = (response: AxiosResponse) => {
    // Extract filename from Content-Disposition header or use a default
    const contentDisposition = response.headers["content-disposition"];

    return contentDisposition
      ? contentDisposition.match(/filename="(.+)"/)?.[1] || "downloaded-file"
      : "downloaded-file";
  };

  const get = async <T>(
    url: string,
    queryParams?: Record<string, number | string | boolean | null | undefined>,
  ) => {
    try {
      isLoading.value = true;

      const response = await axios.get<Blob | MediaSource, AxiosResponse<Blob | MediaSource, T>>(
        url,
        {
          params: queryParams,
          responseType: "blob",
        },
      );

      downloadBlob(response);
    } catch (error) {
      throw new Error(`Failed to download file: ${error}`);
    } finally {
      isLoading.value = false;
    }
  };

  const post = async <T>(url: string, body?: T): Promise<void> => {
    try {
      isLoading.value = true;

      const response = await axios.post<Blob | MediaSource, AxiosResponse<Blob | MediaSource, T>>(
        url,
        body,
        {
          responseType: "blob",
        },
      );

      downloadBlob(response);
    } catch (error) {
      throw new Error(`Failed to download file: ${error}`);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    blobDownload: { get, post },
  };
};
