<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import LogoBlueWTWWhite from "@/assets/logos/LogoBlueWTWWhite.vue";
import router from "@/router";
import { UserWebRouteName } from "@/router/RouteName";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import UWDropdown, {
  type DropdownOptions,
} from "@/components/base/UserWeb/Dropdown/UWDropdown.vue";

const { t } = useI18n({ useScope: "global" });

const moduleId = computed(() => router.currentRoute.value.params.moduleId || "");

const routes = computed<DropdownOptions[]>(() => {
  const routeParams = { moduleId: moduleId.value };

  return [
    {
      label: t("userWeb.navigation.dashboard"),
      name: UserWebRouteName.Dashboard,
      params: routeParams,
    },
    {
      label: t("userWeb.navigation.executiveSummary"),
      name: UserWebRouteName.ExecutiveSummary,
      params: routeParams,
    },
    {
      label: t("userWeb.navigation.recommendationTracking"),
      name: UserWebRouteName.RecTracking,
      params: routeParams,
    },
    {
      label: t("userWeb.navigation.dataAnalysisAndTrends"),
      name: UserWebRouteName.DAAT,
      params: routeParams,
    },
    {
      label: t("userWeb.navigation.sites"),
      name: UserWebRouteName.Sites,
      params: routeParams,
    },
    {
      label: t("userWeb.navigation.library"),
      name: UserWebRouteName.Library,
      params: routeParams,
    },
    {
      label: t("userWeb.navigation.questionnaire"),
      name: UserWebRouteName.Questionnaire,
      params: routeParams,
    },
  ];
});
</script>

<template>
  <nav class="user-web-header-menu">
    <RouterLink
      class="user-web-header-menu__link"
      :to="{ name: UserWebRouteName.Modules }"
      :aria-label="t('userWeb.navigation.modules')"
    >
      <LogoBlueWTWWhite class="user-web-header-menu__logo" />
    </RouterLink>

    <UWDropdown v-if="router.currentRoute.value.params.moduleId" :options="routes">
      <template #activator="{ toggleDropdown }">
        <BaseButton variant="outlined" color="light" :aria-haspopup="true" @click="toggleDropdown">
          <BaseIcon icon="menu" />
          {{ t("navigation.menu") }}
        </BaseButton>
      </template>
    </UWDropdown>
  </nav>
</template>

<style scoped lang="scss">
.user-web-header-menu {
  display: flex;
  align-items: center;
  gap: $spacing-4;

  &__link {
    transition: opacity $duration-100;

    &:hover {
      opacity: 0.8;
    }
  }

  &__logo {
    height: 1rem;
    flex-shrink: 0;
  }
}
</style>
