<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.53701 0.0571123C2.81727 0.173198 3 0.446675 3 0.750022V5.50002H3.25C3.66421 5.50002 4 5.83581 4 6.25002C4 6.66424 3.66421 7.00002 3.25 7.00002H0.75C0.335786 7.00002 0 6.66424 0 6.25002C0 5.83581 0.335786 5.50002 0.75 5.50002H1.5V2.56068L1.28033 2.78035C0.987437 3.07325 0.512563 3.07325 0.21967 2.78035C-0.0732233 2.48746 -0.0732233 2.01259 0.21967 1.71969L1.71967 0.219692C1.93417 0.00519335 2.25676 -0.0589735 2.53701 0.0571123Z"
    />
    <path
      d="M9.25 10.25H7.6562C6.9255 10.25 6.5182 9.43593 6.9058 8.85743C6.9535 8.78613 7.0211 8.73113 7.0925 8.68363L8.8924 7.48603C8.9638 7.43843 9.0312 7.38323 9.0799 7.31263C9.5253 6.66783 9.0713 5.75003 8.2526 5.75003H6.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 10.75C12 10.3358 12.3358 10 12.75 10H14.1964C15.2924 10 15.8865 10.8623 15.9873 11.6368C16.0443 12.0745 15.9561 12.5878 15.6747 13C15.9561 13.4123 16.0443 13.9255 15.9873 14.3633C15.8865 15.1377 15.2924 16 14.1964 16H12.75C12.3358 16 12 15.6642 12 15.25C12 14.8358 12.3358 14.5 12.75 14.5H14.1964C14.2974 14.5 14.3496 14.4677 14.3879 14.4282C14.4369 14.3776 14.4844 14.2881 14.4998 14.1697C14.5153 14.051 14.4926 13.9477 14.4565 13.8813C14.4288 13.8302 14.391 13.793 14.3215 13.7707C14.3194 13.7706 14.3168 13.7705 14.3136 13.7704L13.7236 13.7496C13.3199 13.7353 13 13.404 13 13C13 12.5961 13.3199 12.2647 13.7236 12.2505L14.3134 12.2297C14.3166 12.2296 14.3194 12.2294 14.3216 12.2293C14.391 12.207 14.4288 12.1699 14.4565 12.1188C14.4926 12.0524 14.5153 11.949 14.4998 11.8304C14.4844 11.7119 14.4369 11.6224 14.3879 11.5719C14.3496 11.5324 14.2974 11.5 14.1964 11.5H12.75C12.3358 11.5 12 11.1642 12 10.75Z"
    />
  </svg>
</template>
