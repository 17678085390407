<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58173 3.58173 0 8 0C12.4183 0 16 3.58173 16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.21967 5.21967C5.51256 4.92678 5.98744 4.92678 6.28033 5.21967L10.7803 9.71967C11.0732 10.0126 11.0732 10.4874 10.7803 10.7803C10.4874 11.0732 10.0126 11.0732 9.71967 10.7803L5.21967 6.28033C4.92678 5.98744 4.92678 5.51256 5.21967 5.21967Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7803 5.21967C11.0732 5.51256 11.0732 5.98744 10.7803 6.28033L6.28033 10.7803C5.98744 11.0732 5.51256 11.0732 5.21967 10.7803C4.92678 10.4874 4.92678 10.0126 5.21967 9.71967L9.71967 5.21967C10.0126 4.92678 10.4874 4.92678 10.7803 5.21967Z"
    />
  </svg>
</template>
