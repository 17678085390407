import { BlueColor, SiteResponseStatus, SurveyorStatus } from "@/types/_generated/api";

export enum SortOrder {
  Ascending = "ascending",
  Descending = "descending",
  None = "none",
}

export const blueColorSortOrder: Record<BlueColor, number> = {
  [BlueColor.Blue]: 1,
  [BlueColor.Green]: 2,
  [BlueColor.Yellow]: 3,
  [BlueColor.Red]: 4,
  [BlueColor.Gray]: 5,
  [BlueColor.NotSet]: 6,
};

export const siteResponseSortOrder: Record<SiteResponseStatus, number> = {
  [SiteResponseStatus.NotSet]: 6,
  [SiteResponseStatus.NoProgress]: 4,
  [SiteResponseStatus.UnderReview]: 3,
  [SiteResponseStatus.InProgress]: 2,
  [SiteResponseStatus.Completed]: 1,
  [SiteResponseStatus.Rejected]: 5,
};

export const surveyorStatusSortOrder: Record<SurveyorStatus, number> = {
  [SurveyorStatus.Completed]: 1,
  [SurveyorStatus.InProgress]: 2,
  [SurveyorStatus.UnderReview]: 3,
  [SurveyorStatus.NoProgress]: 4,
  [SurveyorStatus.Abeyance]: 5,
  [SurveyorStatus.Withdrawn]: 6,
  [SurveyorStatus.Rejected]: 7,
  [SurveyorStatus.NotSet]: 8,
};
