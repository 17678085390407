<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDebounce } from "@/composables/useDebounce";
import {
  DraftProperties,
  ValueType,
  useQuestionnaireDraftClient,
} from "@/composables/useQuestionnaireDraftClient";
import { useUniqueId } from "@/composables/useUniqueId";
import { useQuestionnaireDraftStore } from "@/stores/questionnaire-draft";
import { QuestionnaireDraftActionType } from "@/types/_generated/api";
import BaseAutoSaveStatusVue, { State } from "@/components/base/BaseAutoSaveStatus.vue";
import QuestionnaireDraftInputHistoryButton from "./QuestionnaireDraftInputHistoryButton.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    realQuestionnaireId: number;
    type: QuestionnaireDraftActionType;
    id: number;
    propertyName: DraftProperties;
    debounceTime?: number;
    label?: string;
    orientation?: "horizontal" | "horizontal-reversed" | "vertical";
    targetName: string;
    labelFor?: string;
  }>(),
  {
    debounceTime: 2000,
    label: undefined,
    orientation: "vertical",
    labelFor: undefined,
  },
);

const debounce = useDebounce();

const autoSaveStatus = ref<State>("idle");
const client = useQuestionnaireDraftClient(props.realQuestionnaireId);
const draft = useQuestionnaireDraftStore();
const autoSaveId = useUniqueId("save");

const update = (value: ValueType | null | undefined) => {
  draft.autoSave.addAutoSave(autoSaveId);

  debounce(async () => {
    autoSaveStatus.value = "saving";
    const success = await client.updatePropertyValue(
      props.type,
      props.id,
      props.propertyName,
      value ?? "",
    );
    autoSaveStatus.value = success ? "success" : "error";
    draft.autoSave.removeAutoSave(autoSaveId);
  }, props.debounceTime);
};
</script>

<template>
  <div
    :class="[
      'questionnaire-draft__input-wrapper',
      `questionnaire-draft__input-wrapper--${orientation}`,
    ]"
  >
    <div class="questionnaire-draft__input-wrapper__header">
      <slot name="label">
        <label
          class="questionnaire-draft__input-wrapper__header__label"
          :class="{
            'questionnaire-draft__input-wrapper__header__label--clickable': !!labelFor,
          }"
          :for="labelFor"
        >
          {{
            label ??
            t(
              `questionnaire.draft.labels.${props.propertyName[0].toLowerCase()}${props.propertyName.substring(
                1,
              )}`,
            )
          }}
        </label>
      </slot>
      <div
        v-if="orientation !== 'horizontal'"
        class="questionnaire-draft__input-wrapper__header__controls"
      >
        <BaseAutoSaveStatusVue :state="autoSaveStatus" :show-label="false" />
        <QuestionnaireDraftInputHistoryButton
          @click="draft.openPropertyHistory(id, type, propertyName, targetName)"
        />
      </div>
    </div>

    <slot :update="update"></slot>

    <div
      v-if="orientation === 'horizontal'"
      class="questionnaire-draft__input-wrapper__header__controls"
    >
      <BaseAutoSaveStatusVue :state="autoSaveStatus" :show-label="false" />
      <QuestionnaireDraftInputHistoryButton
        @click="draft.openPropertyHistory(id, type, propertyName, targetName)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.questionnaire-draft__input-wrapper {
  display: flex;
  flex-direction: column;

  .questionnaire-draft-input__history-btn {
    opacity: 0;
  }

  &:hover {
    .questionnaire-draft-input__history-btn {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__controls {
      display: flex;
      align-items: center;
    }

    &__label {
      padding: $spacing-3 0 $spacing-2 0;

      &--clickable {
        cursor: pointer;
      }
    }
  }

  &--horizontal {
    padding: $spacing-1;
    gap: $spacing-2;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }

  &--horizontal > &__header {
    display: inline-flex;
  }

  &--horizontal-reversed {
    padding: $spacing-1;
    gap: $spacing-2;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &--horizontal &__header {
    gap: $spacing-2;
    width: 100%;
  }

  &--horizontal-reversed &__header {
    gap: $spacing-2;
  }
}
</style>
