<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { SurveyDepth } from "@/types/_generated/api";
import BaseModal from "@/components/base/BaseModal.vue";
import UWCheckbox from "@/components/base/UserWeb/Inputs/Checkbox/UWCheckbox.vue";

const emit = defineEmits<{
  confirm: [surveyDepth?: SurveyDepth];
  cancel: [void];
}>();

const { t } = useI18n({ useScope: "global" });

const isAreaChecked = ref(false);
const isCategoryChecked = ref(false);
const isQuestionChecked = ref(false);

const getSurveyDepth = () => {
  if (isQuestionChecked.value) {
    return SurveyDepth.Question;
  }

  if (isCategoryChecked.value) {
    return SurveyDepth.Category;
  }

  if (isAreaChecked.value) {
    return SurveyDepth.Area;
  }

  return undefined;
};
</script>

<template>
  <BaseModal
    :title="t('userWeb.daat.rawDataModal.title')"
    :confirm-text="t('common.download')"
    @confirm="emit('confirm', getSurveyDepth())"
    @cancel="emit('cancel')"
  >
    <div class="daat-raw-data-modal__include-text">
      {{ `${t("userWeb.daat.rawDataModal.includeFromSurvey")}:` }}
    </div>
    <div class="daat-raw-data-modal__checkboxes">
      <UWCheckbox
        :checked="isAreaChecked"
        @change="
          (evt) => {
            isAreaChecked = evt;

            if (!evt) {
              isCategoryChecked = evt;
              isQuestionChecked = evt;
            }
          }
        "
      >
        {{ t("userWeb.daat.rawDataModal.areas") }}
      </UWCheckbox>
      <UWCheckbox
        :checked="isCategoryChecked"
        @change="
          (evt) => {
            isCategoryChecked = evt;

            if (evt) {
              isAreaChecked = evt;
            }

            if (!evt) {
              isQuestionChecked = evt;
            }
          }
        "
      >
        {{ t("userWeb.daat.rawDataModal.categories") }}
      </UWCheckbox>
      <UWCheckbox
        :checked="isQuestionChecked"
        @change="
          (evt) => {
            isQuestionChecked = evt;

            if (evt) {
              isAreaChecked = evt;
              isCategoryChecked = evt;
            }
          }
        "
      >
        {{ t("userWeb.daat.rawDataModal.questions") }}
      </UWCheckbox>
    </div>
  </BaseModal>
</template>
<style scoped lang="scss">
.daat-raw-data-modal {
  &__include-text {
    margin-bottom: $spacing-2;
  }
  &__checkboxes {
    padding-left: $spacing-2;
  }
}
</style>
