<template>
  <svg viewBox="0 0 284 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M284 12.6044L264.39 62.9777H247.886L239.634 34.8622L231.293 62.9777H214.78L200.28 25.4311H191.259V42.2311C191.259 50.16 194.49 51.4755 198.902 51.4755C200.072 51.4497 201.236 51.3007 202.375 51.0311L206.85 62.7289C203.334 63.5775 199.728 64.0042 196.11 64C180.984 64 174.531 57.8577 174.531 43.4578V25.3867H167.648L153.041 62.9777H136.528L128.276 34.8178L119.935 62.9777H103.431L84 12.6044H102.724L112.497 43.0133L121.796 12.6044H134.756L144.163 43.0133L153.838 12.6044H174.549V3.55555L191.25 0V12.6044H214.073L223.837 43.0133L233.154 12.6044H246.105L255.521 43.0133L265.187 12.6044H284Z"
      fill="#9333EA"
    />
    <path
      d="M0 37.3199H50.68C52.4326 37.3199 54.1679 37.6658 55.7866 38.3377C57.4053 39.0096 58.8754 39.9943 60.1128 41.2354C61.3502 42.4765 62.3305 43.9496 62.9976 45.5703C63.6646 47.191 64.0053 48.9274 64 50.6799C64 54.2126 62.5966 57.6006 60.0987 60.0986C57.6007 62.5966 54.2127 63.9999 50.68 63.9999H0V37.3199Z"
      fill="url(#paint0_linear_205_2)"
    />
    <path
      d="M0 0H50.68C54.2127 0 57.6007 1.40335 60.0987 3.90134C62.5966 6.39932 64 9.78732 64 13.32C64 16.8527 62.5966 20.2407 60.0987 22.7387C57.6007 25.2366 54.2127 26.64 50.68 26.64H0V0Z"
      fill="url(#paint1_linear_205_2)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_205_2"
        x1="64.0001"
        y1="50.6599"
        x2="0"
        y2="50.6599"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D946EF" />
        <stop offset="1" stop-color="#6366F1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_205_2"
        x1="64"
        y1="13.32"
        x2="0"
        y2="13.32"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D946EF" />
        <stop offset="1" stop-color="#6366F1" />
      </linearGradient>
    </defs>
  </svg>
</template>
