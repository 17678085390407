<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { toCurrency } from "@/helpers/formatNumber";
import {
  RecommendationResponseHistoryDto,
  RecommendationResponseValueDto,
} from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseEmailLink from "@/components/base/BaseEmailLink.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

const props = defineProps<{
  currency: string;
  recommendationResponse: RecommendationResponseHistoryDto;
  title?: string;
}>();

const { t } = useI18n({ useScope: "global" });

const showUpdateBy = (response: RecommendationResponseValueDto<unknown>) =>
  response.updateBy !== props.recommendationResponse.statusUpdateBy;
const showUpdateDate = (response: RecommendationResponseValueDto<unknown>) =>
  response.updateDate !== props.recommendationResponse.statusUpdateDate;
const showUpdate = (response: RecommendationResponseValueDto<unknown>) =>
  showUpdateBy(response) || showUpdateDate(response);

const getUpdateByText = (response: RecommendationResponseValueDto<unknown>) => {
  return [
    showUpdateBy(response) ? response.updateBy : "",
    showUpdateDate(response) ? formatDate.asDateAndTime(response.updateDate) : "",
  ]
    .filter(Boolean)
    .join(" - ");
};

const firstResponseWithFiles = computed(() =>
  props.recommendationResponse.history.find((x) => x.locked && !!x.files.length),
);
</script>

<template>
  <BaseDataDisplayList
    v-if="recommendationResponse"
    :title="title"
    class="latest-recommendation-response"
  >
    <BaseDataDisplayListItem :label="t('userWeb.recommendation.responseStatus')">
      <BaseStatusBadge :status="recommendationResponse.status" />
      <i class="latest-recommendation-response__by">
        <template v-if="recommendationResponse.statusUpdateBy">
          <BaseEmailLink
            :label="recommendationResponse.statusUpdateBy"
            :email="recommendationResponse.statusUpdateBy"
          />
          -
        </template>
        {{ formatDate.asDateAndTime(recommendationResponse.statusUpdateDate) }}
      </i>
    </BaseDataDisplayListItem>

    <BaseDataDisplayListItem
      v-if="recommendationResponse.comment"
      :label="t('userWeb.recommendation.responseComment')"
    >
      <p>{{ recommendationResponse.comment.value }}</p>
      <i
        v-if="showUpdate(recommendationResponse.comment)"
        class="latest-recommendation-response__by"
      >
        {{ getUpdateByText(recommendationResponse.comment) }}
      </i>
    </BaseDataDisplayListItem>

    <BaseDataDisplayListItem
      v-if="recommendationResponse.dueDate"
      :label="t('recommendationResponse.dueDate')"
    >
      <p>{{ formatDate.asDateOnly(recommendationResponse.dueDate.value) }}</p>
      <i
        v-if="showUpdate(recommendationResponse.dueDate)"
        class="latest-recommendation-response__by"
      >
        {{ getUpdateByText(recommendationResponse.dueDate) }}
      </i>
    </BaseDataDisplayListItem>

    <BaseDataDisplayListItem
      v-if="recommendationResponse.costToComplete"
      :label="t('recommendationResponse.costToComplete')"
    >
      <p>{{ toCurrency(recommendationResponse.costToComplete.value, currency) }}</p>
      <i
        v-if="showUpdate(recommendationResponse.costToComplete)"
        class="latest-recommendation-response__by"
      >
        {{ getUpdateByText(recommendationResponse.costToComplete) }}
      </i>
    </BaseDataDisplayListItem>

    <BaseDataDisplayListItem
      v-if="recommendationResponse.responsiblePerson"
      :label="t('recommendationResponse.responsiblePerson')"
    >
      <p>{{ recommendationResponse.responsiblePerson.value }}</p>
      <i
        v-if="showUpdate(recommendationResponse.responsiblePerson)"
        class="latest-recommendation-response__by"
      >
        {{ getUpdateByText(recommendationResponse.responsiblePerson) }}
      </i>
    </BaseDataDisplayListItem>
    <BaseDataDisplayList v-if="!!firstResponseWithFiles">
      <slot name="filelist" :response="firstResponseWithFiles"></slot>
    </BaseDataDisplayList>
  </BaseDataDisplayList>
</template>

<style lang="scss" scoped>
.latest-recommendation-response {
  &__by {
    display: block;
    font-size: $text-xs;
    color: $primary-6;
    margin-top: $spacing-2;
  }
}
</style>
