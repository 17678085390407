<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { bytesToSize } from "@/helpers/bytesToSize";
import { getFileExtension } from "@/helpers/getFileExtension";
import { FileUpload } from "./BaseFileUpload.vue";
import BaseFileUploadErrorOverlay from "./BaseFileUploadErrorOverlay.vue";

const props = defineProps<{
  maxSize?: number;
  minSize?: number;
  maxFiles?: number;
  allowedFileTypes?: string[];
  disabled?: boolean;
  files: FileUpload[];
  customValidation?: (addedFiles: File[], files: FileUpload[]) => string[];
}>();

const emit = defineEmits<{
  (event: "on-drop", files: File[]): void;
}>();

const { t } = useI18n({ useScope: "global" });

const input = ref<HTMLInputElement>();
const isOverDropArea = ref(0);
const errors = ref<string[]>([]);

const onUpload = (uploadFiles: File[] | FileList | null) => {
  if (props.disabled) return;

  const inputFiles = Array.from(uploadFiles || []);

  const {
    allowedFileTypes,
    customValidation,
    maxSize,
    minSize,
    maxFiles,
    files: addedFiles,
  } = props;

  errors.value = customValidation ? customValidation(inputFiles, props.files) : [];

  if (
    allowedFileTypes?.length &&
    inputFiles.some(
      (f) => !props.allowedFileTypes?.includes(getFileExtension(f.name).toLowerCase()),
    )
  ) {
    errors.value.push(
      t("library.fileUpload.errors.fileType", {
        types: allowedFileTypes.join(", "),
      }),
    );
  }

  if (maxSize && inputFiles.some((f) => f.size > props.maxSize!)) {
    errors.value.push(
      t("library.fileUpload.errors.fileSize", {
        size: `${bytesToSize(maxSize)}`,
      }),
    );
  }

  if (minSize && inputFiles.some((f) => f.size < props.minSize!)) {
    errors.value.push(
      t("library.fileUpload.errors.fileMinSize", {
        size: `${bytesToSize(minSize)}`,
      }),
    );
  }

  if (maxFiles && addedFiles.length + inputFiles.length > maxFiles) {
    const maxFilesText =
      maxFiles === 1 ? t("library.fileUpload.file") : t("library.fileUpload.files");
    errors.value.push(
      t("library.fileUpload.errors.maxFiles", {
        maxFiles: props.maxFiles,
        text: `${maxFilesText}`,
      }),
    );
  }

  if (errors.value.length) {
    setTimeout(() => {
      errors.value = [];
    }, 3000);
  }

  if (!errors.value.length) {
    emit("on-drop", inputFiles);
  }

  input.value!.value = "";
};

const onDragEnter = () => {
  if (!props.disabled) {
    isOverDropArea.value++;
  }
};

const onDragLeave = () => {
  if (!props.disabled) {
    isOverDropArea.value--;
  }
};

const onDrop = (event: DragEvent) => {
  if (!props.disabled) {
    isOverDropArea.value = 0;
    onUpload(event.dataTransfer?.files || []);
  }
};

const onClick = () => {
  if (!props.disabled) {
    input.value?.click();
  }
};
</script>

<template>
  <div
    class="base-file-upload__drop-area"
    :class="{
      'base-file-upload__drop-area--disabled': disabled,
      'base-file-upload__drop-area--drag-over': isOverDropArea && !disabled,
    }"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent
    @drop.prevent="onDrop"
    @click="onClick"
  >
    <BaseFileUploadErrorOverlay v-if="errors.length" :errors="errors" @close="errors = []" />

    <p class="base-file-upload__drop-area__text">{{ t("library.fileUpload.dropHere") }}</p>

    <input
      v-show="false"
      ref="input"
      type="file"
      :accept="allowedFileTypes?.join(', ')"
      multiple="true"
      @change="onUpload(($event.target as HTMLInputElement).files)"
    />
  </div>
</template>

<style scoped lang="scss">
.base-file-upload__drop-area {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: $spacing-4;
  border-radius: $rounded-base;
  padding: $spacing-8;
  border: 2px dashed $primary-4;
  background-color: $primary-2;
  transition: border $duration-200;

  &:not(&--disabled) {
    &:hover,
    &.base-file-upload__drop-area--drag-over {
      cursor: pointer;
      border: 2px dashed $primary-6;
    }
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &__text {
    color: $primary-7;
    font-size: $text-sm;
    line-height: $leading-normal;
    text-align: center;
  }
}
</style>
