<script setup lang="ts">
import { LossScenarioDto, SurveyInsuredValueDto } from "@/types/_generated/api";
import SurveyEditDrawerInsuredValue, {
  type InsuredValueUpdate,
} from "./SurveyEditDrawerInsuredValue.vue";

const props = withDefaults(
  defineProps<{
    insuredValues?: SurveyInsuredValueDto[] | null;
    lossScenarios?: LossScenarioDto[] | null;
    currencyCode?: string;
  }>(),
  {
    insuredValues: () => [],
    lossScenarios: () => [],
    currencyCode: "EUR",
  },
);

export type InsuredValuesEmits = {
  update: [
    {
      index: number;
      value: InsuredValueUpdate;
    },
  ];
};

defineEmits<InsuredValuesEmits>();

const getLossScenario = (lossScenarioId: number) => {
  const lossScenario = props.lossScenarios?.find(
    (lossScenario) => lossScenario.id === lossScenarioId,
  );

  if (!lossScenario) {
    throw new Error(`Loss scenario with id ${lossScenarioId} not found`);
  }

  return lossScenario;
};
</script>

<template>
  <div class="survey-edit-drawer-insured-values">
    <SurveyEditDrawerInsuredValue
      v-for="(insuredValue, index) in insuredValues"
      :key="insuredValue.lossScenarioId"
      :insured-value="insuredValue"
      :loss-scenario="getLossScenario(insuredValue.lossScenarioId)"
      :currency-code="currencyCode"
      @update="$emit('update', { index, value: $event })"
    />
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer-insured-values {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr 1fr auto;
  gap: $spacing-2;
  column-gap: $spacing-4;
}
</style>
