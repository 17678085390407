import type { SurveyParticipantDto } from "@/types/_generated/api";
import type { SurveyParticipant } from "../components/SurveyEditDrawer/SurveyEditDrawerParticipants.vue";
import type { SurveySettings } from "../composables/useInitializeSurveySettings";

// Helper to check if a participant has empty fields
export const isParticipantEmpty = (
  participant: SurveyParticipant | SurveyParticipantDto,
): boolean => {
  // Extract the fields we want to check for empty values
  const { email, mobile, company, firstName, lastName, title } = participant;

  const fieldsToCheck = [email, mobile, company, firstName, lastName, title];

  return fieldsToCheck.every(
    (value) => value === null || value === undefined || value.trim() === "",
  );
};

// Filter out empty participants from survey settings
export const filterEmptyParticipants = (settings: SurveySettings): SurveySettings => {
  if (!settings.participants) {
    return settings;
  }

  return {
    ...settings,
    participants: settings.participants.filter((participant) => !isParticipantEmpty(participant)),
  };
};
