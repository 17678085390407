import { Ref, computed } from "vue";
import { SortOrder } from "@/constants/SortOrder";
import { sortByKeys } from "@/helpers/sort";
import { RecommendationResponseDto, SiteResponseStatus } from "@/types/_generated/api";

export const useRecommendationResponse = (responses: Ref<RecommendationResponseDto[]>) => {
  const lockedResponses = computed(() =>
    sortByKeys(
      responses.value.filter((r) => r.locked),
      { key: "updateDate", order: SortOrder.Descending },
    ),
  );

  const filteredResponses = computed((): RecommendationResponseDto[] => {
    // 1. Filter out responses that lacks data and has status set to NotSet or NoProgress
    const hasData = (r: RecommendationResponseDto) =>
      !!r.comment || !!r.costToComplete || !!r.dueDate || !!r.responsiblePerson || !!r.files;

    const reverseFiltered = lockedResponses.value
      .filter(
        (r) =>
          hasData(r) ||
          ![SiteResponseStatus.NotSet, SiteResponseStatus.NoProgress].includes(r.status),
      )
      .toReversed();

    // 2. Filter out responses that lacks data and has the same status as the next response
    return reverseFiltered
      .map((current, i) =>
        hasData(current) || i === 0 || i === reverseFiltered.length - 1
          ? current
          : reverseFiltered[i + 1].status !== current.status
            ? current
            : null,
      )
      .filter(Boolean) as RecommendationResponseDto[];
  });

  return { lockedResponses, filteredResponses };
};
