import { reactive, type DeepReadonly, readonly } from "vue";
import { useI18n } from "vue-i18n";
import type { UpdateSite, SiteDto } from "@/types/_generated/api";
import type { ErrorMessages } from "../types";

export const useValidateSiteEditForm = () => {
  const { t } = useI18n({ useScope: "global" });

  const validationState = reactive({
    address: "",
    city: "",
    country: "",
    custom1: "",
    custom2: "",
    custom3: "",
    custom4: "",
    latitude: "",
    longitude: "",
    phone: "",
    poBox: "",
    zipCode: "",
  }) as ErrorMessages;

  // Reset validation state for a given field
  const resetValidationStateField = (field: keyof UpdateSite) => {
    validationState[field] = "";
  };

  const isFormValid = (
    siteData: DeepReadonly<SiteDto> | null,
    siteEditForm: DeepReadonly<UpdateSite>,
  ): boolean => {
    // Address
    if (siteEditForm.address.length > 400) {
      validationState.address = t("validations.maxLength", { max: 400 });
    } else {
      validationState.address = "";
    }

    // City
    if (siteEditForm.city.length > 150) {
      validationState.city = t("validations.maxLength", { max: 150 });
    } else {
      validationState.city = "";
    }

    // Country
    if (siteEditForm.country.length > 100) {
      validationState.country = t("validations.maxLength", { max: 100 });
    } else {
      validationState.country = "";
    }

    // Latitude
    if (siteEditForm.latitude && (siteEditForm.latitude < -90 || siteEditForm.latitude > 90)) {
      validationState.latitude = t("validations.between", { min: -90, max: 90 });
    } else {
      validationState.latitude = "";
    }

    // Longitude
    if (siteEditForm.longitude && (siteEditForm.longitude < -180 || siteEditForm.longitude > 180)) {
      validationState.longitude = t("validations.between", { min: -180, max: 180 });
    } else {
      validationState.longitude = "";
    }

    // Phone
    if (siteEditForm.phone.length > 50) {
      validationState.phone = t("validations.maxLength", { max: 50 });
    } else {
      validationState.phone = "";
    }

    // PO Box
    if (siteEditForm.poBox.length > 20) {
      validationState.poBox = t("validations.maxLength", { max: 20 });
    } else {
      validationState.poBox = "";
    }

    // Web
    if (siteEditForm.web.length > 150) {
      validationState.web = t("validations.maxLength", { max: 150 });
    } else {
      validationState.web = "";
    }

    // Zip Code
    if (siteEditForm.zipCode.length > 20) {
      validationState.zipCode = t("validations.maxLength", { max: 20 });
    } else {
      validationState.zipCode = "";
    }

    // Custom1
    if (!siteEditForm.custom1) {
      validationState.custom1 = t("validations.required");
    } else if (siteEditForm.custom1.length > 100) {
      validationState.custom1 = t("validations.maxLength", { max: 100 });
    } else {
      validationState.custom1 = "";
    }

    // Custom2
    if (siteData?.moduleSiteDefinition2 && !siteEditForm.custom2) {
      validationState.custom2 = t("validations.required");
    } else if (siteEditForm.custom2.length > 100) {
      validationState.custom2 = t("validations.maxLength", { max: 100 });
    } else {
      validationState.custom2 = "";
    }

    // Custom3
    if (siteData?.moduleSiteDefinition3 && !siteEditForm.custom3) {
      validationState.custom3 = t("validations.required");
    } else if (siteEditForm.custom3.length > 100) {
      validationState.custom3 = t("validations.maxLength", { max: 100 });
    } else {
      validationState.custom3 = "";
    }

    // Custom4
    if (siteData?.moduleSiteDefinition4 && !siteEditForm.custom4) {
      validationState.custom4 = t("validations.required");
    } else if (siteEditForm.custom4.length > 100) {
      validationState.custom4 = t("validations.maxLength", { max: 100 });
    } else {
      validationState.custom4 = "";
    }

    const allFieldsAreValid = Object.values(validationState).every((v) => v === "");

    return allFieldsAreValid;
  };

  return {
    validationState: readonly(validationState),
    resetValidationStateField,
    isFormValid,
  };
};
