<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import router from "@/router";
import { AppRouteName } from "@/router/RouteName";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { handleError } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import BaseSpinner from "@/components/base/BaseSpinner.vue";

const { t } = useI18n({ useScope: "global" });
const auth = useAuthStore();
const app = useAppStore();

interface Props {
  username?: string;
  token?: string;
}

const props = withDefaults(defineProps<Props>(), {
  username: undefined,
  token: undefined,
});

const signInWithToken = async () => {
  try {
    const ok = await auth.signInSSO(props.username!, props.token!);
    if (ok) {
      await auth.handleSuccessfulLogin();
      app.redirectOnLogin();
    } else {
      router.push({
        name: AppRouteName.Auth,
        query: { reason: ViewAuthStatus.SsoFailed },
      });
    }
  } catch (e) {
    handleError(e);
  }
};

onBeforeMount(() => {
  signInWithToken();
});
</script>

<template>
  <div class="view-sso-response">
    <div class="view-sso-response__card">
      <BaseSpinner />

      <h1>{{ t("signIn.sso") }}</h1>
      <p>{{ t("signIn.signingIn") }} {{ props.username }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view-sso-response {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    font-size: $text-2xl;
    font-weight: $font-bold;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-4;
    padding: $spacing-6;
    max-width: 25rem;
    background-color: $primary-2;
    border-radius: $rounded-lg;
    border: 1px solid $primary-4;
  }
}
</style>
