<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import http from "@/helpers/http";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { handleError } from "@/services/shared/handleError";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  email: string;
}>();

defineEmits<{
  "update:email": [value: string];
  "view-change": [view: ViewAuthStatus];
}>();

const isLoading = ref(false);
const isResetEmailSent = ref(false);

const sendResetPasswordEmail = async () => {
  isLoading.value = true;

  try {
    await http.post(`auth/forgot-password`, { email: props.email });
    isResetEmailSent.value = true;
  } catch (error) {
    handleError(error);
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <div class="auth-forgot">
    <BaseAlert v-if="isResetEmailSent" severity="success">
      <div>
        <p>{{ t("signIn.passwordReset.success.message1") }}</p>
        <p>{{ t("signIn.passwordReset.success.message2") }}</p>
        <p>
          {{ t("signIn.passwordReset.success.message3") }}
          <a href="mailto:bluesupport@wtwco.com"> bluesupport@wtwco.com </a>
        </p>
      </div>
    </BaseAlert>

    <BaseAlert severity="info">
      <p>{{ t("signIn.passwordReset.success.info") }}</p>
    </BaseAlert>

    <form class="auth-forgot__form" @submit.prevent="sendResetPasswordEmail">
      <BaseTextField
        v-if="!isResetEmailSent"
        :label="t('common.email')"
        :value="email"
        :disabled="isLoading"
        @update:value="$emit('update:email', String($event))"
      />
      <BaseButton v-if="!isResetEmailSent" :disabled="isLoading" type="submit">
        {{ t("signIn.passwordReset.cta") }}
      </BaseButton>
    </form>

    <BaseButton variant="text" @click="$emit('view-change', ViewAuthStatus.SignIn)">
      {{ t("signIn.return") }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.auth-forgot {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    width: 100%;
  }
}
</style>
