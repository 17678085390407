<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";

type Props = {
  timelineValue?: boolean;
  abeyanceValue?: boolean;
  abeyanceTranslation?: string;
  completedValue?: boolean;
  showTimeline?: boolean;
  showAbeyance?: boolean;
  showCompleted?: boolean;
};

defineProps<Props>();

const emit = defineEmits<{
  "update:timeline": [boolean];
  "update:abeyance": [boolean];
  "update:completed": [boolean];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="online-report-header-settings">
    <BaseCheckbox
      v-if="showTimeline"
      :checked="timelineValue ?? false"
      :label="t('userWeb.onlineReport.showSiteResponseHistory')"
      @change="emit('update:timeline', !timelineValue)"
    />
    <BaseCheckbox
      v-if="showAbeyance"
      :checked="abeyanceValue ?? false"
      :label="
        t('userWeb.onlineReport.showRecommendationsWithStatus', {
          status: abeyanceTranslation ?? t('blueRating.surveyStatus.abeyance'),
        })
      "
      @change="emit('update:abeyance', !abeyanceValue)"
    />
    <BaseCheckbox
      v-if="showCompleted"
      :checked="completedValue ?? false"
      :label="t('userWeb.onlineReport.showCompletedRecommendations')"
      @change="emit('update:completed', !completedValue)"
    />
  </div>
</template>

<style scoped lang="scss">
.online-report-header-settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: $spacing-1;
  border-right: 1px solid $primary-5;
  padding-right: $spacing-4;
}
</style>
