<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import { BORDER, Y_AXIS_STYLE } from "@/components/base/BaseChart/constants/style";
import { truncateLabel, tooltipFormatter } from "@/components/base/BaseChart/helpers/";

type SeriesData = {
  key: string;
  label: string;
};

const props = defineProps<{
  labels: string[];
  colors: Record<string, string>;
  series: SeriesData[];
  data: number[][];
}>();

const { t } = useI18n({ useScope: "global" });

const shouldRotateLabels = computed(() => props.labels.length > 10 || window.innerWidth < 1536);

const option = {
  legend: { selectedMode: true, bottom: 0 },
  grid: {
    top: 10,
    left: 35,
    right: 50,
    bottom: 110,
    containLabel: true,
  },
  yAxis: {
    type: "value",
    name: t("userWeb.recommendationTracking.siteResponseBlueRatingChart.yAxisName"),
    ...Y_AXIS_STYLE,
  },
  xAxis: {
    type: "category",
    data: props.labels,
    axisLabel: {
      rotate: shouldRotateLabels.value ? -45 : 0,
      formatter: (value: string) => {
        if (shouldRotateLabels.value) {
          return truncateLabel(value, 20);
        }
        return value;
      },
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: tooltipFormatter,
  },
  series: props.series.map((serie, index) => ({
    name: serie.label,
    type: "bar",
    stack: "total",
    data: props.data[index],
    itemStyle: {
      color: props.colors[serie.key],
      borderColor: BORDER.COLOR,
      borderWidth: BORDER.WIDTH,
    },
  })),
};
</script>

<template>
  <BaseChart :option="option" />
</template>
