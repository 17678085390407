import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { SiteDefinitions } from "@/composables/services/useGetSites";
import { useSiteDefinitionColumns } from "@/composables/useSiteDefinitionColumns";
import { recResponseStatus, reportDownloadStatus } from "@/helpers/options";
import { SiteLeanDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";

export const useSiteTableColumns = (siteDefinitions: Ref<SiteDefinitions | null>) => {
  const { t } = useI18n({ useScope: "global" });

  const { siteDefinitionColumns } = useSiteDefinitionColumns<SiteLeanDto>(siteDefinitions);

  const columns = computed<ColumnDefinition<SiteLeanDto>[]>(() => [
    ...siteDefinitionColumns.value,
    new ColumnDefinition({
      title: t("sites.surveys"),
      key: "surveyCount",
      alignContent: "right",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.latestSurveyDate"),
      key: "latestSurveyDate",
      type: ColumnDataType.Date,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.nextSurveyDate"),
      key: "nextSurveyDate",
      type: ColumnDataType.Date,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.surveyor"),
      key: "surveyorName",
    }),
    new ColumnDefinition({
      title: t("sites.firstReportMailDate"),
      key: "firstReportMailDate",
      autoWidth: true,
      type: ColumnDataType.Date,
    }),
    new ColumnDefinition({
      title: t("sites.reportDownloadStatus"),
      key: "reportDownloadStatus",
      autoWidth: true,
      searchOptions: reportDownloadStatus,
    }),
    new ColumnDefinition({
      title: t("sites.recommendationResponseStatus"),
      key: "recResponseSubmitDate",
      autoWidth: true,
      searchOptions: recResponseStatus,
    }),
    new ColumnDefinition({
      title: t("sites.daysWithNoResponse"),
      key: "daysWithNoResponse",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("sites.reminderEmailCount"),
      key: "reminderEmailCount",
      alignContent: "right",
      autoWidth: true,
    }),
  ]);

  return { columns };
};
