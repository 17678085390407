<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetClient } from "@/composables/services/useGetClient";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useValidateForm } from "@/composables/validation/useValidateForm";
import { required } from "@/composables/validation/validators";
import { intersectAssign } from "@/helpers/intersectAssign";
import { createClient, updateClient } from "@/services/clients";
import { CreateClient, Status, UpdateClient } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import ClientStatusSelect from "./ClientStatusSelect.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{ clientId: number | null }>();

const emits = defineEmits<{ (event: "close"): void }>();
const { isLoadingClient, getClient } = useGetClient();

const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

class EditClientForm {
  name = "";
  willisOffice = "";
  willisCountryOrUnit = "";
  website = "";
  status = Status.UnderConstruction;
  clientId?: number;
}

const isEdit = computed(() => !!props.clientId);

const form = ref<EditClientForm>(new EditClientForm());

const { isValid, errors, updateFormField } = useValidateForm<EditClientForm>(form, {
  name: { required },
  willisCountryOrUnit: { required },
  willisOffice: { required },
});

onBeforeMount(async () => {
  if (props.clientId) {
    const client = await getClient(props.clientId);
    form.value = intersectAssign(new EditClientForm(), client) as EditClientForm;
  }

  setCompareBase(form);
});

const saveClient = async (form: EditClientForm) => {
  if (!isValid()) {
    return;
  }

  const clientId = await (isEdit.value
    ? updateClient(form as UpdateClient)
    : createClient(form as CreateClient));

  if (clientId !== null) {
    setCompareBase(form);
  }

  emits("close");
};

const close = async () => {
  if (await discardUnsavedChanges(form)) {
    emits("close");
  }
};
</script>

<template>
  <BaseDrawer
    :title="form?.clientId ? t('clients.edit') : t('clients.create')"
    :subtitle="form?.name"
    :is-loading="isLoadingClient"
    width="30"
    @close="close"
  >
    <section class="client-edit-drawer">
      <BaseTextField
        :value="form.name"
        :label="t('common.name')"
        :error-message="errors.name"
        required
        @update:value="updateFormField('name', $event as string)"
      />
      <BaseTextField
        :value="form.willisCountryOrUnit"
        :label="t('clients.country')"
        :error-message="errors.willisCountryOrUnit"
        required
        @update:value="updateFormField('willisCountryOrUnit', $event as string)"
      />
      <BaseTextField v-model:value="form.website" :label="t('clients.website')" />
      <BaseTextField
        :value="form.willisOffice"
        :label="t('clients.office')"
        :error-message="errors.willisOffice"
        required
        @update:value="updateFormField('willisOffice', $event as string)"
      />

      <ClientStatusSelect v-if="form?.clientId" v-model:value="form.status" />
    </section>

    <template #footer-left>
      <BaseButton @click="() => saveClient(form)">
        {{ isEdit ? t("common.actions.save") : t("common.actions.create") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="close">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.client-edit-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: $spacing-6;
}
</style>
