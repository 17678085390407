import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { UserWebRouteName } from "@/router/RouteName";
import { SiteLeanDto } from "@/types/_generated/api";
import type { DashboardHistoryItem } from "../types";

const transformSiteResponseHistoryData = (
  sites: SiteLeanDto[],
  moduleId: number,
): DashboardHistoryItem[] => {
  return sites
    .filter((s) => s.recResponseSubmitDate)
    .map((item) => ({
      site: item.custom1 || "Unknown Site",
      date: formatDate.asDateOnly(item.recResponseSubmitDate),
      routerLink: {
        name: UserWebRouteName.Site,
        params: { siteId: item.siteId, moduleId },
      },
    }));
};

export const useRecResponseHistory = (sites: Ref<SiteLeanDto[]>, moduleId: number) => {
  const { t } = useI18n({ useScope: "global" });

  const siteResponseHistory = computed(() =>
    transformSiteResponseHistoryData(sites.value, moduleId),
  );

  const historyColumns: { title: string; key: keyof DashboardHistoryItem }[] = [
    { title: t("userWeb.dashboard.table.site"), key: "site" },
    { title: t("userWeb.dashboard.table.date"), key: "date" },
  ];

  return {
    historyColumns,
    siteResponseHistory,
  };
};
