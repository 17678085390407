<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import { FileUpload } from "./BaseFileUpload.vue";

const props = defineProps<{
  file: FileUpload;
  isImage: boolean;
}>();

const thumbnail = ref<string>();

onBeforeMount(async () => resizeAndCropImage(props.file.file));

const resizeAndCropImage = (file: File) => {
  const img = document.createElement("img");
  img.src = URL.createObjectURL(file);
  img.onload = function () {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;

    const targetWidth = 50;
    const targetHeight = 50;

    let { width, height } = img;

    let offsetX = 0;
    let offsetY = 0;

    if (width > height) {
      height = targetHeight;
      width = (img.width * height) / img.height;
      offsetX = (width - targetWidth) / 2;
    } else {
      width = targetWidth;
      height = (img.height * width) / img.width;
      offsetY = (height - targetHeight) / 2;
    }

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    ctx.drawImage(img, -offsetX, -offsetY, width, height);

    thumbnail.value = canvas.toDataURL("image/png");
  };
};
</script>

<template>
  <div class="base-upload-file__list__file__thumbnail">
    <img
      v-if="isImage && thumbnail"
      class="base-upload-file__list__file__thumbnail__image"
      :class="{
        [`base-upload-file__list__file__thumbnail__image--${file.rotation.toLowerCase()}`]: true,
      }"
      :src="thumbnail"
      :alt="file.file.name"
    />
    <BaseIcon
      v-else
      class="base-upload-file__list__file__thumbnail__file-icon"
      icon="file"
      size="xxlarge"
    />
  </div>
</template>

<style scoped lang="scss">
.base-upload-file__list__file__thumbnail {
  display: flex;

  &__file-icon {
    color: $primary-5;
  }

  &__image {
    transition: transform 0.25s;

    &--none {
      transform: rotate(0deg);
    }

    &--rotate90 {
      transform: rotate(90deg);
    }

    &--rotate180 {
      transform: rotate(180deg);
    }

    &--rotate270 {
      transform: rotate(270deg);
    }
  }
}
</style>
