<script setup lang="ts">
import { computed } from "vue";
import type { ExecutiveReportSurveyDto, ModuleDto } from "@/types/_generated/api";
import BlueRatingTableCellColor from "@/components/shared/BlueRatingTable/BlueRatingTableCellColor.vue";
import ExecSummaryTableCellSite from "../ExecSummaryTable/ExecSummaryTableCells/ExecSummaryTableCellSite.vue";

const props = defineProps<{
  categoryIndex: number;
  survey: ExecutiveReportSurveyDto;
  showScore: boolean;
  showPercentage: boolean;
  abeyanceShort: ModuleDto["abeyanceShort"];
}>();

const categoryData = computed(() => props.survey.categoryData[props.categoryIndex]);
</script>

<template>
  <tr>
    <BlueRatingTableCellColor
      :color="categoryData.color"
      :completed="{ isVisible: categoryData.completed, abbreviation: 'C' }"
      :abeyance="{ isVisible: categoryData.abeyance, abbreviation: abeyanceShort }"
    />

    <ExecSummaryTableCellSite :row="survey" />

    <BlueRatingTableCellColor
      v-for="(reply, index) in categoryData.replies"
      :key="`${survey.surveyId}_${index}`"
      :color="reply.color"
    />

    <td>{{ survey.type }}</td>

    <td v-if="showScore" data-right-align>
      {{ categoryData.score }}
    </td>

    <td v-if="showPercentage" data-right-align>
      {{ categoryData.scorePercent }}
    </td>
  </tr>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
