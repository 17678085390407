<script setup lang="ts">
import { ref } from "vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

interface Props {
  expandedByDefault?: boolean;
  label: string;
}

const props = defineProps<Props>();

const isExpanded = ref(props.expandedByDefault);

const toggleAccordion = () => (isExpanded.value = !isExpanded.value);
</script>

<template>
  <div class="base-accordion">
    <button type="button" class="base-accordion__btn" @click="toggleAccordion">
      <p class="base-accordion__label">{{ label }}</p>

      <BaseIcon
        class="base-accordion__icon"
        :class="{ 'base-accordion__icon--rotate': isExpanded }"
        icon="chevron-up"
      />
    </button>

    <div
      class="base-accordion__options"
      :class="{ 'base-accordion__options--expanded': isExpanded }"
    >
      <div class="base-accordion__options__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);

.base-accordion {
  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $spacing-4;
    background: $secondary-6;
    color: $primary-0;
    transition: background-color $duration-100;

    &:hover {
      background-color: $secondary-5;
    }
  }

  &__label {
    font-size: $text-sm;
    font-weight: $font-medium;
  }

  &__icon {
    transition: transform $duration-300 $transition-timing-function;
    &--rotate {
      transform: rotate(-180deg);
    }
  }

  &__options {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows $duration-300 $transition-timing-function;

    &--expanded {
      grid-template-rows: 1fr;
    }

    &__content {
      overflow: hidden;
    }
  }
}
</style>
