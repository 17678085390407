import { useI18n } from "vue-i18n";
import { createBlueRatingRecommendation } from "@/services/recommendations";
import {
  BlueColor,
  CreateBlueRatingRecommendation,
  LossEstimateType,
  BlueRatingCategoryDto,
  SurveyorStatus,
} from "@/types/_generated/api";
const getDefaultHeader = (category: BlueRatingCategoryDto, questionId: number | null): string => {
  let header = "";
  if (category.categoryId === category.categoryId) {
    header = `${category.name} - `;

    if (questionId !== null) {
      const question = category.questions.find((q) => q.questionId === Number(questionId));

      if (question) {
        header += question.text;
      }
    }
  }
  return header;
};

export const useCreateRecommendationWithDefaults = async (
  t: ReturnType<typeof useI18n>["t"],
  surveyId: number,
  category: BlueRatingCategoryDto | null,
  questionId: number | null,
): Promise<number | null> => {
  const recommendation: CreateBlueRatingRecommendation = {
    abeyanceComment: "",
    categoryId: category?.categoryId,
    categoryColorOverride: BlueColor.NotSet,
    comment: "",
    completedComment: "",
    header: category ? getDefaultHeader(category, questionId) || "" : "",
    isGeneralRecommendation: category === null && questionId === null,
    isKeyRecommendation: false,
    location: "",
    priority: "",
    questionIds: questionId ? [questionId] : [],
    rcbaDescription: "",
    rcbaProbability: "",
    surveyId,
    surveyorStatus: SurveyorStatus.NotSet,
    text: "",
    type: t("blueRating.recommendationTypes.humanElement"),
    rcbaLossEstimateType: LossEstimateType.Percent,
  };

  const result = await createBlueRatingRecommendation(surveyId, recommendation);

  return result ?? null;
};
