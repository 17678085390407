<script setup lang="ts">
import { computed, getCurrentInstance } from "vue";
import BaseSkeleton from "../BaseSkeleton.vue";

interface Props {
  title?: string;
  hasPadding?: boolean;
  isLoading?: boolean;
  arialabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  hasPadding: false,
  isLoading: undefined,
  arialabel: undefined,
});

defineEmits<{
  (event: "click", option: MouseEvent): void;
}>();

const currentInstance = getCurrentInstance();
const hasClickListener = computed(() => !!currentInstance?.vnode?.props?.onClick);
</script>

<template>
  <div class="base-card" :class="{ 'base-card--padding': hasPadding }" role="list">
    <div
      v-if="title || $slots.header || $slots.title"
      :class="{
        'base-card__header': true,
        'base-card__header--clickable': hasClickListener,
      }"
      @click="$emit('click', $event)"
    >
      <h2 class="base-card__header__title">
        <slot name="title">{{ props.title }}</slot>
      </h2>

      <slot v-if="!isLoading" name="header" />
    </div>

    <div class="base-card__content">
      <slot v-if="!isLoading" />

      <BaseSkeleton
        v-if="isLoading"
        class="base-card__content__skeleton"
        :set="5"
        width="100%"
        height="1.5rem"
        mb="0.25rem"
      />
    </div>
    <div class="base-card__footer">
      <slot v-if="!isLoading" name="footer" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-card {
  font-size: $text-sm;
  line-height: $leading-tight;

  &--padding {
    padding: $spacing-4;
  }

  &__content {
    display: grid;
  }

  &__header + &__content {
    padding-top: $spacing-2;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: $spacing-3;
    align-items: center;
    border-bottom: 1px solid $primary-4;
    padding: $spacing-2;

    &--clickable {
      cursor: pointer;
    }

    &__title {
      font-size: $text-lg;
      margin-bottom: $spacing-4;
      font-weight: $font-medium;
    }
  }
}
</style>
