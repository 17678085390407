import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  ActivityLogEntryDto,
  ErrorLogEntryDto,
  GetActivityLogResponse,
  GetErrorLogResponse,
  GetLoginAttemptLogResponse,
  LoginAttemptDto,
} from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getLoginAttemptLog = async (): Promise<LoginAttemptDto[] | null> => {
  try {
    const {
      data: { log },
    } = await http.get<GetLoginAttemptLogResponse>("/system/logs/login-attempts");

    return log;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("common.entities.loginAttemptLog"),
    });
  }
  return null;
};

export const getErrorLog = async (): Promise<ErrorLogEntryDto[] | null> => {
  try {
    const {
      data: { errorLog },
    } = await http.get<GetErrorLogResponse>("/system/logs/errors");

    return errorLog;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("systemLog.errorLog"),
    });
  }
  return null;
};

export const getActivityLog = async (): Promise<ActivityLogEntryDto[] | null> => {
  try {
    const {
      data: { activityLog },
    } = await http.get<GetActivityLogResponse>("/system/logs/activities");

    return activityLog;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("systemLog.activityLog.title"),
    });
  }

  return null;
};
