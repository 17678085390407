export const emptyValue = "-";

export const displayValue = (value: string | number | undefined): string => {
  // Check for values that should be considered as empty
  if (value === undefined || value === null || value === "") {
    return emptyValue;
  }
  // Otherwise, return the value as a string
  return value.toString();
};
