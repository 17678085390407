import { readonly, ref } from "vue";
import { useI18n } from "vue-i18n";
import { saveRiskManagerComments as saveRiskManagerCommentsService } from "@/services/riskManagerComments";
import { notify } from "@/stores/notifications";
import { SaveRiskManagerComments } from "@/types/_generated/api";

export const useRiskManagerComments = () => {
  const { t } = useI18n({ useScope: "global" });
  const isSavingRMComments = ref(false);

  const saveRMComments = async (settings: SaveRiskManagerComments) => {
    isSavingRMComments.value = true;

    const isSuccess = await saveRiskManagerCommentsService(settings);

    if (isSuccess) {
      notify.success(t("survey.riskManagerComments"), t("common.saved").toLowerCase());
    }

    isSavingRMComments.value = false;
  };

  return {
    isSavingRMComments: readonly(isSavingRMComments),
    saveRMComments,
  };
};
