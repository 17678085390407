<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ListFileDto } from "@/types/_generated/api";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";

defineProps<{
  report?: ListFileDto;
  attachments?: ListFileDto[];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="summary-files">
    <div v-if="report">
      <p class="summary-files__header">{{ t("recResponse.files.downloadBlueReport") }}</p>
      <BaseFile :file="report" :file-url="`/v1/recommendation-response/files/${report.fileId}`" />
    </div>

    <div v-if="attachments?.length">
      <p class="summary-files__header">{{ t("recResponse.files.attachments") }}</p>
      <div class="summary-files__attachments">
        <BaseFile
          v-for="attachment in attachments"
          :key="attachment.fileId"
          :file="attachment"
          :file-url="`/v1/recommendation-response/files/${attachment.fileId}`"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.summary-files {
  display: flex;
  flex-direction: row;
  gap: $spacing-8;
  border: 1px solid $primary-5;
  padding: $spacing-4;
  border-radius: $rounded-base;

  &__header {
    font-size: $text-base;
    font-weight: $font-semibold;
    margin-bottom: $spacing-2;
    white-space: nowrap;
  }
  &__attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $spacing-4;
  }
}
</style>
