import { useI18n } from "vue-i18n";

type Cardinal = "N" | "S" | "E" | "W";

export const toDegreesMinutesAndSeconds = (latitude: number, longitude: number): string | null => {
  const { t } = useI18n({ useScope: "global" });

  // Invalid coordinate
  if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
    return t("map.status.invalidCoordinates");
  }

  // Latitude North/South
  const latitudeCardinal = latitude >= 0 ? "N" : "S";
  // Longitude East/West
  const longitudeCardinal = longitude >= 0 ? "E" : "W";

  return `${toString(latitude, latitudeCardinal)}, ${toString(longitude, longitudeCardinal)}`;
};

const toString = (coordinate: number, cardinal: Cardinal): string => {
  const { degrees, minutes, seconds } = toDegreesMinutesAndSecondsHelper(coordinate);
  return `${degrees}° ${minutes}' ${seconds}" ${cardinal}`;
};

const toDegreesMinutesAndSecondsHelper = (
  coordinate: number,
): { degrees: number; minutes: number; seconds: number } => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return { degrees, minutes, seconds };
};
