<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.14286C0 0.511677 0.511677 0 1.14286 0C1.77404 0 2.28571 0.511677 2.28571 1.14286C2.28571 1.77404 1.77404 2.28571 1.14286 2.28571C0.511677 2.28571 0 1.77404 0 1.14286Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8C0 7.3689 0.511648 6.85715 1.14286 6.85715C1.77407 6.85715 2.28571 7.3689 2.28571 8C2.28571 8.63111 1.77407 9.14286 1.14286 9.14286C0.511648 9.14286 0 8.63111 0 8Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 14.8571C0 14.226 0.511648 13.7143 1.14286 13.7143C1.77407 13.7143 2.28571 14.226 2.28571 14.8571C2.28571 15.4882 1.77407 16 1.14286 16C0.511648 16 0 15.4882 0 14.8571Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.57144 1.14286C4.57144 0.669477 4.9552 0.285721 5.42858 0.285721H15.1429C15.6163 0.285721 16 0.669477 16 1.14286C16 1.61625 15.6163 2.00001 15.1429 2.00001H5.42858C4.9552 2.00001 4.57144 1.61625 4.57144 1.14286Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.57144 8C4.57144 7.52661 4.9552 7.14285 5.42858 7.14285H15.1429C15.6163 7.14285 16 7.52661 16 8C16 8.47338 15.6163 8.85714 15.1429 8.85714H5.42858C4.9552 8.85714 4.57144 8.47338 4.57144 8Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.57144 14.8571C4.57144 14.3838 4.9552 14 5.42858 14H15.1429C15.6163 14 16 14.3838 16 14.8571C16 15.3305 15.6163 15.7143 15.1429 15.7143H5.42858C4.9552 15.7143 4.57144 15.3305 4.57144 14.8571Z"
    />
  </svg>
</template>
