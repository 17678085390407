<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRcba } from "@/composables/useRcba";
import { toCurrency } from "@/helpers/formatNumber";
import { type RiskCostBenefitAnalysisDto, LossEstimateType } from "@/types/_generated/api";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";

type Props = {
  rcba: RiskCostBenefitAnalysisDto;
};

const props = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const {
  isValid: isValidRcba,
  priorSum,
  afterSum,
  formatSum,
  costBenefitRatio,
  hasExtra,
  hasProbability,
  biSum,
  pdSum,
} = useRcba(toRef(() => props.rcba));
</script>

<template>
  <template v-if="isValidRcba">
    <BaseDataDisplayListItem
      v-if="hasProbability"
      :label="t('userWeb.onlineReport.recommendations.rcba.scenarioDescription')"
    >
      <BaseSafeHtml :html="rcba.rcbaDescription" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="hasProbability"
      :label="t('userWeb.onlineReport.recommendations.rcba.probability')"
    >
      <p>{{ rcba.rcbaProbability }}</p>
    </BaseDataDisplayListItem>

    <BaseDataDisplayListItem
      :label="t('userWeb.onlineReport.recommendations.rcba.costBenefitAnalysis')"
    >
      <table class="rec-rcba-props__table">
        <thead>
          <tr>
            <th></th>
            <th>{{ t("userWeb.onlineReport.recommendations.rcba.prior") }}</th>
            <th>{{ t("userWeb.onlineReport.recommendations.rcba.after") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.property") }}</td>
            <td>{{ formatSum(rcba.rcbaPropertyPrior) }}</td>
            <td>{{ formatSum(rcba.rcbaPropertyAfter) }}</td>
          </tr>
          <tr>
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.bi") }}</td>
            <td>{{ formatSum(rcba.rcbaBiPrior) }}</td>
            <td>{{ formatSum(rcba.rcbaBiAfter) }}</td>
          </tr>
          <tr v-if="hasExtra">
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.extra") }}</td>
            <td>{{ formatSum(rcba.rcbaExtraPrior) }}</td>
            <td>{{ formatSum(rcba.rcbaExtraAfter) }}</td>
          </tr>
          <tr
            v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary"
            class="rec-rcba-props__table__total"
          >
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.total") }}</td>
            <td>{{ formatSum(priorSum) }}</td>
            <td>{{ formatSum(afterSum) }}</td>
          </tr>
        </tbody>
      </table>

      <table class="rec-rcba-props__table">
        <tbody>
          <tr>
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.costToComplete") }}</td>
            <td colspan="2">{{ toCurrency(rcba.rcbaCostToComplete ?? 0, rcba.rcbaCurrency) }}</td>
          </tr>
          <tr v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary">
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.riskReduction") }}</td>
            <td colspan="2">{{ formatSum(priorSum - afterSum) }}</td>
          </tr>
          <tr v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary">
            <td>{{ t("userWeb.onlineReport.recommendations.rcba.costBenefitRatio") }}</td>
            <td colspan="2">{{ costBenefitRatio }}</td>
          </tr>
          <tr v-if="rcba.rcbaLossEstimateType === LossEstimateType.Percent">
            <td colspan="3">
              {{
                t("blueRating.recommendation.investmentGivesRiskReductionPD", {
                  cost: toCurrency(rcba.rcbaCostToComplete ?? 0, props.rcba.rcbaCurrency),
                  riskReduction: formatSum(pdSum),
                })
              }}
            </td>
          </tr>
          <tr v-if="rcba.rcbaLossEstimateType === LossEstimateType.Percent">
            <td colspan="3">
              {{
                t("blueRating.recommendation.investmentGivesRiskReductionBI", {
                  cost: toCurrency(rcba.rcbaCostToComplete ?? 0, props.rcba.rcbaCurrency),
                  riskReduction: formatSum(biSum),
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </BaseDataDisplayListItem>
  </template>
</template>

<style lang="scss" scoped>
.rec-rcba-props {
  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid $primary-9;
      padding: $spacing-2;
    }

    td:nth-child(2),
    td:nth-child(3) {
      text-align: right;
    }

    th {
      background-color: $primary-2;
      font-weight: $font-semibold;
    }

    &__total > td {
      font-style: italic;
    }
  }

  &__table + &__table {
    margin-top: $spacing-4;
  }
}
</style>
