import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { binaryOptions } from "@/helpers/options";
import { Role, UserModulePermissionDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";

export const useUserPermissionsTableColumns = (userRole: Ref<Role>) => {
  const columns = computed(() => {
    const { t } = useI18n({ useScope: "global" });

    const items: ColumnDefinition<UserModulePermissionDto>[] = [
      new ColumnDefinition<UserModulePermissionDto>({
        key: "clientName",
        title: t("clients.client"),
      }),
      new ColumnDefinition<UserModulePermissionDto>({
        key: "moduleName",
        title: t("modules.module"),
      }),
    ];

    if (userRole.value === Role.EXT) {
      items.push(
        new ColumnDefinition<UserModulePermissionDto>({
          key: "createSite",
          title: t("users.createSite"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),

        new ColumnDefinition<UserModulePermissionDto>({
          key: "createSurvey",
          title: t("users.createSurvey"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
        new ColumnDefinition<UserModulePermissionDto>({
          key: "editSurvey",
          title: t("users.editSurvey"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),

        new ColumnDefinition<UserModulePermissionDto>({
          key: "uploadStdRec",
          title: t("users.uploadStdRec"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
      );
    }

    if (userRole.value === Role.INT) {
      items.push(
        new ColumnDefinition<UserModulePermissionDto>({
          key: "editQuestionnaire",
          title: t("users.editQuestionnaire"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
        new ColumnDefinition<UserModulePermissionDto>({
          key: "uploadFiles",
          title: t("common.actions.uploadFiles"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
        new ColumnDefinition<UserModulePermissionDto>({
          key: "uploadStdRec",
          title: t("users.uploadStdRec"),
          type: ColumnDataType.Boolean,
          searchOptions: binaryOptions,
          autoWidth: true,
        }),
      );
    }

    return items;
  });

  return { userPermissionsTableColumns: columns };
};
