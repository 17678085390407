<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { LibraryFileDto, SurveyDto } from "@/types/_generated/api";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  files: LibraryFileDto[];
  includeLibraryFileIds: string[];
  survey: SurveyDto | null;
}>();

defineEmits<{
  "add-attachment": [fileId: string];
  "delete-attachment": [fileId: string];
}>();
</script>

<template>
  <BaseSelect
    :label="t('survey.surveyReportMail.selectAttachment')"
    @change="($event) => $emit('add-attachment', $event as string)"
  >
    <BaseOption v-for="option in files" :key="option.fileId" :value="option.fileId">
      {{ option.fileName }}
    </BaseOption>
  </BaseSelect>

  <div v-if="includeLibraryFileIds.length > 0" class="survey-report-mail__form__file-attachment">
    <template
      v-for="(file, i) in files.filter((f) => includeLibraryFileIds.includes(f.fileId))"
      :key="i"
    >
      <BaseFile
        edit
        :file="file"
        :file-url="`/v1/modules/${survey?.moduleId}/library-files/${file?.fileId}`"
        @delete="$emit('delete-attachment', file.fileId)"
      />
    </template>
  </div>
</template>

<style scoped>
.survey-report-mail__form__file-attachment {
  max-width: 100%;
  overflow-x: hidden;
}
</style>
