import type {
  GetLibraryFilesResponse,
  LibraryFileDto,
  UpdateLibraryFile,
} from "@/types/_generated/api";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import { type BaseCRUDMessage, BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const libraryEvents = new BroadcastService<BaseCRUDMessage>("library");

const { t } = i18n.global;

export const getLibraryFilesService = async (
  moduleId: number,
  filter?: { fileExtensions?: string[]; onlyVisibleInUserWeb?: boolean },
): Promise<LibraryFileDto[] | null> => {
  try {
    const queryParts = [];

    if (filter?.fileExtensions?.length) {
      queryParts.push(`filterByFileExtensions=[${filter.fileExtensions.join(",")}]`);
    }

    if (filter?.onlyVisibleInUserWeb) {
      queryParts.push("onlyVisibleInUserWeb=true");
    }

    const query = queryParts.length ? `?${queryParts.join("&")}` : "";

    const {
      data: { libraryFiles },
    } = await http.get<GetLibraryFilesResponse>(`/modules/${moduleId}/library-files${query}`);
    return libraryFiles;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch"),
      entity: t("library.libraryFiles"),
    });
  }
  return null;
};

export const updateLibraryFileService = async (file: UpdateLibraryFile): Promise<void> => {
  try {
    await http.put(`/modules/${file.moduleId}/library-files/${file.fileId}`, file);
    libraryEvents.post({ action: "update" });
    notify.success(t("library.fileUpload.file"), t("common.updated").toLowerCase());
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update"),
      entity: t("library.libraryFiles"),
    });
    throw error;
  }
};

export const deleteLibraryFileService = async (moduleId: number, fileId: string): Promise<void> => {
  try {
    await http.delete(`/modules/${moduleId}/library-files/${fileId}`);
    libraryEvents.post({ action: "delete" });
    notify.success(t("library.fileUpload.file"), t("common.deleted").toLowerCase());
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete"),
      entity: t("library.libraryFiles"),
    });
    throw error;
  }
};
