<script setup lang="ts">
import { computed } from "vue";
import type { CustomDefinitionNumber, SiteDefinition } from "@/types/SiteDefinitions";
import { SitePermissionFilterDto } from "@/types/_generated/api";
import UserSitePermissionFilterEmpty from "./UserSitePermissionFilterEmpty.vue";
import UserSitePermissionFilterSelect from "./UserSitePermissionFilterSelect.vue";
import UserSitePermissionFilterValue from "./UserSitePermissionFilterValue.vue";

const props = defineProps<{
  siteDefinition: SiteDefinition;
  permissionFilter: SitePermissionFilterDto[];
}>();

defineEmits<{
  add: [string];
  remove: [string];
}>();

const sitePermissionFilterMap = computed(() =>
  props.permissionFilter.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.customDefinitionNumber]: curr.values,
    }),
    {} as Record<CustomDefinitionNumber, string[]>,
  ),
);
</script>

<template>
  <div class="user-site-permission-filter__site-definition">
    <UserSitePermissionFilterSelect
      :site-definition="siteDefinition"
      :permission-filter="permissionFilter"
      @add="$emit('add', $event)"
    />

    <div class="user-site-permission-filter__site-definition__values">
      <template
        v-for="(filterValue, j) in sitePermissionFilterMap[siteDefinition.customDefinitionNumber]"
        :key="j"
      >
        <UserSitePermissionFilterValue
          :filter-value="filterValue"
          :site-definition="siteDefinition"
          :permission-filter="permissionFilter"
          @remove="$emit('remove', $event)"
        />
      </template>
      <UserSitePermissionFilterEmpty
        v-if="sitePermissionFilterMap[siteDefinition.customDefinitionNumber]?.length === 0"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.user-site-permission-filter__site-definition {
  padding: $spacing-2;

  border-left: 1px solid $primary-3;

  &__values {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-1;
    gap: $spacing-1;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $spacing-2;
      background: $primary-0;

      &__remove {
        padding: $spacing-1;
      }
    }
  }
}
</style>
