import { useI18n } from "vue-i18n";
import { useTabs } from "@/composables/useTabs";

export enum StandardRecommendationEditDrawerTab {
  General = "General",
  Images = "Images",
}

export const useStandardRecommendationEditDrawerTabs = () => {
  const { t } = useI18n({ useScope: "global" });

  const tabs = [
    {
      label: t("standardRecommendations.standardRecommendation.general"),
      name: StandardRecommendationEditDrawerTab.General,
    },
    {
      label: t("standardRecommendations.standardRecommendation.uploadPhotos"),
      name: StandardRecommendationEditDrawerTab.Images,
    },
  ];

  const { activeTab, changeTab } = useTabs(tabs, StandardRecommendationEditDrawerTab.General);

  return {
    tabs,
    changeTab,
    activeTab,
  };
};
