import { Ref, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AdminRouteName } from "@/router/RouteName";
import { getSiteService } from "@/services/sites";
import { ModuleDto, SiteDto } from "@/types/_generated/api";
import { BlueRating } from "@/types/Survey";

export const useBlueRatingBreadcrumbs = (
  module: Ref<ModuleDto | null>,
  siteId: number,
  survey: Ref<BlueRating | null>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const site = ref<SiteDto | null>();

  const getBreadcrumbData = async () => {
    site.value = await getSiteService(siteId);
  };

  getBreadcrumbData();

  const breadcrumbs = computed(() => [
    {
      title: module.value?.clientName || t("navigation.client"),
      route: {
        name: AdminRouteName.Client,
        params: { clientId: module.value?.clientId ?? 0 },
      },
    },
    {
      title: module.value?.name,
      route: {
        name: AdminRouteName.Module,
        params: { moduleId: module.value?.moduleId },
      },
    },
    {
      title: site.value?.name || t("sites.site"),
      route: {
        name: AdminRouteName.Site,
      },
    },
    {
      title: survey.value?.surveyDate || t("surveys.survey"),
    },
  ]);

  return { breadcrumbs };
};
