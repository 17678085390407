import Squire from "squire-rte";
import { onMounted, onUnmounted, reactive, watch } from "vue";
import { Editor } from "../constants";

export type IsActiveType = Partial<Record<Editor.Command, boolean>>;

export const useToolbarState = (editor: Squire | null) => {
  const isActive = reactive<IsActiveType>({
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
    unorderedList: false,
    orderedList: false,
  });

  const manageEventListener = (newEditor: Squire | null, add: boolean) => {
    const eventHandler = add ? "addEventListener" : "removeEventListener";
    newEditor?.[eventHandler]("pathChange", updateButtonStates);
    newEditor?.[eventHandler]("input", updateButtonStates);
  };

  onMounted(() => {
    updateButtonStates(); // Check the initial states
    manageEventListener(editor, true);
  });

  onUnmounted(() => manageEventListener(editor, false));

  watch(
    () => editor,
    (newEditor, oldEditor) => {
      manageEventListener(oldEditor, false);
      manageEventListener(newEditor, true);
    },
  );

  const updateButtonStates = () => {
    if (!editor) return;

    // Check if the current selection or cursor position is within selected text
    isActive.bold = editor.hasFormat(Editor.Format.Bold);
    isActive.italic = editor.hasFormat(Editor.Format.Italic);
    isActive.underline = editor.hasFormat(Editor.Format.Underline);
    isActive.strikethrough = editor.hasFormat(Editor.Format.Strikethrough);
    isActive.unorderedList = editor.hasFormat(Editor.Format.UnorderedList);
    isActive.orderedList = editor.hasFormat(Editor.Format.OrderedList);
  };

  return { isActive };
};
