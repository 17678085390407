<script setup lang="ts">
import BaseIcon, { type Props as BaseIconProps } from "@/components/base/BaseIcon/BaseIcon.vue";

export interface Props {
  title: string;
  description: string;
  variant: "empty" | "error" | "forbidden";
}

defineProps<Props>();

const iconMap: Record<Props["variant"], BaseIconProps["icon"]> = {
  error: "warning",
  empty: "empty",
  forbidden: "forbidden",
};
</script>

<template>
  <div class="base-info-state">
    <div class="base-info-state__container">
      <div :class="`base-info-state__icon base-info-state__icon--${variant}`">
        <BaseIcon :icon="iconMap[variant]" size="xxlarge" />
      </div>
      <div class="base-info-state__text">
        <p class="base-info-state__text__title">
          {{ title }}
        </p>
        <p class="base-info-state__text__description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@mixin stateStyle($bgColor, $borderColor) {
  background-color: $bgColor;
  border: 8px solid $borderColor;
}

.base-info-state {
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing-6;
    padding: $spacing-6;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 10rem;
    border-radius: $rounded-full;
    color: $primary-0;

    &--empty {
      @include stateStyle($secondary-3, $secondary-2);
    }

    &--error,
    &--forbidden {
      @include stateStyle($error-3, $error-2);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;
    font-size: $text-sm;
    text-align: center;
    line-height: $leading-snug;
    max-width: 40rem;
    text-wrap: balance;

    &__title {
      font-weight: $font-medium;
    }

    &__description {
      color: $primary-8;
    }
  }
}
</style>
