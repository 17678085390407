import { ref } from "vue";
import { getSurveysService } from "@/services/surveys";
import { SurveyLeanDto } from "@/types/_generated/api";

export const useGetSurveys = () => {
  const isLoadingSurveys = ref(false);
  const hasError = ref(false);
  const surveys = ref<SurveyLeanDto[] | null>(null);

  const getSurveys = async (siteId: number) => {
    isLoadingSurveys.value = true;
    hasError.value = false;

    try {
      surveys.value = await getSurveysService(siteId);
    } catch {
      hasError.value = true;
    } finally {
      isLoadingSurveys.value = false;
    }
  };

  return {
    isLoadingSurveys,
    getSurveys,
    surveys,
    hasError,
  };
};
