<script setup lang="ts">
import { computed } from "vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

// Used specifically for enums with values Green, Red, None
// e.g. RecResponseSubmitDate and ReportDownloadStatus

const props = defineProps<{ value: "Green" | "Red" | "None" | undefined }>();

const showIcon = computed(() => props.value && props.value !== "None");
const icon = computed(() => (props.value === "Green" ? "check" : "cross"));
</script>

<template>
  <BaseIcon v-if="showIcon" :icon="icon" />
</template>
