<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { formatDate } from "@/helpers/formatDate";
import { getServiceMessage, saveServiceMessage } from "@/services/serviceMessages";
import { SaveServiceMessage, ServiceMessageDto, Severity } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import { required, maxLength } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });
const isLoading = ref(false);
const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

const props = defineProps<{
  serviceMessageId?: number | null;
}>();

const emits = defineEmits<{
  (event: "close"): void;
}>();

const isNew = computed(() => !props.serviceMessageId);

const options = ref<Option[]>([
  { title: "Information", value: "Information", icon: "IconInfo" },
  { title: "Warning", value: "Warning", icon: "IconWarning" },
  { title: "Error", value: "Error", icon: "IconCrossCircle" },
]);

const serviceMessage = ref<SaveServiceMessage | ServiceMessageDto>({
  active: true,
  subject: "",
  message: "",
  severity: Severity.Information,
});

onBeforeMount(async () => {
  isLoading.value = true;

  const serviceMessageDto = isNew.value ? null : await getServiceMessage(props.serviceMessageId!);

  serviceMessage.value = serviceMessageDto ?? serviceMessage.value;

  setCompareBase(serviceMessage);

  isLoading.value = false;
});

const validations = {
  subject: { required, maxLength: maxLength(150) },
  message: { required, maxLength: maxLength(32767) },
  severity: { required },
};

const { v$, addRef, focusError } = useVuelidate<SaveServiceMessage>(validations, serviceMessage, {
  $autoDirty: true,
});

const save = async () => {
  v$.value.$validate();

  if (v$.value.$invalid) {
    focusError();
    return;
  }

  isLoading.value = true;

  await saveServiceMessage(serviceMessage.value);

  setCompareBase(serviceMessage);

  isLoading.value = false;
  emits("close");
};

const closeDrawer = async () => {
  if (await discardUnsavedChanges(serviceMessage)) {
    emits("close");
  }
};
</script>

<template>
  <BaseDrawer
    class="edit-service-message-drawer"
    :title="isNew ? t('serviceMessages.createNew') : t('serviceMessages.edit')"
    :is-loading="isLoading"
    width="30"
    @close="closeDrawer"
  >
    <div class="edit-service-message-drawer__content">
      <BaseCard has-padding :title="t('serviceMessages.serviceMessage')">
        <template #header>
          <BaseCheckbox
            :label="t('serviceMessages.entity.active')"
            :checked="serviceMessage.active"
            @change="(value) => (serviceMessage.active = value)"
          />
        </template>
        <BaseTextField
          :ref="(el) => addRef(el, 'subject')"
          v-model:value="serviceMessage.subject"
          required
          :label="t('serviceMessages.entity.subject')"
          :errors="v$.subject.$errors"
          @update:value="(value) => (serviceMessage.subject = value as string)"
        />
        <BaseTextArea
          :ref="(el) => addRef(el, 'message')"
          v-model:value="serviceMessage.message"
          required
          :label="t('serviceMessages.entity.message')"
          :errors="v$.message.$errors"
          @update:value="(value) => (serviceMessage.message = value as string)"
        />
        <BaseSelect
          :ref="(el) => addRef(el, 'severity')"
          required
          :label="t('serviceMessages.entity.severity')"
          :value="serviceMessage.severity"
          :errors="v$.severity.$errors"
          @change="(value) => (serviceMessage.severity = value as Severity)"
        >
          <BaseOption v-for="option in options" :key="option.value" :value="option.value">
            {{ option.title }}
          </BaseOption>
        </BaseSelect>

        <div v-if="!isNew" class="edit-service-message-drawer__content__updated">
          <small v-if="(serviceMessage as ServiceMessageDto).insertDate">
            {{ t("common.created") }}:
            {{ formatDate.asDateAndTime((serviceMessage as ServiceMessageDto).insertDate) }}
          </small>

          <small v-if="(serviceMessage as ServiceMessageDto).updateDate">
            {{ t("common.updated") }}:
            {{ formatDate.asDateAndTime((serviceMessage as ServiceMessageDto).updateDate) }}
          </small>
        </div>
      </BaseCard>
    </div>

    <template #footer-left>
      <BaseButton @click="() => save()">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="$emit('close')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.edit-service-message-drawer {
  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;

    &__updated {
      display: flex;
      justify-content: space-between;
      gap: $spacing-2;
      margin-top: $spacing-2;
    }
  }
}
</style>
