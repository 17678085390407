<script setup lang="ts">
import { vTruncationTitle } from "@/directives/truncationTitle";
import {
  BlueRatingRecommendationDto,
  SiteResponseStatus,
  SurveyorStatus,
} from "@/types/_generated/api";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

defineProps<{
  recommendations: BlueRatingRecommendationDto[];
  selected?: BlueRatingRecommendationDto;
  status: "surveyor" | "response";
  abeyanceTranslation?: string;
}>();
defineEmits<{ select: [number] }>();

const getTitle = (recommendation: BlueRatingRecommendationDto) => {
  return `${recommendation.fullPosition} ${recommendation.header}`;
};
</script>

<template>
  <div class="online-report-menu">
    <div
      v-for="(recommendation, i) of recommendations"
      :key="`online-report-menu-rec-${i}`"
      :title="getTitle(recommendation)"
      class="online-report-menu__item"
      :class="{
        'online-report-menu__item--odd-row': i % 2 === 0,
        'online-report-menu__item--selected':
          selected?.recommendationId === recommendation.recommendationId,
      }"
      @click.stop="$emit('select', recommendation.recommendationId)"
    >
      <div v-truncation-title class="online-report-menu__item__title">
        {{ getTitle(recommendation) }}
      </div>

      <div>
        <BaseStatusBadge v-if="status === 'surveyor'" :status="recommendation.surveyorStatus">
          <template
            v-if="abeyanceTranslation && recommendation.surveyorStatus === SurveyorStatus.Abeyance"
          >
            {{ abeyanceTranslation }}
          </template>
        </BaseStatusBadge>

        <BaseStatusBadge
          v-if="status === 'response'"
          :status="recommendation.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.online-report-menu {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-2 $spacing-2;
    gap: $spacing-2;
    border-left: 2px solid transparent;

    cursor: pointer;
    line-height: $leading-relaxed;

    &__title {
      font-size: $text-sm;
    }

    &--odd-row {
      background-color: $primary-2;
    }

    &--selected {
      border-color: $secondary-5;
      background-color: $primary-3;
    }

    &:hover {
      background-color: $primary-3;
    }
  }
}
</style>
