import { i18n } from "@/i18n";

// If a validator is added here, it should also be added to ValidationRule in types.ts and isValid in useValidateForm.ts

const { t } = i18n.global;

const isValidField = (
  value: string | undefined,
  validateFunc: (value: string | undefined) => boolean,
  errorMessage: string,
) => (validateFunc(value) ? "" : errorMessage);

export const validateIf =
  (conditionFunc: () => boolean, validateFunc: (value: string | undefined) => string) =>
  (value: string | undefined) => {
    if (conditionFunc()) {
      return validateFunc(value);
    }

    return "";
  };

export const required = (value: string | undefined) =>
  isValidField(value, (v) => !!v, t("validations.required"));

export const minLength = (min: number) => (value: string | undefined) =>
  isValidField(value, (v) => !v || (!!v && v.length > min), t("validations.minLength", { min }));

export const maxLength = (max: number) => (value: string | undefined) =>
  isValidField(value, (v) => !v || (!!v && v.length < max), t("validations.maxLength", { max }));

export const between = (min: number, max: number) => (value: number | undefined) => {
  if (value === undefined) {
    return "";
  }

  return value >= min && value <= max ? "" : t("validations.between", { min, max });
};

const MOBILE_PATTERN = /^\+\d{2}[1-9]{1}\d{8,}$/;

export const mobilePhoneNumber = (value: string | undefined) =>
  isValidField(value, (v) => !!v && new RegExp(MOBILE_PATTERN).test(v), t("validations.mobile"));

export const EMAIL_PATTERN = /^[a-zA-ZåäöÅÄÖ0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const email = (value: string | undefined) =>
  isValidField(value, (v) => !!v && new RegExp(EMAIL_PATTERN).test(v), t("validations.email"));
