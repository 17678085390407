import { computed, ref } from "vue";
import { isModified, toPlainObject } from "@/helpers/isModified";

const showDialog = ref<boolean>(false);
let modalPromiseResolve: null | ((value: boolean) => void) = null;

const resolveAndReset = (abandon: boolean) => {
  showDialog.value = false;

  if (modalPromiseResolve) {
    modalPromiseResolve(abandon);
    modalPromiseResolve = null;
  }
};

export const useUnsavedChangesModal = () => ({
  showDialog: computed(() => showDialog),
  ok: () => resolveAndReset(true),
  cancel: () => resolveAndReset(false),
});

export const useUnsavedChanges = (obj: unknown = null) => {
  let compareBase: unknown = toPlainObject(obj);

  return {
    /**
     * Set a base object to compare against to check for unsaved changes
     * Use after saving or loading an object
     */
    setCompareBase: (obj: unknown) => {
      compareBase = toPlainObject(obj);
    },

    /**
     * Compare an object against the base object and check for changes
     */
    isModified: (obj: unknown) => isModified(compareBase, obj),

    /**
     * Check if there are unsaved changes and show a dialog
     * to confirm discarding them
     * @param obj Object to compare against the base object
     */
    discardUnsavedChanges: (obj: unknown): Promise<boolean> => {
      if (isModified(compareBase, obj)) {
        showDialog.value = true;

        return new Promise((resolve) => (modalPromiseResolve = resolve));
      }

      return Promise.resolve(true);
    },
    unsavedChangesDialog: {
      showDialog,
      ok: () => resolveAndReset(true),
      cancel: () => resolveAndReset(false),
    },
  };
};
