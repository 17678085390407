<script setup lang="ts">
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";

const skeletonRows = [{ count: 10 }, { count: 5 }, { count: 3 }, { count: 2 }];
</script>

<template>
  <div class="blue-rating-table-loading">
    <div
      v-for="(row, rowIndex) in skeletonRows"
      :key="rowIndex"
      class="blue-rating-table-loading__row"
    >
      <BaseSkeleton
        v-for="index in row.count"
        :key="index"
        width="100%"
        height="100%"
        class="blue-rating-table-loading__data"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-table-loading {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__row {
    display: flex;
    justify-content: space-between;
    gap: $spacing-4;
    width: 100%;
  }

  &__data {
    height: 1.25rem;
    width: 100%;
  }
}
</style>
