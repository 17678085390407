<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { ModuleTranslations } from "@/types/ModuleTranslations";
import { useCategoryRecommendations } from "@/composables/useCategoryRecommendations";
import {
  type BlueRatingCategoryDto,
  type BlueRatingRecommendationDto,
} from "@/types/_generated/api";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import ModImageList from "@/components/mods/ImageList/ModImageList.vue";

const props = defineProps<{
  moduleTranslations: ModuleTranslations;
  recommendation: BlueRatingRecommendationDto;
  categories: BlueRatingCategoryDto[];
  hideSurveyorStatus?: boolean;
  isRecResponse?: boolean;
}>();

const { t } = useI18n({ useScope: "global" });
const { getCategoryNameByRecommendation } = useCategoryRecommendations();

const src = computed(() =>
  props.isRecResponse
    ? "/v1/recommendation-response/recommendations/images/"
    : `/v1/surveys/${props.recommendation.surveyId}/recommendations/${props.recommendation.recommendationId}/images?fileId=`,
);
</script>

<template>
  <BaseDataDisplayListItem
    v-if="recommendation.isKeyRecommendation"
    :label="t('userWeb.onlineReport.recommendations.isKeyRecommendation')"
  >
    <BaseIcon icon="check" />
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem
    v-if="recommendation.isGeneralRecommendation"
    :label="t('userWeb.onlineReport.recommendations.isGeneralRecommendation')"
  >
    <BaseIcon icon="check" />
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem
    v-if="recommendation.categoryId && categories.length"
    :label="t('userWeb.onlineReport.recommendations.category')"
  >
    <p>
      {{ getCategoryNameByRecommendation(recommendation.recommendationId, categories) }}
    </p>
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.blueRating')">
    <BaseBlueRatingStatusBadge
      :translations="moduleTranslations"
      :status="recommendation.effectiveColor"
    />
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem
    v-if="!hideSurveyorStatus"
    :label="t('userWeb.recommendation.surveyorStatus')"
  >
    <BaseStatusBadge :translations="moduleTranslations" :status="recommendation.surveyorStatus" />
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.name')">
    <p>{{ recommendation.header }}</p>
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.location')">
    <p>{{ recommendation.location }}</p>
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.priority')">
    <p>{{ recommendation.priority }}</p>
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.recommendation')">
    <BaseSafeHtml :html="recommendation.text" />
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.comment')">
    <BaseSafeHtml :html="recommendation.comment" />
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.type')">
    <p>{{ recommendation.type }}</p>
  </BaseDataDisplayListItem>
  <BaseDataDisplayListItem
    v-if="recommendation.images?.length"
    :label="t('userWeb.onlineReport.recommendations.images')"
  >
    <ModImageList
      :url-builder="(image) => `${src}${image.fileId}`"
      :images="recommendation?.images ?? []"
    />
  </BaseDataDisplayListItem>
</template>
