import { ref } from "vue";
import { useRouter } from "vue-router";
import { AdminRouteName } from "@/router/RouteName";

export const useQuestionnaireNavigation = () => {
  const router = useRouter();
  const currentTab = ref(router.currentRoute.value.path.includes("draft") ? "draft" : "current");

  const navigateToLatestQuestionnaire = (
    moduleId: number | null,
    questionnaireId: number | null,
    navigateWithReplace: boolean = false,
  ) => {
    currentTab.value = "current";

    // When the draft has been deleted or published back will result in an error
    const navigationMethod = navigateWithReplace ? "replace" : "push";

    if (moduleId) {
      router[navigationMethod]({
        name: AdminRouteName.QuestionnaireCurrent,
        params: {
          moduleId,
          qustionnaireId: null,
        },
      });
    } else {
      router[navigationMethod]({
        name: AdminRouteName.QuestionnaireTemplateCurrent,
        params: {
          moduleId: null,
          questionnaireId,
        },
      });
    }
  };

  const navigateToDraft = (moduleId: number | null) => {
    currentTab.value = "draft";

    router.push({
      name: moduleId
        ? AdminRouteName.QuestionnaireDraft
        : AdminRouteName.QuestionnaireTemplateDraft,
    });
  };

  return { currentTab, navigateToDraft, navigateToLatestQuestionnaire };
};
