import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  ClientDto,
  GetClientResponse,
  GetClientsResponse,
  CreateClient,
  CreateClientResponse,
  UpdateClient,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const clientEvents = new BroadcastService("clients");

export const getClient = async (id: number): Promise<ClientDto | null> => {
  try {
    const {
      data: { client },
    } = await http.get<GetClientResponse>(`/clients/${id}`);
    return client;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("clients.client"),
    });
  }
  return null;
};

export const getClientsService = async (): Promise<ClientDto[] | null> => {
  try {
    const {
      data: { clients },
    } = await http.get<GetClientsResponse>("/clients");
    return clients;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("clients.clients"),
    });
  }
  return null;
};

export const createClient = async (request: CreateClient): Promise<number | null> => {
  try {
    const {
      data: { clientId },
    } = await http.post<CreateClientResponse>("/clients", request);
    notify.success(t("clients.client"), t("common.created").toLocaleLowerCase());

    clientEvents.post({ action: "create", id: clientId });

    return clientId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("clients.client"),
    });
  }
  return null;
};

export const updateClient = async (request: UpdateClient): Promise<number | null> => {
  try {
    await http.put(`clients/${request.clientId}`, request);

    notify.success(t("clients.client"), t("common.updated").toLowerCase());

    clientEvents.post({ action: "update", id: request.clientId });

    return request.clientId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("clients.client"),
    });
  }
  return null;
};
