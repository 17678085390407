<script setup lang="ts">
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useGetModules } from "@/composables/services/useGetModules";
import { UserWebRouteName } from "@/router/RouteName";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import { useModuleTableColumns } from "./composables/useModuleTableColumns";

const router = useRouter();
const { isLoadingModules, modules, getModules } = useGetModules();
const { columns } = useModuleTableColumns();

onMounted(() => getModules({ includeOnlyPublished: true }));
</script>

<template>
  <UserWebViewLayout>
    <BaseGridTable
      enable-row-click
      :rows="modules"
      :total-row-count="modules.length"
      :columns="columns"
      :is-loading="isLoadingModules"
      :default-sort="[{ key: 'name' }]"
      :pagination="false"
      @click:row="
        (row) => {
          router.push({
            name: UserWebRouteName.Module,
            params: { moduleId: row.moduleId },
          });
        }
      "
    >
    </BaseGridTable>
  </UserWebViewLayout>
</template>
