<script setup lang="ts">
import { computed } from "vue";
import useDesign from "@/composables/useDesign";
import { HorizontalSizes, VerticalSizes } from "@/types/CSSUnits";

interface Props {
  fillHeight?: `${number}${VerticalSizes}`;
  removeImpersonation?: boolean;
  removeNavigation?: boolean;
  removeCustom?: `${number}${VerticalSizes | HorizontalSizes}`;
  margin?: `${number}${VerticalSizes | HorizontalSizes}`;
  padding?: `${number}${VerticalSizes | HorizontalSizes}`;
  overflow?: "scroll" | "auto" | "hidden";
}

const props = withDefaults(defineProps<Props>(), {
  fillHeight: "100vh",
  removeImpersonation: true,
  removeNavigation: false,
  removeCustom: undefined,
  margin: "0px",
  padding: "0px",
  overflow: "auto",
});

const design = useDesign();

const height = computed(() => {
  let calc = `calc(${props.fillHeight} - ${props.margin} - ${props.padding}`;

  if (props.removeImpersonation) {
    calc += ` - ${design.impersonationHeight.value}`;
  }

  if (props.removeNavigation) {
    calc += ` - ${design.navigationHeight.value}`;
  }

  if (props.removeCustom) {
    calc += ` - ${props.removeCustom}`;
  }

  return `${calc})`;
});
</script>

<template>
  <div class="base-overflow-scroll">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.base-overflow-scroll {
  overflow: v-bind("props.overflow");
  height: v-bind("height");
  max-height: v-bind("height");
  scroll-behavior: smooth;
}
</style>
