import { ref } from "vue";
import type { ExecutiveSummaryDrawer } from "../types/ExecSummaryDrawers";

export const useDrawerToggle = () => {
  const currentDrawer = ref<ExecutiveSummaryDrawer | null>(null);

  const openDrawer = (drawer: ExecutiveSummaryDrawer) => {
    currentDrawer.value = drawer;
  };

  const closeDrawer = () => {
    currentDrawer.value = null;
  };

  return { currentDrawer, openDrawer, closeDrawer };
};
