<template>
  <h2 class="base-data-display-list-title">
    <slot></slot>
  </h2>
</template>

<style scoped lang="scss">
.base-data-display-list-title {
  font-size: $text-base;
  font-weight: $font-semibold;
  padding-bottom: $spacing-2;
  border-bottom: 1px solid $primary-4;
}
</style>
