import { useI18n } from "vue-i18n";
import { ModuleLeanDto } from "@/types/_generated/api";
import { ColumnDefinition } from "@/types/ColumnDefinition";

export const useModuleTableColumns = () => {
  const { t } = useI18n({ useScope: "global" });

  const columns: ColumnDefinition<ModuleLeanDto>[] = [
    new ColumnDefinition({ title: t("common.name"), key: "name" }),
    new ColumnDefinition({ title: t("clients.client"), key: "clientName" }),
    new ColumnDefinition({
      title: t("modules.office"),
      key: "willisOffice",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.countryOrUnit"),
      key: "willisCountryOrUnit",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.administrator"),
      key: "administratorName",
      autoWidth: true,
    }),
  ];

  return { columns };
};
