<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { RouteParamsRaw } from "vue-router";
import { useDialog } from "@/composables/useDialog";
import { getInitials } from "@/helpers/getInitials";
import router from "@/router";
import { AppRouteName, UserWebRouteName } from "@/router/RouteName";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { useAuthStore } from "@/stores/auth";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import UWDropdown, {
  type DropdownOptions,
} from "@/components/base/UserWeb/Dropdown/UWDropdown.vue";

const props = defineProps<{
  fullName: string;
  userWeb?: { name: UserWebRouteName; params?: RouteParamsRaw } | false;
}>();

const emit = defineEmits<{
  openUserDetails: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const auth = useAuthStore();
const { openDialog } = useDialog();

const options = computed<DropdownOptions[]>(() => [
  {
    label: t("userMenu.userDetails"),
    icon: "person",
    action: userDetails,
  },
  {
    divider: true,
    label: t("userMenu.signOut"),
    icon: "sign-out",
    action: signOut,
  },
]);

const userDetails = () => {
  emit("openUserDetails");
};

const signOut = async () => {
  const result = await openDialog({
    title: t("app.signOutDialog.title"),
    description: t("app.signOutDialog.description"),
    confirmText: t("app.signOutDialog.title"),
  });

  if (result) {
    await auth.signOut();
    router.push({
      name: AppRouteName.Auth,
      query: { reason: ViewAuthStatus.SignedOut },
    });
  }
};

const initials = computed(() => getInitials(props.fullName));
</script>

<template>
  <nav class="admin-header-more">
    <BaseButton v-if="userWeb" color="light" variant="outlined" @click="router.push(userWeb)">
      {{ t("navigation.userWeb") }}
    </BaseButton>

    <UWDropdown position="bottom-left" :options="options">
      <template #activator="{ toggleDropdown }">
        <BaseButton
          variant="outlined"
          color="light"
          :aria-label="t('navigation.more')"
          :aria-haspopup="true"
          @click="toggleDropdown"
        >
          <BaseIcon icon="bolt" />
          {{ initials }}
        </BaseButton>
      </template>
    </UWDropdown>
  </nav>
</template>

<style scoped lang="scss">
.admin-header-more {
  display: flex;
  align-items: center;
  gap: $spacing-2;
}
</style>
