<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { RecReportSettingsScope } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import RecReportControls from "./components/RecReportControls.vue";
import RecReportForm from "./components/RecReportForm.vue";
import RecReportSections from "./components/RecReportSections.vue";
import RecReportState from "./components/RecReportState.vue";
import { useCreateSettings } from "./composables/useCreateSettings";
import { useDeleteSettings } from "./composables/useDeleteSettings";
import { useFormValues } from "./composables/useFormValues";
import { useGetAllSettings } from "./composables/useGetAllSettings";
import { useSettingsSelection } from "./composables/useSettingsSelection";
import { useUpdateSettings } from "./composables/useUpdateSettings";

const props = defineProps<{
  moduleId: number;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();

const {
  selectedScope,
  selectedSection,
  setSelectedScope,
  setSelectedSection,
  closeSelectedSection,
} = useSettingsSelection();
const {
  isEmpty,
  internalForm,
  externalForm,
  selectedScopeForm,
  selectedSectionForm,
  resetFormField,
  updateFormField,
  moveSection,
  applyFetchedData,
} = useFormValues(selectedScope, selectedSection);

const { isFetchingSettings, getSettings } = useGetAllSettings(props.moduleId, applyFetchedData);
const { isCreatingSettings, createSettings } = useCreateSettings(getSettings);
const { isDeletingSettings, deleteSettings } = useDeleteSettings(getSettings);
const { isUpdatingSettings, updateSettings } = useUpdateSettings();

const isLoading = computed(
  () =>
    isFetchingSettings.value ||
    isCreatingSettings.value ||
    isDeletingSettings.value ||
    isUpdatingSettings.value,
);

const openDeleteSettingsModal = async () => {
  const isConfirmed = await dialog.openDeletePrompt(
    `${selectedScope.value} ${t("module.recReportSettings.setting")}`.toLowerCase(),
  );

  if (isConfirmed) {
    closeSelectedSection();
    deleteSettings(props.moduleId, selectedScopeForm.value.id);
  }
};

const handleUpdateSettings = async (closeDrawer: () => void) => {
  // Update internal settings
  if (internalForm.id) {
    await updateSettings({
      settingId: internalForm.id,
      moduleId: props.moduleId,
      scope: RecReportSettingsScope.Internal,
      settings: internalForm,
    });
  }

  if (externalForm.id) {
    // Update external settings
    await updateSettings({
      settingId: externalForm.id,
      moduleId: props.moduleId,
      scope: RecReportSettingsScope.External,
      settings: externalForm,
    });
  }

  closeDrawer();
};
</script>

<template>
  <UWDrawer
    size="small"
    :drawer-title="t('module.recReportSettings.title')"
    :extension="{ title: selectedSection?.sectionLabel || '', size: 'large' }"
    @close-drawer="emit('closeDrawer')"
    @close-extension="closeSelectedSection"
  >
    <RecReportControls
      :is-loading="isLoading"
      :is-empty="isEmpty"
      :selected-scope="selectedScope"
      @delete:setting="openDeleteSettingsModal"
      @update:selected-scope="setSelectedScope"
    />

    <RecReportState
      v-if="isEmpty || isLoading"
      :is-loading="isLoading"
      :selected-scope="selectedScope"
      @create-section="createSettings(moduleId, selectedScope)"
    />

    <RecReportSections
      v-else-if="selectedScopeForm"
      :selected-scope-form="selectedScopeForm"
      @update:form-field="updateFormField"
      @move:section="moveSection"
      @open:section="setSelectedSection"
    />

    <template v-if="selectedSection && selectedSectionForm" #extension>
      <RecReportForm
        :is-loading="isLoading"
        :selected-section-key="selectedSection.key"
        :selected-section-form="selectedSectionForm"
        @reset:form-field="resetFormField"
        @update:form-field="updateFormField"
      />
    </template>

    <template #footer-left="{ onCloseDrawer }">
      <BaseButton :disabled="isLoading || isEmpty" @click="handleUpdateSettings(onCloseDrawer)">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="onCloseDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </UWDrawer>
</template>
