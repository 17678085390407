<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0.75C2 0.335786 2.33579 0 2.75 0H8.5C10.9853 0 13 2.01472 13 4.5C13 6.98531 10.9853 9 8.5 9H2.75C2.33579 9 2 8.66421 2 8.25V0.75ZM3.5 1.5V7.5H8.5C10.1569 7.5 11.5 6.15689 11.5 4.5C11.5 2.84314 10.1569 1.5 8.5 1.5H3.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 8.25C2 7.83579 2.33579 7.5 2.75 7.5H9.75C12.0972 7.5 14 9.40279 14 11.75C14 14.0972 12.0972 16 9.75 16H2.75C2.33579 16 2 15.6642 2 15.25V8.25ZM3.5 9V14.5H9.75C11.2688 14.5 12.5 13.2688 12.5 11.75C12.5 10.2312 11.2688 9 9.75 9H3.5Z"
    />
  </svg>
</template>
