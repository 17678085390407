<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  totalRowCount: number;
}>();
</script>

<template>
  <div class="base-grid-table__empty-state">
    <span v-if="totalRowCount === 0" class="base-grid-table__main__row__empty">
      {{ t("library.table.empty") }}
    </span>
    <span v-if="totalRowCount > 0" class="base-grid-table__main__row__empty">
      {{ t("library.table.emptyWithFilter") }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.base-grid-table__empty-state {
  display: contents;

  & > * {
    grid-column: 1/-1;
    text-align: center;
    font-size: $text-lg;
    color: $primary-5;
    font-style: italic;
    padding: $spacing-4;
  }
}
</style>
