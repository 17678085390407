<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5V16C12.4183 16 16 12.4183 16 8H14.5ZM8 14.5C4.41015 14.5 1.5 11.5899 1.5 8H0C0 12.4183 3.58172 16 8 16V14.5ZM1.5 8C1.5 4.41015 4.41015 1.5 8 1.5V0C3.58172 0 0 3.58172 0 8H1.5ZM8 1.5C11.5899 1.5 14.5 4.41015 14.5 8H16C16 3.58172 12.4183 0 8 0V1.5Z"
    />
    <path
      d="M9.5 6C9.5 6.8284 8.8284 7.5 8 7.5V9C9.6569 9 11 7.6569 11 6H9.5ZM8 7.5C7.1716 7.5 6.5 6.8284 6.5 6H5C5 7.6569 6.3431 9 8 9V7.5ZM6.5 6C6.5 5.17157 7.1716 4.5 8 4.5V3C6.3431 3 5 4.34315 5 6H6.5ZM8 4.5C8.8284 4.5 9.5 5.17157 9.5 6H11C11 4.34315 9.6569 3 8 3V4.5Z"
    />
    <path
      d="M2.62147 12.5197C2.35621 12.8378 2.39907 13.3108 2.7172 13.576C3.03534 13.8413 3.50827 13.7984 3.77353 13.4803L2.62147 12.5197ZM12.2266 13.4803C12.4918 13.7984 12.9648 13.8413 13.2829 13.576C13.601 13.3108 13.6439 12.8378 13.3786 12.5197L12.2266 13.4803ZM3.77353 13.4803C4.78361 12.2689 6.30169 11.5 8.00009 11.5V10C5.83795 10 3.90433 10.9811 2.62147 12.5197L3.77353 13.4803ZM8.00009 11.5C9.69838 11.5 11.2165 12.2689 12.2266 13.4803L13.3786 12.5197C12.0958 10.9811 10.1622 10 8.00009 10V11.5Z"
    />
  </svg>
</template>
