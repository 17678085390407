<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 2.75C1 1.23122 2.23122 0 3.75 0H10C10.1989 0 10.3897 0.0790176 10.5303 0.21967L14.7803 4.46967C14.921 4.61032 15 4.80109 15 5V13.25C15 14.7688 13.7688 16 12.25 16H3.75C2.23122 16 1 14.7688 1 13.25V2.75ZM3.75 1.5C3.05964 1.5 2.5 2.05964 2.5 2.75V13.25C2.5 13.9404 3.05964 14.5 3.75 14.5H12.25C12.9404 14.5 13.5 13.9404 13.5 13.25V5.31066L9.68934 1.5H3.75Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.75 0.25C10.1642 0.25 10.5 0.585786 10.5 1V4.5H14C14.4142 4.5 14.75 4.83579 14.75 5.25C14.75 5.66421 14.4142 6 14 6H9.75C9.33579 6 9 5.66421 9 5.25V1C9 0.585786 9.33579 0.25 9.75 0.25Z"
    />
  </svg>
</template>
