import { RouteRecordRaw } from "vue-router";
import { RecRespRouteName } from "@/router/RouteName";
import ViewRecRespLogin from "@/views/RecResponse/Login/ViewRecRespLogin.vue";
import ViewRecRespRespond from "@/views/RecResponse/Respond/ViewRecRespRespond.vue";
import ViewRecRespSignedOut from "@/views/RecResponse/SignedOut/ViewRecRespSignedOut.vue";
import ViewRecRespSummary from "@/views/RecResponse/Summary/ViewRecRespSummary.vue";
import ViewRecResponse from "@/views/RecResponse/ViewRecResponse.vue";

export const recRespRoutes: Array<RouteRecordRaw> = [
  {
    path: "/recommendation-response",
    name: RecRespRouteName.Auth,
    component: ViewRecResponse,
    redirect: { name: RecRespRouteName.Login },
    meta: { isPublic: true },
    children: [
      {
        path: "login",
        name: RecRespRouteName.Login,
        component: ViewRecRespLogin,
        meta: { isPublic: true },
      },
      {
        path: "signed-out",
        name: RecRespRouteName.SignedOut,
        component: ViewRecRespSignedOut,
      },
      {
        path: "summary",
        name: RecRespRouteName.Summary,
        component: ViewRecRespSummary,
        meta: { isPublic: true },
        redirect: { name: RecRespRouteName.SummaryOverview },
        children: [
          {
            path: "overview",
            name: RecRespRouteName.SummaryOverview,
            component: ViewRecRespSummary,
          },
          {
            path: "detailed",
            name: RecRespRouteName.SummaryDetailed,
            component: ViewRecRespSummary,
          },
        ],
      },
      {
        path: "respond",
        name: RecRespRouteName.Respond,
        component: ViewRecRespRespond,
        meta: { isPublic: true },
      },
    ],
  },
];
