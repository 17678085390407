import { Directive } from "vue";

declare global {
  interface HTMLElement {
    clickOutsideEvent?: (event: MouseEvent | TouchEvent) => void;
  }
}

const clickOutside: Directive = {
  beforeMount(el, binding) {
    const onClick = (event: MouseEvent | TouchEvent) => {
      // Check if the clicked element is outside the directive element
      const target = event.target as Node;
      if (!(el === target || el.contains(target)) && typeof binding.value === "function") {
        binding.value();
      }
    };

    // Use setTimeout to delay the registration of event listeners
    setTimeout(() => {
      // Listen for both click and touchend events
      document.addEventListener("click", onClick);
      document.addEventListener("touchend", onClick);
      el.clickOutsideEvent = onClick;
    }, 0);
  },
  unmounted(el: HTMLElement) {
    if (el.clickOutsideEvent) {
      // Remove both listeners on unmount
      document.removeEventListener("click", el.clickOutsideEvent);
      document.removeEventListener("touchend", el.clickOutsideEvent);
      el.clickOutsideEvent = undefined;
    }
  },
};

export { clickOutside as vClickOutside };
