<script setup lang="ts">
import BaseImage from "@/components/base/BaseImage.vue";

export interface Props {
  url: string;
  image: { fileId: string; fileName: string; caption: string };
}

defineProps<Props>();

const DEFAULT_MAX_WIDTH = "100%";
const DEFAULT_MAX_HEIGHT = "100%";
</script>

<template>
  <li>
    <span>{{ image.caption }}</span>
    <BaseImage
      :src="url"
      :alt="image.fileName"
      :max-width="DEFAULT_MAX_WIDTH"
      :max-height="DEFAULT_MAX_HEIGHT"
    />
  </li>
</template>
