import { unref } from "vue";

export const toPlainObject = (obj: unknown): unknown => {
  if (typeof obj === "function") {
    return undefined;
  }

  const unwrapped = unref(obj);

  if (Array.isArray(unwrapped)) {
    return unwrapped.map(toPlainObject);
  }

  if (unwrapped === null || unwrapped === undefined) {
    return unwrapped;
  }

  if (typeof unwrapped === "object") {
    return Object.fromEntries(
      Object.entries(unwrapped).map(([key, value]) => [key, toPlainObject(value)]),
    );
  }

  return unwrapped;
};

export const isModified = (baseObject: unknown, modifiedObject: unknown): boolean => {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const base = toPlainObject(baseObject) as any;
  const modified = toPlainObject(modifiedObject) as unknown;

  if (!base && !modified) {
    return false;
  }

  if (!base || !modified) {
    return true;
  }

  const modifiedEntries = Object.entries(modified);

  for (const modifiedEntry of modifiedEntries) {
    const [key, value] = modifiedEntry;

    if (typeof key === "function") {
      continue;
    }

    if (typeof value !== typeof base[key]) {
      return true;
    }

    if (typeof value === "object" || Array.isArray(value)) {
      const hasChange = isModified(base[key], value);

      if (hasChange) {
        return true;
      }
      continue;
    }

    if (value !== base[key]) {
      return true;
    }
  }

  return false;
};
