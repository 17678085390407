<script setup lang="ts">
import { computed } from "vue";

export type Color = "dark" | "light" | "error";
export type Variant = "contained" | "outlined" | "text";
export type Size = "small" | "medium";

export interface Props {
  color?: Color;
  variant?: Variant;
  size?: Size;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
  tab?: number;
}

const props = withDefaults(defineProps<Props>(), {
  color: "dark",
  variant: "contained",
  size: "medium",
  disabled: false,
  type: "button",
  tab: undefined,
});

defineEmits<{
  click: [void];
}>();

const classes = computed(() => {
  return {
    "base-button": true,
    [`base-button--${props.color}--${props.variant}`]: true,
    [`base-button--${props.size}`]: true,
    [`base-button--disabled`]: props.disabled,
  };
});
</script>

<template>
  <button
    :class="classes"
    :type="type"
    :tabindex="tab"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss">
@mixin variantStyle($bgColor, $borderColor, $color, $mode: "opacity") {
  background-color: $bgColor;
  border: 1px solid $borderColor;
  color: $color;
  transition:
    opacity $duration-100 ease-in-out,
    background-color $duration-100 ease-in-out;

  @if $mode == "opacity" {
    &:not(:disabled):hover {
      opacity: 0.8;
    }
  } @else if $mode == "background" {
    &:not(:disabled):hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

@mixin sizeStyle($padding, $fontSize, $height) {
  padding: $padding;
  font-size: $fontSize;
  height: $height;
}

.base-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-1-5;
  border-radius: $rounded-base;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition:
    opacity $duration-100 ease-in-out,
    background-color $duration-100 ease-in-out;

  &--dark {
    &--contained {
      @include variantStyle($secondary-5, transparent, $primary-0);
    }

    &--outlined {
      @include variantStyle(transparent, $secondary-5, $secondary-5);
    }

    &--text {
      @include variantStyle(transparent, transparent, $secondary-5, "background");
    }
  }

  &--error {
    &--contained {
      @include variantStyle($error-4, transparent, $primary-0);
    }

    &--outlined {
      @include variantStyle(transparent, $error-3, $error-4);
    }

    &--text {
      @include variantStyle(transparent, transparent, $error-4, "background");
    }
  }

  &--light {
    &--contained {
      @include variantStyle($primary-0, transparent, $primary-0);
    }

    &--outlined {
      @include variantStyle(transparent, $primary-0, $primary-0);
    }

    &--text {
      @include variantStyle(transparent, transparent, $primary-0);
    }
  }

  &--small {
    @include sizeStyle($spacing-1-5 $spacing-2-5, $text-xs, 2rem);
  }

  &--medium {
    @include sizeStyle($spacing-2-5 $spacing-3-5, $text-sm, $shared-component-height);
  }

  &--disabled {
    opacity: 0.6;
  }
}
</style>
