/**
 * Adds zero padding to a string or parts of a string separated by a delimiter.
 * @param value - The string to be padded
 * @param padLength - The length to pad each part to (default: 3)
 * @param delimiter - The character used to split the string (default: '')
 * @returns The zero-padded string
 */
export const addZeroPadding = (
  value: string,
  padLength: number = 3,
  delimiter: string = "",
): string => {
  if (delimiter === "") {
    return value.padStart(padLength, "0");
  }
  const parts = value.split(delimiter);
  return parts.map((part) => part.padStart(padLength, "0")).join(delimiter);
};
