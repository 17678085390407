<template>
  <svg viewBox="0 0 16 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.10352e-05 15.75C6.10352e-05 15.3358 0.335847 15 0.750061 15H15.2501C15.6643 15 16.0001 15.3358 16.0001 15.75C16.0001 16.1642 15.6643 16.5 15.2501 16.5H0.750061C0.335847 16.5 6.10352e-05 16.1642 6.10352e-05 15.75Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00008 0.5C8.30563 0.5 8.58064 0.68537 8.69529 0.968605L12.9453 11.4686C13.1007 11.8526 12.9154 12.2898 12.5315 12.4452C12.1475 12.6006 11.7103 12.4153 11.5549 12.0314L10.2445 8.79412H5.75561L4.44529 12.0314C4.28988 12.4153 3.85264 12.6006 3.46868 12.4452C3.08473 12.2898 2.89946 11.8526 3.05487 11.4686L7.30487 0.968605C7.41951 0.68537 7.69452 0.5 8.00008 0.5ZM6.36276 7.29412H9.6374L8.00008 3.24897L6.36276 7.29412Z"
    />
  </svg>
</template>
