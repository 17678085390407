<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
defineProps<{ showLegend: boolean }>();
defineEmits<{ (event: "toggle"): void }>();
</script>

<template>
  <div
    class="blue-rating__toggle-legend-btn"
    :class="{ 'blue-rating__toggle-legend-btn--show': showLegend }"
  >
    <BaseButton
      variant="text"
      :title="
        showLegend ? t('blueRating.hideDetailedProgress') : t('blueRating.showDetailedProgress')
      "
      @click="$emit('toggle')"
    >
      <BaseIcon icon="info" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.blue-rating__toggle-legend-btn {
  &--show {
    svg {
      color: $secondary-3;
    }
  }
}
</style>
