<script setup lang="ts">
import BaseMap from "@/components/base/BaseMap/BaseMap.vue";
import { MapPin } from "@/components/base/BaseMap/types";

defineProps<{
  pins?: MapPin[];
  isLoadingCoordinates?: boolean;
}>();

const emit = defineEmits<{
  "click:pin": [{ pin: MapPin }];
}>();

const onClickPin = ({ pin }: { pin: MapPin }) => {
  emit("click:pin", { pin });
};
</script>

<template>
  <BaseMap
    class="dashboard-map"
    height="100%"
    :is-loading-data="isLoadingCoordinates"
    :pins="pins"
    clickable-pins
    @click:pin="onClickPin"
  />
</template>

<style scoped lang="scss">
.dashboard-map {
  border-radius: $rounded-base;
}
</style>
