import { defineStore } from "pinia";
import { ref } from "vue";
import { QuestionnaireDto } from "@/types/_generated/api";

export const useQuestionnaireStore = defineStore("questionnaireStore", () => {
  const questionnaire = ref<QuestionnaireDto | null>(null);

  return {
    questionnaire,
    $reset: () => (questionnaire.value = null),
  };
});
