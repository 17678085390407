import { computed, reactive, type Ref } from "vue";
import {
  RecSortOrder,
  RecReportSettingsScope,
  RecReportSectionType,
  type RecReportSettingsDto,
  RecReportTextDto,
} from "@/types/_generated/api";
import type {
  SelectedSection,
  SectionFormData,
  UpdateFormField,
  SectionKeys,
  SectionsDto,
} from "../types";
import { MoveDirection } from "../constants";

const createInitialForm = (scope: RecReportSettingsScope): RecReportSettingsDto => ({
  id: 0,
  scope,
  coverPageSection: {
    showBlueStripe: true,
    showSiteImage: true,
    isVisible: true,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Cover,
  },
  importantNoticeSection: {
    title: { custom: undefined, default: "" },
    text: { custom: undefined, default: "" },
    isVisible: true,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.ImportantNotice,
  },
  blueRatingSection: {
    title: { custom: undefined, default: "" },
    introduction: { custom: undefined, default: "" },
    blueTitle: { custom: undefined, default: "" },
    blueText: { custom: undefined, default: "" },
    greenTitle: { custom: undefined, default: "" },
    greenText: { custom: undefined, default: "" },
    yellowTitle: { custom: undefined, default: "" },
    yellowText: { custom: undefined, default: "" },
    redTitle: { custom: undefined, default: "" },
    redText: { custom: undefined, default: "" },
    grayTitle: { custom: undefined, default: "" },
    grayText: { custom: undefined, default: "" },
    closing: { custom: undefined, default: "" },
    showScore: true,
    showScorePercent: true,
    isVisible: true,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.BlueRating,
  },
  recSection: {
    title: { custom: undefined, default: "" },
    introduction: { custom: undefined, default: "" },
    priorityTitle1: { custom: undefined, default: "" },
    priorityText1: { custom: undefined, default: "" },
    priorityTitle2: { custom: undefined, default: "" },
    priorityText2: { custom: undefined, default: "" },
    priorityTitle3: { custom: undefined, default: "" },
    priorityText3: { custom: undefined, default: "" },
    priorityTitle4: { custom: undefined, default: "" },
    priorityText4: { custom: undefined, default: "" },
    priorityTitle5: { custom: undefined, default: "" },
    priorityText5: { custom: undefined, default: "" },
    closing: { custom: undefined, default: "" },
    recSortOrder: RecSortOrder.Questionnaire,
    isVisible: true,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Recommendations,
  },
  customSection1: {
    title: "",
    text: "",
    isVisible: false,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Custom,
  },
  customSection2: {
    title: "",
    text: "",
    isVisible: false,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Custom,
  },
  customSection3: {
    title: "",
    text: "",
    isVisible: false,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Custom,
  },
  customSection4: {
    title: "",
    text: "",
    isVisible: false,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Custom,
  },
  customSection5: {
    title: "",
    text: "",
    isVisible: false,
    id: 0,
    settingId: 0,
    position: 0,
    sectionType: RecReportSectionType.Custom,
  },
});

export const useFormValues = (
  selectedScope: Ref<RecReportSettingsScope>,
  selectedSection: Ref<SelectedSection | null>,
) => {
  const internalForm = reactive(createInitialForm(RecReportSettingsScope.Internal));
  const externalForm = reactive(createInitialForm(RecReportSettingsScope.External));

  // Get the form for the selected scope
  const selectedScopeForm = computed(() => {
    return selectedScope.value === RecReportSettingsScope.Internal ? internalForm : externalForm;
  });

  const isEmpty = computed(() => !selectedScopeForm.value.id);

  // Get the section form for the selected section (and scope)
  const selectedSectionForm = computed<SectionFormData | undefined>(() => {
    if (!selectedSection.value) return;
    return selectedScopeForm.value[selectedSection.value.key];
  });

  const applyFetchedData = (settings: RecReportSettingsDto[]) => {
    // Reset the forms to the initial state
    Object.assign(internalForm, createInitialForm(RecReportSettingsScope.Internal));
    Object.assign(externalForm, createInitialForm(RecReportSettingsScope.External));

    settings.forEach((setting) => {
      // Update both forms with its specific setting
      [internalForm, externalForm].forEach((form) => {
        if (form.scope === setting.scope) {
          Object.assign(form, {
            id: setting.id,
            scope: setting.scope,
            coverPageSection: { ...form.coverPageSection, ...setting.coverPageSection },
            importantNoticeSection: {
              ...form.importantNoticeSection,
              ...setting.importantNoticeSection,
            },
            blueRatingSection: { ...form.blueRatingSection, ...setting.blueRatingSection },
            recSection: { ...form.recSection, ...setting.recSection },
            customSection1: { ...form.customSection1, ...setting.customSection1 },
            customSection2: { ...form.customSection2, ...setting.customSection2 },
            customSection3: { ...form.customSection3, ...setting.customSection3 },
            customSection4: { ...form.customSection4, ...setting.customSection4 },
            customSection5: { ...form.customSection5, ...setting.customSection5 },
          });
        }
      });
    });
  };

  const updateFormField = (payload: UpdateFormField) => {
    const section = selectedScopeForm.value[payload.sectionKey];
    const field = section[payload.key];

    if (typeof field === "object") {
      Object.assign(section, {
        [payload.key]: { custom: payload.value, default: (field as RecReportTextDto).default },
      });
    } else {
      Object.assign(section, { [payload.key]: payload.value });
    }
  };

  // Reset the form field to default value
  const resetFormField = (payload: Omit<UpdateFormField, "value">) => {
    const section = selectedScopeForm.value[payload.sectionKey];
    const field = section[payload.key];

    if (typeof field === "object") {
      Object.assign(section, {
        [payload.key]: { custom: "", default: (field as RecReportTextDto).default },
      });
    }
  };

  const moveSection = (selectedSection: SelectedSection, direction: MoveDirection) => {
    // Get the current form (without id and scope)
    const formSections = Object.fromEntries(
      Object.entries(selectedScopeForm.value).filter(([key]) => key !== "id" && key !== "scope"),
    ) as SectionsDto;

    // Calculate the positions
    const currentPosition = selectedSection.position;
    const targetPosition =
      direction === MoveDirection.Up ? currentPosition - 1 : currentPosition + 1;

    // Find the section to swap with
    const swapKey = Object.keys(formSections).find(
      (key) => formSections[key as keyof SectionsDto].position === targetPosition,
    );

    // Check if the move is valid
    if (targetPosition <= 0 || targetPosition > Object.keys(formSections).length) return;

    // Keep the cover section in place at the top
    if (targetPosition === 1 || currentPosition === 1) return;

    // Make sure a section is found to swap with
    if (!swapKey) return;

    // Swap positions
    updateFormField({
      sectionKey: selectedSection.key,
      key: "position",
      value: targetPosition,
    });

    updateFormField({
      sectionKey: swapKey as SectionKeys,
      key: "position",
      value: currentPosition,
    });
  };

  return {
    isEmpty,
    internalForm,
    externalForm,
    selectedScopeForm,
    selectedSectionForm,
    resetFormField,
    updateFormField,
    moveSection,
    applyFetchedData,
  };
};
