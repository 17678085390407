import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useTabs } from "@/composables/useTabs";
import { Role } from "@/types/_generated/api";

export enum UserDetailsTab {
  General = "General",
  Permissions = "Permissions",
}

export const useUserDetailsTabs = (roleOfViewedUser: Role) => {
  const { t } = useI18n({ useScope: "global" });

  // The role of the user being viewed in the drawer
  const hasRoleWithPermissions = computed(() =>
    [Role.EXT, Role.INT, Role.BA].includes(roleOfViewedUser),
  );

  const tabs = computed(() => {
    const tabs = [
      {
        label: t("users.tabs.general"),
        name: UserDetailsTab.General,
      },
    ];

    if (hasRoleWithPermissions.value) {
      tabs.push({
        label: t("users.tabs.permissions"),
        name: UserDetailsTab.Permissions,
      });
    }

    return tabs;
  });

  const { activeTab, changeTab } = useTabs(tabs.value, UserDetailsTab.General);

  return {
    tabs,
    changeTab,
    activeTab,
  };
};
