<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useArchivedStatusFilter } from "@/composables/useArchivedStatusFilter";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { authorize } from "@/plugins/can";
import { AdminRouteName } from "@/router/RouteName";
import { getModules, moduleEvents } from "@/services/modules";
import { BroadcastMessage } from "@/services/shared/BroadcastService";
import { useAppStore } from "@/stores/app";
import { Role, type ModuleLeanDto } from "@/types/_generated/api";
import { ColumnDefinition } from "@/types/ColumnDefinition";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import ClientLink from "@/components/shared/ClientLink.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import UserLink from "@/components/shared/UserLink.vue";
import ClientDetailsDrawer from "../../Clients/components/ClientDetailsDrawer.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";
import ModuleEditDrawer from "./Drawers/ModuleEditDrawer/ModuleEditDrawer.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    clientId?: number;
    showClientName?: boolean;
  }>(),
  {
    clientId: undefined,
    showClientName: false,
  },
);

const modules = ref<ModuleLeanDto[]>([]);
const isLoading = ref(false);
const showArchived = ref(false);
const editModule = ref<number | null>(null);

const app = useAppStore();
const { filterArchived, archivedMenuOption } = useArchivedStatusFilter(showArchived);

useBroadcastService(moduleEvents, async (message: BroadcastMessage) => {
  if (message.action === "create" && message.id) {
    editModule.value = message.id as number;
  }
  modules.value = (await getModules({ clientId: props.clientId })) || [];
});

onBeforeMount(async () => {
  isLoading.value = true;

  modules.value = (await getModules({ clientId: props.clientId })) || [];

  isLoading.value = false;
});

const columns = computed(() => {
  const columns: ColumnDefinition<ModuleLeanDto>[] = [
    new ColumnDefinition({ title: t("common.name"), key: "name" }),
  ];
  if (props.showClientName) {
    columns.push(new ColumnDefinition({ title: t("clients.client"), key: "clientName" }));
  }

  columns.push(
    new ColumnDefinition({
      title: t("modules.office"),
      key: "willisOffice",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.countryOrUnit"),
      key: "willisCountryOrUnit",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.administrator"),
      key: "administratorName",
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("modules.status"),
      key: "status",
      maxWidth: "120px",
    }),
  );

  return columns;
});

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(modules, columns, [filterArchived]);

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();

const {
  showDrawer: showClientDetailDrawer,
  drawerData: clientDrawerId,
  closeDrawer: closeClientDetailDrawer,
  openDrawer: openClientDetailDrawer,
} = useDrawerState<number>();
</script>

<template>
  <ModuleEditDrawer v-if="editModule" :module-id="editModule" @close-drawer="editModule = null" />

  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="modulesTable"
    :default-sort="[{ key: 'name' }]"
    :custom-options="app.isAdmin ? [archivedMenuOption] : []"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #header-clientName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #column-name="{ cell, row }">
      <router-link
        :to="{
          name: AdminRouteName.Module,
          params: { moduleId: row.moduleId },
        }"
      >
        {{ cell }}
      </router-link>
    </template>
    <template #column-clientName="{ row }">
      <ClientLink
        :label="row.clientName"
        :client-id="row.clientId"
        @click="openClientDetailDrawer"
      />
    </template>

    <template #column-administratorName="{ row }">
      <UserLink
        :label="row.administratorName"
        :user-id="row.administratorUserId"
        :user-role="row.administratorRole"
        @click="openUserDetailDrawer(row.administratorUserId)"
      />
    </template>
    <template #column-status="{ row }">
      <BaseStatusBadge :status="row.status" />
    </template>

    <template #actions="{ row }">
      <EditIconButton
        v-if="authorize.isSuperiorTo(Role.INT)"
        :title="t('common.actions.edit')"
        @click="() => (editModule = row.moduleId)"
      />
    </template>
  </BaseGridTable>

  <ClientDetailsDrawer
    v-if="showClientDetailDrawer && clientDrawerId"
    :client-id="clientDrawerId"
    @close="closeClientDetailDrawer"
  />

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    width="30"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
</style>
