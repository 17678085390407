<script setup lang="ts">
import { computed } from "vue";
import { ExecutiveReportCategoryDto } from "@/types/_generated/api";
import BaseDivider from "@/components/base/BaseDivider.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

const props = defineProps<{
  categories: ExecutiveReportCategoryDto[];
  deselectedCategories: Set<number>;
}>();

const emit = defineEmits<{
  "update:deselectedCategories": [Set<number>];
}>();

// Transform data for display in the multiselect
const groupedCategories = computed(() => {
  // Group categories by areaName and sort them
  const result = props.categories.reduce(
    (acc, { areaName, categoryName, categoryId, position }) => {
      // Initialize the array if it doesn't exist
      acc[areaName] = acc[areaName] || [];
      // Push both categoryName and categoryId into the array as an object
      acc[areaName].push({ label: `${position}. ${categoryName}`, value: categoryId });
      return acc;
    },
    {} as Record<string, { label: string; value: number }[]>,
  );

  // Sort each group of categories by label for consistent ordering
  for (const area in result) {
    result[area].sort((a, b) => a.label.localeCompare(b.label));
  }

  return result;
});

// Determine which categories are considered included
// by comparing the deselected categories with the categories options
const includedCategories = computed(() => {
  return new Set(
    props.categories
      .filter((category) => !props.deselectedCategories.has(category.categoryId))
      .map((category) => category.categoryId),
  );
});

// Updates the set of deselected category IDs
const handleSelectOptions = (options: number[]) => {
  // Determine which category IDs are deselected
  // by comparing the incoming options with the category IDs
  const allCategoryIds = new Set(props.categories.map((c) => c.categoryId));
  const incomingIds = new Set(options);
  const deselectedIds = Array.from(allCategoryIds).filter((id) => !incomingIds.has(id));
  emit("update:deselectedCategories", new Set(deselectedIds));
};
</script>

<template>
  <div class="exec-summary-filter-categories">
    <template v-for="(group, area, index) in groupedCategories" :key="index">
      <ModMultiselect
        :label="area"
        :options="group"
        :selected-options="includedCategories"
        @update:selected="handleSelectOptions"
      />
      <BaseDivider v-if="index !== Object.keys(groupedCategories).length - 1" />
    </template>
  </div>
</template>

<style scoped lang="scss">
.exec-summary-filter-categories {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  margin-top: $spacing-4;
  padding: 0 $spacing-2;
  max-height: 24rem;
  overflow: auto;
  font-variant-numeric: tabular-nums;
}
</style>
