<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { BlueColor } from "@/types/_generated/api";
import { BlueRating } from "@/types/Survey";
import BaseProgressBar from "@/components/base/BaseProgressBar.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  survey: BlueRating;
  showLegend: boolean;
}>();

const progress = computed(() => {
  const stats = {
    total: 0,
    answered: 0,
    completed: 0,
    missingRecommendations: 0,
  };

  for (const area of props.survey.areas || []) {
    for (const category of area.categories) {
      for (const question of category.questions) {
        stats.total += 1;

        const isAnswered = question.value && question.value !== BlueColor.NotSet;

        const isComplete =
          question.value === BlueColor.Blue ||
          question.value === BlueColor.Gray ||
          ((question.value === BlueColor.Green ||
            question.value === BlueColor.Yellow ||
            question.value === BlueColor.Red) &&
            (question.recommendations.length || category.recommendations.length));

        if (isAnswered) {
          stats.answered += 1;
        }

        if (isComplete) {
          stats.completed += 1;
        }

        if (!isComplete && isAnswered) {
          stats.missingRecommendations += 1;
        }
      }
    }
  }

  return stats;
});
</script>

<template>
  <div class="blue-rating-progress">
    <div v-if="showLegend" class="blue-rating-progress__legend">
      <div class="blue-rating-progress__legend__value">
        <div
          class="blue-rating-progress__legend__value__color blue-rating-progress__legend__value__color--completed"
        ></div>
        <div>
          {{ `${progress.answered} / ${progress.total}` }}
        </div>
        <div>
          {{ t("blueRating.progress.answered") }}
        </div>
      </div>
      <div class="blue-rating-progress__legend__value">
        <div
          class="blue-rating-progress__legend__value__color blue-rating-progress__legend__value__color--missing"
        ></div>
        <div>
          {{
            t("blueRating.progress.missingRecommendations", {
              count: progress.missingRecommendations,
            })
          }}
        </div>
      </div>
    </div>
    <BaseProgressBar
      :max="progress.total"
      :primary-progress="progress.completed"
      :secondary-progress="progress.missingRecommendations"
    />
  </div>
</template>

<style scoped lang="scss">
.blue-rating-progress__legend {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-2;

  &__value {
    display: flex;
    align-items: center;
    padding: $spacing-1;
    gap: $spacing-2;
    font-size: $text-sm;

    &__color {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: $rounded-full;

      &--completed {
        background-color: $secondary-4;
      }

      &--missing {
        background-color: $secondary-2;
      }
    }
  }
}
</style>
