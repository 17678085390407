import type { CurrentUserDto } from "@/types/_generated/api";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { handleError } from "./shared/handleError";

interface ImpersonationResponse {
  user: CurrentUserDto;
  isImpersonated: boolean;
}

const { t } = i18n.global;

export const impersonate = async (userId: number) => {
  try {
    const { data } = await http.post<ImpersonationResponse>(`/admin/impersonate/${userId}`, {
      params: { userId },
    });

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.impersonate").toLowerCase(),
      entity: t("users.user"),
    });
  }
};

export const endImpersonation = async () => {
  try {
    const { data } = await http.post<ImpersonationResponse>(`/admin/impersonate/logout`);

    return data;
  } catch (error) {
    handleError(error);
  }
};
