<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { SiteDefinitionSuggestionsDto } from "@/types/_generated/api";
import { useGetSite } from "@/composables/services/useGetSite";
import { Tab, useTabs } from "@/composables/useTabs";
import { getSiteDefinitionSuggestions } from "@/services/modules";
import { sitesEvents, updateSite } from "@/services/sites";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import { useSiteEditDrawerForm } from "./composables/useSiteEditDrawerForm";
import { useValidateSiteEditForm } from "./composables/useValidateSiteEditForm";
import SiteEditDrawerDefinitions from "./SiteEditDrawerDefinitions.vue";
import SiteEditDrawerFacilityInfo from "./SiteEditDrawerFacilityInfo.vue";
import SiteEditDrawerMap from "./SiteEditDrawerMap.vue";
import SiteEditDrawerNextSurvey from "./SiteEditDrawerNextSurvey.vue";
import SiteEditDrawerReportImage from "./SiteEditDrawerReportImage.vue";

enum SiteEditTab {
  SiteInformation = "SiteInformation",
  ReportImage = "ReportImage",
}

const props = defineProps<{
  siteId: number;
  moduleId: number;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
const { isLoadingSite, siteData, getSite } = useGetSite();
const { validationState, resetValidationStateField, isFormValid } = useValidateSiteEditForm();
const { siteEditForm, updateSiteEditForm, updateSiteEditField } =
  useSiteEditDrawerForm(resetValidationStateField);

const siteDefinitionSuggestions = ref<SiteDefinitionSuggestionsDto[]>([]);

const tabs: Tab<SiteEditTab>[] = [
  { label: t("sites.siteInformation"), name: SiteEditTab.SiteInformation },
  { label: t("sites.reportImage"), name: SiteEditTab.ReportImage },
];

const { activeTab, changeTab } = useTabs(tabs, SiteEditTab.SiteInformation);

const saveSite = async () => {
  if (!isFormValid(siteData.value, siteEditForm)) return;

  await updateSite(siteEditForm);
  emit("closeDrawer");
};

onMounted(async () => {
  await getSite(props.siteId);
  if (siteData.value) {
    updateSiteEditForm(siteData.value);
    updateSiteEditField("moduleId", props.moduleId);
  }
  const suggestions = await getSiteDefinitionSuggestions(props.moduleId);
  siteDefinitionSuggestions.value = suggestions || [];
});
</script>

<template>
  <BaseDrawer width="50" :is-loading="isLoadingSite" @close="$emit('closeDrawer')">
    <template #title>
      <div class="site-edit-drawer__title">
        <p>
          {{ t("sites.editSiteDrawer.editSite") }}
        </p>
        <BaseStatusBadge
          v-if="siteData"
          :status="siteData.status"
          :title="t(`common.statuses.${siteData.status}`)"
        />
      </div>
    </template>

    <BaseDrawerTabs :current-tab="activeTab" :tabs="tabs" @change="changeTab" />

    <div
      v-show="activeTab === SiteEditTab.SiteInformation"
      class="site-edit-drawer__site-information"
    >
      <SiteEditDrawerDefinitions
        :site="siteData"
        :site-definition-suggestions="siteDefinitionSuggestions"
        :site-form="siteEditForm"
        :error-messages="validationState"
        @update:field="updateSiteEditField"
      />

      <BaseDivider />

      <SiteEditDrawerNextSurvey
        :module-id="moduleId"
        :selected-surveyor-id="siteEditForm?.surveyorId"
        :site-form="siteEditForm"
        :error-messages="validationState"
        @update:field="updateSiteEditField"
      />

      <BaseDivider />

      <SiteEditDrawerFacilityInfo
        :site-form="siteEditForm"
        :error-messages="validationState"
        @update:field="updateSiteEditField"
      />

      <SiteEditDrawerMap :site-form="siteEditForm" @update:field="updateSiteEditField" />
    </div>

    <SiteEditDrawerReportImage
      v-show="activeTab === SiteEditTab.ReportImage"
      :site="siteData"
      :site-id="siteId"
      :module-id="moduleId"
      @upload:image="sitesEvents.post({ action: 'update', id: siteId })"
    />

    <template #footer-left>
      <BaseButton @click="saveSite">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="$emit('closeDrawer')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.site-edit-drawer {
  &__title {
    display: flex;
    align-items: center;
    gap: $spacing-4;
  }

  &__site-information {
    display: flex;
    flex-direction: column;
    gap: $spacing-6;
    padding: $spacing-6;
  }
}
</style>
