<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DraftProperties } from "@/composables/useQuestionnaireDraftClient";
import {
  getQuestionnaireDraftPropertyHistory,
  updateQuestionnaireDraft,
} from "@/services/questionnaire-draft";
import {
  DraftAction,
  QuestionnaireDraftActionType,
  QuestionnaireDraftPropertyHistoryDto,
} from "@/types/_generated/api";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import QuestionnaireDraftPropertyHistory from "./QuestionnaireDraftPropertyHistory.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  questionnaireId: number;
  targetActionId: number;
  type: QuestionnaireDraftActionType;
  propertyName: DraftProperties;
  targetName: string;
}>();

const isLoading = ref(false);
const history = ref<QuestionnaireDraftPropertyHistoryDto[] | null>([]);

const emits = defineEmits<{
  (event: "close"): void;
}>();

onBeforeMount(async () => {
  isLoading.value = true;

  history.value = await getQuestionnaireDraftPropertyHistory({
    questionnaireId: props.questionnaireId,
    targetActionId: props.targetActionId,
    type: props.type,
    property: props.propertyName,
  });

  isLoading.value = false;
});

const revert = async (value: string) => {
  await updateQuestionnaireDraft(props.questionnaireId, [
    {
      action: DraftAction.Update,
      targetActionId: props.targetActionId,
      type: props.type,
      property: props.propertyName,
      value,
    },
  ]);

  emits("close");
};
</script>

<template>
  <BaseDrawer @close="emits('close')">
    <template #title>
      <div>
        {{
          t("questionnaire.draft.history.propertyHistoryTitle.header", {
            type: type,
            property: propertyName,
          })
        }}
      </div>
      <div>
        {{
          t("questionnaire.draft.history.propertyHistoryTitle.subHeader", {
            name: targetName,
          })
        }}
      </div>
    </template>

    <QuestionnaireDraftPropertyHistory
      v-if="!isLoading"
      :history="history ?? []"
      @click="revert($event.value!)"
    />

    <BaseSkeleton v-if="isLoading" :set="3" width="100%" height="44px" mb="1rem" />

    <template #footer-left>
      <div class="questionnaire-draft__property-history-drawer__footer">
        {{ t("questionnaire.draft.history.selectToRevert") }}
      </div>
    </template>
  </BaseDrawer>
</template>

<style lang="scss" scoped>
.questionnaire-draft__property-history-drawer__footer {
  text-align: center;
  width: 100%;
}
</style>
