<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { SortOrder } from "@/constants/SortOrder";
import { formatDate } from "@/helpers/formatDate";
import { toCurrency } from "@/helpers/formatNumber";
import { sortByKeys } from "@/helpers/sort";
import {
  RecommendationResponseDto,
  RecommendationRiskManagerCommentDto,
} from "@/types/_generated/api";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import { useRecommendationResponse } from "@/components/shared/RecommendationResponse/composables/useRecommendationResponse";
import { useRiskManagerComment } from "@/components/shared/RecommendationResponse/composables/useRiskManagerComment";
import ExternalResponseFileList from "./ExternalResponseFileList.vue";

type TimelineEntry = {
  comment: RecommendationRiskManagerCommentDto | null;
  response: RecommendationResponseDto | null;
  timestamp: string;
};

const props = defineProps<{
  currency: string;
  responseHistory: RecommendationResponseDto[];
  riskManagerCommentHistory: RecommendationRiskManagerCommentDto[];
  title?: string;
}>();

const showCompleteHistory = ref(false);

const { t } = useI18n({ useScope: "global" });
const { lockedResponses, filteredResponses } = useRecommendationResponse(
  toRef(() => props.responseHistory),
);
const { lockedComments } = useRiskManagerComment(toRef(() => props.riskManagerCommentHistory));

const timeline = computed(() => {
  const responses: TimelineEntry[] = (
    showCompleteHistory.value ? lockedResponses.value : filteredResponses.value
  ).map((r) => ({
    comment: null,
    response: r,
    timestamp: r.updateDate,
  }));
  const comments: TimelineEntry[] = lockedComments.value.map((c) => ({
    comment: c,
    response: null,
    timestamp: c.insertDate,
  }));
  return sortByKeys([...responses, ...comments], {
    key: "timestamp",
    order: SortOrder.Descending,
  }).toReversed();
});
</script>

<template>
  <div class="response-and-comment-timeline">
    <div class="response-and-comment-timeline__header">
      <h2 class="response-and-comment-timeline__header__title">
        {{ title }}
      </h2>
      <BaseCheckbox
        v-if="timeline.length"
        :checked="showCompleteHistory"
        :label="t('recommendationResponse.showCompleteHistory')"
        @change="showCompleteHistory = !showCompleteHistory"
      />
    </div>

    <div v-if="!timeline.length">
      <p>{{ t("recommendationResponse.noHistory") }}</p>
    </div>

    <div v-if="timeline.length" class="response-and-comment-timeline__entries">
      <div
        v-for="(entry, i) in timeline"
        :key="i"
        class="response-and-comment-timeline__entry"
        :class="{
          'response-and-comment-timeline__entry--right': !!entry.comment,
          'response-and-comment-timeline__entry--left': !!entry.response,
        }"
      >
        <div class="response-and-comment-timeline__entry__header">
          {{ entry.comment?.email || entry.response?.updateBy }}
          {{ formatDate.asDateAndTime(entry.timestamp) }}
        </div>
        <div class="response-and-comment-timeline__entry__content">
          <div v-if="entry.comment">
            {{ entry.comment.comment }}
          </div>

          <template v-if="entry.response">
            <div class="response-and-comment-timeline__entry__content__flex">
              <div>
                <BaseStatusBadge v-if="entry.response.status" :status="entry.response.status" />
              </div>
            </div>
            <div v-if="entry.response.comment">
              {{ entry.response.comment }}
            </div>
            <div v-if="entry.response.dueDate">
              {{ t("recommendationResponse.dueDate") }}:
              {{ formatDate.asDateOnly(entry.response.dueDate) }}
            </div>
            <div v-if="entry.response.costToComplete">
              {{ t("recommendationResponse.costToComplete") }}:
              {{ toCurrency(entry.response.costToComplete, currency) }}
            </div>
            <div v-if="entry.response.responsiblePerson">
              {{ t("recommendationResponse.responsiblePerson") }}:
              {{ entry.response.responsiblePerson }}
            </div>
            <ExternalResponseFileList :response="entry.response" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.response-and-comment-timeline {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacing-2;
    margin-bottom: $spacing-4;
    border-bottom: 1px solid $primary-4;

    &__title {
      line-height: $leading-normal;
      font-size: $text-base;
      font-weight: $font-semibold;
    }
  }

  &__entries {
    display: flex;
    flex-direction: column;
  }

  &__entry {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-4;
    width: 55%;

    &--right {
      align-self: flex-end;
    }

    &--left {
      align-self: flex-start;
    }

    &__header {
      font-style: italic;
      font-size: $text-xs;
      color: $primary-6;
      margin-bottom: $spacing-1;
    }

    &--right &__content {
      background-color: $info-2;
    }
    &__content {
      padding: $spacing-2;
      border-radius: $rounded-base;
      background-color: $primary-2;
      display: flex;
      flex-direction: column;
      gap: $spacing-2;

      &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
