import { ref } from "vue";
import { getBlueRatingRecommendationsService } from "@/services/recommendations";
import { BlueRatingRecommendationDto, RecommendationFilter } from "@/types/_generated/api";

export const useGetRecommendations = () => {
  const isLoadingRecommendations = ref(false);
  const hasError = ref(false);
  const recommendations = ref<BlueRatingRecommendationDto[]>([]);

  const getRecommendations = async (surveyId: number, filter?: RecommendationFilter) => {
    isLoadingRecommendations.value = true;
    hasError.value = false;

    try {
      const response = await getBlueRatingRecommendationsService(surveyId, filter);
      if (response) recommendations.value = response;
    } catch {
      hasError.value = true;
    } finally {
      isLoadingRecommendations.value = false;
    }
  };

  return {
    isLoadingRecommendations,
    hasError,
    recommendations,
    getRecommendations,
  };
};
