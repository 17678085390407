<script setup lang="ts">
import { computed, reactive, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import AuthForgot from "@/views/Auth/components/AuthForgot.vue";
import AuthMfaForm from "@/views/Auth/components/AuthMfaForm.vue";
import AuthMfaSelector from "@/views/Auth/components/AuthMfaSelector.vue";
import AuthSignedOut from "@/views/Auth/components/AuthSignedOut.vue";
import AuthSignIn from "@/views/Auth/components/AuthSignIn.vue";
import AuthWrapper from "@/views/Auth/components/AuthWrapper.vue";

const { t } = useI18n({ useScope: "global" });
const { query } = useRoute();

const currentView = ref<ViewAuthStatus>(ViewAuthStatus.SignIn);

const user = reactive({
  email: "",
  password: "",
});

const expiryMinutes = ref<number | null>(null);

const heading = computed(() => {
  const headings = {
    [ViewAuthStatus.SignIn]: t("signIn.signIn"),
    [ViewAuthStatus.TwoFactorSelect]: t("signIn.2fa.method"),
    [ViewAuthStatus.TwoFactorVerify]: t("signIn.2fa.enterCode"),
    [ViewAuthStatus.Forgot]: t("signIn.forgotPassword"),
    [ViewAuthStatus.SignedOut]: t("signIn.signedOut"),
  } as Record<ViewAuthStatus, string>;

  return headings[currentView.value] ?? headings[ViewAuthStatus.SignIn];
});

const isSignedOutView = computed(() =>
  [ViewAuthStatus.SignedOut, ViewAuthStatus.SessionExpired, ViewAuthStatus.SsoFailed].includes(
    currentView.value,
  ),
);

const handleViewChange = (view: ViewAuthStatus) => {
  currentView.value = view;
};

onMounted(() => {
  // Change view after user signed out
  switch (query.reason) {
    case ViewAuthStatus.SignedOut:
      handleViewChange(ViewAuthStatus.SignedOut);
      break;
    case ViewAuthStatus.SessionExpired:
      handleViewChange(ViewAuthStatus.SessionExpired);
      break;
    case ViewAuthStatus.SsoFailed:
      handleViewChange(ViewAuthStatus.SsoFailed);
      break;
  }
});
</script>

<template>
  <AuthWrapper :heading="heading">
    <AuthSignIn
      v-if="currentView === ViewAuthStatus.SignIn"
      :email="user.email"
      :password="user.password"
      @update:email="user.email = $event"
      @update:password="user.password = $event"
      @update:expiry="expiryMinutes = $event"
      @view-change="handleViewChange"
    />

    <AuthMfaSelector
      v-if="currentView === ViewAuthStatus.TwoFactorSelect"
      :email="user.email"
      :password="user.password"
      @update:expiry="expiryMinutes = $event"
      @view-change="handleViewChange"
    />

    <AuthMfaForm
      v-if="currentView === ViewAuthStatus.TwoFactorVerify"
      :expiry-minutes="expiryMinutes"
      :email="user.email"
      :password="user.password"
      @view-change="handleViewChange"
    />

    <AuthForgot
      v-if="currentView === ViewAuthStatus.Forgot"
      :email="user.email"
      @update:email="user.email = $event"
      @view-change="handleViewChange"
    />

    <AuthSignedOut
      v-if="isSignedOutView"
      :current-view="currentView"
      @view-change="handleViewChange"
    />
  </AuthWrapper>
</template>
